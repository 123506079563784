import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IExpenseDetails,
  IExpenseDetailsInitialState,
} from "./expenseDetailsModel";
// import moment from "moment";
import {
  getAllSRExpense,
  createSRExpense,
  updateSRExpense,
  deleteSRExpense,
  getSRExpenseByID,
} from "./services/expenseDetails.services";

const initialState: IExpenseDetailsInitialState = {
  loading: false,
  error: "",
  formLoading: false,
  SRExpenseList: [],
  ExpenseDetailsID: null,
  ExpenseDetailsGUID: "",
  ExpenseDetail: {
    expense_date: "",
    from_city: null,
    to_city: null,
    mode_of_transport: null,
    city_town_visited: "",
    sales_amount: null,
    remarks: "",
    fixed_expense: null,
    expense_details: [],
    total_amount: null,
    kms_travelled: null,
    fare_for_transport: null,
    net_amount: null,
    sr_id: null,
  },
  FilterCustomExpenseDetails: {
    from_city: "",
    to_city: "",
  },
};

const expenseDetailsSlice = createSlice({
  name: "expensedetails",
  initialState,
  reducers: {
    clearExpenseDetail: (state) => {
      state.ExpenseDetail = initialState.ExpenseDetail;
    },
    setExpenseDetailsGUID: (state, action) => {
      state.ExpenseDetailsGUID = action.payload;
    },
    setExpenseDetailsID: (state, action) => {
      state.ExpenseDetailsID = action.payload;
    },
    setFilterCustomExpenseDetails: (state, action) => {
      state.FilterCustomExpenseDetails = action.payload;
    },
    clearFilterCustomExpenseDetails: (state) => {
      state.FilterCustomExpenseDetails =
        initialState.FilterCustomExpenseDetails;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllSRExpense.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllSRExpense.fulfilled,
      (state, action: PayloadAction<IExpenseDetails[]>) => {
        state.loading = false;
        state.SRExpenseList = action.payload || [];
      }
    );
    builder.addCase(getAllSRExpense.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.SRExpenseList = [];
    });

    builder.addCase(createSRExpense.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createSRExpense.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createSRExpense.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateSRExpense.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateSRExpense.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateSRExpense.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteSRExpense.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteSRExpense.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteSRExpense.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getSRExpenseByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getSRExpenseByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.ExpenseDetail = action.payload;
    });
    builder.addCase(getSRExpenseByID.rejected, (state, action) => {
      state.loading = false;
      state.ExpenseDetail = initialState.ExpenseDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});
export const {
  setExpenseDetailsID,
  setExpenseDetailsGUID,
  clearExpenseDetail,
  setFilterCustomExpenseDetails,
  clearFilterCustomExpenseDetails,
} = expenseDetailsSlice.actions;
export default expenseDetailsSlice.reducer;
