import React, { useEffect } from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import { setDutyAndTaxDeleteIndex } from "./purchaseSlice";
import { useLocation } from "react-router-dom";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import _ from "lodash";
import {
  requiredValidator,
  skipValidator,
} from "../../components/formFields/CommonValidator";
import { TAXTYPE } from "../../_contstants/common";

const DutyAndTaxDetailsArray = (
  fieldArrayRenderProps: FieldArrayRenderProps
) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const purchase_guid = location.state?.purchase_guid;

  const DutyAndTaxDeleteIndex = useAppSelector(
    (state) => state.purchase.DutyAndTaxDeleteIndex
  );
  const InputRef = React.useRef<any>(null);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("DutyAndTaxItemDeleteDialog"));
    dispatch(setDutyAndTaxDeleteIndex(index));
  };

  useEffect(() => {
    if (!purchase_guid || fieldArrayRenderProps.value.length === 0) {
      pushElementInarray();
    }
  }, []);
  const handleDeleteAction = React.useCallback(
    (index: number) => {
      fieldArrayRenderProps.onRemove({ index: index });
      const focusedElement = document.getElementsByName(
        `purchase_duty_and_taxes.${
          fieldArrayRenderProps.value.length - 1
        }.gst_amount`
      );
      focusedElement[0].focus();
      dispatch(closeDialog());
      dispatch(setDutyAndTaxDeleteIndex(-1));
    },
    [fieldArrayRenderProps]
  );
  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        ledger_id: null,
        gst_per: null,
        gst_amount: null,
      },
    });
    setTimeout(() => {
      InputRef.current?.element?.children[0]?.children[1]?.children[0]?.focus();
    }, 0);
  }, [fieldArrayRenderProps]);

  const handleProductChange = async (
    e: any,
    innerIndex: number,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => {
    if (e.value) {
      const selectedItem = fieldArrayRenderProps?.value?.[
        innerIndex
      ]?.ledger_options?.filter((item: any) => item.id === e.value)?.[0];
      if (selectedItem?.tax_percentage) {
        fieldArrayRenderProps.formRenderProps.onChange(
          `purchase_duty_and_taxes.${innerIndex}.gst_per`,
          {
            value: selectedItem?.tax_percentage,
          }
        );
      }
    }
  };

  const debouncedHandleProductChange = React.useRef(
    _.debounce((item_id: any, index: number, fieldArrayRenderProps: any) => {
      handleProductChange(item_id, index, fieldArrayRenderProps);
    }, 300)
  ).current;

  useEffect(() => {
    return () => {
      debouncedHandleProductChange.cancel();
    };
  }, []);

  const handleChange = (e: any, index: number, fieldArrayRenderProps: any) => {
    if (e?.value) {
      debouncedHandleProductChange(e, index, fieldArrayRenderProps);
    } else {
      fieldArrayRenderProps?.formRenderProps.onChange(
        `purchase_duty_and_taxes.${index}.gst_amount`,
        {
          value: null,
        }
      );
      fieldArrayRenderProps?.formRenderProps.onChange(
        `purchase_duty_and_taxes.${index}.gst_per`,
        {
          value: null,
        }
      );
      fieldArrayRenderProps.formRenderProps.onChange(
        `purchase_duty_and_taxes.${index}.tax_type`,
        {
          value: undefined,
        }
      );
      fieldArrayRenderProps.formRenderProps.onChange(
        `purchase_duty_and_taxes.${index}.type_of_supply`,
        {
          value: undefined,
        }
      );
    }
  };

  // const handleTotalChange = async (
  //   e: any,
  //   total: number,
  //   index: number,
  //   fieldArrayRenderProps: FieldArrayRenderProps
  // ) => {
  //   e?.syntheticEvent?.preventDefault();
  //   const Ledger = fieldArrayRenderProps.formRenderProps.valueGetter(
  //     `purchase_duty_and_taxes.${index}.ledger_id`
  //   );

  //   console.log(Ledger);
  // };

  const handleKeyEvent = (
    e: any,
    index: number,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => {
    if (
      e.key === "Tab" &&
      fieldArrayRenderProps.value.length === index + 1 &&
      fieldArrayRenderProps.value[index].ledger_id
    ) {
      if (!e.shiftKey) {
        pushElementInarray();
      }
    }
    if (e.key === "Escape" && fieldArrayRenderProps.value.length !== 1) {
      handleOpenDeleteDialog(index);
    }
  };

  const handleSearchLedger = (search: any, index: any) => {
    if (search) {
      fieldArrayRenderProps.handleLedgerSearchChange(
        search,
        `purchase_duty_and_taxes.${index}.ledger_options`,
        fieldArrayRenderProps.formRenderProps
      );
    }
  };

  return (
    <>
      <GridLayout cols={[{ width: "100%" }]}>
        {dialogName === "DutyAndTaxItemDeleteDialog" && (
          <AppDialog>
            <>
              <Typography.h5>{"Delete Item"}</Typography.h5>
              <GridLayout>
                <GridLayoutItem>
                  <Typography.p>
                    Are you sure you want to delete this?
                  </Typography.p>
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    style={{ marginRight: 5 }}
                    fillMode={"solid"}
                    themeColor={"error"}
                    onClick={() => handleDeleteAction(DutyAndTaxDeleteIndex)}
                    type="button"
                  >
                    Delete
                  </Button>
                  <DialogCloseButton themeColor="error" />
                </GridLayoutItem>
              </GridLayout>
            </>
          </AppDialog>
        )}
        {fieldArrayRenderProps.value &&
          fieldArrayRenderProps.value.length > 0 &&
          fieldArrayRenderProps.value.map(
            (purchase_duty_and_taxes: any, index: number) => (
              <GridLayoutItem key={index}>
                <GridLayout
                  style={{ marginRight: 15, padding: 5, position: "relative" }}
                  gap={{ rows: 10, cols: 5 }}
                  cols={[
                    { width: "49%" },
                    { width: "25%" },
                    { width: "8%" },
                    { width: "18%" },
                  ]}
                >
                  <GridLayoutItem></GridLayoutItem>
                  <GridLayoutItem ref={InputRef}>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`purchase_duty_and_taxes.${index}.ledger_id`}
                      placeholder="search Ledger"
                      // isAddNew={true}
                      // addNewLink="account/create"
                      component={FormIncrementalSearch}
                      onChange={
                        (e: any) =>
                          handleChange(e, index, fieldArrayRenderProps)

                        // handleProductChange(e, index, fieldArrayRenderProps)
                      }
                      fetchIncrementalData={(search: any) =>
                        handleSearchLedger(search, index)
                      }
                      options={
                        fieldArrayRenderProps.value[index]?.ledger_options?.map(
                          (item: any) => {
                            return {
                              value: item?.id,
                              label: item?.account_name,
                            };
                          }
                        ) || []
                      }
                    />
                  </GridLayoutItem>
                  {/* <GridLayoutItem></GridLayoutItem> */}
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`purchase_duty_and_taxes.${index}.gst_per`}
                      placeholder="0"
                      min="0"
                      disabled={TAXTYPE?.map((item: any) =>
                        item.value?.toLowerCase()
                      )?.includes(
                        fieldArrayRenderProps.value[index].ledger_options
                          ?.filter(
                            (item: any) =>
                              item.id ===
                              fieldArrayRenderProps.value[index]?.ledger_id
                          )?.[0]
                          ?.tax_type?.toLowerCase()
                      )}
                      // validator={requiredValidator}
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`purchase_duty_and_taxes.${index}.gst_amount`}
                      placeholder="0"
                      format="n2"
                      // ref={(el: any) => (refs.current[index] = el)}
                      // disabled={
                      //   fieldArrayRenderProps.value[index].ledger_id
                      //     ? false
                      //     : true
                      // }
                      validator={
                        fieldArrayRenderProps.value[index]?.ledger_id
                          ? requiredValidator
                          : skipValidator
                      }
                      component={FormNumericTextField}
                      onKeyDown={(e: any) =>
                        handleKeyEvent(e, index, fieldArrayRenderProps)
                      }
                    />
                  </GridLayoutItem>
                  {/* <GridLayoutItem
                    style={{
                      position: "sticky",
                      top: 0,
                      right: 0,
                      zIndex: 9,
                    }}
                  >
                    <IconButton
                      onClick={() => handleOpenDeleteDialog(index)}
                      disabled={fieldArrayRenderProps.value.length === 1}
                      themeColor={"error"}
                      size={"small"}
                      fillMode={"solid"}
                      type="button"
                      style={{
                        position: "absolute",
                        height: 32,
                        width: 32,
                        top: 0,
                        right: 0,
                      }}
                    >
                      <MdDelete
                        className="absolute-position"
                        style={{ fontSize: "16px" }}
                      />
                    </IconButton>
                  </GridLayoutItem> */}
                </GridLayout>
              </GridLayoutItem>
            )
          )}
      </GridLayout>
    </>
  );
};

export default DutyAndTaxDetailsArray;
