import React, { useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import ShadowCard from "../../components/common/ShadowCard";
import FormDatePicker from "../../components/formFields/FormDateField";
import { FINANCIAL_YEAR } from "../../_contstants/common";
import moment from "moment";
import DebitnoteVoucherEntryDetailsArray from "./DebitnoteVoucherEntryDetailsArray";
import {
  createDebitnoteVoucher,
  generateDebitnoteVoucherNoForDebitnoteVoucher,
  getDebitnoteVoucherByID,
  updateDebitnoteVoucher,
} from "./services/debitnoteVoucher";
import {
  clearDebitnoteVoucherDetails,
  setDebitnoteVoucherBillDialogOpenIndex,
} from "./debitnoteVoucherSlice";
import DebitnoteVoucherBillDetailsArray from "./DebitnoteVoucherBillDetailsArray";
import { getAllAccountIncremental } from "../account/services/account.services";
import FormTextArea from "../../components/formFields/FormTextArea";
import { ErrorToast } from "../../components/toast/Toasts";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { findAllVoucherTypeByType } from "../vouchertype/services/voucherType.services";
import { findIndex } from "lodash";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const BillDebitnoteVoucherEntryChangeWatcher: React.FC<
  FormChangeWatcherProps
> = ({ formRenderProps }) => {
  const debit_note_voucher_bill_entry =
    formRenderProps.valueGetter("debit_note_voucher_bill_entry") || [];
  const debit_note_voucher_entry = formRenderProps.valueGetter(
    "debit_note_voucher_entry"
  );
  const DebitnoteVoucherBillDialogOpenIndex = useAppSelector(
    (state) => state.debitnotevoucher.DebitnoteVoucherBillDialogOpenIndex
  );
  const OutstandingDebitnoteVoucherList = useAppSelector(
    (state) => state.debitnotevoucher.OutstandingDebitnoteVoucherList
  );
  const location = useLocation();
  const debit_note_voucher_guid = location.state?.debit_note_voucher_guid;

  useEffect(() => {
    const entryReference =
      debit_note_voucher_entry?.[DebitnoteVoucherBillDialogOpenIndex]
        ?.debit_note_voucher_entry_referance;
    if (
      DebitnoteVoucherBillDialogOpenIndex >= 0 &&
      entryReference?.length > 0
    ) {
      const debitnoteVoucherRefID =
        entryReference?.[0]?.debit_note_voucher_ref_id;
      if (debit_note_voucher_guid && debitnoteVoucherRefID) {
        //=== THIS IS FOR OLD BILL ENTRY FIRST TIME TAB IN EDIT MODE ===//
        const referenceMap = new Map<
          number,
          { refernace_name: string; referance_amount: number }
        >(
          entryReference.map((entry: any) => [
            entry.refernace_bill_id,
            {
              refernace_name: entry.refernace_name,
              referance_amount: entry.referance_amount,
            },
          ])
        );
        // const mergedArray = OutstandingDebitnoteVoucherList.map((bill: any) => {
        //   const match = referenceMap.get(bill.id);
        //   const pendingAmountFullPaid = bill.final_pending_amount;
        //   if (match && match?.refernace_name === bill.bill_no_string) {
        //     if (!pendingAmountFullPaid) {
        //       return {
        //         ...bill,
        //         referance_amount: match.referance_amount,
        //         isfullpayment: true,
        //       };
        //     } else {
        //       return {
        //         ...bill,
        //         referance_amount: match.referance_amount,
        //         isfullpayment: false,
        //       };
        //     }
        //   }
        //   return { ...bill }; // Return the bill unchanged if no match
        // });

        // Merge with OutstandingDebitnoteVoucherList
        const mergedArray = OutstandingDebitnoteVoucherList.map((bill: any) => {
          const match = referenceMap.get(bill.id);
          const isFullPayment = !bill.final_pending_amount;
          return match && match.refernace_name === bill.bill_no_string
            ? {
                ...bill,
                referance_amount: match.referance_amount,
                isfullpayment: isFullPayment,
              }
            : { ...bill }; // Return unchanged if no match
        });
        formRenderProps.onChange("debit_note_voucher_bill_entry", {
          value: mergedArray || [],
        });
      } else {
        formRenderProps.onChange("debit_note_voucher_bill_entry", {
          value: entryReference || [],
        });
      }
    } else {
      // Use structuredClone for deep copy (modern alternative to JSON trick)
      const clonedOutstandingList = structuredClone(
        OutstandingDebitnoteVoucherList
      );
      formRenderProps.onChange("debit_note_voucher_bill_entry", {
        value: clonedOutstandingList,
      });
    }
  }, [
    DebitnoteVoucherBillDialogOpenIndex,
    OutstandingDebitnoteVoucherList?.[0]?.id,
  ]);

  useEffect(() => {
    const calculateTotals = (entries: typeof debit_note_voucher_entry) => {
      const totals: Record<string, number> = {};
      entries.forEach((entry: any) => {
        if (entry?.ledger_id) {
          const key = `${entry.debit_credit}Total`;
          totals[key] = (totals[key] || 0) + entry.amount;
        }
      });
      return totals;
    };
    const result = calculateTotals(debit_note_voucher_entry);
    formRenderProps.onChange(`debit_note_voucher_total_amount`, {
      value: result.CreditTotal || 0,
    });
    if (debit_note_voucher_entry && debit_note_voucher_entry?.length > 0) {
      formRenderProps.onChange(`credit_debit_total`, {
        value: {
          DebitTotal: result?.DebitTotal || 0,
          CreditTotal: result?.CreditTotal || 0,
        },
      });
    }
  }, [
    debit_note_voucher_entry?.map((item: any) => item?.amount).join("-"),
    debit_note_voucher_entry?.map((item: any) => item?.debit_credit).join("-"),
    debit_note_voucher_entry?.map((item: any) => item?.ledger_id).join("-"),
  ]);

  useEffect(() => {
    if (DebitnoteVoucherBillDialogOpenIndex < 0) return;
    const selectedDebitnoteVoucherEntry =
      debit_note_voucher_entry[DebitnoteVoucherBillDialogOpenIndex];
    if (!selectedDebitnoteVoucherEntry) return;
    const totalReferencedAmount = debit_note_voucher_bill_entry.reduce(
      (sum: number, item: any) => sum + (item?.referance_amount || 0),
      0
    );

    const remainingAmount =
      (selectedDebitnoteVoucherEntry.amount ?? 0) - totalReferencedAmount;

    const ledgerName = selectedDebitnoteVoucherEntry.ledger_options?.find(
      (account: any) => account?.id === selectedDebitnoteVoucherEntry.ledger_id
    )?.account_name;
    const updatedDebitnoteVoucherEntry = {
      ...selectedDebitnoteVoucherEntry,
      debit_note_voucher_entry_referance: [...debit_note_voucher_bill_entry],
    };

    const updatedIndexDetails = {
      ...updatedDebitnoteVoucherEntry,
      remainingAmount,
      ledger_name: ledgerName,
    };
    formRenderProps.onChange("IndexDetails", { value: updatedIndexDetails });

    debit_note_voucher_entry[DebitnoteVoucherBillDialogOpenIndex] =
      updatedDebitnoteVoucherEntry;
  }, [
    DebitnoteVoucherBillDialogOpenIndex,
    debit_note_voucher_entry?.map((item: any) => item?.amount).join("-"),
    debit_note_voucher_bill_entry
      ?.map((item: any) => item?.referance_amount)
      .join("-"),
  ]);

  return null;
};

const DebitnoteVoucherNoChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const debit_note_voucher_guid = location.state?.debit_note_voucher_guid;
  // const isDebitnoteVoucherNoRef = useRef(false);
  const voucher_type_id = formRenderProps.valueGetter("voucher_type_id");
  const debit_note_voucher_no_string = formRenderProps.valueGetter(
    "debit_note_voucher_no_string"
  );
  const VoucherTypeList = useAppSelector(
    (state) => state.voucherType.VoucherTypeList
  );
  const DebitnoteVoucherDetail = useAppSelector(
    (state) => state.debitnotevoucher.DebitnoteVoucherDetail
  );

  useEffect(() => {
    const fetchDebitnoteVoucherNo = async () => {
      const payload = {
        financial_year: FINANCIAL_YEAR,
        voucher_type_id: +voucher_type_id,
      };
      const response = await dispatch(
        generateDebitnoteVoucherNoForDebitnoteVoucher(payload)
      );
      if (response.meta.requestStatus === "fulfilled") {
        formRenderProps.onChange("debit_note_voucher_no_string", {
          value: response.payload?.debit_note_voucher_no_string || "",
        });
        formRenderProps.onChange("debit_note_voucher_no", {
          value: response.payload?.debit_note_voucher_no || "",
        });
        const ledgerIDIndex = findIndex(VoucherTypeList, {
          id: voucher_type_id,
        });
        if (ledgerIDIndex > -1) {
          formRenderProps.onChange("ledger_id", {
            value: VoucherTypeList?.[ledgerIDIndex]?.map_ledger_id,
          });
        } else {
          formRenderProps.onChange("ledger_id", {
            value: null,
          });
        }
      }
    };
    if (voucher_type_id && !debit_note_voucher_guid) {
      fetchDebitnoteVoucherNo();
    }
  }, [voucher_type_id]);

  useEffect(() => {
    const fetchDebitnoteVoucherNo = async () => {
      const oldkey = DebitnoteVoucherDetail?.debit_note_voucher_no_string
        ?.split("/")
        ?.slice(0, -1)
        .join("/");
      if (
        debit_note_voucher_no_string?.split("/").length ===
        DebitnoteVoucherDetail?.debit_note_voucher_no_string?.split("/").length
      ) {
        const modifiyed = debit_note_voucher_no_string?.split("/")?.pop();
        formRenderProps.onChange("debit_note_voucher_no_string", {
          value: `${oldkey}/${modifiyed}`,
        });
        formRenderProps.onChange("debit_note_voucher_no", {
          value: `${modifiyed}`,
        });
      } else {
        formRenderProps.onChange("debit_note_voucher_no_string", {
          value: `${oldkey}/`,
        });
      }
    };
    if (debit_note_voucher_guid) {
      fetchDebitnoteVoucherNo();
    }
  }, [debit_note_voucher_no_string]);

  return null;
};

const CreateDebitnoteVoucher = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const debit_note_voucher_guid = location.state?.debit_note_voucher_guid;
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.debitnotevoucher.loading);
  const DebitnoteVoucherDetail = useAppSelector(
    (state) => state.debitnotevoucher.DebitnoteVoucherDetail
  );

  const VoucherTypeList = useAppSelector(
    (state) => state.voucherType.VoucherTypeList
  );

  const [formKey, setFormKey] = React.useState(1);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [DebitnoteVoucherDetail]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" || event.key === "Esc") {
        dispatch(setDebitnoteVoucherBillDialogOpenIndex(-1));
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (debit_note_voucher_guid) {
      // const payload = {
      //   debit_note_voucher_guid: debit_note_voucher_guid,
      // };
      dispatch(getDebitnoteVoucherByID(debit_note_voucher_guid));
    }
  }, [debit_note_voucher_guid]);

  useEffect(() => {
    // const payload = {
    //   account_name: "",
    //   under_group_id: "16,17,18",
    // };

    // dispatch(getAllDebitnoteVoucherAndReceipt(payload));
    dispatch(findAllVoucherTypeByType(8));

    return () => {
      dispatch(clearDebitnoteVoucherDetails());
    };
  }, []);

  const handleVendorSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllAccountIncremental(search));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = async (values: any) => {
    if (
      values?.credit_debit_total?.DebitTotal === 0 &&
      values?.credit_debit_total?.CreditTotal === 0
    ) {
      ErrorToast("Atleast One Entry is Required!");
      return;
    }
    if (values?.debit_note_voucher_no_string?.endsWith("/")) {
      ErrorToast("Please Enter Voucher Number.");
      return;
    }
    if (
      values?.credit_debit_total?.DebitTotal !==
      values?.credit_debit_total?.CreditTotal
    ) {
      if (
        values?.credit_debit_total?.DebitTotal >
        values?.credit_debit_total?.CreditTotal
      ) {
        ErrorToast("Debit Amount is Greater than Credit Amount");
      } else {
        ErrorToast("Credit Amount is Greater than Debit Amount");
      }
      return;
    }
    const formdata: any = {
      financial_year: values?.financial_year
        ? +values?.financial_year
        : FINANCIAL_YEAR,
      debit_note_voucher_date: values?.debit_note_voucher_date
        ? moment(values?.debit_note_voucher_date).format("YYYY-MM-DD")
        : "",
      debit_note_voucher_total_amount: values?.debit_note_voucher_total_amount,
      remarks: values?.remarks,
      //   ledger_id:values?.ledger_id ? values?.ledger_id : null,
      debit_note_voucher_no: values?.debit_note_voucher_no
        ? values?.debit_note_voucher_no
        : null,
      voucher_type_id: values?.voucher_type_id ? values?.voucher_type_id : null,
      debit_note_voucher_entry: values?.debit_note_voucher_entry
        ?.filter((entry: any) => entry?.ledger_id)
        ?.map((entry: any) => ({
          debit_credit: entry?.debit_credit,
          ledger_id: entry?.ledger_id,
          amount: entry?.amount,
          debit_note_voucher_entry_referance:
            entry?.debit_note_voucher_entry_referance
              ?.filter((ref: any) => ref?.referance_amount)
              ?.map((pay_ref: any, ind: number) => {
                if (pay_ref?.id) {
                  return {
                    sr_no: pay_ref?.sr_no ? pay_ref?.sr_no : ind + 1,
                    referance_type: entry?.debit_credit,
                    refernace_name: pay_ref?.bill_no_string,
                    refernace_bill_id: pay_ref?.id,
                    referance_amount: pay_ref?.referance_amount,
                  };
                }
                return pay_ref;
              }),
        })),
    };

    if (debit_note_voucher_guid) {
      try {
        const updatePayload: any = {
          debit_note_voucher_guid: debit_note_voucher_guid,
          id: values?.id ? +values?.id : null,
          debit_note_voucher_no: values?.debit_note_voucher_no
            ? values?.debit_note_voucher_no
            : null,
          debit_note_voucher_no_string: values?.debit_note_voucher_no_string
            ? values?.debit_note_voucher_no_string
            : "",
          ...formdata,
        };
        const response = await dispatch(updateDebitnoteVoucher(updatePayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/debitnotevoucher");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      try {
        const response = await dispatch(createDebitnoteVoucher(formdata));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/debitnotevoucher");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={DebitnoteVoucherDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>
                    {debit_note_voucher_guid
                      ? "Update Debit Note Voucher"
                      : "Add Debit Note Voucher"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    label="Voucher Type"
                    name="voucher_type_id"
                    placeholder="Select Voucher Type"
                    disabled={debit_note_voucher_guid && true}
                    component={FormSelectionField}
                    options={VoucherTypeList.map((voucher: any) => {
                      return {
                        value: voucher?.id,
                        label: voucher?.name,
                      };
                    })}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="debit_note_voucher_no_string"
                    label="Voucher No"
                    disabled={debit_note_voucher_guid ? false : true}
                    placeholder="Voucher No"
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>

                <GridLayoutItem>
                  <Field
                    name="debit_note_voucher_date"
                    label="Voucher Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <DebitnoteVoucherNoChangeWatcher
                  formRenderProps={formRenderProps}
                />
                <BillDebitnoteVoucherEntryChangeWatcher
                  formRenderProps={formRenderProps}
                />
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>Particulars</Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem colSpan={4}>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    handleVendorSearchChange={handleVendorSearchChange}
                    component={DebitnoteVoucherEntryDetailsArray}
                    name="debit_note_voucher_entry"
                  />
                  {dialogName === "DebitnoteVoucherBillItemDetailsDialog" && (
                    <FieldArray
                      formRenderProps={formRenderProps}
                      component={DebitnoteVoucherBillDetailsArray}
                      name="debit_note_voucher_bill_entry"
                    />
                  )}
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="remarks"
                    label="Remarks"
                    rows={2}
                    component={FormTextArea}
                    // validator={requiredValidator}
                    // astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={4}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    marginTop: 15,
                  }}
                >
                  <div>
                    <ButtonWithLoading
                      label={debit_note_voucher_guid ? "Update" : "Save"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                    <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginLeft: 4 }}
                      onClick={
                        () => navigate("/debitnotevoucher")
                        // navigate(-1)
                      }
                    >
                      Cancel
                    </Button>
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateDebitnoteVoucher;
