import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import moment from "moment";

export const getAllCreditnoteVoucher = createAsyncThunk(
  "CreditNoteVoucher/CreditNoteVoucherFindAll",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/CreditNoteVoucher/CreditNoteVoucherFindAll`
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error While Fetch Credit Note Voucher:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createCreditnoteVoucher = createAsyncThunk(
  "CreditNoteVoucher/InsertCreditNoteVoucher",
  async (fomedata: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/CreditNoteVoucher/InsertCreditNoteVoucher`,
        fomedata
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating Credit Note Voucher :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateCreditnoteVoucher = createAsyncThunk(
  "CreditNoteVoucher/UpdateCreditNoteVoucher",
  async (formdata: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/CreditNoteVoucher/UpdateCreditNoteVoucher`,
        formdata
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Credit Note Voucher :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteCreditnoteVoucher = createAsyncThunk(
  "CreditNoteVoucher/DeleteCreditNoteVoucher",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/CreditNoteVoucher/DeleteCreditNoteVoucher`,
        { credit_note_voucher_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Credit Note Voucher :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getCreditnoteVoucherByID = createAsyncThunk(
  "CreditNoteVoucher/FindByIDCreditNoteVoucher",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/CreditNoteVoucher/FindByIDCreditNoteVoucher`,
        { credit_note_voucher_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        id: result?.id,
        credit_note_voucher_guid: result?.credit_note_voucher_guid,
        credit_note_voucher_no: result?.credit_note_voucher_no,
        credit_note_voucher_no_string: result?.credit_note_voucher_no_string,
        financial_year: result?.financial_year,
        credit_note_voucher_date: result?.credit_note_voucher_date
          ? moment(result?.credit_note_voucher_date, "YYYY-MM-DD").toDate()
          : "",
        credit_note_voucher_total_amount:
          result?.credit_note_voucher_total_amount,
        remarks: result?.remarks,
        ledger_id: result?.ledger_id,
        voucher_type_id: result?.voucher_type_id,
        credit_note_voucher_entry: result?.credit_note_voucher_entry,
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Credit Note Voucher details:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllOutstandingCreditnoteVoucher = createAsyncThunk(
  "Payment/OutstandingcreditDebitVoucherFindAll",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Payment/OutstandingcreditDebitVoucherFindAll`,
        formData
      );
      const result = response.data?.Data as any;
      return result as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Outstanding Credit Note details:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const generateCreditnoteVoucherNoForCreditnoteVoucher = createAsyncThunk(
  "CreditNoteVoucher/InvoiceNumberCreditNoteVoucher",
  async (payload: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/CreditNoteVoucher/InvoiceNumberCreditNoteVoucher`,
        payload
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error generating Credit Note Voucher no. :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
