import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IVoucherType, IVoucherTypeState } from "./voucherTypeModel";
import {
  createVoucherType,
  deleteVoucherType,
  getAllVoucherTypes,
  getVoucherTypeByID,
  updateVoucherType,
  voucherTypeActiveInactive,
  findAllVoucherTypeByType,
} from "./services/voucherType.services";

const initialState: IVoucherTypeState = {
  loading: false,
  formLoading: false,
  error: "",
  VoucherTypeList: [],
  VoucherTypeID: "",
  VoucherTypeDetail: {
    type: "",
    name: "",
    prefix_no: "",
    map_ledger_id: null,
    isactive: 1,
    // invoice_no_sequence: false,
  },
};

const voucherTypeSlice = createSlice({
  name: "voucherType",
  initialState,
  reducers: {
    clearVoucherTypeDetails: (state) => {
      state.VoucherTypeDetail = initialState.VoucherTypeDetail;
    },
    setVoucherTypeID: (state, action) => {
      state.VoucherTypeID = action.payload;
    },
    clearVoucherTypeID: (state) => {
      state.VoucherTypeID = initialState.VoucherTypeID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllVoucherTypes.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllVoucherTypes.fulfilled,
      (state, action: PayloadAction<IVoucherType[]>) => {
        state.loading = false;
        state.VoucherTypeList = action.payload || [];
      }
    );
    builder.addCase(getAllVoucherTypes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.VoucherTypeList = [];
    });
    builder.addCase(createVoucherType.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createVoucherType.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createVoucherType.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateVoucherType.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateVoucherType.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateVoucherType.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteVoucherType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteVoucherType.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteVoucherType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getVoucherTypeByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getVoucherTypeByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.VoucherTypeDetail = action.payload;
    });
    builder.addCase(getVoucherTypeByID.rejected, (state, action) => {
      state.formLoading = false;
      state.VoucherTypeDetail = initialState.VoucherTypeDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(voucherTypeActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(voucherTypeActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(voucherTypeActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(findAllVoucherTypeByType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllVoucherTypeByType.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.loading = false;
        state.VoucherTypeList = action.payload || [];
      }
    );
    builder.addCase(findAllVoucherTypeByType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.VoucherTypeList = [];
    });
  },
});

export const { clearVoucherTypeDetails, setVoucherTypeID, clearVoucherTypeID } =
  voucherTypeSlice.actions;
export default voucherTypeSlice.reducer;
