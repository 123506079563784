import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { convertToWords, formatIndianNumber } from "../../_helper/helper";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getOrderByID } from "./services/order.services";
import { clearOrderDetails } from "./orderSlice";
import { LoadingPanel } from "../../components/layout/Loading";
import moment from "moment";

const OrderView: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const gridRef = useRef<any>(null);
  const id = location.state?.id;
  const StateIDString = useAppSelector(
    (state) => state.login.loginDetails.StateID
  );
  const StateID = parseInt(StateIDString);

  const loading = useAppSelector((state) => state.order.loading);
  const OrderDetail = useAppSelector((state) => state.order.OrderDetail);

  useEffect(() => {
    dispatch(clearOrderDetails());
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getOrderByID(+id));
    }
  }, [id]);

  const handlePrint = () => {
    window.print();
  };

  let totalAmount = 0;
  let totalAmountGST5 = 0;
  let totalAmountGST12 = 0;
  let totalAmountGST18 = 0;
  let totalAmountGST28 = 0;
  let totalGST5 = 0;
  let totalGST12 = 0;
  let totalGST18 = 0;
  let totalGST28 = 0;

  OrderDetail &&
    OrderDetail?.place_order_items &&
    OrderDetail?.place_order_items?.length > 0 &&
    OrderDetail?.place_order_items?.map(
      (salesorderitem: any) =>
        salesorderitem?.item_margin_rate &&
        salesorderitem?.item_margin_rate.length > 0 &&
        salesorderitem?.item_margin_rate?.map((item: any) => {
          totalAmount += item?.amount;
          if (item?.gst === 5) {
            totalGST5 += (item?.amount * item?.gst) / 100;
            totalAmountGST5 += item?.amount;
          }
          if (item?.gst === 12) {
            totalGST12 += (item?.amount * item?.gst) / 100;
            totalAmountGST12 += item?.amount;
          }
          if (item?.gst === 18) {
            totalGST18 += (item?.amount * item?.gst) / 100;
            totalAmountGST18 += item?.amount;
          }
          if (item?.gst === 28) {
            totalGST28 += (item?.amount * item?.gst) / 100;
            totalAmountGST28 += item?.amount;
          }
        })
    );

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      <div className="order-container" style={{ padding: 12, minWidth: 800 }}>
        <div className="row">
          <div className="col-12">
            <Typography.h4 className="text-center">Place Order</Typography.h4>
          </div>
          <div className="col-md-6 mb-3">
            <table>
              <tr>
                <th style={{ width: 150 }}>Name</th>
                <td style={{ width: 30 }}>:</td>
                <td>{OrderDetail?.dealer_name}</td>
              </tr>
              <tr>
                <th>Order No.</th>
                <td>:</td>
                <td>{OrderDetail?.sales_order_no}</td>
              </tr>
              <tr>
                <th>Order Date</th>
                <td>:</td>
                <td>
                  {" "}
                  {OrderDetail?.sales_date
                    ? moment(OrderDetail?.sales_date, "YYYY-MM-DD")?.format(
                        "DD/MM/YYYY"
                      )
                    : ""}
                </td>
              </tr>
            </table>
            {/* <p className="m-0">Name :</p>
            <p className="m-0 ">
              Order No. : <span>{OrderDetail?.sales_order_no}</span>
            </p>
            <p className="m-0">
              Order Date :{" "}
              <span>
                {OrderDetail?.sales_date
                  ? moment(OrderDetail?.sales_date, "YYYY-MM-DD")?.format(
                      "DD/MM/YYYY"
                    )
                  : ""}
              </span>
            </p> */}
          </div>
          <div className="col-12 order-print-page">
            <table
              cellSpacing={1}
              cellPadding={2}
              className="table table-bordered w-100  order-print-table mb-0"
              style={{ marginTop: 1 }}
            >
              <tr className="text-center fw-600" style={{ fontSize: 12 }}>
                <th className="order-print-th" style={{ width: "10%" }}>
                  Sr No.
                </th>
                <th className="order-print-th" style={{ width: "40%" }}>
                  Description Of Goods
                </th>
                <th className="order-print-th" style={{ width: "10%" }}>
                  MRP
                </th>
                <th className="order-print-th" style={{ width: "10%" }}>
                  Quantity
                </th>
                <th className="order-print-th" style={{ width: "10%" }}>
                  Per
                </th>
                <th className="order-print-th" style={{ width: "10%" }}>
                  Rate
                </th>
                <th className="order-print-th" style={{ width: "10%" }}>
                  Amount
                </th>
              </tr>
              {OrderDetail &&
                OrderDetail?.place_order_items &&
                OrderDetail?.place_order_items?.length > 0 &&
                OrderDetail?.place_order_items?.map((item: any) => (
                  <>
                    <tr>
                      <td
                        className="fw-600"
                        colSpan={7}
                        style={{ fontSize: 12 }}
                      >
                        {item?.item_group}
                      </td>
                    </tr>
                    {item?.item_margin_rate &&
                      item?.item_margin_rate?.length > 0 &&
                      item?.item_margin_rate?.map(
                        (subitem: any, index: number) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{subitem?.product_name}</td>
                            <td>Rs. {subitem?.mrp || 0}</td>
                            <td className="text-end">{subitem?.quantity}</td>
                            <td>{subitem?.unit_name}</td>
                            <td>
                              Rs. {formatIndianNumber(subitem?.rate) || 0}
                            </td>
                            <td className=" text-end fw-500">
                              {formatIndianNumber(subitem?.amount)}
                            </td>
                          </tr>
                        )
                      )}
                  </>
                ))}
              <tr>
                <td colSpan={6}></td>
                <td className="text-end fw-600" style={{ fontSize: 14 }}>
                  {formatIndianNumber(totalAmount)}
                </td>
              </tr>
              {StateID === 1 ? (
                <>
                  {totalGST5 > 0 && (
                    <>
                      <tr>
                        <td></td>
                        <td className=" text-end">CGST 2.50%</td>
                        <td></td>
                        <td></td>
                        <td className=" text-end">2.50</td>
                        <td>%</td>
                        <td className=" text-end fw-500">
                          {formatIndianNumber(+totalGST5 / 2)}
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td className=" text-end">SGST 2.50%</td>
                        <td></td>
                        <td></td>
                        <td className=" text-end">2.50</td>
                        <td>%</td>
                        <td className=" text-end fw-500">
                          {formatIndianNumber(+totalGST5 / 2)}
                        </td>
                      </tr>
                    </>
                  )}
                  {totalGST12 > 0 && (
                    <>
                      <tr>
                        <td></td>
                        <td className=" text-end">CGST 6.00%</td>
                        <td></td>
                        <td></td>
                        <td className=" text-end">6.00</td>
                        <td>%</td>
                        <td className=" text-end fw-500">
                          {formatIndianNumber(+totalGST12 / 2)}
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td className=" text-end">SGST 6.00%</td>
                        <td></td>
                        <td></td>
                        <td className=" text-end">6.00</td>
                        <td>%</td>
                        <td className=" text-end fw-500">
                          {formatIndianNumber(+totalGST12 / 2)}
                        </td>
                      </tr>
                    </>
                  )}
                  {totalGST18 > 0 && (
                    <>
                      <tr>
                        <td></td>
                        <td className=" text-end">CGST 9.00%</td>
                        <td></td>
                        <td></td>
                        <td className=" text-end">9.00</td>
                        <td>%</td>
                        <td className=" text-end fw-500">
                          {formatIndianNumber(+totalGST18 / 2)}
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td className=" text-end">SGST 9.00%</td>
                        <td></td>
                        <td></td>
                        <td className=" text-end">9.00</td>
                        <td>%</td>
                        <td className=" text-end fw-500">
                          {formatIndianNumber(+totalGST18 / 2)}
                        </td>
                      </tr>
                    </>
                  )}
                  {totalGST28 > 0 && (
                    <>
                      <tr>
                        <td></td>
                        <td className=" text-end">CGST 14.00%</td>
                        <td></td>
                        <td></td>
                        <td className=" text-end">14.00</td>
                        <td>%</td>
                        <td className=" text-end fw-500">
                          {formatIndianNumber(+totalGST28 / 2)}
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td className=" text-end">SGST 14.00%</td>
                        <td></td>
                        <td></td>
                        <td className=" text-end">14.00</td>
                        <td>%</td>
                        <td className=" text-end fw-500">
                          {formatIndianNumber(+totalGST28 / 2)}
                        </td>
                      </tr>
                    </>
                  )}
                </>
              ) : (
                <>
                  {totalGST5 > 0 && (
                    <tr>
                      <td></td>
                      <td className=" text-end">IGST 5.00%</td>
                      <td></td>
                      <td></td>
                      <td className=" text-end">5.00</td>
                      <td>%</td>
                      <td className=" text-end fw-500">
                        {formatIndianNumber(+totalGST5)}
                      </td>
                    </tr>
                  )}
                  {totalGST12 > 0 && (
                    <tr>
                      <td></td>
                      <td className=" text-end">IGST 12.00%</td>
                      <td></td>
                      <td></td>
                      <td className=" text-end">12.00</td>
                      <td>%</td>
                      <td className=" text-end fw-500">
                        {formatIndianNumber(+totalGST12)}
                      </td>
                    </tr>
                  )}
                  {totalGST18 > 0 && (
                    <tr>
                      <td></td>
                      <td className=" text-end">IGST 18.00%</td>
                      <td></td>
                      <td></td>
                      <td className=" text-end">18.00</td>
                      <td>%</td>
                      <td className=" text-end fw-500">
                        {formatIndianNumber(+totalGST18)}
                      </td>
                    </tr>
                  )}
                  {totalGST28 > 0 && (
                    <tr>
                      <td></td>
                      <td className=" text-end">IGST 28.00%</td>
                      <td></td>
                      <td></td>
                      <td className=" text-end">28.00</td>
                      <td>%</td>
                      <td className=" text-end fw-500">
                        {formatIndianNumber(+totalGST28)}
                      </td>
                    </tr>
                  )}
                </>
              )}
              <tr style={{ fontSize: 12 }}>
                <td
                  colSpan={6}
                  className=" text-end fw-600"
                  style={{ fontSize: 14 }}
                >
                  {" "}
                  Total Invoice Amount:
                </td>
                <td className="text-end fw-600" style={{ fontSize: 14 }}>
                  {formatIndianNumber(
                    Math.round(
                      +totalAmount +
                        +totalGST5 +
                        +totalGST12 +
                        +totalGST18 +
                        +totalGST28
                    )
                  )}
                </td>
              </tr>
              <tr>
                <td colSpan={6}>
                  <span className="d-block p-0" style={{ fontSize: 8 }}>
                    Amount Chargeable (in words)
                  </span>
                  <span
                    className="d-block fw-600 p-0"
                    style={{ fontSize: 12, textTransform: "uppercase" }}
                  >
                    {convertToWords(
                      Math.round(
                        +totalAmount +
                          +totalGST5 +
                          +totalGST12 +
                          +totalGST18 +
                          +totalGST28
                      )
                    )}
                  </span>
                </td>
                <td className="text-end">E & O.E</td>
              </tr>
              {StateID === 1 ? (
                <>
                  <tr className="text-center fw-600" style={{ fontSize: 13 }}>
                    <th rowSpan={2}>Taxable Value</th>
                    <th colSpan={2}>Central Tax</th>
                    <th colSpan={2}>State Tax</th>
                    <th rowSpan={2} colSpan={2}>
                      Total Tax Amount
                    </th>
                  </tr>
                  <tr className="text-center" style={{ fontSize: 13 }}>
                    <th>Rate</th>
                    <th>Amount</th>
                    <th>Rate</th>
                    <th>Amount</th>
                  </tr>
                </>
              ) : (
                <>
                  <tr className="text-center" style={{ fontSize: 13 }}>
                    <th rowSpan={2}>Taxable Value</th>
                    <th colSpan={4}>Integrated Tax</th>
                    <th rowSpan={2} colSpan={2}>
                      Total Tax Amount
                    </th>
                  </tr>
                  <tr className="text-center" style={{ fontSize: 13 }}>
                    <th>Rate</th>
                    <th colSpan={3}>Amount</th>
                  </tr>
                </>
              )}
              {StateID === 1 ? (
                <>
                  {totalGST5 > 0 && (
                    <tr className="text-end">
                      <td>{formatIndianNumber(totalAmountGST5)}</td>
                      <td>2.50 %</td>
                      <td className=" ">
                        {formatIndianNumber(+totalGST5 / 2)}
                      </td>
                      <td>2.50 %</td>
                      <td>{formatIndianNumber(+totalGST5 / 2)}</td>
                      <td colSpan={2}>{formatIndianNumber(+totalGST5)}</td>
                    </tr>
                  )}
                  {totalGST12 > 0 && (
                    <tr className="text-end">
                      <td>{formatIndianNumber(totalAmountGST12)}</td>
                      <td>6.00 %</td>
                      <td className=" ">
                        {formatIndianNumber(+totalGST12 / 2)}
                      </td>
                      <td>6.00 %</td>
                      <td>{formatIndianNumber(+totalGST12 / 2)}</td>
                      <td colSpan={2}>{formatIndianNumber(+totalGST12)}</td>
                    </tr>
                  )}
                  {totalGST18 > 0 && (
                    <tr className="text-end">
                      <td>{formatIndianNumber(totalAmountGST18)}</td>
                      <td>9.00 %</td>
                      <td className=" ">
                        {formatIndianNumber(+totalGST18 / 2)}
                      </td>
                      <td>9.00 %</td>
                      <td>{formatIndianNumber(+totalGST18 / 2)}</td>
                      <td colSpan={2}>{formatIndianNumber(+totalGST18)}</td>
                    </tr>
                  )}
                  {totalGST28 > 0 && (
                    <tr className="text-end">
                      <td>{formatIndianNumber(totalAmountGST28)}</td>
                      <td>14.00 %</td>
                      <td className=" ">
                        {formatIndianNumber(+totalGST28 / 2)}
                      </td>
                      <td>14.00 %</td>
                      <td>{formatIndianNumber(+totalGST28 / 2)}</td>
                      <td colSpan={2}>{formatIndianNumber(+totalGST28)}</td>
                    </tr>
                  )}
                </>
              ) : (
                <>
                  {totalGST5 && (
                    <tr className="text-end">
                      <td>{formatIndianNumber(totalAmountGST5)}</td>
                      <td>5.00 %</td>
                      <td colSpan={3}>{formatIndianNumber(+totalGST5)}</td>
                      <td colSpan={2}>{formatIndianNumber(+totalGST5)}</td>
                    </tr>
                  )}
                  {totalGST12 && (
                    <tr className="text-end">
                      <td>{formatIndianNumber(totalAmountGST12)}</td>
                      <td>12.00 %</td>
                      <td colSpan={3}>{formatIndianNumber(+totalGST12)}</td>
                      <td colSpan={2}>{formatIndianNumber(+totalGST12)}</td>
                    </tr>
                  )}
                  {totalGST18 && (
                    <tr className="text-end">
                      <td>{formatIndianNumber(totalAmountGST18)}</td>
                      <td>18.00 %</td>
                      <td colSpan={3}>{formatIndianNumber(+totalGST18)}</td>
                      <td colSpan={2}>{formatIndianNumber(+totalGST18)}</td>
                    </tr>
                  )}
                  {totalGST28 && (
                    <tr className="text-end">
                      <td>{formatIndianNumber(totalAmountGST28)}</td>
                      <td>28.00 %</td>
                      <td colSpan={3}>{formatIndianNumber(+totalGST28)}</td>
                      <td colSpan={2}>{formatIndianNumber(+totalGST28)}</td>
                    </tr>
                  )}
                </>
              )}
              {StateID === 1 ? (
                <tr className="text-end fw-600" style={{ fontSize: 13 }}>
                  <td>{formatIndianNumber(totalAmount)}</td>
                  <td></td>
                  <td>
                    {formatIndianNumber(
                      +(
                        (+totalGST5 + +totalGST12 + +totalGST18 + +totalGST28) /
                        2
                      )?.toFixed(2)
                    )}
                  </td>
                  <td></td>
                  <td>
                    {formatIndianNumber(
                      +(
                        (+totalGST5 + +totalGST12 + +totalGST18 + +totalGST28) /
                        2
                      )?.toFixed(2)
                    )}
                  </td>
                  <td colSpan={2}>
                    {formatIndianNumber(
                      +(
                        +totalGST5 +
                        +totalGST12 +
                        +totalGST18 +
                        +totalGST28
                      )?.toFixed(2)
                    )}
                  </td>
                </tr>
              ) : (
                <tr className="text-end fw-600" style={{ fontSize: 13 }}>
                  <td>{formatIndianNumber(totalAmount)}</td>
                  <td></td>
                  <td colSpan={3}>
                    {formatIndianNumber(
                      +(
                        +totalGST5 +
                        +totalGST12 +
                        +totalGST18 +
                        +totalGST28
                      )?.toFixed(2)
                    )}
                  </td>
                  <td colSpan={2}>
                    {formatIndianNumber(
                      +(
                        +totalGST5 +
                        +totalGST12 +
                        +totalGST18 +
                        +totalGST28
                      )?.toFixed(2)
                    )}
                  </td>
                </tr>
              )}
              <tr>
                <td colSpan={7} style={{ textAlign: "end" }}>
                  <span className="d-block p-0" style={{ fontSize: 8 }}>
                    Tax Amount (in words)
                  </span>
                  <span
                    className="d-block fw-600 p-0"
                    style={{ fontSize: 12, textTransform: "uppercase" }}
                  >
                    {convertToWords(
                      +(
                        +totalGST5 +
                        +totalGST12 +
                        +totalGST18 +
                        +totalGST28
                      )?.toFixed(2)
                    )}
                  </span>
                </td>
              </tr>
            </table>
          </div>
          <div className="col-12 mt-3" style={{ display: "flex" }}>
            <p
              style={{
                fontWeight: "600",
                fontSize: "14px",
                marginBottom: "2px",
              }}
            >
              {OrderDetail?.free_scheme
                ? "Free Scheme"
                : OrderDetail?.card_scheme
                ? "Card Scheme"
                : ""}
            </p>
            {(OrderDetail?.card_scheme || OrderDetail?.free_scheme) &&
              OrderDetail?.scheme?.length > 0 && (
                <table
                  className="table table-bordered"
                  style={{
                    width: "30%",
                    border: "1px solid rgba(0, 0, 0, 0.5)",
                    marginLeft: "auto",
                  }}
                >
                  <tr className="text-center fw-600">
                    <td style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}>
                      Free Goods
                    </td>
                    <td style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}>
                      Article
                    </td>
                  </tr>
                  {OrderDetail?.scheme?.map((scheme: any, index: number) => (
                    <tr key={index}>
                      <td
                        style={{
                          width: "50%",
                          border: "1px solid rgba(0, 0, 0, 0.5)",
                          padding: "2px 5px",
                        }}
                      >
                        {scheme?.free_good}
                      </td>
                      <td
                        style={{
                          border: "1px solid rgba(0, 0, 0, 0.5)",
                          padding: "2px 5px",
                        }}
                      >
                        {scheme?.article}
                      </td>
                    </tr>
                  ))}
                </table>
              )}
          </div>
          <div
            className="col-12 no-visible-print-btn"
            style={{
              textAlign: "end",
              marginTop: "20px",
              width: "100%",
            }}
          >
            <Button
              type="button"
              fillMode={"solid"}
              themeColor={"primary"}
              style={{ marginRight: 10 }}
              onClick={handlePrint}
            >
              Print
            </Button>
            <Button
              type="button"
              fillMode={"solid"}
              themeColor={"primary"}
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderView;
