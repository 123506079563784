import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { LoadingPanel } from "../../components/layout/Loading";
import IconButton from "../../components/common/IconButton";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { closed } from "../../components/muidrawer/muiDrawerSlice";
import { checkAcessRights, formatIndianNumber } from "../../_helper/helper";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  //   INSERT_ACCESS,
} from "../../_contstants/common";
import { setCurrentPage, setPageLimit } from "../pagination/paginationSlice";

import { useNavigate } from "react-router-dom";
import _debounce from "lodash/debounce";
import { TextBox } from "@progress/kendo-react-inputs";
import { filterClearIcon } from "@progress/kendo-svg-icons";
import {
  clearFilterCustomExpenseApproved,
  setFilterCustomExpenseApproved,
} from "./expenseApprovedSlice";
import {
  getAllSRExpensesForVerify,
  getExpenseDetailsByID,
} from "./services/expenseApproved.services";
import { FiEdit } from "react-icons/fi";
import { FaDotCircle, FaEye } from "react-icons/fa";
import AppDialog from "../../components/dialog/Dialog";
import { RxCross2 } from "react-icons/rx";

const ExpenseApproved: React.FC = () => {
  const dispatch = useAppDispatch();
  //   const navigate = useNavigate();
  //   const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);
  useEffect(() => {
    return () => {
      dispatch(closeDialog());
      dispatch(closed());
      dispatch(setCurrentPage(0));
      dispatch(clearFilterCustomExpenseApproved());
    };
  }, []);

  //   const handleCreate = () => {
  //     navigate("/expense/create");
  //   };

  return (
    <>
      {/* <DeleteSRExpenseDialog /> */}
      <ViewExpenseDetailsDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Expense Details List
          </Typography.h4>
          {/* {isAddAccess && (
            <Button
              onClick={handleCreate}
              fillMode={"solid"}
              themeColor={"primary"}
            >
              {"Add New"}
            </Button>
          )} */}
        </GridLayoutItem>
        <GridLayoutItem>
          <SRExpensesGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

const SRExpensesGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);
  const navigate = useNavigate();
  const FilterCustomExpenseApproved: any = useAppSelector(
    (state) => state.expenseApproved.FilterCustomExpenseApproved
  );
  const ExpenseApprovedList = useAppSelector(
    (state) => state.expenseApproved.ExpenseApprovedList
  );
  const loading = useAppSelector((state) => state.expenseApproved.loading);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const [expandedRow, setExpandedRow] = useState(null);
  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);

  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };

  useEffect(() => {
    const fetchItemList = async () => {
      const payload = {
        ...FilterCustomExpenseApproved,
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      await dispatch(getAllSRExpensesForVerify(payload));
    };
    fetchItemList();
  }, [currentPage, pageLimit]);

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEdit = (expense_details_guid: any) => {
      navigate("/srexpense/verify", {
        state: { expense_details_guid: expense_details_guid },
      });
    };
    const handleExpenseDetailsView = (ID: any) => {
      console.log("ID", ID);
      dispatch(openDialog("viewSRExpenseDetails"));
      dispatch(getExpenseDetailsByID(ID)).then((res: any) => {
        if (res.meta?.requestStatus === "fulfilled") {
          dispatch(openDialog("viewSRExpenseDetails"));
        }
      });
    };
    // const handleOpenDeleteDialog = (expense_details_guid: string) => {
    //   dispatch(openDialog("deleteSRExpenseApprove"));
    //   dispatch(setExpenseDetailsGUID(expense_details_guid));
    // };

    return (
      <td
        className={`action-td ${props.className}`}
        style={{
          ...props.style,
        }}
      >
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Verify"
            onClick={() => {
              handleEdit(props.dataItem?.expense_details_guid);
            }}
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {/* {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() =>
              handleOpenDeleteDialog(props.dataItem.expense_details_guid)
            }
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )} */}
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="View"
          onClick={() =>
            handleExpenseDetailsView(props.dataItem?.expense_details_guid)
          }
        >
          <FaEye className="absolute-position" style={{ fontSize: "20px" }} />
        </IconButton>
      </td>
    );
  };

  const truncateRemarks = (remarks: string) => {
    if (!remarks) return "";
    const maxLength = 100;
    if (remarks.length <= maxLength) return remarks;
    return remarks.substring(0, maxLength) + "...";
  };

  const handleExpenseDetailsView = (ID: any) => {
    dispatch(openDialog("viewSRExpenseDetails"));
    dispatch(getExpenseDetailsByID(ID)).then((res: any) => {
      if (res.meta?.requestStatus === "fulfilled") {
        dispatch(openDialog("viewSRExpenseDetails"));
      }
    });
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 10 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  //   if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      <KendoGrid
        style={{ maxHeight: "calc(100vh - 152px)" }}
        className="responsive-table"
        filterable={true}
        filter={filter}
        data={
          filter ? filterBy(ExpenseApprovedList, filter) : ExpenseApprovedList
        }
        onFilterChange={handleFilterChange}
        total={ExpenseApprovedList[0]?.itemcount || ExpenseApprovedList?.length}
        skip={currentPage * pageLimit}
        take={pageLimit}
        pageable={{
          buttonCount: 5,
          pageSizes: [10, 50, 100, 300, 500],
          pageSizeValue: pageLimit,
          type: "input",
        }}
        onPageChange={pageChange}
        cells={{
          headerCell: HeaderCustomCell,
          data: MyDataCustomCell,
        }}
      >
        <Column
          field="verification_status"
          title="Verified Status"
          filterable={false}
          cell={(props: any) => (
            <td className="text-end">
              {props.dataItem?.verification_status ? (
                <IconButton
                  type="button"
                  fillMode="flat"
                  size="small"
                  title="Verified"
                  onClick={() =>
                    handleExpenseDetailsView(
                      props.dataItem?.expense_details_guid
                    )
                  }
                >
                  <FaDotCircle
                    className="absolute-position"
                    style={{ fontSize: "20px", color: "green" }}
                  />
                </IconButton>
              ) : (
                <IconButton
                  type="button"
                  fillMode="flat"
                  size="small"
                  title="Pending"
                  onClick={() =>
                    navigate("/srexpense/verify", {
                      state: {
                        expense_details_guid:
                          props.dataItem?.expense_details_guid,
                      },
                    })
                  }
                >
                  <FaDotCircle
                    className="absolute-position"
                    style={{ fontSize: "20px", color: "#ff8900" }}
                  />
                </IconButton>
              )}
            </td>
          )}
          width={150}
        />
        <Column
          width={130}
          field="expense_date"
          filterable={false}
          title="Expense Date"
          cell={(props: any) => (
            <td>
              <div
                className={isEditAccess ? "hoverableName" : "normalName"}
                onClick={() =>
                  isEditAccess &&
                  navigate("/srexpense/verify", {
                    state: {
                      expense_details_guid:
                        props.dataItem?.expense_details_guid,
                    },
                  })
                }
              >
                {props.dataItem?.expense_date}
              </div>
            </td>
          )}
        />

        <Column
          width={130}
          field="expense_date"
          filterable={false}
          title="Expense Date"
        />
        <Column
          width={200}
          field="from_city_name"
          filterCell={CommonFilterCell}
          title="From City"
        />
        <Column
          width={200}
          field="to_city_name"
          filterCell={CommonFilterCell}
          title="To City"
        />
        <Column
          field="net_amount"
          title="Net Amount"
          filterable={false}
          cell={(props: any) => (
            <td className="text-end">
              ₹ {formatIndianNumber(props.dataItem?.net_amount || 0)}
            </td>
          )}
          width={180}
        />

        <Column
          field="disallowed_amount"
          title="Disallowed Amount"
          filterable={false}
          cell={(props: any) => (
            <td className="text-end">
              ₹ {formatIndianNumber(props.dataItem?.disallowed_amount || 0)}
            </td>
          )}
          width={180}
        />
        <Column
          field="total_amount"
          title="Total Amount"
          filterable={false}
          cell={(props: any) => (
            <td className="text-end">
              ₹ {formatIndianNumber(props.dataItem?.total_amount || 0)}
            </td>
          )}
          width={180}
        />
        <Column
          field="total_amount"
          title="Total Amount"
          filterable={false}
          cell={(props: any) => (
            <td className="text-end">
              ₹ {formatIndianNumber(props.dataItem?.total_amount || 0)}
            </td>
          )}
          width={180}
        />
        <Column
          width={300}
          filterable={false}
          field="remarks"
          title="Remarks"
          cell={(props: any) => (
            <td>
              <div
                className="remarks-content"
                dangerouslySetInnerHTML={{
                  __html:
                    expandedRow === props.dataItem?.id
                      ? props.dataItem?.remarks
                      : truncateRemarks(props.dataItem?.remarks),
                }}
              />
              {props.dataItem?.remarks.length > 100 && (
                <span
                  className="readmore-btn"
                  onClick={() =>
                    setExpandedRow(
                      expandedRow === props.dataItem?.id
                        ? null
                        : props.dataItem?.id
                    )
                  }
                >
                  {expandedRow === props.dataItem?.id
                    ? "Read Less"
                    : "Read More"}
                </span>
              )}
            </td>
          )}
        />

        {(isEditAccess || isDeleteAccess) && (
          <Column
            field="id"
            title="Actions"
            cell={MyEditCommandCell}
            width={"110px"}
            locked={true}
            filterable={false}
          />
        )}
      </KendoGrid>
    </>
  );
};

// const DeleteSRExpenseDialog: React.FC = () => {
//   const dispatch = useAppDispatch();
//   const ExpenseDetailsGUID = useAppSelector(
//     (state) => state.expensedetails.ExpenseDetailsGUID
//   );
//   const dialogName = useAppSelector((state) => state.dialog.dialogName);
//   const currentPage = useAppSelector((state) => state.pagination.currentPage);
//   const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
//   const FilterCustomExpenseDetails: any = useAppSelector(
//     (state) => state.expensedetails.FilterCustomExpenseDetails
//   );

//   const handleDeleteAction = async (GUID: string) => {
//     if (GUID) {
//       const response = await dispatch(deleteSRExpense(GUID));
//       const payload = {
//         ...FilterCustomExpenseDetails,
//         sr_id: Number(localStorage.getItem("UserID")),
//         limit: +pageLimit,
//         skip: +currentPage * +pageLimit,
//       };
//       if (response.payload.Data.Status === 200) {
//         dispatch(getAllSRExpense(payload));
//         dispatch(closeDialog());
//         dispatch(setExpenseDetailsGUID(""));
//       } else {
//         dispatch(getAllSRExpense(payload));
//         dispatch(closeDialog());
//         dispatch(setExpenseDetailsGUID(""));
//       }
//     }
//   };

//   return (
//     <>
//       {dialogName === "deleteSRExpense" && (
//         <AppDialog>
//           <>
//             <Typography.h5>{"Delete Expense Details?"}</Typography.h5>
//             <GridLayout>
//               <GridLayoutItem>
//                 <Typography.p>
//                   Are you sure you want to delete this Expense Details?
//                 </Typography.p>
//               </GridLayoutItem>
//               <GridLayoutItem
//                 style={{
//                   display: "flex",
//                   justifyContent: "end",
//                 }}
//               >
//                 <DialogCloseButton themeColor="error" />
//                 <Button
//                   fillMode={"solid"}
//                   themeColor={"error"}
//                   onClick={() => handleDeleteAction(ExpenseDetailsGUID)}
//                   type="button"
//                 >
//                   Delete
//                 </Button>
//               </GridLayoutItem>
//             </GridLayout>
//           </>
//         </AppDialog>
//       )}
//     </>
//   );
// };

const ViewExpenseDetailsDialog: React.FC = () => {
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const dispatch = useAppDispatch();

  return (
    <>
      {dialogName === "viewSRExpenseDetails" && (
        <AppDialog>
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
              }}
            >
              <Typography.h5>Expense Details</Typography.h5>
              <RxCross2
                onClick={() => dispatch(closeDialog())}
                style={{ fontSize: "25px", fontWeight: "bolder" }}
              />
            </div>
            <table
              className="table table-bordered"
              style={{ borderColor: "black" }}
            >
              <tbody>
                <tr>
                  <td style={{ backgroundColor: "lightgrey" }}>
                    <strong>Expense Date</strong>
                  </td>
                  <td colSpan={3}>29/09/2024</td>
                </tr>
                <tr>
                  <td style={{ backgroundColor: "lightgrey" }}>
                    <strong>To City</strong>
                  </td>
                  <td>AHMEDABAD</td>
                  <td style={{ backgroundColor: "lightgrey" }}>
                    <strong>From City</strong>
                  </td>
                  <td>PRATIJ</td>
                </tr>
                <tr>
                  <td style={{ backgroundColor: "lightgrey" }}>
                    <strong>Mode Of Transport</strong>
                  </td>
                  <td>Bus</td>
                  <td style={{ backgroundColor: "lightgrey" }}>
                    <strong>Petrol Allowance</strong>
                  </td>
                  <td>0</td>
                </tr>
                <tr>
                  <td rowSpan={2} style={{ backgroundColor: "lightgrey" }}>
                    <strong>Cities Visited</strong>
                  </td>
                  <td rowSpan={2}>AHMEDABAD</td>
                  <td style={{ backgroundColor: "lightgrey" }}>
                    <strong>Fare For Transport</strong>
                  </td>
                  <td>210</td>
                </tr>
                <tr>
                  <td style={{ backgroundColor: "lightgrey" }}>
                    <strong>Sales Amount</strong>
                  </td>
                  <td>0</td>
                </tr>
                <tr>
                  <td style={{ backgroundColor: "lightgrey" }}>
                    <strong>Working agent/Area/Town:</strong>
                  </td>
                  <td colSpan={3}>
                    Office meeting DA lagavavu nahi only 210 rs
                  </td>
                </tr>
              </tbody>
            </table>

            <Typography.h5>Expenses</Typography.h5>
            <table
              className="table table-bordered"
              style={{ borderColor: "black" }}
            >
              <tbody>
                <tr>
                  <td style={{ backgroundColor: "lightgrey" }}>
                    <strong> DA - HEAD QUATER </strong>
                  </td>
                  <td>190</td>
                </tr>
                <tr>
                  <td style={{ backgroundColor: "lightgrey" }}>
                    <strong> NIGHT HALT METRO/CLASS-1 TOWN</strong>
                  </td>
                  <td>0</td>
                </tr>
                <tr>
                  <td style={{ backgroundColor: "lightgrey" }}>
                    <strong> NIGHT HALT OTHER TOWN</strong>
                  </td>
                  <td>0</td>
                </tr>
                <tr>
                  <td style={{ backgroundColor: "lightgrey" }}>
                    <strong> NIGHT JOURNEY</strong>
                  </td>
                  <td>0</td>
                </tr>
                <tr>
                  <td style={{ backgroundColor: "lightgrey" }}>
                    <strong> STATN EXP.</strong>
                  </td>
                  <td>0</td>
                </tr>
                <tr>
                  <td style={{ backgroundColor: "lightgrey" }}>
                    <strong> XERX. EXP.</strong>
                  </td>
                  <td>0</td>
                </tr>
                <tr>
                  <td style={{ backgroundColor: "lightgrey" }}>
                    <strong> MAIL COUR.</strong>
                  </td>
                  <td>0</td>
                </tr>
                <tr>
                  <td style={{ backgroundColor: "lightgrey" }}>
                    <strong> OTH. EXP.</strong>
                  </td>
                  <td>0</td>
                </tr>
              </tbody>
            </table>
            <Typography.h5>Expense Verification</Typography.h5>
            <table
              className="table table-bordered"
              style={{ borderColor: "black" }}
            >
              <tbody>
                <tr>
                  <td style={{ backgroundColor: "lightgrey" }}>
                    <strong>Verified By</strong>
                  </td>
                  <td>KEVAL BHIRUD</td>
                  <td style={{ backgroundColor: "lightgrey" }}>
                    <strong>Net Amount</strong>
                  </td>
                  <td>400</td>
                </tr>
                <tr>
                  <td style={{ backgroundColor: "lightgrey" }}>
                    <strong>Verification Date</strong>
                  </td>
                  <td>07/10/2024</td>
                  <td style={{ backgroundColor: "lightgrey" }}>
                    <strong>Disallowed Amount</strong>
                  </td>
                  <td>190</td>
                </tr>
                <tr>
                  <td style={{ backgroundColor: "lightgrey" }}>
                    <strong>Verification Status</strong>
                  </td>
                  <td className="text-success">
                    <strong>Verified</strong>
                  </td>
                  <td style={{ backgroundColor: "lightgrey" }}>
                    <strong>Additional Amount</strong>
                  </td>
                  <td>0</td>
                </tr>
                <tr>
                  <td style={{ backgroundColor: "lightgrey" }}>
                    <strong>Verification Remarks</strong>
                  </td>
                  <td>
                    DA NOT ALLOWED @ RS.190/- AHMEDABAD MEETING 190/- DEDUCTION
                  </td>
                  <td style={{ backgroundColor: "lightgrey" }}>
                    <strong>Total Amount</strong>
                  </td>
                  <td>210</td>
                </tr>
              </tbody>
            </table>
          </>
        </AppDialog>
      )}
    </>
  );
};

const CommonFilterCell = (props: any) => {
  const dispatch = useAppDispatch();
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterCustomExpenseApproved: any = useAppSelector(
    (state) => state.expenseApproved.FilterCustomExpenseApproved
  );
  const isFilterRef = useRef(true);
  const [inputValue, setInputValue] = useState(props.value || "");
  const [inputField, setInputField] = useState(props.field || "");

  const onChange = (event: any) => {
    setInputValue(event.target.value);
    setInputField(event.target.name);
  };

  const delayedApiCall = React.useCallback(
    _debounce((updatedFilters: any) => {
      dispatch(setCurrentPage(0));
      const payload = {
        ...updatedFilters,
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };

      dispatch(getAllSRExpensesForVerify(payload));
    }, 300),
    [dispatch]
  );

  useEffect(() => {
    if (!isFilterRef.current) {
      const updatedFilters = {
        ...FilterCustomExpenseApproved,
        [inputField]: inputValue,
      };

      dispatch(setFilterCustomExpenseApproved({ updatedFilters }));
      delayedApiCall(updatedFilters);
    } else {
      isFilterRef.current = false;
    }
    return () => {
      delayedApiCall.cancel();
    };
  }, [inputValue, delayedApiCall]);

  const onClearButtonClick = (event: any) => {
    event.preventDefault();
    props.onChange({
      value: null,
      operator: "",
      syntheticEvent: event,
    });

    const updatedFilters = {
      ...FilterCustomExpenseApproved,
      [props.field]: "",
    };

    dispatch(setFilterCustomExpenseApproved(updatedFilters));

    const payload = {
      ...updatedFilters,
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };

    dispatch(getAllSRExpensesForVerify(payload));
  };

  return (
    <div className="d-flex">
      <TextBox
        value={FilterCustomExpenseApproved[props.field] || ""}
        name={props.field || ""}
        onChange={onChange}
      />
      <Button
        svgIcon={filterClearIcon}
        title="Clear"
        disabled={!FilterCustomExpenseApproved[props.field]}
        onClick={onClearButtonClick}
        type="button"
        style={{
          marginLeft: "8px",
        }}
      ></Button>
    </div>
  );
};

export default ExpenseApproved;
