import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { LoadingPanel } from "../../components/layout/Loading";
import IconButton from "../../components/common/IconButton";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { MdDelete } from "react-icons/md";
import { closed } from "../../components/muidrawer/muiDrawerSlice";
import ButtonEdit from "../../components/muidrawer/ButtonEdit";
import { checkAcessRights, formatIndianNumber } from "../../_helper/helper";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  INSERT_ACCESS,
} from "../../_contstants/common";
import { setCurrentPage, setPageLimit } from "../pagination/paginationSlice";
import {
  deleteSRExpense,
  getAllSRExpense,
} from "./services/expenseDetails.services";
import {
  setExpenseDetailsGUID,
  setFilterCustomExpenseDetails,
  clearFilterCustomExpenseDetails,
} from "./expenseDetailsSlice";
import { useNavigate } from "react-router-dom";
import _debounce from "lodash/debounce";
import { TextBox } from "@progress/kendo-react-inputs";
import { filterClearIcon } from "@progress/kendo-svg-icons";

const ExpenseDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);
  useEffect(() => {
    return () => {
      dispatch(closeDialog());
      dispatch(closed());
      dispatch(setCurrentPage(0));
      dispatch(clearFilterCustomExpenseDetails());
    };
  }, []);

  const handleCreate = () => {
    navigate("/expense/create");
  };

  return (
    <>
      <DeleteSRExpenseDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px 20px 20px 0",
          }}
        >
          {/* <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}> */}
          <p className="fs-4" style={{ margin: 0, fontWeight: "bold" }}>
            Expense Details
          </p>
          {/* </Typography.h4> */}
          {isAddAccess && (
            <Button
              onClick={handleCreate}
              fillMode={"solid"}
              themeColor={"primary"}
            >
              {"Add New"}
            </Button>
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <SRExpenseGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

const SRExpenseGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);
  const navigate = useNavigate();
  const FilterCustomExpenseDetails: any = useAppSelector(
    (state) => state.expensedetails.FilterCustomExpenseDetails
  );
  const SRExpenseList = useAppSelector(
    (state) => state.expensedetails.SRExpenseList
  );
  const loading = useAppSelector((state) => state.expensedetails.loading);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);

  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };

  useEffect(() => {
    const fetchItemList = async () => {
      const payload = {
        ...FilterCustomExpenseDetails,
        sr_id: Number(localStorage.getItem("UserID")),
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      await dispatch(getAllSRExpense(payload));
    };
    fetchItemList();
  }, [currentPage, pageLimit]);

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleOpenDeleteDialog = (expense_details_guid: string) => {
      dispatch(openDialog("deleteSRExpense"));
      dispatch(setExpenseDetailsGUID(expense_details_guid));
    };

    return (
      <td
        className="action-td"
        style={{
          ...props.style,
        }}
      >
        {isEditAccess && (
          <ButtonEdit
            ID={props.dataItem.expense_details_guid}
            name="expenseAllocationForm"
            tooltipTitle="Edit"
          />
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() =>
              handleOpenDeleteDialog(props.dataItem.expense_details_guid)
            }
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  // const MyActiveInActtiveCommandCell = (props: GridCellProps) => {
  //   const [IsActive, setIsActive] = useState<boolean>(props.dataItem.isactive);

  //   const handleActiveInActive = () => {
  //     setIsActive(!IsActive);
  //     const data = {
  //       expense_details_guid: props.dataItem.expense_details_guid,
  //       isactive: !IsActive,
  //     };
  //     dispatch(ActiveInactiveExpenseAllocation(data)).then((response: any) => {
  //       const payload = {
  //         limit: +pageLimit,
  //         skip: +currentPage * +pageLimit,
  //       };
  //       if (response.payload?.data?.Status === 200) {
  //         dispatch(getAllExpenseAllocations(payload));
  //       } else {
  //         dispatch(getAllExpenseAllocations(payload));
  //       }
  //     });
  //   };

  //   return (
  //     <td style={{ textAlign: "center" }}>
  //       <Switch
  //         disabled={isEditAccess ? false : true}
  //         name="isactive"
  //         checked={IsActive}
  //         onChange={handleActiveInActive}
  //         size="small"
  //       />
  //     </td>
  //   );
  // };

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      <KendoGrid
        style={{ maxHeight: "calc(100vh - 152px)" }}
        className="responsive-table"
        filterable={true}
        filter={filter}
        data={filter ? filterBy(SRExpenseList, filter) : SRExpenseList}
        onFilterChange={handleFilterChange}
        total={SRExpenseList[0]?.itemcount || SRExpenseList?.length}
        skip={currentPage * pageLimit}
        take={pageLimit}
        pageable={{
          buttonCount: 5,
          pageSizes: [10, 50, 100, 300, 500],
          pageSizeValue: pageLimit,
          type: "input",
        }}
        onPageChange={pageChange}
        cells={{
          headerCell: HeaderCustomCell,
          data: MyDataCustomCell,
        }}
      >
        <Column
          width={150}
          field="expense_date"
          filterable={false}
          title="Expense Date"
          cell={(props: any) => (
            <td>
              <div
                className={isEditAccess ? "hoverableName" : "normalName"}
                onClick={() =>
                  isEditAccess &&
                  navigate("/expense/edit", {
                    state: {
                      expense_details_guid:
                        props.dataItem?.expense_details_guid,
                    },
                  })
                }
              >
                {props.dataItem?.expense_date}
              </div>
            </td>
          )}
        />
        <Column
          width={200}
          field="from_city_name"
          filterCell={CommonFilterCell}
          title="From City"
        />
        <Column
          width={200}
          field="to_city_name"
          filterCell={CommonFilterCell}
          title="To City"
        />
        <Column
          field="net_amount"
          title="Net Amount"
          filterable={false}
          cell={(props: any) => (
            <td className="text-end">
              ₹ {formatIndianNumber(props.dataItem?.net_amount || 0)}
            </td>
          )}
          width={180}
        />

        <Column
          field="disallowed_amount"
          title="Disallowed Amount"
          filterable={false}
          cell={(props: any) => (
            <td className="text-end">
              ₹ {formatIndianNumber(props.dataItem?.disallowed_amount || 0)}
            </td>
          )}
          width={180}
        />
        <Column
          field="total_amount"
          title="Total Amount"
          filterable={false}
          cell={(props: any) => (
            <td className="text-end">
              ₹ {formatIndianNumber(props.dataItem?.total_amount || 0)}
            </td>
          )}
          width={180}
        />
        <Column
          field="verification_status"
          title="Verified Status"
          filterable={false}
          cell={(props: any) => (
            <td className="text-end">
              {props.dataItem?.verification_status ? "Verified" : "Pending"}
            </td>
          )}
          width={180}
        />
        {/* <Column
            field="id"
            title="Status"
            cell={MyActiveInActtiveCommandCell}
            width={"90px"}
            filterable={false}
            locked={true}
          /> */}
        {(isEditAccess || isDeleteAccess) && (
          <Column
            field="id"
            title="Actions"
            cell={MyEditCommandCell}
            width={"110px"}
            filterable={false}
          />
        )}
      </KendoGrid>
    </>
  );
};

const DeleteSRExpenseDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const ExpenseDetailsGUID = useAppSelector(
    (state) => state.expensedetails.ExpenseDetailsGUID
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterCustomExpenseDetails: any = useAppSelector(
    (state) => state.expensedetails.FilterCustomExpenseDetails
  );

  const handleDeleteAction = async (GUID: string) => {
    if (GUID) {
      const response = await dispatch(deleteSRExpense(GUID));
      const payload = {
        ...FilterCustomExpenseDetails,
        sr_id: Number(localStorage.getItem("UserID")),
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      if (response.payload.Data.Status === 200) {
        dispatch(getAllSRExpense(payload));
        dispatch(closeDialog());
        dispatch(setExpenseDetailsGUID(""));
      } else {
        dispatch(getAllSRExpense(payload));
        dispatch(closeDialog());
        dispatch(setExpenseDetailsGUID(""));
      }
    }
  };

  return (
    <>
      {dialogName === "deleteSRExpense" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Expense Details?"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Expense Details?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(ExpenseDetailsGUID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

const CommonFilterCell = (props: any) => {
  const dispatch = useAppDispatch();
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterCustomExpenseDetails: any = useAppSelector(
    (state) => state.expensedetails.FilterCustomExpenseDetails
  );
  const isFilterRef = useRef(true);
  const [inputValue, setInputValue] = useState(props.value || "");
  const [inputField, setInputField] = useState(props.field || "");

  const onChange = (event: any) => {
    setInputValue(event.target.value);
    setInputField(event.target.name);
  };

  const delayedApiCall = React.useCallback(
    _debounce((updatedFilters: any) => {
      dispatch(setCurrentPage(0));
      const payload = {
        ...updatedFilters,
        sr_id: Number(localStorage.getItem("UserID")),
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };

      dispatch(getAllSRExpense(payload));
    }, 300),
    [dispatch]
  );

  useEffect(() => {
    if (!isFilterRef.current) {
      const updatedFilters = {
        ...FilterCustomExpenseDetails,
        [inputField]: inputValue,
      };
      dispatch(setFilterCustomExpenseDetails(updatedFilters));
      delayedApiCall(updatedFilters);
    } else {
      isFilterRef.current = false;
    }
    return () => {
      delayedApiCall.cancel();
    };
  }, [inputValue, delayedApiCall]);

  const onClearButtonClick = (event: any) => {
    event.preventDefault();
    props.onChange({
      value: null,
      operator: "",
      syntheticEvent: event,
    });

    const updatedFilters = { ...FilterCustomExpenseDetails, [props.field]: "" };
    dispatch(setFilterCustomExpenseDetails(updatedFilters));

    const payload = {
      ...updatedFilters,
      sr_id: Number(localStorage.getItem("UserID")),
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };

    dispatch(getAllSRExpense(payload));
  };

  return (
    <div className="d-flex">
      <TextBox
        value={FilterCustomExpenseDetails[props.field] || ""}
        name={props.field || ""}
        onChange={onChange}
      />
      <Button
        svgIcon={filterClearIcon}
        title="Clear"
        disabled={!FilterCustomExpenseDetails[props.field]}
        onClick={onClearButtonClick}
        type="button"
        style={{
          marginLeft: "8px",
        }}
      ></Button>
    </div>
  );
};

export default ExpenseDetails;
