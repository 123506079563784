import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import {
  createCreditNote,
  deleteCreditNote,
  getAllCreditNote,
  getCreditNoteByID,
  getCreditNotePrint,
  updateCreditNote,
} from "./services/creditNote.services";
import { ICreditNote, ICreditNoteInitialState } from "./creditNoteModel";

const initialState: ICreditNoteInitialState = {
  loading: false,
  error: "",
  CreditNoteStateForGST: null,
  GeneratedCreditNoteNo: "",
  CreditNoteDeleteIndex: -1,
  CreditNoteID: null,
  CreditNoteList: [],
  CreditNoteTaxItemDeleteIndex: -1,
  CreditNotePrintDetails: {},
  CreditNoteDetail: {
    credit_note_no_string: "",
    credit_note_date: moment().toDate(),
    bill_no: "",
    bill_date: "",
    vendor_id: null,
    taxable_amount: null,
    sub_total: null,
    round_off: null,
    total_amount: null,
    total_gst: null,
    gst: null,
    cgst: null,
    sgst: null,
    igst: null,
    remarks: "",
    financial_year: null,
    transport_name: "",
    vehicle_no: "",
    sales_ledger_id: null,
    credit_note_items: [],
    credit_note_duty_and_taxes: [],
  },
  FilterCustomCreditNote: {
    account_name: "",
  },
};

const creditNoteSlice = createSlice({
  name: "creditnote",
  initialState,
  reducers: {
    clearCreditNoteDetails: (state) => {
      state.CreditNoteDetail = initialState.CreditNoteDetail;
    },
    setCreditNoteID: (state, action) => {
      state.CreditNoteID = action.payload;
    },
    clearCreditNoteID: (state) => {
      state.CreditNoteID = initialState.CreditNoteID;
    },
    setCreditNoteDeleteIndex: (state, action) => {
      state.CreditNoteDeleteIndex = action.payload;
    },
    setCreditNoteStateForGST: (state, action) => {
      state.CreditNoteStateForGST = action.payload;
    },
    clearCreditNoteStateForGST: (state) => {
      state.CreditNoteStateForGST = initialState.CreditNoteStateForGST;
    },
    setFilterCustomCreditNote: (state, action) => {
      state.FilterCustomCreditNote = action.payload;
    },
    setGeneratedCreditNoteNo: (state, action) => {
      state.GeneratedCreditNoteNo = action.payload;
    },
    clearFilterCustomCreditNote: (state) => {
      state.FilterCustomCreditNote = initialState.FilterCustomCreditNote;
    },
    setCreditNoteTaxItemDeleteIndex: (state, action) => {
      state.CreditNoteTaxItemDeleteIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCreditNote.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllCreditNote.fulfilled,
      (state, action: PayloadAction<ICreditNote[]>) => {
        state.loading = false;
        state.CreditNoteList = action.payload || [];
      }
    );
    builder.addCase(getAllCreditNote.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.CreditNoteList = [];
    });

    builder.addCase(createCreditNote.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createCreditNote.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createCreditNote.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateCreditNote.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateCreditNote.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCreditNote.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteCreditNote.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteCreditNote.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteCreditNote.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getCreditNoteByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getCreditNoteByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.CreditNoteDetail = action.payload;
    });
    builder.addCase(getCreditNoteByID.rejected, (state, action) => {
      state.loading = false;
      state.CreditNoteDetail = initialState.CreditNoteDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getCreditNotePrint.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getCreditNotePrint.fulfilled, (state, action: any) => {
      state.loading = false;
      state.CreditNotePrintDetails = action.payload;
    });
    builder.addCase(getCreditNotePrint.rejected, (state, action) => {
      state.loading = false;
      state.CreditNotePrintDetails = initialState.CreditNotePrintDetails;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearCreditNoteDetails,
  clearCreditNoteID,
  setCreditNoteID,
  setCreditNoteDeleteIndex,
  setCreditNoteStateForGST,
  clearCreditNoteStateForGST,
  setFilterCustomCreditNote,
  clearFilterCustomCreditNote,
  setCreditNoteTaxItemDeleteIndex,
  setGeneratedCreditNoteNo,
} = creditNoteSlice.actions;
export default creditNoteSlice.reducer;
