import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IExpenseAllocation } from "../expenseAllocationModel";

export const getAllExpenseAllocations = createAsyncThunk(
  "ExpenseAllocation/FindAllExpenseAllocation",
  async (payload: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ExpenseAllocation/FindAllExpenseAllocation`,
        payload
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Expense Allocation:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const InsertExpenseAllocation = createAsyncThunk(
  "ExpenseAllocation/InsertExpenseAllocation",
  async (ExpenseAllocationData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ExpenseAllocation/InsertExpenseAllocation`,
        ExpenseAllocationData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating expense allocation:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateExpenseAllocation = createAsyncThunk(
  "ExpenseAllocation/UpdateExpenseAllocation",
  async (ExpenseAllocationData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ExpenseAllocation/UpdateExpenseAllocation`,
        ExpenseAllocationData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating expense allocation:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteExpenseAllocation = createAsyncThunk(
  "ExpenseAllocation/DeleteExpenseAllocation",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ExpenseAllocation/DeleteExpenseAllocation`,
        { expense_allocation_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting expense allocation:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getExpenseAllocationByID = createAsyncThunk(
  "ExpenseAllocation/FindByIDExpenseAllocation",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ExpenseAllocation/FindByIDExpenseAllocation`,
        { expense_allocation_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        id: result.id,
        expense_allocation_guid: result.expense_allocation_guid,
        sr_id: result.sr_id,
        expense_type_id: result.expense_type_id,
        expense_amount: result.expense_amount,
        isactive: result.isactive === true ? 1 : 2,
      } as IExpenseAllocation;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching expense allocation:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveExpenseAllocations = createAsyncThunk(
  "ExpenseAllocation/FindAllActiveExpenseAllocation",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/ExpenseAllocation/FindAllActiveExpenseAllocation`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Expense Allocation:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
export const getAllSRFixedExpenseAllocated = createAsyncThunk(
  "ExpenseAllocation/FindAllSrListExpenseAllocation",
  async (id: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ExpenseAllocation/FindAllSrListExpenseAllocation`,
        { sr_id: id }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Expense Allocation:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveExpenseAllocation = createAsyncThunk(
  "ExpenseAllocation/ActiveInActiveExpenseAllocation",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ExpenseAllocation/ActiveInActiveExpenseAllocation`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive expense allocation:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
