import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import moment from "moment";

export const getAllJournal = createAsyncThunk(
  "JournalVoucher/JournalVoucherFindAll",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/JournalVoucher/JournalVoucherFindAll`
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error While Fetch Journal:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createJournal = createAsyncThunk(
  "JournalVoucher/InsertJournalVoucher",
  async (fomedata: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/JournalVoucher/InsertJournalVoucher`,
        fomedata
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating Journal :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateJournal = createAsyncThunk(
  "JournalVoucher/UpdateJournalVoucher",
  async (formdata: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/JournalVoucher/UpdateJournalVoucher`,
        formdata
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Journal :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteJournal = createAsyncThunk(
  "JournalVoucher/DeleteJournalVoucher",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/JournalVoucher/DeleteJournalVoucher`,
        { journal_voucher_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Journal :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getJournalByID = createAsyncThunk(
  "JournalVoucher/FindByIDJournalVoucher",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/JournalVoucher/FindByIDJournalVoucher`,
        { journal_voucher_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        id: result?.id,
        ledger_id: result?.ledger_id,
        voucher_type_id: result?.voucher_type_id,
        journal_voucher_guid: result?.journal_voucher_guid,
        journal_voucher_no: result?.journal_voucher_no,
        journal_voucher_no_string: result?.journal_voucher_no_string,
        financial_year: result?.financial_year,
        journal_voucher_date: result?.journal_voucher_date
          ? moment(result?.journal_voucher_date, "YYYY-MM-DD").toDate()
          : "",
        journal_voucher_total_amount: result?.journal_voucher_total_amount,
        remarks: result?.remarks,
        journal_voucher_entry: result?.journal_voucher_entry
          ? result?.journal_voucher_entry.map((entry: any) => ({
              id: entry?.id,
              journal_voucher_id: entry?.journal_voucher_id,
              debit_credit: entry?.debit_credit,
              ledger_id: entry?.ledger_id,
              amount: entry?.amount,
              ledger_options: entry?.ledger_options
                ? entry?.ledger_options
                : [],
              journal_voucher_entry_referance:
                entry?.journal_voucher_entry_referance
                  ? entry?.journal_voucher_entry_referance.map(
                      (refentry: any) => ({
                        id: refentry?.id,
                        journal_voucher_ref_id:
                          refentry?.journal_voucher_ref_id,
                        referance_type: refentry?.referance_type,
                        refernace_name: refentry?.refernace_name,
                        refernace_bill_id: refentry?.refernace_bill_id,
                        referance_amount: refentry?.referance_amount,
                      })
                    )
                  : [],
            }))
          : [],
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching journal details:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllOutstandingJournal = createAsyncThunk(
  "Payment/OutstandingJournalVoucherFindAll",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Payment/OutstandingJournalVoucherFindAll`,
        formData
      );
      const result = response.data?.Data as any;
      return result as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Outstanding Journal details:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const generateJournalNoForJournal = createAsyncThunk(
  "JournalVoucher/InvoiceNumberJournalVoucher",
  async (payload: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/JournalVoucher/InvoiceNumberJournalVoucher`,
        payload
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error generating Journal no. :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
