import React, { useCallback, useEffect } from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
// import { requiredValidator } from "../../components/formFields/CommonValidator";
import { useLocation } from "react-router-dom";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import { axiosApiInstance } from "../../app/axios";
import { API_URL } from "../../app/config";
import { ErrorToast, SuccessToast } from "../../components/toast/Toasts";
// import _ from "lodash";
import { setFinishedPackingProductDeleteIndex } from "./packingSlice";
import FormTextField from "../../components/formFields/FormTextField";
import FormDatePicker from "../../components/formFields/FormDateField";
import { IItem } from "../Item/itemModel";
import { getItemById } from "../Item/services/item.services";
import moment from "moment";
import { requiredValidator } from "../../components/formFields/CommonValidator";

const FinishnedPackingProductDetailsArray = (
  fieldArrayRenderProps: FieldArrayRenderProps
) => {
  const dispatch = useAppDispatch();
  const InputRef = React.useRef<any>(null);
  const location = useLocation();
  const packing_guid = location.state?.packing_guid;

  const fieldname = fieldArrayRenderProps?.name;

  // console.log("fieldArrayRenderProps value", fieldArrayRenderProps?.value);

  const FinishedPackingProductDeleteIndex = useAppSelector(
    (state) => state.packing.FinishedPackingProductDeleteIndex
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  useEffect(() => {
    if (!packing_guid) {
      pushElementInarray();
    }
  }, []);

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("finishedPackingProductDeleteDialog"));
    dispatch(setFinishedPackingProductDeleteIndex(index));
  };

  const handleDeleteAction = React.useCallback(
    async (index: number) => {
      if (fieldArrayRenderProps?.value[index].id) {
        try {
          const response = await axiosApiInstance.post(
            `${API_URL}/Packing/DeletePackingMulti`,
            { id: fieldArrayRenderProps?.value[index].id }
          );
          fieldArrayRenderProps.onRemove({ index: index });
          dispatch(closeDialog());
          dispatch(setFinishedPackingProductDeleteIndex(-1));
          SuccessToast(response.data?.Details || "Success");
        } catch (error: any) {
          ErrorToast(error?.response?.data?.Details || "Something went wrong");
          console.error("Error deleting Item:", error);
          return Promise.reject({
            message: error?.response?.data?.Details || "Something went wrong",
          });
        }
      } else {
        fieldArrayRenderProps.onRemove({ index: index });
        dispatch(closeDialog());
        dispatch(setFinishedPackingProductDeleteIndex(-1));
      }
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        packing_item_id: null,
        batch_no: null,
        // mfg_date: moment().toDate(),
        mfg_date: null,
        exp_date: null,
        ready_qty_in_nos: null,
      },
    });
    setTimeout(() => {
      InputRef.current?.element?.children[0]?.children[1]?.children[0]?.focus();
    }, 0);
  }, [fieldArrayRenderProps]);

  const fetchEXPDate = useCallback(
    async (index: number, item: any) => {
      if (item?.packing_item_id) {
        const response: any = await dispatch(
          getItemById({ id: item.packing_item_id })
        );
        const mfg_date = fieldArrayRenderProps?.formRenderProps.valueGetter(
          `${fieldname}.${index}.mfg_date`
        );
        if (response?.meta?.requestStatus === "fulfilled") {
          if (mfg_date && response.payload?.expiry_days) {
            const currentDate = moment(mfg_date);
            const targetDate = currentDate.add(
              response.payload?.expiry_days,
              "days"
            );
            fieldArrayRenderProps?.formRenderProps.onChange(
              `${fieldname}.${index}.exp_date`,
              {
                value: targetDate.toDate(),
              }
            );
          } else {
            fieldArrayRenderProps?.formRenderProps.onChange(
              `${fieldname}.${index}.exp_date`,
              {
                value: null,
              }
            );
          }
        }
      }
    },
    [dispatch, fieldArrayRenderProps?.formRenderProps]
  );

  const fetchConversation = useCallback(
    async (index: number, item: any) => {
      if (item?.packing_item_id) {
        const response: any = await dispatch(
          getItemById({ id: item.packing_item_id })
        );
        if (response?.meta?.requestStatus === "fulfilled") {
          fieldArrayRenderProps?.formRenderProps.onChange(
            `${fieldname}.${index}.weight_number`,
            {
              value: response.payload?.weight_number || 1,
            }
          );
        }
      }
    },
    [dispatch, fieldArrayRenderProps?.formRenderProps]
  );

  // const handleFieldChange = (field: string, index: number, item: any) => {
  //   // console.log("field", field);
  //   switch (field) {
  //     case "packing_item_id":
  //       fetchConversation(index, item);
  //       break;
  //     default:
  //       break;
  //   }
  // };

  // const debouncedHandleProductChange = React.useRef(
  //   _.debounce((field: any, index: number, item: any) => {
  //     handleFieldChange(field, index, item);
  //     console.log("field", field);
  //   }, 300)
  // ).current;

  // useEffect(() => {
  //   return () => {
  //     debouncedHandleProductChange.cancel();
  //   };
  // }, []);

  const handleReadyQtyChange = useCallback(
    async (index: number, item: any) => {
      if (item?.ready_qty_in_nos) {
        const readyQty = item?.ready_qty_in_nos;
        const conversionNo = fieldArrayRenderProps?.formRenderProps.valueGetter(
          `${fieldname}.${index}.weight_number`
        );
        console.log("item?.ready_qty_in_nos", item?.ready_qty_in_nos);
        fieldArrayRenderProps.formRenderProps.onChange(
          `${fieldname}.${index}.used_qty`,
          {
            value: readyQty / +conversionNo || 0,
          }
        );
      } else {
        fieldArrayRenderProps.formRenderProps.onChange(
          `${fieldname}.${index}.used_qty`,
          {
            value: 0,
          }
        );
      }
    },
    [dispatch, fieldArrayRenderProps?.formRenderProps]
  );

  const handleChange = (field: any, index: number, item: any) => {
    // debouncedHandleProductChange(field, index, item);
    if (field === "packing_item_id") {
      fetchConversation(index, item);
    } else if (field === "ready_qty_in_nos") {
      handleReadyQtyChange(index, item);
    } else if (field === "mfg_date") {
      fetchEXPDate(index, item);
      // console.log(index, item);
    }
  };

  return (
    <>
      <GridLayout
        cols={[{ width: "100%" }]}
        style={{ overflowX: "scroll", scrollbarWidth: "thin" }}
      >
        <GridLayoutItem
          className="bg-dark text-white fw-600"
          // style={{ width: "100%" }}
          style={{ width: 1800 }}
        >
          <GridLayout
            style={{
              marginRight: "0.2%",
              padding: 10,
            }}
            gap={{ rows: 10, cols: 10 }}
            cols={[
              { width: "21%" },
              { width: "15%" },
              { width: "12%" },
              { width: "12%" },
              { width: "15%" },
              { width: "15%" },
              { width: "7%" },
            ]}
          >
            <GridLayoutItem>Packing Item</GridLayoutItem>
            <GridLayoutItem>Batch No</GridLayoutItem>
            <GridLayoutItem>MFG Date</GridLayoutItem>
            <GridLayoutItem>EXP Date</GridLayoutItem>
            <GridLayoutItem>Ready Qty in NOS</GridLayoutItem>
            <GridLayoutItem>Used Qty (KG)</GridLayoutItem>
            <GridLayoutItem></GridLayoutItem>
          </GridLayout>
        </GridLayoutItem>
        {dialogName === "finishedPackingProductDeleteDialog" && (
          <AppDialog>
            <>
              <Typography.h5>{"Delete Item"}</Typography.h5>
              <GridLayout>
                <GridLayoutItem>
                  <Typography.p>
                    Are you sure you want to delete this?
                  </Typography.p>
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <DialogCloseButton themeColor="error" />
                  <Button
                    fillMode={"solid"}
                    themeColor={"error"}
                    onClick={() =>
                      handleDeleteAction(FinishedPackingProductDeleteIndex)
                    }
                    type="button"
                  >
                    Delete
                  </Button>
                </GridLayoutItem>
              </GridLayout>
            </>
          </AppDialog>
        )}
        {fieldArrayRenderProps.value &&
          fieldArrayRenderProps.value.length > 0 &&
          fieldArrayRenderProps.value.map(
            (packing_item_details: any, index: number) => {
              return (
                <GridLayoutItem
                  key={index}
                  // style={{ width: "100%" }}
                  style={{ width: 1800 }}
                >
                  <GridLayout
                    style={{
                      marginRight: "0.2%",
                      padding: 10,
                      position: "relative",
                    }}
                    gap={{ rows: 10, cols: 10 }}
                    cols={[
                      { width: "21%" },
                      { width: "15%" },
                      { width: "12%" },
                      { width: "12%" },
                      { width: "15%" },
                      { width: "15%" },
                      { width: "7%" },
                    ]}
                  >
                    <GridLayoutItem>
                      <Field
                        name={`${fieldname}.${index}.packing_item_id`}
                        placeholder="Packing Item"
                        component={FormIncrementalSearch}
                        wrapperStyle={{ margin: 0 }}
                        validator={requiredValidator}
                        // astrike={true}
                        onChange={() =>
                          handleChange(
                            `packing_item_id`,
                            index,
                            fieldArrayRenderProps.value[index]
                          )
                        }
                        fetchIncrementalData={(search: any) =>
                          fieldArrayRenderProps.handleItemSearchChange(
                            search,
                            `${fieldname}.${index}.packing_item`,
                            fieldArrayRenderProps.formRenderProps
                          )
                        }
                        options={
                          fieldArrayRenderProps.value[index]?.packing_item?.map(
                            (item: IItem) => {
                              return {
                                value: item?.id,
                                label: item?.product_name,
                              };
                            }
                          ) || []
                        }
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        wrapperStyle={{ margin: 0 }}
                        name={`${fieldname}.${index}.batch_no`}
                        placeholder="Batch No"
                        component={FormTextField}
                        // validator={requiredValidator}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        wrapperStyle={{ margin: 0 }}
                        name={`${fieldname}.${index}.mfg_date`}
                        format="dd/MM/yyyy"
                        component={FormDatePicker}
                        // onChange={() =>
                        //   handleChange(
                        //     `mfg_date`,
                        //     index,
                        //     fieldArrayRenderProps.value[index]
                        //   )
                        // }
                        // validator={requiredValidator}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        wrapperStyle={{ margin: 0 }}
                        name={`${fieldname}.${index}.exp_date`}
                        format="dd/MM/yyyy"
                        component={FormDatePicker}
                        minDate={
                          fieldArrayRenderProps.value[index]?.mfg_date ||
                          new Date(1900, 0, 1)
                        }

                        // validator={requiredValidator}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        wrapperStyle={{ margin: 0 }}
                        wrapperClassName="w-100 right-alighned-field"
                        name={`${fieldname}.${index}.ready_qty_in_nos`}
                        placeholder="0"
                        min={0}
                        onChange={() =>
                          handleChange(
                            `ready_qty_in_nos`,
                            index,
                            fieldArrayRenderProps.value[index]
                          )
                        }
                        max={Math.floor(
                          (packing_item_details.ready_qty_in_nos || 0) +
                            (fieldArrayRenderProps?.formRenderProps.valueGetter(
                              `remaining_used_qty`
                            ) || 0) *
                              (packing_item_details?.weight_number || 1)
                        )}
                        // disabled={
                        //   fieldArrayRenderProps.formRenderProps.valueGetter(
                        //     `${fieldname}.${index}.packing_item_id`
                        //   )
                        //     ? false
                        //     : true
                        // }
                        // max={formRenderProps.valueGetter("current_stock")}
                        validator={requiredValidator}
                        component={FormNumericTextField}
                      />
                    </GridLayoutItem>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      wrapperClassName="w-100 right-alighned-field"
                      name={`${fieldname}.${index}.used_qty`}
                      placeholder="0"
                      min={0}
                      disabled={true}
                      max={fieldArrayRenderProps?.formRenderProps.valueGetter(
                        "taken_qty"
                      )}
                      component={FormNumericTextField}
                    />

                    <GridLayoutItem
                      style={{
                        position: "sticky",
                        top: 0,
                        right: 0,
                        zIndex: 9,
                      }}
                    >
                      <IconButton
                        onClick={() => handleOpenDeleteDialog(index)}
                        disabled={fieldArrayRenderProps.value.length === 1}
                        themeColor={"error"}
                        size={"small"}
                        fillMode={"solid"}
                        type="button"
                        style={{
                          position: "absolute",
                          height: 32,
                          width: 32,
                          top: 0,
                          right: 0,
                        }}
                      >
                        <MdDelete
                          className="absolute-position"
                          style={{ fontSize: "16px" }}
                        />
                      </IconButton>
                    </GridLayoutItem>
                  </GridLayout>
                </GridLayoutItem>
              );
            }
          )}
      </GridLayout>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{ marginTop: 10, borderTop: "1px solid lightgray" }}
        >
          <GridLayout
            style={{ marginRight: 25, padding: 10 }}
            gap={{ rows: 10, cols: 5 }}
            cols={[
              { width: "25%" },
              { width: "14%" },
              { width: "12%" },
              { width: "16%" },
              { width: "16%" },
              { width: "12%" },
              { width: "5%" },
            ]}
          >
            <GridLayoutItem colSpan={2} className="d-flex align-items-end">
              <Button
                fillMode={"solid"}
                themeColor={"primary"}
                onClick={pushElementInarray}
                type="button"
              >
                Add Row
              </Button>
            </GridLayoutItem>
          </GridLayout>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default FinishnedPackingProductDetailsArray;
