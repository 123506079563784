import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { closed } from "../../components/muidrawer/muiDrawerSlice";
import { Typography } from "@progress/kendo-react-common";
import { RxCross1 } from "react-icons/rx";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { STATUSARRAY, VOUCHER_TYPE } from "../../_contstants/common";
import { clearVoucherTypeDetails } from "./voucherTypeSlice";
import {
  createVoucherType,
  getAllVoucherTypes,
  getVoucherTypeByID,
  updateVoucherType,
} from "./services/voucherType.services";
import { getAllPaymentAndReceipt } from "../account/services/account.services";
import FormCheckbox from "../../components/formFields/FormCheckbox";

const CreateVoucherType: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const VoucherTypeDetail = useAppSelector(
    (state) => state.voucherType.VoucherTypeDetail
  );
  const voucher_type_guid = useAppSelector((state) => state.muidrawer.data);
  const formLoading = useAppSelector((state) => state.voucherType.formLoading);
  const AccountPaymentAndReceiptList = useAppSelector(
    (state) => state.account.AccountPaymentAndReceiptList
  );
  useEffect(() => {
    const payload = {
      account_name: "",
      under_group_id: "16,17,18",
    };
    dispatch(getAllPaymentAndReceipt(payload));
    return () => {
      dispatch(clearVoucherTypeDetails());
      dispatch(closed());
    };
  }, []);

  useEffect(() => {
    if (voucher_type_guid) {
      dispatch(getVoucherTypeByID(voucher_type_guid));
    }
  }, [voucher_type_guid]);

  const handleSubmit = async (values: any) => {
    try {
      if (!voucher_type_guid) {
        const insertPayload = {
          type: values?.type ? +values?.type : null,
          name: values?.name ? values?.name : "",
          prefix_no: values?.prefix_no
            ? values.prefix_no.endsWith("/")
              ? values.prefix_no
              : values.prefix_no + "/"
            : "",
          isaccountingyear: values?.isaccountingyear ? true : false,
          isactive: values.isactive === 1,
          map_ledger_id: values?.map_ledger_id ? +values?.map_ledger_id : null,
          //   invoice_no_sequence: values?.invoice_no_sequence
          //     ? values?.invoice_no_sequence
          //     : false,
        };
        const response = await dispatch(createVoucherType(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearVoucherTypeDetails());
          dispatch(getAllVoucherTypes());
        }
      } else {
        const editPayload = {
          voucher_type_guid: voucher_type_guid,
          type: values?.type ? +values?.type : null,
          name: values?.name ? values?.name : "",
          prefix_no: values?.prefix_no
            ? values.prefix_no.endsWith("/")
              ? values.prefix_no
              : values.prefix_no + "/"
            : "",
          isaccountingyear: values?.isaccountingyear ? true : false,
          map_ledger_id: values?.map_ledger_id ? +values?.map_ledger_id : null,
          isactive: values.isactive === 1,
          //   invoice_no_sequence: values?.invoice_no_sequence
          //     ? values?.invoice_no_sequence
          //     : false,
        };
        const response = await dispatch(updateVoucherType(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearVoucherTypeDetails());
          dispatch(getAllVoucherTypes());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={VoucherTypeDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "17px 0 8px",
              }}
            >
              <Typography.h4 className="m-0">
                {voucher_type_guid ? "Update Voucher Type" : "Add Voucher Type"}
              </Typography.h4>
              <Button
                type="button"
                fillMode={"flat"}
                themeColor={"dark"}
                style={{ marginRight: 4 }}
                onClick={() => dispatch(closed())}
              >
                <RxCross1 />
              </Button>
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id={"type"}
                name={"type"}
                label={"Type"}
                placeholder="Type"
                component={FormSelectionField}
                validator={requiredValidator}
                astrike={true}
                options={VOUCHER_TYPE?.map((status: any) => {
                  return {
                    value: status?.value,
                    label: status?.label,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="name"
                label="Name"
                placeholder="Name"
                maxLength="50"
                component={FormTextField}
                validator={requiredValidator}
                astrike={true}
              />
            </GridLayoutItem>

            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="prefix_no"
                label="Prefix No"
                placeholder="i.e. HFPL/"
                maxLength="20"
                component={FormTextField}
                validator={requiredValidator}
                astrike={true}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="isaccountingyear"
                label="Is Accounting Year Add"
                labelClassName={"fw-600"}
                component={FormCheckbox}
              />
            </GridLayoutItem>
            {(formRenderProps.valueGetter("type") === 3 ||
              formRenderProps.valueGetter("type") === 4 ||
              formRenderProps.valueGetter("type") === 6) && (
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name={"map_ledger_id"}
                  label={"Map Ledger"}
                  placeholder="Select Map Ledger"
                  component={FormSelectionField}
                  validator={requiredValidator}
                  astrike={true}
                  options={AccountPaymentAndReceiptList.map((ledger: any) => {
                    return {
                      value: ledger?.id,
                      label: ledger?.account_name,
                    };
                  })}
                />
              </GridLayoutItem>
            )}

            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id={"isactive"}
                name={"isactive"}
                label={"Status"}
                placeholder="Select Status"
                component={FormSelectionField}
                validator={requiredValidator}
                astrike={true}
                options={STATUSARRAY?.map((status: any) => {
                  return {
                    value: status.value,
                    label: status.label,
                  };
                })}
              />
            </GridLayoutItem>
            {/* <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="invoice_no_sequence"
                label="Is Invoice Seq. starts from new"
                component={FormCheckbox}
                labelClassName={"fw-bold"}
              />
            </GridLayoutItem> */}
            <GridLayoutItem
              colSpan={3}
              style={{
                textAlign: "start",
                marginTop: "20px",
                width: "100%",
              }}
            >
              <ButtonWithLoading
                label={voucher_type_guid ? "Update" : "Save"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />
              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginLeft: 4 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
            </GridLayoutItem>
          </GridLayout>
        </FormElement>
      )}
    />
  );
};

export default CreateVoucherType;
