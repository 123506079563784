import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createReceipt,
  deleteReceipt,
  getAllOutstandingReceipt,
  getAllReceipt,
  getReceiptByID,
  updateReceipt,
} from "./services/receipt.services";
import { IReceipt, IReceiptInitialState } from "./receiptModel";
import moment from "moment";

const initialState: IReceiptInitialState = {
  loading: false,
  error: "",
  ReceiptEntryDeleteIndex: -1,
  ReceiptBillDialogOpenIndex: -1,
  GeneratedReceiptNo: "",
  ReceiptID: null,
  ReceiptGUID: "",
  ReceiptList: [],
  OutstandingReceiptList: [],
  ReceiptDetail: {
    financial_year: null,
    receipt_date: moment().toDate(),
    receipt_total_amount: null,
    remarks: "",
    ledger_id: null,
    receipt_no_string: "",
    voucher_type_id: null,
    receipt_entry: [],
  },
};

const ReceiptSlice = createSlice({
  name: "receipt",
  initialState,
  reducers: {
    setReceiptID: (state, action) => {
      state.ReceiptID = action.payload;
    },
    setReceiptGUID: (state, action) => {
      state.ReceiptGUID = action.payload;
    },
    setGeneratedReceiptNo: (state, action) => {
      state.GeneratedReceiptNo = action.payload;
    },
    clearReceiptDetails: (state) => {
      state.ReceiptDetail = initialState.ReceiptDetail;
    },
    clearOutstandingReceiptList: (state) => {
      state.OutstandingReceiptList = initialState.OutstandingReceiptList;
    },
    setReceiptEntryDeleteIndex: (state, action) => {
      state.ReceiptEntryDeleteIndex = action.payload;
    },
    setReceiptBillDialogOpenIndex: (state, action) => {
      state.ReceiptBillDialogOpenIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllReceipt.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllReceipt.fulfilled,
      (state, action: PayloadAction<IReceipt[]>) => {
        state.loading = false;
        state.ReceiptList = action.payload || [];
      }
    );
    builder.addCase(getAllReceipt.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ReceiptList = [];
    });

    builder.addCase(createReceipt.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createReceipt.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createReceipt.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateReceipt.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateReceipt.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateReceipt.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteReceipt.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteReceipt.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteReceipt.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getReceiptByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getReceiptByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.ReceiptDetail = action.payload;
    });
    builder.addCase(getReceiptByID.rejected, (state, action) => {
      state.loading = false;
      state.ReceiptDetail = initialState.ReceiptDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getAllOutstandingReceipt.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllOutstandingReceipt.fulfilled,
      (state, action: any) => {
        state.loading = false;
        state.OutstandingReceiptList = action.payload;
      }
    );
    builder.addCase(getAllOutstandingReceipt.rejected, (state, action) => {
      state.loading = false;
      state.OutstandingReceiptList = initialState.OutstandingReceiptList;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearReceiptDetails,
  setReceiptEntryDeleteIndex,
  setReceiptID,
  setReceiptBillDialogOpenIndex,
  setReceiptGUID,
  setGeneratedReceiptNo,
  clearOutstandingReceiptList,
} = ReceiptSlice.actions;
export default ReceiptSlice.reducer;
