import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { FiEdit } from "react-icons/fi";
import { LoadingPanel } from "../../components/layout/Loading";
import IconButton from "../../components/common/IconButton";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { MdDelete } from "react-icons/md";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import {
  deleteRoasting,
  getAllRoasting,
  getRoastingByIDNew,
} from "./services/roasting.services";
import {
  clearFilterRoasting,
  clearRoastingDetails,
  clearRoastingID,
  setFilterRoasting,
  setRoastingID,
} from "./roastingSlice";
import { setCurrentPage, setPageLimit } from "../pagination/paginationSlice";
import {
  checkAcessRights,
  formatIndianNumber,
  formatIndianNumberForQty,
} from "../../_helper/helper";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  FINANCIAL_YEAR,
  INSERT_ACCESS,
} from "../../_contstants/common";
import _debounce from "lodash/debounce";
import { filterClearIcon } from "@progress/kendo-svg-icons";
import { TextBox } from "@progress/kendo-react-inputs";
import AlertBox from "../../components/common/AlertBox";
import { FaEye } from "react-icons/fa";

const Roasting: React.FC = () => {
  const navigate = useNavigate();
  const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);

  const handleCreate = () => {
    navigate("/roasting/create");
  };

  return (
    <>
      <DeleteRoastingDialog />
      <RoastingChildItemDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Roasting List
          </Typography.h4>
          {isAddAccess && (
            <Button
              onClick={handleCreate}
              fillMode={"solid"}
              themeColor={"primary"}
            >
              {"Add New"}
            </Button>
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <RoastingGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

const RoastingGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const isEditBtnRef = useRef<any>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);
  const FilterRoasting = useAppSelector(
    (state) => state.roasting.FilterRoasting
  );

  const RoastingList = useAppSelector((state) => state.roasting.RoastingList);
  const loading = useAppSelector((state) => state.roasting.loading);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);

  useEffect(() => {
    return () => {
      dispatch(setCurrentPage(0));
      if (!isEditBtnRef.current) {
        dispatch(clearFilterRoasting());
      }
    };
  }, []);

  useEffect(() => {
    const payload = {
      ...FilterRoasting,
      financial_year: FINANCIAL_YEAR,
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };
    dispatch(getAllRoasting(payload));
  }, [currentPage, pageLimit]);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);

  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEdit = (roasting_guid: any) => {
      navigate("/roasting/edit", {
        state: { roasting_guid: roasting_guid },
      });
    };
    const handleOpenDeleteDialog = (roasting_guid: any) => {
      dispatch(openDialog("deleteRoasting"));
      dispatch(setRoastingID(roasting_guid));
    };
    const handleItemView = async (roasting_guid: any) => {
      const payload = {
        roasting_guid: roasting_guid,
      };
      const res = await dispatch(getRoastingByIDNew(payload));
      if (res.meta.requestStatus === "fulfilled") {
        dispatch(openDialog("RoastingItemView"));
      }
    };

    return (
      <td
        className={`${props.className} action-td`}
        style={{
          ...props.style,
        }}
      >
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            ref={isEditBtnRef}
            onClick={() => {
              isEditBtnRef.current = true;
              handleEdit(props.dataItem?.roasting_guid);
            }}
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() =>
              handleOpenDeleteDialog(props.dataItem?.roasting_guid)
            }
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="View"
          onClick={() => handleItemView(props.dataItem?.roasting_guid)}
        >
          <FaEye className="absolute-position" style={{ fontSize: "20px" }} />
        </IconButton>
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 12 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      <KendoGrid
        style={{ maxHeight: "calc(100vh - 152px)" }}
        className="responsive-table"
        filterable={true}
        filter={filter}
        data={filter ? filterBy(RoastingList, filter) : RoastingList}
        onFilterChange={handleFilterChange}
        total={RoastingList[0]?.itemcount || RoastingList.length}
        skip={currentPage * pageLimit}
        take={pageLimit}
        pageable={{
          buttonCount: 5,
          pageSizes: [5, 10, 50, 100, 300, 500],
          pageSizeValue: pageLimit,
          type: "input",
        }}
        onPageChange={pageChange}
        cells={{
          headerCell: HeaderCustomCell,
          data: MyDataCustomCell,
        }}
      >
        <Column
          field="roasting_no"
          title="Roasting No"
          width={150}
          cell={(props: any) => (
            <td>
              <div
                className={isEditAccess ? "hoverableName" : "normalName"}
                onClick={() =>
                  isEditAccess &&
                  navigate("/roasting/edit", {
                    state: {
                      roasting_guid: props.dataItem?.roasting_guid,
                    },
                  })
                }
              >
                {props.dataItem?.roasting_no}
              </div>
            </td>
          )}
        />
        <Column
          field="roasting_date"
          title="Roasting Date"
          width={200}
          filterCell={CommonFilterCell}
        />
        <Column
          field="product_name"
          title="Product Name"
          width={250}
          filterCell={CommonFilterCell}
          cell={(props: any) => <td>{props.dataItem?.ready_product_name}</td>}
        />
        <Column field="grn_no" title="Lot No" width={150} />
        <Column
          field="no_of_workers"
          title="No Of Workers"
          width={200}
          cell={(props: any) => (
            <td className="text-end">{props.dataItem?.no_of_workers}</td>
          )}
        />
        <Column field="machine_name" title="Machine No" width={250} />
        <Column
          field="start_time"
          title="Time"
          width={200}
          cell={(props: any) => (
            <td>
              {props.dataItem?.start_time}
              {props.dataItem?.start_time && props.dataItem?.end_time && " - "}
              {props.dataItem?.end_time}
            </td>
          )}
        />
        <Column
          field="total_qty"
          title="Total Qty"
          width={200}
          cell={(props: any) => (
            <td style={{ textAlign: "end" }}>
              {formatIndianNumberForQty(+props.dataItem.total_qty)}
            </td>
          )}
        />
        <Column
          field="received_qty"
          title="Received Qty"
          width={200}
          cell={(props: any) => (
            <td style={{ textAlign: "end" }}>
              {formatIndianNumberForQty(+props.dataItem.received_qty)}
            </td>
          )}
        />
        <Column
          field="process_loss_qty"
          title="Roasting Loss Qty"
          width={200}
          cell={(props: any) => (
            <td style={{ textAlign: "end" }}>
              {formatIndianNumberForQty(+props.dataItem.process_loss_qty)}
            </td>
          )}
        />
        {/* <Column
          field="previous_process_name"
          title="Material Taken From Department"
          width={270}
        /> */}
        <Column field="checker_name" title="Checker By" width={250} />
        <Column
          field="process_name"
          title="Material Issued to Department"
          width={250}
        />
        {(isEditAccess || isDeleteAccess) && (
          <Column
            field="ID"
            title="Actions"
            cell={MyEditCommandCell}
            width={"140px"}
            locked={true}
            filterable={false}
          />
        )}
      </KendoGrid>
    </>
  );
};

const DeleteRoastingDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const RoastingID = useAppSelector((state) => state.roasting.RoastingID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterRoasting = useAppSelector(
    (state) => state.roasting.FilterRoasting
  );

  const handleDeleteAction = async (ID: any) => {
    if (ID) {
      const response = await dispatch(deleteRoasting(ID));
      const payload = {
        ...FilterRoasting,
        financial_year: FINANCIAL_YEAR,
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      if (response.payload.Data.Status === 200) {
        dispatch(getAllRoasting(payload));
        dispatch(closeDialog());
        dispatch(clearRoastingID());
      } else {
        dispatch(getAllRoasting(payload));
        dispatch(closeDialog());
        dispatch(clearRoastingID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteRoasting" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Roasting"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Roasting?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(RoastingID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

const CommonFilterCell = (props: any) => {
  const dispatch = useAppDispatch();
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterRoasting: any = useAppSelector(
    (state) => state.roasting.FilterRoasting
  );
  const isFilterRef = useRef(true);
  const [inputValue, setInputValue] = useState(props.value || "");
  const [inputField, setInputField] = useState(props.field || "");

  const onChange = (event: any) => {
    setInputValue(event.target.value);
    setInputField(event.target.name);
  };

  const delayedApiCall = React.useCallback(
    _debounce((updatedFilters: any) => {
      dispatch(setCurrentPage(0));
      const payload = {
        ...updatedFilters,
        financial_year: FINANCIAL_YEAR,
        limit: +pageLimit,
        skip: 0 * +pageLimit,
      };

      dispatch(getAllRoasting(payload));
    }, 300),
    [dispatch]
  );

  useEffect(() => {
    if (!isFilterRef.current) {
      const updatedFilters = {
        ...FilterRoasting,
        [inputField]: inputValue,
      };
      dispatch(setFilterRoasting(updatedFilters));
      delayedApiCall(updatedFilters);
    } else {
      isFilterRef.current = false;
    }
    return () => {
      delayedApiCall.cancel();
    };
  }, [inputValue, delayedApiCall]);

  const onClearButtonClick = (event: any) => {
    event.preventDefault();
    props.onChange({
      value: null,
      operator: "",
      syntheticEvent: event,
    });

    const updatedFilters = { ...FilterRoasting, [props.field]: "" };
    dispatch(setFilterRoasting(updatedFilters));

    const payload = {
      ...updatedFilters,
      financial_year: FINANCIAL_YEAR,
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };

    dispatch(getAllRoasting(payload));
  };

  return (
    <div className="d-flex">
      <TextBox
        value={FilterRoasting[props.field] || ""}
        name={props.field || ""}
        onChange={onChange}
      />
      <Button
        svgIcon={filterClearIcon}
        title="Clear"
        disabled={!FilterRoasting[props.field]}
        onClick={onClearButtonClick}
        type="button"
        style={{
          marginLeft: "8px",
        }}
      ></Button>
    </div>
  );
};

const RoastingChildItemDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const RoastingDetail = useAppSelector(
    (state) => state.roasting.RoastingDetail
  );
  const loading = useAppSelector((state) => state.roasting.loading);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" || event.key === "Esc") {
        dispatch(clearRoastingDetails());
      }
    };
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearRoastingDetails());
    };
  }, []);

  let totalTakenQty = 0;
  let totalWastageQty = 0;

  RoastingDetail &&
    RoastingDetail?.roasting_items &&
    RoastingDetail?.roasting_items.length > 0 &&
    RoastingDetail?.roasting_items?.forEach((item: any) => {
      totalTakenQty += item?.taken_qty || 0;
      totalWastageQty += item?.wastage_qty || 0;
    });

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {dialogName === "RoastingItemView" && (
        <AppDialog className="inward-items-dialog">
          <>
            <Typography.h5>{"Roasting Items"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                {RoastingDetail &&
                RoastingDetail?.roasting_items &&
                RoastingDetail?.roasting_items.length > 0 ? (
                  <div
                    className="table-responsive m-0"
                    style={{
                      maxHeight: "calc(100vh - 227px)",
                      scrollbarWidth: "thin",
                    }}
                  >
                    <table className="table table-bordered m-0">
                      <thead>
                        <tr>
                          <th style={{ background: "black", color: "white" }}>
                            No
                          </th>
                          <th
                            style={{
                              background: "black",
                              color: "white",
                              minWidth: 250,
                            }}
                          >
                            Item
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Process Name
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            GRN No
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Taken Qty
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Wastage Qty
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Wastage Qty (%)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {RoastingDetail?.roasting_items?.map(
                          (item: any, index: number) => (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{item?.product_name}</td>
                              <td>{item?.process_name}</td>
                              <td>{item?.grn_no}</td>
                              <td className="text-end">
                                {formatIndianNumberForQty(item?.taken_qty || 0)}
                              </td>
                              <td className="text-end">
                                {formatIndianNumberForQty(
                                  item?.wastage_qty || 0
                                )}
                              </td>
                              <td className="text-end">
                                {item?.loss_percentage
                                  ? `${formatIndianNumber(
                                      item?.loss_percentage
                                    )} %`
                                  : ""}
                              </td>
                            </tr>
                          )
                        )}
                        <tr className="fw-bold">
                          <td
                            colSpan={4}
                            className="text-end pe-5"
                            style={{
                              background: "lightgray",
                              color: "black",
                            }}
                          >
                            Total
                          </td>
                          <td
                            style={{
                              textAlign: "end",
                              background: "lightgray",
                              color: "black",
                            }}
                          >
                            {formatIndianNumberForQty(totalTakenQty)}
                          </td>
                          <td
                            style={{
                              textAlign: "end",
                              background: "lightgray",
                              color: "black",
                            }}
                          >
                            {formatIndianNumberForQty(totalWastageQty)}
                          </td>
                          <td
                            style={{
                              background: "lightgray",
                              color: "black",
                            }}
                          ></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <AlertBox style={{ marginBottom: 10 }} />
                )}
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: 20,
                }}
              >
                <Button
                  onClick={() => {
                    dispatch(closeDialog());
                    dispatch(clearRoastingDetails());
                  }}
                  themeColor="error"
                  fillMode={"outline"}
                >
                  Close
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default Roasting;
