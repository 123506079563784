import React, { useCallback, useEffect } from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import { useLocation } from "react-router-dom";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import _ from "lodash";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { getGRNByItemID } from "../inwardreturn/services/inwardReturn.services";
import { getCurrentStock } from "../stocktransfer/services/stockTransfer.services";
import { axiosApiInstance } from "../../app/axios";
import { API_URL } from "../../app/config";
import { ErrorToast, SuccessToast } from "../../components/toast/Toasts";
import { IProcess } from "../process/processModel";
import { setRoastingDeleteIndex } from "./roastingSlice";
import FormTextField from "../../components/formFields/FormTextField";
import { formatIndianNumberForQty } from "../../_helper/helper";

const RoastingItemDetailsArray = (
  fieldArrayRenderProps: FieldArrayRenderProps
) => {
  const dispatch = useAppDispatch();
  const InputRef = React.useRef<any>(null);
  const location = useLocation();
  const roasting_guid = location.state?.roasting_guid;

  const RoastingDeleteIndex = useAppSelector(
    (state) => state.roasting.RoastingDeleteIndex
  );
  const RoastingDetail = useAppSelector(
    (state) => state.roasting.RoastingDetail
  );
  const ProcessList = useAppSelector((state) => state.process.ProcessList);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("roastingItemDeleteDialog"));
    dispatch(setRoastingDeleteIndex(index));
  };

  const handleReceivedQtyChange = (
    e: any,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => {
    const total_qty =
      fieldArrayRenderProps.formRenderProps.valueGetter("total_qty");
    const roasting_items =
      fieldArrayRenderProps.formRenderProps.valueGetter("roasting_items");

    const rejection_qty = total_qty - e?.value || 0;

    if (roasting_items && roasting_items.length > 0) {
      roasting_items?.map((item: any, index: number) => {
        fieldArrayRenderProps.formRenderProps.onChange(
          `roasting_items.${index}.wastage_qty`,
          {
            value:
              rejection_qty == 0.0
                ? "0.000"
                : rejection_qty /
                  (roasting_items &&
                  typeof roasting_items === "object" &&
                  roasting_items?.length > 0
                    ? roasting_items?.length
                    : 0),
          }
        );
      });
    }
  };

  useEffect(() => {
    if (!roasting_guid) {
      pushElementInarray();
    }
  }, []);

  const handleDeleteAction = React.useCallback(
    async (index: number) => {
      if (fieldArrayRenderProps?.value[index].id) {
        try {
          const response = await axiosApiInstance.post(
            `${API_URL}/Roasting/DeleteRoastingItem`,
            { id: fieldArrayRenderProps?.value[index].id }
          );
          fieldArrayRenderProps.onRemove({ index: index });
          dispatch(closeDialog());
          dispatch(setRoastingDeleteIndex(-1));
          SuccessToast(response.data?.Details || "Success");
        } catch (error: any) {
          ErrorToast(error?.response?.data?.Details || "Something went wrong");
          console.error("Error deleting Item:", error);
          return Promise.reject({
            message: error?.response?.data?.Details || "Something went wrong",
          });
        }
      } else {
        fieldArrayRenderProps.onRemove({ index: index });
        dispatch(closeDialog());
        dispatch(setRoastingDeleteIndex(-1));
      }
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        process_id: null,
        item_id: null,
        grn_no: null,
        current_qty: null,
        taken_qty: null,
        wastage_qty: null,
      },
    });

    setTimeout(() => {
      InputRef.current?.element?.children[0]?.children[1]?.children[0]?.focus();
    }, 0);
  }, [fieldArrayRenderProps]);

  const fetchGRNOptions = useCallback(
    async (index: number, item: any) => {
      if (item?.item_id && item?.process_id) {
        const grnPayload = {
          item_id: item.item_id,
          process_id: item?.process_id,
          warehouse_id: 1,
        };
        const response = await dispatch(getGRNByItemID(grnPayload));
        const result =
          response.payload
            ?.filter((grn: any) => grn.item_id === item.item_id)
            .map((grnno: any) => ({
              value: `${grnno?.grn_no}`,
              label: `${grnno?.grn_no} - ${formatIndianNumberForQty(
                grnno?.current_stock || 0
              )}`,
            })) || [];
        fieldArrayRenderProps?.formRenderProps.onChange(
          `roasting_items.${index}.grnNoOptions`,
          {
            value: result,
          }
        );
      } else {
        fieldArrayRenderProps?.formRenderProps.onChange(
          `roasting_items.${index}.grnNoOptions`,
          {
            value: [],
          }
        );
        fieldArrayRenderProps?.formRenderProps.onChange(
          `roasting_items.${index}.grn_no`,
          {
            value: null,
          }
        );
      }
    },
    [dispatch, fieldArrayRenderProps?.formRenderProps]
  );

  const fetchCurrentStock = useCallback(
    async (index: number, item: any) => {
      if (item?.item_id && item?.grn_no && item?.process_id) {
        const payload = {
          item_id: +item.item_id,
          grn_no: item.grn_no,
          process_id: +item?.process_id,
          warehouse_id: 1,
        };
        const response = await dispatch(getCurrentStock(payload));
        fieldArrayRenderProps?.formRenderProps.onChange(
          `roasting_items.${index}.current_qty`,
          {
            value: response.payload?.actual_stock || 0,
          }
        );
        fieldArrayRenderProps?.formRenderProps.onChange(
          `roasting_items.${index}.dagina`,
          {
            value:
              `${response.payload?.dagina} - ${response.payload?.inward_date}` ||
              "",
          }
        );
      }
    },
    [dispatch, fieldArrayRenderProps?.formRenderProps]
  );

  const handleFieldChange = (field: string, index: number, item: any) => {
    switch (field) {
      case "item_id":
        fetchGRNOptions(index, item);
        fetchCurrentStock(index, item);
        break;
      case "grn_no":
        fetchCurrentStock(index, item);
        break;
      case "process_id":
        fetchGRNOptions(index, item);
        fetchCurrentStock(index, item);
        break;
      default:
        break;
    }
  };

  const debouncedHandleProductChange = React.useRef(
    _.debounce((field: any, index: number, item: any) => {
      handleFieldChange(field, index, item);
    }, 300)
  ).current;

  useEffect(() => {
    return () => {
      debouncedHandleProductChange.cancel();
    };
  }, []);

  const handleChange = (field: any, index: number, item: any) => {
    debouncedHandleProductChange(field, index, item);
  };

  return (
    <>
      <GridLayout
        cols={[{ width: "100%" }]}
        style={{ overflowX: "scroll", scrollbarWidth: "thin" }}
      >
        <GridLayoutItem
          className="bg-dark text-white fw-600"
          style={{ minWidth: 1700 }}
        >
          <GridLayout
            style={{
              marginRight: "0.2%",
              padding: 10,
            }}
            gap={{ rows: 10, cols: 5 }}
            cols={[
              { width: "15%" },
              { width: "20%" },
              { width: "12%" },
              { width: "10%" },
              { width: "11%" },
              { width: "10%" },
              { width: "10%" },
              { width: "7%" },
              { width: "3%" },
            ]}
          >
            <GridLayoutItem>Material Taken From Department</GridLayoutItem>
            <GridLayoutItem>Item</GridLayoutItem>
            <GridLayoutItem>GRN / Lot No</GridLayoutItem>
            <GridLayoutItem>Current Stock</GridLayoutItem>
            <GridLayoutItem>Dagina</GridLayoutItem>
            <GridLayoutItem>Taken Qty</GridLayoutItem>
            <GridLayoutItem>Loss Qty</GridLayoutItem>
            <GridLayoutItem>Loss Qty (%)</GridLayoutItem>
            <GridLayoutItem></GridLayoutItem>
          </GridLayout>
        </GridLayoutItem>
        {dialogName === "roastingItemDeleteDialog" && (
          <AppDialog>
            <>
              <Typography.h5>{"Delete Item"}</Typography.h5>
              <GridLayout>
                <GridLayoutItem>
                  <Typography.p>
                    Are you sure you want to delete this?
                  </Typography.p>
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <DialogCloseButton themeColor="error" />
                  <Button
                    fillMode={"solid"}
                    themeColor={"error"}
                    onClick={() => handleDeleteAction(RoastingDeleteIndex)}
                    type="button"
                  >
                    Delete
                  </Button>
                </GridLayoutItem>
              </GridLayout>
            </>
          </AppDialog>
        )}
        {fieldArrayRenderProps.value &&
          fieldArrayRenderProps.value.length > 0 &&
          fieldArrayRenderProps.value.map(
            (roasting_items: any, index: number) => (
              <GridLayoutItem key={index} style={{ minWidth: 1700 }}>
                {/* <Card className="fieldarray-card" style={{ padding: 10 }}> */}
                <GridLayout
                  style={{
                    marginRight: "0.2%",
                    padding: 10,
                    position: "relative",
                  }}
                  gap={{ rows: 10, cols: 5 }}
                  cols={[
                    { width: "15%" },
                    { width: "20%" },
                    { width: "12%" },
                    { width: "10%" },
                    { width: "11%" },
                    { width: "10%" },
                    { width: "10%" },
                    { width: "7%" },
                    { width: "3%" },
                  ]}
                >
                  <GridLayoutItem ref={InputRef}>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`roasting_items.${index}.process_id`}
                      placeholder="Process"
                      component={FormSelectionField}
                      validator={requiredValidator}
                      onChange={() =>
                        handleFieldChange(
                          `process_id`,
                          index,
                          fieldArrayRenderProps.value[index]
                        )
                      }
                      options={ProcessList?.map((process: IProcess) => {
                        return {
                          value: process?.id,
                          label: process?.process_name,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`roasting_items.${index}.item_id`}
                      placeholder="Product"
                      isAddNew={true}
                      addNewLink="item/create"
                      component={FormIncrementalSearch}
                      validator={requiredValidator}
                      onChange={
                        () =>
                          handleChange(
                            `item_id`,
                            index,
                            fieldArrayRenderProps.value[index]
                          )
                        // handleFieldChange(
                        //   `item_id`,
                        //   index,
                        //   fieldArrayRenderProps.value[index]
                        // )
                      }
                      fetchIncrementalData={(search: any) =>
                        fieldArrayRenderProps.handleItemSearchChange(
                          search,
                          `roasting_items.${index}.item_options`,
                          fieldArrayRenderProps.formRenderProps
                        )
                      }
                      options={
                        fieldArrayRenderProps.value[index]?.item_options?.map(
                          (item: any) => {
                            return {
                              value: item.id,
                              label: item.product_name,
                            };
                          }
                        ) || []
                      }
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`roasting_items.${index}.grn_no`}
                      placeholder="GRN / Lot No"
                      component={FormSelectionField}
                      onChange={() =>
                        handleFieldChange(
                          `grn_no`,
                          index,
                          fieldArrayRenderProps.value[index]
                        )
                      }
                      validator={requiredValidator}
                      options={
                        fieldArrayRenderProps.value[index]?.grnNoOptions || []
                      }
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="stock-label right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`roasting_items.${index}.current_qty`}
                      placeholder="0"
                      format="n3"
                      disabled={true}
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="stock-label "
                      wrapperStyle={{ margin: 0 }}
                      name={`roasting_items.${index}.dagina`}
                      placeholder="0"
                      disabled={true}
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`roasting_items.${index}.taken_qty`}
                      max={
                        roasting_guid
                          ? RoastingDetail?.roasting_items[index]?.taken_qty +
                            fieldArrayRenderProps.formRenderProps.valueGetter(
                              `roasting_items.${index}.current_qty`
                            )
                          : fieldArrayRenderProps.formRenderProps.valueGetter(
                              `roasting_items.${index}.current_qty`
                            )
                      }
                      placeholder="0"
                      type="number"
                      format="n3"
                      minNumber="0"
                      validator={requiredValidator}
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`roasting_items.${index}.wastage_qty`}
                      placeholder="0"
                      max={fieldArrayRenderProps.formRenderProps.valueGetter(
                        `roasting_items.${index}.taken_qty`
                      )}
                      minNumber="0"
                      format="n3"
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <div className="w-100">
                      <Field
                        wrapperStyle={{ margin: 0 }}
                        wrapperClassName="red-highlight-field right-alighned-field d-flex flex-wrap justify-content-center"
                        name={`roasting_items.${index}.loss_percentage`}
                        placeholder="0"
                        disabled={true}
                        component={FormTextField}
                      />
                    </div>
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{
                      textAlign: "end",
                    }}
                  >
                    <IconButton
                      onClick={() => handleOpenDeleteDialog(index)}
                      disabled={fieldArrayRenderProps.value.length === 1}
                      themeColor={"error"}
                      size={"small"}
                      fillMode={"solid"}
                      type="button"
                      style={{
                        height: 32,
                        width: 32,
                      }}
                    >
                      <MdDelete
                        className="absolute-position"
                        style={{ fontSize: "16px" }}
                      />
                    </IconButton>
                  </GridLayoutItem>
                </GridLayout>
              </GridLayoutItem>
            )
          )}
      </GridLayout>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{ marginTop: 10, borderTop: "1px solid lightgray" }}
        >
          <GridLayout
            style={{ marginRight: 30, padding: 10 }}
            gap={{ rows: 10, cols: 5 }}
            cols={[
              { width: "18%" },
              { width: "20%" },
              { width: "12%" },
              { width: "10%" },
              { width: "14%" },
              { width: "10%" },
              { width: "11%" },
              { width: "5%" },
            ]}
          >
            <GridLayoutItem colSpan={2} className="d-flex align-items-end">
              <Button
                fillMode={"solid"}
                themeColor={"primary"}
                onClick={pushElementInarray}
                type="button"
              >
                Add Row
              </Button>
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={4}
              className="d-flex justify-content-end align-items-center fw-600"
            >
              Total Quantity (KG)
            </GridLayoutItem>
            <GridLayoutItem colSpan={2}>
              <Field
                wrapperClassName="right-alighned-field"
                wrapperStyle={{ margin: 0 }}
                name="total_qty"
                placeholder="0"
                format="n3"
                component={FormNumericTextField}
                disabled={true}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={6}
              className="d-flex justify-content-end align-items-start fw-600 mt-1"
            >
              Received Quantity (KG){" "}
              <span className="text-danger">&nbsp;*</span>
            </GridLayoutItem>
            <GridLayoutItem colSpan={2}>
              <Field
                wrapperClassName=" right-alighned-field"
                wrapperStyle={{ margin: 0 }}
                name="received_qty"
                placeholder="0"
                format="n3"
                max={fieldArrayRenderProps.formRenderProps.valueGetter(
                  "total_qty"
                )}
                onChange={(e: any) =>
                  handleReceivedQtyChange(e, fieldArrayRenderProps)
                }
                component={FormNumericTextField}
                validator={requiredValidator}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={6}
              className="d-flex justify-content-end align-items-center fw-600"
            >
              Roasting Loss Quantity (KG)
            </GridLayoutItem>
            <GridLayoutItem colSpan={2}>
              <Field
                wrapperClassName="w-100 right-alighned-field red-highlight-field d-flex flex-column align-items-center"
                wrapperStyle={{ margin: 0 }}
                name="process_loss_qty"
                format="n3"
                placeholder="0"
                disabled={true}
                component={FormNumericTextField}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={6}
              className="d-flex justify-content-end align-items-center fw-600"
            >
              Roasting Loss Qty (%)
            </GridLayoutItem>
            <GridLayoutItem colSpan={2}>
              <div className="w-100">
                <Field
                  wrapperStyle={{ margin: 0 }}
                  wrapperClassName="red-highlight-field right-alighned-field d-flex flex-wrap justify-content-center"
                  name="roasting_loss_percentage"
                  placeholder="0"
                  disabled={true}
                  component={FormTextField}
                />
              </div>
            </GridLayoutItem>
          </GridLayout>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default RoastingItemDetailsArray;
