import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import ShadowCard from "../../components/common/ShadowCard";
import FormRichTextField from "../../components/formFields/FormRichTextField";
import {
  createStockTransfer,
  getCurrentStock,
  getStockTransferByID,
  updateStockTransfer,
} from "./services/stockTransfer.services";
import { getAllItemIncremental } from "../Item/services/item.services";
import { findAllActiveWarehouse } from "../warehouse/services/warehouse.services";
import { clearStockTransferDetails } from "./stockTransferSlice";
import FormDatePicker from "../../components/formFields/FormDateField";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { IWarehouse } from "../warehouse/warehouseModel";
import { IItem } from "../Item/itemModel";
import {
  getGRNByItemID,
  getGRNByItemIDAndReceiptNo,
} from "../inwardreturn/services/inwardReturn.services";
import moment from "moment";
import { IProcess } from "../process/processModel";
import { findAllActiveProcess } from "../process/services/process.services";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import { FINANCIAL_YEAR } from "../../_contstants/common";
import { formatIndianNumberForQty } from "../../_helper/helper";
import { ErrorToast } from "../../components/toast/Toasts";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
  // currentStock: number | null;
}

const QtyChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
  // currentStock,
}) => {
  // const stock_qty = formRenderProps.valueGetter("stock_qty");
  const grn_no = formRenderProps.valueGetter("grn_no");
  const isFetchLotOption = React.useRef(true);
  const location = useLocation();
  const stock_transfer_guid = location.state?.stock_transfer_guid;

  useEffect(() => {
    const currentStockCheck = () => {
      // if (currentStock) {
      //   if (currentStock < stock_qty) {
      formRenderProps.onChange("stock_qty", {
        value: "",
      });
      //     }
      //   } else {
      //     formRenderProps.onChange("stock_qty", {
      //       value: "",
      //     });
      //   }
    };

    if (isFetchLotOption.current && stock_transfer_guid) {
      isFetchLotOption.current = false;
    } else {
      currentStockCheck();
      isFetchLotOption.current = false;
    }
  }, [grn_no]);

  return null;
};

const CreateStockTransfer: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const stock_transfer_guid = location.state?.stock_transfer_guid;
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.stockTransfer.loading);
  const StockTransferDetail = useAppSelector(
    (state) => state.stockTransfer.StockTransferDetail
  );
  const warehouseList = useAppSelector(
    (state) => state.warehouse.warehouseList
  );
  const ProcessList = useAppSelector((state) => state.process.ProcessList);
  const [formKey, setFormKey] = useState(1);
  const [grnOptions, setGrnOptions] = React.useState([]);
  const [currentStock, setCurrentStock] = React.useState(0);

  useEffect(() => {
    setFormKey(formKey + 1);

    if (
      stock_transfer_guid &&
      StockTransferDetail?.item_id &&
      StockTransferDetail?.outward_no &&
      StockTransferDetail?.from_warehouse_id
    ) {
      const grnPayload = {
        item_id: StockTransferDetail?.item_id,
        receipt_no: StockTransferDetail?.outward_no,
        warehouse_id: StockTransferDetail?.from_warehouse_id,
      };
      fetchGRNOptionsByReceiptNo(grnPayload);
    } else if (
      stock_transfer_guid &&
      StockTransferDetail?.item_id &&
      StockTransferDetail?.from_department_id &&
      StockTransferDetail?.from_warehouse_id
    ) {
      const grnPayload = {
        item_id: StockTransferDetail?.item_id,
        process_id: StockTransferDetail?.from_department_id,
        warehouse_id: StockTransferDetail?.from_warehouse_id,
      };
      fetchGRNOptions(grnPayload);
    }
    if (
      stock_transfer_guid &&
      StockTransferDetail?.item_id &&
      StockTransferDetail?.from_department_id &&
      StockTransferDetail?.grn_no &&
      StockTransferDetail?.from_warehouse_id
    ) {
      const stockPayload = {
        item_id: StockTransferDetail?.item_id,
        process_id: StockTransferDetail?.from_department_id,
        grn_no: StockTransferDetail?.grn_no,
        warehouse_id: StockTransferDetail?.from_warehouse_id,
      };
      fetchCurrentStock(stockPayload);
    }
  }, [StockTransferDetail, stock_transfer_guid]);

  useEffect(() => {
    if (stock_transfer_guid) {
      const payload = {
        stock_transfer_guid: stock_transfer_guid,
      };
      dispatch(getStockTransferByID(payload));
    }
  }, [stock_transfer_guid]);

  useEffect(() => {
    dispatch(findAllActiveProcess());
    dispatch(findAllActiveWarehouse());

    return () => {
      dispatch(clearStockTransferDetails());
    };
  }, []);

  const handleTransferNoChange = (
    ev: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (ev.key === "Enter" || ev.key === "Tab") {
      ev.preventDefault();
      if ((ev.target as HTMLInputElement).value) {
        const payload = {
          stock_transfer_no: (ev.target as HTMLInputElement).value,
        };

        dispatch(getStockTransferByID(payload));
      }
    }
  };

  const fetchGRNOptions = async (grnPayload: any) => {
    try {
      if (grnPayload) {
        const response = await dispatch(getGRNByItemID(grnPayload));
        if (response && response.payload) {
          const result = response.payload.filter(
            (grn: any) => grn?.item_id === grnPayload?.item_id
          );
          const options = result?.map((grnno: any) => ({
            value: `${grnno?.grn_no_string}`,
            label: `${grnno?.grn_no_string} - ${formatIndianNumberForQty(
              grnno?.current_stock || 0
            )}`,
          }));
          setGrnOptions(options);
        } else {
          setGrnOptions([]);
        }
      } else {
        setGrnOptions([]);
      }
    } catch (error) {
      console.error("Failed to fetch GRN options:", error);
      setGrnOptions([]);
    }
  };

  const fetchGRNOptionsByReceiptNo = async (grnPayload: any) => {
    try {
      if (grnPayload) {
        const response = await dispatch(getGRNByItemIDAndReceiptNo(grnPayload));
        if (response && response.payload) {
          const result = response.payload.filter(
            (grn: any) => grn?.item_id === grnPayload?.item_id
          );
          const options = result?.map((grnno: any) => ({
            value: `${grnno?.grn_no_string}`,
            label: `${grnno?.grn_no_string} - ${formatIndianNumberForQty(
              grnno?.current_stock || 0
            )}`,
          }));
          setGrnOptions(options);
        } else {
          setGrnOptions([]);
        }
      } else {
        setGrnOptions([]);
      }
    } catch (error) {
      console.error("Failed to fetch GRN options:", error);
      setGrnOptions([]);
    }
  };

  const fetchCurrentStock = async (stockPayload: any) => {
    try {
      if (stockPayload) {
        const response = await dispatch(getCurrentStock(stockPayload));
        if (response.meta.requestStatus === "fulfilled") {
          setCurrentStock(response.payload?.actual_stock || 0);
        } else {
          setCurrentStock(0);
        }
      } else {
        setCurrentStock(0);
      }
    } catch (error) {
      console.error("Failed to fetch current stock:", error);
      setCurrentStock(0);
    }
  };

  const handleItemChange = async (formRenderProps: FormRenderProps) => {
    // const item_id = e?.value;
    const item_id = formRenderProps.valueGetter("item_id");
    const process_id = formRenderProps.valueGetter("from_department_id");
    const grn_no = formRenderProps.valueGetter("grn_no");
    const from_warehouse_id = formRenderProps.valueGetter("from_warehouse_id");
    const outward_no = formRenderProps.valueGetter("outward_no");

    const grnPayload = {
      item_id: item_id,
      process_id: +process_id,
      warehouse_id: from_warehouse_id,
    };

    const grnByReceiptNoPayload = {
      item_id: item_id,
      receipt_no: outward_no ? `${outward_no}` : "",
      warehouse_id: from_warehouse_id,
    };

    const stockPayload = {
      item_id: item_id,
      process_id: +process_id,
      grn_no: grn_no,
      warehouse_id: +from_warehouse_id,
    };

    if (item_id && outward_no && from_warehouse_id) {
      await fetchGRNOptionsByReceiptNo(grnByReceiptNoPayload);
    } else if (item_id && process_id && from_warehouse_id) {
      await fetchGRNOptions(grnPayload);
    } else {
      formRenderProps.onChange("grn_no", {
        value: null,
      });
    }
    if (item_id && process_id && grn_no && from_warehouse_id) {
      await fetchCurrentStock(stockPayload);
    } else {
      setCurrentStock(0);
    }
  };

  const handleItemSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllItemIncremental({ search }));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = async (values: any) => {
    if (
      values.from_warehouse_id &&
      values.to_warehouse_id &&
      values.from_department_id &&
      values.to_department_id &&
      values.from_warehouse_id === values.to_warehouse_id &&
      values.from_department_id === values.to_department_id
    ) {
      ErrorToast("Warehouse and Department cannot be the same.");
    } else {
      if (stock_transfer_guid) {
        try {
          const updatePayload = {
            stock_transfer_guid: StockTransferDetail?.stock_transfer_guid,
            stock_transfer_date: values?.stock_transfer_date
              ? moment(values?.stock_transfer_date).format("YYYY-MM-DD")
              : "",
            from_warehouse_id: values?.from_warehouse_id
              ? +values?.from_warehouse_id
              : null,
            to_warehouse_id: values?.to_warehouse_id
              ? +values?.to_warehouse_id
              : null,
            from_department_id: values?.from_department_id
              ? +values?.from_department_id
              : null,
            to_department_id: values?.to_department_id
              ? +values?.to_department_id
              : null,
            outward_no: values?.outward_no ? values?.outward_no : "",
            vehicle_no: values?.vehicle_no ? values?.vehicle_no : "",
            katta: values?.katta ? +values?.katta : null,
            item_id: values?.item_id ? +values?.item_id : null,
            grn_no: values?.grn_no ? values?.grn_no : "",
            stock_qty: values?.stock_qty ? +values?.stock_qty : 0,
            vacal_no: values?.vacal_no ? values?.vacal_no : "",
            inward_no: values?.inward_no ? +values?.inward_no : null,
            inward_date: values?.inward_date
              ? moment(values?.inward_date).format("YYYY-MM-DD")
              : "",
            financial_year: FINANCIAL_YEAR,
            remarks: values?.remarks ? values?.remarks : "",
          };
          const response = await dispatch(updateStockTransfer(updatePayload));
          if (response?.meta?.requestStatus === "fulfilled") {
            navigate("/stocktransfer");
          }
        } catch (error) {
          console.error("Error in handleSubmit:", error);
          throw error;
        }
      } else {
        try {
          const insertPayload = {
            stock_transfer_date: values?.stock_transfer_date
              ? moment(values?.stock_transfer_date).format("YYYY-MM-DD")
              : "",
            from_warehouse_id: values?.from_warehouse_id
              ? +values?.from_warehouse_id
              : null,
            to_warehouse_id: values?.to_warehouse_id
              ? +values?.to_warehouse_id
              : null,
            from_department_id: values?.from_department_id
              ? +values?.from_department_id
              : null,
            to_department_id: values?.to_department_id
              ? +values?.to_department_id
              : null,
            outward_no: values?.outward_no ? values?.outward_no : "",
            vehicle_no: values?.vehicle_no ? values?.vehicle_no : "",
            katta: values?.katta ? +values?.katta : null,
            item_id: values?.item_id ? +values?.item_id : null,
            grn_no: values?.grn_no ? values?.grn_no : "",
            stock_qty: values?.stock_qty ? +values?.stock_qty : 0,
            vacal_no: values?.vacal_no ? values?.vacal_no : "",
            inward_no: values?.inward_no ? +values?.inward_no : null,
            inward_date: values?.inward_date
              ? moment(values?.inward_date).format("YYYY-MM-DD")
              : "",
            financial_year: FINANCIAL_YEAR,
            remarks: values?.remarks ? values?.remarks : "",
          };
          const response = await dispatch(createStockTransfer(insertPayload));
          if (response?.meta?.requestStatus === "fulfilled") {
            navigate("/stocktransfer");
          }
        } catch (error) {
          console.error("Error in handleSubmit:", error);
          throw error;
        }
      }
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={StockTransferDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>
                    {stock_transfer_guid
                      ? "Update Stock Transfer"
                      : "Add Stock Transfer"}
                  </Typography.h4>
                </GridLayoutItem>
                {stock_transfer_guid && (
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="w-100 right-alighned-field"
                      name="stock_transfer_no"
                      onKeyDown={handleTransferNoChange}
                      label="Transfer No"
                      placeholder="Transfer No"
                      component={FormTextField}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                )}
                <GridLayoutItem>
                  <Field
                    name="stock_transfer_date"
                    label="Transfer Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={stock_transfer_guid ? 2 : 3}
                ></GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="from_warehouse_id"
                    label="From Warehouse"
                    placeholder="From Warehouse"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    onChange={() => handleItemChange(formRenderProps)}
                    astrike={true}
                    options={warehouseList?.map((warehouse: IWarehouse) => {
                      return {
                        value: warehouse?.id,
                        label: warehouse?.warehouse_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="from_department_id"
                    label="From Department"
                    placeholder="From Department"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    onChange={(e: any) => {
                      formRenderProps.onChange("grn_no", {
                        value: "",
                      });
                      if (e?.value !== 1) {
                        formRenderProps.onChange("outward_no", {
                          value: "",
                        });
                      }
                      handleItemChange(formRenderProps);
                    }}
                    astrike={true}
                    options={ProcessList?.map((process: IProcess) => {
                      return {
                        value: process?.id,
                        label: process?.process_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="to_warehouse_id"
                    label="To Warehouse"
                    placeholder="To Warehouse"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    options={warehouseList?.map((warehouse: IWarehouse) => {
                      return {
                        value: warehouse?.id,
                        label: warehouse?.warehouse_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="to_department_id"
                    label="To Department"
                    placeholder="To Department"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    options={ProcessList?.map((process: IProcess) => {
                      return {
                        value: process?.id,
                        label: process?.process_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="outward_no"
                    label="Outward No"
                    placeholder="Outward No"
                    component={FormTextField}
                    onChange={(e: any) => {
                      formRenderProps.onChange("outward_no", {
                        value: e?.value?.replace(/^\s+/, ""),
                      });
                      formRenderProps.onChange("grn_no", {
                        value: "",
                      });
                      handleItemChange(formRenderProps);
                      if (e?.value) {
                        formRenderProps.onChange("from_department_id", {
                          value: 1,
                        });
                      }
                    }}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="vehicle_no"
                    label="Vehicle No"
                    placeholder="Vehicle No"
                    component={FormTextField}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>

                <GridLayoutItem>
                  <Field
                    name="item_id"
                    label="Product Name"
                    placeholder="Product Name"
                    component={FormIncrementalSearch}
                    validator={requiredValidator}
                    astrike={true}
                    fetchIncrementalData={(search: any) =>
                      handleItemSearchChange(
                        search,
                        `item_options`,
                        formRenderProps
                      )
                    }
                    options={
                      formRenderProps
                        .valueGetter("item_options")
                        ?.map((item: IItem) => {
                          return {
                            value: item.id,
                            label: item.product_name,
                          };
                        }) || []
                    }
                    onChange={() => handleItemChange(formRenderProps)}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="grn_no"
                    label="GRN No"
                    placeholder="GRN No"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    onChange={() => handleItemChange(formRenderProps)}
                    options={grnOptions || []}
                  />
                </GridLayoutItem>

                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    name="stock_qty"
                    label="Quantity"
                    placeholder="0"
                    format="n3"
                    component={FormNumericTextField}
                    validator={requiredValidator}
                    astrike={true}
                    max={
                      stock_transfer_guid
                        ? (StockTransferDetail?.stock_qty || 0) + currentStock
                        : currentStock
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="vacal_no"
                    label="Vacal No"
                    placeholder="Vacal No"
                    component={FormTextField}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    name="katta"
                    label="Katta"
                    placeholder="0"
                    component={FormTextField}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    name="inward_no"
                    label="Inward No"
                    placeholder="0"
                    type="number"
                    component={FormTextField}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={3}
                  rowSpan={2}
                  style={{ display: "flex", alignItems: "end" }}
                >
                  <Field
                    wrapperStyle={{ width: "100%" }}
                    name="remarks"
                    label="Remarks"
                    placeholder="Remarks"
                    component={FormRichTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="inward_date"
                    label="Inward Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  // colSpan={4}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                  }}
                >
                  <ButtonWithLoading
                    label={stock_transfer_guid ? "Update" : "Save"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                  />
                  <Button
                    type="button"
                    fillMode={"outline"}
                    themeColor={"primary"}
                    style={{ marginLeft: 4 }}
                    onClick={() => navigate("/stocktransfer")}
                  >
                    Cancel
                  </Button>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <QtyChangeWatcher
              formRenderProps={formRenderProps}
              // currentStock={currentStock}
            />
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateStockTransfer;
