import React, { useEffect, useState } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import FormDatePicker from "../../../components/formFields/FormDateField";
import moment from "moment";
import { Typography } from "@progress/kendo-react-common";
import ShadowCard from "../../../components/common/ShadowCard";
import { getAllItemIncremental } from "../../Item/services/item.services";
import {
  clearPackingStockReportDetails,
  clearPackingStockReportList,
  setSalesRegisterReport,
} from "../reportsSlice";
import AlertBox from "../../../components/common/AlertBox";
import { LoadingPanel } from "../../../components/layout/Loading";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import FormIncrementalSearch from "../../../components/formFields/FormIncrementalSearch";
import { clearItemList } from "../../Item/itemSlice";
import { Button } from "@progress/kendo-react-buttons";
import Logo from "../../../assets/Images/logo.png";
import {
  checkAcessRights,
  formatIndianNumber,
  formatIndianNumberForQty,
} from "../../../_helper/helper";
import { EXCEL_EXPORT_ACCESS, PRINT_ACCESS } from "../../../_contstants/common";
import { RxCross2 } from "react-icons/rx";
import FormCheckboxGroup from "../../../components/formFields/FormCheckboxGroup";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { BsThreeDotsVertical } from "react-icons/bs";
import { getPackingStoreWiseStockReport } from "../services/reports.services";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { IItemGroup } from "../../ItemGroup/itemgroupModel";
import { findAllActiveItemGroup } from "../../ItemGroup/services/itemgroup.services";
import { IItem } from "../../Item/itemModel";
import { useLocation } from "react-router-dom";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { IItemCategory } from "../../Item Category/ItemCategoryModel";
import { findAllActiveItemCategory } from "../../Item Category/services/Itemcategory.services";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const DummyChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const createdBy = localStorage.getItem("UserID");
  useEffect(() => {
    formRenderProps.onChange("UserID", {
      value: createdBy,
    });
  }, []);

  return null;
};

const PackingStockReportForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.reports.loading);
  const ItemList = useAppSelector((state) => state.item.ItemList);
  const ItemGroupList = useAppSelector(
    (state) => state.itemgroup.ItemGroupList
  );
  const ItemCategoryList = useAppSelector(
    (state) => state.itemCategory.ItemCategoryList
  );
  const PackingStockReportList = useAppSelector(
    (state) => state.reports.PackingStockReportList
  );
  const PackingStockReportDetails = useAppSelector(
    (state) => state.reports.PackingStockReportDetails
  );
  const [initialValue, setInitialValue] = useState<any>({});
  const [formKey, setFormKey] = useState<any>(1);

  useEffect(() => {
    setInitialValue({
      ...PackingStockReportDetails,
      from_date: PackingStockReportDetails?.from_date
        ? moment(PackingStockReportDetails?.from_date, "YYYY-MM-DD").toDate()
        : null,
      to_date: PackingStockReportDetails?.to_date
        ? moment(PackingStockReportDetails?.to_date, "YYYY-MM-DD").toDate()
        : null,
    });
    setFormKey(formKey + 1);
  }, [PackingStockReportDetails]);

  useEffect(() => {
    dispatch(findAllActiveItemGroup());
    dispatch(findAllActiveItemCategory());
  }, []);

  const handleSubmit = (values: any) => {
    const payload = {
      item_id: values?.item_id ? values?.item_id : null,
      item_group_id: values?.item_group_id ? values?.item_group_id : null,
      item_category_id: values?.item_category_id
        ? values?.item_category_id
        : null,
      from_date: values?.from_date
        ? moment(values?.from_date).format("YYYY-MM-DD")
        : null,
      to_date: values?.to_date
        ? moment(values?.to_date).format("YYYY-MM-DD")
        : null,
    };
    const setPayload = {
      ...payload,
      item_options: values?.item_options || [],
      isPackingStockRef: true,
    };
    dispatch(setSalesRegisterReport(setPayload));
    dispatch(getPackingStoreWiseStockReport(payload));
  };

  return (
    <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
      <GridLayoutItem>
        <ShadowCard>
          <Form
            key={formKey}
            initialValues={initialValue}
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 30 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                  ]}
                >
                  <GridLayoutItem colSpan={4}>
                    <Typography.h4
                      style={{ marginBottom: 0, marginLeft: "3px" }}
                    >
                      Packing Stock Report
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="from_date"
                      label="From Date"
                      format="dd/MM/yyyy"
                      component={FormDatePicker}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="to_date"
                      label="To Date"
                      format="dd/MM/yyyy"
                      minDate={moment(
                        formRenderProps.valueGetter("from_date")
                      ).toDate() || new Date(1900, 0, 1)}
                      component={FormDatePicker}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="item_group_id"
                      label="Product Group Name"
                      placeholder="Product Group Name"
                      component={FormSelectionField}
                      options={ItemGroupList?.map((itemgroup: IItemGroup) => {
                        return {
                          value: itemgroup?.id,
                          label: itemgroup?.item_group,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="item_category_id"
                      label="Product Category Name"
                      placeholder="Product Category Name"
                      component={FormSelectionField}
                      options={ItemCategoryList?.map(
                        (itemCategory: IItemCategory) => {
                          return {
                            value: itemCategory?.id,
                            label: itemCategory?.item_category,
                          };
                        }
                      )}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="w-100"
                      name="item_id"
                      label="Product Name"
                      placeholder="Type here to search item..."
                      component={FormIncrementalSearch}
                      fetchIncrementalData={(search: any) =>
                        !formRenderProps.valueGetter("item_group_id") ||
                        formRenderProps.valueGetter("item_group_id") === null
                          ? dispatch(
                              getAllItemIncremental({
                                search: search,
                              })
                            )
                          : dispatch(
                              getAllItemIncremental({
                                search: search,
                                item_group_id:
                                  formRenderProps.valueGetter("item_group_id"),
                              })
                            )
                      }
                      //   loading={itemLoading}
                      options={ItemList?.map((item: IItem) => {
                        return {
                          value: item?.id,
                          label: item?.product_name,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    colSpan={3}
                    style={{
                      textAlign: "end",
                      marginTop: 36,
                    }}
                  >
                    <ButtonWithLoading
                      label={"View"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                  </GridLayoutItem>
                </GridLayout>
                <DummyChangeWatcher formRenderProps={formRenderProps} />
              </FormElement>
            )}
          />
        </ShadowCard>
        {/* {loading ? (
          <LoadingPanel gridRef={gridRef} />
        ) : ( */}
        {PackingStockReportList && PackingStockReportList.length > 0 && (
          <ShadowCard style={{ marginTop: 10 }}>
            <SalesRegisterGridCommponent />
          </ShadowCard>
        )}
        {/* )} */}
      </GridLayoutItem>
    </GridLayout>
  );
};

const columns = [
  { label: "Product Name", value: "product_name" },
  { label: "Opening Qty", value: "opening_qty" },
  { label: "Inward Qty", value: "inward_qty" },
  { label: "Outward Qty", value: "outward_qty" },
  { label: "Closing Qty", value: "closing_qty" },
];
const SalesRegisterGridCommponent: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const gridRef = React.useRef<any>(null);
  const isPrintAccess = checkAcessRights(location.pathname, PRINT_ACCESS);
  const isExcelAccess = checkAcessRights(
    location.pathname,
    EXCEL_EXPORT_ACCESS
  );

  const loading = useAppSelector((state) => state.reports.loading);
  const PackingStockReportList = useAppSelector(
    (state) => state.reports.PackingStockReportList
  );
  const PackingStockReportDetails = useAppSelector(
    (state) => state.reports.PackingStockReportDetails
  );
  const [showfilterColumns, setShowFilterColumns] = React.useState(false);
  //   const [selectedGroup, setSelectedGroup] = React.useState<string | null>(null);

  useEffect(() => {
    return () => {
      dispatch(clearPackingStockReportList());
      dispatch(clearPackingStockReportDetails());
      dispatch(clearItemList());
    };
  }, []);

  const handleExportExcel = () => {
    const datetime = moment().format("DD-MM-YYYY HH-mm");
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.table_to_sheet(
      document.querySelector(".currentstock-print-table")
    );
    XLSX.utils.book_append_sheet(wb, ws, "PackingstockReport");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(data, `Packing Stock Report ${datetime}`);
  };

  //   const handleRowClick = (item_id: string) => {
  //     const groupKey = `${item_id}`;
  //     setSelectedGroup(selectedGroup === groupKey ? null : groupKey);
  //   };

  const initialValues = {
    filter_columns: [
      "product_name",
      "opening_qty",
      "inward_qty",
      "outward_qty",
      "closing_qty",
    ],
  };

  const printTable = (data: any[], formRenderProps: any) => {
    let printbuttons = document.querySelector(
      ".printbuttons"
    ) as HTMLElement | null;
    let report_container = document.querySelector(
      ".report-container"
    ) as HTMLElement | null;

    if (printbuttons) printbuttons.style.display = "none";
    if (report_container) report_container.style.padding = "20px 0";

    let tableRows = data.length;
    let maxRows = tableRows;
    let pageCount = Math.ceil(tableRows / maxRows);
    let content = "";

    for (let i = 0; i < pageCount; i++) {
      content += '<div class="print-page">';
      content +=
        '<table class="table table-bordered" style="font-size:10px;margin-bottom:30px;">';
      content += "<tr>";
      content += `<td rowSpan="3" style="width:15%" ><img src="${Logo}" width="100%" /></td>`;
      content +=
        '<td colSpan="2" style="text-align: center;font-size: 14px;font-weight: 600;color: gray;">Harihar Foods Pvt Ltd.</td>';
      content += "</tr>";
      content += "<tr>";
      content +=
        '<td colSpan="2"><h3 style="margin:0;">Packing Stock Report</h3></td>';
      content += "</tr>";
      content += "<tr>";
      content += `<td style="text-align:end;">Page : ${i + 1}</td>`;
      content += "</tr>";

      content += "</table>";

      content += '<table class="table table-bordered" style="font-size:10px;">';
      content += '<thead style="background:lightgray;">';
      content += "<tr>";
      content += `<th style="width:30px;">Sr No.</th>`;
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("product_name") && (content += "<th>Product Name</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("lot_no") &&
          (content += "<th>Lot No.</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("opening_qty") && (content += "<th>Opening Qty</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("inward_qty") &&
          (content += "<th>Inward Qty</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("outward_qty") && (content += "<th>Outward Qty</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("closing_qty") && (content += "<th>Closing Qty</th>");
      }
      content += "</tr>";
      content += "</thead>";
      content += "<tbody>";

      data.map((item: any, index: any) => {
        content += "<tr>";

        content += `<td style="text-align:center;">${index + 1}</td>`;
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("product_name") &&
            (content += `<td>${item.product_name}</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("opening_qty") &&
            (content += `<td style="text-align:end;">${
              item.opening_qty ? formatIndianNumber(item.opening_qty) : 0
            }</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("inward_qty") &&
            (content += `<td style="text-align:end;">${
              item.inward_qty ? formatIndianNumber(item.inward_qty) : 0
            }</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("outward_qty") &&
            (content += `<td style="text-align:end;">${
              item.outward_qty ? formatIndianNumber(item.outward_qty) : 0
            }</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("closing_qty") &&
            (content += `<td style="text-align:end;">${
              item.closing_qty ? formatIndianNumber(item.closing_qty) : 0
            }</td>`);
        }
        content += "</tr>";
      });

      content += "</tbody>";
      content += "</table>";

      content += "</div>";
    }

    let printWindow = window.open("", "_blank");
    if (!printWindow) {
      console.error("Failed to open print preview window.");
      return;
    }
    printWindow.document.write(`
        <html>
        <head>
          <title>Print Preview</title>
          <style>
            @page { margin: 1cm; }
            .print-page { page-break-after: always; }
            .table-bordered { width: 100%; border-collapse: collapse; }
            .table-bordered th, .table-bordered td { border: 1px solid black; padding: 8px; }
            body { font-family: Arial, sans-serif; max-width:1140px; margin: 0 auto;}
          </style>
        </head>
        <body>
          ${content}
          <script>
            window.onload = function() {
              window.print();
            };
          </script>
        </body>
        </html>
      `);
    printWindow.document.close();

    if (printbuttons) printbuttons.style.display = "block";
    if (report_container) report_container.style.padding = "20px 10px";
  };

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      {PackingStockReportList && PackingStockReportList.length ? (
        <Form
          initialValues={initialValues}
          render={(formRenderProps: FormRenderProps) => {
            return (
              <FormElement
                style={{ width: "100%", minHeight: "calc(100vh - 324px)" }}
              >
                <GridLayout
                  gap={{ rows: 10, cols: 10 }}
                  cols={[{ width: "100%" }]}
                >
                  <GridLayoutItem className="d-flex justify-content-between align-items-center">
                    <Typography.h4 className="m-0">
                      Packing Stock Report List{" "}
                      {(PackingStockReportDetails?.from_date ||
                        PackingStockReportDetails?.to_date) && (
                        <span style={{ fontSize: 13 }}>
                          {"("}
                          {PackingStockReportDetails?.from_date
                            ? moment(
                                PackingStockReportDetails?.from_date,
                                "YYYY-MM-DD"
                              ).format("DD/MM/YYYY")
                            : ""}
                          {PackingStockReportDetails?.from_date &&
                            PackingStockReportDetails?.to_date &&
                            " - "}
                          {PackingStockReportDetails?.to_date
                            ? moment(
                                PackingStockReportDetails?.to_date,
                                "YYYY-MM-DD"
                              ).format("DD/MM/YYYY")
                            : ""}
                          {")"}
                        </span>
                      )}
                    </Typography.h4>
                    <div className="position-relative d-flex">
                      {isExcelAccess && (
                        <Button
                          type="button"
                          fillMode={"solid"}
                          themeColor={"primary"}
                          onClick={handleExportExcel}
                          style={{ marginRight: 5 }}
                        >
                          Excel Export
                        </Button>
                      )}
                      {isPrintAccess && (
                        <Button
                          style={{ marginRight: 5 }}
                          type="button"
                          fillMode={"solid"}
                          themeColor={"primary"}
                          onClick={() =>
                            printTable(PackingStockReportList, formRenderProps)
                          }
                        >
                          Print
                        </Button>
                      )}
                      <Tooltip
                        anchorElement="target"
                        position="left"
                        parentTitle={true}
                      >
                        <Button
                          title="Filter Columns"
                          type="button"
                          themeColor={"primary"}
                          fillMode={"solid"}
                          onClick={() => setShowFilterColumns(true)}
                        >
                          Filter
                          <BsThreeDotsVertical />
                        </Button>
                      </Tooltip>
                      {showfilterColumns && (
                        <div
                          style={{
                            position: "absolute",
                            top: "32px",
                            right: "0",
                            background: "white",
                            padding: "12px",
                            border: "1px solid whitesmoke",
                            width: "250px",
                            zIndex: 2,
                          }}
                        >
                          <div className="d-flex justify-content-between align-items-baseline">
                            <p className="m-0 fw-600">Filter</p>
                            <Button
                              themeColor={"primary"}
                              fillMode={"flat"}
                              onClick={() => setShowFilterColumns(false)}
                            >
                              <RxCross2 />
                            </Button>
                          </div>
                          <hr className="m-1" style={{ color: "lightgray" }} />
                          <Field
                            wrapperClassName="d-flex flex-column checkBoxGroup-vertical"
                            inputClassName="checkBoxGroup-vertical-input"
                            id={"filter_columns"}
                            name={"filter_columns"}
                            marginRight={0}
                            marginBeetween={8}
                            component={FormCheckboxGroup}
                            options={columns}
                          />
                        </div>
                      )}
                    </div>
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{
                      maxHeight: "calc(100vh - 380px)",
                      overflow: "scroll",
                      scrollbarWidth: "thin",
                    }}
                  >
                    <table
                      cellSpacing={1}
                      cellPadding={2}
                      className="table table-bordered w-100  currentstock-print-table"
                      style={{
                        marginTop: 1,
                      }}
                    >
                      <tr
                        className="text-center fw-600"
                        style={{
                          fontSize: 12,
                          position: "sticky",
                          top: 0,
                          outline: "1px solid #3b3b3b",
                          zIndex: 1,
                        }}
                      >
                        <th style={{ minWidth: 80 }}>Sr. No.</th>
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("product_name") && (
                          <th style={{ minWidth: 250 }}>Product Name</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("opening_qty") && (
                          <th style={{ minWidth: 150 }}>Opening Qty</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("inward_qty") && (
                          <th style={{ minWidth: 150 }}>Inward Qty</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("outward_qty") && (
                          <th style={{ minWidth: 150 }}>Outward Qty</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("closing_qty") && (
                          <th style={{ minWidth: 150 }}>Closing Qty</th>
                        )}
                      </tr>
                      {PackingStockReportList &&
                        PackingStockReportList?.length > 0 &&
                        PackingStockReportList?.map(
                          (item: any, index: number) => {
                            // const groupKey = `${item?.item_id}`;
                            // const isSelected = selectedGroup === groupKey;

                            return (
                              <tr
                                className={`text-center`}
                                // style={{
                                //   background: isSelected
                                //     ? "rgb(255 198 198 / 53%)"
                                //     : "transparent",
                                // }}
                                // onClick={() => handleRowClick(item.item_id)}
                              >
                                <td>{index + 1}</td>
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("product_name") && (
                                  <td className="text-start">
                                    {item?.product_name}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("opening_qty") && (
                                  <td className="text-end">
                                    {formatIndianNumberForQty(
                                      item?.opening_qty
                                    ) || 0}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("inward_qty") && (
                                  <td className="text-end">
                                    {formatIndianNumberForQty(
                                      item?.inward_qty
                                    ) || 0}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("outward_qty") && (
                                  <td className="text-end">
                                    {formatIndianNumberForQty(
                                      item?.outward_qty
                                    ) || 0}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("closing_qty") && (
                                  <td className="text-end">
                                    {formatIndianNumberForQty(
                                      item?.closing_qty
                                    ) || 0}
                                  </td>
                                )}
                              </tr>
                            );
                          }
                        )}
                    </table>
                  </GridLayoutItem>
                </GridLayout>
              </FormElement>
            );
          }}
        />
      ) : (
        <AlertBox />
      )}
    </>
  );
};
export default PackingStockReportForm;
