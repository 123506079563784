import {
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useEffect, useRef } from "react";
import AddDistributorCompititorArray from "./AddDistributorCompititorArray";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Button } from "@progress/kendo-react-buttons";
import { getAllActiveCompetitors } from "../competition/services/competitorsServices";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getCompititorsBySRRetailerID,
  getSelctedInSalesOrderWiseItemGroup,
  insertVisitCompititors,
  updateVisitCompititors,
} from "./services/distributorOrder.services";
import { LoadingPanel } from "../../components/layout/Loading";
import moment from "moment";
import { ErrorToast } from "../../components/toast/Toasts";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const CompititorsChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const compitior_id = location.state?.compitior_id;
  const CompetitorsList = useAppSelector(
    (state) => state.competitors.CompetitorsList
  );
  const compititors = formRenderProps.valueGetter("compititors");
  const competitorsData =
    CompetitorsList &&
    CompetitorsList?.map((compititor: any) => {
      return {
        compititor_id: compititor?.id,
        compititor_name: compititor?.competitors,
        ischeck: false,
        item_group_id: [],
        remarks: "",
      };
    });

  useEffect(() => {
    formRenderProps.onChange("compititors", {
      value: competitorsData,
    });
  }, [CompetitorsList]);

  useEffect(() => {
    if (CompetitorsList && compitior_id) {
      const fetchCompititorsByID = async () => {
        const res = await dispatch(getCompititorsBySRRetailerID(compitior_id));

        compititors &&
          compititors?.length > 0 &&
          compititors?.map((compititor: any, index: number) => {
            const match = res.payload?.find(
              (item: any) => item?.compititor_id === compititor?.compititor_id
            );
            formRenderProps.onChange(`compititors.${index}.remarks`, {
              value: match?.remarks || "",
            });
            formRenderProps.onChange(`compititors.${index}.ischeck`, {
              value: match?.ischeck || false,
            });
            formRenderProps.onChange(`compititors.${index}.item_group_id`, {
              value: match?.item_group_id || [],
            });
          });
      };
      fetchCompititorsByID();
    }
  }, [compitior_id, CompetitorsList]);

  return null;
};

const DistributorCompititor: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const distributor_id = location.state?.distributor_id;
  const sr_retailer_id = location.state?.sr_retailer_id;
  const compitior_id = location.state?.compitior_id;
  const date = location.state?.date;

  const distributorOrderLoading = useAppSelector(
    (state) => state.distributorOrder.loading
  );

  useEffect(() => {
    dispatch(getSelctedInSalesOrderWiseItemGroup());
    dispatch(getAllActiveCompetitors());
  }, []);

  const handleSubmit = async (values: any) => {
    const isValidate =
      values?.compititors &&
      values?.compititors?.filter((compititor: any) => compititor?.ischeck)
        ?.length;
    if (isValidate < 1) {
      ErrorToast("Please Select Atleast One Competitor.");
    } else {
      const compititorsData = values?.compititors
        ? values?.compititors
            ?.filter((compititor: any) => compititor?.ischeck)
            ?.map((compititor: any) => {
              return {
                compititor_id: compititor?.compititor_id
                  ? +compititor?.compititor_id
                  : null,
                // sr_retailer_id: sr_retailer_id ? +sr_retailer_id : null,
                ischeck: compititor?.ischeck ? compititor?.ischeck : false,
                item_group_id:
                  compititor?.item_group_id &&
                  compititor?.item_group_id?.length > 0
                    ? compititor?.item_group_id?.join(", ")
                    : "",
                remarks: compititor?.remarks ? compititor?.remarks : "",
              };
            })
        : [];

      if (compitior_id) {
        const editPayload: any = {
          sr_retailer_id: compitior_id ? +compitior_id : null,
          compititors: compititorsData,
        };
        const response = await dispatch(updateVisitCompititors(editPayload));
        if (response.meta.requestStatus === "fulfilled") {
          navigate("/distributororder", {
            state: {
              distributor_id: distributor_id ? +distributor_id : null,
              date: date ? moment(date, "YYYY-MM-DD").toDate() : "",
            },
          });
        }
      } else {
        const insertPayload: any = {
          sr_retailer_id: sr_retailer_id ? +sr_retailer_id : null,
          compititors: compititorsData,
        };
        const response = await dispatch(insertVisitCompititors(insertPayload));
        if (response.meta.requestStatus === "fulfilled") {
          navigate("/distributororder", {
            state: {
              distributor_id: distributor_id ? +distributor_id : null,
              date: date ? moment(date, "YYYY-MM-DD").toDate() : "",
            },
          });
        }
      }
    }
  };

  return (
    <>
      {distributorOrderLoading && <LoadingPanel gridRef={gridRef} />}
      <Form
        onSubmit={handleSubmit}
        render={(formRenderProps: FormRenderProps) => {
          return (
            <FormElement>
              <div className="container-fluid">
                <div className="container">
                  <div className="row">
                    <div
                      className="col-12 p-0"
                      style={{ overflowX: "scroll", scrollbarWidth: "thin" }}
                    >
                      <FieldArray
                        formRenderProps={formRenderProps}
                        component={AddDistributorCompititorArray}
                        name="compititors"
                      />
                    </div>
                    <div className="col-12 mt-3 mb-3 p-0 d-sm-flex gap-2">
                      <div className="text-center">
                        <Button
                          themeColor={"primary"}
                          fillMode={"solid"}
                          type="submit"
                        >
                          Save Compititor Details
                        </Button>
                      </div>
                      <div className="mt-2 mt-sm-0 text-center">
                        {compitior_id ? (
                          <Button
                            themeColor={"primary"}
                            fillMode={"outline"}
                            type="button"
                            onClick={() =>
                              navigate("/distributororder", {
                                state: {
                                  distributor_id: distributor_id
                                    ? +distributor_id
                                    : null,
                                  date: date
                                    ? moment(date, "YYYY-MM-DD").toDate()
                                    : "",
                                },
                              })
                            }
                          >
                            Cancel
                          </Button>
                        ) : (
                          <Button
                            themeColor={"primary"}
                            fillMode={"outline"}
                            type="button"
                            onClick={() =>
                              navigate("/distributororder", {
                                state: {
                                  distributor_id: distributor_id
                                    ? +distributor_id
                                    : null,
                                  date: date
                                    ? moment(date, "YYYY-MM-DD").toDate()
                                    : "",
                                },
                              })
                            }
                          >
                            Skip Compititor Details
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <CompititorsChangeWatcher formRenderProps={formRenderProps} />
            </FormElement>
          );
        }}
      />
    </>
  );
};

export default DistributorCompititor;
