import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createCreditnoteVoucher,
  deleteCreditnoteVoucher,
  getAllOutstandingCreditnoteVoucher,
  getAllCreditnoteVoucher,
  getCreditnoteVoucherByID,
  updateCreditnoteVoucher,
} from "./services/creditnoteVoucher";
import {
  ICreditnoteVoucher,
  ICreditnoteVoucherInitialState,
} from "./creditnoteVoucherModel";
import moment from "moment";

const initialState: ICreditnoteVoucherInitialState = {
  loading: false,
  error: "",
  CreditnoteVoucherEntryDeleteIndex: -1,
  CreditnoteVoucherBillDialogOpenIndex: -1,
  CreditnoteVoucherID: null,
  CreditnoteVoucherGUID: "",
  CreditnoteVoucherList: [],
  OutstandingCreditnoteVoucherList: [],
  CreditnoteVoucherDetail: {
    financial_year: null,
    ledger_id: null,
    voucher_type_id: null,
    remarks: "",
    credit_note_voucher_guid: "",
    credit_note_voucher_no: null,
    credit_note_voucher_no_string: "",
    credit_note_voucher_date: moment().toDate(),
    credit_note_voucher_total_amount: null,
    credit_note_voucher_entry: [],
  },
};

const creditnoteVoucherSlice = createSlice({
  name: "creditnotevoucher",
  initialState,
  reducers: {
    setCreditnoteVoucherID: (state, action) => {
      state.CreditnoteVoucherID = action.payload;
    },
    setCreditnoteVoucherGUID: (state, action) => {
      state.CreditnoteVoucherGUID = action.payload;
    },
    clearCreditnoteVoucherDetails: (state) => {
      state.CreditnoteVoucherDetail = initialState.CreditnoteVoucherDetail;
    },
    setCreditnoteVoucherEntryDeleteIndex: (state, action) => {
      state.CreditnoteVoucherEntryDeleteIndex = action.payload;
    },
    setCreditnoteVoucherBillDialogOpenIndex: (state, action) => {
      state.CreditnoteVoucherBillDialogOpenIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCreditnoteVoucher.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllCreditnoteVoucher.fulfilled,
      (state, action: PayloadAction<ICreditnoteVoucher[]>) => {
        state.loading = false;
        state.CreditnoteVoucherList = action.payload || [];
      }
    );
    builder.addCase(getAllCreditnoteVoucher.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.CreditnoteVoucherList = [];
    });

    builder.addCase(createCreditnoteVoucher.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createCreditnoteVoucher.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createCreditnoteVoucher.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateCreditnoteVoucher.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateCreditnoteVoucher.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCreditnoteVoucher.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteCreditnoteVoucher.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteCreditnoteVoucher.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteCreditnoteVoucher.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getCreditnoteVoucherByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getCreditnoteVoucherByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.CreditnoteVoucherDetail = action.payload;
    });
    builder.addCase(getCreditnoteVoucherByID.rejected, (state, action) => {
      state.loading = false;
      state.CreditnoteVoucherDetail = initialState.CreditnoteVoucherDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getAllOutstandingCreditnoteVoucher.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllOutstandingCreditnoteVoucher.fulfilled,
      (state, action: any) => {
        state.loading = false;
        state.OutstandingCreditnoteVoucherList = action.payload;
      }
    );
    builder.addCase(
      getAllOutstandingCreditnoteVoucher.rejected,
      (state, action) => {
        state.loading = false;
        state.OutstandingCreditnoteVoucherList =
          initialState.OutstandingCreditnoteVoucherList;
        state.error = action.error.message || "Something went wrong";
      }
    );
  },
});

export const {
  setCreditnoteVoucherID,
  setCreditnoteVoucherBillDialogOpenIndex,
  setCreditnoteVoucherEntryDeleteIndex,
  setCreditnoteVoucherGUID,
  clearCreditnoteVoucherDetails,
} = creditnoteVoucherSlice.actions;
export default creditnoteVoucherSlice.reducer;
