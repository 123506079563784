import React, { useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import ShadowCard from "../../components/common/ShadowCard";
import FormDatePicker from "../../components/formFields/FormDateField";
import { FINANCIAL_YEAR } from "../../_contstants/common";
import moment from "moment";
import JournalEntryDetailsArray from "./JournalEntryDetailsArray";
import {
  createJournal,
  generateJournalNoForJournal,
  getJournalByID,
  updateJournal,
} from "./services/journal.services";
import {
  clearJournalDetails,
  setJournalBillDialogOpenIndex,
} from "./journalSlice";
import JournalBillDetailsArray from "./JournalBillDetailsArray";
import { getAllAccountIncremental } from "../account/services/account.services";
import FormTextArea from "../../components/formFields/FormTextArea";
import { ErrorToast } from "../../components/toast/Toasts";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { findAllVoucherTypeByType } from "../vouchertype/services/voucherType.services";
import { findIndex } from "lodash";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const BillJournalEntryChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const journal_bill_entry =
    formRenderProps.valueGetter("journal_bill_entry") || [];
  const journal_voucher_entry = formRenderProps.valueGetter(
    "journal_voucher_entry"
  );
  const JournalBillDialogOpenIndex = useAppSelector(
    (state) => state.journal.JournalBillDialogOpenIndex
  );
  const OutstandingJournalList = useAppSelector(
    (state) => state.journal.OutstandingJournalList
  );
  const location = useLocation();
  const journal_voucher_guid = location.state?.journal_voucher_guid;

  useEffect(() => {
    const entryReference =
      journal_voucher_entry?.[JournalBillDialogOpenIndex]
        ?.journal_voucher_entry_referance;
    if (JournalBillDialogOpenIndex >= 0 && entryReference?.length > 0) {
      const journalRefID = entryReference?.[0]?.journal_voucher_ref_id;
      if (journal_voucher_guid && journalRefID) {
        //=== THIS IS FOR OLD BILL ENTRY FIRST TIME TAB IN EDIT MODE ===//
        const referenceMap = new Map<
          number,
          { refernace_name: string; referance_amount: number }
        >(
          entryReference.map((entry: any) => [
            entry.refernace_bill_id,
            {
              refernace_name: entry.refernace_name,
              referance_amount: entry.referance_amount,
            },
          ])
        );
        // const mergedArray = OutstandingJournalList.map((bill: any) => {
        //   const match = referenceMap.get(bill.id);
        //   const pendingAmountFullPaid = bill.final_pending_amount;
        //   if (match && match?.refernace_name === bill.bill_no_string) {
        //     if (!pendingAmountFullPaid) {
        //       return {
        //         ...bill,
        //         referance_amount: match.referance_amount,
        //         isfullpayment: true,
        //       };
        //     } else {
        //       return {
        //         ...bill,
        //         referance_amount: match.referance_amount,
        //         isfullpayment: false,
        //       };
        //     }
        //   }
        //   return { ...bill }; // Return the bill unchanged if no match
        // });

        // Merge with OutstandingJournalList
        const mergedArray = OutstandingJournalList.map((bill: any) => {
          const match = referenceMap.get(bill.id);
          const isFullPayment = !bill.final_pending_amount;

          return match && match.refernace_name === bill.bill_no_string
            ? {
                ...bill,
                referance_amount: match.referance_amount,
                isfullpayment: isFullPayment,
              }
            : { ...bill }; // Return unchanged if no match
        });
        formRenderProps.onChange("journal_bill_entry", {
          value: mergedArray || [],
        });
      } else {
        formRenderProps.onChange("journal_bill_entry", {
          value: entryReference || [],
        });
      }
    } else {
      // Use structuredClone for deep copy (modern alternative to JSON trick)
      const clonedOutstandingList = structuredClone(OutstandingJournalList);
      formRenderProps.onChange("journal_bill_entry", {
        value: clonedOutstandingList,
      });
    }
  }, [JournalBillDialogOpenIndex, OutstandingJournalList?.[0]?.id]);

  useEffect(() => {
    const calculateTotals = (entries: typeof journal_voucher_entry) => {
      const totals: Record<string, number> = {};
      entries.forEach((entry: any) => {
        if (entry?.ledger_id) {
          const key = `${entry.debit_credit}Total`;
          totals[key] = (totals[key] || 0) + entry.amount;
        }
      });
      return totals;
    };
    const result = calculateTotals(journal_voucher_entry);
    formRenderProps.onChange(`journal_voucher_total_amount`, {
      value: result.CreditTotal || 0,
    });
    if (journal_voucher_entry && journal_voucher_entry?.length > 0) {
      formRenderProps.onChange(`credit_debit_total`, {
        value: {
          DebitTotal: result?.DebitTotal || 0,
          CreditTotal: result?.CreditTotal || 0,
        },
      });
    }
  }, [
    journal_voucher_entry?.map((item: any) => item?.amount).join("-"),
    journal_voucher_entry?.map((item: any) => item?.debit_credit).join("-"),
    journal_voucher_entry?.map((item: any) => item?.ledger_id).join("-"),
  ]);

  useEffect(() => {
    if (JournalBillDialogOpenIndex < 0) return;
    // Retrieve the current journal entry
    // Get the selected journal entry
    const selectedJournalEntry =
      journal_voucher_entry[JournalBillDialogOpenIndex];
    if (!selectedJournalEntry) return;

    console.log("Hello Running");
    // Calculate total referenced amount
    const totalReferencedAmount = journal_bill_entry.reduce(
      (sum: number, item: any) => sum + (item?.referance_amount || 0),
      0
    );

    // Compute remaining amount
    const remainingAmount =
      (selectedJournalEntry.amount ?? 0) - totalReferencedAmount;

    // Retrieve ledger name
    const ledgerName = selectedJournalEntry.ledger_options?.find(
      (account: any) => account?.id === selectedJournalEntry.ledger_id
    )?.account_name;

    // Clone journal entry and update its reference
    const updatedJournalEntry = {
      ...selectedJournalEntry,
      journal_voucher_entry_referance: [...journal_bill_entry],
    };

    // Construct the updated index details object
    const updatedIndexDetails = {
      ...updatedJournalEntry,
      remainingAmount,
      ledger_name: ledgerName,
    };

    // Update the form state
    formRenderProps.onChange("IndexDetails", { value: updatedIndexDetails });

    // Update the main journal entry array safely
    journal_voucher_entry[JournalBillDialogOpenIndex] = updatedJournalEntry;

    // if (JournalBillDialogOpenIndex >= 0) {
    //   let TotalAmount = 0;
    //   const JournalEntryOpenIndex =
    //     journal_voucher_entry[JournalBillDialogOpenIndex];
    //   if (JournalEntryOpenIndex) {
    //     journal_voucher_entry[JournalBillDialogOpenIndex] = {
    //       ...JournalEntryOpenIndex,
    //       journal_voucher_entry_referance: [...journal_bill_entry],
    //     };
    //   }
    //   if (
    //     journal_voucher_entry[JournalBillDialogOpenIndex]
    //       ?.journal_voucher_entry_referance?.length > 0
    //   ) {
    //     TotalAmount = journal_voucher_entry[
    //       JournalBillDialogOpenIndex
    //     ]?.journal_voucher_entry_referance?.reduce(
    //       (total: number, item: any) => total + (item?.referance_amount || 0),
    //       0
    //     );
    //   } else {
    //     TotalAmount = 0;
    //   }
    //   const RemaingAmount =
    //     (JournalEntryOpenIndex?.amount ?? 0) - (TotalAmount ?? 0);
    //   const IndexDetailsObj = {
    //     remainingAmount: RemaingAmount,

    //     ledger_name: JournalEntryOpenIndex?.ledger_options?.find(
    //       (account: any) => account?.id === JournalEntryOpenIndex?.ledger_id
    //     )?.account_name,
    //     ...JournalEntryOpenIndex,
    //   };
    //   formRenderProps.onChange(`IndexDetails`, {
    //     value: IndexDetailsObj || 0,
    //   });
    // }
  }, [
    JournalBillDialogOpenIndex,
    // OutstandingJournalList?.[0]?.id,
    journal_voucher_entry?.map((item: any) => item?.amount).join("-"),
    // journal_voucher_entry?.map((item: any) => item?.debit_credit).join("-"),
    // journal_voucher_entry?.map((item: any) => item?.ledger_id).join("-"),
    journal_bill_entry?.map((item: any) => item?.referance_amount).join("-"),
  ]);

  return null;
};

const JournalNoChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const journal_voucher_guid = location.state?.journal_voucher_guid;
  // const isJournalNoRef = useRef(false);
  const voucher_type_id = formRenderProps.valueGetter("voucher_type_id");
  const journal_voucher_no_string = formRenderProps.valueGetter(
    "journal_voucher_no_string"
  );
  const VoucherTypeList = useAppSelector(
    (state) => state.voucherType.VoucherTypeList
  );
  const JournalDetail = useAppSelector((state) => state.journal.JournalDetail);

  useEffect(() => {
    const fetchJournalNo = async () => {
      const payload = {
        financial_year: FINANCIAL_YEAR,
        voucher_type_id: +voucher_type_id,
      };
      const response = await dispatch(generateJournalNoForJournal(payload));
      if (response.meta.requestStatus === "fulfilled") {
        formRenderProps.onChange("journal_voucher_no_string", {
          value: response.payload?.journal_voucher_no_string || "",
        });
        formRenderProps.onChange("journal_no", {
          value: response.payload?.journal_voucher_no_string || "",
        });
        const ledgerIDIndex = findIndex(VoucherTypeList, {
          id: voucher_type_id,
        });
        if (ledgerIDIndex > -1) {
          formRenderProps.onChange("ledger_id", {
            value: VoucherTypeList?.[ledgerIDIndex]?.map_ledger_id,
          });
        } else {
          formRenderProps.onChange("ledger_id", {
            value: null,
          });
        }
      }
    };
    if (voucher_type_id && !journal_voucher_guid) {
      fetchJournalNo();
    }
  }, [voucher_type_id]);

  useEffect(() => {
    const fetchJournalNo = async () => {
      const oldkey = JournalDetail?.journal_voucher_no_string
        ?.split("/")
        ?.slice(0, -1)
        .join("/");
      if (
        journal_voucher_no_string?.split("/").length ===
        JournalDetail?.journal_voucher_no_string?.split("/").length
      ) {
        const modifiyed = journal_voucher_no_string?.split("/")?.pop();
        formRenderProps.onChange("journal_voucher_no_string", {
          value: `${oldkey}/${modifiyed}`,
        });
        formRenderProps.onChange("journal_voucher_no", {
          value: `${modifiyed}`,
        });
      } else {
        formRenderProps.onChange("journal_voucher_no_string", {
          value: `${oldkey}/`,
        });
      }
    };
    if (journal_voucher_guid) {
      fetchJournalNo();
    }
  }, [journal_voucher_no_string]);

  return null;
};

const CreateJournal = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const journal_voucher_guid = location.state?.journal_voucher_guid;
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.journal.loading);
  const JournalDetail = useAppSelector((state) => state.journal.JournalDetail);

  const VoucherTypeList = useAppSelector(
    (state) => state.voucherType.VoucherTypeList
  );

  const [formKey, setFormKey] = React.useState(1);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [JournalDetail]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" || event.key === "Esc") {
        dispatch(setJournalBillDialogOpenIndex(-1));
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (journal_voucher_guid) {
      // const payload = {
      //   journal_voucher_guid: journal_voucher_guid,
      // };
      dispatch(getJournalByID(journal_voucher_guid));
    }
  }, [journal_voucher_guid]);

  useEffect(() => {
    // const payload = {
    //   account_name: "",
    //   under_group_id: "16,17,18",
    // };

    // dispatch(getAllJournalAndReceipt(payload));
    dispatch(findAllVoucherTypeByType(5));

    return () => {
      dispatch(clearJournalDetails());
    };
  }, []);

  const handleVendorSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllAccountIncremental(search));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = async (values: any) => {
    if (
      values?.credit_debit_total?.DebitTotal === 0 &&
      values?.credit_debit_total?.CreditTotal === 0
    ) {
      ErrorToast("Atleast One Entry is Required!");
      return;
    }
    if (values?.journal_voucher_no_string?.endsWith("/")) {
      ErrorToast("Please Enter Voucher Number.");
      return;
    }
    if (
      values?.credit_debit_total?.DebitTotal !==
      values?.credit_debit_total?.CreditTotal
    ) {
      if (
        values?.credit_debit_total?.DebitTotal >
        values?.credit_debit_total?.CreditTotal
      ) {
        ErrorToast("Debit Amount is Greater than Credit Amount");
      } else {
        ErrorToast("Credit Amount is Greater than Debit Amount");
      }
      return;
    }
    const formdata: any = {
      financial_year: values?.financial_year
        ? +values?.financial_year
        : FINANCIAL_YEAR,
      journal_voucher_date: values?.journal_voucher_date
        ? moment(values?.journal_voucher_date).format("YYYY-MM-DD")
        : "",
      journal_voucher_total_amount: values?.journal_voucher_total_amount,
      remarks: values?.remarks,
      //   ledger_id:values?.ledger_id ? values?.ledger_id : null,
      journal_voucher_no: values?.journal_voucher_no
        ? values?.journal_voucher_no
        : null,
      voucher_type_id: values?.voucher_type_id ? values?.voucher_type_id : null,
      journal_voucher_entry: values?.journal_voucher_entry
        ?.filter((entry: any) => entry?.ledger_id)
        ?.map((entry: any) => ({
          debit_credit: entry?.debit_credit,
          ledger_id: entry?.ledger_id,
          amount: entry?.amount,
          journal_voucher_entry_referance:
            entry?.journal_voucher_entry_referance
              ?.filter((pay_ref: any) => pay_ref?.referance_amount)
              ?.map((pay_ref: any, ind: number) => {
                if (pay_ref?.id) {
                  return {
                    sr_no: pay_ref?.sr_no ? pay_ref?.sr_no : ind + 1,
                    referance_type: entry?.debit_credit,
                    refernace_name: pay_ref?.bill_no_string,
                    refernace_bill_id: pay_ref?.id,
                    referance_amount: pay_ref?.referance_amount,
                  };
                }
                return pay_ref;
              }),
        })),
    };

    if (journal_voucher_guid) {
      try {
        const updatePayload: any = {
          journal_voucher_guid: journal_voucher_guid,
          id: values?.id ? +values?.id : null,
          journal_voucher_no: values?.journal_voucher_no
            ? values?.journal_voucher_no
            : null,
          journal_voucher_no_string: values?.journal_voucher_no_string
            ? values?.journal_voucher_no_string
            : "",
          ...formdata,
        };
        const response = await dispatch(updateJournal(updatePayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/journal");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      try {
        const response = await dispatch(createJournal(formdata));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/journal");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  // console.log("JournalDetail", JournalDetail);
  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={JournalDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>
                    {journal_voucher_guid ? "Update Journal" : "Add Journal"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    label="Voucher Type"
                    name="voucher_type_id"
                    placeholder="Select Voucher Type"
                    disabled={journal_voucher_guid && true}
                    component={FormSelectionField}
                    options={VoucherTypeList.map((voucher: any) => {
                      return {
                        value: voucher?.id,
                        label: voucher?.name,
                      };
                    })}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="journal_voucher_no_string"
                    label="Voucher No"
                    disabled={journal_voucher_guid ? false : true}
                    placeholder="Voucher No"
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>

                <GridLayoutItem>
                  <Field
                    name="journal_voucher_date"
                    label="Voucher Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <JournalNoChangeWatcher formRenderProps={formRenderProps} />
                <BillJournalEntryChangeWatcher
                  formRenderProps={formRenderProps}
                />
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>Particulars</Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem colSpan={4}>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    handleVendorSearchChange={handleVendorSearchChange}
                    component={JournalEntryDetailsArray}
                    name="journal_voucher_entry"
                  />
                  {dialogName === "JournalBillItemDetailsDialog" && (
                    <FieldArray
                      formRenderProps={formRenderProps}
                      component={JournalBillDetailsArray}
                      name="journal_bill_entry"
                    />
                  )}
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="remarks"
                    label="Remarks"
                    rows={2}
                    component={FormTextArea}
                    // validator={requiredValidator}
                    // astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={4}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    marginTop: 15,
                  }}
                >
                  <div>
                    <ButtonWithLoading
                      label={journal_voucher_guid ? "Update" : "Save"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                    <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginLeft: 4 }}
                      onClick={
                        () => navigate("/journal")
                        // navigate(-1)
                      }
                    >
                      Cancel
                    </Button>
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateJournal;
