import React, { useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import ShadowCard from "../../components/common/ShadowCard";
import FormDatePicker from "../../components/formFields/FormDateField";
import { FINANCIAL_YEAR } from "../../_contstants/common";
import moment from "moment";

import ReceiptEntryDetailsArray from "./ReceiptEntryDetailsArray";
import {
  createReceipt,
  generateReceiptNoForReceipt,
  getReceiptByID,
  updateReceipt,
} from "./services/receipt.services";
import {
  // clearOutstandingReceiptList,
  clearReceiptDetails,
  setGeneratedReceiptNo,
  setReceiptBillDialogOpenIndex,
} from "./receiptSlice";
import ReceiptBillDetailsArray from "./ReceiptBillDetailsArray";
import {
  getAllAccountIncremental,
  getAllPaymentAndReceipt,
} from "../account/services/account.services";
import FormTextArea from "../../components/formFields/FormTextArea";
import { BiInfoCircle } from "react-icons/bi";
import { Tooltip } from "@progress/kendo-react-tooltip";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { findAllVoucherTypeByType } from "../vouchertype/services/voucherType.services";
import { findIndex } from "lodash";
import { ErrorToast } from "../../components/toast/Toasts";
interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const BillReceiptEntryChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const receipt_bill_entry =
    formRenderProps.valueGetter("receipt_bill_entry") || [];
  const receipt_entry = formRenderProps.valueGetter("receipt_entry");
  const ReceiptBillDialogOpenIndex = useAppSelector(
    (state) => state.receipt.ReceiptBillDialogOpenIndex
  );

  const OutstandingReceiptList = useAppSelector(
    (state) => state.receipt.OutstandingReceiptList
  );
  const location = useLocation();
  const receipt_guid = location.state?.receipt_guid;

  // const dispatch = useAppDispatch();

  useEffect(() => {
    const EntryReferance =
      receipt_entry?.[ReceiptBillDialogOpenIndex]?.receipt_entry_referance;
    if (ReceiptBillDialogOpenIndex >= 0 && EntryReferance?.length > 0) {
      const IsReceiptRefID =
        receipt_entry?.[ReceiptBillDialogOpenIndex]
          ?.receipt_entry_referance?.[0]?.receipt_ref_id;

      if (receipt_guid && IsReceiptRefID) {
        const referenceMap = new Map<
          number,
          {
            refernace_name: string;
            referance_amount: number;
            referance_type: string;
          }
        >(
          EntryReferance.map((entry: any) => [
            entry.refernace_bill_id,
            {
              refernace_name: entry.refernace_name,
              referance_amount: entry.referance_amount,
              referance_type: entry.referance_type,
            },
          ])
        );
        const mergedArray = OutstandingReceiptList.map((bill: any) => {
          const match = referenceMap.get(bill.id);
          const pendingAmountFullPaid = bill.final_pending_amount;
          if (match && match?.refernace_name === bill.bill_no_string) {
            if (!pendingAmountFullPaid) {
              return {
                ...bill,
                referance_amount: match.referance_amount,
                referance_type: match.referance_type,
                isfullpayment: true,
              };
            } else {
              return {
                ...bill,
                referance_amount: match.referance_amount,
                referance_type: match.referance_type,
                isfullpayment: false,
              };
            }
          }

          return { ...bill }; // Return the bill unchanged if no match
        });

        formRenderProps.onChange("receipt_bill_entry", {
          value: mergedArray || [],
        });
      } else {
        formRenderProps.onChange("receipt_bill_entry", {
          value: EntryReferance || [],
        });
      }
    } else {
      formRenderProps.onChange("receipt_bill_entry", {
        value: JSON.parse(JSON.stringify(OutstandingReceiptList)) || [],
      });
    }
  }, [ReceiptBillDialogOpenIndex, OutstandingReceiptList?.[0]?.id]);

  useEffect(() => {
    const calculateTotals = (entries: typeof receipt_entry) => {
      const totals: Record<string, number> = {};
      entries.forEach((entry: any) => {
        const key = `receipt_total_amount`;
        totals[key] = (totals[key] || 0) + entry.amount;
      });
      return totals;
    };
    const result = calculateTotals(receipt_entry);

    if (receipt_entry && receipt_entry?.length > 0) {
      formRenderProps.onChange(`receipt_total_amount`, {
        value: result.receipt_total_amount || 0,
      });
    }
  }, [
    receipt_entry?.map((item: any) => item?.amount).join("-"),
    receipt_entry?.map((item: any) => item?.debit_credit).join("-"),
    receipt_entry?.map((item: any) => item?.ledger_id).join("-"),
  ]);

  useEffect(() => {
    let TotalAmount = 0;
    const ReceiptEntryOpenIndex = receipt_entry[ReceiptBillDialogOpenIndex];
    if (ReceiptEntryOpenIndex) {
      receipt_entry[ReceiptBillDialogOpenIndex] = {
        ...ReceiptEntryOpenIndex,
        receipt_entry_referance: [...receipt_bill_entry],
      };
    }
    if (
      receipt_entry[ReceiptBillDialogOpenIndex]?.receipt_entry_referance
        ?.length > 0
    ) {
      TotalAmount = receipt_entry[
        ReceiptBillDialogOpenIndex
      ]?.receipt_entry_referance?.reduce(
        (total: number, item: any) => total + (item?.referance_amount || 0),
        0
      );
    } else {
      TotalAmount = 0;
    }
    const RemaingAmount =
      (ReceiptEntryOpenIndex?.amount ?? 0) - (TotalAmount ?? 0);
    const IndexDetailsObj = {
      remainingAmount: RemaingAmount,

      ledger_name: ReceiptEntryOpenIndex?.ledger_options?.find(
        (account: any) => account?.id === ReceiptEntryOpenIndex?.ledger_id
      )?.account_name,
      ...ReceiptEntryOpenIndex,
    };
    formRenderProps.onChange(`IndexDetails`, {
      value: IndexDetailsObj || 0,
    });
  }, [
    ReceiptBillDialogOpenIndex,
    OutstandingReceiptList?.[0]?.id,
    receipt_entry?.map((item: any) => item?.amount).join("-"),
    receipt_entry?.map((item: any) => item?.debit_credit).join("-"),
    receipt_entry?.map((item: any) => item?.ledger_id).join("-"),
    receipt_bill_entry?.map((item: any) => item?.referance_amount).join("-"),
  ]);

  return null;
};

const ReceiptNoChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const receipt_guid = location.state?.receipt_guid;
  const voucher_type_id = formRenderProps.valueGetter("voucher_type_id");
  const VoucherTypeList = useAppSelector(
    (state) => state.voucherType.VoucherTypeList
  );
  const receipt_no_string = formRenderProps.valueGetter("receipt_no_string");
  const ReceiptDetail = useAppSelector((state) => state.receipt.ReceiptDetail);
  const GeneratedReceiptNo = useAppSelector(
    (state) => state.receipt.GeneratedReceiptNo
  );
  useEffect(() => {
    const fetchReceiptNo = async () => {
      const payload = {
        financial_year: FINANCIAL_YEAR,
        voucher_type_id: +voucher_type_id,
      };
      const response = await dispatch(generateReceiptNoForReceipt(payload));
      if (response.meta.requestStatus === "fulfilled") {
        dispatch(setGeneratedReceiptNo(response.payload?.receipt_no_string));
        formRenderProps.onChange("receipt_no_string", {
          value: response.payload?.receipt_no_string || "",
        });
        formRenderProps.onChange("receipt_no", {
          value: response.payload?.receipt_no || "",
        });
        const ledgerIDIndex = findIndex(VoucherTypeList, {
          id: voucher_type_id,
        });
        if (ledgerIDIndex > -1) {
          formRenderProps.onChange("ledger_id", {
            value: VoucherTypeList?.[ledgerIDIndex]?.map_ledger_id,
          });
        } else {
          formRenderProps.onChange("ledger_id", {
            value: null,
          });
        }
      }
    };
    if (voucher_type_id && !receipt_guid) {
      fetchReceiptNo();
    }
  }, [voucher_type_id]);

  useEffect(() => {
    const updateInvoiveNoDate = async () => {
      const receiptNoString = receipt_guid
        ? ReceiptDetail?.receipt_no_string
        : GeneratedReceiptNo;
      const oldkey = receiptNoString?.split("/")?.slice(0, -1).join("/");
      if (
        receiptNoString?.split("/").length ===
          receipt_no_string?.split("/").length &&
        voucher_type_id
      ) {
        const modifiyed = receipt_no_string?.split("/")?.pop();
        formRenderProps.onChange("receipt_no_string", {
          value: `${oldkey}/${modifiyed}`,
        });

        formRenderProps.onChange("receipt_no", {
          value: `${modifiyed}`,
        });
      } else {
        if (voucher_type_id) {
          formRenderProps.onChange("receipt_no_string", {
            value: `${oldkey}/`,
          });
        } else {
          formRenderProps.onChange("receipt_no_string", {
            value: "",
          });
        }
        formRenderProps.onChange("receipt_no", {
          value: null,
        });
      }
    };

    updateInvoiveNoDate();
  }, [receipt_no_string, voucher_type_id]);

  return null;
};

const CreateReceipt = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const receipt_guid = location.state?.receipt_guid;
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.receipt.loading);
  const ReceiptDetail = useAppSelector((state) => state.receipt.ReceiptDetail);
  const AccountPaymentAndReceiptList = useAppSelector(
    (state) => state.account.AccountPaymentAndReceiptList
  );

  const VoucherTypeList = useAppSelector(
    (state) => state.voucherType.VoucherTypeList
  );

  // const ReceiptStateForGST = useAppSelector(
  //   (state) => state.receipt.ReceiptStateForGST
  // );

  const [formKey, setFormKey] = React.useState(1);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [ReceiptDetail]);

  useEffect(() => {
    if (receipt_guid) {
      const payload = {
        receipt_guid: receipt_guid,
      };
      dispatch(getReceiptByID(payload));
    }
  }, [receipt_guid]);

  useEffect(() => {
    const payload = {
      account_name: "",
      under_group_id: "16,17,18",
    };
    dispatch(getAllPaymentAndReceipt(payload));
    dispatch(findAllVoucherTypeByType(4));

    return () => {
      dispatch(setReceiptBillDialogOpenIndex(-1));
      dispatch(clearReceiptDetails());
    };
  }, []);

  const handleVendorSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllAccountIncremental(search));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = async (values: any) => {
    if (
      !(
        values?.receipt_entry?.filter(
          (entry: any) => entry?.ledger_id && entry?.amount
        ).length > 0
      )
    ) {
      ErrorToast("At least One Entry is Required!");
      return;
    }
    if (values?.receipt_no_string?.endsWith("/")) {
      ErrorToast("Please Enter Voucher Number.");
      return;
    }
    const formdata: any = {
      financial_year: values?.financial_year
        ? +values?.financial_year
        : FINANCIAL_YEAR,
      receipt_date: values?.receipt_date
        ? moment(values?.receipt_date).format("YYYY-MM-DD")
        : "",
      receipt_no_string: values?.receipt_no_string
        ? values?.receipt_no_string
        : "",
      receipt_no: values?.receipt_no ? +values?.receipt_no : null,
      receipt_total_amount: values?.receipt_total_amount,
      remarks: values?.remarks,
      ledger_id: values?.ledger_id,
      voucher_type_id: values?.voucher_type_id,

      receipt_entry: values?.receipt_entry
        ?.filter((fentry: any) => fentry?.ledger_id)
        ?.map((entry: any) => ({
          // debit_credit: entry?.debit_credit,
          cheque_no: entry?.cheque_no,
          bank_name: entry?.bank_name,
          favouring_name: entry?.favouring_name,
          ledger_id: entry?.ledger_id,
          amount: entry?.amount,
          receipt_entry_referance: entry?.receipt_entry_referance
            ? entry?.receipt_entry_referance
                ?.filter((fpay_ref: any) => fpay_ref?.referance_amount)
                ?.map((pay_ref: any) => {
                  if (pay_ref?.id) {
                    return {
                      referance_type: "Credit",
                      refernace_name: pay_ref?.bill_no_string
                        ? pay_ref?.bill_no_string
                        : pay_ref?.refernace_name,
                      refernace_bill_id: pay_ref?.refernace_bill_id
                        ? pay_ref?.refernace_bill_id
                        : pay_ref?.id,
                      referance_amount: pay_ref?.referance_amount,
                    };
                  }
                  return pay_ref;
                })
            : [],
        })),
    };
    if (receipt_guid) {
      try {
        const updatePayload: any = {
          receipt_guid: receipt_guid,
          receipt_no: values?.receipt_no_string
            ? +values?.receipt_no_string?.split("/")?.pop()
            : null,
          receipt_no_string: values?.receipt_no_string,
          id: values?.id ? +values?.id : null,
          ...formdata,
        };
        const response = await dispatch(updateReceipt(updatePayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          if (values?.buttonIdentity === "save") {
            navigate("/receipt");
          } else {
            dispatch(clearReceiptDetails());
            navigate("/receipt/create");
          }

          dispatch(setReceiptBillDialogOpenIndex(-1));
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      try {
        const response = await dispatch(createReceipt(formdata));
        if (response?.meta?.requestStatus === "fulfilled") {
          if (values?.buttonIdentity === "save") {
            navigate("/receipt");
          } else {
            setFormKey(formKey + 1);
            navigate("/receipt/create");
          }
          dispatch(setReceiptBillDialogOpenIndex(-1));
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={ReceiptDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>
                    {receipt_guid ? "Update Receipt" : "Add Receipt"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    label="Voucher Type"
                    name="voucher_type_id"
                    disabled={receipt_guid ? true : false}
                    placeholder="Select Voucher Type"
                    component={FormSelectionField}
                    options={VoucherTypeList.map((voucher: any) => {
                      return {
                        value: voucher?.id,
                        label: voucher?.name,
                      };
                    })}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="receipt_no_string"
                    label="Voucher No"
                    disabled={receipt_guid ? false : true}
                    placeholder="Receipt No"
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="receipt_date"
                    label="Voucher Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem></GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    label="Account"
                    name="ledger_id"
                    placeholder="Select Account"
                    component={FormSelectionField}
                    options={AccountPaymentAndReceiptList.map((ledger: any) => {
                      return {
                        value: ledger?.id,
                        label: ledger?.account_name,
                      };
                    })}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>

                <ReceiptNoChangeWatcher formRenderProps={formRenderProps} />
                <BillReceiptEntryChangeWatcher
                  formRenderProps={formRenderProps}
                />
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem
                  colSpan={4}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography.h4>Particulars</Typography.h4>
                  <Tooltip
                    anchorElement="target"
                    position="left"
                    parentTitle={true}
                  >
                    <BiInfoCircle
                      title="Escape to Delete Entry."
                      style={{ color: "red", cursor: "pointer" }}
                    />
                  </Tooltip>
                </GridLayoutItem>
                <GridLayoutItem colSpan={4}>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    handleVendorSearchChange={handleVendorSearchChange}
                    component={ReceiptEntryDetailsArray}
                    name="receipt_entry"
                  />
                  {dialogName === "CreditBillItemDialog" && (
                    <FieldArray
                      formRenderProps={formRenderProps}
                      component={ReceiptBillDetailsArray}
                      name="receipt_bill_entry"
                    />
                  )}
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}>
                  <Field
                    name="remarks"
                    label="Remarks"
                    rows={2}
                    id="remarks_for_focus"
                    component={FormTextArea}
                    // validator={requiredValidator}
                    // astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={4}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    marginTop: 15,
                  }}
                >
                  <div>
                    <ButtonWithLoading
                      label={receipt_guid ? "Update" : "Save"}
                      type="submit"
                      onClick={() =>
                        formRenderProps.onChange("buttonIdentity", {
                          value: "save",
                        })
                      }
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                    <ButtonWithLoading
                      buttonStyle={{ marginLeft: 6 }}
                      onClick={() =>
                        formRenderProps.onChange("buttonIdentity", {
                          value: "continue",
                        })
                      }
                      label={
                        receipt_guid
                          ? "Update and Continue"
                          : "Save and Continue"
                      }
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                    <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginLeft: 6 }}
                      onClick={() => {
                        navigate("/receipt");
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateReceipt;
