import React, { useEffect } from "react";
import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import ShadowCard from "../../components/common/ShadowCard";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import {
  ExpansionPanel,
  ExpansionPanelActionEvent,
  ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { clearOldDistributorDataDetails } from "./oldDistributorDataSlice";
import {
  getOldDistributorDataByID,
  updateOldDistributorData,
} from "./services/oldDistributorData.services";
import { getAllItemMarginRates } from "../placeorder/services/order.services";
import FormDatePicker from "../../components/formFields/FormDateField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import moment from "moment";

const CreateOldDistributorData: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const id = location.state?.id;

  const loading = useAppSelector((state) => state.oldDistributorData.loading);
  const MarginRateList = useAppSelector((state) => state.order.MarginRateList);
  const OldDistributorDataDetails = useAppSelector(
    (state) => state.oldDistributorData.OldDistributorDataDetails
  );

  const [initialValues, setInitialValues] = React.useState({});
  const [formKey, setFormKey] = React.useState(1);
  const [RateData, setRateData] = React.useState<any[]>(
    JSON.parse(JSON.stringify(MarginRateList))
  );
  const [expanded, setExpanded] = React.useState<any>(0);

  useEffect(() => {
    return () => {
      dispatch(clearOldDistributorDataDetails());
    };
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getAllItemMarginRates(+id));
      dispatch(getOldDistributorDataByID(+id));
    }
  }, [id]);

  useEffect(() => {
    setRateData(JSON.parse(JSON.stringify(MarginRateList)));
  }, [MarginRateList]);

  useEffect(() => {
    const updatedMarginRatelist = JSON.parse(JSON.stringify(MarginRateList));
    const updatedData = updatedMarginRatelist.map((itemGroup: any) => {
      const updatedItems = itemGroup.item_margin_rate.map((item: any) => {
        OldDistributorDataDetails?.distributer_qty?.forEach(
          (printGroup: any) => {
            if (item.item_group_id === printGroup.item_group_id) {
              printGroup?.distributer_Previous_qty_list.forEach(
                (printItem: any) => {
                  if (item.item_id === printItem.item_id) {
                    item.quantity = +printItem.previous_year_qty;
                    item.current_year_qty = +printItem.current_year_qty;
                    item.id = +printItem.id;
                  }
                }
              );
            }
          }
        );
        return item;
      });
      return {
        ...itemGroup,
        item_margin_rate: updatedItems,
      };
    });

    setRateData(updatedData);
  }, [MarginRateList, OldDistributorDataDetails]);

  useEffect(() => {
    const newInitialValues =
      OldDistributorDataDetails &&
      OldDistributorDataDetails?.distributer_qty &&
      OldDistributorDataDetails?.distributer_qty
        ?.map((item: any) => {
          return item?.distributer_Previous_qty_list?.map((subitem: any) => {
            return {
              [`prev_qty_${item?.item_group_id}_${subitem?.item_id}`]:
                subitem?.previous_year_qty,
              [`curr_qty_${item?.item_group_id}_${subitem?.item_id}`]:
                subitem?.current_year_qty,
            };
          });
        })
        ?.reduce((acc: any, curr: any) => {
          return {
            ...OldDistributorDataDetails,
            ...acc,
            ...curr.reduce((subAcc: any, subCurr: any) => {
              return { ...subAcc, ...subCurr };
            }, {}),
          };
        }, {});

    if (JSON.stringify(newInitialValues) !== JSON.stringify(initialValues)) {
      setInitialValues(newInitialValues);
      setFormKey((prevKey) => prevKey + 1);
    }
  }, [OldDistributorDataDetails]);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, []);

  const handleQtyChange = (
    formRenderProps: FormRenderProps,
    mainIndex: number,
    subIndex: number,
    itemGroupId: number,
    itemId: number
  ) => {
    const qty = formRenderProps.valueGetter(
      `prev_qty_${itemGroupId}_${itemId}`
    );
    const curr_qty = formRenderProps.valueGetter(
      `curr_qty_${itemGroupId}_${itemId}`
    );
    // if (qty === 0) {
    //   formRenderProps.onChange(`qty_${itemGroupId}_${itemId}`, {
    //     value: null,
    //   });
    // }

    // formRenderProps.onChange(`amount_${itemGroupId}_${itemId}`, {
    //   value: +rate * +qty || null,
    // });

    RateData[mainIndex].item_margin_rate[subIndex].quantity = +qty || 0;
    RateData[mainIndex].item_margin_rate[subIndex].current_year_qty =
      +curr_qty || 0;
  };

  const handleSubmit = async (values: any) => {
    const updatedValue: any = RateData?.map((item: any) => ({
      ...item,
      item_margin_rate: item?.item_margin_rate?.filter(
        (subItem: any) => subItem.quantity > 0
      ),
    }))?.filter((item: any) => item?.item_margin_rate?.length > 0);

    const QtyDataItem =
      updatedValue && updatedValue.length > 0
        ? updatedValue?.flatMap(
            (rate: any) =>
              rate?.item_margin_rate &&
              rate?.item_margin_rate.length > 0 &&
              rate?.item_margin_rate?.map((item: any) => {
                return {
                  item_id: item?.item_id ? +item?.item_id : null,
                  // mrp: item?.mrp ? +item?.mrp : null,
                  previous_year_qty: item?.quantity ? +item?.quantity : null,
                  current_year_qty: item?.current_year_qty
                    ? +item?.current_year_qty
                    : null,
                  unit_id: item?.unit_id ? +item?.unit_id : null,
                  // rate: item?.rate ? +item?.rate : null,
                  // total_amount: item?.amount ? +item?.amount : null,
                };
              })
          )
        : [];
    const editPayload: any = {
      accountid: id ? +id : null,
      date: values?.date ? moment(values?.date).format("YYYY-MM-DD") : "",
      distributer_Previous_qty_list: QtyDataItem,
    };
    const response = await dispatch(updateOldDistributorData(editPayload));
    if (response.meta.requestStatus === "fulfilled") {
      navigate("/account");
    }
  };

  return (
    <>
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement style={{ overflow: "scroll", scrollbarWidth: "none" }}>
            <ShadowCard style={{ padding: 12, marginBottom: 10 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>{"Update Distributor Data"}</Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="date"
                    label="Date"
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={3}
                  style={{ overflow: "scroll", scrollbarWidth: "none" }}
                >
                  <div
                    className="container-fluid orderform"
                    style={{ padding: "20px 0 0 0", minWidth: 800 }}
                  >
                    <div className="row">
                      <div className="col-12">
                        <table
                          cellSpacing={1}
                          cellPadding={2}
                          className="table table-bordered w-100  salesorder-print-table"
                          style={{ marginTop: 1 }}
                        >
                          <tr
                            className="text-center fw-600 text-white bg-dark"
                            style={{ fontSize: 12 }}
                          >
                            <th className="pb-3 pt-3" style={{ width: "5%" }}>
                              No.
                            </th>
                            <th style={{ width: "45%" }}>
                              Description Of Goods
                            </th>
                            {/* <th style={{ width: "10%" }}>MRP</th>
                            <th style={{ width: "10%" }}>Rate</th> */}
                            <th style={{ width: "10%" }}>Unit</th>
                            <th style={{ width: "20%" }}>2022 Qty</th>
                            <th style={{ width: "20%" }}>2023 Qty</th>
                          </tr>
                          {RateData && RateData.length > 0 ? (
                            RateData?.map((item: any, mainIndex: number) => (
                              <tr key={mainIndex}>
                                <td colSpan={7} className="p-0">
                                  <ExpansionPanel
                                    title={item?.item_group}
                                    expanded={expanded === mainIndex}
                                    tabIndex={0}
                                    key={item.id}
                                    onAction={(
                                      event: ExpansionPanelActionEvent
                                    ) => {
                                      setExpanded(
                                        event.expanded ? null : mainIndex
                                      );
                                    }}
                                  >
                                    <Reveal>
                                      {expanded === mainIndex && (
                                        <ExpansionPanelContent>
                                          {item?.item_margin_rate &&
                                            item?.item_margin_rate?.length >
                                              0 &&
                                            item?.item_margin_rate?.map(
                                              (
                                                subitem: any,
                                                subIndex: number
                                              ) => (
                                                <div
                                                  key={subIndex}
                                                  id={`collapse${mainIndex}`}
                                                >
                                                  <div
                                                    className="accordion-body"
                                                    style={{
                                                      padding: "5px 0",
                                                    }}
                                                  >
                                                    <table
                                                      style={{ width: "100%" }}
                                                    >
                                                      <tr>
                                                        <td
                                                          style={{
                                                            width: "5%",
                                                          }}
                                                          className="text-center"
                                                        >
                                                          {subIndex + 1}
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: "45%",
                                                          }}
                                                        >
                                                          {
                                                            subitem?.product_name
                                                          }
                                                        </td>
                                                        {/* <td
                                                          style={{
                                                            width: "10%",
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          Rs.{" "}
                                                          {subitem?.mrp || 0}
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: "10%",
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          Rs.{" "}
                                                          {subitem?.rate || 0}
                                                        </td> */}
                                                        <td
                                                          style={{
                                                            width: "10%",
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          {subitem?.unit_name}
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: "20%",
                                                          }}
                                                        >
                                                          <Field
                                                            wrapperClassName="right-alighned-field"
                                                            wrapperStyle={{
                                                              margin: 0,
                                                            }}
                                                            name={`prev_qty_${item?.item_group_id}_${subitem?.item_id}`}
                                                            placeholder="0"
                                                            onChange={() =>
                                                              handleQtyChange(
                                                                formRenderProps,
                                                                mainIndex,
                                                                subIndex,
                                                                item?.item_group_id,
                                                                subitem?.item_id
                                                              )
                                                            }
                                                            format="n2"
                                                            component={
                                                              FormNumericTextField
                                                            }
                                                          />
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: "20%",
                                                            padding: "0 5px",
                                                          }}
                                                        >
                                                          <Field
                                                            wrapperClassName="right-alighned-field orderform-amount"
                                                            wrapperStyle={{
                                                              margin: 0,
                                                            }}
                                                            name={`curr_qty_${item?.item_group_id}_${subitem?.item_id}`}
                                                            placeholder="0"
                                                            onChange={() =>
                                                              handleQtyChange(
                                                                formRenderProps,
                                                                mainIndex,
                                                                subIndex,
                                                                item?.item_group_id,
                                                                subitem?.item_id
                                                              )
                                                            }
                                                            format="n2"
                                                            component={
                                                              FormNumericTextField
                                                            }
                                                          />
                                                        </td>
                                                      </tr>
                                                    </table>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                        </ExpansionPanelContent>
                                      )}
                                    </Reveal>
                                  </ExpansionPanel>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={7} className="text-center p-2">
                                No Records Found
                              </td>
                            </tr>
                          )}
                        </table>
                      </div>
                      <div
                        className="col-12"
                        style={{
                          textAlign: "end",
                          marginTop: "20px",
                          width: "100%",
                        }}
                      >
                        <ButtonWithLoading
                          label={
                            OldDistributorDataDetails?.accountid
                              ? "Update"
                              : "Save"
                          }
                          type="submit"
                          disabled={!formRenderProps.allowSubmit || loading}
                          loading={loading}
                        />
                        <Button
                          type="button"
                          fillMode={"outline"}
                          themeColor={"primary"}
                          style={{ marginLeft: 4 }}
                          onClick={() => navigate(-1)}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateOldDistributorData;
