import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IExpenseAllocation,
  IExpenseAllocationInitialState,
} from "./expenseAllocationModel";
import {
  ActiveInactiveExpenseAllocation,
  deleteExpenseAllocation,
  getAllActiveExpenseAllocations,
  getAllExpenseAllocations,
  getAllSRFixedExpenseAllocated,
  getExpenseAllocationByID,
  InsertExpenseAllocation,
  updateExpenseAllocation,
} from "./services/expenseAllocation.services";

const initialState: IExpenseAllocationInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  ExpenseAllocationList: [],
  FixedExpenseAllocationList: [],
  ExpenseAllocationID: null,
  ExpenseAllocationDetail: {
    sr_id: null,
    expense_type_id: null,
    expense_amount: null,
    isactive: 1,
  },
};

const expenseAllocationSlice = createSlice({
  name: "expenseAllocation",
  initialState,
  reducers: {
    clearExpenseAllocationDetails: (state) => {
      state.ExpenseAllocationDetail = initialState.ExpenseAllocationDetail;
    },
    setExpenseAllocationID: (state, action) => {
      state.ExpenseAllocationID = action.payload;
    },
    clearExpenseAllocationID: (state) => {
      state.ExpenseAllocationID = initialState.ExpenseAllocationID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllExpenseAllocations.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllExpenseAllocations.fulfilled,
      (state, action: PayloadAction<IExpenseAllocation[]>) => {
        state.loading = false;
        state.ExpenseAllocationList = action.payload || [];
      }
    );
    builder.addCase(getAllExpenseAllocations.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ExpenseAllocationList = [];
    });

    builder.addCase(getAllSRFixedExpenseAllocated.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllSRFixedExpenseAllocated.fulfilled,
      (state, action: PayloadAction<IExpenseAllocation[]>) => {
        state.loading = false;
        state.FixedExpenseAllocationList = action.payload || [];
      }
    );
    builder.addCase(getAllSRFixedExpenseAllocated.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.FixedExpenseAllocationList = [];
    });
    builder.addCase(InsertExpenseAllocation.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(InsertExpenseAllocation.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(InsertExpenseAllocation.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateExpenseAllocation.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateExpenseAllocation.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateExpenseAllocation.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteExpenseAllocation.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteExpenseAllocation.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteExpenseAllocation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getExpenseAllocationByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(
      getExpenseAllocationByID.fulfilled,
      (state, action: any) => {
        state.formLoading = false;
        state.ExpenseAllocationDetail = action.payload;
      }
    );
    builder.addCase(getExpenseAllocationByID.rejected, (state, action) => {
      state.formLoading = false;
      state.ExpenseAllocationDetail = initialState.ExpenseAllocationDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveExpenseAllocations.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveExpenseAllocations.fulfilled,
      (state, action: PayloadAction<IExpenseAllocation[]>) => {
        state.loading = false;
        state.ExpenseAllocationList = action.payload || [];
      }
    );
    builder.addCase(
      getAllActiveExpenseAllocations.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.ExpenseAllocationList = [];
      }
    );
    builder.addCase(ActiveInactiveExpenseAllocation.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveExpenseAllocation.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(
      ActiveInactiveExpenseAllocation.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      }
    );
  },
});

export const {
  clearExpenseAllocationDetails,
  setExpenseAllocationID,
  clearExpenseAllocationID,
} = expenseAllocationSlice.actions;
export default expenseAllocationSlice.reducer;
