import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import moment from "moment";

export const getAllSRExpense = createAsyncThunk(
  "ExpenseDetails/FindAllSrExpenseList",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ExpenseDetails/FindAllSrExpenseList`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching SR Expense:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createSRExpense = createAsyncThunk(
  "ExpenseDetails/InsertExpenseDetails",
  async (Item: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ExpenseDetails/InsertExpenseDetails`,
        Item
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Item:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateSRExpense = createAsyncThunk(
  "ExpenseDetails/UpdateExpenseDetails",
  async (formdata: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ExpenseDetails/UpdateExpenseDetails`,
        formdata
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error update SR Expense:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getSRExpenseByID = createAsyncThunk(
  "ExpenseDetails/FindByIDExpenseDetails",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ExpenseDetails/FindByIDExpenseDetails`,
        { expense_details_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        id: result?.id,
        expense_details_guid: result?.expense_details_guid,
        expense_date: result?.expense_date
          ? moment(result?.expense_date, "YYYY-MM-DD").toDate()
          : "",
        from_city: result?.from_city,
        to_city: result?.to_city,
        mode_of_transport: result?.mode_of_transport,
        city_town_visited: result?.city_town_visited,
        sales_amount: result?.sales_amount,
        remarks: result?.remarks,
        fixed_expense: result?.fixed_expense,
        expense_details: result?.expense_details,
        // expense1: result?.expense1,
        // expense2: result?.expense2,
        // expense3: result?.expense3,
        // expense4: result?.expense4,
        // expense5: result?.expense5,
        total_amount: result?.total_amount,
        kms_travelled: result?.kms_travelled,
        fare_for_transport: result?.fare_for_transport,
        net_amount: result?.net_amount,
        sr_id: result?.sr_id,
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching SR Expense:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteSRExpense = createAsyncThunk(
  "ExpenseDetails/DeleteExpenseDetails",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ExpenseDetails/DeleteExpenseDetails`,
        { expense_details_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting SR Expense:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
