import React, { useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import ShadowCard from "../../components/common/ShadowCard";
import FormDatePicker from "../../components/formFields/FormDateField";
import { FINANCIAL_YEAR } from "../../_contstants/common";
import moment from "moment";

import ContraEntryDetailsArray from "./ContraEntryDetailsArray";
import {
  createContra,
  generateContraNoForContra,
  getContraByID,
  updateContra,
} from "./services/contra.services";

import { findAllVoucherTypeByType } from "../vouchertype/services/voucherType.services";
import {
  // clearOutstandingContraList,
  clearContraDetails,
  setContraBillDialogOpenIndex,
} from "./contraSlice";
// import ContraBillDetailsArray from "./ContraBillDetailsArray";
import {
  getAllAccountIncremental,
  getAllPaymentAndReceipt,
} from "../account/services/account.services";
import FormTextArea from "../../components/formFields/FormTextArea";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { BiInfoCircle } from "react-icons/bi";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { findIndex } from "lodash";
import { ErrorToast } from "../../components/toast/Toasts";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const BillContraEntryChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  //   const contra_bill_entry =
  //     formRenderProps.valueGetter("contra_bill_entry") || [];
  const contra_entry = formRenderProps.valueGetter("contra_entry");
  //   const ContraBillDialogOpenIndex = useAppSelector(
  //     (state) => state.contra.ContraBillDialogOpenIndex
  //   );

  //   const OutstandingContraList = useAppSelector(
  //     (state) => state.contra.OutstandingContraList
  //   );
  //   const location = useLocation();
  //   const contra_guid = location.state?.contra_guid;

  // const dispatch = useAppDispatch();

  //   useEffect(() => {
  //     if (
  //       ContraBillDialogOpenIndex >= 0 &&
  //       contra_entry?.[ContraBillDialogOpenIndex]?.contra_entry_referance
  //         ?.length > 0
  //     ) {
  //       const IsContraRefID =
  //         contra_entry?.[ContraBillDialogOpenIndex]?.contra_entry_referance?.[0]
  //           ?.contra_ref_id;

  //       if (contra_guid && IsContraRefID) {
  //         const OldBillEntry =
  //           contra_entry?.[ContraBillDialogOpenIndex]?.contra_entry_referance;

  //         const referenceMap = new Map<
  //           number,
  //           { refernace_name: string; referance_amount: number }
  //         >(
  //           OldBillEntry.map((entry: any) => [
  //             entry.refernace_bill_id,
  //             {
  //               refernace_name: entry.refernace_name,
  //               referance_amount: entry.referance_amount,
  //             },
  //           ])
  //         );
  //         const mergedArray = OutstandingContraList.map((bill: any) => {
  //           const match = referenceMap.get(bill.bill_no);
  //           const pendingAmountFullPaid = bill.final_pending_amount;

  //           if (match && match?.refernace_name === bill.bill_no_string) {
  //             if (!pendingAmountFullPaid) {
  //               return {
  //                 ...bill,
  //                 referance_amount: match.referance_amount,
  //                 isfullcontra: true,
  //               };
  //             } else {
  //               return {
  //                 ...bill,
  //                 referance_amount: match.referance_amount,
  //                 isfullcontra: false,
  //               };
  //             }
  //           }

  //           return { ...bill }; // Return the bill unchanged if no match
  //         });

  //         formRenderProps.onChange("contra_bill_entry", {
  //           value: mergedArray || [],
  //         });
  //       } else {
  //         formRenderProps.onChange("contra_bill_entry", {
  //           value:
  //             contra_entry?.[ContraBillDialogOpenIndex]?.contra_entry_referance ||
  //             [],
  //         });
  //       }
  //     } else {
  //       formRenderProps.onChange("contra_bill_entry", {
  //         value: JSON.parse(JSON.stringify(OutstandingContraList)) || [],
  //       });
  //     }
  //   }, [ContraBillDialogOpenIndex, OutstandingContraList?.[0]?.bill_no]);

  useEffect(() => {
    const calculateTotals = (entries: typeof contra_entry) => {
      const totals: Record<string, number> = {};
      entries.forEach((entry: any) => {
        const key = `contra_total_amount`;
        totals[key] = (totals[key] || 0) + entry.amount;
      });
      return totals;
    };
    const result = calculateTotals(contra_entry);
    formRenderProps.onChange(`contra_total_amount`, {
      value: result.contra_total_amount || 0,
    });
    if (contra_entry && contra_entry?.length > 0) {
      formRenderProps.onChange(`contra_entry.${0}.db_total`, {
        value: result || {},
      });
    }
  }, [
    contra_entry?.map((item: any) => item?.amount).join("-"),
    contra_entry?.map((item: any) => item?.debit_credit).join("-"),
    contra_entry?.map((item: any) => item?.ledger_id).join("-"),
  ]);

  //   useEffect(() => {
  //     let TotalAmount = 0;
  //     const ContraEntryOpenIndex = contra_entry[ContraBillDialogOpenIndex];
  //     // if (ContraEntryOpenIndex) {
  //     //   contra_entry[ContraBillDialogOpenIndex] = {
  //     //     ...ContraEntryOpenIndex,
  //     //     contra_entry_referance: [...contra_bill_entry],
  //     //   };
  //     // }
  //     // if (
  //     //   contra_entry[ContraBillDialogOpenIndex]?.contra_entry_referance?.length >
  //     //   0
  //     // ) {
  //     TotalAmount = contra_entry[
  //       ContraBillDialogOpenIndex
  //     ]?.contra_entry_referance?.reduce(
  //       (total: number, item: any) => total + (item?.referance_amount || 0),
  //       0
  //     );
  //     // } else {
  //     //   TotalAmount = 0;
  //     // }
  //     const RemaingAmount =
  //       (ContraEntryOpenIndex?.amount ?? 0) - (TotalAmount ?? 0);
  //     const IndexDetailsObj = {
  //       remainingAmount: RemaingAmount,

  //       ledger_name: ContraEntryOpenIndex?.ledger_options?.find(
  //         (account: any) => account?.id === ContraEntryOpenIndex?.ledger_id
  //       )?.account_name,
  //       ...ContraEntryOpenIndex,
  //     };
  //     formRenderProps.onChange(`contra_bill_entry.${0}.IndexDetails`, {
  //       value: IndexDetailsObj || 0,
  //     });
  //   }, [
  //     ContraBillDialogOpenIndex,
  //     OutstandingContraList?.[0]?.bill_no,
  //     contra_entry?.map((item: any) => item?.amount).join("-"),
  //     // contra_entry?.map((item: any) => item?.debit_credit).join("-"),
  //     contra_entry?.map((item: any) => item?.ledger_id).join("-"),
  //     // contra_bill_entry?.map((item: any) => item?.referance_amount).join("-"),
  //   ]);

  return null;
};

const ContraNoChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const contra_guid = location.state?.contra_guid;
  // const isContraNoRef = useRef(false);
  const voucher_type_id = formRenderProps.valueGetter("voucher_type_id");
  const contra_no_string = formRenderProps.valueGetter("contra_no_string");
  const VoucherTypeList = useAppSelector(
    (state) => state.voucherType.VoucherTypeList
  );
  const ContraDetail = useAppSelector((state) => state.contra.ContraDetail);

  useEffect(() => {
    const fetchContraNo = async () => {
      const payload = {
        financial_year: FINANCIAL_YEAR,
        voucher_type_id: +voucher_type_id,
      };
      const response = await dispatch(generateContraNoForContra(payload));
      if (response.meta.requestStatus === "fulfilled") {
        formRenderProps.onChange("contra_no_string", {
          value: response.payload?.contra_no_string || "",
        });
        formRenderProps.onChange("contra_no", {
          value: response.payload?.contra_no_string || "",
        });
        const ledgerIDIndex = findIndex(VoucherTypeList, {
          id: voucher_type_id,
        });
        if (ledgerIDIndex > -1) {
          formRenderProps.onChange("ledger_id", {
            value: VoucherTypeList?.[ledgerIDIndex]?.map_ledger_id,
          });
        } else {
          formRenderProps.onChange("ledger_id", {
            value: null,
          });
        }
      }
    };
    if (voucher_type_id && !contra_guid) {
      fetchContraNo();
    }
  }, [voucher_type_id]);

  useEffect(() => {
    const fetchContraNo = async () => {
      const oldkey = ContraDetail?.contra_no_string
        ?.split("/")
        ?.slice(0, -1)
        .join("/");
      if (
        contra_no_string?.split("/").length ===
        ContraDetail?.contra_no_string?.split("/").length
      ) {
        const modified = contra_no_string?.split("/")?.pop();
        formRenderProps.onChange("contra_no_string", {
          value: `${oldkey}/${modified}`,
        });
      } else {
        formRenderProps.onChange("contra_no_string", {
          value: `${oldkey}/`,
        });
      }
    };
    if (contra_guid) {
      fetchContraNo();
    }
  }, [contra_no_string]);

  return null;
};

const CreateContra = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const contra_guid = location.state?.contra_guid;
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.contra.loading);
  const ContraDetail = useAppSelector((state) => state.contra.ContraDetail);
  const AccountPaymentAndReceiptList = useAppSelector(
    (state) => state.account.AccountPaymentAndReceiptList
  );

  const VoucherTypeList = useAppSelector(
    (state) => state.voucherType.VoucherTypeList
  );

  const [formKey, setFormKey] = React.useState(1);
  //   const dialogName = useAppSelector((state) => state.dialog.dialogName);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [ContraDetail]);

  useEffect(() => {
    if (contra_guid) {
      const payload = {
        contra_guid: contra_guid,
      };
      dispatch(getContraByID(payload));
    }
  }, [contra_guid]);

  useEffect(() => {
    const payload = {
      account_name: "",
      under_group_id: "16,17,18",
    };

    dispatch(getAllPaymentAndReceipt(payload));
    dispatch(findAllVoucherTypeByType(6));

    return () => {
      dispatch(setContraBillDialogOpenIndex(-1));
      dispatch(clearContraDetails());
    };
  }, []);

  const handleVendorSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllAccountIncremental(search));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = async (values: any) => {
    if (
      !(
        values?.contra_entry?.filter(
          (entry: any) => entry?.ledger_id && entry?.amount
        ).length > 0
      )
    ) {
      ErrorToast("At least One Entry is Required!");
      return;
    }
    if (values?.contra_no_string?.endsWith("/")) {
      ErrorToast("Please Enter Voucher Number.");
      return;
    }
    const formdata: any = {
      financial_year: values?.financial_year
        ? +values?.financial_year
        : FINANCIAL_YEAR,
      contra_date: values?.contra_date
        ? moment(values?.contra_date).format("YYYY-MM-DD")
        : "",
      contra_total_amount: values?.contra_total_amount,
      remarks: values?.remarks,
      ledger_id: values?.ledger_id,
      voucher_type_id: values?.voucher_type_id,
      contra_entry: values?.contra_entry
        ?.filter((entry: any) => entry?.ledger_id)
        ?.map((entry: any) => ({
          ledger_id: entry?.ledger_id,
          amount: entry?.amount,
        })),
    };
    if (contra_guid) {
      try {
        const updatePayload: any = {
          contra_guid: contra_guid,
          contra_no: values?.contra_no_string
            ? +values?.contra_no_string?.split("/").pop()
            : null,
          contra_no_string: values?.contra_no_string,
          id: values?.id ? +values?.id : null,
          ...formdata,
        };
        const response = await dispatch(updateContra(updatePayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/contra");
          dispatch(setContraBillDialogOpenIndex(-1));
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      try {
        const response = await dispatch(createContra(formdata));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/contra");
          dispatch(setContraBillDialogOpenIndex(-1));
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={ContraDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>
                    {contra_guid ? "Update Contra" : "Add Contra"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    label="Voucher Type"
                    name="voucher_type_id"
                    placeholder="Select Voucher Type"
                    component={FormSelectionField}
                    disabled={contra_guid && true}
                    options={VoucherTypeList.map((voucher: any) => {
                      return {
                        value: voucher?.id,
                        label: voucher?.name,
                      };
                    })}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="contra_no_string"
                    label="Voucher No"
                    disabled={contra_guid ? false : true}
                    placeholder="Voucher No"
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="contra_date"
                    label="Voucher Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem></GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    label="Account"
                    name="ledger_id"
                    placeholder="Select Account"
                    component={FormSelectionField}
                    options={AccountPaymentAndReceiptList.map((ledger: any) => {
                      return {
                        value: ledger?.id,
                        label: ledger?.account_name,
                      };
                    })}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <ContraNoChangeWatcher formRenderProps={formRenderProps} />
                <BillContraEntryChangeWatcher
                  formRenderProps={formRenderProps}
                />
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem
                  colSpan={4}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography.h4>Particulars</Typography.h4>
                  <Tooltip
                    anchorElement="target"
                    position="left"
                    parentTitle={true}
                  >
                    <BiInfoCircle
                      title="Escape to Delete Entry."
                      style={{ color: "red", cursor: "pointer" }}
                    />
                  </Tooltip>
                </GridLayoutItem>
                <GridLayoutItem colSpan={4}>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    handleVendorSearchChange={handleVendorSearchChange}
                    component={ContraEntryDetailsArray}
                    name="contra_entry"
                  />
                  {/* {dialogName === "BillItemDeleteDialog" && (
                    <FieldArray
                      formRenderProps={formRenderProps}
                      component={ContraBillDetailsArray}
                      name="contra_bill_entry"
                    />
                  )} */}
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}>
                  <Field
                    name="remarks"
                    label="Remarks"
                    rows={2}
                    id="remarks_for_focus"
                    component={FormTextArea}
                    // validator={requiredValidator}
                    // astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={4}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    marginTop: 15,
                  }}
                >
                  <div>
                    <ButtonWithLoading
                      label={contra_guid ? "Update" : "Save"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                    <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginLeft: 4 }}
                      onClick={() => {
                        navigate("/contra");
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateContra;
