import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import moment from "moment";

export const getAllDebitNote = createAsyncThunk(
  "DebitNote/FindAllDebitNote",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/DebitNote/FindAllDebitNote`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching DebitNote :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createDebitNote = createAsyncThunk(
  "DebitNote/InsertDebitNote",
  async (DebitNoteData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/DebitNote/InsertDebitNote`,
        DebitNoteData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating debitNote :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateDebitNote = createAsyncThunk(
  "DebitNote/UpdateDebitNote",
  async (DebitNoteData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/DebitNote/UpdateDebitNote`,
        DebitNoteData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating DebitNote :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteDebitNote = createAsyncThunk(
  "DebitNote/DeleteDebitNote",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/DebitNote/DeleteDebitNote`,
        { debit_note_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting DebitNote :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getDebitNoteByID = createAsyncThunk(
  "DebitNote/FindByIDDebitNote",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/DebitNote/FindByIDDebitNote`,
        formData
      );
      const result = response.data?.Data as any;
      return {
        id: result?.id,
        debit_note_guid: result?.debit_note_guid,
        voucher_type_id: result?.voucher_type_id,
        debit_note_no: result?.debit_note_no,
        debit_note_no_string: result?.debit_note_no_string,
        debit_note_date: result?.debit_note_date
          ? moment(result?.debit_note_date, "YYYY-MM-DD").toDate()
          : "",
        bill_no: result?.bill_no,
        bill_date: result?.bill_date
          ? moment(result?.bill_date, "YYYY-MM-DD").toDate()
          : "",
        vendor_id: result?.vendor_id,
        total_amount: result?.total_amount,
        total_gst: result?.total_gst,
        gst: result?.gst,
        cgst: result?.cgst,
        sgst: result?.sgst,
        igst: result?.igst,
        remarks: result?.remarks,
        financial_year: result?.financial_year,
        purchase_ledger_id: result?.purchase_ledger_id
          ? +result?.purchase_ledger_id
          : null,
        sub_total: result?.sub_total,
        round_off: result?.round_off,
        taxable_amount: result?.taxable_amount,
        debit_note_items: result?.debit_note_items
          ? result?.debit_note_items?.map((debitNoteitem: any) => {
              return {
                id: debitNoteitem?.id ? +debitNoteitem?.id : null,
                debit_note_id: debitNoteitem?.debit_note_id
                  ? +debitNoteitem?.debit_note_id
                  : null,
                item_id: debitNoteitem?.item_id
                  ? +debitNoteitem?.item_id
                  : null,
                quantity: debitNoteitem?.quantity
                  ? +debitNoteitem?.quantity
                  : null,
                unit_id: debitNoteitem?.unit_id
                  ? +debitNoteitem?.unit_id
                  : null,
                unit_name: debitNoteitem?.unit_name
                  ? debitNoteitem?.unit_name
                  : "",
                unit_decimal: debitNoteitem?.unit_decimal
                  ? +debitNoteitem?.unit_decimal
                  : 0,
                rate: debitNoteitem?.rate ? +debitNoteitem?.rate : null,
                free_quantity: debitNoteitem?.free_quantity
                  ? +debitNoteitem?.free_quantity
                  : null,
                discount_type: debitNoteitem?.discount_type === 1 ? "₹" : "%",
                discount: debitNoteitem?.discount
                  ? debitNoteitem?.discount
                  : null,
                purchase_ledger_id: debitNoteitem?.purchase_ledger_id
                  ? +debitNoteitem?.purchase_ledger_id
                  : null,
                tax_percentage: debitNoteitem?.tax_percentage
                  ? +debitNoteitem?.tax_percentage
                  : 0,
                gst_id: debitNoteitem?.gst_id
                  ? `${debitNoteitem?.gst_id}--${
                      debitNoteitem?.sgst_per
                        ? debitNoteitem?.sgst_per + debitNoteitem?.cgst_per
                        : debitNoteitem?.igst_per
                        ? debitNoteitem?.igst_per
                        : "0"
                    }`
                  : null,
                sgst_per: debitNoteitem?.sgst_per
                  ? +debitNoteitem?.sgst_per
                  : null,
                sgst_amount: debitNoteitem?.sgst_amount
                  ? +debitNoteitem?.sgst_amount
                  : null,
                cgst_per: debitNoteitem?.cgst_per
                  ? +debitNoteitem?.cgst_per
                  : null,
                cgst_amount: debitNoteitem?.cgst_amount
                  ? +debitNoteitem?.cgst_amount
                  : null,
                igst_per: debitNoteitem?.igst_per
                  ? +debitNoteitem?.igst_per
                  : null,
                igst_amount: debitNoteitem?.igst_amount
                  ? +debitNoteitem?.igst_amount
                  : null,
                amount: debitNoteitem?.amount ? +debitNoteitem?.amount : null,
                item_options: debitNoteitem?.item_options
                  ? debitNoteitem?.item_options
                  : [],
              };
            })
          : [],
        debit_note_duty_and_taxes: result?.debit_note_duty_and_taxes
          ? result.debit_note_duty_and_taxes?.map((tax: any) => {
              return {
                ledger_id: tax?.ledger_id,
                ledger_name: tax?.ledger_name,
                gst_per: tax?.gst_per,
                gst_amount: tax?.gst_amount,
                ledger_options: tax?.ledger_options ? tax?.ledger_options : [],
              };
            })
          : [],
        vendor_options: result?.vendor_options ? result?.vendor_options : [],
        debit_note_ledger: result?.debit_note_ledger
          ? result?.debit_note_ledger
          : [],
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching DebitNote details:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const generateDebitNoteNoForDebitNote = createAsyncThunk(
  "DebitNote/InvoiceNumberdebitnote",
  async (payload: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/DebitNote/InvoiceNumberdebitnote`,
        payload
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error generating debitNote no. :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
