import React, { useEffect, useRef } from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog } from "../../components/dialog/dialogSlice";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import AppDialog from "../../components/dialog/Dialog";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import { Typography } from "@progress/kendo-react-common";
import { setPaymentBillDialogOpenIndex } from "./paymentSlice";
import { LoadingPanel } from "../../components/layout/Loading";
import { useLocation } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import FormCheckbox from "../../components/formFields/FormCheckbox";
import { SUNDRY_CREDITORS_DEBTORS } from "../../_contstants/common";
// import FormTextField from "../../components/formFields/FormTextField";

const PaymentBillDetailsArray = (
  fieldArrayRenderProps: FieldArrayRenderProps
) => {
  const dispatch = useAppDispatch();
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const loading = useAppSelector((state) => state.payment.loading);
  const gridRef = useRef<any>(null);
  const location = useLocation();
  const payment_guid = location.state?.payment_guid;
  const PaymentBillDialogOpenIndex = useAppSelector(
    (state) => state.payment.PaymentBillDialogOpenIndex
  );
  const PaymentDetail = useAppSelector((state) => state.payment.PaymentDetail);

  const RemainingAmount =
    fieldArrayRenderProps.formRenderProps.valueGetter(
      "IndexDetails"
    )?.remainingAmount;
  const FieldAmount =
    fieldArrayRenderProps.formRenderProps.valueGetter("IndexDetails")?.amount;
  const LedgerName =
    fieldArrayRenderProps.formRenderProps.valueGetter(
      "IndexDetails"
    )?.ledger_name;

  useEffect(() => {
    const element = document.getElementById(
      `payment_bill_entry.0.refamount_id_id`
    );
    if (!loading) {
      if (element) {
        setTimeout(() => {
          element?.focus();
          if (payment_guid) {
            element?.blur();
          }
        }, 1);
      }
    }
  }, [loading]);

  const handleCloseDialog = () => {
    dispatch(closeDialog());
    const isSundryCreditorDebtors = SUNDRY_CREDITORS_DEBTORS?.includes(
      fieldArrayRenderProps.formRenderProps
        .valueGetter(
          `payment_entry.${PaymentBillDialogOpenIndex}.ledger_options`
        )
        ?.find(
          (ledger: any) =>
            ledger.id ===
            fieldArrayRenderProps.formRenderProps.valueGetter(
              `payment_entry.${PaymentBillDialogOpenIndex}.ledger_id`
            )
        )?.under_group_id
    );

    const element = document.getElementById(
      `payment_entry.${PaymentBillDialogOpenIndex + 1}.ledger_id_id`
    );
    const elementtwo = document.getElementById(
      `payment_entry.${PaymentBillDialogOpenIndex}.cheque_no_id`
    );
    if (isSundryCreditorDebtors && elementtwo) {
      setTimeout(() => {
        elementtwo.focus();
      }, 1);
    } else {
      if (element) {
        setTimeout(() => {
          element.focus();
        }, 1);
      } else {
        const element = document.getElementById(`remarks_for_focus`);
        if (element) {
          setTimeout(() => {
            element.focus();
          }, 1);
        }
      }
    }
    dispatch(setPaymentBillDialogOpenIndex(-1));
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" || event.key === "Esc") {
        dispatch(setPaymentBillDialogOpenIndex(-1));
        handleCloseDialog();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      dispatch(setPaymentBillDialogOpenIndex(-1));
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleKeyEvent = (
    e: any,
    index: number,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => {
    let pendingAmount = 0;
    if (payment_guid) {
      pendingAmount =
        (fieldArrayRenderProps.value[index].final_pending_amount || 0) +
        (+PaymentDetail?.payment_entry?.[
          PaymentBillDialogOpenIndex
        ]?.payment_entry_referance.filter(
          (item: any) =>
            item.refernace_name ===
            fieldArrayRenderProps.formRenderProps.valueGetter(
              `payment_bill_entry.${index}.bill_no_string`
            )
        )?.[0]?.referance_amount || 0);

      // console.log("GUID key change pending amount", pendingAmount);
    } else {
      pendingAmount =
        fieldArrayRenderProps.value[index]?.final_pending_amount || 0;
    }
    const remainingAmount =
      (RemainingAmount || 0) +
      (fieldArrayRenderProps.value[index]?.referance_amount || 0);

    if (pendingAmount > remainingAmount) {
      fieldArrayRenderProps.formRenderProps.onChange(
        `payment_bill_entry.${index}.MaxAmount`,
        {
          value: +remainingAmount,
        }
      );
    } else {
      fieldArrayRenderProps.formRenderProps.onChange(
        `payment_bill_entry.${index}.MaxAmount`,
        {
          value: +pendingAmount,
        }
      );
    }
    if (e.key === "Tab" && RemainingAmount === 0) {
      if (!e.shiftKey) {
        handleCloseDialog();
      }
    }
  };

  const handleRefAmountChange = (
    e: any,
    index: number,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => {
    let pendingAmount = 0;
    if (payment_guid) {
      pendingAmount =
        (fieldArrayRenderProps.formRenderProps.valueGetter(
          `payment_bill_entry.${index}.final_pending_amount`
        ) || 0) +
        (+PaymentDetail?.payment_entry?.[
          PaymentBillDialogOpenIndex
        ]?.payment_entry_referance.filter(
          (item: any) =>
            item.refernace_name ===
            fieldArrayRenderProps.formRenderProps.valueGetter(
              `payment_bill_entry.${index}.bill_no_string`
            )
        )?.[0]?.referance_amount || 0);
    } else {
      pendingAmount =
        fieldArrayRenderProps.formRenderProps.valueGetter(
          `payment_bill_entry.${index}.final_pending_amount`
        ) || 0;
    }

    if (e.value === pendingAmount) {
      fieldArrayRenderProps.formRenderProps.onChange(
        `payment_bill_entry.${index}.isfullpayment`,
        { value: true }
      );
    } else {
      fieldArrayRenderProps.formRenderProps.onChange(
        `payment_bill_entry.${index}.isfullpayment`,
        { value: false }
      );
    }
  };
  const handleIsFullPayment = (
    e: any,
    index: number,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => {
    if (e.value) {
      let pendingAmount = 0;
      if (payment_guid) {
        pendingAmount =
          (fieldArrayRenderProps.formRenderProps.valueGetter(
            `payment_bill_entry.${index}.final_pending_amount`
          ) || 0) +
          (+PaymentDetail?.payment_entry?.[
            PaymentBillDialogOpenIndex
          ]?.payment_entry_referance.filter(
            (item: any) =>
              item.refernace_name ===
              fieldArrayRenderProps.formRenderProps.valueGetter(
                `payment_bill_entry.${index}.bill_no_string`
              )
          )?.[0]?.referance_amount || 0);
      } else {
        pendingAmount =
          fieldArrayRenderProps.formRenderProps.valueGetter(
            `payment_bill_entry.${index}.final_pending_amount`
          ) || 0;
      }
      const remainingAmount =
        (fieldArrayRenderProps.formRenderProps.valueGetter("IndexDetails")
          ?.remainingAmount || 0) +
        (fieldArrayRenderProps.value[index]?.referance_amount || 0);

      if (remainingAmount >= pendingAmount) {
        fieldArrayRenderProps.formRenderProps.onChange(
          `payment_bill_entry.${index}.referance_amount`,
          { value: pendingAmount }
        );
      } else {
        fieldArrayRenderProps.formRenderProps.onChange(
          `payment_bill_entry.${index}.isfullpayment`,
          { value: false }
        );
      }
    } else {
      fieldArrayRenderProps.formRenderProps.onChange(
        `payment_bill_entry.${index}.referance_amount`,
        { value: 0 }
      );
    }
  };

  return (
    <>
      {dialogName === "BillItemDeleteDialog" && (
        <AppDialog className="journal-bill-dialog">
          <>
            <GridLayout
              cols={[
                {
                  width: "950px",
                },
              ]}
              style={{ padding: "10px" }}
            >
              <GridLayoutItem>
                <Typography.p className="m-0 text-center">
                  Bill-wise Details For :- <b>{LedgerName?.toUpperCase()}</b>
                </Typography.p>
                <Typography.p className="m-0 text-center">
                  Up to :- <b>₹ {FieldAmount} Dr</b>
                </Typography.p>
                <Typography.p
                  className=" text-center"
                  style={{ position: "relative" }}
                >
                  Remaining Amount :-
                  <b>
                    <span
                      style={{ color: RemainingAmount > 0 ? "green" : "red" }}
                    >
                      {" "}
                      ₹ {RemainingAmount}
                    </span>{" "}
                    Dr.
                  </b>
                </Typography.p>
              </GridLayoutItem>
            </GridLayout>
            <GridLayout
              cols={[{ width: "950px" }]}
              style={{
                maxHeight: "500px",
                overflowY: "scroll",
              }}
            >
              <GridLayoutItem
                className="bg-dark text-white fw-600"
                style={{
                  position: "sticky",
                  top: "0",
                  zIndex: "2",
                }}
              >
                <GridLayout
                  gap={{ rows: 10, cols: 0 }}
                  cols={[
                    { width: "8%" },
                    { width: "15%" },
                    { width: "15%" },
                    { width: "13%" },
                    { width: "14%" },
                    { width: "17%" },
                    { width: "15%" },
                    { width: "3%" },
                  ]}
                >
                  <GridLayoutItem
                    style={{ padding: "5px 10px", border: "0.5px solid grey" }}
                  >
                    Sr. No
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{ padding: "5px 10px", border: "0.5px solid grey" }}
                  >
                    Bill No
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{ padding: "5px 10px", border: "0.5px solid grey" }}
                  >
                    Bill Date
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{ padding: "5px 10px", border: "0.5px solid grey" }}
                  >
                    Due Date
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{ padding: "5px 10px", border: "0.5px solid grey" }}
                  >
                    Bill Amount
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{
                      padding: "5px 10px",
                      border: "0.5px solid grey",
                    }}
                  >
                    Pending Amount
                  </GridLayoutItem>

                  {/* <GridLayoutItem
                    style={{ padding: "5px 10px", border: "0.5px solid grey" }}
                  >
                    Cheque No
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{ padding: "5px 10px", border: "0.5px solid grey" }}
                  >
                    Bank Name
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{ padding: "5px 10px", border: "0.5px solid grey" }}
                  >
                    Favoring Name
                  </GridLayoutItem> */}
                  <GridLayoutItem
                    style={{ padding: "5px 10px", border: "0.5px solid grey" }}
                  >
                    Amount
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{ padding: "5px 10px", border: "0.5px solid grey" }}
                  ></GridLayoutItem>
                </GridLayout>
              </GridLayoutItem>
              {loading ? (
                <LoadingPanel gridRef={gridRef} />
              ) : (
                <>
                  {fieldArrayRenderProps.value &&
                    fieldArrayRenderProps.value.length > 0 &&
                    fieldArrayRenderProps.value.map(
                      (payment_bill_entry: any, index: number) => (
                        <GridLayoutItem
                          key={index}
                          style={{
                            backgroundColor:
                              index % 2 === 1 ? "#f4f1f1" : "#f9fdf9",
                          }}
                        >
                          <GridLayout
                            style={{
                              padding: "5px 0",
                            }}
                            gap={{ rows: 10, cols: 0 }}
                            cols={[
                              { width: "8%" },
                              { width: "15%" },
                              { width: "15%" },
                              { width: "13%" },
                              { width: "14%" },
                              { width: "17%" },
                              { width: "15%" },
                              { width: "3%" },
                            ]}
                          >
                            <GridLayoutItem
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "5px 10px",
                              }}
                            >
                              {index + 1}
                            </GridLayoutItem>
                            <GridLayoutItem
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "5px 10px",
                              }}
                            >
                              {payment_bill_entry?.bill_no_string}
                            </GridLayoutItem>
                            <GridLayoutItem
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "5px 10px",
                              }}
                            >
                              {payment_bill_entry?.invoice_date}
                            </GridLayoutItem>
                            <GridLayoutItem
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "5px 10px",
                              }}
                            >
                              {payment_bill_entry?.due_date}
                            </GridLayoutItem>
                            <GridLayoutItem
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "end",
                                padding: "5px 10px",
                              }}
                            >
                              {Number(payment_bill_entry?.total_amount).toFixed(
                                2
                              ) || 0}
                            </GridLayoutItem>
                            <GridLayoutItem
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "end",
                                padding: "5px 15px",
                                color: "#ff3d3d",
                                fontWeight: "bold",
                              }}
                            >
                              {Number(
                                payment_bill_entry?.final_pending_amount
                              ).toFixed(2) || 0}
                            </GridLayoutItem>
                            {/* <GridLayoutItem>
                              <Field
                                wrapperStyle={{ margin: 0, padding: "5px" }}
                                id={`payment_bill_entry.${index}.cheque_no_id`}
                                name={`payment_bill_entry.${index}.cheque_no`}
                                max={6}
                                placeholder="i.e. 123456"
                                type={"number"}
                                component={FormTextField}
                              />
                            </GridLayoutItem>
                            <GridLayoutItem>
                              <Field
                                wrapperStyle={{ margin: 0, padding: "5px" }}
                                name={`payment_bill_entry.${index}.bank_name`}
                                placeholder="i.e. Bank Of Baroda"
                                component={FormTextField}
                              />
                            </GridLayoutItem>
                            <GridLayoutItem>
                              <Field
                                wrapperStyle={{ margin: 0, padding: "5px" }}
                                name={`payment_bill_entry.${index}.favouring_name`}
                                placeholder="Favoring Name"
                                component={FormTextField}
                              />
                            </GridLayoutItem> */}
                            <GridLayoutItem>
                              <Field
                                wrapperClassName="right-alighned-field"
                                wrapperStyle={{ margin: 0, padding: "5px" }}
                                name={`payment_bill_entry.${index}.referance_amount`}
                                id={`payment_bill_entry.${index}.refamount_id_id`}
                                placeholder="0"
                                format="n2"
                                max={
                                  +fieldArrayRenderProps.formRenderProps.valueGetter(
                                    `payment_bill_entry.${index}.MaxAmount`
                                  )
                                }
                                onChange={(e: any) =>
                                  handleRefAmountChange(
                                    e,
                                    index,
                                    fieldArrayRenderProps
                                  )
                                }
                                component={FormNumericTextField}
                                onKeyDown={(e: any) =>
                                  handleKeyEvent(
                                    e,
                                    index,
                                    fieldArrayRenderProps
                                  )
                                }
                              />
                            </GridLayoutItem>
                            <GridLayoutItem>
                              <Field
                                wrapperClassName="right-alighned-field"
                                wrapperStyle={{
                                  margin: "0px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "100%",
                                  borderRadius: "5px",
                                }}
                                size="large"
                                style={{
                                  border: "1px solid #ccc",
                                }}
                                onChange={(e: any) =>
                                  handleIsFullPayment(
                                    e,
                                    index,
                                    fieldArrayRenderProps
                                  )
                                }
                                tabIndex={-1}
                                name={`payment_bill_entry.${index}.isfullpayment`}
                                component={FormCheckbox}
                              />
                            </GridLayoutItem>
                          </GridLayout>
                        </GridLayoutItem>
                      )
                    )}
                </>
              )}
            </GridLayout>
            <GridLayout
              cols={[{ width: "950px" }]}
              style={{
                position: "absolute",
                bottom: "15px",
              }}
            >
              <GridLayoutItem>
                <GridLayout cols={[{ width: "950px" }]}>
                  <GridLayoutItem
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      style={{ marginRight: "-20px" }}
                      themeColor={"primary"}
                      fillMode={"outline"}
                      onClick={handleCloseDialog}
                      className="dialog-close-btn-entry"
                    >
                      Close
                    </Button>
                    {/* // <DialogCloseButton themeColor="error" /> */}
                  </GridLayoutItem>
                </GridLayout>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default PaymentBillDetailsArray;
