import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";

export const getAllSRExpensesForVerify = createAsyncThunk(
  "ExpenseDetails/FindAllAdminExpenseList",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ExpenseDetails/FindAllAdminExpenseList`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching SR Expense For Verify:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const verifySRExpense = createAsyncThunk(
  "ExpenseDetails/VerifyExpenseDetails",
  async (expense: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ExpenseDetails/VerifyExpenseDetails`,
        expense
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Item:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getExpenseDetailsByID = createAsyncThunk(
  "ExpenseDetails/FindByIDExpenseDetails",
  async (id: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ExpenseDetails/FindByIDExpenseDetails`,
        { expense_details_guid: id }
      );

      const result = response.data?.Data as any;

      return result;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Item:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
