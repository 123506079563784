import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import moment from "moment";

export const getAllCreditNote = createAsyncThunk(
  "CreditNote/FindAllCreditNote",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/CreditNote/FindAllCreditNote`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching CreditNote :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createCreditNote = createAsyncThunk(
  "CreditNote/InsertCreditNote",
  async (CreditNoteData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/CreditNote/InsertCreditNote`,
        CreditNoteData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating creditNote :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateCreditNote = createAsyncThunk(
  "CreditNote/UpdateCreditNote",
  async (CreditNoteData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/CreditNote/UpdateCreditNote`,
        CreditNoteData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating CreditNote :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteCreditNote = createAsyncThunk(
  "CreditNote/DeleteCreditNote",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/CreditNote/DeleteCreditNote`,
        { credit_note_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting CreditNote :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getCreditNoteByID = createAsyncThunk(
  "CreditNote/FindByIDCreditNote",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/CreditNote/FindByIDCreditNote`,
        formData
      );
      const result = response.data?.Data as any;
      return {
        id: result?.id,
        credit_note_guid: result?.credit_note_guid,
        voucher_type_id: result?.voucher_type_id,
        credit_note_no: result?.credit_note_no,
        credit_note_no_string: result?.credit_note_no_string,
        credit_note_date: result?.credit_note_date
          ? moment(result?.credit_note_date, "YYYY-MM-DD").toDate()
          : "",
        bill_no: result?.bill_no,
        bill_date: result?.bill_date
          ? moment(result?.bill_date, "YYYY-MM-DD").toDate()
          : "",
        vendor_id: result?.vendor_id,
        taxable_amount: result?.taxable_amount,
        sub_total: result?.sub_total,
        round_off: result?.round_off,
        total_amount: result?.total_amount,
        total_gst: result?.total_gst,
        gst: result?.gst,
        cgst: result?.cgst,
        sgst: result?.sgst,
        igst: result?.igst,
        remarks: result?.remarks,
        financial_year: result?.financial_year,
        vehicle_no: result?.vehicle_no,
        transport_name: result?.transport_name,
        sales_ledger_id: result?.sales_ledger_id
          ? +result?.sales_ledger_id
          : null,
        credit_note_items: result?.credit_note_items
          ? result?.credit_note_items?.map((creditNoteitem: any) => {
              return {
                id: creditNoteitem?.id ? +creditNoteitem?.id : null,
                credit_note_id: creditNoteitem?.credit_note_id
                  ? +creditNoteitem?.credit_note_id
                  : null,
                item_id: creditNoteitem?.item_id
                  ? +creditNoteitem?.item_id
                  : null,
                quantity: creditNoteitem?.quantity
                  ? +creditNoteitem?.quantity
                  : null,
                unit_id: creditNoteitem?.unit_id
                  ? +creditNoteitem?.unit_id
                  : null,
                unit_name: creditNoteitem?.unit_name
                  ? creditNoteitem?.unit_name
                  : "",
                unit_decimal: creditNoteitem?.unit_decimal
                  ? +creditNoteitem?.unit_decimal
                  : 0,
                rate: creditNoteitem?.rate ? +creditNoteitem?.rate : null,
                free_quantity: creditNoteitem?.free_quantity
                  ? +creditNoteitem?.free_quantity
                  : null,
                discount_type: creditNoteitem?.discount_type === 1 ? "₹" : "%",
                discount: creditNoteitem?.discount
                  ? creditNoteitem?.discount
                  : null,
                sales_ledger_id: creditNoteitem?.sales_ledger_id
                  ? +creditNoteitem?.sales_ledger_id
                  : null,
                tax_percentage: creditNoteitem?.tax_percentage
                  ? +creditNoteitem?.tax_percentage
                  : 0,
                gst_id: creditNoteitem?.gst_id
                  ? `${creditNoteitem?.gst_id}--${
                      creditNoteitem?.sgst_per
                        ? creditNoteitem?.sgst_per + creditNoteitem?.cgst_per
                        : creditNoteitem?.igst_per
                        ? creditNoteitem?.igst_per
                        : "0"
                    }`
                  : null,
                sgst_per: creditNoteitem?.sgst_per
                  ? +creditNoteitem?.sgst_per
                  : null,
                sgst_amount: creditNoteitem?.sgst_amount
                  ? +creditNoteitem?.sgst_amount
                  : null,
                cgst_per: creditNoteitem?.cgst_per
                  ? +creditNoteitem?.cgst_per
                  : null,
                cgst_amount: creditNoteitem?.cgst_amount
                  ? +creditNoteitem?.cgst_amount
                  : null,
                igst_per: creditNoteitem?.igst_per
                  ? +creditNoteitem?.igst_per
                  : null,
                igst_amount: creditNoteitem?.igst_amount
                  ? +creditNoteitem?.igst_amount
                  : null,
                amount: creditNoteitem?.amount ? +creditNoteitem?.amount : null,
                item_options: creditNoteitem?.item_options
                  ? creditNoteitem?.item_options
                  : [],
              };
            })
          : [],
        credit_note_duty_and_taxes: result?.credit_note_duty_and_taxes
          ? result.credit_note_duty_and_taxes?.map((tax: any) => {
              return {
                ledger_id: tax?.ledger_id,
                ledger_name: tax?.ledger_name,
                gst_per: tax?.gst_per,
                gst_amount: tax?.gst_amount,
                ledger_options: tax?.ledger_options ? tax?.ledger_options : [],
              };
            })
          : [],
        vendor_options: result?.vendor_options ? result?.vendor_options : [],
        credit_note_ledger: result?.credit_note_ledger
          ? result?.credit_note_ledger
          : [],
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching CreditNote details:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getCreditNotePrint = createAsyncThunk(
  "CreditNote/CreditNoteRegisterPrint",
  async (credit_note_guid: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/CreditNote/CreditNoteRegisterPrint`,
        { credit_note_guid: credit_note_guid }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Credit Note Print:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const generateCreditNoteNoForCreditNote = createAsyncThunk(
  "CreditNote/InvoiceNumbercreditnote",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/CreditNote/InvoiceNumbercreditnote`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error generating creditNote no. :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
