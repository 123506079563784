import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IExpenseType } from "../expenseTypeModel";

export const getAllExpenseTypes = createAsyncThunk(
  "ExpenseType/FindAllExpenseType",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ExpenseType/FindAllExpenseType`,
        {
          expense_name: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Expense Types:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const InsertExpenseType = createAsyncThunk(
  "ExpenseType/InsertExpenseType",
  async (ExpenseTypeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ExpenseType/InsertExpenseType`,
        ExpenseTypeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating expense type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateExpenseType = createAsyncThunk(
  "ExpenseType/UpdateExpenseType",
  async (ExpenseTypeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ExpenseType/UpdateExpenseType`,
        ExpenseTypeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating expense type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteExpenseType = createAsyncThunk(
  "ExpenseType/DeleteExpenseType",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ExpenseType/DeleteExpenseType`,
        { expense_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting expense type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getExpenseTypeByID = createAsyncThunk(
  "ExpenseType/FindByIDExpenseType",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ExpenseType/FindByIDExpenseType`,
        { expense_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        id: result.id,
        expense_guid: result.expense_guid,
        expense_name: result.expense_name,
        expense_type: result.expense_type,
        isactive: result.isactive === true ? 1 : 2,
      } as IExpenseType;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching expense type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveExpenseTypes = createAsyncThunk(
  "ExpenseType/FindAllActiveExpenseType",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/ExpenseType/FindAllActiveExpenseType`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Expense Types:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveExpenseType = createAsyncThunk(
  "ExpenseType/ActiveInActiveExpenseType",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ExpenseType/ActiveInActiveExpenseType`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive expense type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllFixedExpenseTypes = createAsyncThunk(
  "ExpenseType/FixedExpenseType",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/ExpenseType/FixedExpenseType`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Fixed Expense Types:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
