import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IModeOfTransport,
  IModeOfTransportInitialState,
} from "./modeOfTransportModel";
import {
  ActiveInactiveModeOfTransport,
  createModeOfTransport,
  deleteModeOfTransport,
  getAllActiveModeOfTransport,
  getAllModeOfTransport,
  getModeOfTransportById,
  updateModeOfTransport,
} from "./services/modeOfTransport.services";

const initialState: IModeOfTransportInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  ModeOfTransportList: [],
  ModeOfTransportID: null,
  ModeOfTransportDetail: {
    mode_of_transport: "",
    isactive: 1,
  },
};

export const modeOfTransportSlice = createSlice({
  name: "modeOfTransport",
  initialState,
  reducers: {
    clearModeOfTransportDetails: (state) => {
      state.ModeOfTransportDetail = initialState.ModeOfTransportDetail;
    },
    setModeOfTransportID: (state, action) => {
      state.ModeOfTransportID = action.payload;
    },
    clearModeOfTransportID: (state) => {
      state.ModeOfTransportID = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllModeOfTransport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllModeOfTransport.fulfilled,
      (state, action: PayloadAction<IModeOfTransport[]>) => {
        state.loading = false;
        state.ModeOfTransportList = action.payload;
      }
    );
    builder.addCase(getAllModeOfTransport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ModeOfTransportList = [];
    });

    builder.addCase(getAllActiveModeOfTransport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveModeOfTransport.fulfilled,
      (state, action: PayloadAction<IModeOfTransport[]>) => {
        state.loading = false;
        state.ModeOfTransportList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveModeOfTransport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ModeOfTransportList = [];
    });

    builder.addCase(createModeOfTransport.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createModeOfTransport.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createModeOfTransport.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateModeOfTransport.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateModeOfTransport.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateModeOfTransport.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteModeOfTransport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteModeOfTransport.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteModeOfTransport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(ActiveInactiveModeOfTransport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveModeOfTransport.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveModeOfTransport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getModeOfTransportById.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getModeOfTransportById.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.ModeOfTransportDetail = action.payload;
    });
    builder.addCase(getModeOfTransportById.rejected, (state, action) => {
      state.formLoading = false;
      state.ModeOfTransportDetail = initialState.ModeOfTransportDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearModeOfTransportDetails,
  setModeOfTransportID,
  clearModeOfTransportID,
} = modeOfTransportSlice.actions;
export default modeOfTransportSlice.reducer;
