import React, { useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import ShadowCard from "../../components/common/ShadowCard";
import FormDatePicker from "../../components/formFields/FormDateField";
import { FINANCIAL_YEAR } from "../../_contstants/common";
import moment from "moment";

import PaymentEntryDetailsArray from "./PaymentEntryDetailsArray";
import {
  createPayment,
  generatePaymentNoForPayment,
  getPaymentByID,
  updatePayment,
} from "./services/payment.services";

import { findAllVoucherTypeByType } from "../vouchertype/services/voucherType.services";
import {
  // clearOutstandingPaymentList,
  clearPaymentDetails,
  setPaymentBillDialogOpenIndex,
} from "./paymentSlice";
import PaymentBillDetailsArray from "./PaymentBillDetailsArray";
import {
  getAllAccountIncremental,
  getAllPaymentAndReceipt,
} from "../account/services/account.services";
import FormTextArea from "../../components/formFields/FormTextArea";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { BiInfoCircle } from "react-icons/bi";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { findIndex } from "lodash";
import { ErrorToast } from "../../components/toast/Toasts";
interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const BillPaymentEntryChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const payment_bill_entry =
    formRenderProps.valueGetter("payment_bill_entry") || [];
  const payment_entry = formRenderProps.valueGetter("payment_entry");
  const PaymentBillDialogOpenIndex = useAppSelector(
    (state) => state.payment.PaymentBillDialogOpenIndex
  );

  const OutstandingPaymentList = useAppSelector(
    (state) => state.payment.OutstandingPaymentList
  );
  const location = useLocation();
  const payment_guid = location.state?.payment_guid;

  useEffect(() => {
    const EntryReferance =
      payment_entry?.[PaymentBillDialogOpenIndex]?.payment_entry_referance;
    if (PaymentBillDialogOpenIndex >= 0 && EntryReferance?.length > 0) {
      const IsPaymentRefID = EntryReferance?.[0]?.payment_ref_id;
      if (payment_guid && IsPaymentRefID) {
        const referenceMap = new Map<
          number,
          {
            refernace_name: string;
            referance_amount: number;
            referance_type: string;
          }
        >(
          EntryReferance?.map((entry: any) => [
            entry.refernace_bill_id,
            {
              refernace_name: entry.refernace_name,
              referance_amount: entry.referance_amount,
              referance_type: entry.referance_type,
            },
          ])
        );
        const mergedArray = OutstandingPaymentList.map((bill: any) => {
          const match = referenceMap.get(bill.id);
          const pendingAmountFullPaid = bill.final_pending_amount;
          if (match && match?.refernace_name === bill.bill_no_string) {
            if (!pendingAmountFullPaid) {
              return {
                ...bill,
                referance_amount: match.referance_amount,
                referance_type: match.referance_type,
                isfullpayment: true,
              };
            } else {
              return {
                ...bill,
                referance_amount: match.referance_amount,
                referance_type: match.referance_type,
                isfullpayment: false,
              };
            }
          }
          return { ...bill }; // Return the bill unchanged if no match
        });
        formRenderProps.onChange("payment_bill_entry", {
          value: mergedArray || [],
        });
      } else {
        formRenderProps.onChange("payment_bill_entry", {
          value: EntryReferance || [],
        });
      }
    } else {
      formRenderProps.onChange("payment_bill_entry", {
        value: JSON.parse(JSON.stringify(OutstandingPaymentList)) || [],
      });
    }
  }, [PaymentBillDialogOpenIndex, OutstandingPaymentList?.[0]?.id]);

  useEffect(() => {
    const calculateTotals = (entries: typeof payment_entry) => {
      const totals: Record<string, number> = {};
      entries.forEach((entry: any) => {
        const key = `payment_total_amount`;
        totals[key] = (totals[key] || 0) + entry.amount;
      });
      return totals;
    };
    const result = calculateTotals(payment_entry);
    if (payment_entry && payment_entry?.length > 0) {
      formRenderProps.onChange(`payment_total_amount`, {
        value: result.payment_total_amount || 0,
      });
    }
  }, [
    payment_entry?.map((item: any) => item?.amount).join("-"),
    payment_entry?.map((item: any) => item?.debit_credit).join("-"),
    payment_entry?.map((item: any) => item?.ledger_id).join("-"),
  ]);

  useEffect(() => {
    let TotalAmount = 0;
    const PaymentEntryOpenIndex = payment_entry[PaymentBillDialogOpenIndex];
    if (PaymentEntryOpenIndex) {
      payment_entry[PaymentBillDialogOpenIndex] = {
        ...PaymentEntryOpenIndex,
        payment_entry_referance: [...payment_bill_entry],
      };
    }
    if (
      payment_entry[PaymentBillDialogOpenIndex]?.payment_entry_referance
        ?.length > 0
    ) {
      TotalAmount = payment_entry[
        PaymentBillDialogOpenIndex
      ]?.payment_entry_referance?.reduce(
        (total: number, item: any) => total + (item?.referance_amount || 0),
        0
      );
    } else {
      TotalAmount = 0;
    }
    const RemaingAmount =
      (PaymentEntryOpenIndex?.amount ?? 0) - (TotalAmount ?? 0);
    const IndexDetailsObj = {
      remainingAmount: RemaingAmount,

      ledger_name: PaymentEntryOpenIndex?.ledger_options?.find(
        (account: any) => account?.id === PaymentEntryOpenIndex?.ledger_id
      )?.account_name,
      ...PaymentEntryOpenIndex,
    };
    formRenderProps.onChange(`IndexDetails`, {
      value: IndexDetailsObj || 0,
    });
  }, [
    PaymentBillDialogOpenIndex,
    OutstandingPaymentList?.[0]?.id,
    payment_entry?.map((item: any) => item?.amount).join("-"),
    payment_entry?.map((item: any) => item?.debit_credit).join("-"),
    payment_entry?.map((item: any) => item?.ledger_id).join("-"),
    payment_bill_entry?.map((item: any) => item?.referance_amount).join("-"),
  ]);

  return null;
};

const PaymentNoChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const payment_guid = location.state?.payment_guid;
  // const isPaymentNoRef = useRef(false);
  const voucher_type_id = formRenderProps.valueGetter("voucher_type_id");
  const payment_no_string = formRenderProps.valueGetter("payment_no_string");
  const VoucherTypeList = useAppSelector(
    (state) => state.voucherType.VoucherTypeList
  );
  const PaymentDetail = useAppSelector((state) => state.payment.PaymentDetail);

  useEffect(() => {
    const fetchPaymentNo = async () => {
      const payload = {
        financial_year: FINANCIAL_YEAR,
        voucher_type_id: +voucher_type_id,
      };
      const response = await dispatch(generatePaymentNoForPayment(payload));
      if (response.meta.requestStatus === "fulfilled") {
        formRenderProps.onChange("payment_no_string", {
          value: response.payload?.payment_no_string || "",
        });
        formRenderProps.onChange("payment_no", {
          value: response.payload?.payment_no_string || "",
        });
        const ledgerIDIndex = findIndex(VoucherTypeList, {
          id: voucher_type_id,
        });
        if (ledgerIDIndex > -1) {
          formRenderProps.onChange("ledger_id", {
            value: VoucherTypeList?.[ledgerIDIndex]?.map_ledger_id,
          });
        } else {
          formRenderProps.onChange("ledger_id", {
            value: null,
          });
        }
      }
    };
    if (voucher_type_id && !payment_guid) {
      fetchPaymentNo();
    }
  }, [voucher_type_id]);

  useEffect(() => {
    const fetchPaymentNo = async () => {
      const oldkey = PaymentDetail?.payment_no_string
        ?.split("/")
        ?.slice(0, -1)
        .join("/");
      if (
        payment_no_string?.split("/").length ===
        PaymentDetail?.payment_no_string?.split("/").length
      ) {
        const modifiyed = payment_no_string?.split("/")?.pop();
        formRenderProps.onChange("payment_no_string", {
          value: `${oldkey}/${modifiyed}`,
        });
      } else {
        formRenderProps.onChange("payment_no_string", {
          value: `${oldkey}/`,
        });
      }
    };
    if (payment_guid) {
      fetchPaymentNo();
    }
  }, [payment_no_string]);

  return null;
};

const CreatePayment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const payment_guid = location.state?.payment_guid;
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.payment.loading);
  const PaymentDetail = useAppSelector((state) => state.payment.PaymentDetail);
  const AccountPaymentAndReceiptList = useAppSelector(
    (state) => state.account.AccountPaymentAndReceiptList
  );

  const VoucherTypeList = useAppSelector(
    (state) => state.voucherType.VoucherTypeList
  );

  const [formKey, setFormKey] = React.useState(1);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [PaymentDetail]);

  useEffect(() => {
    if (payment_guid) {
      const payload = {
        payment_guid: payment_guid,
      };
      dispatch(getPaymentByID(payload));
    }
  }, [payment_guid]);

  useEffect(() => {
    const payload = {
      account_name: "",
      under_group_id: "16,17,18",
    };

    dispatch(getAllPaymentAndReceipt(payload));
    dispatch(findAllVoucherTypeByType(3));

    return () => {
      dispatch(setPaymentBillDialogOpenIndex(-1));
      dispatch(clearPaymentDetails());
    };
  }, []);

  const handleVendorSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllAccountIncremental(search));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = async (values: any) => {
    // console.log("values", values);

    // return;
    if (
      !(
        values?.payment_entry?.filter(
          (entry: any) => entry?.ledger_id && entry?.amount
        ).length > 0
      )
    ) {
      ErrorToast("At least One Entry is Required!");
      return;
    }
    if (values?.payment_no_string?.endsWith("/")) {
      ErrorToast("Please Enter Voucher Number.");
      return;
    }
    const formdata: any = {
      financial_year: values?.financial_year
        ? +values?.financial_year
        : FINANCIAL_YEAR,
      payment_date: values?.payment_date
        ? moment(values?.payment_date).format("YYYY-MM-DD")
        : "",
      payment_total_amount: values?.payment_total_amount,
      remarks: values?.remarks,
      ledger_id: values?.ledger_id,
      voucher_type_id: values?.voucher_type_id,
      payment_entry: values?.payment_entry
        ?.filter((fentry: any) => fentry?.ledger_id)
        ?.map((entry: any) => ({
          // debit_credit: entry?.debit_credit,
          cheque_no: entry?.cheque_no,
          bank_name: entry?.bank_name,
          favouring_name: entry?.favouring_name,
          ledger_id: entry?.ledger_id,
          amount: entry?.amount,
          payment_entry_referance: entry?.payment_entry_referance
            ? entry?.payment_entry_referance
                ?.filter((fpay_ref: any) => fpay_ref?.referance_amount)
                ?.map((pay_ref: any) => {
                  if (pay_ref?.id) {
                    return {
                      referance_type: "Debit",
                      refernace_name: pay_ref?.bill_no_string
                        ? pay_ref?.bill_no_string
                        : pay_ref?.refernace_name,
                      refernace_bill_id: pay_ref?.refernace_bill_id
                        ? pay_ref?.refernace_bill_id
                        : pay_ref?.id,
                      referance_amount: pay_ref?.referance_amount,
                    };
                  }
                  return pay_ref;
                })
            : [],
        })),
    };
    if (payment_guid) {
      try {
        const updatePayload: any = {
          payment_guid: payment_guid,
          payment_no: values?.payment_no_string
            ? +values?.payment_no_string?.split("/")?.pop()
            : null,
          payment_no_string: values?.payment_no_string,
          id: values?.id ? +values?.id : null,
          ...formdata,
        };
        const response = await dispatch(updatePayment(updatePayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          if (values?.buttonIdentity === "save") {
            navigate("/payment");
          } else {
            dispatch(clearPaymentDetails());
            navigate("/payment/create");
          }
          dispatch(setPaymentBillDialogOpenIndex(-1));
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      try {
        const response = await dispatch(createPayment(formdata));
        if (response?.meta?.requestStatus === "fulfilled") {
          if (values?.buttonIdentity === "save") {
            navigate("/payment");
          } else {
            setFormKey(formKey + 1);
            navigate("/payment/create");
          }
          dispatch(setPaymentBillDialogOpenIndex(-1));
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={PaymentDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>
                    {payment_guid ? "Update Payment" : "Add Payment"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    label="Voucher Type"
                    name="voucher_type_id"
                    placeholder="Select Voucher Type"
                    disabled={payment_guid && true}
                    component={FormSelectionField}
                    options={VoucherTypeList.map((voucher: any) => {
                      return {
                        value: voucher?.id,
                        label: voucher?.name,
                      };
                    })}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="payment_no_string"
                    label="Voucher No"
                    disabled={payment_guid ? false : true}
                    placeholder="Voucher No"
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="payment_date"
                    label="Voucher Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem></GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    label="Account"
                    name="ledger_id"
                    placeholder="Select Account"
                    component={FormSelectionField}
                    options={AccountPaymentAndReceiptList.map((ledger: any) => {
                      return {
                        value: ledger?.id,
                        label: ledger?.account_name,
                      };
                    })}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <PaymentNoChangeWatcher formRenderProps={formRenderProps} />
                <BillPaymentEntryChangeWatcher
                  formRenderProps={formRenderProps}
                />
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem
                  colSpan={4}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography.h4>Particulars</Typography.h4>
                  <Tooltip
                    anchorElement="target"
                    position="left"
                    parentTitle={true}
                  >
                    <BiInfoCircle
                      title="Escape to Delete Entry."
                      style={{ color: "red", cursor: "pointer" }}
                    />
                  </Tooltip>
                </GridLayoutItem>
                <GridLayoutItem colSpan={4}>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    handleVendorSearchChange={handleVendorSearchChange}
                    component={PaymentEntryDetailsArray}
                    name="payment_entry"
                  />
                  {dialogName === "BillItemDeleteDialog" && (
                    <FieldArray
                      formRenderProps={formRenderProps}
                      component={PaymentBillDetailsArray}
                      name="payment_bill_entry"
                    />
                  )}
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}>
                  <Field
                    name="remarks"
                    label="Remarks"
                    rows={2}
                    id="remarks_for_focus"
                    component={FormTextArea}
                    // validator={requiredValidator}
                    // astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={4}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    marginTop: 15,
                  }}
                >
                  <div>
                    <ButtonWithLoading
                      label={payment_guid ? "Update" : "Save"}
                      type="submit"
                      onClick={() =>
                        formRenderProps.onChange("buttonIdentity", {
                          value: "save",
                        })
                      }
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                    <ButtonWithLoading
                      buttonStyle={{ marginLeft: 6 }}
                      onClick={() =>
                        formRenderProps.onChange("buttonIdentity", {
                          value: "continue",
                        })
                      }
                      label={
                        payment_guid
                          ? "Update and Continue"
                          : "Save and Continue"
                      }
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                    <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginLeft: 6 }}
                      onClick={() => {
                        navigate("/payment");
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreatePayment;
