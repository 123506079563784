import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import ShadowCard from "../../components/common/ShadowCard";
import FormTextField from "../../components/formFields/FormTextField";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import {
  createLoanEntry,
  getLoanEntryByID,
  updateLoanEntry,
} from "./services/loanentry.services";
import { clearLoanEntryDetails } from "./loanentrySlice";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import { findIndex } from "lodash";
import { LoadingPanel } from "../../components/layout/Loading";
import { closed } from "../../components/muidrawer/muiDrawerSlice";
import { getAllActiveEmployee } from "../employee/services/employee.services";
import { IEmployee } from "../employee/employeeModel";
import { MONTHS } from "../../_contstants/common";
import FormDatePicker from "../../components/formFields/FormDateField";
import moment from "moment";

interface ChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const AreaChangeWatcher: React.FC<ChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const loanAmount = formRenderProps.valueGetter("loan_amout");
  const loanInstallment = formRenderProps.valueGetter("loan_installment");
  const employee = formRenderProps.valueGetter("employee_id");
  const EmployeeList = useAppSelector((state) => state.employee.EmployeeList);
  const location = useLocation();
  const loan_guid = location.state?.loan_guid;

  useEffect(() => {
    if (employee && loan_guid) {
      const employeeIndex = findIndex(EmployeeList, {
        id: employee,
      });
      if (employeeIndex > -1) {
        const Employee = EmployeeList[employeeIndex];
        formRenderProps.onChange("employee_code_string", {
          value: Employee.employee_code_string,
        });
      }
    }
  }, [employee]);

  useEffect(() => {
    if (loanAmount && loanInstallment) {
      const total_installment = Math.round(loanAmount / loanInstallment);
      formRenderProps.onChange("total_installment", {
        value: total_installment,
      });
    } else {
      formRenderProps.onChange("total_installment", { value: 0 });
    }
  }, [loanAmount, loanInstallment]);

  useEffect(() => {
    if (loanAmount && loanInstallment) {
      const total_installment = Math.round(loanAmount / loanInstallment);
      formRenderProps.onChange("total_installment", {
        value: total_installment,
      });
    } else {
      formRenderProps.onChange("total_installment", { value: 0 });
    }
  }, [loanAmount, loanInstallment]);
  return null;
};

const CreateLoanEntry: React.FC = () => {
  const gridRef = useRef<any>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const loan_guid = location.state?.loan_guid;
  const loading = useAppSelector((state) => state.loanentry.loading);
  const EmployeeList = useAppSelector((state) => state.employee.EmployeeList);
  const LoanEntryDetail = useAppSelector(
    (state) => state.loanentry.LoanEntryDetail
  );

  useEffect(() => {
    if (loan_guid) {
      const payload = {
        loan_guid: loan_guid,
      };
      dispatch(getLoanEntryByID(payload));
    }
  }, [loan_guid]);

  useEffect(() => {
    dispatch(getAllActiveEmployee());
    return () => {
      dispatch(clearLoanEntryDetails());
    };
  }, []);

  const handleSubmit = async (values: any) => {
    if (loan_guid) {
      try {
        const editPayload = {
          loan_guid: loan_guid,
          Loan_date: values?.Loan_date
            ? moment(values?.Loan_date).format("YYYY-MM-DD")
            : "",
          employee_id: values?.employee_id ? values?.employee_id : null,
          loan_amout: values?.loan_amout ? values?.loan_amout : null,
          loan_installment: values?.loan_installment
            ? values?.loan_installment
            : null,
          installment_start_month: values?.installment_start_month
            ? values?.installment_start_month
            : null,
          total_installment: values?.total_installment
            ? values?.total_installment
            : null,
        };
        const response = await dispatch(updateLoanEntry(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/loanentry");
          dispatch(closed());
          dispatch(clearLoanEntryDetails());
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
      }
    } else {
      try {
        const insertPayload = {
          Loan_date: values?.Loan_date ? values?.Loan_date : "",
          employee_id: values?.employee_id ? values?.employee_id : null,
          loan_amout: values?.loan_amout ? values?.loan_amout : null,
          loan_installment: values?.loan_installment
            ? values?.loan_installment
            : null,
          installment_start_month: values?.installment_start_month
            ? values?.installment_start_month
            : null,
          total_installment: values?.total_installment
            ? values?.total_installment
            : null,
        };
        const response = await dispatch(createLoanEntry(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/loanentry");
          dispatch(closed());
          dispatch(clearLoanEntryDetails());
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
    navigate("/loanentry");
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    formRenderProps: FormRenderProps
  ) => {
    if (e.key === "Enter" || e.key === "Tab") {
      const value = formRenderProps.valueGetter("employee_code_string");
      const employeeIndex = findIndex(
        EmployeeList,
        (employee: any) =>
          employee?.employee_code_string?.toLowerCase() === value?.toLowerCase()
      );
      if (employeeIndex > -1) {
        const Employee = EmployeeList[employeeIndex];
        formRenderProps.onChange("employee_id", { value: Employee.id });
      } else {
        formRenderProps.onChange("employee_id", { value: null });
      }
    }
  };

  const handleChange = (e: any, formRenderProps: FormRenderProps) => {
    if (e?.value) {
      const employeeIndex = findIndex(EmployeeList, {
        id: e?.value,
      });

      if (employeeIndex > -1) {
        const Employee = EmployeeList[employeeIndex];
        formRenderProps.onChange("employee_code_string", {
          value: Employee.employee_code_string,
        });
      }
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={LoanEntryDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>
                    {loan_guid ? "Update Loan Entry" : "Add Loan Entry"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="Loan_date"
                    label="Loan Date"
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="employee_code_string"
                    label="Employee Code"
                    placeholder="i.e. V055"
                    component={FormTextField}
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                      handleKeyDown(e, formRenderProps)
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="employee_id"
                    label="Employee"
                    placeholder="Select Employee"
                    component={FormSelectionField}
                    options={EmployeeList.map((emp: IEmployee) => ({
                      value: emp.id,
                      label: emp.name,
                    }))}
                    onChange={(e: any) => handleChange(e, formRenderProps)}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>

                <GridLayoutItem>
                  <Field
                    name="loan_amout"
                    label="Loan Amount"
                    placeholder="i.e. 10000"
                    type="number"
                    max={10}
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="loan_installment"
                    label="Loan Installment"
                    placeholder="i.e. 1500"
                    type="number"
                    max={10}
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="installment_start_month"
                    label="Installment Month"
                    placeholder="i.e. Select Month"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    options={MONTHS.map((month: any) => ({
                      value: month.value,
                      label: month.label,
                    }))}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="total_installment"
                    label="Total Installment"
                    placeholder="i.e. 67"
                    type="number"
                    max={10}
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={3}
                  style={{
                    textAlign: "end",
                    marginTop: "20px",
                    width: "100%",
                  }}
                >
                  <ButtonWithLoading
                    label={!loan_guid ? "Save" : "Update"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                  />
                  <Button
                    type="button"
                    fillMode={"outline"}
                    themeColor={"primary"}
                    style={{ marginLeft: 4 }}
                    onClick={handleFormClose}
                  >
                    Cancel
                  </Button>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <AreaChangeWatcher formRenderProps={formRenderProps} />
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateLoanEntry;
