import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { getAllActiveStates } from "../state/services/state.services";
import { closed } from "../../components/muidrawer/muiDrawerSlice";
import { Typography } from "@progress/kendo-react-common";
import { RxCross1 } from "react-icons/rx";
import { clearExpenseAllocationDetails } from "./expenseAllocationSlice";
import {
  getAllActiveExpenseAllocations,
  getAllExpenseAllocations,
  getExpenseAllocationByID,
  InsertExpenseAllocation,
  updateExpenseAllocation,
} from "./services/expenseAllocation.services";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import { IExpenseType } from "../expense/expenseTypeModel";
import { getAllFixedExpenseTypes } from "../expense/services/expenseType.services";
import { ISalesRepresentative } from "../salesrepresentativeassign/salesRepresentativeModel";
import { getAllSalesRepresentative } from "../salesrepresentativeassign/services/salesRepresentative.services";

const CreateExpenseAllocation: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const ExpenseAllocationDetail = useAppSelector(
    (state) => state.expenseAllocation.ExpenseAllocationDetail
  );
  const expense_allocation_guid = useAppSelector(
    (state) => state.muidrawer.data
  );
  const formLoading = useAppSelector(
    (state) => state.expenseAllocation.formLoading
  );
  const isIndirect = useAppSelector((state) => state.muidrawer.isIndirect);

  const FixedExpenseTypeList = useAppSelector(
    (state) => state.expenseType.FixedExpenseTypeList
  );
  const SalesRepresentativeList = useAppSelector(
    (state) => state.salesrepresentative.SalesRepresentativeList
  );

  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);

  useEffect(() => {
    dispatch(getAllSalesRepresentative());
    dispatch(getAllFixedExpenseTypes());
    dispatch(getAllActiveStates());
    
    return () => {
      dispatch(clearExpenseAllocationDetails());
      dispatch(closed());
    };
  }, []);

  useEffect(() => {
    if (expense_allocation_guid) {
      dispatch(getExpenseAllocationByID(expense_allocation_guid));
    }
  }, [expense_allocation_guid]);

  const handleSubmit = async (values: any) => {
    const defaultpayload = {
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };

    try {
      if (!expense_allocation_guid) {
        const payload = {
          sr_id: values.sr_id ? +values.sr_id : null,
          expense_type_id: values.expense_type_id
            ? +values.expense_type_id
            : null,
          expense_amount: values.expense_amount ? +values.expense_amount : null,
          isactive: values.isactive === 1,
        };
        const response = await dispatch(InsertExpenseAllocation(payload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearExpenseAllocationDetails());
          isIndirect
            ? dispatch(getAllActiveExpenseAllocations())
            : dispatch(getAllExpenseAllocations(defaultpayload));
        }
      } else {
        const payload = {
          expense_allocation_guid: expense_allocation_guid,
          sr_id: values.sr_id ? +values.sr_id : null,
          expense_type_id: values.expense_type_id
            ? +values.expense_type_id
            : null,
          expense_amount: values.expense_amount ? +values.expense_amount : null,
          isactive: values.isactive === 1,
        };
        const response = await dispatch(updateExpenseAllocation(payload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearExpenseAllocationDetails());
          isIndirect
            ? dispatch(getAllActiveExpenseAllocations())
            : dispatch(getAllExpenseAllocations(defaultpayload));
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={ExpenseAllocationDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "17px 0 8px",
              }}
            >
              <Typography.h4 className="m-0">
                {expense_allocation_guid
                  ? "Update Expense Allocation"
                  : "Add Expense Allocation"}
              </Typography.h4>
              <Button
                type="button"
                fillMode={"flat"}
                themeColor={"dark"}
                style={{ marginRight: 4 }}
                onClick={() => dispatch(closed())}
              >
                <RxCross1 />
              </Button>
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="sr_id"
                label={"Sales Representative"}
                placeholder="Select Expense"
                component={FormSelectionField}
                validator={requiredValidator}
                astrike={true}
                options={SalesRepresentativeList?.map(
                  (expensetype: ISalesRepresentative) => {
                    return {
                      value: expensetype?.id,
                      label: `${expensetype?.first_name} ${expensetype?.middle_name} ${expensetype?.last_name}`,
                    };
                  }
                )}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id={"expense_type_id"}
                name={"expense_type_id"}
                label={"Expense Name"}
                placeholder="Select Expense"
                component={FormSelectionField}
                validator={requiredValidator}
                astrike={true}
                options={FixedExpenseTypeList?.map(
                  (expensetype: IExpenseType) => {
                    return {
                      value: expensetype?.id,
                      label: expensetype?.expense_name,
                    };
                  }
                )}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="expense_amount"
                label="Expense Amount"
                placeholder="0"
                component={FormNumericTextField}
                validator={requiredValidator}
                astrike={true}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={3}
              style={{
                textAlign: "start",
                marginTop: "20px",
                width: "100%",
              }}
            >
              <ButtonWithLoading
                label={expense_allocation_guid ? "Update" : "Save"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />
              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginLeft: 4 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
            </GridLayoutItem>
          </GridLayout>
        </FormElement>
      )}
    />
  );
};

export default CreateExpenseAllocation;
