import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ActiveInactiveExpenseType,
  deleteExpenseType,
  getAllActiveExpenseTypes,
  getAllExpenseTypes,
  getAllFixedExpenseTypes,
  getExpenseTypeByID,
  InsertExpenseType,
  updateExpenseType,
} from "./services/expenseType.services";
import { IExpenseType, IExpenseTypeInitialState } from "./expenseTypeModel";

const initialState: IExpenseTypeInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  ExpenseTypeList: [],
  FixedExpenseTypeList: [],
  ExpenseTypeID: null,
  ExpenseTypeDetail: {
    expense_name: "",
    expense_type: "",
    isactive: 1,
  },
};

const expenseTypeSlice = createSlice({
  name: "expenseType",
  initialState,
  reducers: {
    clearExpenseTypeDetails: (state) => {
      state.ExpenseTypeDetail = initialState.ExpenseTypeDetail;
    },
    setExpenseTypeID: (state, action) => {
      state.ExpenseTypeID = action.payload;
    },
    clearExpenseTypeID: (state) => {
      state.ExpenseTypeID = initialState.ExpenseTypeID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllExpenseTypes.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllExpenseTypes.fulfilled,
      (state, action: PayloadAction<IExpenseType[]>) => {
        state.loading = false;
        state.ExpenseTypeList = action.payload || [];
      }
    );
    builder.addCase(getAllExpenseTypes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ExpenseTypeList = [];
    });

    builder.addCase(InsertExpenseType.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(InsertExpenseType.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(InsertExpenseType.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateExpenseType.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateExpenseType.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateExpenseType.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteExpenseType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteExpenseType.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteExpenseType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getExpenseTypeByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getExpenseTypeByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.ExpenseTypeDetail = action.payload;
    });
    builder.addCase(getExpenseTypeByID.rejected, (state, action) => {
      state.formLoading = false;
      state.ExpenseTypeDetail = initialState.ExpenseTypeDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getAllActiveExpenseTypes.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveExpenseTypes.fulfilled,
      (state, action: PayloadAction<IExpenseType[]>) => {
        state.loading = false;
        state.ExpenseTypeList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveExpenseTypes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ExpenseTypeList = [];
    });

    builder.addCase(ActiveInactiveExpenseType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveExpenseType.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveExpenseType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getAllFixedExpenseTypes.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllFixedExpenseTypes.fulfilled,
      (state, action: PayloadAction<IExpenseType[]>) => {
        state.loading = false;
        state.FixedExpenseTypeList = action.payload || [];
      }
    );
    builder.addCase(getAllFixedExpenseTypes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.FixedExpenseTypeList = [];
    });
  },
});

export const { clearExpenseTypeDetails, setExpenseTypeID, clearExpenseTypeID } =
  expenseTypeSlice.actions;
export default expenseTypeSlice.reducer;
