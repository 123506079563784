import React, { useEffect, useRef } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import { Typography } from "@progress/kendo-react-common";
import ShadowCard from "../../../components/common/ShadowCard";
import {
  clearSalaryRegisterDetails,
  clearSalaryRegisterList,
  setSalaryRegisterReport,
} from "../reportsSlice";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCellProps,
  GridHeaderCellProps,
} from "@progress/kendo-react-grid";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
// import { setCurrentPage, setPageLimit } from "../../pagination/paginationSlice";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import AlertBox from "../../../components/common/AlertBox";
import { LoadingPanel } from "../../../components/layout/Loading";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import { Button } from "@progress/kendo-react-buttons";
import {
  checkAcessRights,
  getYearsArray,
  // useResponsiveJSX,
} from "../../../_helper/helper";
import {
  EDIT_ACCESS,
  EXCEL_EXPORT_ACCESS,
  MONTHS,
  PRINT_ACCESS,
} from "../../../_contstants/common";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { getSalaryRegisterReport } from "../services/reports.services";
import { ISalaryRegisterDetails } from "../reportsModel";
import { getAllEmployeeLocation } from "../../employee/services/employee.services";
import IconButton from "../../../components/common/IconButton";
import { FiEdit } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const DateChangeWatcher = ({ formRenderProps }: FormChangeWatcherProps) => {
  useEffect(() => {
    formRenderProps.onChange("userr_id", {
      value: 1,
    });
  }, []);
  return null;
};

const SalaryRegisterReportForm: React.FC = () => {
  const gridRef = React.useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.reports.loading);
  const EmployeeLocationList = useAppSelector(
    (state) => state.employee.EmployeeLocationList
  );
  const SalaryRegisterList = useAppSelector(
    (state) => state.reports.SalaryRegisterList
  );
  const SalaryRegisterDetails = useAppSelector(
    (state) => state.reports.SalaryRegisterDetails
  );

  useEffect(() => {
    dispatch(getAllEmployeeLocation());
    return () => {
      dispatch(clearSalaryRegisterDetails());
      dispatch(clearSalaryRegisterList());
    };
  }, []);

  const handleSubmit = (values: any) => {
    const payload = {
      location: values.location,
      payout_month_year: `${String(values.payout_month).padStart(2, "0")}-${
        values.payout_year
      }`,
    };
    dispatch(
      setSalaryRegisterReport({
        location: values.location,
        payout_year: values.payout_year,
        payout_month: values.payout_month,
        isSalaryRegisterRef: true,
      })
    );
    dispatch(getSalaryRegisterReport(payload));
  };
  return (
    <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
      <GridLayoutItem>
        <ShadowCard>
          <Form
            initialValues={SalaryRegisterDetails}
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 10 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[
                    { width: "33.33%" },
                    { width: "33.33%" },
                    { width: "33.33%" },
                  ]}
                >
                  <GridLayoutItem colSpan={3}>
                    <Typography.h4
                      style={{ marginBottom: 0, marginLeft: "3px" }}
                    >
                      Salary Register Report
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem className="d-flex  gap-2">
                    <Field
                      name="payout_month"
                      label="Payout Month"
                      placeholder="Payout Month"
                      component={FormSelectionField}
                      wrapperClassName="w-100"
                      options={MONTHS.map((month: any) => ({
                        value: month.value,
                        label: month.label,
                      }))}
                      validator={requiredValidator}
                      astrike={true}
                    />
                    <Field
                      name="payout_year"
                      label="Payout Year"
                      placeholder="Payout Year"
                      wrapperClassName="w-100"
                      component={FormSelectionField}
                      options={getYearsArray(2000, new Date().getFullYear() + 1)
                        ?.reverse()
                        ?.map((year: any) => {
                          return {
                            value: year?.value,
                            label: year?.label,
                          };
                        })}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="location"
                      label="Location"
                      placeholder="Select Location"
                      component={FormSelectionField}
                      options={EmployeeLocationList.map((loc: any) => ({
                        value: loc.location,
                        label: loc.location,
                      }))}
                    />
                  </GridLayoutItem>
                  <DateChangeWatcher formRenderProps={formRenderProps} />
                  <GridLayoutItem>
                    <div style={{ marginTop: "35px" }}>
                      <ButtonWithLoading
                        label={"View"}
                        type="submit"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </div>
                  </GridLayoutItem>
                </GridLayout>
              </FormElement>
            )}
          />
        </ShadowCard>
        {loading ? (
          <LoadingPanel gridRef={gridRef} />
        ) : (
          SalaryRegisterList &&
          SalaryRegisterList.length > 0 && (
            <ShadowCard style={{ marginTop: 10 }}>
              <SalaryRegisterViewGridComponent />
            </ShadowCard>
          )
        )}
      </GridLayoutItem>
    </GridLayout>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 50 };

const SalaryRegisterViewGridComponent: React.FC = () => {
  const navigate = useNavigate();
  const SalaryRegisterList = useAppSelector(
    (state) => state.reports.SalaryRegisterList
  );
  const SalaryRegisterDetails = useAppSelector(
    (state) => state.reports.SalaryRegisterDetails
  );
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isPrintAccess = checkAcessRights(location.pathname, PRINT_ACCESS);
  const isExcelAccess = checkAcessRights(
    location.pathname,
    EXCEL_EXPORT_ACCESS
  );

  const loading = useAppSelector((state) => state.retailer.loading);
  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();
  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? SalaryRegisterList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 0 ? "locked-header" : ""}`}
      // className={`table-header `}
    >
      {props.children}
    </HeaderThElement>
  );
  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEdit = (item: any) => {
      navigate("/salarygeneration", {
        state: {
          EmployeeID: item?.employee_id,
          PayoutYear: `${item?.payout_year}`,
          PayoutMonth: `${item?.payout_month}`,
          IsEditMode: true,
        },
      });
    };
    return (
      <td
        className={`${props.className} action-td `}
        style={{
          ...props.style,
        }}
      >
        {props.dataIndex + 1}
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            onClick={() => handleEdit(props.dataItem)}
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );
  const gridRef = useRef<any>(null);
  if (loading) return <LoadingPanel gridRef={gridRef} />;

  const handlePrint = (props: ISalaryRegisterDetails, type: string) => {
    const state = {
      location: props.location,
      payout_month: props.payout_month,
      payout_year: props.payout_year,
    };

    if (type === "SalarySlip") {
      sessionStorage.setItem("salaryslipsState", JSON.stringify(state));
      const reportTab = window.open("/salaryslips", "_blank");
      const interval = setInterval(() => {
        if (reportTab?.closed) {
          sessionStorage.removeItem("salaryslipsState");
          clearInterval(interval);
        }
      }, 1000);
    } else if (type === "SalaryRegisterReport") {
      sessionStorage.setItem("salaryreportState", JSON.stringify(state));
      const reportTab = window.open("/salaryregisterreport", "_blank");
      const interval = setInterval(() => {
        if (reportTab?.closed) {
          sessionStorage.removeItem("salaryreportState");
          clearInterval(interval);
        }
      }, 1000);
    } else if (type === "SalaryRegisterExcel") {
      sessionStorage.setItem("salaryRegisterExcelState", JSON.stringify(state));
      const reportTab = window.open("/salaryregisterexcel", "_blank");
      const interval = setInterval(() => {
        if (reportTab?.closed) {
          sessionStorage.removeItem("salaryRegisterExcelState");
          clearInterval(interval);
        }
      }, 1000);
    }
  };
  return (
    <>
      {SalaryRegisterList && SalaryRegisterList.length ? (
        <GridLayout
          //   style={{ marginRight: 30 }}
          gap={{ rows: 10, cols: 10 }}
          cols={[{ width: "100%" }]}
        >
          <GridLayoutItem className="d-flex justify-content-between align-items-center">
            <Typography.h4 className="m-0">
              Salary Register Report
            </Typography.h4>
            <div>
              {isPrintAccess && (
                <Button
                  style={{ marginRight: 5 }}
                  type="button"
                  fillMode={"solid"}
                  themeColor={"primary"}
                  onClick={() =>
                    handlePrint(SalaryRegisterDetails, "SalarySlip")
                  }
                >
                  Print Salary Slip
                </Button>
              )}
              {isPrintAccess && (
                <Button
                  style={{ marginRight: 5 }}
                  type="button"
                  fillMode={"solid"}
                  themeColor={"primary"}
                  onClick={() =>
                    handlePrint(SalaryRegisterDetails, "SalaryRegisterReport")
                  }
                >
                  Print Register Report
                </Button>
              )}
              {isExcelAccess && (
                <Button
                  style={{ marginRight: 5 }}
                  type="button"
                  fillMode={"solid"}
                  themeColor={"primary"}
                  onClick={() =>
                    handlePrint(SalaryRegisterDetails, "SalaryRegisterExcel")
                  }
                >
                  Excel view
                </Button>
              )}
            </div>
          </GridLayoutItem>
          <GridLayoutItem>
            <KendoGrid
              style={{ maxHeight: "calc(100vh - 152px)" }}
              className="responsive-table salary-register-report-form-table"
              filterable={true}
              filter={filter}
              data={
                filter
                  ? filterBy(SalaryRegisterList, filter).slice(
                      page.skip,
                      page.take + page.skip
                    )
                  : SalaryRegisterList.slice(page.skip, page.take + page.skip)
              }
              onFilterChange={handleFilterChange}
              skip={page.skip}
              take={page.take}
              total={SalaryRegisterList.length}
              pageable={{
                buttonCount: 5,
                pageSizes: [10, 25, 50, "All"],
                pageSizeValue: pageSizeValue,
                type: "input",
              }}
              onPageChange={pageChange}
              cells={{
                headerCell: HeaderCustomCell,
                data: MyDataCustomCell,
              }}
            >
              <Column
                field="Date"
                title="Sr. No."
                width={90}
                locked
                cell={MyEditCommandCell}
                headerCell={(props: GridHeaderCellProps) => (
                  <th
                    style={{
                      whiteSpace: "normal",
                      textAlign: "center",
                      padding: 0,
                    }}
                    className={`salaryview-column-header`}
                    {...props}
                  >
                    <p>Sr. No</p>
                  </th>
                )}
              />

              <Column
                field="employee_id"
                title="Employee Details"
                width={300}
                headerCell={() => (
                  <div
                    style={{
                      whiteSpace: "normal",
                      textAlign: "start",
                      padding: 0,
                      lineHeight: "14px",
                    }}
                  >
                    <p>Employee Name</p>
                    <p>Employee ID</p>
                    <p>Department</p>
                    <p>Designation</p>
                    <p>Location</p>
                  </div>
                )}
                cell={(props: GridCellProps) => {
                  return (
                    <td
                      style={{
                        textAlign: "start",
                        lineHeight: "14px",
                      }}
                    >
                      <p>
                        <b style={{ textTransform: "uppercase" }}>
                          {props.dataItem?.employee_name
                            ? props.dataItem?.employee_name
                            : "-"}
                        </b>
                      </p>
                      <p>
                        {props.dataItem?.employee_code_string
                          ? props.dataItem?.employee_code_string
                          : "-"}
                      </p>
                      <p>
                        {props.dataItem?.department_name
                          ? props.dataItem?.department_name
                          : "-"}
                      </p>
                      <p>
                        {props.dataItem?.designation_name
                          ? props.dataItem?.designation_name
                          : "-"}
                      </p>
                      <p>
                        {props.dataItem?.location
                          ? props.dataItem?.location
                          : "-"}
                      </p>
                    </td>
                  );
                }}
              />

              <Column
                field="employee_id"
                title="Employee Details"
                width={300}
                headerCell={() => (
                  <div
                    style={{
                      whiteSpace: "normal",
                      textAlign: "start",
                      padding: 0,
                      lineHeight: "14px",
                    }}
                  >
                    <p>PF No</p>
                    <p>UAN No</p>
                    <p>ESI No</p>
                    <p>BANK Name</p>
                    <p>A/c No</p>
                  </div>
                )}
                cell={(props: GridCellProps) => {
                  return (
                    <td
                      style={{
                        textAlign: "start",
                        lineHeight: "14px",
                      }}
                    >
                      <p style={{ textTransform: "uppercase" }}>
                        {props.dataItem?.pf_no ? props.dataItem?.pf_no : "-"}
                      </p>
                      <p>
                        {props.dataItem?.uan_no ? props.dataItem?.uan_no : "-"}
                      </p>
                      <p>
                        {props.dataItem?.est_no ? props.dataItem?.est_no : "-"}
                      </p>
                      <p style={{ textTransform: "uppercase" }}>
                        {props.dataItem?.bank_name
                          ? props.dataItem?.bank_name
                          : "-"}
                      </p>
                      <p>
                        {props.dataItem?.account_no
                          ? props.dataItem?.account_no
                          : "-"}
                      </p>
                    </td>
                  );
                }}
              />
              <Column
                field="date_of_joining"
                title="Date Of Joining"
                width={150}
              />
              <Column
                field="employee_id"
                title="Employee Information"
                width={150}
                headerCell={(props) => (
                  <div
                    {...props}
                    style={{
                      whiteSpace: "normal",
                      textAlign: "start",
                      padding: 0,
                    }}
                    className="salaryview-column-header"
                  >
                    <p style={{ margin: 0 }}>Working Details</p>
                  </div>
                )}
                cell={(props) => (
                  <td
                    className="salaryview-workingDetails"
                    style={{ lineHeight: "16px" }}
                  >
                    <p>
                      <span> WD</span>
                      <span>
                        {Number(props.dataItem?.working_days || 0)?.toFixed(2)}
                      </span>
                    </p>
                    <p>
                      <span> WO</span>
                      <span>
                        {Number(props.dataItem?.weekly_off || 0)?.toFixed(2)}
                      </span>
                    </p>
                    <p>
                      <span> PH</span>
                      <span>{Number(props.dataItem?.ph || 0)?.toFixed(2)}</span>
                    </p>
                    <p>
                      <span> PD</span>
                      <span>
                        {Number(props.dataItem?.present_days || 0)?.toFixed(2)}
                      </span>
                    </p>
                    <p>
                      <span> PL</span>
                      <span>{Number(props.dataItem?.pl || 0)?.toFixed(2)}</span>
                    </p>
                    <p>
                      <span> CL</span>
                      <span>
                        {Number(props.dataItem?.casual_leave || 0)?.toFixed(2)}
                      </span>
                    </p>
                    <p>
                      <span> SL</span>
                      <span>{Number(props.dataItem?.sl || 0)?.toFixed(2)}</span>
                    </p>
                    <p>
                      <span> AB</span>
                      <span>
                        {(
                          Number(props.dataItem?.working_days || 0) -
                          (Number(props.dataItem?.present_days || 0) +
                            Number(props.dataItem?.casual_leave || 0))
                        )?.toFixed(2)}
                      </span>
                    </p>
                    <p style={{ borderTop: "0.5px solid #757575" }}>
                      <span> TOT</span>
                      <span>
                        {(
                          Number(props.dataItem?.casual_leave || 0) +
                            Number(props.dataItem?.weekly_off || 0) +
                            Number(props.dataItem?.present_days || 0) || 0
                        )?.toFixed(2)}
                      </span>
                    </p>
                  </td>
                )}
              />
              <Column
                field="employee_id"
                title="Rate Earnings"
                width={400}
                headerCell={(props) => (
                  <div
                    {...props}
                    style={{
                      whiteSpace: "normal",
                      textAlign: "start",
                      padding: 0,
                    }}
                    className="salaryview-column-header "
                  >
                    <table
                      style={{ width: "100%", lineHeight: "14px" }}
                      className="salary-report-header-cell-table"
                    >
                      <tr>
                        <td>Rate</td>
                        <td>Earnings</td>
                      </tr>
                      <tr>
                        <td>
                          <p>Basic</p>
                          <p>HRA</p>
                          <p>WASH</p>
                          <p>PROD.INS.ALL</p>
                          <p>ADV. BONUS</p>
                          <p>ADV. LEAVE</p>
                          <p>ADV. STD. BE</p>
                          <p>FOOD</p>
                          <p>DA</p>
                          <p>OTHER EARNING</p>
                        </td>
                        <td>
                          <p>Basic</p>
                          <p>HRA</p>
                          <p>WASH</p>
                          <p>PROD.INS.ALL</p>
                          <p>ADV. BONUS</p>
                          <p>ADV. LEAVE</p>
                          <p>ADV. STD. BE</p>
                          <p>FOOD</p>
                          <p>DA</p>
                          <p>OTHER EARNING</p>
                        </td>
                      </tr>
                    </table>
                  </div>
                )}
                cell={(props: GridCellProps) => (
                  <td
                    style={{
                      whiteSpace: "normal",
                      textAlign: "right",
                      padding: 0,
                    }}
                    className="salaryview-column-cell"
                  >
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td>
                          <p>{props?.dataItem?.actual_basic}</p>
                          <p>{props?.dataItem?.actual_hra}</p>
                          <p>{props?.dataItem?.actual_wash}</p>
                          <p>{props?.dataItem?.actual_prod_ins_all}</p>
                          <p>{props?.dataItem?.actual_adv_bonus}</p>
                          <p>{props?.dataItem?.actual_adv_leave}</p>
                          <p>{props?.dataItem?.actual_adv_std_be}</p>
                          <p>{props?.dataItem?.actual_food}</p>
                          <p>{props?.dataItem?.actual_da}</p>
                          <p>{props?.dataItem?.actual_other_earning}</p>
                        </td>
                        <td>
                          <p>{props?.dataItem?.basic}</p>
                          <p>{props?.dataItem?.hra}</p>
                          <p>{props?.dataItem?.wash}</p>
                          <p>{props?.dataItem?.prod_ins_all}</p>
                          <p>{props?.dataItem?.adv_bonus}</p>
                          <p>{props?.dataItem?.adv_leave}</p>
                          <p>{props?.dataItem?.adv_std_be}</p>
                          <p>{props?.dataItem?.food}</p>
                          <p>{props?.dataItem?.da}</p>
                          <p>{props?.dataItem?.other_earning}</p>
                        </td>
                      </tr>
                    </table>
                  </td>
                )}
              />
              <Column
                field="total_days"
                title="Gross Salary"
                width={120}
                headerCell={(props) => (
                  <div
                    {...props}
                    style={{
                      whiteSpace: "normal",
                      textAlign: "start",
                      padding: 0,
                    }}
                    className="salaryview-column-header"
                  >
                    <p>Gross Salary</p>
                    <p>PF Wages</p>
                    <p>ESI Wages</p>
                  </div>
                )}
                cell={(props: GridCellProps) => {
                  // const ActualTotalIncome =
                  //   props?.dataItem?.actual_basic +
                  //   props?.dataItem?.actual_hra +
                  //   props?.dataItem?.actual_wash +
                  //   props?.dataItem?.actual_prod_ins_all +
                  //   props?.dataItem?.actual_adv_bonus +
                  //   props?.dataItem?.actual_adv_leave +
                  //   props?.dataItem?.actual_adv_std_be +
                  //   props?.dataItem?.actual_food +
                  //   props?.dataItem?.actual_da +
                  //   props?.dataItem?.actual_other_earning;
                  return (
                    <td
                      style={{
                        textAlign: "right",
                      }}
                    >
                      <p>
                        <b>{props.dataItem?.total_income}</b>
                      </p>
                      <p>
                        {props.dataItem?.pf
                          ? props.dataItem?.celling_amount || 0
                          : 0}
                      </p>
                      <p>
                        {props.dataItem?.esi > 0
                          ? props.dataItem?.total_income
                          : 0}
                      </p>
                    </td>
                  );
                }}
              />
              <Column
                field="total_days"
                title="Deduction"
                width={400}
                headerCell={(props) => (
                  <div
                    {...props}
                    style={{
                      whiteSpace: "normal",
                      textAlign: "start",
                      padding: 0,
                    }}
                    className="salaryview-column-header"
                  >
                    <table
                      style={{ width: "100%", lineHeight: "14px" }}
                      className="salary-report-header-cell-table"
                    >
                      <tr>
                        <td
                          style={{ border: "none", textAlign: "center" }}
                          colSpan={2}
                        >
                          Deduction
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "center", width: "50%" }}>
                          <p>P.F</p>
                          <p>ESI</p>
                          <p>IT</p>
                          <p>P.T.</p>
                          <p>Loan</p>
                          <p>Advance</p>
                          <p>LWF</p>
                          <p>TDS</p>
                          <p>Other Deduction</p>
                        </td>
                        <td>
                          <p style={{ textAlign: "center" }}>
                            Gross <br /> Deduction
                          </p>
                        </td>
                      </tr>
                    </table>
                  </div>
                )}
                cell={(props: GridCellProps) => {
                  return (
                    <td
                      style={{
                        textAlign: "right",
                        padding: "0 10px 10px 10px",
                        verticalAlign: "top",
                      }}
                    >
                      <table
                        className="salary-deduction-cell"
                        style={{ width: "100%" }}
                      >
                        <tr>
                          <td style={{ width: "50%" }}>
                            <p>{props.dataItem?.pf}</p>
                            <p>{props.dataItem?.esi}</p>
                            <p>{props.dataItem?.it}</p>
                            <p>{props.dataItem?.pt}</p>
                            <p>{props.dataItem?.loan}</p>
                            <p>{props.dataItem?.advance}</p>
                            <p>{props.dataItem?.lwf}</p>
                            <p>{props.dataItem?.tds}</p>
                            <p>{props.dataItem?.other}</p>
                          </td>
                          <td>
                            <p style={{ textAlign: "right" }}>
                              <b>{props.dataItem?.total_deduction}</b>
                            </p>
                          </td>
                        </tr>
                      </table>
                    </td>
                  );
                }}
              />
              <Column
                field="payable_amount"
                title="Total Payable Amount"
                width={150}
                headerCell={(props) => (
                  <th
                    {...props}
                    style={{
                      whiteSpace: "normal",
                      textAlign: "center",
                      padding: 0,
                    }}
                    className="salaryview-column-header"
                  >
                    <p>Net Salary Payable in Rs.</p>
                  </th>
                )}
                cell={(props: GridCellProps) => {
                  return (
                    <td
                      style={{
                        whiteSpace: "normal",
                        textAlign: "center",
                        padding: 0,
                      }}
                      className="salaryview-column-header"
                    >
                      <p>
                        <b>{props.dataItem.payable_amount}</b>
                      </p>
                    </td>
                  );
                }}
              />
            </KendoGrid>
          </GridLayoutItem>
        </GridLayout>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

export default SalaryRegisterReportForm;
