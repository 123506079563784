import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ShadowCard from "../../components/common/ShadowCard";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import FormDatePicker from "../../components/formFields/FormDateField";
import moment from "moment";

import { getAllActiveCities } from "../city/services/city.services";
import {
  getSRExpenseByID,
  updateSRExpense,
  createSRExpense,
} from "./services/expenseDetails.services";
import { clearExpenseDetail } from "./expenseDetailsSlice";
import { ICity } from "../city/cityModel";
import { IModeOfTransport } from "../modeoftransport/modeOfTransportModel";
import FormMultiSelectionFiled from "../../components/formFields/FormMultiSelectionFiled";
import FormTextArea from "../../components/formFields/FormTextArea";
import { getAllActiveModeOfTransport } from "../modeoftransport/services/modeOfTransport.services";
// import { formatIndianNumber } from "../../_helper/helper";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { Button } from "@progress/kendo-react-buttons";
import { getAllActiveExpenseTypes } from "../expense/services/expenseType.services";
import ExpenseDetailsArray from "./ExpenseDetailsArray";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import { formatIndianNumber, useResponsiveJSX } from "../../_helper/helper";
import { IExpenseAllocation } from "../expenseallocation/expenseAllocationModel";
import { getAllSRFixedExpenseAllocated } from "../expenseallocation/services/expenseAllocation.services";
import { Label } from "@progress/kendo-react-labels";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const ExpenseChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const ExpenseTypeList = useAppSelector(
    (state) => state.expenseType.ExpenseTypeList
  );
  const FixedExpenseAllocationList = useAppSelector(
    (state) => state.expenseAllocation.FixedExpenseAllocationList
  );
  const expense_details = formRenderProps.valueGetter("expense_details");
  const fixed_expense = formRenderProps.valueGetter("fixed_expense");

  useEffect(() => {
    if (fixed_expense) {
      const expenceAmount = FixedExpenseAllocationList?.filter(
        (item: any) => item?.expense_type_id === fixed_expense
      )[0]?.expense_amount;

      expense_details?.map((item: any, index: number) => {
        if (item.expense_type_id === fixed_expense) {
          formRenderProps.onChange(`expense_details.${index}.amount`, {
            value: expenceAmount,
          });
        } else {
          if (item.expense_type === "Fixed") {
            formRenderProps.onChange(`expense_details.${index}.amount`, {
              value: null,
            });
          }
        }
      });
    }
  }, [fixed_expense]);
  useEffect(() => {
    if (ExpenseTypeList && ExpenseTypeList.length > 0) {
      formRenderProps.onChange("expense_details", {
        value: JSON.parse(JSON.stringify(ExpenseTypeList))
          ?.sort((a: any) => (a.expense_type === "Fixed" ? -1 : 1))
          ?.map((item: any) => ({
            ...item,
            expense_type_id: item.id,
          })),
      });
    }
  }, [ExpenseTypeList]);

  const kms_travelled = +formRenderProps.valueGetter("kms_travelled") || 0;
  const fare_for_transport =
    +formRenderProps.valueGetter("fare_for_transport") || 0;
  const total_amount = +formRenderProps.valueGetter("total_amount") || 0;

  useEffect(() => {
    const totalExpense = expense_details?.reduce(
      (total: number, item: any) => total + (item?.amount || 0),
      0
    );
    formRenderProps.onChange("total_amount", { value: totalExpense });
  }, [expense_details?.map((item: any) => item?.amount).join("-")]);

  useEffect(() => {
    formRenderProps.onChange("net_amount", {
      value: fare_for_transport + kms_travelled + total_amount,
    });
  }, [total_amount, fare_for_transport, kms_travelled]);
  return null;
};

const CreateExpenseDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const gridRef = useRef<any>(null);
  const expense_details_guid = location.state?.expense_details_guid;
  const loading = useAppSelector((state) => state.expensedetails.loading);
  const formLoading = useAppSelector(
    (state) => state.expensedetails.formLoading
  );
  const ExpenseDetail = useAppSelector(
    (state) => state.expensedetails.ExpenseDetail
  );

  const FixedExpenseAllocationList = useAppSelector(
    (state) => state.expenseAllocation.FixedExpenseAllocationList
  );
  const CityList = useAppSelector((state) => state.city.CityList);
  const ModeOfTransportList = useAppSelector(
    (state) => state.modeOfTransport.ModeOfTransportList
  );

  const [formKey, setFormKey] = React.useState(1);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [ExpenseDetail]);

  useEffect(() => {
    if (expense_details_guid) {
      dispatch(getSRExpenseByID(expense_details_guid));
    }
  }, [expense_details_guid]);

  useEffect(() => {
    dispatch(getAllSRFixedExpenseAllocated(localStorage.getItem("UserID")));
    // dispatch(getAllSRFixedExpenseAllocated("88"));
    dispatch(getAllActiveExpenseTypes());
    dispatch(getAllActiveCities());
    dispatch(getAllActiveModeOfTransport());

    return () => {
      dispatch(clearExpenseDetail());
    };
  }, []);

  const breakPoint = useResponsiveJSX([530, 930]);
  // console.log("breakPoint", breakPoint);
  const dynamicColSpan = [4, 2, 0][breakPoint] ?? 0;

  const handleSubmit = async (values: any) => {
    const formdata = {
      sr_id: values?.sr_id ? +values?.sr_id : localStorage.getItem("UserID"),
      expense_date: values?.expense_date
        ? values?.expense_date
          ? moment(values?.expense_date).format("YYYY-MM-DD")
          : ""
        : "",
      from_city: values?.from_city ? +values?.from_city : null,
      to_city: values?.to_city ? +values?.to_city : null,
      mode_of_transport: values?.mode_of_transport
        ? +values?.mode_of_transport
        : null,
      city_town_visited: values?.city_town_visited
        ? values?.city_town_visited.join(",")
        : "",
      sales_amount: values?.sales_amount ? values?.sales_amount : null,
      remarks: values?.remarks ? values?.remarks : "",
      fixed_expense: values?.fixed_expense ? +values?.fixed_expense : null,
      expense_details: values?.expense_details
        ? values?.expense_details?.map((expense: any) => {
            return {
              expense_type_id: expense?.expense_type_id,
              expense_name: expense?.expense_name,
              expense_type: expense?.expense_type,
              amount: expense?.amount ? expense?.amount : 0,
            };
          })
        : [],
      total_amount: values?.total_amount ? +values?.total_amount : null,
      kms_travelled: values?.kms_travelled ? +values?.kms_travelled : null,
      fare_for_transport: +values?.fare_for_transport
        ? values?.fare_for_transport
        : null,
      net_amount: values?.net_amount ? +values?.net_amount : null,
    };

    // console.log("formdata", formdata);
    // return false;
    if (expense_details_guid) {
      try {
        const updatePayload: any = {
          id: values?.id ? +values?.id : null,
          expense_details_guid: expense_details_guid,
          ...formdata,
        };

        const response = await dispatch(updateSRExpense(updatePayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/expense");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      try {
        const response = await dispatch(createSRExpense(formdata));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/expense");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  const handleFormClose = () => {
    navigate("/expense");
  };

  // if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}

      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={ExpenseDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <p className="fs-4" style={{ margin: 0, fontWeight: "bold" }}>
                    {expense_details_guid
                      ? "Update Expense Details"
                      : "Add Expense Details"}
                  </p>
                  {/* <Typography.h4>
                    {expense_details_guid
                      ? "Update Expense Details"
                      : "Add Expense Details"}
                  </Typography.h4> */}
                </GridLayoutItem>
                <GridLayoutItem colSpan={dynamicColSpan}>
                  <Field
                    name="expense_date"
                    label="Expense Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={dynamicColSpan}>
                  <Field
                    name="from_city"
                    label="From City"
                    placeholder="Select From City "
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    options={CityList?.map((city: ICity) => {
                      return {
                        value: city?.id,
                        label: city?.city_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={dynamicColSpan}>
                  <Field
                    id="expensetocity"
                    name="to_city"
                    label="To City"
                    placeholder="Select To City "
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    options={CityList?.map((city: ICity) => {
                      return {
                        value: city?.id,
                        label: city?.city_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={dynamicColSpan}>
                  <Field
                    name="mode_of_transport"
                    label="Mode Of Transport"
                    placeholder="Select Mode of Transport"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    options={ModeOfTransportList?.map(
                      (trans: IModeOfTransport) => {
                        return {
                          value: trans?.id,
                          label: trans?.mode_of_transport,
                        };
                      }
                    )}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={dynamicColSpan}>
                  <Field
                    name="city_town_visited"
                    label="City / Town Visited"
                    placeholder="Select To City "
                    component={FormMultiSelectionFiled}
                    validator={requiredValidator}
                    astrike={true}
                    options={CityList?.map((city: ICity) => {
                      return {
                        value: city?.id,
                        label: city?.city_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={dynamicColSpan}>
                  <Field
                    name="sales_amount"
                    label="Sales Amount"
                    placeholder="Sales Amount"
                    component={FormTextField}
                    type="number"
                    // validator={requiredValidator}
                    // astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={
                    dynamicColSpan === 2
                      ? 4
                      : dynamicColSpan === 0
                      ? 2
                      : dynamicColSpan
                  }
                >
                  <Field
                    name="remarks"
                    label="Remarks"
                    placeholder="Remarks.."
                    component={FormTextArea}
                    rows={2}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <p
                    className="fs-4"
                    style={{ margin: 0, padding: "10px 0", fontWeight: "bold" }}
                  >
                    Expenses
                  </p>
                  {/* <Typography.h4>Expenses</Typography.h4> */}
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={2}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "5px 10px",
                  }}
                >
                  <Label>Fixed Expense</Label>
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}>
                  <Field
                    wrapperStyle={{ margin: 0, marginBottom: "5px" }}
                    name="fixed_expense"
                    // label="Fixed Expense"
                    placeholder="Select Fixed Expense"
                    component={FormSelectionField}
                    // validator={requiredValidator}
                    // astrike={true}
                    options={FixedExpenseAllocationList?.map(
                      (expense: IExpenseAllocation) => {
                        return {
                          value: expense?.expense_type_id,
                          label: expense?.expense_type_name,
                        };
                      }
                    )}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={4}>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    component={ExpenseDetailsArray}
                    name="expense_details"
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={4}>
                  <hr />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={2}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "5px 10px",
                  }}
                >
                  <Label>Total Amount</Label>
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={2}
                  style={{
                    textAlign: "end",
                    marginRight: "13px",
                  }}
                >
                  <Typography.h6>
                    {formatIndianNumber(
                      formRenderProps.valueGetter("total_amount")
                    )}
                  </Typography.h6>
                  {/* <Field
                    wrapperClassName="right-alighned-field"
                    name="total_amount"
                    placeholder="0"
                    disabled={true}
                    component={FormTextField}
                    type="number"
                    // validator={requiredValidator}
                    // astrike={true}
                  /> */}
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={2}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "5px 10px",
                  }}
                >
                  <Label>Kms Travelled</Label>
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}>
                  <Field
                    wrapperStyle={{ margin: 0, padding: "5px" }}
                    wrapperClassName="right-alighned-field"
                    name="kms_travelled"
                    placeholder="0"
                    component={FormNumericTextField}
                    // type="number"
                    // validator={requiredValidator}
                    // astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={2}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "5px 10px",
                  }}
                >
                  <Label>Fare For Transport</Label>
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}>
                  <Field
                    wrapperStyle={{ margin: 0, padding: "5px" }}
                    wrapperClassName="right-alighned-field"
                    name="fare_for_transport"
                    placeholder="0"
                    component={FormNumericTextField}

                    // validator={requiredValidator}
                    // astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={2}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "5px 10px",
                  }}
                >
                  <Label>Net Amount</Label>
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={2}
                  style={{
                    textAlign: "end",
                    marginRight: "13px",
                  }}
                >
                  <Typography.h6>
                    {formatIndianNumber(
                      formRenderProps.valueGetter("net_amount")
                    )}
                  </Typography.h6>
                  {/* <Field
                    wrapperClassName="right-alighned-field"
                    name="net_amount"
                    disabled={true}
                    placeholder="0"
                    component={FormTextField}
                    type="number"
                    // validator={requiredValidator}
                    // astrike={true}
                  /> */}
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={4}
                  style={{
                    textAlign: "end",
                    marginTop: "20px",
                    width: "100%",
                  }}
                >
                  <ButtonWithLoading
                    label={expense_details_guid ? "Update" : "Save"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || formLoading}
                    loading={formLoading}
                  />
                  <Button
                    type="button"
                    fillMode={"outline"}
                    themeColor={"primary"}
                    style={{ marginLeft: 4 }}
                    onClick={handleFormClose}
                  >
                    Cancel
                  </Button>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>

            <ExpenseChangeWatcher formRenderProps={formRenderProps} />
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateExpenseDetails;
