import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  formatIndianNumber,
  formatIndianNumberForQty,
} from "../../_helper/helper";
// eslint-disable-next-line
// import html2pdf from "html2pdf.js";
import { getSalesReprsentativePDF } from "./services/distributorOrder.services";
import moment from "moment";
import { Button } from "@progress/kendo-react-buttons";
import { sumBy } from "lodash";

const DownloadOrderView: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const contentRef = useRef(null);
  const current_date = location.state?.current_date;
  const sr_id = location.state?.sr_id;
  const distributor_id = location.state?.distributor_id;

  const FinalOrderDetails = useAppSelector(
    (state) => state.distributorOrder.FinalOrderDetails
  );

  useEffect(() => {
    if (FinalOrderDetails && FinalOrderDetails?.sr_id) {
      const handlePDFDownload = async () => {
        const html2pdf = (await import("html2pdf.js")).default;

        const options = {
          margin: 10,
          filename: `${FinalOrderDetails?.distributor_name || "download"}_${
            FinalOrderDetails?.plan_date
              ? moment(FinalOrderDetails?.plan_date, "DD/MM/YYYY").format(
                  "DD-MM-YYYY"
                )
              : ""
          }.pdf`,
          html2canvas: { scale: 2 },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        };

        html2pdf()
          .set(options)
          .from(contentRef.current)
          .toPdf()
          .get("pdf")
          .then((pdf: any) => {
            const totalPages = pdf.internal.getNumberOfPages();
            const text = `Created By ${FinalOrderDetails?.sr_name}`;

            for (let i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(10);
              // Get page width dynamically
              const pageWidth = pdf.internal.pageSize.getWidth();
              const textWidth = pdf.getTextWidth(text);
              // Place text at the bottom center
              pdf.text(
                text,
                (pageWidth - textWidth) / 2,
                pdf.internal.pageSize.getHeight() - 10
              );
            }
          })
          .save();
      };
      setTimeout(() => {
        if (contentRef.current) {
          handlePDFDownload();
        }
      }, 500);
    }
  }, [FinalOrderDetails]);

  useEffect(() => {
    if (!FinalOrderDetails || !FinalOrderDetails?.sr_id) {
      const payload = {
        current_date: current_date
          ? moment(current_date).format("YYYY-MM-DD")
          : "",
        sr_id: sr_id ? +sr_id : null,
        distributor_id: distributor_id ? +distributor_id : null,
      };
      dispatch(getSalesReprsentativePDF(payload));
    }
  }, [FinalOrderDetails]);

  return (
    <>
      <div
        className="print-btn"
        style={{
          padding: "5px 13px",
          textAlign: "end",
        }}
      >
        <Button
          themeColor={"primary"}
          fillMode={"solid"}
          onClick={() =>
            FinalOrderDetails && FinalOrderDetails?.sr_id && window.print()
          }
        >
          Print
        </Button>
      </div>
      <div className="container-fluid sr-final-order-view" ref={contentRef}>
        <div className="contaier">
          <div className="row">
            <div className="col-12" style={{ pageBreakAfter: "always" }}>
              <table className="sr-final-order-table w-100 mb-3">
                <tr>
                  <th className="bg-lightgray" style={{ width: "30%" }}>
                    Sales Representative
                  </th>
                  <td style={{ width: "70%" }}>{FinalOrderDetails?.sr_name}</td>
                </tr>
                <tr>
                  <th className="bg-lightgray">Route Plan Date</th>
                  <td>{FinalOrderDetails?.plan_date}</td>
                </tr>
                <tr>
                  <th className="bg-lightgray">Distributor Name</th>
                  <td>{FinalOrderDetails?.distributor_name}</td>
                </tr>
              </table>

              <table
                className="sr-final-order-table mb-3"
                style={{ width: "40%" }}
              >
                <tr>
                  <th className="bg-lightgray" style={{ width: "60%" }}>
                    Total Call:
                  </th>
                  <td style={{ width: "40%" }}>
                    {FinalOrderDetails?.total_call}
                  </td>
                </tr>
                <tr>
                  <th className="bg-lightgray">Prod Call:</th>
                  <td> {FinalOrderDetails?.prod_call}</td>
                </tr>
                <tr>
                  <th className="bg-lightgray">P.C.%:</th>
                  <td>
                    {FinalOrderDetails?.prod_call_percentage}
                    {FinalOrderDetails?.prod_call_percentage && " %"}
                  </td>
                </tr>
              </table>
              {FinalOrderDetails?.item_group &&
                FinalOrderDetails?.item_group?.length > 0 && (
                  <table className="sr-final-order-table w-100 mb-3">
                    <tr className="bg-lightgray">
                      <th>Product</th>
                      <th>Bandha</th>
                      <th>Kgs</th>
                      <th>Packet</th>
                      <th>Value</th>
                    </tr>
                    {FinalOrderDetails?.item_group?.map((group: any) => (
                      <tr>
                        <td>{group?.item_group}</td>
                        <td>{group?.unit_bdh_qty}</td>
                        <td>{group?.unit_kg_qty}</td>
                        <td>{group?.unit_pkt_qty}</td>
                        <td className="text-end">
                          {formatIndianNumber(
                            group?.unit_bdh_amt +
                              group?.unit_pkt_amt +
                              group?.unit_kg_amt
                          )}
                        </td>
                      </tr>
                    ))}
                    <tr style={{ fontWeight: "bold" }}>
                      <td>Total</td>
                      <td>
                        {sumBy(FinalOrderDetails?.item_group, "unit_bdh_qty")}
                      </td>
                      <td>
                        {sumBy(FinalOrderDetails?.item_group, "unit_kg_qty")}
                      </td>
                      <td>
                        {sumBy(FinalOrderDetails?.item_group, "unit_pkt_qty")}
                      </td>
                      <td className="text-end">
                        {formatIndianNumber(
                          sumBy(FinalOrderDetails?.item_group, "unit_bdh_amt") +
                            sumBy(
                              FinalOrderDetails?.item_group,
                              "unit_pkt_amt"
                            ) +
                            sumBy(FinalOrderDetails?.item_group, "unit_kg_amt")
                        )}
                      </td>
                    </tr>
                  </table>
                )}
            </div>
            {FinalOrderDetails?.sales_order &&
              FinalOrderDetails?.sales_order?.length > 0 &&
              FinalOrderDetails?.sales_order?.map(
                (salesorder: any, mainIndex: number) => {
                  return (
                    <div
                      className="col-12 mb-3"
                      style={{
                        pageBreakAfter:
                          mainIndex ===
                          FinalOrderDetails?.sales_order?.length - 1
                            ? "avoid"
                            : "always",
                      }}
                    >
                      <h5 className="text-center">
                        {FinalOrderDetails?.distributor_name}
                      </h5>
                      <h4 className="text-center">Order</h4>
                      <div className="d-flex">
                        <div style={{ width: "60%" }}>
                          <b>{salesorder?.retailer_name}</b> <br />
                          {salesorder?.address1} <br /> {salesorder?.city_name}
                          {salesorder?.city_name && ", "}
                          {salesorder?.state_name}
                        </div>
                        <div
                          style={{
                            width: "40%",
                          }}
                        >
                          <table className="w-100">
                            <tr>
                              <td style={{ width: "50%" }} className="fw-600">
                                Order No:
                              </td>
                              <td style={{ width: "50%" }}>
                                {salesorder?.retailer_sales_order_no}
                              </td>
                            </tr>
                            <tr>
                              <td className="fw-600">Order Date:</td>
                              <td>{salesorder?.retailer_order_date}</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      {salesorder?.sales_order_items &&
                        salesorder?.sales_order_items?.length > 0 && (
                          <table className="sr-final-order-table w-100 mt-4">
                            <tr className="bg-lightgray">
                              <th style={{ maxWidth: 30 }}>Sr No.</th>
                              <th>Product</th>
                              <th>Buy Qty</th>
                              <th>Free Qty</th>
                              <th>Unit Price</th>
                              <th>Total Price</th>
                            </tr>
                            {salesorder?.sales_order_items?.map(
                              (item: any, index: number) => {
                                return (
                                  <tr>
                                    <td className="text-center">{index + 1}</td>
                                    <td>{item?.product_name}</td>
                                    <td className="text-end">
                                      {formatIndianNumberForQty(item?.buy_qty)}
                                    </td>
                                    <td className="text-end">
                                      {formatIndianNumberForQty(item?.free_qty)}
                                    </td>
                                    <td className="text-end">
                                      {formatIndianNumber(item?.unit_price) ||
                                        0}
                                    </td>
                                    <td className="text-end">
                                      {formatIndianNumber(item?.total_price) ||
                                        0}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                            <tr className="text-end fw-600 bg-lightgray">
                              <td colSpan={5}>Grand Total</td>
                              <td>{salesorder?.grand_total_price}</td>
                            </tr>
                          </table>
                        )}
                    </div>
                  );
                }
              )}
          </div>
        </div>
        <div className="print-footer">
          Created By {FinalOrderDetails?.sr_name}
        </div>
      </div>
    </>
  );
};

export default DownloadOrderView;
