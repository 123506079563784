import React, { useCallback, useEffect, useState } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import FormDatePicker from "../../../components/formFields/FormDateField";
import moment from "moment";
import { Typography } from "@progress/kendo-react-common";
import ShadowCard from "../../../components/common/ShadowCard";
import { getAllItemIncremental } from "../../Item/services/item.services";
import {
  clearSalesRegisterDetails,
  clearSalesRegisterList,
  setSalesRegisterReport,
} from "../reportsSlice";
import AlertBox from "../../../components/common/AlertBox";
import { LoadingPanel } from "../../../components/layout/Loading";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import FormIncrementalSearch from "../../../components/formFields/FormIncrementalSearch";
import { clearItemList } from "../../Item/itemSlice";
import { Button } from "@progress/kendo-react-buttons";
import Logo from "../../../assets/Images/logo.png";
import {
  checkAcessRights,
  formatIndianNumber,
  formatIndianNumberForQty,
} from "../../../_helper/helper";
import {
  EDIT_ACCESS,
  EXCEL_EXPORT_ACCESS,
  PRINT_ACCESS,
} from "../../../_contstants/common";
import { getAllAccountIncremental } from "../../account/services/account.services";
import { RxCross2 } from "react-icons/rx";
import FormCheckboxGroup from "../../../components/formFields/FormCheckboxGroup";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { BsThreeDotsVertical } from "react-icons/bs";
import { getSalesRegisterReport } from "../services/reports.services";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { IItemGroup } from "../../ItemGroup/itemgroupModel";
import { findAllActiveItemGroup } from "../../ItemGroup/services/itemgroup.services";
import { IItem } from "../../Item/itemModel";
import { FiEdit } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import IconButton from "../../../components/common/IconButton";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const DummyChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const createdBy = localStorage.getItem("UserID");
  useEffect(() => {
    formRenderProps.onChange("UserID", {
      value: createdBy,
    });
  }, []);

  return null;
};

const SalesRegisterReportForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.reports.loading);
  const ItemList = useAppSelector((state) => state.item.ItemList);
  const ItemGroupList = useAppSelector(
    (state) => state.itemgroup.ItemGroupList
  );
  const SalesRegisterList = useAppSelector(
    (state) => state.reports.SalesRegisterList
  );
  const SalesRegisterDetails = useAppSelector(
    (state) => state.reports.SalesRegisterDetails
  );
  const [initialValue, setInitialValue] = useState<any>({});
  const [formKey, setFormKey] = useState<any>(1);

  useEffect(() => {
    setInitialValue({
      ...SalesRegisterDetails,
      from_date: SalesRegisterDetails?.from_date
        ? moment(SalesRegisterDetails?.from_date, "YYYY-MM-DD").toDate()
        : null,
      to_Date: SalesRegisterDetails?.to_Date
        ? moment(SalesRegisterDetails?.to_Date, "YYYY-MM-DD").toDate()
        : null,
    });
    setFormKey(formKey + 1);
  }, [SalesRegisterDetails]);

  useEffect(() => {
    dispatch(findAllActiveItemGroup());
  }, []);

  const handleVendorSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllAccountIncremental(search));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = (values: any) => {
    const payload = {
      item_id: values?.item_id ? values?.item_id : null,
      item_group_id: values?.item_group_id ? values?.item_group_id : null,
      vendor_id: values?.vendor_id ? values?.vendor_id : null,
      from_date: values?.from_date
        ? moment(values?.from_date).format("YYYY-MM-DD")
        : null,
      to_Date: values?.to_Date
        ? moment(values?.to_Date).format("YYYY-MM-DD")
        : null,
    };
    const setPayload = {
      ...payload,
      vendor_options: values?.vendor_options || [],
      item_options: values?.item_options || [],
      isSalesRef: true,
    };
    dispatch(setSalesRegisterReport(setPayload));
    dispatch(getSalesRegisterReport(payload));
  };

  return (
    <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
      <GridLayoutItem>
        <ShadowCard>
          <Form
            key={formKey}
            initialValues={initialValue}
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 30 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                  ]}
                >
                  <GridLayoutItem colSpan={4}>
                    <Typography.h4
                      style={{ marginBottom: 0, marginLeft: "3px" }}
                    >
                      Sales Register
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="from_date"
                      label="From Date"
                      format="dd/MM/yyyy"
                      component={FormDatePicker}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="to_Date"
                      label="To Date"
                      format="dd/MM/yyyy"
                      minDate={moment(
                        formRenderProps.valueGetter("from_date")
                      ).toDate() || new Date(1900, 0, 1)}
                      component={FormDatePicker}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="vendor_id"
                      label="Customer"
                      placeholder="Type here to search customer..."
                      component={FormIncrementalSearch}
                      fetchIncrementalData={(search: any) =>
                        handleVendorSearchChange(
                          search,
                          `vendor_options`,
                          formRenderProps
                        )
                      }
                      options={
                        formRenderProps
                          .valueGetter("vendor_options")
                          ?.map((item: any) => {
                            return {
                              value: item.id,
                              label: item.account_name,
                            };
                          }) || []
                      }
                    />
                  </GridLayoutItem>
                  <GridLayoutItem></GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="item_group_id"
                      label="Product Group Name"
                      placeholder="Product Group Name"
                      component={FormSelectionField}
                      //   validator={requiredValidator}
                      //   astrike={true}
                      options={ItemGroupList?.map((itemgroup: IItemGroup) => {
                        return {
                          value: itemgroup?.id,
                          label: itemgroup?.item_group,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="w-100"
                      name="item_id"
                      label="Product Name"
                      placeholder="Type here to search item..."
                      component={FormIncrementalSearch}
                      fetchIncrementalData={(search: any) =>
                        !formRenderProps.valueGetter("item_group_id") ||
                        formRenderProps.valueGetter("item_group_id") === null
                          ? dispatch(
                              getAllItemIncremental({
                                search: search,
                              })
                            )
                          : dispatch(
                              getAllItemIncremental({
                                search: search,
                                item_group_id:
                                  formRenderProps.valueGetter("item_group_id"),
                              })
                            )
                      }
                      //   loading={itemLoading}
                      options={ItemList?.map((item: IItem) => {
                        return {
                          value: item?.id,
                          label: item?.product_name,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    // colSpan={4}
                    style={{
                      textAlign: "end",
                      marginTop: 36,
                    }}
                  >
                    <ButtonWithLoading
                      label={"View"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                  </GridLayoutItem>
                </GridLayout>
                <DummyChangeWatcher formRenderProps={formRenderProps} />
              </FormElement>
            )}
          />
        </ShadowCard>
        {/* {loading ? (
          <LoadingPanel gridRef={gridRef} />
        ) : ( */}
        {SalesRegisterList && SalesRegisterList.length > 0 && (
          <ShadowCard style={{ marginTop: 10 }}>
            <SalesRegisterGridCommponent />
          </ShadowCard>
        )}
        {/* )} */}
      </GridLayoutItem>
    </GridLayout>
  );
};

const columns = [
  //   { label: "Invoice No.", value: "invoice_no" },
  { label: "Invoice Date", value: "invoice_date" },
  { label: "Customer Name", value: "account_name" },
  { label: "Item Name", value: "product_name" },
  { label: "Lot No.", value: "lot_no" },
  { label: "Qty", value: "quantity" },
  { label: "Free Qty", value: "free_quantity" },
  { label: "Unit", value: "unit_name" },
  { label: "MRP", value: "mrp" },
  { label: "Rate", value: "rate" },
  { label: "Vatav(%)", value: "vatav_per" },
  { label: "Vatav", value: "vatav" },
  { label: "Taxable Amount ", value: "taxable_amount" },
  { label: "CGST(%)", value: "cgst" },
  { label: "CGST Amount", value: "cgst_amount" },
  { label: "SGST(%)", value: "sgst" },
  { label: "SGST Amount", value: "sgst_amount" },
  { label: "IGST(%)", value: "igst" },
  { label: "IGST Amount", value: "igst_amount" },
  { label: "Total Amount", value: "total_amount" },
  { label: "Total Taxable Amount", value: "total_taxable_amount" },
  { label: "Total CGST Amount", value: "total_cgst" },
  { label: "Total SGST Amount", value: "total_sgst" },
  { label: "Total IGST Amount", value: "total_igst" },
  { label: "Freight", value: "frieght" },
  { label: "Grand Total Amount", value: "gross_total" },
  { label: "Terms Day", value: "terms_day" },
  { label: "Due Date", value: "due_date" },
  { label: "Vehicle No.", value: "vehicle_no" },
  { label: "Cartoon", value: "cartoon" },
  { label: "Bag", value: "bag" },
];
const SalesRegisterGridCommponent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const gridRef = React.useRef<any>(null);
  const isEditBtnRef = React.useRef<any>(false);
  const isEditAccess = checkAcessRights("/sales", EDIT_ACCESS);
  const isPrintAccess = checkAcessRights(location.pathname, PRINT_ACCESS);
  const isExcelAccess = checkAcessRights(
    location.pathname,
    EXCEL_EXPORT_ACCESS
  );

  const loading = useAppSelector((state) => state.reports.loading);
  const SalesRegisterList = useAppSelector(
    (state) => state.reports.SalesRegisterList
  );
  const SalesRegisterDetails = useAppSelector(
    (state) => state.reports.SalesRegisterDetails
  );
  const [showfilterColumns, setShowFilterColumns] = React.useState(false);
  const [selectedGroup, setSelectedGroup] = React.useState<string | null>(null);

  useEffect(() => {
    return () => {
      if (!isEditBtnRef.current) {
        dispatch(clearSalesRegisterList());
        dispatch(clearSalesRegisterDetails());
        dispatch(clearItemList());
      }
    };
  }, []);

  const handleExportExcel = () => {
    const datetime = moment().format("DD-MM-YYYY HH-mm");
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.table_to_sheet(
      document.querySelector(".currentstock-print-table")
    );
    XLSX.utils.book_append_sheet(wb, ws, "SalesRegisterReport");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(data, `Sales Register Report ${datetime}`);
  };

  const handleRowClick = (sale_guid: string) => {
    const groupKey = `${sale_guid}`;
    setSelectedGroup(selectedGroup === groupKey ? null : groupKey);
  };

  const initialValues = {
    filter_columns: [
      //   "invoice_no",

      "invoice_date",
      "account_name",
      // "product_name",
      // "lot_no",
      // "quantity",
      // "free_quantity",
      // "unit_name",
      // "mrp",
      // "rate",
      // "vatav_per",
      // "vatav",
      "taxable_amount",
      "cgst",
      "cgst_amount",
      "sgst",
      "sgst_amount",
      "igst",
      "igst_amount",
      // "total_amount",
      // "total_taxable_amount",
      "total_cgst",
      "total_sgst",
      "total_igst",
      // "frieght",
      "gross_total",
      // "terms_day",
      // "due_date",
      // "vehicle_no",
      // "cartoon",
      // "bag",
    ],
  };

  const GrandTotalArray: any = [
    "quantity",
    "free_quantity",
    "vatav",
    "taxable_amount",
    "cgst_amount",
    "sgst_amount",
    "igst_amount",
    "total_amount",
    "total_taxable_amount",
    "total_cgst",
    "total_sgst",
    "total_igst",
    "frieght",
    "gross_total",
  ];

  const groupedData = SalesRegisterList.reduce((acc: any, item: any) => {
    const { sale_guid } = item;
    acc[sale_guid] = acc[sale_guid] || [];
    acc[sale_guid].push(item);
    return acc;
  }, {});
  const groupedArray = Object.values(groupedData);

  let totalQty = 0;
  let totalFreeQty = 0;
  let totalVatav = 0;
  let totalTaxableAmount = 0;
  let totalCGSTAmount = 0;
  let totalSGSTAmount = 0;
  let totalIGSTAmount = 0;
  let totalGSTWithAmount = 0;
  let totalGrandTaxableAmount = 0;
  let GrandtotalCGSTAmount = 0;
  let GrandtotalSGSTAmount = 0;
  let GrandtotalIGSTAmount = 0;
  let totalFreightAmount = 0;
  let GrandTotalAmount = 0;

  groupedArray &&
    groupedArray.length > 0 &&
    groupedArray?.map((group: any) => {
      group?.map((item: any, index: number) => {
        totalQty += item?.quantity || 0;
        totalFreeQty += item?.free_quantity || 0;
        totalVatav += item?.vatav || 0;
        totalTaxableAmount += item?.taxable_amount || 0;
        totalCGSTAmount += item?.cgst_amount || 0;
        totalSGSTAmount += item?.sgst_amount || 0;
        totalIGSTAmount += item?.igst_amount || 0;
        totalGSTWithAmount += item?.total_amount || 0;

        if (index === 0) {
          totalGrandTaxableAmount += item?.total_taxable_amount || 0;
          GrandtotalCGSTAmount += item?.total_cgst || 0;
          GrandtotalSGSTAmount += item?.total_sgst || 0;
          GrandtotalIGSTAmount += item?.total_igst || 0;
          totalFreightAmount += item?.frieght || 0;
          GrandTotalAmount += item?.gross_total || 0;
        }
      });
    });

  const printTable = (data: any[], formRenderProps: any) => {
    let printbuttons = document.querySelector(
      ".printbuttons"
    ) as HTMLElement | null;
    let report_container = document.querySelector(
      ".report-container"
    ) as HTMLElement | null;

    if (printbuttons) printbuttons.style.display = "none";
    if (report_container) report_container.style.padding = "20px 0";

    let tableRows = data.length;
    let maxRows = tableRows;
    let pageCount = Math.ceil(tableRows / maxRows);
    let content = "";

    for (let i = 0; i < pageCount; i++) {
      content += '<div class="print-page">';
      content +=
        '<table class="table table-bordered" style="font-size:10px;margin-bottom:30px;">';
      content += "<tr>";
      content += `<td rowSpan="3" style="width:15%" ><img src="${Logo}" width="100%" /></td>`;
      content +=
        '<td colSpan="2" style="text-align: center;font-size: 14px;font-weight: 600;color: gray;">Harihar Foods Pvt Ltd.</td>';
      content += "</tr>";
      content += "<tr>";
      content +=
        '<td colSpan="2"><h3 style="margin:0;">Sales Register Report</h3></td>';
      content += "</tr>";
      content += "<tr>";
      content += `<td style="text-align:end;">Page : ${i + 1}</td>`;
      content += "</tr>";

      content += "</table>";

      content += '<table class="table table-bordered" style="font-size:10px;">';
      content += '<thead style="background:lightgray;">';
      content += "<tr>";
      content += '<th style="width:30px;">Invoice No.</th>';
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("invoice_date") && (content += "<th>Invoice Date</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("account_name") && (content += "<th>Customer Name</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("product_name") &&
          (content += '<th style="min-width:150px;">Item Name</th>');
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("lot_no") &&
          (content += "<th>Lot No.</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("quantity") &&
          (content += "<th>Qty</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("free_quantity") && (content += "<th>Free Qty</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("unit_name") &&
          (content += "<th>Unit</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("mrp") &&
          (content += "<th>MRP</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("rate") &&
          (content += "<th>Rate</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("vatav_per") &&
          (content += "<th>Vatav(%)</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("vatav") &&
          (content += "<th>Vatav</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("taxable_amount") &&
          (content += "<th>Taxable Amount</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("cgst") &&
          (content += "<th>CGST(%)</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("cgst_amount") && (content += "<th>CGST Amount</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("sgst") &&
          (content += "<th>SGST(%)</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("sgst_amount") && (content += "<th>SGST Amount</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("igst") &&
          (content += "<th>IGST(%)</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("igst_amount") && (content += "<th>IGST Amount</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("total_amount") && (content += "<th>Total Amount</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("total_taxable_amount") &&
          (content += "<th>Total Taxable Amount</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("total_cgst") &&
          (content += "<th>Total CGST Amount</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("total_sgst") &&
          (content += "<th>Total SGST Amount</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("total_igst") &&
          (content += "<th>Total IGST Amount</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("frieght") &&
          (content += "<th>Freight</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("gross_total") &&
          (content += "<th>Grand Total Amount</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("terms_day") &&
          (content += "<th>Terms Day</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("due_date") &&
          (content += "<th>Due Date</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("vehicle_no") &&
          (content += "<th>Vehicle No.</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("cartoon") &&
          (content += "<th>Cartoon</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("bag") &&
          (content += "<th>Bag</th>");
      }
      content += "</tr>";
      content += "</thead>";
      content += "<tbody>";

      // let startRow = i * maxRows;
      // let endRow = Math.min((i + 1) * maxRows, tableRows);

      data.map((group: any) =>
        group.map((item: any, index: any) => {
          content += "<tr>";
          index === 0 &&
            (content += `<td rowspan="${group.length}" >${
              item.invoice_no_string ? item.invoice_no_string : ""
            }</td>`);
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("invoice_date") &&
              index === 0 &&
              (content += `<td rowspan="${
                group.length
              }" style="text-align:center;">${
                item?.invoice_date
                  ? moment(item?.invoice_date, "DD/MM/YYYY").format(
                      "DD MMM YYYY"
                    )
                  : ""
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("account_name") &&
              index === 0 &&
              (content += `<td rowspan="${group.length}" >${item.account_name}</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("product_name") &&
              (content += `<td>${item.product_name}</td>`);
          }
          {
            formRenderProps.valueGetter("filter_columns")?.includes("lot_no") &&
              (content += `<td>${item.lot_no ? item.lot_no : ""}</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("quantity") &&
              (content += `<td style="text-align:end;">${
                item.quantity ? formatIndianNumber(item.quantity) : 0
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("free_quantity") &&
              (content += `<td style="text-align:end;">${
                item.free_quantity ? formatIndianNumber(item.free_quantity) : 0
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("unit_name") &&
              (content += `<td>${item.unit_code}</td>`);
          }
          {
            formRenderProps.valueGetter("filter_columns")?.includes("mrp") &&
              (content += `<td>${item.mrp}</td>`);
          }
          {
            formRenderProps.valueGetter("filter_columns")?.includes("rate") &&
              (content += `<td style="text-align:end;">${
                item.rate ? formatIndianNumber(item.rate) : 0
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("vatav_per") &&
              (content += `<td style="text-align:end;">${
                item.vatav_per ? `${item.vatav_per} %` : ""
              }</td>`);
          }
          {
            formRenderProps.valueGetter("filter_columns")?.includes("vatav") &&
              (content += `<td style="text-align:end;">${
                item.vatav ? formatIndianNumber(item.vatav) : ""
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("taxable_amount") &&
              (content += `<td style="text-align:end;">${
                item.taxable_amount
                  ? formatIndianNumber(item.taxable_amount)
                  : 0
              }</td>`);
          }
          {
            formRenderProps.valueGetter("filter_columns")?.includes("cgst") &&
              (content += `<td style="text-align:end;">${
                item.cgst ? `${item.cgst} %` : ""
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("cgst_amount") &&
              (content += `<td style="text-align:end;">${
                item.cgst_amount ? formatIndianNumber(item.cgst_amount) : ""
              }</td>`);
          }
          {
            formRenderProps.valueGetter("filter_columns")?.includes("sgst") &&
              (content += `<td style="text-align:end;">${
                item.sgst ? `${item.sgst} %` : ""
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("sgst_amount") &&
              (content += `<td style="text-align:end;">${
                item.sgst_amount ? formatIndianNumber(item.sgst_amount) : ""
              }</td>`);
          }
          {
            formRenderProps.valueGetter("filter_columns")?.includes("igst") &&
              (content += `<td style="text-align:end;">${
                item.igst ? `${item.igst} %` : ""
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("igst_amount") &&
              (content += `<td style="text-align:end;">${
                item.igst_amount ? formatIndianNumber(item.igst_amount) : ""
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("total_amount") &&
              (content += `<td style="text-align:end;">${
                item.total_amount ? formatIndianNumber(item.total_amount) : ""
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("total_taxable_amount") &&
              index === 0 &&
              (content += `<td rowspan="${
                group.length
              }" style="text-align:end;">${
                item.total_taxable_amount
                  ? formatIndianNumber(item.total_taxable_amount)
                  : ""
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("total_cgst") &&
              index === 0 &&
              (content += `<td rowspan="${
                group.length
              }" style="text-align:end;">${
                item.total_cgst ? formatIndianNumber(item.total_cgst) : ""
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("total_sgst") &&
              index === 0 &&
              (content += `<td rowspan="${
                group.length
              }" style="text-align:end;">${
                item.total_sgst ? formatIndianNumber(item.total_sgst) : ""
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("total_igst") &&
              index === 0 &&
              (content += `<td rowspan="${
                group.length
              }" style="text-align:end;">${
                item.total_igst ? formatIndianNumber(item.total_igst) : ""
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("frieght") &&
              index === 0 &&
              (content += `<td rowspan="${
                group.length
              }" style="text-align:end;">${
                item.frieght ? formatIndianNumber(item.frieght) : ""
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("gross_total") &&
              index === 0 &&
              (content += `<td rowspan="${
                group.length
              }" style="text-align:end;">${
                item.gross_total ? formatIndianNumber(item.gross_total) : ""
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("terms_day") &&
              index === 0 &&
              (content += `<td rowspan="${
                group.length
              }" style="text-align:center;">${
                item.terms_day ? item.terms_day : ""
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("due_date") &&
              index === 0 &&
              (content += `<td rowspan="${
                group.length
              }" style="text-align:center;">${
                item?.due_date
                  ? moment(item?.due_date, "DD/MM/YYYY").format("DD MMM YYYY")
                  : ""
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("vehicle_no") &&
              index === 0 &&
              (content += `<td rowspan="${group.length}" >${item.vehicle_no}</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("cartoon") &&
              index === 0 &&
              (content += `<td rowspan="${
                group.length
              }" style="text-align:center;">${
                item.cartoon ? item.cartoon : ""
              }</td>`);
          }
          {
            formRenderProps.valueGetter("filter_columns")?.includes("bag") &&
              index === 0 &&
              (content += `<td rowspan="${
                group.length
              }" style="text-align:center;">${item.bag ? item.bag : ""}</td>`);
          }
          content += "</tr>";
        })
      );
      if (data && data?.length > 0) {
        content += '<tr style="background:whitesmoke; font-weight:600;">';
        content += `<td>Total</td>`;
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("invoice_date") && (content += `<td></td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("account_name") && (content += `<td ></td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("product_name") && (content += `<td></td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("lot_no") &&
            (content += `<td></td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("quantity") &&
            (content += `<td style="text-align:end;">${formatIndianNumber(
              totalQty
            )}</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("free_quantity") &&
            (content += `<td style="text-align:end;">${formatIndianNumber(
              totalFreeQty
            )}</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("unit_name") && (content += `<td></td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("mrp") &&
            (content += `<td></td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("rate") &&
            (content += `<td style="text-align:end;"></td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("vatav_per") &&
            (content += `<td style="text-align:end;"></td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("vatav") &&
            (content += `<td style="text-align:end;">${formatIndianNumber(
              totalVatav
            )}</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("taxable_amount") &&
            (content += `<td style="text-align:end;">${formatIndianNumber(
              totalTaxableAmount
            )}</td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("cgst") &&
            (content += `<td style="text-align:end;"></td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("cgst_amount") &&
            (content += `<td style="text-align:end;">${formatIndianNumber(
              totalCGSTAmount
            )}</td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("sgst") &&
            (content += `<td style="text-align:end;"></td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("sgst_amount") &&
            (content += `<td style="text-align:end;">${formatIndianNumber(
              totalSGSTAmount
            )}</td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("igst") &&
            (content += `<td style="text-align:end;"></td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("igst_amount") &&
            (content += `<td style="text-align:end;">${formatIndianNumber(
              totalIGSTAmount
            )}</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("total_amount") &&
            (content += `<td style="text-align:end;">${formatIndianNumber(
              totalGSTWithAmount
            )}</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("total_taxable_amount") &&
            (content += `<td style="text-align:end;">${formatIndianNumber(
              totalGrandTaxableAmount
            )}</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("total_cgst") &&
            (content += `<td  style="text-align:end;">${formatIndianNumber(
              GrandtotalCGSTAmount
            )}</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("total_sgst") &&
            (content += `<td  style="text-align:end;">${formatIndianNumber(
              GrandtotalSGSTAmount
            )}</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("total_igst") &&
            (content += `<td style="text-align:end;">${formatIndianNumber(
              GrandtotalIGSTAmount
            )}</td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("frieght") &&
            (content += `<td style="text-align:end;">${formatIndianNumber(
              totalFreightAmount
            )}</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("gross_total") &&
            (content += `<td style="text-align:end;">${formatIndianNumber(
              GrandTotalAmount
            )}</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("terms_day") &&
            (content += `<td style="text-align:center;"></td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("due_date") &&
            (content += `<td  style="text-align:center;"></td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("vehicle_no") && (content += `<td  ></td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("cartoon") &&
            (content += `<td style="text-align:center;"></td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("bag") &&
            (content += `<td style="text-align:center;"></td>`);
        }
        content += "</tr>";
      }

      content += "</tbody>";
      content += "</table>";

      content += "</div>";
    }

    let printWindow = window.open("", "_blank");
    if (!printWindow) {
      console.error("Failed to open print preview window.");
      return;
    }
    printWindow.document.write(`
        <html>
        <head>
          <title>Print Preview</title>
          <style>
            @page { margin: 1cm; }
            .print-page { page-break-after: always; }
            .table-bordered { width: 100%; border-collapse: collapse; }
            .table-bordered th, .table-bordered td { border: 1px solid black; padding: 8px; }
            body { font-family: Arial, sans-serif; }
          </style>
        </head>
        <body>
          ${content}
          <script>
            window.onload = function() {
              window.print();
            };
          </script>
        </body>
        </html>
      `);
    printWindow.document.close();

    if (printbuttons) printbuttons.style.display = "block";
    if (report_container) report_container.style.padding = "20px 10px";
  };

  // const calculateRowSpans = (data: any[]) => {
  //   const rowSpans: any = {};

  //   data.forEach((item: any, index: number) => {
  //     const { invoice_no, vendor_id } = item;
  //     if (!rowSpans[invoice_no]) {
  //       rowSpans[invoice_no] = {};
  //     }

  //     if (!rowSpans[invoice_no][vendor_id]) {
  //       rowSpans[invoice_no][vendor_id] = { count: 1, index };
  //     } else {
  //       rowSpans[invoice_no][vendor_id].count++;
  //     }
  //   });

  //   return rowSpans;
  // };

  // const rowSpans = calculateRowSpans(SalesRegisterList);

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      {SalesRegisterList && SalesRegisterList.length ? (
        <Form
          initialValues={initialValues}
          render={(formRenderProps: FormRenderProps) => {
            const isMatchFound = GrandTotalArray?.some((subItem: any) =>
              formRenderProps.valueGetter("filter_columns")?.includes(subItem)
            );
            return (
              <FormElement
                style={{ width: "100%", minHeight: "calc(100vh - 318px)" }}
              >
                <GridLayout
                  gap={{ rows: 10, cols: 10 }}
                  cols={[{ width: "100%" }]}
                >
                  <GridLayoutItem className="d-flex justify-content-between align-items-center">
                    <Typography.h4 className="m-0">
                      Sales Register List{" "}
                      {(SalesRegisterDetails?.from_date ||
                        SalesRegisterDetails?.to_Date) && (
                        <span style={{ fontSize: 13 }}>
                          {"("}
                          {SalesRegisterDetails?.from_date
                            ? moment(
                                SalesRegisterDetails?.from_date,
                                "YYYY-MM-DD"
                              ).format("DD/MM/YYYY")
                            : ""}
                          {SalesRegisterDetails?.from_date &&
                            SalesRegisterDetails?.to_Date &&
                            " - "}
                          {SalesRegisterDetails?.to_Date
                            ? moment(
                                SalesRegisterDetails?.to_Date,
                                "YYYY-MM-DD"
                              ).format("DD/MM/YYYY")
                            : ""}
                          {")"}
                        </span>
                      )}
                    </Typography.h4>
                    <div className="position-relative d-flex">
                      {isPrintAccess && (
                        <Button
                          style={{ marginRight: 5 }}
                          type="button"
                          fillMode={"solid"}
                          themeColor={"primary"}
                          onClick={() =>
                            printTable(groupedArray, formRenderProps)
                          }
                        >
                          Print
                        </Button>
                      )}
                      {isExcelAccess && (
                        <Button
                          type="button"
                          fillMode={"solid"}
                          themeColor={"primary"}
                          onClick={handleExportExcel}
                          style={{ marginRight: 5 }}
                        >
                          Excel Export
                        </Button>
                      )}
                      <Tooltip
                        anchorElement="target"
                        position="left"
                        parentTitle={true}
                      >
                        <Button
                          title="Filter Columns"
                          type="button"
                          themeColor={"primary"}
                          fillMode={"solid"}
                          onClick={() => setShowFilterColumns(true)}
                        >
                          Filter
                          <BsThreeDotsVertical />
                        </Button>
                      </Tooltip>
                      {showfilterColumns && (
                        <div
                          style={{
                            position: "absolute",
                            top: "32px",
                            right: "0",
                            background: "white",
                            padding: "12px",
                            border: "1px solid whitesmoke",
                            width: "250px",
                            zIndex: 2,
                          }}
                        >
                          <div className="d-flex justify-content-between align-items-baseline">
                            <p className="m-0 fw-600">Filter</p>
                            <Button
                              themeColor={"primary"}
                              fillMode={"flat"}
                              onClick={() => setShowFilterColumns(false)}
                            >
                              <RxCross2 />
                            </Button>
                          </div>
                          <hr className="m-1" style={{ color: "lightgray" }} />
                          <Field
                            wrapperClassName="d-flex flex-column checkBoxGroup-vertical"
                            inputClassName="checkBoxGroup-vertical-input"
                            id={"filter_columns"}
                            name={"filter_columns"}
                            marginRight={0}
                            marginBeetween={8}
                            component={FormCheckboxGroup}
                            options={columns}
                          />
                        </div>
                      )}
                    </div>
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{
                      maxHeight: "calc(100vh - 362px)",
                      overflow: "scroll",
                      scrollbarWidth: "thin",
                    }}
                  >
                    <table
                      cellSpacing={1}
                      cellPadding={2}
                      className="table table-bordered w-100  currentstock-print-table"
                      style={{
                        marginTop: 1,
                      }}
                    >
                      <tr
                        className="text-center fw-600"
                        style={{
                          fontSize: 12,
                          position: "sticky",
                          top: 0,
                          outline: "1px solid #3b3b3b",
                          zIndex: 1,
                        }}
                      >
                        {/* {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("invoice_no") && ( */}
                        <th style={{ minWidth: 200 }}>Invoice No.</th>
                        {/* )} */}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("invoice_date") && (
                          <th style={{ minWidth: 120 }}>Invoice Date</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("account_name") && (
                          <th style={{ minWidth: 250 }}>Customer Name</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("product_name") && (
                          <th style={{ minWidth: 250 }}>Item Name</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("lot_no") && (
                          <th style={{ minWidth: 100 }}>Lot No</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("quantity") && (
                          <th style={{ minWidth: 150 }}>Qty</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("free_quantity") && (
                          <th style={{ minWidth: 150 }}>Free Qty</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("unit_name") && (
                          <th style={{ minWidth: 100 }}>Unit</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("mrp") && (
                          <th style={{ minWidth: 120 }}>MRP</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("rate") && (
                          <th style={{ minWidth: 120 }}>Rate</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("vatav_per") && (
                          <th style={{ minWidth: 80 }}>Vatav(%)</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("vatav") && (
                          <th style={{ minWidth: 120 }}>Vatav</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("taxable_amount") && (
                          <th style={{ minWidth: 150 }}>Taxable Amount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("cgst") && (
                          <th style={{ minWidth: 100 }}>CGST(%)</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("cgst_amount") && (
                          <th style={{ minWidth: 150 }}>CGST Amount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("sgst") && (
                          <th style={{ minWidth: 100 }}>SGST(%)</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("sgst_amount") && (
                          <th style={{ minWidth: 150 }}>SGST Amount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("igst") && (
                          <th style={{ minWidth: 100 }}>IGST(%)</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("igst_amount") && (
                          <th style={{ minWidth: 150 }}>IGST Amount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("total_amount") && (
                          <th style={{ minWidth: 150 }}>Total Amount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("total_taxable_amount") && (
                          <th style={{ minWidth: 150 }}>
                            Total Taxable Amount
                          </th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("total_cgst") && (
                          <th style={{ minWidth: 150 }}>Total CGST Amount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("total_sgst") && (
                          <th style={{ minWidth: 150 }}>Total SGST Amount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("total_igst") && (
                          <th style={{ minWidth: 150 }}>Total IGST Amount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("frieght") && (
                          <th style={{ minWidth: 100 }}>Freight</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("gross_total") && (
                          <th style={{ minWidth: 150 }}>Grand Total Amount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("terms_day") && (
                          <th style={{ minWidth: 80 }}>Terms Day</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("due_date") && (
                          <th style={{ minWidth: 120 }}>Due Date</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("vehicle_no") && (
                          <th style={{ minWidth: 120 }}>Vehicle No.</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("cartoon") && (
                          <th style={{ minWidth: 100 }}>Cartoon</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("bag") && (
                          <th style={{ minWidth: 100 }}>Bag</th>
                        )}
                      </tr>
                      {groupedArray &&
                        groupedArray?.length > 0 &&
                        groupedArray?.map((group: any) =>
                          group.map((item: any, index: number) => {
                            const groupKey = `${item?.sale_guid}`;
                            const isSelected = selectedGroup === groupKey;

                            return (
                              <tr
                                className={`text-center`}
                                style={{
                                  background: isSelected
                                    ? "rgb(255 198 198 / 53%)"
                                    : "transparent",
                                }}
                                onClick={() => handleRowClick(item.sale_guid)}
                              >
                                {index === 0 && (
                                  <td
                                    rowSpan={group.length}
                                    className="text-start register-edit-column"
                                  >
                                    {item.invoice_no_string}
                                    {isEditAccess && (
                                      <IconButton
                                        type="button"
                                        fillMode="flat"
                                        themeColor={"primary"}
                                        ref={isEditBtnRef}
                                        size="small"
                                        style={{
                                          padding: "15px",
                                          marginLeft: "5px",
                                        }}
                                        onClick={() => {
                                          isEditBtnRef.current = true;
                                          navigate("/sales/edit", {
                                            state: {
                                              sale_guid: item?.sale_guid,
                                            },
                                          });
                                        }}
                                      >
                                        <FiEdit
                                          className="absolute-position"
                                          style={{
                                            fontSize: "20px",
                                            zIndex: 0,
                                            color: "red",
                                          }}
                                        />
                                      </IconButton>
                                    )}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("invoice_date") &&
                                  index === 0 && (
                                    <td rowSpan={group.length}>
                                      {item.invoice_date
                                        ? moment(
                                            item.invoice_date,
                                            "DD/MM/YYYY"
                                          )?.format("DD MMM YYYY")
                                        : ""}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("account_name") &&
                                  index === 0 && (
                                    <td
                                      rowSpan={group.length}
                                      className="text-start"
                                    >
                                      {item?.account_name}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("product_name") && (
                                  <td className="text-start">
                                    {item?.product_name}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("lot_no") && (
                                  <td>{item?.lot_no}</td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("quantity") && (
                                  <td className="text-end">
                                    {formatIndianNumberForQty(item?.quantity) ||
                                      0}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("free_quantity") && (
                                  <td className="text-end">
                                    {formatIndianNumberForQty(
                                      item?.free_quantity
                                    ) || 0}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("unit_name") && (
                                  <td>{item?.unit_code}</td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("mrp") && (
                                  <td className="text-end">{item?.mrp}</td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("rate") && (
                                  <td className="text-end">
                                    {formatIndianNumber(item?.rate || 0)}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("vatav_per") && (
                                  <td className="text-end">
                                    {item?.vatav_per
                                      ? `${item?.vatav_per} %`
                                      : ""}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("vatav") && (
                                  <td className="text-end">
                                    {formatIndianNumber(item?.vatav)}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("taxable_amount") && (
                                  <td className="text-end">
                                    {formatIndianNumber(
                                      item?.taxable_amount || 0
                                    )}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("cgst") && (
                                  <td className="text-end">
                                    {item?.cgst ? `${item?.cgst} %` : ""}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("cgst_amount") && (
                                  <td className="text-end">
                                    {formatIndianNumber(item?.cgst_amount)}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("sgst") && (
                                  <td className="text-end">
                                    {item?.sgst ? `${item?.sgst} %` : ""}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("sgst_amount") && (
                                  <td className="text-end">
                                    {formatIndianNumber(item?.sgst_amount)}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("igst") && (
                                  <td className="text-end">
                                    {item?.igst ? `${item?.igst} %` : ""}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("igst_amount") && (
                                  <td className="text-end">
                                    {formatIndianNumber(item?.igst_amount)}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("total_amount") && (
                                  <td className="text-end">
                                    {formatIndianNumber(
                                      item?.total_amount || 0
                                    )}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("total_taxable_amount") &&
                                  index === 0 && (
                                    <td
                                      rowSpan={group.length}
                                      className="text-end"
                                    >
                                      {formatIndianNumber(
                                        item?.total_taxable_amount || 0
                                      )}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("total_cgst") &&
                                  index === 0 && (
                                    <td
                                      rowSpan={group.length}
                                      className="text-end"
                                    >
                                      {formatIndianNumber(item?.total_cgst)}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("total_sgst") &&
                                  index === 0 && (
                                    <td
                                      rowSpan={group.length}
                                      className="text-end"
                                    >
                                      {formatIndianNumber(item?.total_sgst)}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("total_igst") &&
                                  index === 0 && (
                                    <td
                                      rowSpan={group.length}
                                      className="text-end"
                                    >
                                      {formatIndianNumber(item?.total_igst)}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("frieght") &&
                                  index === 0 && (
                                    <td
                                      rowSpan={group.length}
                                      className="text-end"
                                    >
                                      {formatIndianNumber(item?.frieght || 0)}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("gross_total") &&
                                  index === 0 && (
                                    <td
                                      rowSpan={group.length}
                                      className="text-end"
                                    >
                                      {formatIndianNumber(
                                        item?.gross_total || 0
                                      )}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("terms_day") &&
                                  index === 0 && (
                                    <td rowSpan={group.length}>
                                      {item?.terms_day}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("due_date") &&
                                  index === 0 && (
                                    <td rowSpan={group.length}>
                                      {item?.due_date}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("vehicle_no") &&
                                  index === 0 && (
                                    <td rowSpan={group.length}>
                                      {item?.vehicle_no}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("cartoon") &&
                                  index === 0 && (
                                    <td
                                      rowSpan={group.length}
                                      className="text-start"
                                    >
                                      {item?.cartoon}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("bag") &&
                                  index === 0 && (
                                    <td rowSpan={group.length}>{item?.bag}</td>
                                  )}
                              </tr>
                            );
                          })
                        )}
                      {groupedArray &&
                        groupedArray?.length > 0 &&
                        isMatchFound && (
                          <tr
                            className="text-center"
                            style={{
                              background: "whitesmoke",
                              fontWeight: 600,
                            }}
                          >
                            <td className="text-start">Total</td>
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("invoice_date") && <td></td>}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("account_name") && (
                              <td className="text-start"></td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("product_name") && (
                              <td className="text-start"></td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("lot_no") && <td></td>}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("quantity") && (
                              <td className="text-end">
                                {formatIndianNumberForQty(totalQty)}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("free_quantity") && (
                              <td className="text-end">
                                {formatIndianNumberForQty(totalFreeQty)}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("unit_name") && <td></td>}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("mrp") && (
                              <td className="text-end"></td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("rate") && (
                              <td className="text-end"></td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("vatav_per") && (
                              <td className="text-end"></td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("vatav") && (
                              <td className="text-end">
                                {formatIndianNumber(totalVatav)}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("taxable_amount") && (
                              <td className="text-end">
                                {formatIndianNumber(totalTaxableAmount)}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("cgst") && (
                              <td className="text-end"></td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("cgst_amount") && (
                              <td className="text-end">
                                {formatIndianNumber(totalCGSTAmount)}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("sgst") && (
                              <td className="text-end"></td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("sgst_amount") && (
                              <td className="text-end">
                                {formatIndianNumber(totalSGSTAmount)}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("igst") && (
                              <td className="text-end"></td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("igst_amount") && (
                              <td className="text-end">
                                {formatIndianNumber(totalIGSTAmount)}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("total_amount") && (
                              <td className="text-end">
                                {formatIndianNumber(totalGSTWithAmount)}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("total_taxable_amount") && (
                              <td className="text-end">
                                {formatIndianNumber(totalGrandTaxableAmount)}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("total_cgst") && (
                              <td className="text-end">
                                {formatIndianNumber(GrandtotalCGSTAmount)}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("total_sgst") && (
                              <td className="text-end">
                                {formatIndianNumber(GrandtotalSGSTAmount)}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("total_igst") && (
                              <td className="text-end">
                                {formatIndianNumber(GrandtotalIGSTAmount)}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("frieght") && (
                              <td className="text-end">
                                {formatIndianNumber(totalFreightAmount)}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("gross_total") && (
                              <td className="text-end">
                                {formatIndianNumber(GrandTotalAmount)}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("terms_day") && <td></td>}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("due_date") && <td></td>}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("vehicle_no") && <td></td>}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("cartoon") && (
                              <td className="text-start"></td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("bag") && <td></td>}
                          </tr>
                        )}
                    </table>
                  </GridLayoutItem>
                </GridLayout>
              </FormElement>
            );
          }}
        />
      ) : (
        <AlertBox />
      )}
    </>
  );
};
export default SalesRegisterReportForm;
