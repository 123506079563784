import React, { useCallback, useEffect } from "react";
import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  createOpeningStock,
  getAllDistributorRetailerMarginRates,
  getOpeningStockByID,
  updateOpeningStock,
} from "./services/distributorOpeningStock.services";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ShadowCard from "../../components/common/ShadowCard";
import { getAllAccountIncremental } from "../account/services/account.services";
import FormDatePicker from "../../components/formFields/FormDateField";
import moment from "moment";
import { clearOpeningStockDetails } from "./distributorOpeningStockSlice";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import {
  ExpansionPanel,
  ExpansionPanelActionEvent,
  ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import { clearAccountList } from "../account/accountSlice";

const CreateDistributorOpeningStock: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const id = location.state?.id;

  const loading = useAppSelector(
    (state) => state.distributorOpeningStock.loading
  );
  const MarginRateList = useAppSelector(
    (state) => state.distributorOpeningStock.MarginRateList
  );
  const OpeningStockDetail = useAppSelector(
    (state) => state.distributorOpeningStock.OpeningStockDetail
  );

  const [initialValues, setInitialValues] = React.useState({});
  const [formKey, setFormKey] = React.useState(1);
  const [RateData, setRateData] = React.useState<any[]>(
    JSON.parse(JSON.stringify(MarginRateList))
  );
  const [expanded, setExpanded] = React.useState<any>(0);

  useEffect(() => {
    dispatch(getAllDistributorRetailerMarginRates(true));

    return () => {
      dispatch(clearOpeningStockDetails());
      dispatch(clearAccountList());
    };
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getOpeningStockByID(+id));
    }
  }, [id]);

  useEffect(() => {
    setRateData(JSON.parse(JSON.stringify(MarginRateList)));
  }, [MarginRateList]);

  useEffect(() => {
    const updatedMarginRatelist = JSON.parse(JSON.stringify(MarginRateList));
    const updatedData = updatedMarginRatelist.map((itemGroup: any) => {
      const updatedItems = itemGroup.item_margin_rate.map((item: any) => {
        OpeningStockDetail?.open_stock?.forEach((printGroup: any) => {
          if (item.item_group_id === printGroup.item_group_id) {
            printGroup.item_margin_rate.forEach((printItem: any) => {
              if (item.item_id === printItem.item_id) {
                item.quantity = +printItem.quantity;
                item.amount = +printItem.amount;
                item.id = +printItem.id;
              }
            });
          }
        });
        return item;
      });
      return {
        ...itemGroup,
        item_margin_rate: updatedItems,
      };
    });

    setRateData(updatedData);
  }, [MarginRateList, OpeningStockDetail]);

  useEffect(() => {
    const newInitialValues =
      OpeningStockDetail &&
      OpeningStockDetail?.open_stock &&
      OpeningStockDetail?.open_stock
        ?.map((item: any) => {
          return item?.item_margin_rate?.map((subitem: any) => {
            return {
              [`qty_${item?.item_group_id}_${subitem?.item_id}`]:
                subitem?.quantity,
              [`amount_${item?.item_group_id}_${subitem?.item_id}`]:
                subitem?.amount,
            };
          });
        })
        ?.reduce((acc: any, curr: any) => {
          return {
            ...OpeningStockDetail,
            ...acc,
            ...curr.reduce((subAcc: any, subCurr: any) => {
              return { ...subAcc, ...subCurr };
            }, {}),
          };
        }, {});

    if (JSON.stringify(newInitialValues) !== JSON.stringify(initialValues)) {
      setInitialValues(newInitialValues);
      setFormKey((prevKey) => prevKey + 1);
    }
  }, [OpeningStockDetail]);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, []);

  const handleQtyChange = (
    formRenderProps: FormRenderProps,
    mainIndex: number,
    subIndex: number,
    itemGroupId: number,
    itemId: number
  ) => {
    const qty = formRenderProps.valueGetter(`qty_${itemGroupId}_${itemId}`);

    const rate = RateData[mainIndex]?.item_margin_rate[subIndex]?.rate;
    if (qty === 0) {
      formRenderProps.onChange(`qty_${itemGroupId}_${itemId}`, {
        value: null,
      });
    }

    formRenderProps.onChange(`amount_${itemGroupId}_${itemId}`, {
      value: +rate * +qty || null,
    });

    RateData[mainIndex].item_margin_rate[subIndex].amount = +rate * +qty || 0;
    RateData[mainIndex].item_margin_rate[subIndex].quantity = +qty || 0;
  };

  const handleVendorSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllAccountIncremental(search));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = async (values: any) => {
    const updatedValue: any = RateData?.map((item: any) => ({
      ...item,
      item_margin_rate: item?.item_margin_rate?.filter(
        (subItem: any) => subItem.quantity > 0
      ),
    }))?.filter((item: any) => item?.item_margin_rate?.length > 0);

    const OpeningStockItem =
      updatedValue && updatedValue.length > 0
        ? updatedValue?.flatMap(
            (rate: any) =>
              rate?.item_margin_rate &&
              rate?.item_margin_rate.length > 0 &&
              rate?.item_margin_rate?.map((item: any) => {
                return {
                  id: item?.id ? +item?.id : 0,
                  item_id: item?.item_id ? +item?.item_id : null,
                  mrp: item?.mrp ? +item?.mrp : null,
                  quantity: item?.quantity ? +item?.quantity : null,
                  unit_id: item?.unit_id ? +item?.unit_id : null,
                  rate: item?.rate ? +item?.rate : null,
                  total_amount: item?.amount ? +item?.amount : null,
                };
              })
          )
        : [];

    if (id) {
      const editPayload: any = {
        id: id ? +id : null,
        accountid: values?.accountid ? +values?.accountid : null,
        date: values?.date ? moment(values?.date).format("YYYY-MM-DD") : "",
        open_stock_list: OpeningStockItem,
      };
      const response = await dispatch(updateOpeningStock(editPayload));
      if (response.meta.requestStatus === "fulfilled") {
        navigate("/distributoropeningstock");
      }
    } else {
      const insertPayload: any = {
        accountid: values?.accountid ? +values?.accountid : null,
        date: values?.date ? moment(values?.date).format("YYYY-MM-DD") : "",
        open_stock_list: OpeningStockItem,
      };
      const response = await dispatch(createOpeningStock(insertPayload));
      if (response.meta.requestStatus === "fulfilled") {
        navigate("/distributoropeningstock");
      }
    }
  };

  return (
    <>
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement style={{ overflow: "scroll", scrollbarWidth: "none" }}>
            <ShadowCard style={{ padding: 12, marginBottom: 10 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>
                    {id ? "Update Opening Stock" : "Add Opening Stock"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="accountid"
                    label="Account Name"
                    // placeholder="Account Name"
                    // isAddNew={true}
                    // addNewLink="account/create"
                    placeholder="Type here to search..."
                    component={FormIncrementalSearch}
                    validator={requiredValidator}
                    astrike={true}
                    disabled={id ? true : false}
                    fetchIncrementalData={(search: any) =>
                      handleVendorSearchChange(
                        search,
                        `vendor_options`,
                        formRenderProps
                      )
                    }
                    options={
                      formRenderProps
                        .valueGetter("vendor_options")
                        ?.map((item: any) => {
                          return {
                            value: item?.id,
                            label: item?.account_name,
                          };
                        }) || []
                    }
                    // options={AccountList?.map((account: any) => {
                    //   return {
                    //     value: account?.id,
                    //     label: account?.account_name,
                    //   };
                    // })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="date"
                    label="Date"
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={3}
                  style={{ overflow: "scroll", scrollbarWidth: "none" }}
                >
                  <div
                    className="container-fluid orderform"
                    style={{ padding: "20px 0 0 0", minWidth: 800 }}
                  >
                    <div className="row">
                      <div className="col-12">
                        <table
                          cellSpacing={1}
                          cellPadding={2}
                          className="table table-bordered w-100  salesorder-print-table"
                          style={{ marginTop: 1 }}
                        >
                          <tr
                            className="text-center fw-600 text-white bg-dark"
                            style={{ fontSize: 12 }}
                          >
                            <th className="pb-3 pt-3" style={{ width: "5%" }}>
                              No.
                            </th>
                            <th style={{ width: "45%" }}>
                              Description Of Goods
                            </th>
                            <th style={{ width: "10%" }}>MRP</th>
                            <th style={{ width: "10%" }}>Rate</th>
                            <th style={{ width: "10%" }}>Unit</th>
                            <th style={{ width: "10%" }}>Opening Stock</th>
                            <th style={{ width: "10%" }}>Amount</th>
                          </tr>
                          {RateData && RateData.length > 0 ? (
                            RateData?.map((item: any, mainIndex: number) => (
                              <tr key={mainIndex}>
                                <td colSpan={7} className="p-0">
                                  <ExpansionPanel
                                    title={item?.item_group}
                                    expanded={expanded === mainIndex}
                                    tabIndex={0}
                                    key={item.id}
                                    onAction={(
                                      event: ExpansionPanelActionEvent
                                    ) => {
                                      setExpanded(
                                        event.expanded ? null : mainIndex
                                      );
                                    }}
                                  >
                                    <Reveal>
                                      {expanded === mainIndex && (
                                        <ExpansionPanelContent>
                                          {item?.item_margin_rate &&
                                            item?.item_margin_rate?.length >
                                              0 &&
                                            item?.item_margin_rate?.map(
                                              (
                                                subitem: any,
                                                subIndex: number
                                              ) => (
                                                <div
                                                  key={subIndex}
                                                  id={`collapse${mainIndex}`}
                                                >
                                                  <div
                                                    className="accordion-body"
                                                    style={{
                                                      padding: "5px 0",
                                                    }}
                                                  >
                                                    <table
                                                      style={{ width: "100%" }}
                                                    >
                                                      <tr>
                                                        <td
                                                          style={{
                                                            width: "5%",
                                                          }}
                                                          className="text-center"
                                                        >
                                                          {subIndex + 1}
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: "45%",
                                                          }}
                                                        >
                                                          {
                                                            subitem?.product_name
                                                          }
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: "10%",
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          Rs.{" "}
                                                          {subitem?.mrp || 0}
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: "10%",
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          Rs.{" "}
                                                          {subitem?.rate || 0}
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: "10%",
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          {subitem?.unit_name}
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: "10%",
                                                          }}
                                                        >
                                                          <Field
                                                            wrapperClassName="right-alighned-field"
                                                            wrapperStyle={{
                                                              margin: 0,
                                                            }}
                                                            name={`qty_${item?.item_group_id}_${subitem?.item_id}`}
                                                            placeholder="0"
                                                            onChange={() =>
                                                              handleQtyChange(
                                                                formRenderProps,
                                                                mainIndex,
                                                                subIndex,
                                                                item?.item_group_id,
                                                                subitem?.item_id
                                                              )
                                                            }
                                                            // format="n2"
                                                            format={
                                                              subitem?.unit_decimal &&
                                                              subitem?.unit_decimal >
                                                                0
                                                                ? `n${subitem?.unit_decimal}`
                                                                : "n0"
                                                            }
                                                            component={
                                                              FormNumericTextField
                                                            }
                                                          />
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: "10%",
                                                            padding: "0 5px",
                                                          }}
                                                        >
                                                          <Field
                                                            wrapperClassName="right-alighned-field orderform-amount"
                                                            wrapperStyle={{
                                                              margin: 0,
                                                            }}
                                                            name={`amount_${item?.item_group_id}_${subitem?.item_id}`}
                                                            placeholder="0"
                                                            format="n2"
                                                            disabled={true}
                                                            component={
                                                              FormNumericTextField
                                                            }
                                                          />
                                                        </td>
                                                      </tr>
                                                    </table>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                        </ExpansionPanelContent>
                                      )}
                                    </Reveal>
                                  </ExpansionPanel>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={7} className="text-center p-2">
                                No Records Found
                              </td>
                            </tr>
                          )}
                        </table>
                      </div>
                      <div
                        className="col-12"
                        style={{
                          textAlign: "end",
                          marginTop: "20px",
                          width: "100%",
                        }}
                      >
                        <ButtonWithLoading
                          label={id ? "Update" : "Save"}
                          type="submit"
                          disabled={!formRenderProps.allowSubmit || loading}
                          loading={loading}
                        />
                        <Button
                          type="button"
                          fillMode={"outline"}
                          themeColor={"primary"}
                          style={{ marginLeft: 4 }}
                          onClick={() => navigate(-1)}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateDistributorOpeningStock;
