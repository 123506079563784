import React from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import Logo from "../../assets/Images/logo.png";
import SRUserProfile from "./SRUserProfile";
import { RxCross1 } from "react-icons/rx";
import { Button } from "@progress/kendo-react-buttons";

const SRLayout: React.FC = () => {
  const navigate = useNavigate();
  const openNav = () => {
    const sidebar = document.querySelector("#mySidebar") as HTMLElement;
    // const main = document.querySelector("#sr-layout") as HTMLElement;
    if (sidebar) sidebar.style.width = "250px";
    // if (main) main.style.marginLeft = "250px";
  };

  const closeNav = () => {
    const sidebar = document.querySelector("#mySidebar") as HTMLElement;
    // const main = document.querySelector("#sr-layout") as HTMLElement;
    if (sidebar) sidebar.style.width = "0";
    // if (main) main.style.marginLeft = "0";
  };

  const handleNavigate = (Route: string) => {
    navigate(`/${Route}`);
    closeNav();
  };

  return (
    <div id="wrapper">
      <div id="mySidebar" className="sidebar">
        <div className="closebtn">
          <img src={Logo} alt="" height={45} style={{ cursor: "pointer" }} />
          <Button
            className="close-icon"
            style={{ background: "transparent" }}
            onClick={closeNav}
          >
            <RxCross1 />
          </Button>
        </div>
        <a
          className="sidebar-item"
          href="javascript:void(0)"
          onClick={() => handleNavigate("")}
        >
          My Task
        </a>
        {/* <a
          className="sidebar-item"
          href="javascript:void(0)"
          onClick={() => handleNavigate("expense")}
        >
          Expenses
        </a> */}
        <a
          className="sidebar-item"
          href="javascript:void(0)"
          onClick={() => handleNavigate("order")}
        >
          Order
        </a>
        <a
          className="sidebar-item"
          href="javascript:void(0)"
          onClick={() => handleNavigate("orderhistory")}
        >
          Order History
        </a>
      </div>

      <div id="sr-layout">
        <div
          id=" container-fluid p-0 sr-layout"
          style={{
            overflowX: "scroll",
            scrollbarWidth: "none",
            background: "#000",
          }}
        >
          <nav className="container navbar navbar-expand-md p-0">
            <div className="container-fluid p-1 p-md-0">
              <div>
                <button className="openbtn" onClick={openNav}>
                  ☰
                </button>
                <Link className="navbar-brand" to="/">
                  <img
                    src={Logo}
                    alt=""
                    height={45}
                    style={{ cursor: "pointer" }}
                  />
                </Link>
              </div>
              <form className="d-md-flex">
                <SRUserProfile />
              </form>
            </div>
          </nav>
        </div>
        <div className="container-fluid">
          <div className="row" style={{ maxWidth: 1140, margin: "auto" }}>
            <div
              className="col-12 p-0"
              style={{ overflow: "scroll", scrollbarWidth: "none" }}
            >
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SRLayout;
