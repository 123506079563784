import React, { useEffect } from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { ErrorToast, SuccessToast } from "../../components/toast/Toasts";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import FormTextField from "../../components/formFields/FormTextField";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import { axiosApiInstance } from "../../app/axios";
import { API_URL } from "../../app/config";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { useLocation } from "react-router-dom";
import { IUnit } from "../unit/unitModel";
import { setInwardDeleteIndex } from "./inwardSlice";
import { IWarehouse } from "../warehouse/warehouseModel";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import { getItemById } from "../Item/services/item.services";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import _ from "lodash";
import { IGst } from "../gst/gstModel";
import { DropDownList } from "@progress/kendo-react-dropdowns";
// import { getUnitById } from "../unit/services/unit.services";

const InwardItemDetailsArray = (
  fieldArrayRenderProps: FieldArrayRenderProps
) => {
  const dispatch = useAppDispatch();
  const InputRef = React.useRef<any>(null);
  const location = useLocation();
  const inward_guid = location.state?.inward_guid;

  const InwardDeleteIndex = useAppSelector(
    (state) => state.inward.InwardDeleteIndex
  );
  const InwardDetail = useAppSelector((state) => state.inward.InwardDetail);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const UnitList = useAppSelector((state) => state.unit.UnitList);
  const warehouseList = useAppSelector(
    (state) => state.warehouse.warehouseList
  );
  const GstList = useAppSelector((state) => state.gst.GstList);

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("inwardItemDeleteDialog"));
    dispatch(setInwardDeleteIndex(index));
  };

  useEffect(() => {
    if (!inward_guid) {
      pushElementInarray();
    }
  }, [inward_guid]);

  const handleDeleteAction = React.useCallback(
    async (index: number) => {
      if (fieldArrayRenderProps?.value[index].id) {
        try {
          const response = await axiosApiInstance.post(
            `${API_URL}/Inward/DeleteInwardItem`,
            { id: fieldArrayRenderProps?.value[index].id }
          );
          fieldArrayRenderProps.onRemove({ index: index });
          dispatch(closeDialog());
          dispatch(setInwardDeleteIndex(-1));
          SuccessToast(response.data?.Details || "Success");
        } catch (error: any) {
          ErrorToast(error?.response?.data?.Details || "Something went wrong");
          console.error("Error deleting Item:", error);
          return Promise.reject({
            message: error?.response?.data?.Details || "Something went wrong",
          });
        }
      } else {
        fieldArrayRenderProps.onRemove({ index: index });
        dispatch(closeDialog());
        dispatch(setInwardDeleteIndex(-1));
      }
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        item_id: null,
        dagina: null,
        weight: null,
        rate: null,
        gst_id: null,
        discount: null,
        discount_type: "₹",
        total: null,
        cold_storage_id: null,
        receipt_no: "",
        unit_id: null,
        igst_per: null,
        igst_amount: null,
        sgst_per: null,
        sgst_amount: null,
        cgst_per: null,
        cgst_amount: null,
        total_gst_amount: null,
      },
    });

    setTimeout(() => {
      InputRef.current?.element?.children[0]?.children[1]?.children[0]?.focus();
    }, 0);
  }, [fieldArrayRenderProps]);

  const fetchItemDetails = async (
    item_id: number,
    index: number,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => {
    if (item_id) {
      const payload = {
        id: item_id,
      };
      const res = (await dispatch(getItemById(payload))) as any;
      fieldArrayRenderProps.formRenderProps.onChange(
        `inward_items.${index}.unit_id`,
        {
          value: res.payload?.unit_id,
        }
      );
      fieldArrayRenderProps.formRenderProps.onChange(
        `inward_items.${index}.unit_decimal`,
        {
          value: res.payload?.unit_decimal,
        }
      );
      fieldArrayRenderProps.formRenderProps.onChange(
        `inward_items.${index}.gst_id`,
        {
          value: `${res.payload?.gst_id}--${res.payload?.gst}`,
        }
      );
    } else {
      fieldArrayRenderProps.formRenderProps.onChange(
        `inward_items.${index}.unit_id`,
        {
          value: null,
        }
      );
      fieldArrayRenderProps.formRenderProps.onChange(
        `inward_items.${index}.unit_decimal`,
        {
          value: null,
        }
      );
      fieldArrayRenderProps.formRenderProps.onChange(
        `inward_items.${index}.gst_id`,
        {
          value: null,
        }
      );
    }
  };

  const handleTotalChange = async (
    total: number,
    index: number,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => {
    const qty = fieldArrayRenderProps.formRenderProps.valueGetter(
      `inward_items.${index}.weight`
    );
    const unit_decimal = fieldArrayRenderProps.formRenderProps.valueGetter(
      `inward_items.${index}.unit_decimal`
    );
    const discount = fieldArrayRenderProps.formRenderProps.valueGetter(
      `inward_items.${index}.discount`
    );
    const discount_type = fieldArrayRenderProps.formRenderProps.valueGetter(
      `inward_items.${index}.discount_type`
    );

    if (discount_type && discount_type === "₹") {
      fieldArrayRenderProps.formRenderProps.onChange(
        `inward_items.${index}.rate`,
        {
          value:
            (total + discount) / qty?.toFixed(unit_decimal ? unit_decimal : 0),
        }
      );
    } else if (discount_type && discount_type === "%") {
      let perc = 100 - (discount < 100 ? discount : 100);

      fieldArrayRenderProps.formRenderProps.onChange(
        `inward_items.${index}.rate`,
        {
          value:
            (total * 100) /
            perc /
            qty?.toFixed(unit_decimal ? unit_decimal : 0),
        }
      );
    } else {
      fieldArrayRenderProps.formRenderProps.onChange(
        `inward_items.${index}.rate`,
        {
          value: total / qty?.toFixed(unit_decimal ? unit_decimal : 0),
        }
      );
    }
  };

  const debouncedHandleProductChange = React.useRef(
    _.debounce((item_id: any, index: number, fieldArrayRenderProps: any) => {
      fetchItemDetails(item_id, index, fieldArrayRenderProps);
    }, 300)
  ).current;

  useEffect(() => {
    return () => {
      debouncedHandleProductChange.cancel();
    };
  }, []);

  const handleChange = (
    item_id: any,
    index: number,
    fieldArrayRenderProps: any
  ) => {
    debouncedHandleProductChange(item_id, index, fieldArrayRenderProps);
  };

  // const handleUnitChange = async (
  //   e: any,
  //   innerIndex: number,
  //   fieldArrayRenderProps: FieldArrayRenderProps
  // ) => {
  //   const inward_items =
  //     fieldArrayRenderProps.formRenderProps.valueGetter("inward_items");
  //   if (e?.value) {
  //     const payload = {
  //       id: e?.value,
  //     };
  //     const response: any = await dispatch(getUnitById(payload));
  //     inward_items &&
  //       inward_items.length > 0 &&
  //       inward_items.map((item: any, index: number) => {
  //         if (innerIndex === index) {
  //           if (response.meta.requestStatus === "fulfilled") {
  //             fieldArrayRenderProps.formRenderProps.onChange(
  //               `inward_items.${index}.unit_decimal`,
  //               {
  //                 value: response.payload?.unit_decimal,
  //               }
  //             );
  //           } else {
  //             fieldArrayRenderProps.formRenderProps.onChange(
  //               `inward_items.${index}.unit_decimal`,
  //               {
  //                 value: null,
  //               }
  //             );
  //           }
  //         }
  //       });
  //   } else {
  //     inward_items &&
  //       inward_items.length > 0 &&
  //       inward_items.map((item: any, index: number) => {
  //         if (innerIndex === index) {
  //           fieldArrayRenderProps.formRenderProps.onChange(
  //             `inward_items.${index}.unit_decimal`,
  //             {
  //               value: null,
  //             }
  //           );
  //         }
  //       });
  //   }
  // };

  return (
    <>
      <GridLayout
        cols={[{ width: "100%" }]}
        style={{ overflowX: "scroll", scrollbarWidth: "thin" }}
      >
        <GridLayoutItem
          className="bg-dark text-white fw-600"
          style={{ width: 2800 }}
        >
          <GridLayout
            style={{ marginRight: "0.1%", padding: 10 }}
            gap={{ rows: 10, cols: 5 }}
            cols={
              inward_guid
                ? [
                    { width: "9%" },
                    { width: "3%" },
                    { width: "4%" },
                    { width: "6%" },
                    { width: "5%" },
                    { width: "7%" },
                    { width: "7%" },
                    { width: "7%" },
                    { width: "3%" },
                    { width: "5%" },
                    { width: "3%" },
                    { width: "5%" },
                    { width: "3%" },
                    { width: "5%" },
                    { width: "5%" },
                    { width: "6%" },
                    { width: "7%" },
                    { width: "5%" },
                    { width: "2%" },
                  ]
                : [
                    { width: "11%" },
                    { width: "4%" },
                    { width: "6%" },
                    { width: "5%" },
                    { width: "7%" },
                    { width: "7%" },
                    { width: "7%" },
                    { width: "3%" },
                    { width: "5%" },
                    { width: "3%" },
                    { width: "5%" },
                    { width: "3%" },
                    { width: "5%" },
                    { width: "5%" },
                    { width: "7%" },
                    { width: "7%" },
                    { width: "5%" },
                    { width: "2%" },
                  ]
            }
          >
            <GridLayoutItem>Item Name</GridLayoutItem>
            {inward_guid && <GridLayoutItem>GRN No</GridLayoutItem>}
            <GridLayoutItem>Dagina</GridLayoutItem>
            <GridLayoutItem>Qty (Weight)</GridLayoutItem>
            <GridLayoutItem>Rate</GridLayoutItem>
            <GridLayoutItem>Unit</GridLayoutItem>
            <GridLayoutItem>Discount</GridLayoutItem>
            <GridLayoutItem>GST</GridLayoutItem>

            <GridLayoutItem>CGST %</GridLayoutItem>
            <GridLayoutItem>CGST</GridLayoutItem>
            <GridLayoutItem>SGST %</GridLayoutItem>
            <GridLayoutItem>SGST</GridLayoutItem>
            <GridLayoutItem>IGST %</GridLayoutItem>
            <GridLayoutItem>IGST</GridLayoutItem>
            <GridLayoutItem>Total GST</GridLayoutItem>

            <GridLayoutItem>Amount</GridLayoutItem>
            <GridLayoutItem>Warehouse</GridLayoutItem>
            <GridLayoutItem>Warehouse Receipt No.</GridLayoutItem>
            <GridLayoutItem></GridLayoutItem>
          </GridLayout>
        </GridLayoutItem>
        {dialogName === "inwardItemDeleteDialog" && (
          <AppDialog>
            <>
              <Typography.h5>{"Delete Item"}</Typography.h5>
              <GridLayout>
                <GridLayoutItem>
                  <Typography.p>
                    Are you sure you want to delete this?
                  </Typography.p>
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <DialogCloseButton themeColor="error" />
                  <Button
                    fillMode={"solid"}
                    themeColor={"error"}
                    onClick={() => handleDeleteAction(InwardDeleteIndex)}
                    type="button"
                  >
                    Delete
                  </Button>
                </GridLayoutItem>
              </GridLayout>
            </>
          </AppDialog>
        )}
        {fieldArrayRenderProps.value &&
          fieldArrayRenderProps.value.length > 0 &&
          fieldArrayRenderProps.value.map(
            (inward_items: any, index: number) => (
              <GridLayoutItem key={index} style={{ padding: 10, width: 2800 }}>
                {/* <Card className="fieldarray-card" style={{ padding: 10 }}> */}
                <GridLayout
                  style={{ marginRight: "0.1%" }}
                  gap={{ rows: 10, cols: 5 }}
                  cols={
                    inward_guid
                      ? [
                          { width: "9%" },
                          { width: "3%" },
                          { width: "4%" },
                          { width: "6%" },
                          { width: "5%" },
                          { width: "7%" },
                          { width: "7%" },
                          { width: "7%" },
                          { width: "3%" },
                          { width: "5%" },
                          { width: "3%" },
                          { width: "5%" },
                          { width: "3%" },
                          { width: "5%" },
                          { width: "5%" },
                          { width: "6%" },
                          { width: "7%" },
                          { width: "5%" },
                          { width: "2%" },
                        ]
                      : [
                          { width: "11%" },
                          { width: "4%" },
                          { width: "6%" },
                          { width: "5%" },
                          { width: "7%" },
                          { width: "7%" },
                          { width: "7%" },
                          { width: "3%" },
                          { width: "5%" },
                          { width: "3%" },
                          { width: "5%" },
                          { width: "3%" },
                          { width: "5%" },
                          { width: "5%" },
                          { width: "7%" },
                          { width: "7%" },
                          { width: "5%" },
                          { width: "2%" },
                        ]
                  }
                >
                  <GridLayoutItem ref={InputRef}>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`inward_items.${index}.item_id`}
                      placeholder="Item"
                      isAddNew={true}
                      addNewLink="item/create"
                      component={FormIncrementalSearch}
                      validator={requiredValidator}
                      onChange={(e) => {
                        handleChange(e?.value, index, fieldArrayRenderProps);
                        // fetchItemDetails(e?.value, index, fieldArrayRenderProps);
                      }}
                      fetchIncrementalData={(search: any) =>
                        fieldArrayRenderProps.handleItemSearchChange(
                          search,
                          `inward_items.${index}.item_options`,
                          fieldArrayRenderProps.formRenderProps
                        )
                      }
                      options={
                        fieldArrayRenderProps.value[index]?.item_options?.map(
                          (item: any) => {
                            return {
                              value: item.id,
                              label: item.product_name,
                            };
                          }
                        ) || []
                      }
                    />
                  </GridLayoutItem>
                  {inward_guid && (
                    <GridLayoutItem>
                      <Field
                        wrapperStyle={{ margin: 0 }}
                        name={`inward_items.${index}.grn_no_string`}
                        placeholder="GRN No"
                        // type="number"
                        // minNumber="0"
                        disabled={true}
                        component={FormTextField}
                      />
                    </GridLayoutItem>
                  )}
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`inward_items.${index}.dagina`}
                      placeholder="0"
                      type="number"
                      minNumber="0"
                      validator={requiredValidator}
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`inward_items.${index}.weight`}
                      placeholder="0"
                      format={
                        fieldArrayRenderProps.value[index]?.unit_decimal &&
                        fieldArrayRenderProps.value[index]?.unit_decimal > 0
                          ? `n${fieldArrayRenderProps.value[index]?.unit_decimal}`
                          : "n0"
                      }
                      validator={requiredValidator}
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`inward_items.${index}.rate`}
                      placeholder="0"
                      format="n4"
                      minNumber="0"
                      validator={requiredValidator}
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`inward_items.${index}.unit_id`}
                      placeholder="Unit"
                      component={FormSelectionField}
                      validator={requiredValidator}
                      // onChange={(e) =>
                      //   handleUnitChange(e, index, fieldArrayRenderProps)
                      // }
                      options={UnitList?.map((unit: IUnit) => {
                        return {
                          value: unit?.id,
                          label: unit?.unit_code,
                        };
                      })}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem style={{ position: "relative" }}>
                    <Field
                      // wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`inward_items.${index}.discount`}
                      placeholder="0 %"
                      min="0"
                      component={FormNumericTextField}
                    />
                    <DropDownList
                      style={{
                        position: "absolute",
                        top: 1,
                        right: 1,
                        width: "32%",
                        zIndex: 1,
                      }}
                      onChange={(e) => {
                        fieldArrayRenderProps?.formRenderProps?.onChange(
                          `inward_items.${index}.discount_type`,
                          {
                            value: e.value,
                          }
                        );
                      }}
                      name={`inward_items.${index}.discount_type`}
                      data={["₹", "%"]}
                      defaultValue={
                        inward_guid
                          ? InwardDetail &&
                            InwardDetail?.inward_items.length > 0 &&
                            InwardDetail?.inward_items[index]?.discount_type ===
                              "₹"
                            ? "₹"
                            : InwardDetail?.inward_items[index]
                                ?.discount_type === "%"
                            ? "%"
                            : "₹"
                          : fieldArrayRenderProps.value[index].discount_type
                          ? fieldArrayRenderProps.value[index].discount_type
                          : "₹"
                      }
                    />
                  </GridLayoutItem>

                  <GridLayoutItem>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`inward_items.${index}.gst_id`}
                      placeholder="GST (%)"
                      component={FormSelectionField}
                      validator={requiredValidator}
                      options={GstList?.map((gst: IGst) => {
                        return {
                          value: `${gst?.id}--${gst?.gst}`,
                          label: gst?.gst_title,
                        };
                      })}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`inward_items.${index}.cgst_per`}
                      placeholder="0"
                      min="0"
                      disabled
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`inward_items.${index}.cgst_amount`}
                      placeholder="0"
                      min="0"
                      disabled
                      format="n2"
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`inward_items.${index}.sgst_per`}
                      placeholder="0"
                      min="0"
                      disabled
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`inward_items.${index}.sgst_amount`}
                      placeholder="0"
                      min="0"
                      disabled
                      format="n2"
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`inward_items.${index}.igst_per`}
                      placeholder="0"
                      min="0"
                      disabled
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`inward_items.${index}.igst_amount`}
                      placeholder="0"
                      min="0"
                      disabled
                      format="n2"
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`inward_items.${index}.total_gst_amount`}
                      placeholder="0"
                      min="0"
                      disabled
                      format="n2"
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem>
                    <Field
                      wrapperClassName="child-calculation-total right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`inward_items.${index}.total`}
                      placeholder="0"
                      // disabled="true"
                      format="n2"
                      onChange={(e) =>
                        handleTotalChange(
                          e?.value,
                          index,
                          fieldArrayRenderProps
                        )
                      }
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`inward_items.${index}.cold_storage_id`}
                      placeholder="Warehouse"
                      component={FormSelectionField}
                      disabled={inward_guid && true}
                      validator={requiredValidator}
                      options={warehouseList?.map((warehouse: IWarehouse) => {
                        return {
                          value: warehouse?.id,
                          label: warehouse?.warehouse_name,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`inward_items.${index}.receipt_no`}
                      placeholder="0"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem style={{ position: "relative" }}>
                    <IconButton
                      onClick={() => handleOpenDeleteDialog(index)}
                      disabled={fieldArrayRenderProps.value.length === 1}
                      themeColor={"error"}
                      size={"small"}
                      fillMode={"solid"}
                      type="button"
                      style={{
                        position: "absolute",
                        height: 32,
                        width: 32,
                        top: 1,
                        right: 0,
                      }}
                    >
                      <MdDelete
                        className="absolute-position"
                        style={{ fontSize: "16px" }}
                      />
                    </IconButton>
                  </GridLayoutItem>
                </GridLayout>
                {/* </Card> */}
              </GridLayoutItem>
            )
          )}
      </GridLayout>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{ marginTop: 10, borderTop: "1px solid lightgray" }}
        >
          <GridLayout
            style={{ marginRight: 10, padding: 10 }}
            gap={{ rows: 10, cols: 5 }}
            cols={[{ width: "30%" }, { width: "50%" }, { width: "20%" }]}
          >
            <GridLayoutItem className="d-flex align-items-end">
              <Button
                fillMode={"solid"}
                themeColor={"primary"}
                onClick={pushElementInarray}
                type="button"
              >
                Add Row
              </Button>
            </GridLayoutItem>
            {/* <GridLayoutItem
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                fontWeight: 600,
                fontSize: 17,
              }}
            >
              Total Amount :
            </GridLayoutItem>
            <GridLayoutItem>
              <Field
                wrapperClassName="parent-calculation-label right-alighned-field"
                wrapperStyle={{ margin: 0 }}
                name="total_amount"
                format="n2"
                component={FormNumericTextField}
                disabled="true"
              />
            </GridLayoutItem> */}
          </GridLayout>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default InwardItemDetailsArray;
