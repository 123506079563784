import React, { useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import ShadowCard from "../../components/common/ShadowCard";
import FormDatePicker from "../../components/formFields/FormDateField";
import { FINANCIAL_YEAR } from "../../_contstants/common";
import moment from "moment";
import CreditnoteVoucherEntryDetailsArray from "./CreditnoteVoucherEntryDetailsArray";
import {
  createCreditnoteVoucher,
  generateCreditnoteVoucherNoForCreditnoteVoucher,
  getCreditnoteVoucherByID,
  updateCreditnoteVoucher,
} from "./services/creditnoteVoucher";
import {
  clearCreditnoteVoucherDetails,
  setCreditnoteVoucherBillDialogOpenIndex,
} from "./creditnoteVoucherSlice";
import CreditnoteVoucherBillDetailsArray from "./CreditnoteVoucherBillDetailsArray";
import { getAllAccountIncremental } from "../account/services/account.services";
import FormTextArea from "../../components/formFields/FormTextArea";
import { ErrorToast } from "../../components/toast/Toasts";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { findAllVoucherTypeByType } from "../vouchertype/services/voucherType.services";
import { findIndex } from "lodash";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const BillCreditnoteVoucherEntryChangeWatcher: React.FC<
  FormChangeWatcherProps
> = ({ formRenderProps }) => {
  const credit_note_voucher_bill_entry =
    formRenderProps.valueGetter("credit_note_voucher_bill_entry") || [];
  const credit_note_voucher_entry = formRenderProps.valueGetter(
    "credit_note_voucher_entry"
  );
  const CreditnoteVoucherBillDialogOpenIndex = useAppSelector(
    (state) => state.creditnotevoucher.CreditnoteVoucherBillDialogOpenIndex
  );
  const OutstandingCreditnoteVoucherList = useAppSelector(
    (state) => state.creditnotevoucher.OutstandingCreditnoteVoucherList
  );
  const location = useLocation();
  const credit_note_voucher_guid = location.state?.credit_note_voucher_guid;

  useEffect(() => {
    const entryReference =
      credit_note_voucher_entry?.[CreditnoteVoucherBillDialogOpenIndex]
        ?.credit_note_voucher_entry_referance;
    if (
      CreditnoteVoucherBillDialogOpenIndex >= 0 &&
      entryReference?.length > 0
    ) {
      const debitnoteVoucherRefID =
        entryReference?.[0]?.credit_note_voucher_ref_id;
      if (credit_note_voucher_guid && debitnoteVoucherRefID) {
        //=== THIS IS FOR OLD BILL ENTRY FIRST TIME TAB IN EDIT MODE ===//
        const referenceMap = new Map<
          number,
          { refernace_name: string; referance_amount: number }
        >(
          entryReference.map((entry: any) => [
            entry.refernace_bill_id,
            {
              refernace_name: entry.refernace_name,
              referance_amount: entry.referance_amount,
            },
          ])
        );

        // Merge with OutstandingCreditnoteVoucherList
        const mergedArray = OutstandingCreditnoteVoucherList.map(
          (bill: any) => {
            const match = referenceMap.get(bill?.id);
            const isFullPayment = !bill.final_pending_amount;
            return match && match.refernace_name === bill.bill_no_string
              ? {
                  ...bill,
                  referance_amount: match.referance_amount,
                  isfullpayment: isFullPayment,
                }
              : { ...bill }; // Return unchanged if no match
          }
        );
        formRenderProps.onChange("credit_note_voucher_bill_entry", {
          value: mergedArray || [],
        });
      } else {
        formRenderProps.onChange("credit_note_voucher_bill_entry", {
          value: entryReference || [],
        });
      }
    } else {
      // Use structuredClone for deep copy (modern alternative to JSON trick)
      const clonedOutstandingList = structuredClone(
        OutstandingCreditnoteVoucherList
      );
      formRenderProps.onChange("credit_note_voucher_bill_entry", {
        value: clonedOutstandingList,
      });
    }
  }, [
    CreditnoteVoucherBillDialogOpenIndex,
    OutstandingCreditnoteVoucherList?.[0]?.id,
  ]);

  useEffect(() => {
    const calculateTotals = (entries: typeof credit_note_voucher_entry) => {
      const totals: Record<string, number> = {};
      entries.forEach((entry: any) => {
        if (entry?.ledger_id) {
          const key = `${entry.debit_credit}Total`;
          totals[key] = (totals[key] || 0) + entry.amount;
        }
      });
      return totals;
    };
    const result = calculateTotals(credit_note_voucher_entry);
    formRenderProps.onChange(`credit_note_voucher_total_amount`, {
      value: result.CreditTotal || 0,
    });
    if (credit_note_voucher_entry && credit_note_voucher_entry?.length > 0) {
      formRenderProps.onChange(`credit_debit_total`, {
        value: {
          DebitTotal: result?.DebitTotal || 0,
          CreditTotal: result?.CreditTotal || 0,
        },
      });
    }
  }, [
    credit_note_voucher_entry?.map((item: any) => item?.amount).join("-"),
    credit_note_voucher_entry?.map((item: any) => item?.debit_credit).join("-"),
    credit_note_voucher_entry?.map((item: any) => item?.ledger_id).join("-"),
  ]);

  useEffect(() => {
    if (CreditnoteVoucherBillDialogOpenIndex < 0) return;
    const selectedCreditnoteVoucherEntry =
      credit_note_voucher_entry[CreditnoteVoucherBillDialogOpenIndex];
    if (!selectedCreditnoteVoucherEntry) return;
    const totalReferencedAmount = credit_note_voucher_bill_entry.reduce(
      (sum: number, item: any) => sum + (item?.referance_amount || 0),
      0
    );

    const remainingAmount =
      (selectedCreditnoteVoucherEntry.amount ?? 0) - totalReferencedAmount;

    const ledgerName = selectedCreditnoteVoucherEntry.ledger_options?.find(
      (account: any) => account?.id === selectedCreditnoteVoucherEntry.ledger_id
    )?.account_name;

    const updatedCreditnoteVoucherEntry = {
      ...selectedCreditnoteVoucherEntry,
      credit_note_voucher_entry_referance: [...credit_note_voucher_bill_entry],
    };

    const updatedIndexDetails = {
      ...updatedCreditnoteVoucherEntry,
      remainingAmount,
      ledger_name: ledgerName,
    };
    formRenderProps.onChange("IndexDetails", { value: updatedIndexDetails });

    credit_note_voucher_entry[CreditnoteVoucherBillDialogOpenIndex] =
      updatedCreditnoteVoucherEntry;
  }, [
    CreditnoteVoucherBillDialogOpenIndex,
    credit_note_voucher_entry?.map((item: any) => item?.amount).join("-"),
    credit_note_voucher_bill_entry
      ?.map((item: any) => item?.referance_amount)
      .join("-"),
  ]);

  return null;
};

const CreditnoteVoucherNoChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const credit_note_voucher_guid = location.state?.credit_note_voucher_guid;
  // const isCreditnoteVoucherNoRef = useRef(false);
  const voucher_type_id = formRenderProps.valueGetter("voucher_type_id");
  const credit_note_voucher_no_string = formRenderProps.valueGetter(
    "credit_note_voucher_no_string"
  );
  const VoucherTypeList = useAppSelector(
    (state) => state.voucherType.VoucherTypeList
  );
  const CreditnoteVoucherDetail = useAppSelector(
    (state) => state.creditnotevoucher.CreditnoteVoucherDetail
  );

  useEffect(() => {
    const fetchCreditnoteVoucherNo = async () => {
      const payload = {
        financial_year: FINANCIAL_YEAR,
        voucher_type_id: +voucher_type_id,
      };
      const response = await dispatch(
        generateCreditnoteVoucherNoForCreditnoteVoucher(payload)
      );
      if (response.meta.requestStatus === "fulfilled") {
        formRenderProps.onChange("credit_note_voucher_no_string", {
          value: response.payload?.credit_note_voucher_no_string || "",
        });
        formRenderProps.onChange("credit_note_voucher_no", {
          value: response.payload?.credit_note_voucher_no || "",
        });
        const ledgerIDIndex = findIndex(VoucherTypeList, {
          id: voucher_type_id,
        });
        if (ledgerIDIndex > -1) {
          formRenderProps.onChange("ledger_id", {
            value: VoucherTypeList?.[ledgerIDIndex]?.map_ledger_id,
          });
        } else {
          formRenderProps.onChange("ledger_id", {
            value: null,
          });
        }
      }
    };
    if (voucher_type_id && !credit_note_voucher_guid) {
      fetchCreditnoteVoucherNo();
    }
  }, [voucher_type_id]);

  useEffect(() => {
    const fetchCreditnoteVoucherNo = async () => {
      const oldkey = CreditnoteVoucherDetail?.credit_note_voucher_no_string
        ?.split("/")
        ?.slice(0, -1)
        .join("/");
      if (
        credit_note_voucher_no_string?.split("/").length ===
        CreditnoteVoucherDetail?.credit_note_voucher_no_string?.split("/")
          .length
      ) {
        const modifiyed = credit_note_voucher_no_string?.split("/")?.pop();
        formRenderProps.onChange("credit_note_voucher_no_string", {
          value: `${oldkey}/${modifiyed}`,
        });
        formRenderProps.onChange("credit_note_voucher_no", {
          value: `${modifiyed}`,
        });
      } else {
        formRenderProps.onChange("credit_note_voucher_no_string", {
          value: `${oldkey}/`,
        });
      }
    };
    if (credit_note_voucher_guid) {
      fetchCreditnoteVoucherNo();
    }
  }, [credit_note_voucher_no_string]);

  return null;
};

const CreateCreditnoteVoucher = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const credit_note_voucher_guid = location.state?.credit_note_voucher_guid;
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.creditnotevoucher.loading);
  const CreditnoteVoucherDetail = useAppSelector(
    (state) => state.creditnotevoucher.CreditnoteVoucherDetail
  );

  const VoucherTypeList = useAppSelector(
    (state) => state.voucherType.VoucherTypeList
  );

  const [formKey, setFormKey] = React.useState(1);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [CreditnoteVoucherDetail]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" || event.key === "Esc") {
        dispatch(setCreditnoteVoucherBillDialogOpenIndex(-1));
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (credit_note_voucher_guid) {
      // const payload = {
      //   credit_note_voucher_guid: credit_note_voucher_guid,
      // };
      dispatch(getCreditnoteVoucherByID(credit_note_voucher_guid));
    }
  }, [credit_note_voucher_guid]);

  useEffect(() => {
    // const payload = {
    //   account_name: "",
    //   under_group_id: "16,17,18",
    // };

    // dispatch(getAllCreditnoteVoucherAndReceipt(payload));
    dispatch(findAllVoucherTypeByType(7));

    return () => {
      dispatch(clearCreditnoteVoucherDetails());
    };
  }, []);

  const handleVendorSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllAccountIncremental(search));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = async (values: any) => {
    if (
      values?.credit_debit_total?.DebitTotal === 0 &&
      values?.credit_debit_total?.CreditTotal === 0
    ) {
      ErrorToast("Atleast One Entry is Required!");
      return;
    }
    if (values?.credit_note_voucher_no_string?.endsWith("/")) {
      ErrorToast("Please Enter Voucher Number.");
      return;
    }
    if (
      values?.credit_debit_total?.DebitTotal !==
      values?.credit_debit_total?.CreditTotal
    ) {
      if (
        values?.credit_debit_total?.DebitTotal >
        values?.credit_debit_total?.CreditTotal
      ) {
        ErrorToast("Debit Amount is Greater than Credit Amount");
      } else {
        ErrorToast("Credit Amount is Greater than Debit Amount");
      }
      return;
    }
    const formdata: any = {
      financial_year: values?.financial_year
        ? +values?.financial_year
        : FINANCIAL_YEAR,
      credit_note_voucher_date: values?.credit_note_voucher_date
        ? moment(values?.credit_note_voucher_date).format("YYYY-MM-DD")
        : "",
      credit_note_voucher_total_amount:
        values?.credit_note_voucher_total_amount,
      remarks: values?.remarks,
      //   ledger_id:values?.ledger_id ? values?.ledger_id : null,
      credit_note_voucher_no: values?.credit_note_voucher_no
        ? values?.credit_note_voucher_no
        : null,
      voucher_type_id: values?.voucher_type_id ? values?.voucher_type_id : null,
      credit_note_voucher_entry: values?.credit_note_voucher_entry
        ?.filter((entry: any) => entry?.ledger_id)
        ?.map((entry: any) => ({
          debit_credit: entry?.debit_credit,
          ledger_id: entry?.ledger_id,
          amount: entry?.amount,
          credit_note_voucher_entry_referance:
            entry?.credit_note_voucher_entry_referance
              ?.filter((ref: any) => ref?.referance_amount)
              ?.map((pay_ref: any, ind: number) => {
                if (pay_ref?.id) {
                  return {
                    sr_no: pay_ref?.sr_no ? pay_ref?.sr_no : ind + 1,
                    referance_type: entry?.debit_credit,
                    refernace_name: pay_ref?.bill_no_string,
                    refernace_bill_id: pay_ref?.id,
                    referance_amount: pay_ref?.referance_amount,
                  };
                }
                return pay_ref;
              }),
        })),
    };

    if (credit_note_voucher_guid) {
      try {
        const updatePayload: any = {
          credit_note_voucher_guid: credit_note_voucher_guid,
          id: values?.id ? +values?.id : null,
          credit_note_voucher_no: values?.credit_note_voucher_no
            ? values?.credit_note_voucher_no
            : null,
          credit_note_voucher_no_string: values?.credit_note_voucher_no_string
            ? values?.credit_note_voucher_no_string
            : "",
          ...formdata,
        };
        const response = await dispatch(updateCreditnoteVoucher(updatePayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/creditnotevoucher");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      try {
        const response = await dispatch(createCreditnoteVoucher(formdata));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/creditnotevoucher");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={CreditnoteVoucherDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>
                    {credit_note_voucher_guid
                      ? "Update Credit Note Voucher"
                      : "Add Credit Note Voucher"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    label="Voucher Type"
                    name="voucher_type_id"
                    placeholder="Select Voucher Type"
                    disabled={credit_note_voucher_guid && true}
                    component={FormSelectionField}
                    options={VoucherTypeList.map((voucher: any) => {
                      return {
                        value: voucher?.id,
                        label: voucher?.name,
                      };
                    })}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="credit_note_voucher_no_string"
                    label="Voucher No"
                    disabled={credit_note_voucher_guid ? false : true}
                    placeholder="Voucher No"
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>

                <GridLayoutItem>
                  <Field
                    name="credit_note_voucher_date"
                    label="Voucher Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <CreditnoteVoucherNoChangeWatcher
                  formRenderProps={formRenderProps}
                />
                <BillCreditnoteVoucherEntryChangeWatcher
                  formRenderProps={formRenderProps}
                />
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>Particulars</Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem colSpan={4}>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    handleVendorSearchChange={handleVendorSearchChange}
                    component={CreditnoteVoucherEntryDetailsArray}
                    name="credit_note_voucher_entry"
                  />
                  {dialogName === "CreditnoteVoucherBillItemDetailsDialog" && (
                    <FieldArray
                      formRenderProps={formRenderProps}
                      component={CreditnoteVoucherBillDetailsArray}
                      name="credit_note_voucher_bill_entry"
                    />
                  )}
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="remarks"
                    label="Remarks"
                    rows={2}
                    component={FormTextArea}
                    // validator={requiredValidator}
                    // astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={4}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    marginTop: 15,
                  }}
                >
                  <div>
                    <ButtonWithLoading
                      label={credit_note_voucher_guid ? "Update" : "Save"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                    <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginLeft: 4 }}
                      onClick={
                        () => navigate("/creditnotevoucher")
                        // navigate(-1)
                      }
                    >
                      Cancel
                    </Button>
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateCreditnoteVoucher;
