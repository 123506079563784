import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import moment from "moment";

export const getAllContra = createAsyncThunk(
  "Contra/ContraFindAll",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Contra/ContraFindAll`
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error While Fetch Contra Entries:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createContra = createAsyncThunk(
  "Contra/InsertContra",
  async (fomedata: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Contra/InsertContra`,
        fomedata
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating Contra Entry:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateContra = createAsyncThunk(
  "Contra/UpdateContra",
  async (formdata: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Contra/UpdateContra`,
        formdata
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Contra Entry:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteContra = createAsyncThunk(
  "Contra/DeleteContra",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Contra/DeleteContra`,
        { contra_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Contra Entry:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getContraByID = createAsyncThunk(
  "Contra/FindByIDContra",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Contra/FindByIDContra`,
        formData
      );
      const result = response.data?.Data as any;
      return {
        id: result?.id,
        contra_guid: result?.contra_guid,
        contra_no: result?.contra_no,
        contra_no_string: result?.contra_no_string,
        financial_year: result?.financial_year,
        contra_date: result?.contra_date
          ? moment(result?.contra_date, "YYYY-MM-DD").toDate()
          : "",
        contra_total_amount: result?.contra_total_amount,
        remarks: result?.remarks,
        ledger_id: result?.ledger_id,
        voucher_type_id: result?.voucher_type_id,
        contra_entry: result?.contra_entry,
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Contra Entry details:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllOutstandingContra = createAsyncThunk(
  "Contra/OutstandingContraFindAll",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Contra/OutstandingContraFindAll`,
        formData
      );
      const result = response.data?.Data as any;
      return result as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Outstanding Contra Entries:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const generateContraNoForContra = createAsyncThunk(
  "Contra/InvoiceNumberContra",
  async (payload: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Contra/InvoiceNumberContra`,
        payload
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error generating contra no. :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
