import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import moment from "moment";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";

export const getAllProductMiliing = createAsyncThunk(
  "ProductMilling/FindAllProductMilling",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ProductMilling/FindAllProductMilling`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Product Milling:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createProductMiliing = createAsyncThunk(
  "ProductMilling/InsertProductMilling",
  async (ProductMilling: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ProductMilling/InsertProductMilling`,
        ProductMilling
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating Product Milling:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateProductMiliing = createAsyncThunk(
  "ProductMilling/UpdateProductMilling",
  async (ProductMilling: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ProductMilling/UpdateProductMilling`,
        ProductMilling
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Product Milling:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteProductMiliing = createAsyncThunk(
  "ProductMilling/DeleteProductMilling",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ProductMilling/DeleteProductMilling`,
        { product_milling_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Product Milling:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getProductMiliingByID = createAsyncThunk(
  "ProductMilling/FindByIDProductMilling",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ProductMilling/FindByIDProductMilling`,
        formData
      );
      const result = response.data?.Data as any;
      return {
        id: result.id,
        product_milling_guid: result.product_milling_guid,
        milling_date: result.milling_date
          ? moment(result.milling_date, "YYYY-MM-DD").toDate()
          : "",
        item_id: result.item_id ? +result.item_id : null,
        item_options: result.item_options ? result.item_options : [],
        grn_no: result.grn_no ? result.grn_no : null,
        no_of_workers: result.no_of_workers ? +result.no_of_workers : null,
        machine_id: result.machine_id ? +result.machine_id : null,
        start_time: result.start_time
          ? moment(result?.start_time, "HH:mm:ss").toDate()
          : "",
        end_time: result.end_time
          ? moment(result?.end_time, "HH:mm:ss").toDate()
          : "",
        total_qty: result.total_qty ? +result.total_qty : null,
        received_qty: result.received_qty ? +result.received_qty : null,
        production_loss_qty: result.production_loss_qty
          ? +result.production_loss_qty
          : null,
        lot_no: result.lot_no ? result.lot_no : "",
        milling_no: result.milling_no ? +result.milling_no : null,
        katta: result.katta ? +result.katta : null,
        checker_by: result.checker_by ? +result.checker_by : null,
        financial_year: result.financial_year ? +result.financial_year : null,
        // department_id: result.department_id ? +result.department_id : null,
        previous_process_id: result.previous_process_id
          ? +result.previous_process_id
          : null,
        process_id: result.process_id ? +result.process_id : null,
        remarks: result.remarks,
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Product Milling:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createProductMiliingNew = createAsyncThunk(
  "ProductMilling/InsertProductMillingNew",
  async (ProductMilling: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ProductMilling/InsertProductMillingNew`,
        ProductMilling
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating Product Milling:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateProductMiliingNew = createAsyncThunk(
  "ProductMilling/UpdateProductMillingNew",
  async (ProductMilling: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ProductMilling/UpdateProductMillingNew`,
        ProductMilling
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Product Milling:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getProductMiliingByIDNew = createAsyncThunk(
  "ProductMilling/FindByIDProductMillingNew",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ProductMilling/FindByIDProductMillingNew`,
        formData
      );
      const result = response.data?.Data as any;
      return {
        id: result.id,
        product_milling_guid: result.product_milling_guid,
        milling_date: result.milling_date
          ? moment(result.milling_date, "YYYY-MM-DD").toDate()
          : "",
        ready_product_id: result.ready_product_id,
        // item_id: result.item_id,
        item_options: result.item_options ? result.item_options : [],
        // grn_no: result.grn_no,
        no_of_workers: result.no_of_workers,
        machine_id: result.machine_id,
        start_time: result.start_time
          ? moment(result?.start_time, "HH:mm:ss").toDate()
          : "",
        end_time: result.end_time
          ? moment(result?.end_time, "HH:mm:ss").toDate()
          : "",
        total_qty: result.total_qty,
        received_qty: result.received_qty,
        production_loss_qty: result.production_loss_qty,
        milling_loss_percentage: result.milling_loss_percentage,
        lot_no: result.lot_no ? result.lot_no : "",
        milling_no: result.milling_no,
        katta: result.katta,
        checker_by: result.checker_by,
        financial_year: result.financial_year,
        remarks: result.remarks,
        product_milling_items: result?.product_milling_items
          ? result?.product_milling_items?.map((pmitem: any) => {
              return {
                id: pmitem?.id ? +pmitem?.id : null,
                process_id: pmitem?.process_id ? +pmitem?.process_id : null,

                item_id: pmitem?.item_id ? +pmitem?.item_id : null,
                grn_no: pmitem?.grn_no ? pmitem?.grn_no : "",
                process_name: pmitem?.process_name ? pmitem?.process_name : "",
                product_name: pmitem?.product_name ? pmitem?.product_name : "",
                taken_qty: pmitem?.taken_qty ? +pmitem?.taken_qty : null,
                wastage_qty: pmitem?.wastage_qty ? +pmitem?.wastage_qty : null,
                loss_percentage: pmitem?.loss_percentage
                  ? +pmitem?.loss_percentage
                  : null,
                item_options: pmitem?.item_options ? pmitem?.item_options : [],
              };
            })
          : [],
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Product Milling:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const generateLotNoForMilling = createAsyncThunk(
  "ProductMilling/LotNumberProductMilling",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ProductMilling/LotNumberProductMilling`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error generating lot no :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
