import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createContra,
  deleteContra,
  getAllOutstandingContra,
  getAllContra,
  getContraByID,
  updateContra,
} from "./services/contra.services";
import { IContra, IContraInitialState } from "./contraModel";
import moment from "moment";

const initialState: IContraInitialState = {
  loading: false,
  error: "",
  ContraEntryDeleteIndex: -1,
  ContraBillDialogOpenIndex: -1,

  ContraID: null,
  ContraGUID: "",
  ContraList: [],
  OutstandingContraList: [],
  ContraDetail: {
    financial_year: null,
    contra_date: moment().toDate(),
    contra_total_amount: null,
    contra_no_string: "",
    remarks: "",
    ledger_id: null,
    voucher_type_id: null,
    contra_entry: [],
  },
};

const ContraSlice = createSlice({
  name: "contra",
  initialState,
  reducers: {
    setContraID: (state, action) => {
      state.ContraID = action.payload;
    },
    setContraGUID: (state, action) => {
      state.ContraGUID = action.payload;
    },
    clearContraDetails: (state) => {
      state.ContraDetail = initialState.ContraDetail;
    },
    clearOutstandingContraList: (state) => {
      state.OutstandingContraList = initialState.OutstandingContraList;
    },
    setContraEntryDeleteIndex: (state, action) => {
      state.ContraEntryDeleteIndex = action.payload;
    },
    setContraBillDialogOpenIndex: (state, action) => {
      state.ContraBillDialogOpenIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllContra.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllContra.fulfilled,
      (state, action: PayloadAction<IContra[]>) => {
        state.loading = false;
        state.ContraList = action.payload || [];
      }
    );
    builder.addCase(getAllContra.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ContraList = [];
    });

    builder.addCase(createContra.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createContra.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createContra.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateContra.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateContra.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateContra.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteContra.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteContra.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteContra.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getContraByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getContraByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.ContraDetail = action.payload;
    });
    builder.addCase(getContraByID.rejected, (state, action) => {
      state.loading = false;
      state.ContraDetail = initialState.ContraDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getAllOutstandingContra.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getAllOutstandingContra.fulfilled, (state, action: any) => {
      state.loading = false;
      state.OutstandingContraList = action.payload;
    });
    builder.addCase(getAllOutstandingContra.rejected, (state, action) => {
      state.loading = false;
      state.OutstandingContraList = initialState.OutstandingContraList;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearContraDetails,
  setContraEntryDeleteIndex,
  setContraID,
  setContraBillDialogOpenIndex,
  setContraGUID,

  clearOutstandingContraList,
} = ContraSlice.actions;
export default ContraSlice.reducer;
