import React, { useEffect, useRef } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import moment from "moment";
import { Typography } from "@progress/kendo-react-common";
import ShadowCard from "../../../components/common/ShadowCard";
import {
  clearDistributorAndSalesManReportDetails,
  clearDistributorAndSalesManReportList,
  setDistributorAndSalesManReport,
} from "../reportsSlice";
import AlertBox from "../../../components/common/AlertBox";
import { LoadingPanel } from "../../../components/layout/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { checkAcessRights, formatIndianNumber } from "../../../_helper/helper";
import { EXCEL_EXPORT_ACCESS, PRINT_ACCESS } from "../../../_contstants/common";
import { clearItemList } from "../../Item/itemSlice";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { getDistributorAndSalesManReportReport } from "../services/reports.services";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { BsThreeDotsVertical } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import FormCheckboxGroup from "../../../components/formFields/FormCheckboxGroup";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import FormDatePicker from "../../../components/formFields/FormDateField";
import { getAllSalesRepresentative } from "../../salesrepresentativeassign/services/salesRepresentative.services";
// import { getAllAccounts } from "../../account/services/account.services";
import { findAllActiveItemGroup } from "../../ItemGroup/services/itemgroup.services";
import FormMultiSelectionFiled from "../../../components/formFields/FormMultiSelectionFiled";
import { requiredValidator } from "../../../components/formFields/CommonValidator";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const ChangeWatcher = ({ formRenderProps }: FormChangeWatcherProps) => {
  useEffect(() => {
    formRenderProps.onChange("department_id", {
      value: null,
    });
  }, []);
  return null;
};

const DistributorAndSalesManWiseReportForm: React.FC = () => {
  const gridRef = React.useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.reports.loading);
  const DistributorAndSalesManReportDetails = useAppSelector(
    (state) => state.reports.DistributorAndSalesManReportDetails
  );
  const DistributorAndSalesManReportList = useAppSelector(
    (state) => state.reports.DistributorAndSalesManReportList
  );

  const SalesRepresentativeList = useAppSelector(
    (state) => state.salesrepresentative.SalesRepresentativeList
  );
  // const AccountList = useAppSelector(
  //   (state) => state.account.AccountList
  // )?.filter((acc: any) => acc.user_type_id === 1);

  const ItemGroupList = useAppSelector((state) =>
    state.itemgroup.ItemGroupList?.filter(
      (item: any) => item.isdisplaysalesorder
    )
  );

  //   const currentPage = useAppSelector((state) => state.pagination.currentPage);
  //   const pageLimit = useAppSelector((state) => state.pagination.pageLimit);

  useEffect(() => {
    // dispatch(getAllAccounts());
    dispatch(getAllSalesRepresentative());
    dispatch(findAllActiveItemGroup());
    return () => {
      dispatch(clearDistributorAndSalesManReportDetails());
      dispatch(clearDistributorAndSalesManReportList());
      //   dispatch(setCurrentPage(0));
      dispatch(clearItemList());
    };
  }, []);

  const handleSubmit = (values: any) => {
    const payload = {
      from_date: values?.from_date
        ? moment(values?.from_date).format("YYYY-MM-DD")
        : "",
      to_date: values?.to_date
        ? moment(values?.to_date).format("YYYY-MM-DD")
        : "",
      sr_id: values?.sr_id,
      item_group_id: values?.item_group_id
        ? values?.item_group_id?.join(",")
        : "",
      //   limit: +pageLimit,
      //   skip: +currentPage * +pageLimit,
    };
    dispatch(getDistributorAndSalesManReportReport(payload));
    dispatch(setDistributorAndSalesManReport(payload));
  };

  return (
    <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
      <GridLayoutItem>
        <ShadowCard>
          <Form
            initialValues={DistributorAndSalesManReportDetails}
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 30 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                  ]}
                >
                  <ChangeWatcher formRenderProps={formRenderProps} />
                  <GridLayoutItem colSpan={4}>
                    <Typography.h4
                      style={{ marginBottom: 0, marginLeft: "3px" }}
                    >
                      Sales Man Wise Report
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperStyle={{ width: "100%" }}
                      name="from_date"
                      label="From Date"
                      format={"dd/MM/yyyy"}
                      component={FormDatePicker}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem>
                    <Field
                      wrapperStyle={{ width: "100%" }}
                      name="to_date"
                      label="To Date"
                      format={"dd/MM/yyyy"}
                      minDate={
                        formRenderProps.valueGetter("from_date") ||
                        new Date(1900, 1, 1)
                      }
                      component={FormDatePicker}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="item_group_id"
                      label="Item Group"
                      placeholder="Select Item Group"
                      component={FormMultiSelectionFiled}
                      options={ItemGroupList.map((itemgroup: any) => ({
                        value: itemgroup.id,
                        label: itemgroup.item_group,
                      }))}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="sr_id"
                      label="Sales Representative"
                      placeholder="Select Sales Representative"
                      component={FormSelectionField}
                      astrike={true}
                      validator={requiredValidator}
                      options={SalesRepresentativeList.map((emp: any) => ({
                        value: emp.id,
                        label: `${emp?.first_name} ${emp?.middle_name} ${emp?.last_name}`,
                      }))}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem colSpan={4}>
                    <div style={{ marginTop: "35px", textAlign: "end" }}>
                      <ButtonWithLoading
                        label={"View"}
                        type="submit"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </div>
                  </GridLayoutItem>
                </GridLayout>
              </FormElement>
            )}
          />
        </ShadowCard>
        {loading ? (
          <LoadingPanel gridRef={gridRef} />
        ) : (
          DistributorAndSalesManReportList &&
          DistributorAndSalesManReportList.length > 0 && (
            <ShadowCard style={{ marginTop: 10, minHeight: "30vh" }}>
              <DistributorANdSalesManExportTable />
            </ShadowCard>
          )
        )}
      </GridLayoutItem>
    </GridLayout>
  );
};

const DistributorANdSalesManExportTable: React.FC = () => {
  const isExcelAccess = checkAcessRights(
    location.pathname,
    EXCEL_EXPORT_ACCESS
  );

  const DistributorAndSalesManReportList = useAppSelector(
    (state) => state.reports.DistributorAndSalesManReportList
  );
  const DistributorAndSalesManReportDetails = useAppSelector(
    (state) => state.reports.DistributorAndSalesManReportDetails
  );
  const SalesRepresentativeList = useAppSelector(
    (state) => state.salesrepresentative.SalesRepresentativeList
  );
  const isPrintAccess = checkAcessRights(location.pathname, PRINT_ACCESS);

  const handleExportExcel = () => {
    const table = document.querySelector(
      ".employee-details-report-table"
    ) as HTMLTableElement;
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.table_to_sheet(table);

    // Adjust column widths dynamically
    const colWidths: number[] = [];
    const rows = table.querySelectorAll("tr");

    rows.forEach((row) => {
      const cells = row.querySelectorAll("th, td");
      cells.forEach((cell, colIndex) => {
        const cellContent = cell.textContent || "";
        const cellLength = cellContent.length;

        // Calculate width (max of existing or current cell)
        colWidths[colIndex] = Math.max(
          colWidths[colIndex] || 10,
          cellLength + 2
        );
      });
    });

    // Apply column widths
    ws["!cols"] = colWidths.map((width) => ({ wch: width }));
    const headerRows = table.querySelectorAll("thead tr");
    headerRows.forEach((row) => {
      const cells = row.querySelectorAll("th");
      cells.forEach((cell, colIndex) => {
        const cellRef = XLSX.utils.encode_cell({ r: 0, c: colIndex }); // Assume <thead> is row 0
        if (ws[cellRef]) {
          ws[cellRef].s = {
            font: { bold: true }, // Bold font
            alignment: { horizontal: "center", vertical: "center" }, // Center alignment
            fill: { fgColor: { rgb: "D9E1F2" } }, // Light background color
            border: {
              top: { style: "thin", color: { rgb: "000000" } },
              bottom: { style: "thin", color: { rgb: "000000" } },
              left: { style: "thin", color: { rgb: "000000" } },
              right: { style: "thin", color: { rgb: "000000" } },
            },
          };
        }
      });
    });

    Object.keys(ws).forEach((key) => {
      if (!key.startsWith("!")) {
        const cell = ws[key];
        if (cell.z !== "m/d/yy") {
          if (typeof cell.v === "number") {
            // Check if the cell is a date (custom logic depending on your use case)
            const isDate = cell.t === "d"; // 't' might indicate the cell type in some libraries
            if (!isDate) {
              cell.t = "s"; // Set cell type to 'string'
              cell.v = `${cell.v}`; // Convert number to string
            }
          }
        } else if (cell.z === "m/d/yy") {
          cell.z = "DD-MMM-YYYY";
        }
      }
    });

    XLSX.utils.book_append_sheet(wb, ws, `Sheet1`);

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(data, `Sales Man Wise Report`);
  };

  const [showfilterColumns, setShowFilterColumns] = React.useState(false);

  const initialValues = {
    filter_columns: ["plan_date", "distributor_name", "item_group"],
  };
  const columns = [
    { label: "Distributor Name", value: "distributor_name" },
    { label: "Item Group", value: "item_group" },
  ];

  const sr = SalesRepresentativeList?.find(
    (item: any) => item.id === DistributorAndSalesManReportDetails?.sr_id
  );

  const groupByItemGroupId = (data: any[]) => {
    const grouped = data.reduce((acc, item) => {
      if (!acc[item.item_group_id]) {
        acc[item.item_group_id] = [];
      }
      acc[item.item_group_id].push(item);
      return acc;
    }, {} as Record<number, any[]>);

    return Object.values(grouped) as any[][];
  };

  const printTableRef = useRef<HTMLTableElement | null>(null);

  const groupOfItems = groupByItemGroupId(DistributorAndSalesManReportList);
  const totalItemGroups = groupOfItems
    ?.map((group: any) => group[0]?.item_group)
    .filter(Boolean);

  // console.log("groupOfItems", groupOfItems);

  const getUnitTypes = (data: any[][]) => {
    return data.map((group) => {
      const unitTypes: any = [];
      group?.map((item: any) => {
        if (item.unit_kg_qty > 0)
          unitTypes?.includes("KG") ? "" : unitTypes?.push("KG");
        if (item.unit_pkt_qty > 0)
          unitTypes?.includes("PACKET") ? "" : unitTypes.push("PACKET");
        if (item.unit_bdh_qty > 0)
          unitTypes?.includes("BANDHA") ? "" : unitTypes.push("BANDHA");
      }); // Get the first item of each group
      return unitTypes;
    });
  };

  const unitArray = getUnitTypes(groupOfItems);

  // console.log("unitArray", unitArray);

  const calculateTotals = (data: any[][]) => {
    const totals: Record<string, number> = {};

    data.flat().forEach((item) => {
      [
        "unit_kg_qty",
        "unit_pkt_qty",
        "unit_bdh_qty",
        "unit_kg_amt",
        "unit_pkt_amt",
        "unit_bdh_amt",
      ].forEach((key) => {
        totals[key] = (totals[key] || 0) + (item[key] || 0);
      });
    });

    return totals;
  };
  const grandtotals = calculateTotals(groupOfItems);

  const checkInclude = (key: string, formRenderProps: FormRenderProps) => {
    return formRenderProps.valueGetter("filter_columns")?.includes(key);
  };

  const printTable = () => {
    // console.log("printTableRef", printTableRef.current);

    // let printbuttons = document.querySelector(
    //   ".printbuttons"
    // ) as HTMLElement | null;
    // let report_container = document.querySelector(
    //   ".report-container"
    // ) as HTMLElement | null;

    // if (printbuttons) printbuttons.style.display = "none";
    // if (report_container) report_container.style.padding = "20px 0";

    // Create a new window for the print preview

    // const handlePDFDownload = async () => {
    //   const html2pdf = (await import("html2pdf.js")).default;
    //   const options = {
    //     margin: 10,
    //     filename: `test.pdf`,
    //     html2canvas: { scale: 2 },
    //     jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
    //   };
    //   html2pdf().set(options).from(printTableRef.current).save();
    // };

    // handlePDFDownload();
    let printWindow = window.open("", "_blank");

    if (!printWindow) {
      console.error("Failed to open print preview window.");
      return;
    }

    const salesManName = DistributorAndSalesManReportDetails.sr_id
      ? ` For Sales Man - ${sr?.first_name} ${sr?.middle_name} ${sr?.last_name} -`
      : "";

    const dateRange =
      DistributorAndSalesManReportDetails.from_date &&
      DistributorAndSalesManReportDetails.to_date
        ? `( ${moment(
            DistributorAndSalesManReportDetails.from_date,
            "YYYY-MM-DD"
          ).format("DD/MM/YYYY")} - ${moment(
            DistributorAndSalesManReportDetails.to_date,
            "YYYY-MM-DD"
          ).format("DD/MM/YYYY")} )`
        : "";
    printWindow.document.write(`
        <html>
        <head>
          <title>Print Preview</title>
          <style>
            @page { margin: 1cm; }
            .print-page { page-break-after: always; }
            .table-bordered { width: 100%; border-collapse: collapse; }
            .table-bordered th, .table-bordered td { border: 1px solid black; padding: 8px; }

            body { font-family: Arial, sans-serif; }
            .sales-man-info { font-size: 16px; margin-bottom: 10px;text-align:center }
           .employee-details-report-table > thead { background-color:lightgrey}
          </style>
        </head>
        <body>
         <p style="text-align:center">
         Salesman wise Report
        </p>
        <p class="sales-man-info">
          <span class="sales-man-name">${salesManName}</span> ${dateRange}
        </p>
          ${printTableRef.current?.outerHTML}
          <script>
            window.onload = function() {
              window.print();
            };
          </script>
        </body>
        </html>
      `);
    printWindow.document.close();

    // if (printbuttons) printbuttons.style.display = "block";
    // if (report_container) report_container.style.padding = "20px 10px";
  };

  return (
    <>
      {DistributorAndSalesManReportList &&
      DistributorAndSalesManReportList.length ? (
        <Form
          initialValues={initialValues}
          render={(formRenderProps: FormRenderProps) => {
            return (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  //   style={{ marginRight: 30 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[{ width: "100%" }]}
                >
                  <GridLayoutItem className="d-flex justify-content-between align-items-center">
                    <Typography.h4 className="m-0">
                      Sales Man Wise Report
                      <>
                        <p style={{ fontSize: 16 }}>
                          {DistributorAndSalesManReportDetails.sr_id && (
                            <>
                              For Sales Man-
                              <span style={{ color: "red" }}>
                                {`${
                                  DistributorAndSalesManReportDetails.sr_id
                                    ? ` ${sr?.first_name} ${sr?.middle_name} ${sr?.last_name}`
                                    : ""
                                } -`}
                              </span>
                            </>
                          )}
                          {DistributorAndSalesManReportDetails.from_date &&
                            DistributorAndSalesManReportDetails.to_date &&
                            `( ${moment(
                              DistributorAndSalesManReportDetails.from_date,
                              "YYYY-MM-DD"
                            ).format("DD/MM/YYYY")} - ${moment(
                              DistributorAndSalesManReportDetails.to_date,
                              "YYYY-MM-DD"
                            ).format("DD/MM/YYYY")} )`}
                        </p>
                      </>
                    </Typography.h4>
                    <div className="position-relative d-flex">
                      {isPrintAccess && (
                        <Button
                          style={{ marginRight: 5 }}
                          type="button"
                          fillMode={"solid"}
                          themeColor={"primary"}
                          onClick={() => printTable()}
                        >
                          Print
                        </Button>
                      )}
                      {isExcelAccess && (
                        <Button
                          type="button"
                          fillMode={"solid"}
                          themeColor={"primary"}
                          onClick={handleExportExcel}
                          style={{ marginRight: 10 }}
                        >
                          Excel Export
                        </Button>
                      )}
                      <Tooltip
                        anchorElement="target"
                        position="left"
                        parentTitle={true}
                      >
                        <Button
                          title="Filter Columns"
                          type="button"
                          themeColor={"primary"}
                          fillMode={"solid"}
                          onClick={() => setShowFilterColumns(true)}
                        >
                          Filter
                          <BsThreeDotsVertical />
                        </Button>
                      </Tooltip>
                      {showfilterColumns && (
                        <div
                          style={{
                            position: "absolute",
                            top: "32px",
                            right: "0",
                            background: "white",
                            padding: "12px",
                            border: "1px solid whitesmoke",
                            width: "250px",
                            zIndex: 1,
                          }}
                        >
                          <div className="d-flex justify-content-between align-items-baseline">
                            <p className="m-0 fw-600">Filter</p>
                            <Button
                              themeColor={"primary"}
                              fillMode={"flat"}
                              onClick={() => setShowFilterColumns(false)}
                            >
                              <RxCross2 />
                            </Button>
                          </div>
                          <hr className="m-1" style={{ color: "lightgray" }} />
                          <Field
                            wrapperClassName="d-flex flex-column checkBoxGroup-vertical"
                            inputClassName="checkBoxGroup-vertical-input"
                            id={"filter_columns"}
                            name={"filter_columns"}
                            marginRight={0}
                            marginBeetween={8}
                            component={FormCheckboxGroup}
                            options={columns}
                          />
                        </div>
                      )}
                    </div>
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{
                      maxHeight: "calc(100vh - 340px)",
                      overflow: "scroll",
                      scrollbarWidth: "thin",
                    }}
                  >
                    <table
                      ref={printTableRef}
                      cellSpacing={1}
                      cellPadding={2}
                      className="table table-bordered w-100  employee-details-report-table"
                      style={{
                        marginTop: 1,
                      }}
                    >
                      <thead>
                        <tr
                          className="text-center fw-600"
                          style={{
                            fontSize: 12,
                            position: "sticky",
                            top: 0,
                          }}
                        >
                          <>
                            <th style={{ minWidth: 50 }} rowSpan={2}>
                              SR. No
                            </th>
                          </>

                          {checkInclude(
                            "distributor_name",
                            formRenderProps
                          ) && (
                            <>
                              <th style={{ minWidth: 200 }} rowSpan={2}>
                                Distributor Name
                              </th>
                            </>
                          )}
                          {checkInclude("plan_date", formRenderProps) && (
                            <>
                              <th style={{ minWidth: 100 }} rowSpan={2}>
                                Plan Date
                              </th>
                            </>
                          )}
                          {checkInclude("item_group", formRenderProps) &&
                            totalItemGroups?.map(
                              (item: string, index: number) => (
                                <>
                                  <th colSpan={2 * unitArray[index].length + 1}>
                                    {item?.toUpperCase()} GROUP{" "}
                                  </th>
                                </>
                              )
                            )}
                        </tr>
                        <tr
                          className="text-center fw-600"
                          style={{
                            fontSize: 12,
                            position: "sticky",
                            top: 39,
                          }}
                        >
                          {checkInclude("item_group", formRenderProps) &&
                            totalItemGroups?.map((_: string, index: number) => (
                              <>
                                {unitArray[index]?.map((unit: any) => (
                                  <>
                                    <th style={{ minWidth: 100 }}>
                                      {unit?.toUpperCase()}
                                    </th>
                                    <th style={{ minWidth: 100 }}>Value</th>
                                  </>
                                ))}
                                <th style={{ minWidth: 100 }}>Total</th>
                              </>
                            ))}
                        </tr>
                      </thead>
                      <tbody>
                        {groupOfItems.flat().map((item, rowIndex) => (
                          <tr key={rowIndex}>
                            <td>{rowIndex + 1}</td>
                            {checkInclude(
                              "distributor_name",
                              formRenderProps
                            ) && <td>{item.distributor_name}</td>}
                            {checkInclude("plan_date", formRenderProps) && (
                              <td>
                                {item.plan_date
                                  ? moment(item.plan_date, "DD/MM/YYYY").format(
                                      "DD-MMM-YYYY"
                                    )
                                  : ""}
                              </td>
                            )}
                            {checkInclude("item_group", formRenderProps) &&
                              totalItemGroups.map((_, index) => (
                                <>
                                  {unitArray[index].map((unit: string) => {
                                    const qtyKey =
                                      unit === "KG"
                                        ? "unit_kg_qty"
                                        : unit === "PACKET"
                                        ? "unit_pkt_qty"
                                        : "unit_bdh_qty";
                                    const amtKey =
                                      unit === "KG"
                                        ? "unit_kg_amt"
                                        : unit === "PACKET"
                                        ? "unit_pkt_amt"
                                        : "unit_bdh_amt";
                                    return (
                                      <React.Fragment key={unit}>
                                        <td style={{ textAlign: "end" }}>
                                          {item[qtyKey]?.toFixed(2)}
                                        </td>
                                        <td style={{ textAlign: "end" }}>
                                          {item[amtKey]?.toFixed(2)}
                                        </td>
                                      </React.Fragment>
                                    );
                                  })}
                                  <td
                                    style={{
                                      textAlign: "end",
                                    }}
                                  >
                                    {unitArray[index]
                                      .reduce((total: number, unit: string) => {
                                        const key =
                                          unit === "KG"
                                            ? "unit_kg_amt"
                                            : unit === "PACKET"
                                            ? "unit_pkt_amt"
                                            : "unit_bdh_amt";
                                        return total + (item[key] || 0);
                                      }, 0)
                                      ?.toFixed(2)}
                                  </td>
                                </>
                              ))}
                          </tr>
                        ))}
                        <tr className="fw-bold bg-light">
                          <td
                            colSpan={
                              checkInclude("distributor_name", formRenderProps)
                                ? 3
                                : 2
                            }
                            style={{
                              textAlign: "start",
                              fontWeight: "bold",
                            }}
                          >
                            Final Total
                          </td>

                          {checkInclude("item_group", formRenderProps) &&
                            totalItemGroups.map((group, index) => (
                              <>
                                {unitArray[index].map((unit: string) => {
                                  const qtyKey =
                                    unit === "KG"
                                      ? "unit_kg_qty"
                                      : unit === "PACKET"
                                      ? "unit_pkt_qty"
                                      : "unit_bdh_qty";
                                  const amtKey =
                                    unit === "KG"
                                      ? "unit_kg_amt"
                                      : unit === "PACKET"
                                      ? "unit_pkt_amt"
                                      : "unit_bdh_amt";
                                  return (
                                    <React.Fragment key={unit}>
                                      <td
                                        style={{
                                          textAlign: "end",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {grandtotals[qtyKey]?.toFixed(2)}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "end",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {formatIndianNumber(
                                          grandtotals[amtKey]
                                        )}
                                      </td>
                                    </React.Fragment>
                                  );
                                })}
                                <td
                                  style={{
                                    textAlign: "end",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {formatIndianNumber(
                                    unitArray[index].reduce(
                                      (total: number, unit: string) => {
                                        const key =
                                          unit === "KG"
                                            ? "unit_kg_amt"
                                            : unit === "PACKET"
                                            ? "unit_pkt_amt"
                                            : "unit_bdh_amt";
                                        return total + (grandtotals[key] || 0);
                                      },
                                      0
                                    )
                                  )}
                                </td>
                              </>
                            ))}
                        </tr>
                      </tbody>
                    </table>
                  </GridLayoutItem>
                </GridLayout>
              </FormElement>
            );
          }}
        />
      ) : (
        <AlertBox />
      )}
    </>
  );
};

export default DistributorAndSalesManWiseReportForm;
