import React, { useCallback, useEffect, useState } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import FormDatePicker from "../../../components/formFields/FormDateField";
import moment from "moment";
import { Typography } from "@progress/kendo-react-common";
import ShadowCard from "../../../components/common/ShadowCard";
import { getAllItemIncremental } from "../../Item/services/item.services";
import {
  clearDebitNoteRegisterDetails,
  clearDebitNoteRegisterList,
  setDebitNoteRegisterReport,
} from "../reportsSlice";
import AlertBox from "../../../components/common/AlertBox";
import { LoadingPanel } from "../../../components/layout/Loading";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import FormIncrementalSearch from "../../../components/formFields/FormIncrementalSearch";
import { clearItemList } from "../../Item/itemSlice";
import { Button } from "@progress/kendo-react-buttons";
import Logo from "../../../assets/Images/logo.png";
import {
  checkAcessRights,
  formatIndianNumber,
  formatIndianNumberForQty,
} from "../../../_helper/helper";
import {
  EDIT_ACCESS,
  // EXCEL_EXPORT_ACCESS,
  // PRINT_ACCESS,
} from "../../../_contstants/common";
import { getAllAccountIncremental } from "../../account/services/account.services";
import { RxCross2 } from "react-icons/rx";
import FormCheckboxGroup from "../../../components/formFields/FormCheckboxGroup";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { BsThreeDotsVertical } from "react-icons/bs";
import { getDebitNoteRegisterReport } from "../services/reports.services";
import { FiEdit } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import IconButton from "../../../components/common/IconButton";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const DummyChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const createdBy = localStorage.getItem("UserID");
  useEffect(() => {
    formRenderProps.onChange("UserID", {
      value: createdBy,
    });
  }, []);

  return null;
};

const DebitNoteRegisterReportForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.reports.loading);
  const DebitNoteRegisterList = useAppSelector(
    (state) => state.reports.DebitNoteRegisterList
  );
  const DebitNoteRegisterDetails = useAppSelector(
    (state) => state.reports.DebitNoteRegisterDetails
  );
  const [initialValue, setInitialValue] = useState<any>({});
  const [formKey, setFormKey] = useState<any>(1);

  useEffect(() => {
    setInitialValue({
      ...DebitNoteRegisterDetails,
      from_date: DebitNoteRegisterDetails?.from_date
        ? moment(DebitNoteRegisterDetails?.from_date, "YYYY-MM-DD").toDate()
        : null,
      to_date: DebitNoteRegisterDetails?.to_date
        ? moment(DebitNoteRegisterDetails?.to_date, "YYYY-MM-DD").toDate()
        : null,
    });
    setFormKey(formKey + 1);
  }, [DebitNoteRegisterDetails]);

  const handleVendorSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllAccountIncremental(search));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleItemSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllItemIncremental({ search }));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = (values: any) => {
    const payload = {
      item_id: values?.item_id ? values?.item_id : null,
      vendor_id: values?.vendor_id ? values?.vendor_id : null,
      from_date: values?.from_date
        ? moment(values?.from_date).format("YYYY-MM-DD")
        : null,
      to_date: values?.to_date
        ? moment(values?.to_date).format("YYYY-MM-DD")
        : null,
    };
    const setPayload = {
      ...payload,
      vendor_options: values?.vendor_options || [],
      item_options: values?.item_options || [],
      isDebitNoteRef: true,
    };
    dispatch(setDebitNoteRegisterReport(setPayload));
    dispatch(getDebitNoteRegisterReport(payload));
  };

  return (
    <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
      <GridLayoutItem>
        <ShadowCard>
          <Form
            key={formKey}
            initialValues={initialValue}
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 30 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                  ]}
                >
                  <GridLayoutItem colSpan={4}>
                    <Typography.h4
                      style={{ marginBottom: 0, marginLeft: "3px" }}
                    >
                      Purchase Return Register
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="from_date"
                      label="From Date"
                      format="dd/MM/yyyy"
                      component={FormDatePicker}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="to_date"
                      label="To Date"
                      format="dd/MM/yyyy"
                      minDate={moment(
                        formRenderProps.valueGetter("from_date")
                      ).toDate() || new Date(1900, 0, 1)}
                      component={FormDatePicker}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="vendor_id"
                      label="Vendor"
                      placeholder="Type here to search Vendor..."
                      component={FormIncrementalSearch}
                      fetchIncrementalData={(search: any) =>
                        handleVendorSearchChange(
                          search,
                          `vendor_options`,
                          formRenderProps
                        )
                      }
                      options={
                        formRenderProps
                          .valueGetter("vendor_options")
                          ?.map((item: any) => {
                            return {
                              value: item.id,
                              label: item.account_name,
                            };
                          }) || []
                      }
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="w-100"
                      name="item_id"
                      label="Product Name"
                      placeholder="Type here to search item..."
                      component={FormIncrementalSearch}
                      fetchIncrementalData={(search: any) =>
                        handleItemSearchChange(
                          search,
                          `item_options`,
                          formRenderProps
                        )
                      }
                      options={
                        formRenderProps
                          .valueGetter("item_options")
                          ?.map((item: any) => {
                            return {
                              value: item?.id,
                              label: item?.product_name,
                            };
                          }) || []
                      }
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    colSpan={4}
                    style={{
                      textAlign: "end",
                      marginTop: 10,
                    }}
                  >
                    <ButtonWithLoading
                      label={"View"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                  </GridLayoutItem>
                </GridLayout>
                <DummyChangeWatcher formRenderProps={formRenderProps} />
              </FormElement>
            )}
          />
        </ShadowCard>
        {/* {loading ? (
          <LoadingPanel gridRef={gridRef} />
        ) : ( */}
        {DebitNoteRegisterList && DebitNoteRegisterList.length > 0 && (
          <ShadowCard style={{ marginTop: 10 }}>
            <DebitNoteRegisterGridCommponent />
          </ShadowCard>
        )}
        {/* )} */}
      </GridLayoutItem>
    </GridLayout>
  );
};

const columns = [
  // { label: "Purchase Return No.", value: "debit_note_no_string" },
  { label: "Purchase Return Date", value: "debit_note_date" },
  { label: "Bill No.", value: "bill_no" },
  { label: "Bill Date", value: "bill_date" },
  { label: "Vendor Name", value: "account_name" },
  { label: "Total Amount", value: "total_amount" },
  { label: "GST", value: "gst" },
  { label: "CGST", value: "cgst" },
  { label: "SGST", value: "sgst" },
  { label: "IGST", value: "igst" },
  { label: "Item Name", value: "product_name" },
  { label: "Quantity", value: "quantity" },
  { label: "Unit Name", value: "unit_name" },
  { label: "Rate", value: "rate" },
  { label: "Free Quantity", value: "free_quantity" },
  { label: "Discount", value: "discount" },
  { label: "SGST (%)", value: "sgst_per" },
  { label: "SGST Amount", value: "sgst_amount" },
  { label: "CGST (%)", value: "cgst_per" },
  { label: "CGST Amount", value: "cgst_amount" },
  { label: "IGST (%)", value: "igst_per" },
  { label: "IGST Amount", value: "igst_amount" },
  // { label: "Amount", value: "amount" },
];
const DebitNoteRegisterGridCommponent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const location = useLocation();
  const gridRef = React.useRef<any>(null);
  const isEditBtnRef = React.useRef<any>(false);
  const isEditAccess = checkAcessRights("/debitnote", EDIT_ACCESS);
  // const isPrintAccess = checkAcessRights(location.pathname, PRINT_ACCESS);
  // const isExcelAccess = checkAcessRights(
  //   location.pathname,
  //   EXCEL_EXPORT_ACCESS
  // );

  const loading = useAppSelector((state) => state.reports.loading);
  const DebitNoteRegisterList = useAppSelector(
    (state) => state.reports.DebitNoteRegisterList
  );
  const DebitNoteRegisterDetails = useAppSelector(
    (state) => state.reports.DebitNoteRegisterDetails
  );
  const [showfilterColumns, setShowFilterColumns] = React.useState(false);
  const [selectedGroup, setSelectedGroup] = React.useState<string | null>(null);

  useEffect(() => {
    return () => {
      if (!isEditBtnRef.current) {
        dispatch(clearDebitNoteRegisterList());
        dispatch(clearDebitNoteRegisterDetails());
        dispatch(clearItemList());
      }
    };
  }, []);

  const handleExportExcel = () => {
    const table = document.querySelector(
      ".currentstock-print-table"
    ) as HTMLTableElement;
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.table_to_sheet(table);

    // Adjust column widths dynamically
    const colWidths: number[] = [];
    const rows = table.querySelectorAll("tr");

    rows.forEach((row) => {
      const cells = row.querySelectorAll("th, td");
      cells.forEach((cell, colIndex) => {
        const cellContent = cell.textContent || "";
        const cellLength = cellContent.length;

        // Calculate width (max of existing or current cell)
        colWidths[colIndex] = Math.max(
          colWidths[colIndex] || 10,
          cellLength + 2
        );
      });
    });
    // Apply column widths
    ws["!cols"] = colWidths.map((width) => ({ wch: width }));

    Object.keys(ws).forEach((key) => {
      if (!key.startsWith("!")) {
        const cell = ws[key];
        if (cell.z !== "m/d/yy") {
          if (typeof cell.v === "number") {
            // Check if the cell is a date (custom logic depending on your use case)
            const isDate = cell.t === "d"; // 't' might indicate the cell type in some libraries
            if (!isDate) {
              cell.t = "s"; // Set cell type to 'string'
              cell.v = `${cell.v}`; // Convert number to string
            }
          }
        } else if (cell.z === "m/d/yy") {
          cell.z = "DD-MMM-YYYY";
        }
      }
    });

    XLSX.utils.book_append_sheet(wb, ws, `Sheet 1`);

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(data, `Purchase Return Register Report`);
  };

  const handleRowClick = (debit_note_guid: string) => {
    const groupKey = `${debit_note_guid}`;
    setSelectedGroup(selectedGroup === groupKey ? null : groupKey);
  };

  const initialValues = {
    filter_columns: [
      // "debit_note_no_string",
      "debit_note_date",
      "bill_no",
      "bill_date",
      "account_name",
      "total_amount",
      "gst",
      "cgst",
      "sgst",
      "igst",
      "product_name",
      "quantity",
      "unit_name",
      "rate",
      "free_quantity",
      "discount",
      "sgst_per",
      "sgst_amount",
      "cgst_per",
      "cgst_amount",
      "igst_per",
      "igst_amount",
      // "amount",
    ],
  };

  // type DataItem = { [key: string]: number | null };

  // const calculateTotals = (
  //   data: DataItem[],
  //   keysToTotal: string[]
  // ): { [key: string]: number } => {
  //   const totals: { [key: string]: number } = {};
  //   keysToTotal.forEach((key) => {
  //     totals[key] = data.reduce((sum, item) => sum + (item[key] || 0), 0);
  //   });

  //   return totals;
  // };

  const GrandTotalArray: any = [
    "quantity",
    "free_quantity",
    "cgst_amount",
    "sgst_amount",
    "igst_amount",
    "gst",
    "cgst",
    "sgst",
    "igst",
    "total_amount",
  ];

  const groupedData = DebitNoteRegisterList.reduce((acc: any, item: any) => {
    const { debit_note_guid } = item;
    acc[debit_note_guid] = acc[debit_note_guid] || [];
    acc[debit_note_guid].push(item);
    return acc;
  }, {});
  const groupedArray = Object.values(groupedData);
  let totalQty = 0;
  let totalFreeQty = 0;

  let totalCGSTAmount = 0;
  let totalSGSTAmount = 0;
  let totalIGSTAmount = 0;
  let GrandtotalCGSTAmount = 0;
  let GrandtotalSGSTAmount = 0;
  let GrandtotalIGSTAmount = 0;

  let GrandtotalGSTAmount = 0;
  let GrandTotalAmount = 0;

  groupedArray &&
    groupedArray.length > 0 &&
    groupedArray?.map((group: any) => {
      group?.map((item: any, index: number) => {
        totalQty += item?.quantity || 0;
        totalFreeQty += item?.free_quantity || 0;

        totalCGSTAmount += item?.cgst_amount || 0;
        totalSGSTAmount += item?.sgst_amount || 0;
        totalIGSTAmount += item?.igst_amount || 0;

        if (index === 0) {
          GrandtotalCGSTAmount += item.cgst || 0;
          GrandtotalSGSTAmount += item.sgst || 0;
          GrandtotalIGSTAmount += item.igst || 0;
          GrandtotalGSTAmount += item.gst || 0;
          GrandTotalAmount += item.total_amount || 0;
        }
      });
    });

  const printTable = (data: any[], formRenderProps: any) => {
    let printbuttons = document.querySelector(
      ".printbuttons"
    ) as HTMLElement | null;
    let report_container = document.querySelector(
      ".report-container"
    ) as HTMLElement | null;

    if (printbuttons) printbuttons.style.display = "none";
    if (report_container) report_container.style.padding = "20px 0";

    let tableRows = data.length;
    let maxRows = tableRows;
    let pageCount = Math.ceil(tableRows / maxRows);
    let content = "";

    for (let i = 0; i < pageCount; i++) {
      content += '<div class="print-page">';
      content +=
        '<table class="table table-bordered" style="font-size:10px;margin-bottom:30px;">';
      content += "<tr>";
      content += `<td rowSpan="3" style="width:15%" ><img src="${Logo}" width="100%" /></td>`;
      content +=
        '<td colSpan="2" style="text-align: center;font-size: 14px;font-weight: 600;color: gray;">Harihar Foods Pvt Ltd.</td>';
      content += "</tr>";
      content += "<tr>";
      content +=
        '<td colSpan="2"><h3 style="margin:0;">Purchase Return Register Report</h3></td>';
      content += "</tr>";
      content += "<tr>";
      content += `<td style="text-align:end;">Page : ${i + 1}</td>`;
      content += "</tr>";

      content += "</table>";

      content += '<table class="table table-bordered" style="font-size:10px;">';
      content += '<thead style="background:lightgray;">';
      content += "<tr>";
      content += '<th style="width:30px;">Purchase Return No.</th>';
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("debit_note_date") &&
          (content += '<th style="width:30px;">Purchase Return Date.</th>');
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("bill_no") &&
          (content += "<th>Bill No</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("bill_date") &&
          (content += "<th>Bill Date</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("account_name") && (content += "<th>Vendor Name</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("product_name") && (content += "<th>Product Name</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("quantity") &&
          (content += "<th>Quantity</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("free_quantity") && (content += "<th>Free Quantity</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("rate") &&
          (content += "<th>Rate</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("unit_name") &&
          (content += "<th>Unit Name</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("discount") &&
          (content += "<th>Discount</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("cgst_per") &&
          (content += "<th>CGST(%) </th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("cgst_amount") && (content += "<th>CGST Amount</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("sgst_per") &&
          (content += "<th>SGST(%)</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("sgst_amount") && (content += "<th>SGST Amount</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("igst_per") &&
          (content += "<th>IGST(%)</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("igst_amount") && (content += "<th>IGST Amount</th>");
      }

      {
        formRenderProps.valueGetter("filter_columns")?.includes("cgst") &&
          (content += "<th>Total CGST</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("sgst") &&
          (content += "<th>Total SGST</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("igst") &&
          (content += "<th>Total IGST</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("gst") &&
          (content += "<th>Total GST </th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("total_amount") && (content += "<th>Total Amount</th>");
      }
      content += "</tr>";
      content += "</thead>";
      content += "<tbody>";

      // let startRow = i * maxRows;
      // let endRow = Math.min((i + 1) * maxRows, tableRows);

      data.map((group: any) =>
        group.map((item: any, index: any) => {
          content += "<tr className='debitnote-avoid-break'>";
          index === 0 &&
            (content += `<td rowspan="${group.length}" >${
              item.debit_note_no_string ? item.debit_note_no_string : ""
            }</td>`);
          // {
          //   formRenderProps
          //     .valueGetter("filter_columns")
          //     ?.includes("debit_note_date") &&
          //     index === 0 &&
          //     (content += `<td rowspan="${group.length}" class="text-start">${item.debit_note_date}</td>`);
          // }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("debit_note_date") &&
              index === 0 &&
              (content += `<td rowspan="${group.length}" style="text-align:center;">${item?.debit_note_date}</td>`);
          }
          // {
          //   formRenderProps
          //     .valueGetter("filter_columns")
          //     ?.includes("bill_no") &&
          //     index === 0 &&
          //     (content += `<td rowspan="${
          //       group.length
          //     }" style="text-align:center;">${
          //       item.inward_no ? item.inward_no : ""
          //     }</td>`);
          // }

          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("bill_no") &&
              index === 0 &&
              (content += `<td rowspan="${group.length}" class="text-start">${item.bill_no}</td>`);
          }

          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("bill_date") &&
              index === 0 &&
              (content += `<td rowspan="${group.length}" style="text-align:center;">${item?.bill_date}</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("account_name") &&
              index === 0 &&
              (content += `<td rowspan="${group.length}" style="text-align:center;">${item.account_name}</td>`);
          }

          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("product_name") &&
              index === 0 &&
              (content += `<td rowspan="${group.length}" style="text-align:center;">${item.product_name}</td>`);
          }

          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("quantity") &&
              (content += `<td style="text-align:end;">${
                item.quantity ? formatIndianNumberForQty(item.quantity) : 0
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("free_quantity") &&
              (content += `<td style="text-align:end;">${
                item.free_quantity
                  ? formatIndianNumberForQty(item.free_quantity)
                  : 0
              }</td>`);
          }

          {
            formRenderProps.valueGetter("filter_columns")?.includes("rate") &&
              index === 0 &&
              (content += `<td rowspan="${group.length}" style="text-align:center;">${item.rate}</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("unit_name") &&
              index === 0 &&
              (content += `<td rowspan="${group.length}" style="text-align:center;">${item.unit_name}</td>`);
          }

          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("discount") &&
              (content += `<td style="text-align:end;">${
                item.discount ? item.discount : ""
              } ${
                item.discount && item.discount_type
                  ? item.discount_type === 1
                    ? "₹"
                    : item.discount_type === 2
                    ? "%"
                    : ""
                  : ""
              }</td>`);
          }

          // cgst_per;
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("cgst_per") &&
              (content += `<td style="text-align:end;">${
                item.cgst ? `${item.cgst_per} %` : ""
              }</td>`);
          }

          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("cgst_amount") &&
              (content += `<td style="text-align:end;">${
                item.cgst_amount ? formatIndianNumber(item.cgst_amount) : "0.00"
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("sgst_per") &&
              (content += `<td style="text-align:end;">${
                item.sgst ? `${item.sgst_per} %` : ""
              }</td>`);
          }

          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("sgst_amount") &&
              (content += `<td style="text-align:end;">${
                item.sgst_amount ? formatIndianNumber(item.sgst_amount) : "0.00"
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("igst_per") &&
              (content += `<td style="text-align:end;">${
                item.igst ? `${item.igst_per} %` : ""
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("igst_amount") &&
              (content += `<td style="text-align:end;">${
                item.igst_amount ? formatIndianNumber(item.igst_amount) : "0.00"
              }</td>`);
          }

          {
            formRenderProps.valueGetter("filter_columns")?.includes("cgst") &&
              index === 0 &&
              (content += `<td rowspan="${
                group.length
              }" style="text-align:end;">${
                item.cgst ? formatIndianNumber(item.cgst) : "0.00"
              }</td>`);
          }
          {
            formRenderProps.valueGetter("filter_columns")?.includes("sgst") &&
              index === 0 &&
              (content += `<td rowspan="${
                group.length
              }" style="text-align:end;">${
                item.sgst ? formatIndianNumber(item.sgst) : "0.00"
              }</td>`);
          }
          {
            formRenderProps.valueGetter("filter_columns")?.includes("igst") &&
              index === 0 &&
              (content += `<td rowspan="${
                group.length
              }" style="text-align:end;">${
                item.igst ? formatIndianNumber(item.igst) : "0.00"
              }</td>`);
          }

          {
            formRenderProps.valueGetter("filter_columns")?.includes("gst") &&
              index === 0 &&
              (content += `<td rowspan="${
                group.length
              }" style="text-align:end;">${
                item.gst ? formatIndianNumber(item.gst) : "0.00"
              }</td>`);
          }

          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("total_amount") &&
              index === 0 &&
              (content += `<td rowspan="${
                group.length
              }" style="text-align:end;">${
                item.total_amount
                  ? formatIndianNumber(item.total_amount)
                  : "0.00"
              }</td>`);
          }

          content += "</tr>";
        })
      );
      if (data && data?.length > 0) {
        content += '<tr style="background:whitesmoke; font-weight:600;">';

        content += `<td>Total</td>`;
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("debit_note_date") &&
            (content += `<td  class="text-start"></td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("bill_no") &&
            (content += `<td style="text-align:center;"></td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("bill_date") &&
            (content += `<td style="text-align:center;"></td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("account_name") &&
            (content += `<td style="text-align:center;"></td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("product_name") &&
            (content += `<td  style="text-align:center;"></td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("quantity") &&
            (content += `<td style="text-align:end;">${formatIndianNumberForQty(
              totalQty
            )}</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("free_quantity") &&
            (content += `<td style="text-align:end;">${formatIndianNumberForQty(
              totalFreeQty
            )}</td>`);
        }

        {
          formRenderProps.valueGetter("filter_columns")?.includes("rate") &&
            (content += `<td style="text-align:end;"></td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("unit_name") && (content += `<td></td>`);
        }

        {
          formRenderProps.valueGetter("filter_columns")?.includes("discount") &&
            (content += `<td style="text-align:end;"></td>`);
        }

        {
          formRenderProps.valueGetter("filter_columns")?.includes("cgst_per") &&
            (content += `<td style="text-align:end;"></td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("cgst_amount") &&
            (content += `<td style="text-align:end;">${formatIndianNumber(
              totalCGSTAmount
            )}</td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("sgst_per") &&
            (content += `<td style="text-align:end;"></td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("sgst_amount") &&
            (content += `<td style="text-align:end;">${formatIndianNumber(
              totalSGSTAmount
            )}</td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("igst_per") &&
            (content += `<td style="text-align:end;"></td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("igst_amount") &&
            (content += `<td style="text-align:end;">${formatIndianNumber(
              totalIGSTAmount
            )}</td>`);
        }

        {
          formRenderProps.valueGetter("filter_columns")?.includes("cgst") &&
            (content += `<td style="text-align:end;">${formatIndianNumber(
              GrandtotalCGSTAmount
            )}</td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("sgst") &&
            (content += `<td style="text-align:end;">${formatIndianNumber(
              GrandtotalSGSTAmount
            )}</td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("igst") &&
            (content += `<td style="text-align:end;">${formatIndianNumber(
              GrandtotalIGSTAmount
            )}</td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("gst") &&
            (content += `<td style="text-align:end;">${formatIndianNumber(
              GrandtotalGSTAmount
            )}</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("total_amount") &&
            (content += `<td style="text-align:end;">${formatIndianNumber(
              GrandTotalAmount
            )}</td>`);
        }

        content += "</tr>";
      }

      content += "</tbody>";
      content += "</table>";

      content += "</div>";
    }

    let printWindow = window.open("", "_blank");
    if (!printWindow) {
      console.error("Failed to open print preview window.");
      return;
    }
    printWindow.document.write(`
        <html>
        <head>
          <title>Print Preview</title>
          <style>
            @page { margin: 1cm; }
            .print-page { page-break-after: always; }
            .table-bordered { width: 100%; border-collapse: collapse; }
            .table-bordered th, .table-bordered td { border: 1px solid black; padding: 8px; }
            body { font-family: Arial, sans-serif; }
          </style>
        </head>
        <body>
          ${content}
          <script>
            window.onload = function() {
              window.print();
            };
          </script>
        </body>
        </html>
      `);
    printWindow.document.close();

    if (printbuttons) printbuttons.style.display = "block";
    if (report_container) report_container.style.padding = "20px 10px";
  };

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      {DebitNoteRegisterList && DebitNoteRegisterList.length ? (
        <Form
          initialValues={initialValues}
          render={(formRenderProps: FormRenderProps) => {
            const isMatchFound = GrandTotalArray?.some((subItem: any) =>
              formRenderProps.valueGetter("filter_columns")?.includes(subItem)
            );
            return (
              <FormElement
                style={{ width: "100%", minHeight: "calc(100vh - 290px)" }}
              >
                <GridLayout
                  gap={{ rows: 10, cols: 10 }}
                  cols={[{ width: "100%" }]}
                >
                  <GridLayoutItem className="d-flex justify-content-between align-items-center">
                    <Typography.h4 className="m-0">
                      Purchase Return Register List{" "}
                      {(DebitNoteRegisterDetails?.from_date ||
                        DebitNoteRegisterDetails?.to_date) && (
                        <span style={{ fontSize: 13 }}>
                          {"("}
                          {DebitNoteRegisterDetails?.from_date
                            ? moment(
                                DebitNoteRegisterDetails?.from_date,
                                "YYYY-MM-DD"
                              ).format("DD/MM/YYYY")
                            : ""}
                          {DebitNoteRegisterDetails?.from_date &&
                            DebitNoteRegisterDetails?.to_date &&
                            " - "}
                          {DebitNoteRegisterDetails?.to_date
                            ? moment(
                                DebitNoteRegisterDetails?.to_date,
                                "YYYY-MM-DD"
                              ).format("DD/MM/YYYY")
                            : ""}
                          {")"}
                        </span>
                      )}
                    </Typography.h4>
                    <div className="position-relative d-flex">
                      {/* {isPrintAccess && ( */}
                      <Button
                        style={{ marginRight: 5 }}
                        type="button"
                        fillMode={"solid"}
                        themeColor={"primary"}
                        onClick={() =>
                          printTable(groupedArray, formRenderProps)
                        }
                      >
                        Print
                      </Button>
                      {/* )} */}
                      {/* {isExcelAccess && ( */}
                      <Button
                        type="button"
                        fillMode={"solid"}
                        themeColor={"primary"}
                        onClick={handleExportExcel}
                        style={{ marginRight: 5 }}
                      >
                        Excel Export
                      </Button>
                      {/* )} */}
                      <Tooltip
                        anchorElement="target"
                        position="left"
                        parentTitle={true}
                      >
                        <Button
                          title="Filter Columns"
                          type="button"
                          themeColor={"primary"}
                          fillMode={"solid"}
                          onClick={() => setShowFilterColumns(true)}
                        >
                          Filter
                          <BsThreeDotsVertical />
                        </Button>
                      </Tooltip>
                      {showfilterColumns && (
                        <div
                          style={{
                            position: "absolute",
                            top: "32px",
                            right: "0",
                            background: "white",
                            padding: "12px",
                            border: "1px solid whitesmoke",
                            width: "250px",
                            zIndex: 2,
                          }}
                        >
                          <div className="d-flex justify-content-between align-items-baseline">
                            <p className="m-0 fw-600">Filter</p>
                            <Button
                              themeColor={"primary"}
                              fillMode={"flat"}
                              onClick={() => setShowFilterColumns(false)}
                            >
                              <RxCross2 />
                            </Button>
                          </div>
                          <hr className="m-1" style={{ color: "lightgray" }} />
                          <Field
                            wrapperClassName="d-flex flex-column checkBoxGroup-vertical"
                            inputClassName="checkBoxGroup-vertical-input"
                            id={"filter_columns"}
                            name={"filter_columns"}
                            marginRight={0}
                            marginBeetween={8}
                            component={FormCheckboxGroup}
                            options={columns}
                          />
                        </div>
                      )}
                    </div>
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{
                      maxHeight: "calc(100vh - 334px)",
                      overflow: "scroll",
                      scrollbarWidth: "thin",
                    }}
                  >
                    <table
                      cellSpacing={1}
                      cellPadding={2}
                      className="table table-bordered w-100  currentstock-print-table"
                      style={{
                        marginTop: 1,
                      }}
                    >
                      <tr
                        className="text-center fw-600"
                        style={{
                          fontSize: 12,
                          position: "sticky",
                          top: 0,
                          outline: "1px solid #3b3b3b",
                          zIndex: 1,
                        }}
                      >
                        {/* {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("debit_note_no_string") && ( */}
                        <th style={{ minWidth: 120 }}>Purchase Return No.</th>
                        {/* )} */}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("debit_note_date") && (
                          <th style={{ minWidth: 120 }}>Purchase Return Date</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("bill_no") && (
                          <th style={{ minWidth: 80 }}>Bill No.</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("bill_date") && (
                          <th style={{ minWidth: 120 }}>Bill Date</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("account_name") && (
                          <th style={{ minWidth: 250 }}>Vendor Name</th>
                        )}

                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("product_name") && (
                          <th style={{ minWidth: 250 }}>Item Name</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("quantity") && (
                          <th style={{ minWidth: 150 }}>Qty</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("free_quantity") && (
                          <th style={{ minWidth: 150 }}>Free Qty</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("rate") && (
                          <th style={{ minWidth: 150 }}>Rate</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("unit_name") && (
                          <th style={{ minWidth: 120 }}>Unit</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("discount") && (
                          <th style={{ minWidth: 80 }}>Discount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("cgst_per") && (
                          <th style={{ minWidth: 100 }}>CGST(%)</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("cgst_amount") && (
                          <th style={{ minWidth: 150 }}>CGST Amount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("sgst_per") && (
                          <th style={{ minWidth: 100 }}>SGST(%)</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("sgst_amount") && (
                          <th style={{ minWidth: 150 }}>SGST Amount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("igst_per") && (
                          <th style={{ minWidth: 100 }}>IGST(%)</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("igst_amount") && (
                          <th style={{ minWidth: 150 }}>IGST Amount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("cgst") && (
                          <th style={{ minWidth: 150 }}>Total CGST Amount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("sgst") && (
                          <th style={{ minWidth: 150 }}>Total SGST Amount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("igst") && (
                          <th style={{ minWidth: 150 }}>Total IGST Amount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("gst") && (
                          <th style={{ minWidth: 150 }}>Total GST Amount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("total_amount") && (
                          <th style={{ minWidth: 150 }}>Total Amount</th>
                        )}
                      </tr>
                      {groupedArray &&
                        groupedArray?.length > 0 &&
                        groupedArray?.map((group: any) =>
                          group.map((item: any, index: number) => {
                            const groupKey = `${item?.debit_note_guid}`;
                            const isSelected = selectedGroup === groupKey;
                            return (
                              <tr
                                className="text-center"
                                style={{
                                  background: isSelected
                                    ? "rgb(255 198 198 / 53%)"
                                    : "transparent",
                                }}
                                onClick={() =>
                                  handleRowClick(item.debit_note_guid)
                                }
                              >
                                {index === 0 && (
                                  <td
                                    rowSpan={group.length}
                                    className="text-start register-edit-column"
                                  >
                                    {item.debit_note_no_string}
                                    {isEditAccess && (
                                      <IconButton
                                        type="button"
                                        fillMode="flat"
                                        themeColor={"primary"}
                                        size="small"
                                        ref={isEditBtnRef}
                                        style={{
                                          padding: "15px",
                                          marginLeft: "5px",
                                        }}
                                        onClick={() => {
                                          isEditBtnRef.current = true;
                                          navigate("/debitnote/edit", {
                                            state: {
                                              debit_note_guid:
                                                item?.debit_note_guid,
                                            },
                                          });
                                        }}
                                      >
                                        <FiEdit
                                          className="absolute-position"
                                          style={{
                                            fontSize: "20px",
                                            zIndex: 0,
                                            color: "red",
                                          }}
                                        />
                                      </IconButton>
                                    )}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("debit_note_date") &&
                                  index === 0 && (
                                    <td
                                      rowSpan={group.length}
                                      className="text-start"
                                    >
                                      {item.debit_note_date}
                                    </td>
                                  )}

                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("bill_no") &&
                                  index === 0 && (
                                    <td rowSpan={group.length}>
                                      {item.bill_no}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("bill_date") &&
                                  index === 0 && (
                                    <td rowSpan={group.length}>
                                      {item.bill_date
                                        ? moment(
                                            item.bill_date,
                                            "DD/MM/YYYY"
                                          )?.format("DD MMM YYYY")
                                        : ""}
                                    </td>
                                  )}

                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("inward_date") &&
                                  index === 0 && (
                                    <td rowSpan={group.length}>
                                      {item.inward_date
                                        ? moment(
                                            item.inward_date,
                                            "DD/MM/YYYY"
                                          )?.format("DD MMM YYYY")
                                        : ""}
                                    </td>
                                  )}

                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("account_name") &&
                                  index === 0 && (
                                    <td
                                      rowSpan={group.length}
                                      className="text-start"
                                    >
                                      {item.account_name}
                                    </td>
                                  )}

                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("product_name") && (
                                  <td className="text-start">
                                    {item?.product_name}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("quantity") && (
                                  <td className="text-end">
                                    {formatIndianNumberForQty(item?.quantity) ||
                                      0}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("free_quantity") && (
                                  <td className="text-end">
                                    {formatIndianNumberForQty(
                                      item?.free_quantity
                                    ) || 0}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("rate") && (
                                  <td className="text-end">
                                    {formatIndianNumber(item?.rate || 0)}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("unit_name") && (
                                  <td>{item?.unit_name}</td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("discount") && (
                                  <td className="text-end">
                                    {item?.discount ? item?.discount : ""}
                                    {item.discount &&
                                      item.discount_type &&
                                      (item?.discount_type === 1
                                        ? " ₹"
                                        : item?.discount_type === 2
                                        ? " %"
                                        : "")}
                                  </td>
                                )}

                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("cgst_per") && (
                                  <td className="text-end">
                                    {item?.cgst_per
                                      ? `${item?.cgst_per} %`
                                      : ""}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("cgst_amount") && (
                                  <td className="text-end">
                                    {formatIndianNumber(item?.cgst_amount)}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("sgst_per") && (
                                  <td className="text-end">
                                    {item?.sgst_per
                                      ? `${item?.sgst_per} %`
                                      : ""}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("sgst_amount") && (
                                  <td className="text-end">
                                    {formatIndianNumber(item?.sgst_amount)}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("igst_per") && (
                                  <td className="text-end">
                                    {item?.igst_per
                                      ? `${item?.igst_per} %`
                                      : ""}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("igst_amount") && (
                                  <td className="text-end">
                                    {formatIndianNumber(item?.igst_amount)}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("cgst") &&
                                  index === 0 && (
                                    <td
                                      rowSpan={group.length}
                                      className="text-end"
                                    >
                                      {formatIndianNumber(item?.cgst || 0)}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("sgst") &&
                                  index === 0 && (
                                    <td
                                      rowSpan={group.length}
                                      className="text-end"
                                    >
                                      {formatIndianNumber(item?.sgst || 0)}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("igst") &&
                                  index === 0 && (
                                    <td
                                      rowSpan={group.length}
                                      className="text-end"
                                    >
                                      {formatIndianNumber(item?.igst)}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("gst") &&
                                  index === 0 && (
                                    <td
                                      rowSpan={group.length}
                                      className="text-end"
                                    >
                                      {formatIndianNumber(item?.gst)}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("total_amount") &&
                                  index === 0 && (
                                    <td
                                      rowSpan={group.length}
                                      className="text-end"
                                    >
                                      {formatIndianNumber(item?.total_amount)}
                                    </td>
                                  )}
                              </tr>
                            );
                          })
                        )}
                      {groupedArray &&
                        groupedArray?.length > 0 &&
                        isMatchFound && (
                          <tr
                            className="text-center"
                            style={{
                              background: "whitesmoke",
                              fontWeight: 600,
                            }}
                          >
                            <td className="text-start">Total</td>
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("invoice_no") && (
                              <td className="text-start"></td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("debit_note_date") && <td></td>}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("bill_no") && <td></td>}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("bill_date") && <td></td>}

                            {/* {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("inward_date") && (
                              <td className="text-start"></td>
                            )} */}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("account_name") && (
                              <td className="text-start"></td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("product_name") && (
                              <td className="text-start"></td>
                            )}

                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("quantity") && (
                              <td className="text-end">
                                {formatIndianNumberForQty(totalQty) || 0}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("free_quantity") && (
                              <td className="text-end">
                                {formatIndianNumberForQty(totalFreeQty) || 0}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("rate") && (
                              <td className="text-end"></td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("unit_name") && <td></td>}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("discount") && <td></td>}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("cgst_per") && (
                              <td className="text-end"></td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("cgst_amount") && (
                              <td className="text-end">
                                {formatIndianNumber(totalCGSTAmount)}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("sgst_per") && (
                              <td className="text-end"></td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("sgst_amount") && (
                              <td className="text-end">
                                {formatIndianNumber(totalSGSTAmount)}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("igst_per") && (
                              <td className="text-end"></td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("igst_amount") && (
                              <td className="text-end">
                                {formatIndianNumber(totalIGSTAmount)}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("cgst") && (
                              <td className="text-end">
                                {formatIndianNumber(GrandtotalCGSTAmount)}
                              </td>
                            )}

                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("sgst") && (
                              <td className="text-end">
                                {formatIndianNumber(GrandtotalSGSTAmount)}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("igst") && (
                              <td className="text-end">
                                {formatIndianNumber(GrandtotalIGSTAmount)}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("gst") && (
                              <td className="text-end">
                                {formatIndianNumber(GrandtotalGSTAmount)}
                              </td>
                            )}

                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("total_amount") && (
                              <td className="text-end">
                                {formatIndianNumber(GrandTotalAmount)}
                              </td>
                            )}
                          </tr>
                        )}
                    </table>
                  </GridLayoutItem>
                </GridLayout>
              </FormElement>
            );
          }}
        />
      ) : (
        <AlertBox />
      )}
    </>
  );
};
export default DebitNoteRegisterReportForm;
