import React, { useEffect } from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import {
  setCreditnoteVoucherBillDialogOpenIndex,
  setCreditnoteVoucherEntryDeleteIndex,
} from "./creditnoteVoucherSlice";
import { getAllOutstandingCreditnoteVoucher } from "./services/creditnoteVoucher";
import { useLocation } from "react-router-dom";
import {
  requiredValidator,
  skipValidator,
} from "../../components/formFields/CommonValidator";
import { SUNDRY_CREDITORS_DEBTORS } from "../../_contstants/common";

const CreditnoteVoucherEntryDetailsArray = (
  fieldArrayRenderProps: FieldArrayRenderProps
) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const credit_note_voucher_guid = location.state?.credit_note_voucher_guid;
  const CreditnoteVoucherEntryDeleteIndex = useAppSelector(
    (state) => state.creditnotevoucher.CreditnoteVoucherEntryDeleteIndex
  );

  const InputRef = React.useRef<any>(null);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  const handleDeleteAction = React.useCallback(
    async (index: number) => {
      fieldArrayRenderProps.onRemove({ index: index });
      dispatch(closeDialog());
      dispatch(setCreditnoteVoucherBillDialogOpenIndex(-1));
      const element = document.getElementsByName(
        `credit_note_voucher_entry.${index - 1}.amount`
      );
      if (element?.[0]) {
        element?.[0]?.focus();
      }
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarrayFirst = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        debit_credit: "Credit",
        ledger_id: "",
        amount: null,
        credit_note_voucher_entry_referance: [],
      },
    });

    // setTimeout(() => {
    //   InputRef.current?.element?.children[0]?.children[1]?.children[0]?.focus();
    // }, 0);
  }, [fieldArrayRenderProps]);

  const pushElementInarray = React.useCallback(
    (debitAmountTotal: number) => {
      fieldArrayRenderProps.onPush({
        value: {
          debit_credit: "Debit",
          ledger_id: "",
          amount: debitAmountTotal,
          credit_note_voucher_entry_referance: [],
        },
      });

      // setTimeout(() => {
      //   InputRef.current?.element?.children[0]?.children[1]?.children[0]?.focus();
      // }, 0);
    },
    [fieldArrayRenderProps]
  );

  const handleChange = (
    e: any,
    field: string,
    index: number,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => {
    fieldArrayRenderProps.formRenderProps.onChange(
      `credit_note_voucher_entry.${index}.credit_note_voucher_entry_referance`,
      { value: [] }
    );
    if (field === "debit_credit") {
      const formProps = fieldArrayRenderProps.formRenderProps;
      const creditDebitTotal =
        formProps.valueGetter("credit_debit_total") || {};

      const DebitTotal =
        (creditDebitTotal.DebitTotal || 0) -
        (formProps.valueGetter(`credit_note_voucher_entry.${index}.amount`) ||
          0);
      const CreditTotal =
        (creditDebitTotal.CreditTotal || 0) -
        (formProps.valueGetter(`credit_note_voucher_entry.${index}.amount`) ||
          0);

      let differenceValue: number | null = null;

      if (e?.value === "Debit") {
        differenceValue = CreditTotal - DebitTotal;
      } else {
        differenceValue = DebitTotal - CreditTotal;
      }

      // Ensure the difference is non-negative; otherwise, set it to null
      formProps.onChange(`credit_note_voucher_entry.${index}.amount`, {
        value: differenceValue >= 0 ? differenceValue : null,
      });
    }
    dispatch(setCreditnoteVoucherBillDialogOpenIndex(-1));
  };

  const handleSearchLedger = (search: any, index: any) => {
    if (search) {
      fieldArrayRenderProps.handleVendorSearchChange(
        search,
        `credit_note_voucher_entry.${index}.ledger_options`,
        fieldArrayRenderProps.formRenderProps
      );
    }
  };
  const handleKeyEvent = (
    e: any,
    index: number,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => {
    const value = parseFloat(e?.target?.value?.replace(/,/g, ""));
    const DebitTotal =
      fieldArrayRenderProps.formRenderProps.valueGetter("credit_debit_total")
        ?.DebitTotal || 0;

    const CreditTotal =
      fieldArrayRenderProps.formRenderProps.valueGetter("credit_debit_total")
        ?.CreditTotal || 0;
    if (
      e.key === "Tab" &&
      value &&
      fieldArrayRenderProps.value[index].ledger_id
    ) {
      if (!e.shiftKey) {
        const isCredit =
          fieldArrayRenderProps.value[index]?.debit_credit === "Credit";
        if (isCredit) {
          const payloadFindBill = {
            credit_note_voucher_guid: credit_note_voucher_guid
              ? credit_note_voucher_guid
              : null,
            credit_or_debit: isCredit ? "CR" : "DR",
            ledger_id: fieldArrayRenderProps.value[index].ledger_id,
          };
          const fetchBillList = async () => {
            const IsDebitNoteVoucherRefID =
              fieldArrayRenderProps.value[index]
                .credit_note_voucher_entry_referance?.[0]
                ?.credit_note_voucher_ref_id;
            const under_group_id = fieldArrayRenderProps.value[
              index
            ]?.ledger_options.filter(
              (item: any) =>
                item.id === fieldArrayRenderProps.value[index].ledger_id
            )?.[0]?.under_group_id;
            if (SUNDRY_CREDITORS_DEBTORS?.includes(under_group_id)) {
              if (
                fieldArrayRenderProps.value[index]
                  .credit_note_voucher_entry_referance.length > 0 &&
                !IsDebitNoteVoucherRefID
              ) {
                dispatch(setCreditnoteVoucherBillDialogOpenIndex(index));
                dispatch(openDialog("CreditnoteVoucherBillItemDetailsDialog"));
              } else {
                const response = await dispatch(
                  getAllOutstandingCreditnoteVoucher(payloadFindBill)
                );
                if (response.meta.requestStatus === "fulfilled") {
                  dispatch(
                    openDialog("CreditnoteVoucherBillItemDetailsDialog")
                  );
                  dispatch(setCreditnoteVoucherBillDialogOpenIndex(index));
                }
              }
            }
          };
          fetchBillList();
          if (
            fieldArrayRenderProps.value.length === index + 1 &&
            DebitTotal !== CreditTotal
          ) {
            const diffrenceValue = CreditTotal - DebitTotal;
            if (diffrenceValue >= 0) {
              pushElementInarray(diffrenceValue);
            } else {
              pushElementInarray(0);
            }
          }
        } else {
          if (DebitTotal !== CreditTotal) {
            pushElementInarray(CreditTotal - DebitTotal);
          }
        }
      }
    }
    if (
      e.key === "Escape" &&
      fieldArrayRenderProps.value.length !== 1 &&
      index !== 0
    ) {
      dispatch(openDialog("CreditnoteVoucherItemDeleteDialog"));
      dispatch(setCreditnoteVoucherEntryDeleteIndex(index));
    }
  };

  useEffect(() => {
    if (fieldArrayRenderProps.value?.length === 0) {
      pushElementInarrayFirst();
    }
  }, []);

  const PAYMENT_INITIAL = [
    {
      label: "Cr.",
      value: "Credit",
    },

    {
      label: "Dr.",
      value: "Debit",
    },
  ];

  return (
    <>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem className="bg-dark text-white fw-600">
          <GridLayout
            style={{
              padding: 10,
              marginRight: 10,
            }}
            gap={{ rows: 10, cols: 5 }}
            cols={[
              { width: "12%" },
              { width: "58%" },
              { width: "15%" },
              { width: "15%" },
            ]}
          >
            <GridLayoutItem>Initial</GridLayoutItem>
            <GridLayoutItem>Ledger Name</GridLayoutItem>
            <GridLayoutItem>Debit</GridLayoutItem>
            <GridLayoutItem>Credit</GridLayoutItem>
          </GridLayout>
        </GridLayoutItem>

        {dialogName === "CreditnoteVoucherItemDeleteDialog" && (
          <AppDialog>
            <>
              <Typography.h5>{"Delete Item"}</Typography.h5>
              <GridLayout>
                <GridLayoutItem>
                  <Typography.p>
                    Are you sure you want to delete this?
                  </Typography.p>
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    fillMode={"solid"}
                    themeColor={"error"}
                    onClick={() =>
                      handleDeleteAction(CreditnoteVoucherEntryDeleteIndex)
                    }
                    type="button"
                    style={{ marginRight: 5 }}
                  >
                    Delete
                  </Button>
                  <DialogCloseButton themeColor="error" />
                </GridLayoutItem>
              </GridLayout>
            </>
          </AppDialog>
        )}

        {fieldArrayRenderProps.value &&
          fieldArrayRenderProps.value.length > 0 &&
          fieldArrayRenderProps.value.map(
            (credit_note_voucher_entry: any, index: number) => (
              <>
                <GridLayoutItem key={index}>
                  <GridLayout
                    style={{
                      marginRight: 10,
                      padding: 10,
                    }}
                    gap={{ rows: 10, cols: 5 }}
                    cols={[
                      { width: "12%" },
                      { width: "35%" },
                      { width: "23%" },
                      { width: "15%" },
                      { width: "15%" },
                    ]}
                  >
                    <GridLayoutItem>
                      <Field
                        // wrapperClassName="right-alighned-field"
                        wrapperStyle={{ margin: 0 }}
                        name={`credit_note_voucher_entry.${index}.debit_credit`}
                        id={`credit_note_voucher_entry.${index}.debit_credit_id`}
                        format="n2"
                        className="hide-clear-action"
                        onChange={(e: any) =>
                          handleChange(
                            e,
                            "debit_credit",
                            index,
                            fieldArrayRenderProps
                          )
                        }
                        component={FormSelectionField}
                        disabled={index === 0}
                        options={PAYMENT_INITIAL.map((init: any) => {
                          return {
                            value: init?.value,
                            label: init?.label,
                          };
                        })}
                      />
                    </GridLayoutItem>

                    <GridLayoutItem ref={InputRef}>
                      <Field
                        wrapperStyle={{ margin: 0 }}
                        name={`credit_note_voucher_entry.${index}.ledger_id`}
                        placeholder="search ledger"
                        component={FormIncrementalSearch}
                        onChange={(e: any) =>
                          handleChange(
                            e,
                            "ledger_id",
                            index,
                            fieldArrayRenderProps
                          )
                        }
                        fetchIncrementalData={(search: any) =>
                          handleSearchLedger(search, index)
                        }
                        options={
                          fieldArrayRenderProps.value[
                            index
                          ]?.ledger_options?.map((item: any) => {
                            return {
                              value: item?.id,
                              label: item?.account_name,
                            };
                          }) || []
                        }
                        validator={
                          fieldArrayRenderProps.value?.[index]?.amount
                            ? requiredValidator
                            : skipValidator
                        }
                      />
                    </GridLayoutItem>
                    <GridLayoutItem></GridLayoutItem>
                    <GridLayoutItem>
                      {fieldArrayRenderProps.value[index]?.debit_credit ===
                        "Debit" && (
                        <Field
                          wrapperClassName="right-alighned-field"
                          wrapperStyle={{ margin: 0 }}
                          name={`credit_note_voucher_entry.${index}.amount`}
                          placeholder="0"
                          format="n2"
                          component={FormNumericTextField}
                          onKeyDown={(e: any) =>
                            handleKeyEvent(e, index, fieldArrayRenderProps)
                          }
                        />
                      )}
                    </GridLayoutItem>
                    <GridLayoutItem>
                      {fieldArrayRenderProps.value[index]?.debit_credit ===
                        "Credit" && (
                        <Field
                          wrapperClassName="right-alighned-field"
                          wrapperStyle={{ margin: 0 }}
                          name={`credit_note_voucher_entry.${index}.amount`}
                          placeholder="0"
                          format="n2"
                          component={FormNumericTextField}
                          onKeyDown={(e: any) =>
                            handleKeyEvent(e, index, fieldArrayRenderProps)
                          }
                        />
                      )}
                    </GridLayoutItem>
                  </GridLayout>
                </GridLayoutItem>
                {/* dialogName !== "CreditnoteVoucherBillItemDetailsDialog" && */}
                {fieldArrayRenderProps.value?.[
                  index
                ]?.credit_note_voucher_entry_referance?.filter(
                  (pay_ref: any) => pay_ref?.referance_amount
                ).length > 0 && (
                  <GridLayoutItem>
                    <GridLayout
                      style={{
                        marginRight: 10,
                        padding: "0 30px",
                      }}
                      gap={{ rows: 5, cols: 5 }}
                    >
                      {fieldArrayRenderProps.value?.[
                        index
                      ]?.credit_note_voucher_entry_referance
                        ?.filter((pay_ref: any) => pay_ref?.referance_amount)
                        .map((item: any, ind: number) => (
                          <GridLayoutItem key={ind}>
                            <div>
                              <span
                                style={{
                                  fontWeight: "bold",
                                  width: "15px",
                                  display: "inline-block",
                                }}
                              >
                                {ind + 1}
                              </span>
                              <span
                                style={{
                                  fontWeight: "bold",
                                  marginLeft: "10px",
                                  background: "#e9e9e9",
                                  padding: "2px",
                                  width: "200px",
                                  display: "inline-block",
                                }}
                              >
                                {item?.bill_no_string
                                  ? item?.bill_no_string
                                  : item?.refernace_name}
                              </span>
                              <span
                                style={{
                                  marginLeft: "20px",
                                  fontWeight: "bold",
                                }}
                              >
                                {item?.referance_amount} Dr.
                              </span>
                            </div>
                          </GridLayoutItem>
                        ))}
                    </GridLayout>
                  </GridLayoutItem>
                )}
                <div
                  style={{
                    background: "goldenrod",
                    height: "1px",
                    opacity: "1",
                    margin: "10px 0",
                  }}
                />
              </>
            )
          )}

        <GridLayoutItem style={{ borderTop: "1px solid #ccc", marginTop: 10 }}>
          <GridLayout
            style={{
              marginRight: 10,
              padding: 10,
            }}
            gap={{ rows: 10, cols: 5 }}
            cols={[
              { width: "8%" },
              { width: "62%" },
              { width: "15%" },
              { width: "15%" },
            ]}
          >
            <GridLayoutItem colSpan={2}></GridLayoutItem>
            <GridLayoutItem>
              <h5
                style={{
                  textAlign: "end",
                  fontWeight: "bold",
                }}
              >
                ₹{" "}
                {fieldArrayRenderProps.formRenderProps.valueGetter(
                  "credit_debit_total"
                )?.DebitTotal || 0}
              </h5>
            </GridLayoutItem>
            <GridLayoutItem>
              <h5
                style={{
                  textAlign: "end",
                  fontWeight: "bold",
                }}
              >
                ₹{" "}
                {fieldArrayRenderProps.formRenderProps.valueGetter(
                  "credit_debit_total"
                )?.CreditTotal || 0}
              </h5>
            </GridLayoutItem>
          </GridLayout>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default CreditnoteVoucherEntryDetailsArray;
