import React, { useEffect } from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import {
  setReceiptBillDialogOpenIndex,
  setReceiptEntryDeleteIndex,
} from "./receiptSlice";
import { getAllOutstandingReceipt } from "./services/receipt.services";
import { useLocation } from "react-router-dom";
import {
  requiredValidator,
  skipValidator,
} from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import { SUNDRY_CREDITORS_DEBTORS } from "../../_contstants/common";

const ReceiptEntryDetailsArray = (
  fieldArrayRenderProps: FieldArrayRenderProps
) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const receipt_guid = location.state?.receipt_guid;
  const ReceiptEntryDeleteIndex = useAppSelector(
    (state) => state.receipt.ReceiptEntryDeleteIndex
  );

  const InputRef = React.useRef<any>(null);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  const handleDeleteAction = React.useCallback(
    async (index: number) => {
      fieldArrayRenderProps.onRemove({ index: index });
      dispatch(closeDialog());
      dispatch(setReceiptEntryDeleteIndex(-1));
      const element = document.getElementsByName(
        `receipt_entry.${index - 1}.amount`
      );
      if (element?.[0]) {
        element?.[0]?.focus();
      }
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarrayFirst = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        debit_credit: "Credit",
        ledger_id: "",
        amount: null,
        receipt_entry_referance: [],
      },
    });

    // setTimeout(() => {
    //   InputRef.current?.element?.children[0]?.children[1]?.children[0]?.focus();
    // }, 0);
  }, [fieldArrayRenderProps]);

  const handleSearchLedger = (search: any, index: any) => {
    if (search) {
      fieldArrayRenderProps.handleVendorSearchChange(
        search,
        `receipt_entry.${index}.ledger_options`,
        fieldArrayRenderProps.formRenderProps
      );
    }
  };

  const handleChange = (index: number, fieldArrayRenderProps: any) => {
    fieldArrayRenderProps.formRenderProps.onChange(
      `receipt_entry.${index}.receipt_entry_referance`,
      {
        value: [],
      }
    );
    fieldArrayRenderProps.formRenderProps.onChange(
      `receipt_entry.${index}.cheque_no`,
      {
        value: null,
      }
    );
    fieldArrayRenderProps.formRenderProps.onChange(
      `receipt_entry.${index}.bank_name`,
      {
        value: "",
      }
    );
    fieldArrayRenderProps.formRenderProps.onChange(
      `receipt_entry.${index}.favouring_name`,
      {
        value: "",
      }
    );
    const debit_credit = fieldArrayRenderProps.formRenderProps.valueGetter(
      `receipt_entry.${index}.debit_credit`
    );

    if (debit_credit === "Credit") {
      fieldArrayRenderProps.formRenderProps.onChange(
        `receipt_entry.${index}.amount`,
        {
          value: null,
        }
      );
    }

    dispatch(setReceiptBillDialogOpenIndex(-1));
  };
  const handleKeyEvent = (
    e: any,
    index: number,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => {
    const value = parseFloat(e?.target?.value?.replace(/,/g, ""));
    if (
      e.key === "Tab" &&
      value &&
      fieldArrayRenderProps.value[index].ledger_id
    ) {
      if (!e.shiftKey) {
        // const isSundryCreditorDebtors = SUNDRY_CREDITORS_DEBTORS?.includes(
        //   fieldArrayRenderProps.value[index]?.ledger_options?.find(
        //     (ledger: any) =>
        //       ledger.id === fieldArrayRenderProps.value[index]?.ledger_id
        //   )?.under_group_id
        // );
        const fetchBillList = async () => {
          const payloadFindBill = {
            receipt_guid: receipt_guid ? receipt_guid : null,
            credit_or_debit: "CR",
            ledger_id: fieldArrayRenderProps.value[index].ledger_id,
          };
          const IsReceiptRefID =
            fieldArrayRenderProps.value[index].receipt_entry_referance?.[0]
              ?.receipt_ref_id;
          if (
            fieldArrayRenderProps.value[index].receipt_entry_referance.length >
              0 &&
            !IsReceiptRefID
          ) {
            dispatch(setReceiptBillDialogOpenIndex(index));
            dispatch(openDialog("CreditBillItemDialog"));
          } else {
            const response = await dispatch(
              getAllOutstandingReceipt(payloadFindBill)
            );
            if (response.meta.requestStatus === "fulfilled") {
              dispatch(openDialog("CreditBillItemDialog"));
              dispatch(setReceiptBillDialogOpenIndex(index));
            }
          }
        };
        // if (isSundryCreditorDebtors) {
        // fetchBillList();
        // }
        fetchBillList();
        if (fieldArrayRenderProps.value.length === index + 1) {
          pushElementInarrayFirst();
        }
      }
    }
    if (
      e.key === "Escape" &&
      fieldArrayRenderProps.value.length !== 1 &&
      index !== 0
    ) {
      dispatch(openDialog("CreditReceiptItemDeleteDialog"));
      dispatch(setReceiptEntryDeleteIndex(index));
    }
  };

  useEffect(() => {
    if (fieldArrayRenderProps.value?.length === 0) {
      pushElementInarrayFirst();
    }
  }, []);

  return (
    <>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem className="bg-dark text-white fw-600">
          <GridLayout
            style={{
              padding: 10,
              marginRight: 10,
            }}
            gap={{ rows: 10, cols: 5 }}
            cols={[{ width: "60%" }, { width: "20%" }, { width: "20%" }]}
          >
            <GridLayoutItem>Ledger Name</GridLayoutItem>
            <GridLayoutItem></GridLayoutItem>
            <GridLayoutItem style={{ textAlign: "end", marginRight: "10px" }}>
              Amount
            </GridLayoutItem>
          </GridLayout>
        </GridLayoutItem>

        {dialogName === "CreditReceiptItemDeleteDialog" && (
          <AppDialog>
            <>
              <Typography.h5>{"Delete Item"}</Typography.h5>
              <GridLayout>
                <GridLayoutItem>
                  <Typography.p>
                    Are you sure you want to delete this?
                  </Typography.p>
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    fillMode={"solid"}
                    themeColor={"error"}
                    onClick={() => handleDeleteAction(ReceiptEntryDeleteIndex)}
                    type="button"
                    style={{ marginRight: 5 }}
                  >
                    Delete
                  </Button>
                  <DialogCloseButton themeColor="error" />
                </GridLayoutItem>
              </GridLayout>
            </>
          </AppDialog>
        )}
        {fieldArrayRenderProps.value &&
          fieldArrayRenderProps.value.length > 0 &&
          fieldArrayRenderProps.value.map(
            (receipt_entry: any, index: number) => (
              <>
                <GridLayoutItem key={index}>
                  <GridLayout
                    style={{
                      marginRight: 10,
                      padding: 10,
                    }}
                    gap={{ rows: 10, cols: 5 }}
                    cols={[
                      { width: "35%" },
                      { width: "45%" },
                      { width: "20%" },
                    ]}
                  >
                    <GridLayoutItem ref={InputRef}>
                      <Field
                        wrapperStyle={{ margin: 0 }}
                        name={`receipt_entry.${index}.ledger_id`}
                        placeholder="search ledger"
                        isAddNew={true}
                        addNewLink="account/create"
                        id={`receipt_entry.${index}.ledger_id_id`}
                        component={FormIncrementalSearch}
                        onChange={() =>
                          handleChange(index, fieldArrayRenderProps)
                        }
                        fetchIncrementalData={(search: any) =>
                          handleSearchLedger(search, index)
                        }
                        validator={
                          fieldArrayRenderProps.value[index].amount
                            ? requiredValidator
                            : skipValidator
                        }
                        options={
                          fieldArrayRenderProps.value[
                            index
                          ]?.ledger_options?.map((item: any) => {
                            return {
                              value: item?.id,
                              label: item?.account_name,
                            };
                          }) || []
                        }
                      />
                    </GridLayoutItem>
                    <GridLayoutItem></GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        wrapperClassName="right-alighned-field"
                        wrapperStyle={{ margin: 0 }}
                        name={`receipt_entry.${index}.amount`}
                        placeholder="0"
                        format="n2"
                        component={FormNumericTextField}
                        onKeyDown={(e: any) =>
                          handleKeyEvent(e, index, fieldArrayRenderProps)
                        }
                      />
                    </GridLayoutItem>
                  </GridLayout>
                </GridLayoutItem>
                {SUNDRY_CREDITORS_DEBTORS.includes(
                  fieldArrayRenderProps.value[index]?.ledger_options?.find(
                    (ledger: any) =>
                      ledger.id ===
                      fieldArrayRenderProps.value[index]?.ledger_id
                  )?.under_group_id
                ) && (
                  <GridLayoutItem>
                    <GridLayout
                      style={{
                        marginRight: 10,
                      }}
                      gap={{ rows: 5, cols: 5 }}
                      cols={[
                        { width: "15%" },
                        { width: "25%" },
                        { width: "25%" },
                        { width: "35%" },
                      ]}
                    >
                      <GridLayoutItem>
                        <Field
                          wrapperStyle={{ margin: 0, padding: "5px 10px" }}
                          id={`receipt_entry.${index}.cheque_no_id`}
                          name={`receipt_entry.${index}.cheque_no`}
                          max={6}
                          placeholder="Cheque No"
                          type={"number"}
                          component={FormTextField}
                        />
                      </GridLayoutItem>
                      <GridLayoutItem>
                        <Field
                          wrapperStyle={{ margin: 0, padding: "5px 10px" }}
                          name={`receipt_entry.${index}.bank_name`}
                          placeholder="Bank Name"
                          component={FormTextField}
                        />
                      </GridLayoutItem>
                      <GridLayoutItem>
                        <Field
                          wrapperStyle={{ margin: 0, padding: "5px 10px" }}
                          name={`receipt_entry.${index}.favouring_name`}
                          placeholder="Favoring Name"
                          component={FormTextField}
                        />
                      </GridLayoutItem>
                    </GridLayout>
                  </GridLayoutItem>
                )}
                {fieldArrayRenderProps.value?.[
                  index
                ]?.receipt_entry_referance?.filter(
                  (pay_ref: any) => pay_ref?.referance_amount
                ).length > 0 && (
                  <GridLayoutItem>
                    <GridLayout
                      style={{
                        marginRight: 10,
                        padding: "0 30px",
                      }}
                      gap={{ rows: 5, cols: 5 }}
                    >
                      {fieldArrayRenderProps.value?.[
                        index
                      ]?.receipt_entry_referance
                        ?.filter((pay_ref: any) => pay_ref?.referance_amount)
                        .map((item: any, ind: number) => (
                          <GridLayoutItem key={ind}>
                            <div>
                              <span
                                style={{
                                  fontWeight: "bold",
                                  width: "15px",
                                  display: "inline-block",
                                }}
                              >
                                {ind + 1}
                              </span>
                              <span
                                style={{
                                  fontWeight: "bold",
                                  marginLeft: "10px",
                                  background: "#e9e9e9",
                                  padding: "2px",
                                  width: "200px",
                                  display: "inline-block",
                                }}
                              >
                                {item?.bill_no_string
                                  ? item?.bill_no_string
                                  : item?.refernace_name}
                              </span>
                              <span
                                style={{
                                  marginLeft: "20px",
                                  fontWeight: "bold",
                                }}
                              >
                                {item?.referance_amount} Cr.
                              </span>
                            </div>
                          </GridLayoutItem>
                        ))}
                    </GridLayout>
                  </GridLayoutItem>
                )}

                <div
                  style={{
                    background: "goldenrod",
                    height: "1px",
                    opacity: "1",
                    margin: "10px 0",
                  }}
                />
              </>
            )
          )}

        <GridLayoutItem style={{ borderTop: "1px solid #ccc", marginTop: 10 }}>
          <GridLayout
            style={{
              marginRight: 10,
              padding: 10,
            }}
            gap={{ rows: 10, cols: 5 }}
            cols={[
              { width: "8%" },
              { width: "62%" },
              { width: "15%" },
              { width: "15%" },
            ]}
          >
            <GridLayoutItem colSpan={3}></GridLayoutItem>
            <GridLayoutItem>
              <h5
                style={{
                  textAlign: "end",
                  fontWeight: "bold",
                }}
              >
                ₹{" "}
                {fieldArrayRenderProps.formRenderProps.valueGetter(
                  "receipt_total_amount"
                ) || 0}
              </h5>
            </GridLayoutItem>
          </GridLayout>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default ReceiptEntryDetailsArray;
