import React, { useCallback, useEffect, useState } from "react";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import ShadowCard from "../../components/common/ShadowCard";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { Button } from "@progress/kendo-react-buttons";
import { useNavigate } from "react-router-dom";
import {
  generateDateRange,
  getAccountingYear,
  getCalendarWeeks,
  getCurrentYearMonths,
} from "../../_helper/helper";
import SalesRepresentativeDetailsArray from "./SalesRepresentativeDetailsArray";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  createSalesRespresentative,
  getAllDistributorWithArea,
  getAllSalesRepresentative,
} from "./services/salesRepresentative.services";
// import { findIndex } from "lodash";
import moment from "moment";
import { clearSalesRepresentativeDetail } from "./salesRepresentativeSlice";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const MonthChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const monthID = formRenderProps.valueGetter("monthID");
  const weekID = formRenderProps.valueGetter("weekID");
  const SalesRepresentativeList = useAppSelector(
    (state) => state.salesrepresentative.SalesRepresentativeList
  );
  type Date = { date: string };

  function combineArraysWithDynamicKeys(
    dateArray: Date[],
    salesRepresentativeArray: any[]
  ) {
    return salesRepresentativeArray.map((rep) => ({
      sr_name: `${rep.first_name || ""} ${rep.middle_name || ""} ${
        rep.last_name || ""
      }`,
      sr_id: rep.id,
      distributor_details: JSON.parse(JSON.stringify([...dateArray])),
    }));
  }

  useEffect(() => {
    if (monthID) {
      formRenderProps.onChange("weekID", {
        value: "",
      });
      const AccountingMonth = getCalendarWeeks(monthID);
      formRenderProps.onChange("weeklist", {
        value: AccountingMonth || [],
      });
    } else {
      formRenderProps.onChange("weekID", {
        value: "",
      });
    }
  }, [monthID]);

  useEffect(() => {
    if (weekID) {
      const DateArray = weekID.split(" to ");
      const DaysList = generateDateRange(DateArray?.[0], DateArray?.[1]);
      const sales_representative_array = combineArraysWithDynamicKeys(
        DaysList,
        SalesRepresentativeList
      );

      formRenderProps.onChange("sales_representative", {
        value: sales_representative_array || [],
      });
    }
  }, [weekID]);

  return null;
};

const CreateSalesRepresentativeAssign: React.FC = () => {
  const navigate = useNavigate();
  const AccountingYear = getAccountingYear();
  const [accountingMonths, setAccountingMonths] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const [formKey, setFormKey] = useState(1);
  const SalesRepresentativeDetail = useAppSelector(
    (state) => state.salesrepresentative.SalesRepresentativeDetail
  );

  useEffect(() => {
    dispatch(getAllSalesRepresentative());

    return () => {
      dispatch(clearSalesRepresentativeDetail());
    };
  }, []);

  useEffect(() => {
    if (AccountingYear) {
      const AccountingMonth = getCurrentYearMonths(new Date().getMonth());
      setAccountingMonths(AccountingMonth || []);
    }
  }, [AccountingYear]);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, []);

  const handleDistributorSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const selectedVendor =
        formRenderProps.valueGetter(`${field}.area_assign_id`) || [];
      const prevMergerOption =
        formRenderProps
          .valueGetter(`${field}.vendor_options`)
          ?.filter((item: any) => selectedVendor.includes(item?.id)) || [];
      const result = await dispatch(getAllDistributorWithArea(search));
      const newoption = result.payload || [];

      const mergedResult = [...newoption, ...prevMergerOption].reduce(
        (acc, current) => {
          const isExisting = acc.find((item: any) => item.id === current.id);
          if (!isExisting) {
            acc.push(current);
          }
          return acc;
        },
        []
      );
      formRenderProps.onChange(`${field}.vendor_options`, {
        value: mergedResult || [],
      });
    },
    [dispatch]
  );

  const handleSubmit = async (values: any) => {
    try {
      const formdata: any = {
        sales_representative_details: values?.sales_representative
          ? values?.sales_representative?.flatMap((item: any) =>
              item?.distributor_details
                .filter(
                  (detail: any) =>
                    detail?.area_assign_id && detail?.area_assign_id?.length > 0
                )
                .map((detail: any) => ({
                  sr_id: item?.sr_id,
                  date: moment(detail?.date, "DD/MM/YYYY").format("YYYY-MM-DD"),
                  area_assign_id: Array.isArray(detail?.area_assign_id)
                    ? detail?.area_assign_id.join(", ")
                    : detail?.area_assign_id,
                }))
            )
          : [],
      };
      const response = await dispatch(createSalesRespresentative(formdata));
      if (response.meta.requestStatus === "fulfilled") {
        navigate("/salesrepresentativeschedule");
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  return (
    <Form
      key={formKey}
      onSubmit={handleSubmit}
      initialValues={SalesRepresentativeDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement>
          <ShadowCard style={{ padding: 12 }}>
            <GridLayout
              style={{ marginRight: 30 }}
              gap={{ rows: 10, cols: 10 }}
              cols={[
                { width: "25%" },
                { width: "25%" },
                { width: "25%" },
                { width: "25%" },
              ]}
            >
              <GridLayoutItem colSpan={4}>
                <Typography.h4>
                  Add Sales Representative
                  {/* {sale_order_guid ? "Update Sales Order" : "Add Sales Order"} */}
                </Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={"monthID"}
                  placeholder="Select Month"
                  component={FormSelectionField}
                  validator={requiredValidator}
                  options={accountingMonths?.map((month: any) => {
                    return {
                      value: month?.value,
                      label: month?.label,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={"weekID"}
                  placeholder="Select Week"
                  component={FormSelectionField}
                  validator={requiredValidator}
                  options={formRenderProps
                    .valueGetter("weeklist")
                    ?.map((weeks: any) => {
                      return {
                        value: weeks?.value,
                        label: weeks?.label,
                      };
                    })}
                />
              </GridLayoutItem>
              {/* <GridLayoutItem>
                <Field
                  name="vendor_id"
                  placeholder="Select Distributor"
                  // onChange={(e: any) =>
                  //   handleVendorChange(e, formRenderProps)
                  // }
                  // isAddNew={true}
                  // addNewLink="account/create"
                  component={FormIncrementalMultiSelectionFiled}
                  // validator={requiredValidator}
                  // astrike={true}
                  fetchIncrementalData={(search: any) =>
                    handleDistributorSearchChange(
                      search,
                      `vendor_options`,
                      formRenderProps
                    )
                  }
                  options={
                    formRenderProps
                      .valueGetter("vendor_options")
                      ?.map((item: any) => {
                        return {
                          value: item.id,
                          label: `${item.distributor_name} - ${item.area_name}`,
                        };
                      }) || []
                  }
                />
              </GridLayoutItem> */}
            </GridLayout>
          </ShadowCard>
          {formRenderProps.valueGetter("monthID") &&
            formRenderProps.valueGetter("weekID") && (
              <ShadowCard style={{ padding: 12, marginTop: 10 }}>
                <GridLayout
                  style={{ marginRight: 30 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                  ]}
                >
                  <GridLayoutItem colSpan={4}>
                    <FieldArray
                      formRenderProps={formRenderProps}
                      handleDistributorSearchChange={
                        handleDistributorSearchChange
                      }
                      component={SalesRepresentativeDetailsArray}
                      name="sales_representative"
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    colSpan={4}
                    style={{
                      textAlign: "end",
                      marginTop: "20px",
                      width: "100%",
                    }}
                  >
                    <ButtonWithLoading
                      label={"Save"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit}
                      //   loading={loading}
                    />
                    <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginLeft: 4 }}
                      onClick={() => navigate("/salesrepresentativeschedule")}
                    >
                      Cancel
                    </Button>
                  </GridLayoutItem>
                </GridLayout>
              </ShadowCard>
            )}
          <MonthChangeWatcher formRenderProps={formRenderProps} />
        </FormElement>
      )}
    />
  );
};

export default CreateSalesRepresentativeAssign;
