import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IModeOfTransport } from "../modeOfTransportModel";

export const getAllModeOfTransport = createAsyncThunk(
  "ModeOfTransport/FindAllModeOfTransport",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ModeOfTransport/FindAllModeOfTransport`,
        {
          ModeOfTransport: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching Mode Of Transport:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createModeOfTransport = createAsyncThunk(
  "ModeOfTransport/InsertModeOfTransport",
  async (ModeOfTransport: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ModeOfTransport/InsertModeOfTransport`,
        ModeOfTransport
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Mode Of Transport:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateModeOfTransport = createAsyncThunk(
  "ModeOfTransport/UpdateModeOfTransport",
  async (ModeOfTransport: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ModeOfTransport/UpdateModeOfTransport`,
        ModeOfTransport
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Mode Of Transport:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getModeOfTransportById = createAsyncThunk(
  "ModeOfTransport/FindByIDModeOfTransport",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ModeOfTransport/FindByIDModeOfTransport`,
        {
          mode_of_transport_guid: ID,
        }
      );
      // SuccessToast(response.data?.Details || "Success")
      const result = response.data?.Data as any;
      return {
        ID: result.mode_of_transport_guid,
        mode_of_transport: result.mode_of_transport,
        isactive: result.isactive === true ? 1 : 2,
      } as IModeOfTransport;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Mode Of Transport:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteModeOfTransport = createAsyncThunk(
  "ModeOfTransport/DeleteModeOfTransport",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ModeOfTransport/DeleteModeOfTransport`,
        {
          mode_of_transport_guid: ID,
        }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting Mode Of Transport:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveModeOfTransport = createAsyncThunk(
  "ModeOfTransport/FindAllActiveModeOfTransport",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/ModeOfTransport/FindAllActiveModeOfTransport`
        
      );
      // SuccessToast(response.data?.Details || "Success")
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting Mode Of Transport:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveModeOfTransport = createAsyncThunk(
  "ModeOfTransport/ActiveInActiveModeOfTransport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ModeOfTransport/ActiveInActiveModeOfTransport`,
                    
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive Mode Of Transport:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
