import { Button } from "@progress/kendo-react-buttons";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import {
  GridCellProps,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  Grid as KendoGrid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Switch } from "@progress/kendo-react-inputs";
import { LoadingPanel } from "../../components/layout/Loading";
import AlertBox from "../../components/common/AlertBox";
import {
  AccountActiveInactive,
  deleteAccount,
  getAccountByID,
  getAllAccounts,
  updateAccountDetails,
} from "./services/account.services";
import {
  clearAccountDetails,
  clearAccountGuID,
  clearAccountID,
  setAccountGuID,
  setAccountID,
} from "./accountSlice";
import { FiEdit } from "react-icons/fi";
import { checkAcessRights, getLocalStorageItem } from "../../_helper/helper";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  INSERT_ACCESS,
} from "../../_contstants/common";
import { MdAssignmentAdd } from "react-icons/md";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import {
  passwordValidator,
  requiredValidator,
} from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import FormPasswordField from "../../components/formFields/FormPasswordField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { FaUserPlus } from "react-icons/fa";
import { FaUserPen } from "react-icons/fa6";
import { DAYS } from "./CreateAccount";
import { resetUserPassword } from "../user/services/user.services";
// import AccountTab from "./AccountTab";

const Account: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);

  useEffect(() => {
    dispatch(getAllAccounts());
    return () => {
      dispatch(closeDialog());
      dispatch(clearAccountDetails());
    };
  }, []);

  const handleAccountCreate = () => {
    navigate("/account/create");
  };

  return (
    <>
      <DeleteAccountDialog />
      <AddEditAccountDialog />
      <ResetUserPasswordDialog />
      {/* <GridLayoutItem colSpan={3} style={{marginBottom:"10px"}}>
        <AccountTab />
      </GridLayoutItem> */}
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Account List
          </Typography.h4>

          {isAddAccess && (
            <Button
              onClick={handleAccountCreate}
              fillMode={"solid"}
              themeColor={"primary"}
            >
              Add New
            </Button>
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <AccountGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 50 };

const AccountGridComponent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);

  const AccountList = useAppSelector((state) => state.account.AccountList);
  const loading = useAppSelector((state) => state.account.loading);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();
  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? AccountList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEditAccount = (ID: number) => {
      navigate("/account/edit", {
        state: { account_guid: ID, id: props.dataItem?.id },
      });
    };

    const handleOpenDeleteDialog = (ID: any) => {
      dispatch(openDialog("deleteAccount"));
      dispatch(setAccountGuID(ID));
    };

    const handleAddOldData = (ID: number) => {
      navigate("/account/adddistributordata", {
        state: { id: ID },
      });
    };

    const handleOpenAddAccountDialog = async (GuID: string, ID: number) => {
      const payload = {
        account_guid: GuID,
      };
      const res = await dispatch(getAccountByID(payload));
      if (res.meta?.requestStatus === "fulfilled") {
        dispatch(openDialog("addEditAccount"));
        dispatch(setAccountGuID(GuID));
        dispatch(setAccountID(ID));
      }
    };

    return (
      <td className={`action-td ${props.className}`} style={props.style}>
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            onClick={() => handleEditAccount(props.dataItem.account_guid)}
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() => handleOpenDeleteDialog(props.dataItem.account_guid)}
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Add Data"
          onClick={() => handleAddOldData(props.dataItem?.id)}
        >
          <MdAssignmentAdd
            className="absolute-position"
            style={{ fontSize: "24px" }}
          />
        </IconButton>
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title={props.dataItem?.user_name ? "Edit" : "Add"}
          onClick={() =>
            handleOpenAddAccountDialog(
              props.dataItem?.account_guid,
              props.dataItem?.id
            )
          }
        >
          {props.dataItem?.user_name ? (
            <FaUserPen
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          ) : (
            <FaUserPlus
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          )}
        </IconButton>
      </td>
    );
  };
  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${
        props.index === 5 || props.index === 6 ? "locked-header" : ""
      }`}
    >
      {props.children}
    </HeaderThElement>
  );
  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };
  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );
  const MyActiveInActtiveCommandCell = (props: GridCellProps) => {
    const [IsActive, setIsActive] = useState<boolean>(props.dataItem?.isactive);

    const handleActiveInActive = () => {
      setIsActive(!IsActive);
      const data = {
        account_guid: props.dataItem.account_guid,
        isactive: !IsActive,
      };
      dispatch(AccountActiveInactive(data)).then((response: any) => {
        if (response.payload?.data?.Status === 200) {
          dispatch(getAllAccounts());
        } else {
          dispatch(getAllAccounts());
        }
      });
    };

    return (
      <td
        className={props.className}
        style={{ textAlign: "center", ...props.style }}
      >
        <Tooltip anchorElement="target" position="top" parentTitle={true}>
          <div title={`Set ${IsActive ? "Inactive" : "Active"}.`}>
            <Switch
              disabled={isEditAccess ? false : true}
              name="isactive"
              checked={IsActive}
              onChange={handleActiveInActive}
              size="small"
            />
          </div>
        </Tooltip>
      </td>
    );
  };

  const gridRef = useRef<any>(null);
  // if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      {AccountList && AccountList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          className="responsive-table"
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(AccountList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : AccountList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={AccountList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [10, 25, 50, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="account_name"
            title="Account Name"
            width={250}
            cell={(props: any) => (
              <td>
                <div
                  className={isEditAccess ? "hoverableName" : "normalName"}
                  onClick={() =>
                    isEditAccess &&
                    navigate("/account/edit", {
                      state: { account_guid: props.dataItem?.account_guid },
                    })
                  }
                >
                  {props.dataItem?.account_name}
                </div>
              </td>
            )}
          />
          <Column field="mobile_number" title="Mobile No" width={200} />
          <Column field="under_group_name" title="Under Group" width={200} />
          <Column field="city_name" title="City" width={200} />
          <Column field="state_name" title="State" width={200} />
          {/* <Column field="opening_balance" title="Opening Balance" /> */}
          {/* <Column field="production_by" title="Production By" /> */}
          <Column
            field="isactive"
            title="Status"
            cell={MyActiveInActtiveCommandCell}
            width={"90px"}
            filterable={false}
            locked={true}
          />
          {(isEditAccess || isDeleteAccess) && (
            <Column
              field="account_guid"
              title="Actions"
              cell={MyEditCommandCell}
              width={"170px"}
              filterable={false}
              locked={true}
            />
          )}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteAccountDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const AccountGuID = useAppSelector((state) => state.account.AccountGuID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteAccount(ID));
      if (response.payload?.Data.Status === 200) {
        dispatch(getAllAccounts());
        dispatch(closeDialog());
        dispatch(clearAccountDetails());
      } else {
        dispatch(getAllAccounts());
        dispatch(closeDialog());
        dispatch(clearAccountDetails());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteAccount" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Account ?"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Account?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => {
                    handleDeleteAction(AccountGuID);
                  }}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

const AddEditAccountDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const AccountGuID = useAppSelector((state) => state.account.AccountGuID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const loading = useAppSelector((state) => state.account.loading);
  const AccountDetail = useAppSelector((state) => state.account.AccountDetail);
  const UserTypeID = getLocalStorageItem("UserTypeID");

  const handleResetPassword = async () => {
    dispatch(openDialog("resetAccountPassword"));
  };

  const handleSubmit = async (values: any) => {
    try {
      const payload = {
        account_guid: AccountGuID,
        user_type_id: values?.user_type_id ? values?.user_type_id : null,
        user_name: values?.user_name ? values?.user_name : "",
        password: values?.password ? values?.password : "",
        delivery_day: values?.delivery_day ? values?.delivery_day : "",
        order_day: values?.order_day ? values?.order_day : "",
      };
      const response = await dispatch(updateAccountDetails(payload));
      if (response?.meta?.requestStatus === "fulfilled") {
        dispatch(closeDialog());
        dispatch(clearAccountDetails());
        dispatch(clearAccountGuID());
        dispatch(getAllAccounts());
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const formCancelButton = () => {
    dispatch(closeDialog());
    dispatch(clearAccountDetails());
  };

  return (
    <>
      {dialogName === "addEditAccount" && (
        <AppDialog className="driver-expense-dialog">
          <>
            <Typography.h4
              className="m-0"
              style={{
                borderBottom: "1px solid lightgray",
                paddingBottom: "10px",
              }}
            >
              {AccountDetail?.user_name
                ? "Update Account Details"
                : "Add Account Details"}
            </Typography.h4>
            <Form
              initialValues={AccountDetail}
              onSubmit={handleSubmit}
              render={(formRenderProps: FormRenderProps) => (
                <FormElement style={{ maxWidth: 340 }}>
                  <div className="row">
                    <div className="col-12">
                      <Field
                        name="user_type_id"
                        label="User Type"
                        placeholder="User Type"
                        component={FormSelectionField}
                        validator={requiredValidator}
                        astrike={true}
                        options={[
                          { label: "Distributor", value: 1 },
                          { label: "Retailer", value: 2 },
                        ]}
                      />
                    </div>
                    <div className="col-12">
                      <Field
                        name="user_name"
                        label="User Name"
                        placeholder="User Name"
                        component={FormTextField}
                        validator={requiredValidator}
                        astrike={true}
                      />
                    </div>
                    {!AccountDetail?.user_name && (
                      <div className="col-12">
                        <Field
                          name={"password"}
                          type={"password"}
                          component={FormPasswordField}
                          label={"Password"}
                          placeholder="Enter your password"
                          validator={passwordValidator}
                        />
                      </div>
                    )}
                    <div className="col-12">
                      <Field
                        name="order_day"
                        label="Order Day"
                        placeholder="i.e. Monday"
                        astrike={true}
                        validator={requiredValidator}
                        component={FormSelectionField}
                        options={DAYS?.map((days: any) => {
                          return {
                            value: days?.value,
                            label: days?.label,
                          };
                        })}
                      />
                    </div>
                    <div className="col-12">
                      <Field
                        name="delivery_day"
                        label="Delivery Day"
                        placeholder="i.e. Thursday"
                        astrike={true}
                        component={FormSelectionField}
                        validator={requiredValidator}
                        options={DAYS?.map((days: any) => {
                          return {
                            value: days?.value,
                            label: days?.label,
                          };
                        })}
                      />
                    </div>

                    <div
                      className={`col-12 d-flex ${
                        AccountDetail?.user_name
                          ? "justify-content-between"
                          : "justify-content-end"
                      } `}
                      style={{ marginTop: 20 }}
                    >
                      {(UserTypeID === "1" || UserTypeID === "2") &&
                        AccountDetail?.user_name && (
                          <Button
                            type="button"
                            themeColor={"primary"}
                            fillMode={"outline"}
                            style={{ marginRight: 10 }}
                            onClick={() => handleResetPassword()}
                          >
                            Reset Password
                          </Button>
                        )}
                      <div>
                        <ButtonWithLoading
                          label={"Save"}
                          buttonStyle={{ marginRight: 10 }}
                          type="submit"
                          disabled={!formRenderProps.allowSubmit || loading}
                          loading={loading}
                        />
                        <Button
                          type="button"
                          themeColor={"primary"}
                          fillMode={"outline"}
                          onClick={formCancelButton}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </div>
                </FormElement>
              )}
            />
          </>
        </AppDialog>
      )}
    </>
  );
};

const ResetUserPasswordDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const AccountID = useAppSelector((state) => state.account.AccountID);
  const loading = useAppSelector((state) => state.user.loading);
  const handleResetPassword = async (ID: number | null) => {
    if (ID) {
      const resetPassPayload = {
        id: ID ? +ID : null,
        isemployee: false,
      };
      const response = await dispatch(resetUserPassword(resetPassPayload));

      if (response.meta.requestStatus === "fulfilled") {
        dispatch(getAllAccounts());
        dispatch(closeDialog());
        dispatch(clearAccountID());
        dispatch(clearAccountGuID());
      } else {
        dispatch(getAllAccounts());
        dispatch(closeDialog());
        dispatch(clearAccountID());
        dispatch(clearAccountGuID());
      }
    }
  };
  return (
    <>
      {dialogName === "resetAccountPassword" && (
        <AppDialog>
          <>
            <Typography.h5>{"Reset Account User Password?"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to reset password?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleResetPassword(AccountID)}
                  type="button"
                  disabled={loading}
                >
                  Reset
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default Account;
