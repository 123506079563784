import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import ShadowCard from "../../components/common/ShadowCard";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { Button } from "@progress/kendo-react-buttons";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import { formatIndianNumber } from "../../_helper/helper";
import { clearExpenseApprovedDetails } from "./expenseApprovedSlice";
import { verifySRExpense } from "./services/expenseApproved.services";
import FormTextArea from "../../components/formFields/FormTextArea";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const ExpenseChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const ExpenseTypeList = useAppSelector(
    (state) => state.expenseType.ExpenseTypeList
  );
  const FixedExpenseAllocationList = useAppSelector(
    (state) => state.expenseAllocation.FixedExpenseAllocationList
  );
  const expense_details = formRenderProps.valueGetter("expense_details");
  const fixed_expense = formRenderProps.valueGetter("fixed_expense");

  useEffect(() => {
    if (fixed_expense) {
      const expenceAmount = FixedExpenseAllocationList?.filter(
        (item: any) => item?.expense_type_id === fixed_expense
      )[0]?.expense_amount;

      expense_details?.map((item: any, index: number) => {
        if (item.expense_type_id === fixed_expense) {
          formRenderProps.onChange(`expense_details.${index}.amount`, {
            value: expenceAmount,
          });
        } else {
          if (item.expense_type === "Fixed") {
            formRenderProps.onChange(`expense_details.${index}.amount`, {
              value: null,
            });
          }
        }
      });
    }
  }, [fixed_expense]);
  useEffect(() => {
    if (ExpenseTypeList && ExpenseTypeList.length > 0) {
      formRenderProps.onChange("expense_details", {
        value: JSON.parse(JSON.stringify(ExpenseTypeList))
          ?.sort((a: any) => (a.expense_type === "Fixed" ? -1 : 1))
          ?.map((item: any) => ({
            ...item,
            expense_type_id: item.id,
          })),
      });
    }
  }, [ExpenseTypeList]);

  const kms_travelled = +formRenderProps.valueGetter("kms_travelled") || 0;
  const fare_for_transport =
    +formRenderProps.valueGetter("fare_for_transport") || 0;
  const total_amount = +formRenderProps.valueGetter("total_amount") || 0;

  useEffect(() => {
    const totalExpense = expense_details?.reduce(
      (total: number, item: any) => total + (item?.amount || 0),
      0
    );
    formRenderProps.onChange("total_amount", { value: totalExpense });
  }, [expense_details?.map((item: any) => item?.amount).join("-")]);

  useEffect(() => {
    formRenderProps.onChange("net_amount", {
      value: fare_for_transport + kms_travelled + total_amount,
    });
  }, [total_amount, fare_for_transport, kms_travelled]);
  return null;
};

const CreateExpenseApproved = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const gridRef = useRef<any>(null);
  const expense_details_guid = location.state?.expense_details_guid;
  const loading = useAppSelector((state) => state.expenseApproved.loading);
  const formLoading = useAppSelector(
    (state) => state.expenseApproved.formLoading
  );
  const ExpenseApprovedDetails = useAppSelector(
    (state) => state.expenseApproved.ExpenseApprovedDetails
  );

  const [formKey, setFormKey] = React.useState(1);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [ExpenseApprovedDetails]);

  //   useEffect(() => {
  //     if (expense_details_guid) {
  //       dispatch(getSRExpenseByID(expense_details_guid));
  //     }
  //   }, [expense_details_guid]);

  useEffect(() => {
    return () => {
      dispatch(clearExpenseApprovedDetails());
    };
  }, []);

  //   const breakPoint = useResponsiveJSX([530, 930]);
  //   const dynamicColSpan = [4, 2, 0][breakPoint] ?? 0;

  const handleSubmit = async (values: any) => {
    const formdata = {
      total_amount: values?.total_amount,
      net_amount: values?.net_amount,
      disallowed_amount: values?.disallowed_amount,
      additional_expense_amount: values?.additional_expense_amount,
      verification_remarks: values?.verification_remarks,
    };
    if (expense_details_guid) {
      try {
        const updatePayload: any = {
          id: values?.id ? +values?.id : null,
          expense_details_guid: expense_details_guid,
          ...formdata,
        };

        const response = await dispatch(verifySRExpense(updatePayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/srexpenses");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  const handleFormClose = () => {
    navigate("/srexpenses");
  };

  // if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={ExpenseApprovedDetails}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <Typography.h5>Expense Details</Typography.h5>
              <table
                className="table table-bordered"
                style={{ borderColor: "black" }}
              >
                <tbody>
                  <tr>
                    <td style={{ backgroundColor: "lightgrey" }}>
                      <strong>Expense Date</strong>
                    </td>
                    <td colSpan={3}>29/09/2024</td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "lightgrey" }}>
                      <strong>To City</strong>
                    </td>
                    <td>AHMEDABAD</td>
                    <td style={{ backgroundColor: "lightgrey" }}>
                      <strong>From City</strong>
                    </td>
                    <td>PRATIJ</td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "lightgrey" }}>
                      <strong>Mode Of Transport</strong>
                    </td>
                    <td>Bus</td>
                    <td style={{ backgroundColor: "lightgrey" }}>
                      <strong>Petrol Allowance</strong>
                    </td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td rowSpan={2} style={{ backgroundColor: "lightgrey" }}>
                      <strong>Cities Visited</strong>
                    </td>
                    <td rowSpan={2}>AHMEDABAD</td>
                    <td style={{ backgroundColor: "lightgrey" }}>
                      <strong>Fare For Transport</strong>
                    </td>
                    <td>210</td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "lightgrey" }}>
                      <strong>Sales Amount</strong>
                    </td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "lightgrey" }}>
                      <strong>Working agent/Area/Town:</strong>
                    </td>
                    <td colSpan={3}>
                      Office meeting DA lagavavu nahi only 210 rs
                    </td>
                  </tr>
                </tbody>
              </table>

              <Typography.h5>Expenses</Typography.h5>
              <table
                className="table table-bordered"
                style={{ borderColor: "black" }}
              >
                <tbody>
                  <tr>
                    <td style={{ backgroundColor: "lightgrey" }}>
                      <strong> DA - HEAD QUATER </strong>
                    </td>
                    <td>190</td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "lightgrey" }}>
                      <strong> NIGHT HALT METRO/CLASS-1 TOWN</strong>
                    </td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "lightgrey" }}>
                      <strong> NIGHT HALT OTHER TOWN</strong>
                    </td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "lightgrey" }}>
                      <strong> NIGHT JOURNEY</strong>
                    </td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "lightgrey" }}>
                      <strong> STATN EXP.</strong>
                    </td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "lightgrey" }}>
                      <strong> XERX. EXP.</strong>
                    </td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "lightgrey" }}>
                      <strong> MAIL COUR.</strong>
                    </td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "lightgrey" }}>
                      <strong> OTH. EXP.</strong>
                    </td>
                    <td>0</td>
                  </tr>
                </tbody>
              </table>
              {/* <Typography.h5>Expense Verification</Typography.h5>
              <table className="table table-bordered" style={{borderColor:"black"}}>
                <tbody>
                  <tr>
                    <td style={{backgroundColor:"lightgrey"}}>
                      <strong>Verified By</strong>
                    </td>
                    <td>KEVAL BHIRUD</td>
                    <td style={{backgroundColor:"lightgrey"}}>
                      <strong>Net Amount</strong>
                    </td>
                    <td>400</td>
                  </tr>
                  <tr>
                    <td style={{backgroundColor:"lightgrey"}}>
                      <strong>Verification Date</strong>
                    </td>
                    <td>07/10/2024</td>
                    <td style={{backgroundColor:"lightgrey"}}>
                      <strong>Disallowed Amount</strong>
                    </td>
                    <td>190</td>
                  </tr>
                  <tr>
                    <td style={{backgroundColor:"lightgrey"}}>
                      <strong>Verification Status</strong>
                    </td>
                    <td className="text-success">
                      <strong>Verified</strong>
                    </td>
                    <td style={{backgroundColor:"lightgrey"}}>
                      <strong>Additional Amount</strong>
                    </td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td style={{backgroundColor:"lightgrey"}}>
                      <strong>Verification Remarks</strong>
                    </td>
                    <td>
                      DA NOT ALLOWED @ RS.190/- AHMEDABAD MEETING 190/-
                      DEDUCTION
                    </td>
                    <td style={{backgroundColor:"lightgrey"}}>
                      <strong>Total Amount</strong>
                    </td>
                    <td>210</td>
                  </tr>
                </tbody>
              </table> */}
            </ShadowCard>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>Expense Verification</Typography.h4>
                </GridLayoutItem>

                <GridLayoutItem colSpan={4}>
                  <hr />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "5px 10px",
                  }}
                >
                  <Typography.h6>Net Amount</Typography.h6>
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    textAlign: "end",
                    marginRight: "13px",
                  }}
                >
                  <Typography.h6>
                    {formatIndianNumber(
                      formRenderProps.valueGetter("net_amount")
                    )}
                  </Typography.h6>
                </GridLayoutItem>
                <GridLayoutItem colSpan={2} rowSpan={4}>
                  <Field
                    wrapperClassName="m-0"
                    name="remarks"
                    label="Remarks"
                    placeholder="Remarks.."
                    component={FormTextArea}
                    rows={5}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "5px 10px",
                  }}
                >
                  <Typography.h6>Disallowed Amount</Typography.h6>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperStyle={{ margin: 0, padding: "5px" }}
                    wrapperClassName="right-alighned-field"
                    name="disallowed_amount"
                    placeholder="0"
                    component={FormNumericTextField}
                    // type="number"
                    // validator={requiredValidator}
                    // astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "5px 10px",
                  }}
                >
                  <Typography.h6>Additional Expense Amount</Typography.h6>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperStyle={{ margin: 0, padding: "5px" }}
                    wrapperClassName="right-alighned-field"
                    name="additional_expense_amount"
                    placeholder="0"
                    component={FormNumericTextField}

                    // validator={requiredValidator}
                    // astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "5px 10px",
                  }}
                >
                  <Typography.h6>Total Amount</Typography.h6>
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    textAlign: "end",
                    marginRight: "13px",
                  }}
                >
                  <Typography.h6>
                    {formatIndianNumber(
                      formRenderProps.valueGetter("total_amount") || 0
                    )}
                  </Typography.h6>
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={4}
                  style={{
                    textAlign: "end",
                    marginTop: "20px",
                    width: "100%",
                  }}
                >
                  <ButtonWithLoading
                    label={expense_details_guid ? "Update" : "Save"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || formLoading}
                    loading={formLoading}
                  />
                  <Button
                    type="button"
                    fillMode={"outline"}
                    themeColor={"primary"}
                    style={{ marginLeft: 4 }}
                    onClick={handleFormClose}
                  >
                    Cancel
                  </Button>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>

            <ExpenseChangeWatcher formRenderProps={formRenderProps} />
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateExpenseApproved;
