import React, { useEffect, useRef, useState } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import ShadowCard from "../../components/common/ShadowCard";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridSelectionChangeEvent,
  GridHeaderSelectionChangeEvent,
} from "@progress/kendo-react-grid";
import {
  getSelectedState,
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import { getter, Typography } from "@progress/kendo-react-common";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Button } from "@progress/kendo-react-buttons";
import { DELETE_ACCESS } from "../../_contstants/common";
import {
  createAssingArea,
  deleteAssignArea,
  getAllAreaAssignToDistributor,
} from "./services/areaAssignToDistributor.services";
import {
  clearAssignAreaDetails,
  setAssignAreaID,
} from "./areaAssignToDistributorSlice";
import { LoadingPanel } from "../../components/layout/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { checkAcessRights } from "../../_helper/helper";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import AlertBox from "../../components/common/AlertBox";
import { ICity } from "../city/cityModel";
import { IArea } from "../area/areaModel";
import { getAllActiveCities } from "../city/services/city.services";
import FormMultiSelectionFiled from "../../components/formFields/FormMultiSelectionFiled";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { getAreaByCityID } from "../area/services/area.services";

interface ChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const CityChangeWatcher: React.FC<ChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const cityID = formRenderProps.valueGetter("city_id");
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (cityID) {
      dispatch(getAreaByCityID(cityID));
    }
    formRenderProps.onChange("area_id", { value: "" });
  }, [cityID]);
  return null;
};

const CreateAreaAssignToDistributor: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const distributor_id = location.state?.distributor_id;
  const distributor_name = location.state?.distributor_name;
  const AssignAreaDetails = useAppSelector(
    (state) => state.areaAssign.AssignAreaDetails
  );
  const CityList = useAppSelector((state) => state.city.CityList);
  const AreaList = useAppSelector((state) => state.area.AreaList);
  const loading = useAppSelector((state) => state.areaAssign.loading);
  const formloading = useAppSelector((state) => state.areaAssign.formloading);
  const formVisibleRef = useRef(false);

  const [formKey, setFormKey] = useState(1);
  useEffect(() => {
    dispatch(getAllActiveCities());
    return () => {
      dispatch(clearAssignAreaDetails());
    };
  }, []);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [AssignAreaDetails]);

  useEffect(() => {
    if (distributor_id) {
      dispatch(getAllAreaAssignToDistributor(distributor_id));
    }
  }, [distributor_id]);

  const handleSubmit = async (values: any) => {
    try {
      const payload = {
        distributor_id: distributor_id,

        area_id: values?.area_id ? values?.area_id.join(",") : "",
        city_id: values?.city_id ? values?.city_id : null,
      };
      const response = await dispatch(createAssingArea(payload));
      if (response?.meta?.requestStatus === "fulfilled") {
        dispatch(clearAssignAreaDetails());
        setFormKey((pre) => pre + 1);
        dispatch(getAllAreaAssignToDistributor(distributor_id));
        formVisibleRef.current = !formVisibleRef.current;
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormVisible = (formRenderProps: FormRenderProps) => {
    const visible = formRenderProps.valueGetter("visible");

    formRenderProps.onChange("visible", { value: !visible });
    formVisibleRef.current = !formVisibleRef.current;
  };
  return (
    <>
      <DeleteAssignAreaToDistributorDialog />
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={AssignAreaDetails}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement style={{ paddingBottom: 10 }}>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem
                  colSpan={3}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography.h4>
                    Assign Area To{" - "}
                    <span
                      style={{
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        color: "red",
                      }}
                    >
                      {distributor_name}
                    </span>{" "}
                  </Typography.h4>
                  <Button
                    type="button"
                    fillMode={"solid"}
                    themeColor={"primary"}
                    style={{ marginLeft: 4 }}
                    onClick={() => handleFormVisible(formRenderProps)}
                  >
                    {formVisibleRef.current ? "Cancel" : "Add New"}
                  </Button>
                </GridLayoutItem>
                {/* <GridLayoutItem
                  colSpan={3}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography.p>
                    Distributor Name :{" "}
                    <b
                      style={{ fontWeight: "bold", textTransform: "uppercase" }}
                    >
                      {distributor_name}
                    </b>{" "}
                  </Typography.p>
                </GridLayoutItem> */}
                <GridLayoutItem
                  colSpan={2}
                  className={`${
                    formVisibleRef.current ? "expand" : "expandout"
                  }`}
                >
                  <div
                    style={{
                      border: "1px solid goldenrod",
                      padding: "10px",
                      borderRadius: "4px",
                    }}
                  >
                    <GridLayout
                      style={{ marginRight: 20 }}
                      gap={{ rows: 0, cols: 10 }}
                      cols={[
                        { width: "45%" },
                        { width: "45%" },
                        { width: "10%" },
                      ]}
                    >
                      <GridLayoutItem>
                        <Field
                          name="city_id"
                          label="City"
                          placeholder="Select City"
                          // isAddNew={true}
                          // isIndirect={true}
                          // drawerName="itemCategoryForm"
                          component={FormSelectionField}
                          validator={requiredValidator}
                          astrike={true}
                          suggest={true}
                          options={CityList?.map((city: ICity) => {
                            return {
                              value: city?.id,
                              label: city?.city_name,
                            };
                          })}
                        />
                      </GridLayoutItem>
                      <GridLayoutItem>
                        <Field
                          name="area_id"
                          id="area_id_abc"
                          label="Area"
                          placeholder="Select Area"
                          component={FormMultiSelectionFiled}
                          validator={requiredValidator}
                          astrike={true}
                          options={AreaList?.map((area: IArea) => {
                            return {
                              value: area?.id,
                              label: area?.area_name,
                            };
                          })}
                        />
                      </GridLayoutItem>
                      <GridLayoutItem
                        style={{
                          textAlign: "start",
                          marginTop: "34px",
                          width: "100%",
                        }}
                      >
                        <ButtonWithLoading
                          label={"Save"}
                          type="submit"
                          disabled={!formRenderProps.allowSubmit || formloading}
                          loading={formloading}
                        />
                      </GridLayoutItem>
                    </GridLayout>
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <CityChangeWatcher formRenderProps={formRenderProps} />
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <Typography.h4>Area List</Typography.h4>
              {loading ? (
                <LoadingPanel gridRef={gridRef} />
              ) : (
                <AreaAssignToDistributorGridComponent />
              )}

              <Button
                type="button"
                fillMode={"solid"}
                themeColor={"primary"}
                style={{ marginLeft: "auto", marginTop: "20px", width: "80px" }}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}
const initialDataState: PageState = { skip: 0, take: 50 };

const AreaAssignToDistributorGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const isDeleteAccess = checkAcessRights(
    "/area-assign-to-distributor",
    DELETE_ACCESS
  );
  const AssignAreaList = useAppSelector(
    (state) => state.areaAssign.AssignAreaList
  );
  const [selectedState, setSelectedState] = React.useState<{
    [id: string]: boolean | number[];
  }>({});
  const [rowSelectionModel, setRowSelectionModel] = React.useState<number[]>(
    []
  );

  useEffect(() => {
    setRowSelectionModel([]);
  }, [AssignAreaList]);

  const dataState = AssignAreaList?.map((dataItem: any) =>
    Object.assign({ selected: false }, dataItem)
  );

  const DATA_ITEM_KEY: string = "id";
  const SELECTED_FIELD: string = "selected";
  const idGetter = getter(DATA_ITEM_KEY);
  const loading = useAppSelector((state) => state.areaAssign.loading);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? AssignAreaList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const onSelectionChange = React.useCallback(
    (event: GridSelectionChangeEvent) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
      const trueKeys = Object.keys(newSelectedState)
        .filter((key) => newSelectedState[key])
        .map(Number);
      setRowSelectionModel(trueKeys);
    },
    [selectedState]
  );

  const onHeaderSelectionChange = React.useCallback(
    (event: GridHeaderSelectionChangeEvent) => {
      const checkboxElement: any = event.syntheticEvent.target;
      const checked = checkboxElement.checked;
      const newSelectedState: { [key: string]: boolean } = {};

      event.dataItems.forEach((item) => {
        newSelectedState[idGetter(item)] = checked;
      });
      setSelectedState(newSelectedState);
      const trueKeys = Object.keys(newSelectedState)
        .filter((key: string) => newSelectedState[key])
        .map(Number);
      setRowSelectionModel(trueKeys);
    },
    []
  );

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleOpenDeleteDialog = (id: string) => {
      dispatch(openDialog("deleteAreaAssign"));
      dispatch(setAssignAreaID(id));
    };
    return (
      <td
        className="action-td"
        style={{
          ...props.style,
        }}
      >
        {isDeleteAccess && rowSelectionModel.length === 0 && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() => handleOpenDeleteDialog(`${props.dataItem.id}`)}
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const handleOpenDeleteDialog = (id: any) => {
    dispatch(openDialog("deleteAreaAssign"));
    dispatch(setAssignAreaID(id));
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {isDeleteAccess && rowSelectionModel && rowSelectionModel.length > 0 && (
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Multi Delete"
          style={{
            position: "absolute",
            right: "65px",
            top: "20px",
          }}
          onClick={() => handleOpenDeleteDialog(rowSelectionModel.join(","))}
        >
          <MdDelete
            className="absolute-position"
            style={{ fontSize: "24px" }}
          />
        </IconButton>
      )}
      {AssignAreaList && AssignAreaList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 281px)" }}
          className="responsive-table"
          filterable={true}
          filter={filter}
          // data={
          //   filter
          //     ? filterBy(AssignAreaList, filter).slice(
          //         page.skip,
          //         page.take + page.skip
          //       )
          //     : AssignAreaList.slice(page.skip, page.take + page.skip)
          // }
          data={
            filter
              ? filterBy(AssignAreaList, filter)?.map((item: any) => ({
                  ...item,
                  [SELECTED_FIELD]: selectedState[idGetter(item)],
                }))
              : AssignAreaList?.map((item: any) => ({
                  ...item,
                  [SELECTED_FIELD]: selectedState[idGetter(item)],
                }))
          }
          onFilterChange={handleFilterChange}
          selectedField={SELECTED_FIELD}
          skip={page.skip}
          take={page.take}
          total={AssignAreaList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [10, 25, 50, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onSelectionChange={onSelectionChange}
          onHeaderSelectionChange={onHeaderSelectionChange}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            filterable={false}
            field={SELECTED_FIELD}
            width={50}
            headerSelectionValue={
              dataState?.findIndex(
                (item: any) => !selectedState[idGetter(item)]
              ) === -1
            }
          />
          <Column field="area_name" title="Area Name" />
          <Column field="city_name" title="City" />
          <Column field="state_name" title="State" />
          <Column field="country_name" title="Country" />
          {isDeleteAccess && (
            <Column
              field="id"
              title="Actions"
              cell={MyEditCommandCell}
              width={"110px"}
              filterable={false}
            />
          )}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteAssignAreaToDistributorDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const distributor_id = location.state?.distributor_id;
  const AssignAreaID = useAppSelector((state) => state.areaAssign.AssignAreaID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  const handleDeleteAction = async (ID: string) => {
    if (ID) {
      const response = await dispatch(deleteAssignArea(ID));
      if (response?.meta?.requestStatus === "fulfilled") {
        dispatch(getAllAreaAssignToDistributor(distributor_id));
        dispatch(closeDialog());
      } else {
        dispatch(getAllAreaAssignToDistributor(distributor_id));
        dispatch(closeDialog());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteAreaAssign" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Assign Area"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Area?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(AssignAreaID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default CreateAreaAssignToDistributor;
