import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import moment from "moment";

// export const getAllPayment = createAsyncThunk(
//   "Payment/PaymentFindAll",
//   async (formData: any) => {
//     try {
//       const response = await axiosApiInstance.post(
//         `${API_URL}/Payment/PaymentFindAll`,
//         formData
//       );
//       return response.data?.Data;
//     } catch (error: any) {
//       // ErrorToast(error?.response?.data?.Details || "Something went wrong");
//       console.error("Error Fetching Payment :", error);
//       return Promise.reject({
//         message: error?.response?.data?.Details || "Something went wrong",
//       });
//     }
//   }
// );

export const getAllPayment = createAsyncThunk(
  "Payment/PaymentFindAll",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Payment/PaymentFindAll`
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error While Fetch Payment:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createPayment = createAsyncThunk(
  "Payment/InsertPayment",
  async (fomedata: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Payment/InsertPayment`,
        fomedata
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating Payment :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updatePayment = createAsyncThunk(
  "Payment/UpdatePayment",
  async (formdata: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Payment/UpdatePayment`,
        formdata
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Payment :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deletePayment = createAsyncThunk(
  "Payment/DeletePayment",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Payment/DeletePayment`,
        { payment_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Payment :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getPaymentByID = createAsyncThunk(
  "Payment/FindByIDPayment",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Payment/FindByIDPayment`,
        formData
      );
      const result = response.data?.Data as any;
      return {
        id: result?.id,
        payment_guid: result?.payment_guid,
        payment_no: result?.payment_no,
        payment_no_string: result?.payment_no_string,
        financial_year: result?.financial_year,
        payment_date: result?.payment_date
          ? moment(result?.payment_date, "YYYY-MM-DD").toDate()
          : "",
        payment_total_amount: result?.payment_total_amount,
        remarks: result?.remarks,
        ledger_id: result?.ledger_id,
        voucher_type_id: result?.voucher_type_id,
        payment_entry: result?.payment_entry,
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching DebitNote details:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllOutstandingPayment = createAsyncThunk(
  "Payment/OutstandingPaymentFindAll",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Payment/OutstandingPaymentFindAll`,
        formData
      );
      const result = response.data?.Data as any;
      return result as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Outstanding Payment details:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const generatePaymentNoForPayment = createAsyncThunk(
  "Payment/InvoiceNumberPayment",
  async (payload: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Payment/InvoiceNumberPayment`,
        payload
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error generating paymnet no. :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
