import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createJournal,
  deleteJournal,
  getAllOutstandingJournal,
  getAllJournal,
  getJournalByID,
  updateJournal,
} from "./services/journal.services";
import { IJournal, IJournalInitialState } from "./journalModel";
import moment from "moment";

const initialState: IJournalInitialState = {
  loading: false,
  error: "",
  JournalEntryDeleteIndex: -1,
  JournalBillDialogOpenIndex: -1,
  JournalID: null,
  JournalGUID: "",
  JournalList: [],
  OutstandingJournalList: [],
  JournalDetail: {
    financial_year: null,
    journal_voucher_date: moment().toDate(),
    journal_voucher_total_amount: null,
    ledger_id: null,
    voucher_type_id: null,
    remarks: "",
    journal_voucher_entry: [],
    journal_voucher_no_string: "",
  },
};

const JournalSlice = createSlice({
  name: "journal",
  initialState,
  reducers: {
    setJournalID: (state, action) => {
      state.JournalID = action.payload;
    },
    setJournalGUID: (state, action) => {
      state.JournalGUID = action.payload;
    },
    clearJournalDetails: (state) => {
      state.JournalDetail = initialState.JournalDetail;
    },
    setJournalEntryDeleteIndex: (state, action) => {
      state.JournalEntryDeleteIndex = action.payload;
    },
    setJournalBillDialogOpenIndex: (state, action) => {
      state.JournalBillDialogOpenIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllJournal.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllJournal.fulfilled,
      (state, action: PayloadAction<IJournal[]>) => {
        state.loading = false;
        state.JournalList = action.payload || [];
      }
    );
    builder.addCase(getAllJournal.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.JournalList = [];
    });

    builder.addCase(createJournal.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createJournal.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createJournal.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateJournal.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateJournal.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateJournal.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteJournal.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteJournal.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteJournal.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getJournalByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getJournalByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.JournalDetail = action.payload;
    });
    builder.addCase(getJournalByID.rejected, (state, action) => {
      state.loading = false;
      state.JournalDetail = initialState.JournalDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getAllOutstandingJournal.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllOutstandingJournal.fulfilled,
      (state, action: any) => {
        state.loading = false;
        state.OutstandingJournalList = action.payload;
      }
    );
    builder.addCase(getAllOutstandingJournal.rejected, (state, action) => {
      state.loading = false;
      state.OutstandingJournalList = initialState.OutstandingJournalList;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearJournalDetails,
  setJournalEntryDeleteIndex,
  setJournalID,
  setJournalBillDialogOpenIndex,
  setJournalGUID,
} = JournalSlice.actions;
export default JournalSlice.reducer;
