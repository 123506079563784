export const GENDER = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

export const CONDITION = [
  { label: "OK", value: "OK" },
  { label: "NOT OK", value: "NOT OK" },
];

export const STATUSARRAY = [
  { label: "Active", value: 1 },
  { label: "Inactive", value: 2 },
];

export const DISCOUNTTYPE = [
  { text: "%", value: "1" },
  { text: "₹", value: "2" },
];

export const OUTWARDTYPE = [
  { label: "DOMESTIC", value: "DOMESTIC" },
  { label: "INTERNATIONAL", value: "INTERNATIONAL" },
];
export const WEEK_DAYS = [
  { label: "Sunday", value: "Sun" },
  { label: "Monday", value: "Mon" },
  { label: "Tuesday", value: "Tues" },
  { label: "Wednesday", value: "Wed" },
  { label: "Thursday", value: "Thurs" },
  { label: "Friday", value: "Fri" },
  { label: "Saturday", value: "Sat" },
];
export const MONTHS = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
];

export const MONTHS_MAP: { [key: number]: string } = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};
export const BLOOD_GROUPS = [
  { label: "A Positive (A+)", value: "A+" },
  { label: "A Negative (A-)", value: "A-" },
  { label: "B Positive (B+)", value: "B+" },
  { label: "B Negative (B-)", value: "B-" },
  { label: "AB Positive (AB+)", value: "AB+" },
  { label: "AB Negative (AB-)", value: "AB-" },
  { label: "O Positive (O+)", value: "O+" },
  { label: "O Negative (O-)", value: "O-" },
];
export const MARITAL_STATUS = [
  { label: "Single", value: "Single" },
  { label: "Married", value: "Married" },
  { label: "Widowed", value: "Widowed" },
  { label: "Divorced", value: "Divorced" },
  // { label: "Separated", value: "Separated" },
];

export const NATIONALITY = [
  { label: "Indian", value: "Indian" },
  { label: "Other", value: "Other" },
];

export const TAXTYPE = [
  { label: "IGST", value: "IGST" },
  { label: "CGST", value: "CGST" },
  { label: "SGST", value: "SGST" },
  { label: "CESS", value: "CESS" },
];

export const TYPEOFSUPPLY = [
  { label: "Good", value: "Good" },
  { label: "Service", value: "Service" },
];

export const GSTAPPLICABLE = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

export const RELIGIOUS = [
  { label: "Hinduism", value: "Hinduism" },
  { label: "Christianity", value: "Christianity" },
  { label: "Islam", value: "Islam" },
  { label: "Buddhism", value: "Buddhism" },
  { label: "Sikhism", value: "Sikhism" },
  { label: "No Religion", value: "No Religion" },
];

export const WORKER_TYPE = [
  { label: "UnSkilled", value: "UnSkilled" },
  { label: "Semi Skilled", value: "Semi Skilled" },
  { label: "Skilled", value: "Skilled" },
];

export const LEAVE_STATUS = [
  { label: "Approved", value: "Approved" },
  { label: "Rejected", value: "Rejected" },
];

export const VOUCHER_TYPE = [
  { label: "Sales", value: 1 },
  { label: "Purchase", value: 2 },
  { label: "Payment", value: 3 },
  { label: "Receipt", value: 4 },
  { label: "JV", value: 5 },
  { label: "Contra", value: 6 },
  { label: "Credit Note", value: 7 },
  { label: "Debit Note", value: 8 },
];

export const VOUCHER_TYPE_MAP: { [key: number]: string } = {
  1: "Sales",
  2: "Purchase",
  3: "Payment",
  4: "Receipt",
  5: "JV",
  6: "Contra",
  7: "Credit Note",
  8: "Debit Note",
};

export const SUNDRY_CREDITORS_DEBTORS = [26, 27];
// export const SUNDRY_DEBTORS = [27];
// export const SUNDRY_CREDITORS = [26];

export const ITEMTYPEOPTIONS = [
  // { label: "Product", value: "Product" },
  // { label: "Service", value: "Service" },
  // { label: "Additional Service", value: "Additional Service" },
  { label: "Raw Material", value: "Raw Material" },
  { label: "Semi Finished", value: "Semi Finished" },
  { label: "Finished", value: "Finished" },
  { label: "Packing Material", value: "Packing Material" },
];

export const EXPENSE_TYPE = [
  { label: "Fixed", value: "Fixed" },
  { label: "Variable", value: "Variable" },
];

export const EXP_INC_TAX_GROUP = [8, 9, 11, 12, 20, 15, 14];
export const PURCHASE_SALES_ACCOUNT = [15, 14];
export const EXP_INC_GROUP = [8, 9, 11, 12];

export const COMPANY_PF_NO = "GJVAT0024846000";
export const COMPANY_ESI_NO = "37000192150000009";
export const COMPANY_NAME = "HARIHAR FOODS PVT LTD";
export const COMPANY_ADDRESS = "519/1, PHASE IV, VATVA, GIDC, AHMEDABAD-382445";

export type ISelectionType = {
  value: number;
  label: string;
};

export const FINANCIAL_YEAR = 202425;

export const DRAWER_WIDTH = 400;
export const INSERT_OPT_TYPE = 1;
export const EDIT_OPT_TYPE = 2;
export const DELETE_OPT_TYPE = 3;
export const GET_BY_GUID_OPT_TYPE = 4;
export const GET_ALL_ACTIVE_WITH_FILTER_OPT_TYPE = 5;
export const GET_ALL_OPT_TYPE = 6;
export const ACTIVE_INACTIVE_OPT_TYPE = 7;

export const GUJARATIDFORGST = 1;
export const COMMONTNCID = 1;
export const DRIVERUSERTYPEID = 4;
export const SUPERADMINUSERTYPEID = 1;
export const ADMINUSERTYPEID = 2;

export const INSERT_ACCESS = "1";
export const EDIT_ACCESS = "2";
export const DELETE_ACCESS = "3";
export const PDF_DOWNLOAD_ACCESS = "4";
export const EXCEL_EXPORT_ACCESS = "5";
export const PRINT_ACCESS = "6";
