import React, { useEffect } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import FormDatePicker from "../../../components/formFields/FormDateField";
import moment from "moment";
import { Typography } from "@progress/kendo-react-common";
import ShadowCard from "../../../components/common/ShadowCard";
import {
  clearGhantiCleaningReportDetails,
  clearGhantiCleaningReportList,
  setGhantiCleaningReport,
} from "../reportsSlice";
import {
  Grid,
  GridColumn,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
} from "@progress/kendo-react-grid";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import { getGhantiCleaningReport } from "../services/reports.services";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import AlertBox from "../../../components/common/AlertBox";
import { LoadingPanel } from "../../../components/layout/Loading";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import { findAllActiveMachine } from "../../machine/services/machine.services";
import { IMachine } from "../../machine/machineModel";
import { Button } from "@progress/kendo-react-buttons";
import Logo from "../../../assets/Images/logo.png";
import {
  checkAcessRights,
  formatIndianNumberForQty,
} from "../../../_helper/helper";
import { EXCEL_EXPORT_ACCESS, PRINT_ACCESS } from "../../../_contstants/common";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const DatechangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const fromdate = moment()?.startOf("month")?.toDate() || "";
  const todate = moment()?.toDate() || "";
  useEffect(() => {
    formRenderProps.onChange("from_date", {
      value: fromdate,
    });
    formRenderProps.onChange("to_date", {
      value: todate,
    });
  }, []);

  return null;
};

const GhantiCleaningReportForm: React.FC = () => {
  const gridRef = React.useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.reports.loading);
  const MachineList = useAppSelector((state) => state.machine.MachineList);
  const GhantiCleaningReportList = useAppSelector(
    (state) => state.reports.GhantiCleaningReportList
  );
  const GhantiCleaningReportDetails = useAppSelector(
    (state) => state.reports.GhantiCleaningReportDetails
  );
  // const currentPage = useAppSelector((state) => state.pagination.currentPage);
  // const pageLimit = useAppSelector((state) => state.pagination.pageLimit);

  useEffect(() => {
    dispatch(findAllActiveMachine());

    return () => {
      dispatch(clearGhantiCleaningReportList());
      dispatch(clearGhantiCleaningReportDetails());
      // dispatch(setCurrentPage(0));
    };
  }, []);

  const handleSubmit = (values: any) => {
    const payload = {
      machine_no: values?.machine_no ? values?.machine_no : null,
      from_date: values?.from_date
        ? moment(values?.from_date).format("YYYY-MM-DD")
        : null,
      to_date: values?.to_date
        ? moment(values?.to_date).format("YYYY-MM-DD")
        : null,
      // limit: +pageLimit,
      // skip: +currentPage * +pageLimit,
    };
    dispatch(setGhantiCleaningReport(payload));
    dispatch(getGhantiCleaningReport(payload));
  };
  return (
    <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
      <GridLayoutItem>
        <ShadowCard>
          <Form
            initialValues={GhantiCleaningReportDetails}
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 10 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                  ]}
                >
                  <GridLayoutItem colSpan={4}>
                    <Typography.h4
                      style={{ marginBottom: 0, marginLeft: "3px" }}
                    >
                      Ghanti Cleaning Report
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperStyle={{ width: "100%" }}
                      name="from_date"
                      label="From Date"
                      format="dd/MM/yyyy"
                      component={FormDatePicker}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperStyle={{ width: "100%" }}
                      name="to_date"
                      label="To Date"
                      format="dd/MM/yyyy"
                      minDate={moment(
                        formRenderProps.valueGetter("from_date")
                      ).toDate() || new Date(1900, 0, 1)}
                      component={FormDatePicker}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="w-100"
                      name="machine_no"
                      label="Ghanti No"
                      placeholder="Ghanti No"
                      component={FormSelectionField}
                      options={MachineList?.map((machine: IMachine) => {
                        return {
                          value: machine?.id,
                          label: machine?.machine_name,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <div style={{ marginTop: 35 }}>
                      <ButtonWithLoading
                        label={"View"}
                        type="submit"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </div>
                  </GridLayoutItem>
                </GridLayout>
                <DatechangeWatcher formRenderProps={formRenderProps} />
              </FormElement>
            )}
          />
        </ShadowCard>
        {loading ? (
          <LoadingPanel gridRef={gridRef} />
        ) : (
          GhantiCleaningReportList &&
          GhantiCleaningReportList.length > 0 && (
            <ShadowCard style={{ marginTop: 10 }}>
              <GhantiCleaningReportGridCommponent />
            </ShadowCard>
          )
        )}
      </GridLayoutItem>
    </GridLayout>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 50 };

const GhantiCleaningReportGridCommponent: React.FC = () => {
  const isPrintAccess = checkAcessRights(location.pathname, PRINT_ACCESS);
  const isExcelAccess = checkAcessRights(
    location.pathname,
    EXCEL_EXPORT_ACCESS
  );

  const GhantiCleaningReportList = useAppSelector(
    (state) => state.reports.GhantiCleaningReportList
  );
  const GhantiCleaningReportDetails = useAppSelector(
    (state) => state.reports.GhantiCleaningReportDetails
  );

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All"
        ? GhantiCleaningReportList.length
        : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const handleExportExcel = () => {
    const datetime = moment().format("DD-MM-YYYY HH-mm");
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.table_to_sheet(
      document.querySelector(".table-bordered")
    );
    XLSX.utils.book_append_sheet(wb, ws, "GhantiCleaningReport");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(data, `Ghanti Cleaning Report ${datetime}`);
  };

  const printTable = (data: any[]) => {
    let printbuttons = document.querySelector(
      ".printbuttons"
    ) as HTMLElement | null;
    let report_container = document.querySelector(
      ".report-container"
    ) as HTMLElement | null;

    if (printbuttons) printbuttons.style.display = "none";
    if (report_container) report_container.style.padding = "20px 0";

    let tableRows = data.length;
    let maxRows = tableRows;
    let pageCount = Math.ceil(tableRows / maxRows);
    let content = "";

    for (let i = 0; i < pageCount; i++) {
      content += '<div class="print-page">';
      //   content += `<div class="page-header">Page ${i + 1}</div>`;
      content +=
        '<table class="table table-bordered" style="font-size:10px;margin-bottom:30px;">';
      content += "<tr>";
      content += `<td rowSpan="4" style="width:16%" ><img src="${Logo}" width="100%" /></td>`;
      content +=
        '<td colSpan="4" style="text-align: center;font-size: 14px;font-weight: 600;color: gray;">Harihar Foods Pvt Ltd.</td>';
      content += "</tr>";
      content += "<tr>";
      content += '<td style="width:20%;">Document Name:</td>';
      content +=
        '<td colSpan="3" style="font-weight:bold;">Ghanti Cleaning, Maintenance and QC Clearance Report.</td>';
      content += "</tr>";
      content += "<tr>";
      content += "<td>Document Number:</td>";
      content += "<td>PRD/F/06:</td>";
      content += "<td>Page</td>";
      content += `<td>${i + 1}</td>`;
      content += "</tr>";
      content += "<tr>";
      content += "<td>Date Of Issue:</td>";
      content += "<td>01/01/2020</td>";
      content += "<td>Version</td>";
      content += "<td>2.00</td>";
      content += "</tr>";

      content += "</table>";

      content += '<table class="table table-bordered" style="font-size:10px;">';
      content += '<thead style="background:lightgray;">';
      content += '<tr style="text-align:left">';
      content += "<th>SR. NO.</th>";
      content += "<th>DATE</th>";
      content += '<th style="width:15%;">PRODUCT NAME</th>';
      content += "<th>GHANTI NO.</th>";
      content += "<th>GRINDING LOT NO.</th>";
      content += "<th>PRO. QTY.</th>";
      content +=
        '<th style="width:100px">GHANTI CLEARING, MAINTANANCE CHECKING DATE & TIME.</th>';
      content += "<th>QC NAME</th>";
      content += "<th>PRO. NAME</th>";
      content += "<th>REMARK</th>";
      content += "</tr>";
      content += "</thead>";
      content += "<tbody>";

      let startRow = i * maxRows;
      let endRow = Math.min((i + 1) * maxRows, tableRows);

      for (let j = startRow; j < endRow; j++) {
        content += "<tr>";
        content += `<td style="text-align:center">${j + 1}</td>`;
        content += `<td>${
          data[j]?.machine_cleaning_date
            ? moment(data[j]?.machine_cleaning_date, "DD/MM/YYYY").format(
                "DD MMM YYYY"
              )
            : ""
        }</td>`;
        content += `<td>${data[j].product_name}</td>`;
        content += `<td>${data[j].machine_name}</td>`;
        content += `<td style="text-align:end">${data[j].grinding_lot_no}</td>`;
        content += `<td style="text-align:end">${formatIndianNumberForQty(
          +data[j].production_qty || 0
        )}</td>`;
        content += `<td>${data[j].maintenance_time}</td>`;
        content += `<td>${data[j].qc_name}</td>`;
        content += `<td>${data[j].production_name}</td>`;
        content += `<td>${data[j].remarks}</td>`;
        content += "</tr>";
      }

      content += "</tbody>";
      content += "</table>";

      // content +=
      //   '<table class="table table-bordered" style="font-size:11px; width:100%; margin:30px 0; text-align:center">';
      // content += "<tr>";
      // content += `<td style="width:33%">Prepared By: <br>Mr. GAURANG MEHTA</br></td>`;
      // content += `<td style="width:34%">Document Status: </br>CONTROL COPY</td>`;
      // content += `<td style="width:33%">Approved By: <br>Mr. PARAG SHAH</br> </td>`;
      // content += "</tr>";
      // content += "</table>";

      content += "</div>";
    }

    // Create a new window for the print preview
    let printWindow = window.open("", "_blank");
    if (!printWindow) {
      console.error("Failed to open print preview window.");
      return;
    }
    printWindow.document.write(`
      <html>
      <head>
        <title>Print Preview</title>
        <style>
          @page { margin: 1cm; }
          .print-page { page-break-after: always; }
          .table-bordered { width: 100%; border-collapse: collapse; }
          .table-bordered th, .table-bordered td { border: 1px solid black; padding: 8px; }
          body { font-family: Arial, sans-serif; }
        </style>
      </head>
      <body>
        ${content}
        <script>
          window.onload = function() {
            window.print();
          };
        </script>
      </body>
      </html>
    `);
    printWindow.document.close();

    if (printbuttons) printbuttons.style.display = "block";
    if (report_container) report_container.style.padding = "20px 10px";
  };

  return (
    <>
      {GhantiCleaningReportList && GhantiCleaningReportList.length ? (
        <GridLayout gap={{ rows: 10, cols: 10 }} cols={[{ width: "100%" }]}>
          <GridLayoutItem className="d-flex justify-content-between align-items-center">
            <Typography.h4 className="m-0">
              Ghanti Cleaning List{" "}
              {(GhantiCleaningReportDetails?.from_date ||
                GhantiCleaningReportDetails?.to_date) && (
                <span style={{ fontSize: 13 }}>
                  {"("}
                  {GhantiCleaningReportDetails?.from_date
                    ? moment(
                        GhantiCleaningReportDetails?.from_date,
                        "YYYY-MM-DD"
                      ).format("DD/MM/YYYY")
                    : ""}
                  {GhantiCleaningReportDetails?.from_date &&
                    GhantiCleaningReportDetails?.to_date &&
                    " - "}
                  {GhantiCleaningReportDetails?.to_date
                    ? moment(
                        GhantiCleaningReportDetails?.to_date,
                        "YYYY-MM-DD"
                      ).format("DD/MM/YYYY")
                    : ""}
                  {")"}
                </span>
              )}
            </Typography.h4>
            <div>
              {isExcelAccess && (
                <Button
                  type="button"
                  fillMode={"solid"}
                  themeColor={"primary"}
                  onClick={handleExportExcel}
                  style={{ marginRight: 5 }}
                >
                  Excel Export
                </Button>
              )}
              {isPrintAccess && (
                <Button
                  style={{ marginRight: 5 }}
                  type="button"
                  fillMode={"solid"}
                  themeColor={"primary"}
                  onClick={() => printTable(GhantiCleaningReportList)}
                >
                  Print
                </Button>
              )}
            </div>
          </GridLayoutItem>
          <GridLayoutItem>
            <Grid
              className="table-bordered responsive-table"
              style={{ maxHeight: "calc(100vh - 152px)" }}
              filterable={true}
              filter={filter}
              data={
                filter
                  ? filterBy(GhantiCleaningReportList, filter).slice(
                      page.skip,
                      page.take + page.skip
                    )
                  : GhantiCleaningReportList.slice(
                      page.skip,
                      page.take + page.skip
                    )
              }
              onFilterChange={handleFilterChange}
              skip={page.skip}
              take={page.take}
              total={GhantiCleaningReportList.length}
              pageable={{
                buttonCount: 5,
                pageSizes: [10, 25, 50, 100, 500, "All"],
                pageSizeValue: pageSizeValue,
                type: "input",
              }}
              onPageChange={pageChange}
              cells={{
                headerCell: HeaderCustomCell,
                data: MyDataCustomCell,
              }}
            >
              <GridColumn
                field="UniqueNo"
                title="Sr. No."
                width={80}
                filterable={false}
              />
              <GridColumn
                field="machine_cleaning_date"
                title="Date"
                width={150}
                cell={(props: any) => (
                  <td>
                    {props.dataItem?.machine_cleaning_date
                      ? moment(
                          props.dataItem?.machine_cleaning_date,
                          "DD/MM/YYYY"
                        ).format("DD MMM YYYY")
                      : ""}
                  </td>
                )}
              />
              <GridColumn
                field="product_name"
                title="Product Name"
                width={350}
              />
              <GridColumn field="machine_name" title="Ghanti No" width={200} />
              <GridColumn
                field="grinding_lot_no"
                title="Grinding Lot No"
                width={150}
                cell={(props: any) => (
                  <td className="text-end">
                    {props.dataItem?.grinding_lot_no}
                  </td>
                )}
              />
              <GridColumn
                field="production_qty"
                title="Production Qty"
                width={150}
                cell={(props: any) => (
                  <td className="text-end">
                    {props.dataItem?.production_qty
                      ? formatIndianNumberForQty(
                          +props.dataItem?.production_qty
                        )
                      : null}
                  </td>
                )}
              />
              <GridColumn
                field="maintenance_time"
                title="Maintenance Time"
                width={200}
              />
              <GridColumn field="qc_name" title="QC Name" width={250} />
              <GridColumn
                field="production_name"
                title="Pro Name"
                width={250}
              />
              <GridColumn
                field="remarks"
                title="Remarks"
                width={400}
                cell={(props: any) => (
                  <td>
                    <div
                      className="remarks-content"
                      dangerouslySetInnerHTML={{
                        __html: props.dataItem?.remarks,
                      }}
                    />
                  </td>
                )}
              />
            </Grid>
          </GridLayoutItem>
        </GridLayout>
      ) : (
        <AlertBox />
      )}
    </>
  );
};
export default GhantiCleaningReportForm;
