import React, { useRef } from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppSelector } from "../../app/hooks";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import { LoadingPanel } from "../../components/layout/Loading";
import { Label } from "@progress/kendo-react-labels";
// import { useLocation } from "react-router-dom";

// import FormTextField from "../../components/formFields/FormTextField";

const ExpenseDetailsArray = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  //   const dispatch = useAppDispatch();
  //   const location = useLocation();
  const loading = useAppSelector((state) => state.payment.loading);
  const gridRef = useRef<any>(null);

  return (
    <>
      <GridLayout>
        {/* <GridLayoutItem className="bg-dark text-white fw-600">
          <GridLayout
            gap={{ rows: 10, cols: 0 }}
            cols={[{ width: "50%" }, { width: "50%" }]}
          >
            <GridLayoutItem
              style={{ padding: "5px 10px", border: "0.5px solid grey" }}
            >
              Expenses
            </GridLayoutItem>
            <GridLayoutItem
              style={{ padding: "5px 10px", border: "0.5px solid grey" }}
            >
              Amount
            </GridLayoutItem>
          </GridLayout>
        </GridLayoutItem> */}
        {loading ? (
          <LoadingPanel gridRef={gridRef} />
        ) : (
          <>
            {fieldArrayRenderProps.value &&
              fieldArrayRenderProps.value.length > 0 &&
              fieldArrayRenderProps.value.map(
                (expense_details: any, index: number) => (
                  <GridLayoutItem key={index}>
                    <GridLayout
                      gap={{ rows: 10, cols: 0 }}
                      cols={[{ width: "50%" }, { width: "50%" }]}
                    >
                      <GridLayoutItem
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "5px 10px",
                        }}
                      >
                        <Label>{expense_details?.expense_name?.toUpperCase()}</Label>
                        
                      </GridLayoutItem>

                      <GridLayoutItem>
                        <Field
                          wrapperClassName="right-alighned-field"
                          wrapperStyle={{ margin: 0, padding: "5px" }}
                          name={`expense_details.${index}.amount`}
                          placeholder="0"
                          disabled={expense_details?.expense_type === "Fixed"}
                          //   format="n2"
                          component={FormNumericTextField}
                        />
                      </GridLayoutItem>
                    </GridLayout>
                  </GridLayoutItem>
                )
              )}
          </>
        )}
      </GridLayout>
    </>
  );
};

export default ExpenseDetailsArray;
