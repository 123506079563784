import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { getAllActiveStates } from "../state/services/state.services";
import { closed } from "../../components/muidrawer/muiDrawerSlice";
import { Typography } from "@progress/kendo-react-common";
import { RxCross1 } from "react-icons/rx";
import { EXPENSE_TYPE, STATUSARRAY } from "../../_contstants/common";
import { clearExpenseTypeDetails } from "./expenseTypeSlice";
import {
  getAllActiveExpenseTypes,
  getAllExpenseTypes,
  getExpenseTypeByID,
  InsertExpenseType,
  updateExpenseType,
} from "./services/expenseType.services";

const CreateExpense: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const ExpenseTypeDetail = useAppSelector(
    (state) => state.expenseType.ExpenseTypeDetail
  );
  const expense_guid = useAppSelector((state) => state.muidrawer.data);
  const formLoading = useAppSelector((state) => state.expenseType.formLoading);
  const isIndirect = useAppSelector((state) => state.muidrawer.isIndirect);

  useEffect(() => {
    dispatch(getAllActiveStates());
    return () => {
      dispatch(clearExpenseTypeDetails());
      dispatch(closed());
    };
  }, []);

  useEffect(() => {
    if (expense_guid) {
      dispatch(getExpenseTypeByID(expense_guid));
    }
  }, [expense_guid]);

  const handleSubmit = async (values: any) => {
    try {
      if (!expense_guid) {
        const payload = {
          expense_name: values.expense_name,
          expense_type: values.expense_type,

          isactive: values.isactive === 1,
        };
        const response = await dispatch(InsertExpenseType(payload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearExpenseTypeDetails());
          isIndirect
            ? dispatch(getAllActiveExpenseTypes())
            : dispatch(getAllExpenseTypes());
        }
      } else {
        const payload = {
          expense_guid: values.expense_guid,
          expense_name: values.expense_name,
          expense_type: values.expense_type,
          isactive: values.isactive === 1,
        };
        const response = await dispatch(updateExpenseType(payload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearExpenseTypeDetails());
          isIndirect
            ? dispatch(getAllActiveExpenseTypes())
            : dispatch(getAllExpenseTypes());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={ExpenseTypeDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "17px 0 8px",
              }}
            >
              <Typography.h4 className="m-0">
                {expense_guid ? "Update Expense Type" : "Add Expense Type"}
              </Typography.h4>
              <Button
                type="button"
                fillMode={"flat"}
                themeColor={"dark"}
                style={{ marginRight: 4 }}
                onClick={() => dispatch(closed())}
              >
                <RxCross1 />
              </Button>
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="expense_name"
                label="Expense Name"
                placeholder="i.e. Packing Expense"
                component={FormTextField}
                validator={requiredValidator}
                astrike={true}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id={"expense_type"}
                name={"expense_type"}
                label={"Expense Type"}
                placeholder="i.e. Fixed"
                component={FormSelectionField}
                validator={requiredValidator}
                astrike={true}
                options={EXPENSE_TYPE?.map((expensetype: any) => {
                  return {
                    value: expensetype?.value,
                    label: expensetype?.label,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id={"isactive"}
                name={"isactive"}
                label={"Status"}
                placeholder="i.e. Active"
                component={FormSelectionField}
                validator={requiredValidator}
                astrike={true}
                options={STATUSARRAY?.map((status: any) => {
                  return {
                    value: status.value,
                    label: status.label,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={3}
              style={{
                textAlign: "start",
                marginTop: "20px",
                width: "100%",
              }}
            >
              <ButtonWithLoading
                label={expense_guid ? "Update" : "Save"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />
              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginLeft: 4 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
            </GridLayoutItem>
          </GridLayout>
        </FormElement>
      )}
    />
  );
};

export default CreateExpense;
