import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MdFiberNew } from "react-icons/md";
import { AiTwotoneEdit } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { PiProhibit } from "react-icons/pi";
import { FaPeopleGroup } from "react-icons/fa6";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Button } from "@progress/kendo-react-buttons";
import { BsInfoCircle } from "react-icons/bs";
import { FaEye } from "react-icons/fa";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getAllCurrentDateAndDistributorWiseRetailer,
  getSalesReprsentativePDF,
  updateVisitStatus,
} from "./services/distributorOrder.services";
import IconButton from "../../components/common/IconButton";
import { deleteRetailerSalesOrder } from "../retailersalesorder/services/retailerSalesOrder.services";
import { LoadingPanel } from "../../components/layout/Loading";

const DistributorOrder: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const distributor_id = location.state?.distributor_id;
  const date = location.state?.date;
  // const sr_id = location.state?.sr_id;

  const UserIDString = useAppSelector(
    (state) => state.login.loginDetails.UserID
  );
  const UserID = parseInt(UserIDString);

  const DistributorWiseRetailerList = useAppSelector(
    (state) => state.distributorOrder.DistributorWiseRetailerList
  );
  const distributorOrderLoading = useAppSelector(
    (state) => state.distributorOrder.loading
  );
  const retailerSalesOrderLoading = useAppSelector(
    (state) => state.retailerSalesOrder.loading
  );

  const [showfilterColumns, setShowFilterColumns] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (distributor_id && date && UserID) {
      const fetchRetailers = () => {
        const payload = {
          current_date: date ? moment(date).format("YYYY-MM-DD") : "",
          sr_id: UserID ? +UserID : null,
          distributor_id: distributor_id ? +distributor_id : null,
        };
        dispatch(getAllCurrentDateAndDistributorWiseRetailer(payload));
      };

      fetchRetailers();
    }
  }, []);

  const handleAddCompititor = (sr_retailer_id: any) => {
    navigate("compititor/add", {
      state: {
        sr_retailer_id: sr_retailer_id,
        distributor_id: distributor_id ? +distributor_id : null,
        date: date ? moment(date).format("YYYY-MM-DD") : "",
      },
    });
  };

  const handleUpdateCompititor = (
    sr_retailer_id: any,
    compitior_id: number
  ) => {
    navigate("compititor/edit", {
      state: {
        sr_retailer_id: sr_retailer_id,
        compitior_id: compitior_id ? +compitior_id : null,
        distributor_id: distributor_id ? +distributor_id : null,
        date: date ? moment(date).format("YYYY-MM-DD") : "",
      },
    });
  };

  const handleAddNewOrder = (
    sr_retailer_id: number,
    retailer_id: number,
    retailer_name: string,
    date: string
  ) => {
    navigate("/retailersalesorder/create", {
      state: {
        distributor_id: distributor_id ? +distributor_id : null,
        sr_retailer_id: sr_retailer_id ? +sr_retailer_id : null,
        date: date ? moment(date, "DD/MM/YYYY")?.toDate() : "",
        retailer_options: [
          {
            id: retailer_id,
            retailer_name: retailer_name,
          },
        ],
      },
    });
  };

  const handleUpdateOrder = (sr_retailer_order_id: number) => {
    navigate("/retailersalesorder/edit", {
      state: {
        id: sr_retailer_order_id ? +sr_retailer_order_id : null,
        date: date ? moment(date, "DD/MM/YYYY")?.toDate() : "",
      },
    });
  };

  const handleViewOrder = (sr_retailer_order_id: number) => {
    navigate("/retailersalesorder/view", {
      state: {
        id: sr_retailer_order_id ? +sr_retailer_order_id : null,
        // isView: true,
        distributor_id: distributor_id ? +distributor_id : null,
        date: date ? date : "",
      },
    });
  };

  const handleDeleteOrder = async (
    id: number,
    sr_retailer_order_id: number
  ) => {
    const response = await dispatch(
      deleteRetailerSalesOrder(sr_retailer_order_id)
    );
    if (response.meta.requestStatus === "fulfilled") {
      const payload = {
        id: id ? +id : null,
        visit_status: null,
      };
      const res = await dispatch(updateVisitStatus(payload));
      if (res.meta.requestStatus === "fulfilled") {
        const payload = {
          current_date: date ? moment(date).format("YYYY-MM-DD") : "",
          sr_id: UserID ? +UserID : null,
          distributor_id: distributor_id ? +distributor_id : null,
        };
        dispatch(getAllCurrentDateAndDistributorWiseRetailer(payload));
      }
    }
  };

  const handleNoOrder = async (id: number, visit_status: number | null) => {
    if (id) {
      const payload = {
        id: id ? +id : null,
        visit_status: visit_status === 2 ? null : 2,
      };
      const res = await dispatch(updateVisitStatus(payload));
      if (res.meta.requestStatus === "fulfilled") {
        const payload = {
          current_date: date ? moment(date).format("YYYY-MM-DD") : "",
          sr_id: UserID ? +UserID : null,
          distributor_id: distributor_id ? +distributor_id : null,
        };
        dispatch(getAllCurrentDateAndDistributorWiseRetailer(payload));
      }
    }
  };
  const handleNoVisit = async (id: number, visit_status: number | null) => {
    if (id) {
      const payload = {
        id: id ? +id : null,
        visit_status: visit_status === 3 ? null : 3,
      };
      const res = await dispatch(updateVisitStatus(payload));
      if (res.meta.requestStatus === "fulfilled") {
        const payload = {
          current_date: date ? moment(date).format("YYYY-MM-DD") : "",
          sr_id: UserID ? +UserID : null,
          distributor_id: distributor_id ? +distributor_id : null,
        };
        dispatch(getAllCurrentDateAndDistributorWiseRetailer(payload));
      }
    }
  };

  const filteredRetailers =
    DistributorWiseRetailerList && DistributorWiseRetailerList?.length > 0
      ? DistributorWiseRetailerList?.filter((retailer: any) =>
          retailer.retailer_name
            ?.toLowerCase()
            ?.replace(/\s/g, "")
            ?.includes(searchQuery?.toLowerCase())
        )
      : [];

  const callsCompleted =
    DistributorWiseRetailerList &&
    DistributorWiseRetailerList?.filter(
      (retailer: any) => retailer?.visit_status > 0
    )?.length;

  const OrderCount =
    DistributorWiseRetailerList &&
    DistributorWiseRetailerList?.filter(
      (retailer: any) => retailer?.sr_retailer_order_id !== null
    )?.length;

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
      ? event.target.value?.replace(/\s/g, "")
      : "";
    setSearchQuery(value);
  };
  const handleDownload = async () => {
    if (date && UserID && distributor_id) {
      const payload = {
        current_date: date ? moment(date).format("YYYY-MM-DD") : "",
        sr_id: UserID ? +UserID : null,
        distributor_id: distributor_id ? +distributor_id : null,
      };
      const res = await dispatch(getSalesReprsentativePDF(payload));
      if (res.meta.requestStatus === "fulfilled") {
        navigate("/downloadorderview", {
          state: {
            current_date: date ? moment(date).format("YYYY-MM-DD") : "",
            sr_id: UserID ? +UserID : null,
            distributor_id: distributor_id ? +distributor_id : null,
          },
        });
      }
    }
  };

  return (
    <>
      {(distributorOrderLoading || retailerSalesOrderLoading) && (
        <LoadingPanel gridRef={gridRef} />
      )}
      <Form
        //   key={formKey}
        //   onSubmit={handleSubmit}
        //   initialValues={DebitNoteDetail}
        render={() => {
          return (
            <FormElement>
              <div className="container-fluid">
                <div
                  className="container"
                  style={{ height: "calc(100vh - 50px)" }}
                >
                  <div className="row">
                    <div className="col-12 d-flex justify-content-between position-relative pt-3 pb-4">
                      <Button
                        themeColor={"primary"}
                        fillMode={"solid"}
                        onClick={() => navigate("/")}
                      >
                        Back
                      </Button>
                      <Tooltip
                        anchorElement="target"
                        position="left"
                        parentTitle={true}
                      >
                        <Button
                          title="Icon Info."
                          type="button"
                          themeColor={"primary"}
                          fillMode={"solid"}
                          onClick={() => setShowFilterColumns(true)}
                        >
                          <BsInfoCircle />
                        </Button>
                      </Tooltip>
                      {showfilterColumns && (
                        <div
                          style={{
                            position: "absolute",
                            right: "0",
                            background: "white",
                            padding: "12px",
                            border: "1px solid whitesmoke",
                            width: "300px",
                            zIndex: 1,
                          }}
                        >
                          <div className="d-flex justify-content-between align-items-baseline">
                            <p className="m-0 fw-600">Icon Information</p>
                            <Button
                              themeColor={"primary"}
                              fillMode={"flat"}
                              onClick={() => setShowFilterColumns(false)}
                            >
                              <RxCross2 />
                            </Button>
                          </div>
                          <hr className="m-1" style={{ color: "lightgray" }} />
                          <div className="text-start pt-1 pb-1">
                            <FaEye
                              className="distributor-order-icon"
                              style={{ background: "green" }}
                            />{" "}
                            <span>View Order</span>
                          </div>
                          <div className="text-start pt-1 pb-1">
                            <MdFiberNew
                              className="distributor-order-icon"
                              style={{ background: "#3487d4" }}
                            />{" "}
                            <span>New Order</span>
                          </div>
                          <div className="text-start pt-1 pb-1">
                            <AiTwotoneEdit
                              className="distributor-order-icon"
                              style={{ background: "#3487d4" }}
                            />{" "}
                            <span>Edit Order</span>
                          </div>
                          <div className="text-start pt-1 pb-1">
                            <RxCross2
                              className="distributor-order-icon"
                              style={{ background: "red" }}
                            />{" "}
                            <span>Cancel Order</span>
                          </div>
                          <div className="text-start pt-1 pb-1">
                            <PiProhibit
                              className="distributor-order-icon"
                              style={{ background: "red" }}
                            />{" "}
                            <span>No Order</span>
                          </div>
                          <div className="text-start pt-1 pb-1">
                            <PiProhibit
                              className="distributor-order-icon"
                              style={{ background: "green" }}
                            />{" "}
                            <span>Cancel No Order</span>
                          </div>
                          <div className="text-start pt-1 pb-1">
                            <RxCross2
                              className="distributor-order-icon"
                              style={{ background: "goldenrod" }}
                            />{" "}
                            <span>Not Visited</span>
                          </div>
                          <div className="text-start pt-1 pb-1">
                            <RxCross2
                              className="distributor-order-icon"
                              style={{ background: "green" }}
                            />{" "}
                            <span>Cancel Not Visited</span>
                          </div>
                          <div className="text-start pt-1 pb-1">
                            <FaPeopleGroup
                              className="distributor-order-icon"
                              style={{ background: "#3487d4" }}
                            />{" "}
                            <span>Add Compititor</span>
                          </div>
                          <div className="text-start pt-1 pb-1">
                            <FaPeopleGroup
                              className="distributor-order-icon"
                              style={{ background: "green" }}
                            />{" "}
                            <span>Edit Compititor</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-12">
                      <Field
                        wrapperStyle={{
                          margin: 0,
                          width: "100%",
                          maxWidth: "400px",
                          float: "left",
                        }}
                        name="retailer"
                        label="Retailer"
                        placeholder="Type here to search retailer..."
                        component={FormTextField}
                        onChange={handleSearchChange}
                      />
                      <p
                        style={{
                          marginTop: 25,
                          display: "inline-block",
                          paddingLeft: 20,
                        }}
                      >
                        Calls Completed:{" "}
                        {`${callsCompleted || 0}/${
                          filteredRetailers?.length || 0
                        }`}
                      </p>
                    </div>
                    <div className="col-lg-8 d-flex">
                      <table className="distributor-order-table">
                        {filteredRetailers &&
                          filteredRetailers?.length > 0 &&
                          filteredRetailers?.map((retailer: any) => (
                            <tr
                              style={{
                                background:
                                  retailer?.visit_status === 1 ||
                                  retailer?.visit_status === 2 ||
                                  retailer?.visit_status === 3
                                    ? "lightgreen"
                                    : "",
                              }}
                            >
                              <td style={{ minWidth: 200, fontWeight: "bold" }}>
                                {retailer?.retailer_name}
                              </td>
                              <td style={{ width: "5%" }}>
                                <IconButton
                                  type="button"
                                  size="small"
                                  style={{
                                    border: "none",
                                    background: retailer?.sr_retailer_order_id
                                      ? "green"
                                      : "darkgray",
                                  }}
                                  disabled={!retailer?.sr_retailer_order_id}
                                  onClick={() =>
                                    handleViewOrder(
                                      retailer?.sr_retailer_order_id
                                    )
                                  }
                                >
                                  <FaEye
                                    className="absolute-position"
                                    style={{ fontSize: "20px" }}
                                  />
                                </IconButton>
                              </td>
                              <td style={{ width: "5%" }}>
                                <IconButton
                                  type="button"
                                  size="small"
                                  style={{
                                    border: "none",
                                    background: retailer?.sr_retailer_order_id
                                      ? "red"
                                      : "darkgray",
                                  }}
                                  onClick={() =>
                                    handleDeleteOrder(
                                      retailer?.id,
                                      retailer?.sr_retailer_order_id
                                    )
                                  }
                                  disabled={!retailer?.sr_retailer_order_id}
                                >
                                  <RxCross2
                                    className="absolute-position"
                                    style={{ fontSize: "20px" }}
                                  />
                                </IconButton>
                              </td>
                              <td style={{ width: "5%" }}>
                                {retailer?.sr_retailer_order_id ? (
                                  <IconButton
                                    type="button"
                                    size="small"
                                    style={{
                                      border: "none",
                                      background: "#3487d4",
                                    }}
                                    onClick={() =>
                                      handleUpdateOrder(
                                        retailer?.sr_retailer_order_id
                                      )
                                    }
                                  >
                                    <AiTwotoneEdit
                                      className="absolute-position"
                                      style={{ fontSize: "20px" }}
                                    />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    type="button"
                                    size="small"
                                    style={{
                                      border: "none",
                                      background:
                                        retailer?.visit_status === 2 ||
                                        retailer?.visit_status === 3
                                          ? "darkgray"
                                          : "#3487d4",
                                    }}
                                    disabled={
                                      retailer?.visit_status === 2 ||
                                      retailer?.visit_status === 3
                                    }
                                    onClick={() =>
                                      handleAddNewOrder(
                                        retailer?.id,
                                        retailer?.retailer_id,
                                        retailer?.retailer_name,
                                        retailer?.date
                                      )
                                    }
                                  >
                                    <MdFiberNew
                                      className="absolute-position"
                                      style={{ fontSize: "20px" }}
                                    />
                                  </IconButton>
                                )}
                              </td>
                              <td style={{ width: "5%" }}>
                                <IconButton
                                  type="button"
                                  size="small"
                                  style={{
                                    border: "none",
                                    background:
                                      retailer?.visit_status === 2
                                        ? "green"
                                        : retailer?.sr_retailer_order_id ||
                                          retailer?.visit_status === 3
                                        ? "darkgray"
                                        : "red",
                                  }}
                                  disabled={
                                    retailer?.sr_retailer_order_id ||
                                    retailer?.visit_status === 3
                                  }
                                  onClick={() =>
                                    handleNoOrder(
                                      retailer?.id,
                                      retailer?.visit_status
                                    )
                                  }
                                >
                                  <PiProhibit
                                    className="absolute-position"
                                    style={{ fontSize: "20px" }}
                                  />
                                </IconButton>
                              </td>
                              <td style={{ width: "5%" }}>
                                <IconButton
                                  type="button"
                                  size="small"
                                  style={{
                                    border: "none",
                                    background:
                                      retailer?.visit_status === 3
                                        ? "green"
                                        : retailer?.sr_retailer_order_id ||
                                          retailer?.visit_status === 2
                                        ? "darkgray"
                                        : "goldenrod",
                                  }}
                                  disabled={
                                    retailer?.sr_retailer_order_id ||
                                    retailer?.visit_status === 2
                                  }
                                  onClick={() =>
                                    handleNoVisit(
                                      retailer?.id,
                                      retailer?.visit_status
                                    )
                                  }
                                >
                                  <RxCross2
                                    className="absolute-position"
                                    style={{ fontSize: "20px" }}
                                  />
                                </IconButton>
                              </td>
                              <td style={{ width: "5%" }}>
                                {retailer?.compitior_id ? (
                                  <IconButton
                                    type="button"
                                    size="small"
                                    style={{
                                      border: "none",
                                      background:
                                        retailer?.sr_retailer_order_id ||
                                        retailer?.visit_status === 2
                                          ? "green"
                                          : "darkgray",
                                    }}
                                    disabled={
                                      !retailer?.sr_retailer_order_id &&
                                      retailer?.visit_status !== 2
                                    }
                                    onClick={() =>
                                      handleUpdateCompititor(
                                        retailer?.id,
                                        retailer?.compitior_id
                                      )
                                    }
                                  >
                                    <FaPeopleGroup
                                      className="absolute-position"
                                      style={{ fontSize: "20px" }}
                                    />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    type="button"
                                    size="small"
                                    style={{
                                      border: "none",
                                      background:
                                        retailer?.sr_retailer_order_id ||
                                        retailer?.visit_status === 2
                                          ? "#3487d4"
                                          : "darkgray",
                                    }}
                                    disabled={
                                      !retailer?.sr_retailer_order_id &&
                                      retailer?.visit_status !== 2
                                    }
                                    onClick={() =>
                                      handleAddCompititor(retailer?.id)
                                    }
                                  >
                                    <FaPeopleGroup
                                      className="absolute-position"
                                      style={{ fontSize: "20px" }}
                                    />
                                  </IconButton>
                                )}
                              </td>
                            </tr>
                          ))}
                      </table>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-12">
                      <div className="d-flex gap-2">
                        <Button
                          themeColor={"primary"}
                          fillMode={"solid"}
                          disabled={
                            filteredRetailers?.length < 1 ||
                            callsCompleted !== filteredRetailers?.length
                          }
                          onClick={() => navigate("/")}
                        >
                          Submit Placed Orders To Distributor
                        </Button>
                        {filteredRetailers?.length > 0 &&
                          callsCompleted === filteredRetailers?.length &&
                          OrderCount > 0 && (
                            <Button
                              themeColor={"primary"}
                              fillMode={"solid"}
                              onClick={() => handleDownload()}
                            >
                              Download Order
                            </Button>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </FormElement>
          );
        }}
      />
    </>
  );
};

export default DistributorOrder;
