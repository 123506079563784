import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import moment from "moment";

export const getAllDebitnoteVoucher = createAsyncThunk(
  "DebitNoteVoucher/DebitNoteVoucherFindAll",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/DebitNoteVoucher/DebitNoteVoucherFindAll`
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error While Fetch Debit Note Voucher:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createDebitnoteVoucher = createAsyncThunk(
  "DebitNoteVoucher/InsertDebitNoteVoucher",
  async (fomedata: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/DebitNoteVoucher/InsertDebitNoteVoucher`,
        fomedata
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating Debit Note Voucher :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateDebitnoteVoucher = createAsyncThunk(
  "DebitNoteVoucher/UpdateDebitNoteVoucher",
  async (formdata: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/DebitNoteVoucher/UpdateDebitNoteVoucher`,
        formdata
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Debit Note Voucher :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteDebitnoteVoucher = createAsyncThunk(
  "DebitNoteVoucher/DeleteDebitNoteVoucher",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/DebitNoteVoucher/DeleteDebitNoteVoucher`,
        { debit_note_voucher_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Debit Note Voucher :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getDebitnoteVoucherByID = createAsyncThunk(
  "DebitNoteVoucher/FindByIDDebitNoteVoucher",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/DebitNoteVoucher/FindByIDDebitNoteVoucher`,
        { debit_note_voucher_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        id: result?.id,
        debit_note_voucher_guid: result?.debit_note_voucher_guid,
        debit_note_voucher_no: result?.debit_note_voucher_no,
        debit_note_voucher_no_string: result?.debit_note_voucher_no_string,
        financial_year: result?.financial_year,
        debit_note_voucher_date: result?.debit_note_voucher_date
          ? moment(result?.debit_note_voucher_date, "YYYY-MM-DD").toDate()
          : "",
        debit_note_voucher_total_amount:
          result?.debit_note_voucher_total_amount,
        remarks: result?.remarks,
        ledger_id: result?.ledger_id,
        voucher_type_id: result?.voucher_type_id,
        debit_note_voucher_entry: result?.debit_note_voucher_entry,
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Debit Note Voucher details:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllOutstandingDebitnoteVoucher = createAsyncThunk(
  "Payment/OutstandingcreditDebitVoucherFindAll",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Payment/OutstandingcreditDebitVoucherFindAll`,
        formData
      );
      const result = response.data?.Data as any;
      return result as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Outstanding Debit Note details:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const generateDebitnoteVoucherNoForDebitnoteVoucher = createAsyncThunk(
  "DebitNoteVoucher/InvoiceNumberDebitNoteVoucher",
  async (payload: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/DebitNoteVoucher/InvoiceNumberDebitNoteVoucher`,
        payload
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error generating Debit Note Voucher no. :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
