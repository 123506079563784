import React, { useEffect, useRef } from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog } from "../../components/dialog/dialogSlice";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import AppDialog from "../../components/dialog/Dialog";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import { Typography } from "@progress/kendo-react-common";
import { setJournalBillDialogOpenIndex } from "./journalSlice";
import { LoadingPanel } from "../../components/layout/Loading";
import { useLocation } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import FormCheckbox from "../../components/formFields/FormCheckbox";

const JournalBillDetailsArray = (
  fieldArrayRenderProps: FieldArrayRenderProps
) => {
  const dispatch = useAppDispatch();
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const loading = useAppSelector((state) => state.journal.loading);
  const gridRef = useRef<any>(null);
  const location = useLocation();
  const journal_voucher_guid = location.state?.journal_voucher_guid;
  const JournalBillDialogOpenIndex = useAppSelector(
    (state) => state.journal.JournalBillDialogOpenIndex
  );
  const JournalDetail = useAppSelector((state) => state.journal.JournalDetail);

  useEffect(() => {
    const element = document.getElementsByName(
      `journal_bill_entry.0.referance_amount`
    );
    if (!loading) {
      if (element?.[0]) {
        setTimeout(() => {
          element?.[0]?.focus();
          if (journal_voucher_guid) {
            element?.[0]?.blur();
          }
        }, 1);
      }
    }

    // console.log(document.activeElement);

    // console.log("debitcredidialog", debitcredidialog);
  }, [loading]);

  const handleCloseDialog = () => {
    dispatch(closeDialog());
    dispatch(setJournalBillDialogOpenIndex(-1));
    const element = document.getElementById(
      `journal_voucher_entry.${JournalBillDialogOpenIndex + 1}.debit_credit_id`
    );
    if (element) {
      setTimeout(() => {
        element.focus();
      }, 1);
    } else {
      const element = document.getElementById(`remarks_for_focus`);
      if (element) {
        setTimeout(() => {
          element.focus();
        }, 1);
      }
    }
  };

  const handleKeyEvent = (
    e: any,
    index: number,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => {
    const isremainingAmount0 =
      fieldArrayRenderProps.formRenderProps.valueGetter(
        "IndexDetails"
      )?.remainingAmount;

    let pendingAmount = 0;
    if (journal_voucher_guid) {
      pendingAmount =
        (fieldArrayRenderProps.value[index].final_pending_amount || 0) +
        (+JournalDetail?.journal_voucher_entry?.[
          JournalBillDialogOpenIndex
        ]?.journal_voucher_entry_referance.filter(
          (item: any) =>
            item.refernace_name ===
            fieldArrayRenderProps.formRenderProps.valueGetter(
              `journal_bill_entry.${index}.bill_no_string`
            )
        )?.[0]?.referance_amount || 0);
    } else {
      pendingAmount =
        fieldArrayRenderProps.value[index]?.final_pending_amount || 0;
    }

    const remainingAmount =
      (fieldArrayRenderProps.formRenderProps.valueGetter("IndexDetails")
        ?.remainingAmount || 0) +
      (fieldArrayRenderProps.value?.[index]?.referance_amount || 0);

    if (pendingAmount > remainingAmount) {
      fieldArrayRenderProps.formRenderProps.onChange(
        `journal_bill_entry.${index}.MaxAmount`,
        {
          value: +remainingAmount,
        }
      );
    } else {
      fieldArrayRenderProps.formRenderProps.onChange(
        `journal_bill_entry.${index}.MaxAmount`,
        {
          value: +pendingAmount,
        }
      );
    }

    if (e.key === "Tab" && isremainingAmount0 === 0) {
      if (!e.shiftKey) {
        dispatch(closeDialog());
        const element = document.getElementById(
          `journal_voucher_entry.${
            JournalBillDialogOpenIndex + 1
          }.debit_credit_id`
        );
        if (element) {
          setTimeout(() => {
            element.focus();
          }, 1);
        } else {
          const element = document.getElementById(`remarks_for_focus`);
          if (element) {
            setTimeout(() => {
              element.focus();
            }, 1);
          }
        }
        dispatch(setJournalBillDialogOpenIndex(-1));
      }
    }
  };

  const handleRefAmountChange = (
    e: any,
    index: number,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => {
    let pendingAmount = 0;
    if (journal_voucher_guid) {
      pendingAmount =
        (fieldArrayRenderProps.formRenderProps.valueGetter(
          `journal_bill_entry.${index}.final_pending_amount`
        ) || 0) +
        (+JournalDetail?.journal_voucher_entry?.[
          JournalBillDialogOpenIndex
        ]?.journal_voucher_entry_referance.filter(
          (item: any) =>
            item.refernace_name ===
            fieldArrayRenderProps.formRenderProps.valueGetter(
              `journal_bill_entry.${index}.bill_no_string`
            )
        )?.[0]?.referance_amount || 0);
    } else {
      pendingAmount =
        fieldArrayRenderProps.formRenderProps.valueGetter(
          `journal_bill_entry.${index}.final_pending_amount`
        ) || 0;
    }

    if (e.value === pendingAmount) {
      fieldArrayRenderProps.formRenderProps.onChange(
        `journal_bill_entry.${index}.isfullpayment`,
        { value: true }
      );
    } else {
      fieldArrayRenderProps.formRenderProps.onChange(
        `journal_bill_entry.${index}.isfullpayment`,
        { value: false }
      );
    }
  };
  const handleIsFullJournal = (
    e: any,
    index: number,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => {
    if (e.value) {
      let pendingAmount = 0;
      if (journal_voucher_guid) {
        pendingAmount =
          (fieldArrayRenderProps.formRenderProps.valueGetter(
            `journal_bill_entry.${index}.final_pending_amount`
          ) || 0) +
          (+JournalDetail?.journal_voucher_entry?.[
            JournalBillDialogOpenIndex
          ]?.journal_voucher_entry_referance.filter(
            (item: any) =>
              item.refernace_name ===
              fieldArrayRenderProps.formRenderProps.valueGetter(
                `journal_bill_entry.${index}.bill_no_string`
              )
          )?.[0]?.referance_amount || 0);
      } else {
        pendingAmount =
          fieldArrayRenderProps.formRenderProps.valueGetter(
            `journal_bill_entry.${index}.final_pending_amount`
          ) || 0;
      }
      const remainingAmount =
        (fieldArrayRenderProps.formRenderProps.valueGetter("IndexDetails")
          ?.remainingAmount || 0) +
        (fieldArrayRenderProps.value?.[index]?.referance_amount || 0);

      if (remainingAmount >= pendingAmount) {
        fieldArrayRenderProps.formRenderProps.onChange(
          `journal_bill_entry.${index}.referance_amount`,
          { value: pendingAmount }
        );
      } else {
        fieldArrayRenderProps.formRenderProps.onChange(
          `journal_bill_entry.${index}.isfullpayment`,
          { value: false }
        );
      }
    } else {
      fieldArrayRenderProps.formRenderProps.onChange(
        `journal_bill_entry.${index}.referance_amount`,
        { value: 0 }
      );
    }
  };

  return (
    <>
      {dialogName === "JournalBillItemDetailsDialog" && (
        <AppDialog className="journal-bill-dialog">
          <>
            <GridLayout
              cols={[
                {
                  width: "950px",
                },
              ]}
              style={{ padding: "10px" }}
            >
              <GridLayoutItem>
                <Typography.p className="m-0 text-center">
                  Bill-wise Details For :-{" "}
                  <b>
                    {fieldArrayRenderProps.formRenderProps
                      .valueGetter("IndexDetails")
                      ?.ledger_name?.toUpperCase()}
                  </b>
                </Typography.p>
                <Typography.p className="m-0 text-center">
                  Up to :-{" "}
                  <b>
                    ₹{" "}
                    {
                      fieldArrayRenderProps.formRenderProps.valueGetter(
                        "IndexDetails"
                      )?.amount
                    }{" "}
                    {fieldArrayRenderProps.formRenderProps.valueGetter(
                      "IndexDetails"
                    )?.debit_credit === "Debit"
                      ? "Dr"
                      : "Cr"}
                  </b>
                </Typography.p>
                <Typography.p
                  className=" text-center"
                  style={{ position: "relative" }}
                >
                  Remaining Amount :- ₹{" "}
                  <b>
                    {
                      fieldArrayRenderProps.formRenderProps.valueGetter(
                        "IndexDetails"
                      )?.remainingAmount
                    }{" "}
                    {fieldArrayRenderProps.formRenderProps.valueGetter(
                      "IndexDetails"
                    )?.debit_credit === "Debit"
                      ? "Dr"
                      : "Cr"}
                  </b>
                  {/* <button style={{visibility: "hidden", position: "absolute" }} id="use-me-for-focus">
                  Focus Button
                </button> */}
                </Typography.p>
              </GridLayoutItem>
            </GridLayout>
            <GridLayout
              cols={[{ width: "950px" }]}
              style={{
                maxHeight: "500px",
                overflowY: "scroll",
              }}
            >
              <GridLayoutItem
                className="bg-dark text-white fw-600"
                style={{
                  position: "sticky",
                  top: "0",
                  zIndex: "2",
                }}
              >
                <GridLayout
                  gap={{ rows: 10, cols: 0 }}
                  cols={[
                    { width: "8%" },
                    { width: "15%" },
                    { width: "15%" },
                    { width: "13%" },
                    { width: "14%" },
                    { width: "17%" },
                    { width: "15%" },
                    { width: "3%" },
                  ]}
                >
                  <GridLayoutItem
                    style={{ padding: "5px 10px", border: "0.5px solid grey" }}
                  >
                    Sr. No
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{ padding: "5px 10px", border: "0.5px solid grey" }}
                  >
                    Bill No
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{ padding: "5px 10px", border: "0.5px solid grey" }}
                  >
                    Bill Date
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{ padding: "5px 10px", border: "0.5px solid grey" }}
                  >
                    Due Date
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{ padding: "5px 10px", border: "0.5px solid grey" }}
                  >
                    Bill Amount
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{ padding: "5px 10px", border: "0.5px solid grey" }}
                  >
                    Pending Amount
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{ padding: "5px 10px", border: "0.5px solid grey" }}
                  >
                    Amount
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{ padding: "5px 10px", border: "0.5px solid grey" }}
                  ></GridLayoutItem>
                </GridLayout>
              </GridLayoutItem>
              {loading ? (
                <LoadingPanel gridRef={gridRef} />
              ) : (
                <>
                  {fieldArrayRenderProps.value &&
                    fieldArrayRenderProps.value.length > 0 &&
                    fieldArrayRenderProps.value.map(
                      (journal_bill_entry: any, index: number) => (
                        <GridLayoutItem
                          key={index}
                          style={{
                            backgroundColor:
                              index % 2 === 1 ? "#f4f1f1" : "#f9fdf9",
                          }}
                        >
                          <GridLayout
                            style={{
                              padding: "5px 0",
                            }}
                            gap={{ rows: 10, cols: 0 }}
                            cols={[
                              { width: "8%" },
                              { width: "15%" },
                              { width: "15%" },
                              { width: "13%" },
                              { width: "14%" },
                              { width: "17%" },
                              { width: "15%" },
                              { width: "3%" },
                            ]}
                          >
                            <GridLayoutItem
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "5px 10px",
                              }}
                            >
                              {index + 1}
                            </GridLayoutItem>
                            <GridLayoutItem
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "5px 10px",
                              }}
                            >
                              {journal_bill_entry?.bill_no_string}
                            </GridLayoutItem>
                            <GridLayoutItem
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "5px 10px",
                              }}
                            >
                              {journal_bill_entry?.invoice_date}
                            </GridLayoutItem>
                            <GridLayoutItem
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "5px 10px",
                              }}
                            >
                              {journal_bill_entry?.due_date}
                            </GridLayoutItem>
                            <GridLayoutItem
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "end",
                                padding: "5px 10px",
                              }}
                            >
                              {Number(journal_bill_entry?.total_amount).toFixed(
                                2
                              ) || 0}
                            </GridLayoutItem>
                            <GridLayoutItem
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "end",
                                padding: "5px 15px",
                              }}
                            >
                              {Number(
                                journal_bill_entry?.final_pending_amount
                              ).toFixed(2) || 0}
                            </GridLayoutItem>
                            <GridLayoutItem>
                              <Field
                                wrapperClassName="right-alighned-field"
                                wrapperStyle={{ margin: 0, padding: "5px" }}
                                name={`journal_bill_entry.${index}.referance_amount`}
                                placeholder="0"
                                format="n2"
                                max={
                                  +fieldArrayRenderProps.formRenderProps.valueGetter(
                                    `journal_bill_entry.${index}.MaxAmount`
                                  )
                                }
                                onChange={(e: any) =>
                                  handleRefAmountChange(
                                    e,
                                    index,
                                    fieldArrayRenderProps
                                  )
                                }
                                component={FormNumericTextField}
                                onKeyDown={(e: any) =>
                                  handleKeyEvent(
                                    e,
                                    index,
                                    fieldArrayRenderProps
                                  )
                                }
                              />
                            </GridLayoutItem>
                            <GridLayoutItem>
                              <Field
                                wrapperClassName="right-alighned-field"
                                wrapperStyle={{
                                  margin: "0px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "100%",
                                  borderRadius: "5px",
                                }}
                                tabIndex={-1}
                                size="large"
                                style={{
                                  border: "1px solid #ccc",
                                }}
                                onChange={(e: any) =>
                                  handleIsFullJournal(
                                    e,
                                    index,
                                    fieldArrayRenderProps
                                  )
                                }
                                name={`journal_bill_entry.${index}.isfullpayment`}
                                component={FormCheckbox}
                              />
                            </GridLayoutItem>
                          </GridLayout>
                        </GridLayoutItem>
                      )
                    )}
                </>
              )}
            </GridLayout>
            <GridLayout
              cols={[{ width: "950px" }]}
              style={{
                position: "absolute",
                bottom: "15px",
              }}
            >
              <GridLayoutItem>
                <GridLayout cols={[{ width: "950px" }]}>
                  <GridLayoutItem
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      themeColor={"primary"}
                      fillMode={"outline"}
                      onClick={handleCloseDialog}
                      className="dialog-close-btn-entry"
                    >
                      Close
                    </Button>
                    {/* // <DialogCloseButton themeColor="error" /> */}
                  </GridLayoutItem>
                </GridLayout>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default JournalBillDetailsArray;
