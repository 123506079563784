import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createDebitnoteVoucher,
  deleteDebitnoteVoucher,
  getAllOutstandingDebitnoteVoucher,
  getAllDebitnoteVoucher,
  getDebitnoteVoucherByID,
  updateDebitnoteVoucher,
} from "./services/debitnoteVoucher";
import {
  IDebitnoteVoucher,
  IDebitnoteVoucherInitialState,
} from "./debitnoteVoucherModel";
import moment from "moment";

const initialState: IDebitnoteVoucherInitialState = {
  loading: false,
  error: "",
  DebitnoteVoucherEntryDeleteIndex: -1,
  DebitnoteVoucherBillDialogOpenIndex: -1,
  DebitnoteVoucherID: null,
  DebitnoteVoucherGUID: "",
  DebitnoteVoucherList: [],
  OutstandingDebitnoteVoucherList: [],
  DebitnoteVoucherDetail: {
    financial_year: null,
    ledger_id: null,
    voucher_type_id: null,
    remarks: "",
    debit_note_voucher_guid: "",
    debit_note_voucher_no: null,
    debit_note_voucher_no_string: "",
    debit_note_voucher_date: moment().toDate(),
    debit_note_voucher_total_amount: null,
    debit_note_voucher_entry: [],
  },
};

const debitnoteVoucherSlice = createSlice({
  name: "debitnotevoucher",
  initialState,
  reducers: {
    setDebitnoteVoucherID: (state, action) => {
      state.DebitnoteVoucherID = action.payload;
    },
    setDebitnoteVoucherGUID: (state, action) => {
      state.DebitnoteVoucherGUID = action.payload;
    },
    clearDebitnoteVoucherDetails: (state) => {
      state.DebitnoteVoucherDetail = initialState.DebitnoteVoucherDetail;
    },
    setDebitnoteVoucherEntryDeleteIndex: (state, action) => {
      state.DebitnoteVoucherEntryDeleteIndex = action.payload;
    },
    setDebitnoteVoucherBillDialogOpenIndex: (state, action) => {
      state.DebitnoteVoucherBillDialogOpenIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllDebitnoteVoucher.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllDebitnoteVoucher.fulfilled,
      (state, action: PayloadAction<IDebitnoteVoucher[]>) => {
        state.loading = false;
        state.DebitnoteVoucherList = action.payload || [];
      }
    );
    builder.addCase(getAllDebitnoteVoucher.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.DebitnoteVoucherList = [];
    });

    builder.addCase(createDebitnoteVoucher.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createDebitnoteVoucher.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createDebitnoteVoucher.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateDebitnoteVoucher.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateDebitnoteVoucher.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateDebitnoteVoucher.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteDebitnoteVoucher.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteDebitnoteVoucher.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteDebitnoteVoucher.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getDebitnoteVoucherByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getDebitnoteVoucherByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.DebitnoteVoucherDetail = action.payload;
    });
    builder.addCase(getDebitnoteVoucherByID.rejected, (state, action) => {
      state.loading = false;
      state.DebitnoteVoucherDetail = initialState.DebitnoteVoucherDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getAllOutstandingDebitnoteVoucher.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllOutstandingDebitnoteVoucher.fulfilled,
      (state, action: any) => {
        state.loading = false;
        state.OutstandingDebitnoteVoucherList = action.payload;
      }
    );
    builder.addCase(
      getAllOutstandingDebitnoteVoucher.rejected,
      (state, action) => {
        state.loading = false;
        state.OutstandingDebitnoteVoucherList =
          initialState.OutstandingDebitnoteVoucherList;
        state.error = action.error.message || "Something went wrong";
      }
    );
  },
});

export const {
  setDebitnoteVoucherID,
  setDebitnoteVoucherBillDialogOpenIndex,
  setDebitnoteVoucherEntryDeleteIndex,
  setDebitnoteVoucherGUID,
  clearDebitnoteVoucherDetails,
} = debitnoteVoucherSlice.actions;
export default debitnoteVoucherSlice.reducer;
