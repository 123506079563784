import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  convertToWords,
  formatIndianNumber,
  formatIndianNumberForQty,
} from "../../_helper/helper";
import { getCreditNotePrint } from "./services/creditNote.services";

const CreditNotePrint = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const params = useParams();
  const queryParam = new URLSearchParams(location.search);
  const isPrint = queryParam.get("print");
  const credit_note_guid = params?.credit_note_guid;
  const CreditNotePrintDetails = useAppSelector(
    (state) => state.creditnote.CreditNotePrintDetails
  );

  // const [tableHeight, setTableHeight] = React.useState<any>(null);

  useEffect(() => {
    if (credit_note_guid) {
      const handleOrderPrint = async () => {
        const res = await dispatch(getCreditNotePrint(credit_note_guid));
        if (res.meta.requestStatus === "fulfilled") {
          if (isPrint === "1") {
            window.print();
            // const table1: any = document.getElementById("main-table");
            // const table2: any = document.getElementById("secondary-table");

            // if (table1 && table1.offsetHeight > 421) {
            //   table2.classList.add("start-on-new-page");
            // }
          }
        }
      };
      handleOrderPrint();
    }
  }, [credit_note_guid]);

  // const table1: any = document.getElementById("main-table");
  // const table2: any = document.getElementById("secondary-table");

  // useEffect(() => {
  //   if (table1?.offsetHeight) {
  //     setTableHeight(table1?.offsetHeight);
  //   }
  //   if (table1 && table1.offsetHeight > 421) {
  //     table2.classList.add("start-on-new-page");
  //   }
  // }, [table1 && table1?.offsetHeight]);

  let totalAmount = 0;
  let totalTaxAmount = 0;

  CreditNotePrintDetails?.credit_note_items &&
    CreditNotePrintDetails?.credit_note_items.length > 0 &&
    CreditNotePrintDetails?.credit_note_items?.map(
      (item: any) => (totalAmount += item?.amount)
    );

  CreditNotePrintDetails?.gst_details_hsn &&
    CreditNotePrintDetails?.gst_details_hsn?.length > 0 &&
    CreditNotePrintDetails?.gst_details_hsn?.map((gstamount: any) => {
      const totaltax =
        gstamount?.total_taxable_amount &&
        gstamount?.gst &&
        (gstamount?.total_taxable_amount * gstamount?.gst) / 100;
      totalTaxAmount += totaltax || 0;
    });

  return (
    <div className="p-4 sales-print-page">
      <p className="fw-600 text-center mb-1">
        <i>(ORIGINAL FOR RECEPIENT)</i>
      </p>
      <h6 className="text-center fw-600">
        {/* {CreditNotePrintDetails?.voucher_type_id === 2
          ? "Credit Note"
          : "Tax Invoice"} */}
        Credit Note
      </h6>
      {/* <p className="text-center">
        <b>Harihar Foods Pvt. Ltd.</b>
        <br />
        519/1, Nr Ramol Police Chowky
        <br />
        Phase IV, G.I.D.C. Vatva, Ahmedabad 382445
        <br />
        Tel (Off) 079-25841773/25840855 <br />
        FSSAI No: 10012021000359 <br />
        GSTIN/UIN: 24AAACH5100R1ZN <br />
        State Name: Gujarat, Code: 24 <br />
        E-Mail: marketing@shreeganeshmasala.com
      </p> */}
      <table className="table table-bordered w-100 sales-print-table m-0">
        <tr style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}>
          <td rowSpan={3} className="p-0" style={{ width: "40%" }}>
            <p className="m-0 p-1">
              <b style={{ fontSize: 13 }}>Harihar Foods Pvt. Ltd.</b>
              <br />
              519/1, Nr Ramol Police Chowky
              <br />
              Phase IV, G.I.D.C. Vatva, Ahmedabad 382445
              <br />
              Tel (Off) 079-25841773/25840855 <br />
              FSSAI No: 10012021000359 <br />
              GSTIN/UIN: 24AAACH5100R1ZN <br />
              State Name: Gujarat, Code: 24 <br />
              E-Mail: marketing@shreeganeshmasala.com
            </p>
          </td>
          <td style={{ height: 40, width: "20%" }}>
            Credit Note No.
            <br />
            <span className="fw-600 p-0">
              {CreditNotePrintDetails?.credit_note_no_string}
            </span>
          </td>
          <td style={{ height: 40, width: "20%" }}>
            Dated
            <br />
            <span className="fw-600 p-0">
              {CreditNotePrintDetails?.credit_note_date}
            </span>
          </td>
          <td>
            Destination
            <br />
            <span className="fw-600 p-0">
              {CreditNotePrintDetails?.city_name}
            </span>
          </td>
        </tr>
        <tr style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}>
          <td style={{ height: 40 }}>
            Sales Invoice No.
            <br />
            <span className="fw-600 p-0">
              {CreditNotePrintDetails?.bill_no}
            </span>
          </td>
          <td>
            Sales Invoice Date
            <br />
            <span className="fw-600 p-0">
              {" "}
              {CreditNotePrintDetails?.bill_date}
            </span>
          </td>
          <td>
            Vehicle Number
            <br />
            <span className="fw-600 p-0">
              {CreditNotePrintDetails?.vehicle_no}
            </span>
          </td>
        </tr>
        <tr style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}>
          <td style={{ height: 40 }} colSpan={3}>
            Dispatched through
            <br />
            <span className="fw-600 p-0">
              {CreditNotePrintDetails?.transport_name}
            </span>
          </td>
        </tr>

        <tr style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}>
          <td colSpan={4} className="p-0">
            <div style={{ display: "flex", padding: 0 }}>
              <div
                className="p-1"
                style={{
                  flex: "1",
                  borderRight: "1px solid rgba(0, 0, 0, 0.5)",
                }}
              >
                <p className="fw-600 mb-1">Billing Address:-</p>
                <p className="m-0 fw-bold" style={{ fontSize: 13 }}>
                  {CreditNotePrintDetails?.account_name}
                </p>
                <div className="pt-0" style={{ minHeight: 80 }}>
                  {CreditNotePrintDetails?.address1}{" "}
                  {CreditNotePrintDetails?.address1 && <br />}
                  Ph.: {CreditNotePrintDetails?.mobile_number}
                  {CreditNotePrintDetails?.mobile_number &&
                    CreditNotePrintDetails?.alternet_mobile_no &&
                    ", "}
                  {CreditNotePrintDetails?.alternet_mobile_no} <br />
                  GSTIN/UIN: {CreditNotePrintDetails?.gst_no}
                  {", "}&nbsp;&nbsp; PAN No.: {CreditNotePrintDetails?.pan_no}{" "}
                  <br />
                  State: {CreditNotePrintDetails?.state_name} Code:{" "}
                  {CreditNotePrintDetails?.gst_state_code}
                </div>
              </div>
              <div className="p-1" style={{ minHeight: 80, flex: 1 }}>
                <p className="fw-600 mb-1">Shipping Address:-</p>
                {CreditNotePrintDetails?.address2}{" "}
                {CreditNotePrintDetails?.address2 && <br />}
                {CreditNotePrintDetails?.city_name2}{" "}
                {CreditNotePrintDetails?.city_name2 &&
                  CreditNotePrintDetails?.pincode2 &&
                  " - "}
                {CreditNotePrintDetails?.pincode2}{" "}
                {CreditNotePrintDetails?.pincode2 && <br />}
                State: {CreditNotePrintDetails?.state_name2} Code:{" "}
                {CreditNotePrintDetails?.gst_state_code}
              </div>
            </div>
          </td>
        </tr>
        {/* <tr style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}>
          <td className="w-25" style={{ height: 40 }}>
            Sales Order No.
            <br />
            <span className="fw-600 p-0">
              {CreditNotePrintDetails?.sales_order_no}
            </span>
          </td>
          <td className="w-25">
            Destination
            <br />
            <span className="fw-600 p-0">{CreditNotePrintDetails?.city_name}</span>
          </td>
        </tr>
        <tr style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}>
          <td className="w-25" style={{ height: 40 }}>
            Challan No.
            <br />
            <span className="fw-600 p-0">
              {CreditNotePrintDetails?.sales_challan_no}
            </span>
          </td>
          <td className="w-25">
            Delivery Note Date
            <br />
            <span className="fw-600 p-0"></span>
          </td>
        </tr> */}
        {/* <tr style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}>
          <td className="w-25" style={{ height: 40 }}>
            Dispatched through
            <br />
            <span className="fw-600 p-0">
              {CreditNotePrintDetails?.transport_name}
            </span>
          </td>
          <td className="w-25">
            Vehicle Number
            <br />
            <span className="fw-600 p-0">{CreditNotePrintDetails?.vehicle_no}</span>
          </td>
        </tr>
        <tr style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}>
          <td colSpan={2} style={{ height: 40 }}>
            Scheme
            <br />
            <span className="fw-600 p-0">{CreditNotePrintDetails?.scheme}</span>
          </td>
        </tr> */}
      </table>
      <table
        cellSpacing={1}
        cellPadding={2}
        className="table w-100  sales-print-table mb-0 b-none"
        id="main-table"
      >
        <tr className="text-center fw-600" style={{ fontSize: 12 }}>
          <th rowSpan={2} style={{ width: "5%" }}>
            Sr No.
          </th>
          <th rowSpan={2} style={{ width: "40%" }}>
            Description Of Goods
          </th>
          <th rowSpan={2} style={{ width: "8%" }}>
            HSN/SAC
          </th>
          <th rowSpan={2} style={{ width: "4%" }}>
            GST Rate
          </th>
          <th rowSpan={2} style={{ width: "6%" }}>
            MRP
          </th>
          <th colSpan={2} style={{ width: "10%" }}>
            Quantity
          </th>
          <th rowSpan={2} style={{ width: "5%" }}>
            Per
          </th>
          <th rowSpan={2} style={{ width: "7%" }}>
            Rate
          </th>

          <th rowSpan={2} style={{ width: "10%" }}>
            Amount
          </th>
        </tr>
        <tr className="text-center fw-600" style={{ fontSize: 12 }}>
          <th>Free</th>
          <th>Billed</th>
        </tr>
        {CreditNotePrintDetails?.credit_note_items &&
          CreditNotePrintDetails?.credit_note_items.length > 0 &&
          CreditNotePrintDetails?.credit_note_items?.map(
            (item: any, index: number) => (
              <>
                <tr>
                  <td className="text-center">{index + 1}</td>
                  <td>
                    {item?.product_name}
                    {item?.item_description && <br />}{" "}
                    <span
                      style={{
                        fontSize: 8,
                        display: "inline-table",
                        marginLeft: 10,
                      }}
                    >
                      {item?.item_description}
                    </span>
                  </td>
                  {/* <td className="text-center">{item?.lot_no}</td> */}
                  <td className="text-center">{item?.hsn_sac_code}</td>
                  <td className="text-end">
                    {item?.billed_quantity && item?.gst ? `${item?.gst} %` : ""}
                  </td>
                  <td className="text-end">
                    {item?.billed_quantity && item?.mrp ? item?.mrp || 0 : ""}
                  </td>
                  <td className="text-end">
                    {formatIndianNumberForQty(item?.free_quantity || 0)}
                  </td>
                  <td className="text-end">
                    {formatIndianNumberForQty(item?.quantity || 0)}
                  </td>
                  <td>{item?.unit_name}</td>
                  <td className=" text-end">
                    {item?.billed_quantity
                      ? formatIndianNumber(item?.rate)
                      : ""}
                  </td>
                  <td className=" text-end">
                    {formatIndianNumber(item?.amount)}
                  </td>
                </tr>
              </>
            )
          )}
        {/* {tableHeight > 421 && (
          <tr>
            <td style={{ height: "calc(100vh - 820px)" }}></td>
            <td colSpan={10}></td>
            <td></td>
          </tr>
        )} */}
        <tr>
          <td colSpan={9}></td>
          <td className="text-end fw-600" style={{ fontSize: 12 }}>
            {formatIndianNumber(totalAmount)}
          </td>
        </tr>
        {CreditNotePrintDetails?.credit_note_duty_and_taxes &&
          CreditNotePrintDetails?.credit_note_duty_and_taxes?.length > 0 &&
          CreditNotePrintDetails?.credit_note_duty_and_taxes?.map(
            (gstdetails: any) => (
              // CreditNotePrintDetails?.state_name === "GUJARAT" ? (
              //   <>
              //     {gstdetails?.gst_per > 0 && (
              //       <tr>
              //         <td className=" text-end" colSpan={9}>
              //           CGST {gstdetails?.gst_per / 2}%
              //         </td>
              //         <td className=" text-end">
              //           {(gstdetails?.gst_per / 2)?.toFixed(2)}
              //         </td>
              //         <td>%</td>
              //         <td className=" text-end">
              //           {formatIndianNumber(gstdetails?.gst_amount)}
              //           {/* {gstdetails?.gst_amount &&
              //             gstdetails?.gst_per &&
              //             formatIndianNumber(
              //               (gstdetails?.total_taxable_amount * gstdetails?.gst_per) /
              //                 100 /
              //                 2
              //             )} */}
              //         </td>
              //       </tr>
              //     )}
              //     {gstdetails?.gst_per > 0 && (
              //       <tr>
              //         <td className=" text-end" colSpan={9}>
              //           SGST {gstdetails?.gst_per / 2}%
              //         </td>
              //         <td className=" text-end">
              //           {(gstdetails?.gst_per / 2)?.toFixed(2)}
              //         </td>
              //         <td>%</td>
              //         <td className=" text-end">
              //           {formatIndianNumber(gstdetails?.gst_amount)}
              //         </td>
              //       </tr>
              //     )}
              //   </>
              // ) : (

              <tr>
                <td className=" text-end" colSpan={7}>
                  {gstdetails?.ledger_name}
                </td>
                <td className=" text-end">{gstdetails?.gst_per?.toFixed(2)}</td>
                <td>%</td>
                <td className=" text-end">
                  {formatIndianNumber(gstdetails?.gst_amount)}
                </td>
              </tr>
            )
            // )
          )}
        <tr style={{ fontSize: 12 }}>
          <td colSpan={9} className=" text-end fw-600">
            {" "}
            Round Off:
          </td>
          <td className="text-end fw-600">
            {formatIndianNumber(CreditNotePrintDetails?.round_off)}
          </td>
        </tr>
        <tr style={{ fontSize: 12 }}>
          <td colSpan={9} className=" text-end fw-600">
            {" "}
            Total Invoice Amount:
          </td>
          <td className="text-end fw-600">
            {formatIndianNumber(
              Math.round(+CreditNotePrintDetails?.total_amount)
            )}
          </td>
        </tr>
        <tr className="b-none">
          <td colSpan={9} className="b-none">
            <span className="d-block p-0" style={{ fontSize: 8 }}>
              Amount Chargeable (in words)
            </span>
            <span
              className="d-block fw-600 p-0"
              style={{ fontSize: 12, textTransform: "uppercase" }}
            >
              {convertToWords(
                Math.round(+CreditNotePrintDetails?.total_amount)
              )}
            </span>
          </td>
          <td className="text-end b-none">E & O.E</td>
        </tr>
      </table>
      <table
        cellSpacing={1}
        cellPadding={2}
        className="table table-bordered w-100  sales-print-table mb-0 b-none"
        id="secondary-table"
        style={{ pageBreakInside: "avoid" }}
      >
        {CreditNotePrintDetails?.state_name === "GUJARAT" ? (
          <>
            <tr className="text-center fw-600" style={{ fontSize: 13 }}>
              <th style={{ width: "30%" }} rowSpan={2}>
                HSN/SAC Code
              </th>
              <th rowSpan={2}>Taxable Value</th>
              <th colSpan={2}>Central Tax</th>
              <th colSpan={2}>State Tax</th>
              <th rowSpan={2} colSpan={2}>
                Total Tax Amount
              </th>
            </tr>
            <tr className="text-center" style={{ fontSize: 13 }}>
              <th>Rate</th>
              <th>Amount</th>
              <th>Rate</th>
              <th>Amount</th>
            </tr>
          </>
        ) : (
          <>
            <tr className="text-center" style={{ fontSize: 13 }}>
              <th style={{ width: "30%" }} rowSpan={2}>
                HSN/SAC Code
              </th>
              <th rowSpan={2}>Taxable Value</th>
              <th colSpan={4}>Integrated Tax</th>
              <th rowSpan={2} colSpan={2}>
                Total Tax Amount
              </th>
            </tr>
            <tr className="text-center" style={{ fontSize: 13 }}>
              <th>Rate</th>
              <th colSpan={3}>Amount</th>
            </tr>
          </>
        )}
        {CreditNotePrintDetails?.gst_details_hsn &&
          CreditNotePrintDetails?.gst_details_hsn?.length > 0 &&
          CreditNotePrintDetails?.gst_details_hsn?.map((gstdetails: any) =>
            CreditNotePrintDetails?.state_name === "GUJARAT" ? (
              <tr className="text-end">
                <td className="text-start">{gstdetails?.hsn_sac_code || ""}</td>
                <td>{formatIndianNumber(gstdetails?.total_taxable_amount)}</td>
                <td>{formatIndianNumber(gstdetails?.gst / 2)} %</td>
                <td className=" ">
                  {gstdetails?.total_taxable_amount &&
                    gstdetails?.gst &&
                    formatIndianNumber(
                      (gstdetails?.total_taxable_amount * gstdetails?.gst) /
                        100 /
                        2
                    )}
                </td>
                <td>{formatIndianNumber(gstdetails?.gst / 2)} %</td>
                <td>
                  {gstdetails?.total_taxable_amount &&
                    gstdetails?.gst &&
                    formatIndianNumber(
                      (gstdetails?.total_taxable_amount * gstdetails?.gst) /
                        100 /
                        2
                    )}
                </td>
                <td colSpan={2}>
                  {gstdetails?.total_taxable_amount &&
                    gstdetails?.gst &&
                    formatIndianNumber(
                      (gstdetails?.total_taxable_amount * gstdetails?.gst) / 100
                    )}
                </td>
              </tr>
            ) : (
              <tr className="text-end">
                <td className="text-start">{gstdetails?.hsn_sac_code || ""}</td>
                <td>{formatIndianNumber(gstdetails?.total_taxable_amount)}</td>
                <td>{formatIndianNumber(gstdetails?.gst)} %</td>
                <td colSpan={3}>
                  {gstdetails?.total_taxable_amount &&
                    gstdetails?.gst &&
                    formatIndianNumber(
                      (gstdetails?.total_taxable_amount * gstdetails?.gst) / 100
                    )}
                </td>
                <td colSpan={2}>
                  {gstdetails?.total_taxable_amount &&
                    gstdetails?.gst &&
                    formatIndianNumber(
                      (gstdetails?.total_taxable_amount * gstdetails?.gst) / 100
                    )}
                </td>
              </tr>
            )
          )}
        {CreditNotePrintDetails?.state_name === "GUJARAT" ? (
          <tr className="text-end fw-600" style={{ fontSize: 13 }}>
            <td></td>
            <td>
              {formatIndianNumber(CreditNotePrintDetails?.taxable_amount)}
            </td>
            <td></td>
            <td>{formatIndianNumber(totalTaxAmount / 2)}</td>
            <td></td>
            <td>{formatIndianNumber(totalTaxAmount / 2)}</td>
            <td colSpan={2}>{formatIndianNumber(totalTaxAmount)}</td>
          </tr>
        ) : (
          <tr className="text-end fw-600" style={{ fontSize: 13 }}>
            <td></td>
            <td>
              {formatIndianNumber(CreditNotePrintDetails?.taxable_amount)}
            </td>
            <td></td>
            <td colSpan={3}>{formatIndianNumber(totalTaxAmount)}</td>
            <td colSpan={2}>{formatIndianNumber(totalTaxAmount)}</td>
          </tr>
        )}
        <tr style={{ borderBottom: "none" }}>
          <td colSpan={7} style={{ textAlign: "end", border: "none" }}>
            <span className="d-block p-0" style={{ fontSize: 8 }}>
              Tax Amount (in words)
            </span>
            <span
              className="d-block fw-600 p-0"
              style={{ fontSize: 12, textTransform: "uppercase" }}
            >
              {convertToWords(+totalTaxAmount?.toFixed(2))}
            </span>
          </td>
        </tr>
      </table>
      <table
        cellSpacing={1}
        cellPadding={2}
        style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}
        className="table table-bordered w-100 mb-0"
      >
        <tr>
          <td
            className="border-bottom-0 p-1"
            style={{ width: "66%", verticalAlign: "baseline" }}
          >
            Company's PAN : <span className="fw-600 p-0">AAACH5100R</span>{" "}
            <br />
            CIN NO : <span className="fw-600 p-0">U15499GJ1994PTC021520</span>
            {/* <br />
            FSSAI No : <span className="fw-600 p-0">10012021000359</span> */}
          </td>
          <td
            className=" p-0"
            style={{
              width: "34%",
              borderTop: 0,
              borderLeft: "1px solid rgba(0, 0, 0, 0.5)",
              borderBottom: "1px solid rgba(0, 0, 0, 0.5)",
            }}
          >
            <div className="p-1">
              <b>Company's Bank Details</b> <br />
              <table className="table bank-details-table mb-0">
                <tr>
                  <td>Bank Name</td>
                  <td className="fw-600">: Kotak Mahindra Bank</td>
                </tr>
                <tr>
                  <td>A/c No.</td>
                  <td className="fw-600">: 2411684374</td>
                </tr>
                <tr>
                  <td>Branch</td>
                  <td className="fw-600">: SATELITE BRANCH</td>
                </tr>
                <tr>
                  <td>IFS Code</td>
                  <td className="fw-600">: KKBK0000810</td>
                </tr>
              </table>
            </div>
          </td>
        </tr>
        <tr className="border-top-0">
          <td className="p-0 border-top-0" style={{ width: "66%" }}>
            <u className="p-1 d-block pb-0 fw-600">Remarks:</u>
            {CreditNotePrintDetails.remarks && (
              <p className="p-0 ps-1 m-0">{CreditNotePrintDetails.remarks}</p>
            )}
            <u className="p-1 d-block pb-0 fw-600">Declaration:</u>
            <div
              className="sales-description"
              dangerouslySetInnerHTML={{
                __html: CreditNotePrintDetails.description,
              }}
            />
          </td>
          <td
            style={{
              minHeight: 80,
              border: "none",
              borderLeft: "1px solid rgba(0, 0, 0, 0.5) ",
              textAlign: "end",
              verticalAlign: "baseline",
            }}
          >
            <p className="p-0 mb-5  fw-600">for Harihar Foods Pvt. Ltd.</p>
            <p className="m-0 p-0">Authorised Signatory</p>
          </td>
        </tr>
      </table>
      <p className="text-center m-0">
        This is Computer Generated Credit Note. Signature Is Not Required.
      </p>
    </div>
  );
};

export default CreditNotePrint;
