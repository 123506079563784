import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IExpenseApproved,
  IExpenseApprovedInitialState,
} from "./expenseApprovedModel";
import {
  getAllSRExpensesForVerify,
  verifySRExpense,
} from "./services/expenseApproved.services";

const initialState: IExpenseApprovedInitialState = {
  loading: false,
  error: "",
  formLoading: false,
  ExpenseDetailsApprovedGUID: "",
  ExpenseApprovedList: [],
  ExpenseApprovedDetails: {
    total_amount: null,
    net_amount: null,
    disallowed_amount: null,
    additional_expense_amount: null,
    verification_remarks: "",
  },
  FilterCustomExpenseApproved: {
    from_city: "",
    to_city: "",
    sr_name: "",
  },
};

const expenseApprovedSlice = createSlice({
  name: "expenseApproved",
  initialState,
  reducers: {
    clearExpenseApprovedDetails: (state) => {
      state.ExpenseApprovedDetails = initialState.ExpenseApprovedDetails;
    },
    setExpenseDetailsApprovedGUID: (state, action) => {
      state.ExpenseDetailsApprovedGUID = action.payload;
    },
    setFilterCustomExpenseApproved: (state, action) => {
      state.FilterCustomExpenseApproved = action.payload;
    },
    clearFilterCustomExpenseApproved: (state) => {
      state.FilterCustomExpenseApproved =
        initialState.FilterCustomExpenseApproved;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllSRExpensesForVerify.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllSRExpensesForVerify.fulfilled,
      (state, action: PayloadAction<IExpenseApproved[]>) => {
        state.loading = false;
        state.ExpenseApprovedList = action.payload || [];
      }
    );
    builder.addCase(getAllSRExpensesForVerify.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ExpenseApprovedList = [];
    });

    builder.addCase(verifySRExpense.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(verifySRExpense.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(verifySRExpense.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});
export const {
  clearExpenseApprovedDetails,
  setExpenseDetailsApprovedGUID,
  clearFilterCustomExpenseApproved,
  setFilterCustomExpenseApproved,
} = expenseApprovedSlice.actions;
export default expenseApprovedSlice.reducer;
