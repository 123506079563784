import * as React from "react";
import {
  MultiSelect,
  // MultiSelectChangeEvent,
  MultiSelectFilterChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { filterBy, FilterDescriptor } from "@progress/kendo-data-query";
import { Error, Hint, Label } from "@progress/kendo-react-labels";
import { CgDanger } from "react-icons/cg";
import { checkAcessRights } from "../../_helper/helper";
import { DRAWER_WIDTH, INSERT_ACCESS } from "../../_contstants/common";
// import { opened } from "../drawer/drawerSlice";
import { useAppDispatch } from "../../app/hooks";
import { IDrawerState, opened } from "../muidrawer/muiDrawerSlice";

const delay = 300;

const FormIncrementalMultiSelectionFiled = (
  fieldRenderProps: FieldRenderProps
) => {
  const dispatch = useAppDispatch();
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    size = "medium",
    disabled,
    hint,
    value,
    options = [],
    onChange,
    isSelectAll = false,
    fetchIncrementalData,
    isAddNew = false,
    drawerName,
    isIndirect,
    dispatcherFunction,
    addNewLink = "",
    textField = "label",
    wrapperStyle,
    astrike = false,
    name,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef<any>(null);
  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";
  const labelId: string = label ? `${id}_label` : "";

  const [data, setData] = React.useState(options);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [filter, setFilter] = React.useState("");
  // eslint-disable-next-line no-undef
  const timeout = React.useRef<NodeJS.Timeout | null>(null);

  const path = drawerName
    ? drawerName.replace(/form/i, "")
    : addNewLink
    ? addNewLink.replace(/\/create/i, "")
    : "";
  const isAddAccess = checkAcessRights(`/${path}`, INSERT_ACCESS);

  React.useEffect(() => {
    let updatedData = options;
    if (isSelectAll) {
      updatedData = [
        { label: "Select All", value: "selectall" },
        ...updatedData,
      ];
    }
    if (isAddNew && isAddAccess) {
      updatedData = [{ label: "Add New", value: "addnew" }, ...updatedData];
    }

    if (dispatcherFunction && isAddAccess) {
      updatedData = [
        { label: "Refresh List", value: "refresh" },
        ...updatedData,
      ];
    }
    setData(updatedData);
  }, [options, isSelectAll, isAddNew, dispatcherFunction]);

  React.useEffect(() => {
    if (filter) {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(() => {
        setData(
          filterData({
            field: "label",
            operator: "contains",
            ignoreCase: true,
            value: filter,
          })
        );
        fetchIncrementalData(filter || "");
        setLoading(false);
      }, delay);
      setLoading(true);
    }
  }, [filter]);

  const filterData = (filter: FilterDescriptor) => {
    let updatedData = options || [];

    if (isSelectAll) {
      updatedData = [
        { label: "Select All", value: "selectall" },
        ...updatedData,
      ];
    }

    if (isAddNew && isAddAccess) {
      updatedData = [{ label: "Add New", value: "addnew" }, ...updatedData];
    }

    if (dispatcherFunction && isAddAccess) {
      updatedData = [
        { label: "Refresh List", value: "refresh" },
        ...updatedData,
      ];
    }
    const data = updatedData?.slice();
    return filterBy(data, filter);
  };

  const handleFilterChange = (event: MultiSelectFilterChangeEvent) => {
    setFilter(event.filter?.value);
  };

  const handleSelect = (event: any) => {
    setFilter(filter);
    const selectedValuesArray = event.target.value;
    const values = selectedValuesArray.map((option: any) => option.value);
    const allValues = options.map((option: any) => option.value);

    if (values && values.includes("selectall")) {
      onChange({ value: allValues });
    } else if (values && values.includes("addnew")) {
      setData(
        filterData({
          field: "label",
          operator: "contains",
          ignoreCase: true,
          value: "",
        })
      );
      if (addNewLink) {
        window.open(`${location.origin}/${addNewLink}`);
      } else {
        const drawerOptions: IDrawerState = {
          open: true,
          anchor: "right",
          name: drawerName,
          drawerWidth: DRAWER_WIDTH,
          isIndirect: isIndirect,
        };
        dispatch(opened(drawerOptions));
      }
    } else if (values && values.includes("refresh") && dispatcherFunction) {
      dispatch(dispatcherFunction());
    } else {
      onChange({ value: values });
    }
  };

  let initialValue = options?.filter((option: any) => {
    return (value || []).includes(option.value);
  });

  const onOpen = () => {
    setOpen(true);
  };
  const onFocus = () => {
    setOpen(true);
  };
  const onBlur = () => {
    setOpen(false);
    setFilter("");
    setData(
      filterData({
        field: "label",
        operator: "contains",
        ignoreCase: true,
        value: "",
      })
    );
    fetchIncrementalData("");
  };

  return (
    <>
      <FieldWrapper style={wrapperStyle}>
        <Label editorId={id} editorValid={valid} editorDisabled={disabled}>
          {label} {astrike && <span style={{ color: "red" }}>*</span>}
        </Label>
        <MultiSelect
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hintId} ${errorId}`}
          ref={editorRef}
          valid={valid}
          id={id}
          disabled={disabled}
          {...others}
          data={data}
          onOpen={onOpen}
          onFocus={onFocus}
          onBlur={onBlur}
          opened={open}
          filter={filter}
          name={name}
          value={Array.isArray(initialValue) ? initialValue : []}
          filterable={true}
          onFilterChange={handleFilterChange}
          loading={loading}
          size={size}
          textField={textField}
          onChange={handleSelect}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error className="d-flex justify-content-end" id={errorId}>
            <span
              className="d-flex align-items-center"
              style={{
                background: "#DF4957",
                color: "white",
                padding: "1px 8px 1px 5px",
                borderRadius: 10,
                gap: 5,
              }}
            >
              <CgDanger /> {validationMessage}
            </span>
          </Error>
        )}
      </FieldWrapper>
    </>
  );
};

export default FormIncrementalMultiSelectionFiled;
