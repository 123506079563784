import React, { useEffect } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import FormDatePicker from "../../../components/formFields/FormDateField";
import moment from "moment";
import { Typography } from "@progress/kendo-react-common";
import ShadowCard from "../../../components/common/ShadowCard";
import {
  clearRoastingReportDetails,
  clearRoastingReportList,
  setRoastingReport,
} from "../reportsSlice";
import { IItem } from "../../Item/itemModel";
import {
  Grid,
  GridColumn,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
} from "@progress/kendo-react-grid";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import { getRoastingReport } from "../services/reports.services";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import AlertBox from "../../../components/common/AlertBox";
import { LoadingPanel } from "../../../components/layout/Loading";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import { Button } from "@progress/kendo-react-buttons";
import Logo from "../../../assets/Images/logo.png";
import {
  checkAcessRights,
  formatIndianNumberForQty,
} from "../../../_helper/helper";
import { EXCEL_EXPORT_ACCESS, PRINT_ACCESS } from "../../../_contstants/common";
import FormIncrementalSearch from "../../../components/formFields/FormIncrementalSearch";
import { getAllItemIncremental } from "../../Item/services/item.services";
import { clearItemList } from "../../Item/itemSlice";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const DatechangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const fromdate = moment()?.startOf("month")?.toDate() || "";
  const todate = moment()?.toDate() || "";
  useEffect(() => {
    formRenderProps.onChange("from_date", {
      value: fromdate,
    });
    formRenderProps.onChange("to_date", {
      value: todate,
    });
  }, []);

  return null;
};

const RoastingReportForm: React.FC = () => {
  const gridRef = React.useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.reports.loading);
  const itemLoading = useAppSelector((state) => state.item.loading);
  const ItemList = useAppSelector((state) => state.item.ItemList);
  const RoastingReportList = useAppSelector(
    (state) => state.reports.RoastingReportList
  );

  useEffect(() => {
    return () => {
      dispatch(clearRoastingReportList());
      dispatch(clearRoastingReportDetails());
      dispatch(clearItemList());
    };
  }, []);

  const handleSubmit = (values: any) => {
    const payload = {
      item_id: values?.item_id ? values?.item_id : null,
      from_date: values?.from_date
        ? moment(values?.from_date).format("YYYY-MM-DD")
        : null,
      to_date: values?.to_date
        ? moment(values?.to_date).format("YYYY-MM-DD")
        : null,
    };
    dispatch(setRoastingReport(payload));
    dispatch(getRoastingReport(payload));
  };
  return (
    <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
      <GridLayoutItem>
        <ShadowCard>
          <Form
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 10 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                  ]}
                >
                  <GridLayoutItem colSpan={4}>
                    <Typography.h4
                      style={{ marginBottom: 0, marginLeft: "3px" }}
                    >
                      Roasting Register
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperStyle={{ width: "100%" }}
                      name="from_date"
                      label="From Date"
                      format="dd/MM/yyyy"
                      component={FormDatePicker}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperStyle={{ width: "100%" }}
                      name="to_date"
                      label="To Date"
                      format="dd/MM/yyyy"
                      minDate={moment(
                        formRenderProps.valueGetter("from_date")
                      ).toDate() || new Date(1900, 0, 1)}
                      component={FormDatePicker}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="w-100"
                      name="item_id"
                      label="Product Name"
                      placeholder="Type here to search item..."
                      component={FormIncrementalSearch}
                      fetchIncrementalData={(search: any) =>
                        dispatch(getAllItemIncremental({ search }))
                      }
                      loading={itemLoading}
                      options={ItemList?.map((item: IItem) => {
                        return {
                          value: item?.id,
                          label: item?.product_name,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <div style={{ marginTop: 35 }}>
                      <ButtonWithLoading
                        label={"View"}
                        type="submit"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </div>
                  </GridLayoutItem>
                </GridLayout>
                <DatechangeWatcher formRenderProps={formRenderProps} />
              </FormElement>
            )}
          />
        </ShadowCard>
        {loading ? (
          <LoadingPanel gridRef={gridRef} />
        ) : (
          RoastingReportList &&
          RoastingReportList.length > 0 && (
            <ShadowCard style={{ marginTop: 10 }}>
              <RoastingGridCommponent />
            </ShadowCard>
          )
        )}
      </GridLayoutItem>
    </GridLayout>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 50 };

const RoastingGridCommponent: React.FC = () => {
  const isPrintAccess = checkAcessRights(location.pathname, PRINT_ACCESS);
  const isExcelAccess = checkAcessRights(
    location.pathname,
    EXCEL_EXPORT_ACCESS
  );

  //   const loading = useAppSelector((state) => state.reports.loading);
  const RoastingReportList = useAppSelector(
    (state) => state.reports.RoastingReportList
  );
  const RoastingReportDetails = useAppSelector(
    (state) => state.reports.RoastingReportDetails
  );

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? RoastingReportList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const handleExportExcel = () => {
    const datetime = moment().format("DD-MM-YYYY HH-mm");
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.table_to_sheet(
      document.querySelector(".table-bordered")
    );
    XLSX.utils.book_append_sheet(wb, ws, "RoastingReport");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(data, `Roasting Report ${datetime}`);
  };

  const printTable = (data: any[]) => {
    let printbuttons = document.querySelector(
      ".printbuttons"
    ) as HTMLElement | null;
    let report_container = document.querySelector(
      ".report-container"
    ) as HTMLElement | null;

    if (printbuttons) printbuttons.style.display = "none";
    if (report_container) report_container.style.padding = "20px 0";

    let tableRows = data.length;
    let maxRows = tableRows;
    let pageCount = Math.ceil(tableRows / maxRows);
    let content = "";

    for (let i = 0; i < pageCount; i++) {
      content += '<div class="print-page">';
      //   content += `<div class="page-header">Page ${i + 1}</div>`;
      content +=
        '<table class="table table-bordered" style="font-size:10px;margin-bottom:30px;">';
      content += "<tr>";
      content += `<td rowSpan="4" style="width:15%" ><img src="${Logo}" width="100%" /></td>`;
      content +=
        '<td colSpan="4" style="text-align: center;font-size: 14px;font-weight: 600;color: gray;">Harihar Foods Pvt Ltd.</td>';
      content += "</tr>";
      content += "<tr>";
      content += "<td>Document Name:</td>";
      content +=
        '<td colSpan="3" style="font-style:bold;">Roasting Register</td>';
      content += "</tr>";
      content += "<tr>";
      content += "<td>Document Number:</td>";
      content += "<td>PRD/F/07:</td>";
      content += "<td>Page</td>";
      content += `<td>${i + 1}</td>`;
      content += "</tr>";
      content += "<tr>";
      content += "<td>Date Of Issue:</td>";
      content += "<td>01/01/2020</td>";
      content += "<td>Version</td>";
      content += "<td>2.00</td>";
      content += "</tr>";

      content += "</table>";

      content += '<table class="table table-bordered" style="font-size:10px;">';
      content += '<thead style="background:lightgray;">';
      content += "<tr>";
      content += "<th>Sr. No.</th>";
      content += '<th style="width:60px">Date</th>';
      content += '<th style="width:200px">Product Name</th>';
      content += "<th>GRN No</th>";
      content += "<th>No Of Workers</th>";
      content += "<th>Starting Time</th>";
      content += "<th>Closing Time</th>";
      content += "<th>Total Qty Kg</th>";
      content += "<th>Received Qty Kg</th>";
      content += "<th>Process loss Kg</th>";
      content += "<th>M/C/No.</th>";
      content += "<th>Material Issue to DEPT.</th>";
      content += '<th style="width:200px">Ready Product Name</th>';
      content += '<th style="width:50px">Name</th>';
      content += "</tr>";
      content += "</thead>";
      content += "<tbody>";

      let startRow = i * maxRows;
      let endRow = Math.min((i + 1) * maxRows, tableRows);

      for (let j = startRow; j < endRow; j++) {
        content += "<tr>";
        content += `<td>${j + 1}</td>`;
        content += `<td>${
          data[j]?.roasting_date
            ? moment(data[j]?.roasting_date, "DD/MM/YYYY").format("DD MMM YYYY")
            : ""
        }</td>`;
        content += `<td>${data[j].product_name}</td>`;
        content += `<td>${data[j].grn_no}</td>`;
        content += `<td style="text-align:center;">${data[j].no_of_workers}</td>`;
        content += `<td>${data[j].start_time}</td>`;
        content += `<td>${data[j].end_time}</td>`;
        content += `<td style="text-align:end;">${formatIndianNumberForQty(
          data[j].total_qty || 0
        )}</td>`;
        content += `<td style="text-align:end;">${formatIndianNumberForQty(
          data[j].received_qty || 0
        )}</td>`;
        content += `<td style="text-align:end;">${formatIndianNumberForQty(
          data[j].process_loss_qty || 0
        )}</td>`;
        content += `<td>${data[j].machine_name}</td>`;
        content += `<td>${data[j].process_name}</td>`;
        content += `<td>${data[j].ready_product_name}</td>`;
        content += `<td></td>`;
        content += "</tr>";
      }

      content += "</tbody>";
      content += "</table>";
      // content +=
      //   '<table class="table table-bordered" style="font-size:11px; width:100%; margin:50px 0; text-align:center">';
      // content += "<tr>";
      // content += `<td style="width:33%;">Prepared By: <br>Mr. GAURANG MEHTA</br></td>`;
      // content += `<td style="width:34%;">Document Status: <br>MASTER COPY</br></td>`;
      // content += `<td style="width:33%;">Approved By: <br>Mr. PARAG SHAH</br> </td>`;
      // content += "</tr>";
      // content += "</table>";
      content += "</div>";
    }

    let printWindow = window.open("", "_blank");
    if (!printWindow) {
      console.error("Failed to open print preview window.");
      return;
    }
    printWindow.document.write(`
      <html>
      <head>
        <title>Print Preview</title>
        <style>
          @page { margin: 1cm; }
          .print-page { page-break-after: always; }
          .table-bordered { width: 100%; border-collapse: collapse; }
          .table-bordered th, .table-bordered td { border: 1px solid black; padding: 8px; }
          body { font-family: Arial, sans-serif; }
        </style>
      </head>
      <body>
        ${content}
        <script>
          window.onload = function() {
            window.print();
          };
        </script>
      </body>
      </html>
    `);
    printWindow.document.close();

    if (printbuttons) printbuttons.style.display = "block";
    if (report_container) report_container.style.padding = "20px 10px";
  };

  return (
    <>
      {RoastingReportList && RoastingReportList.length ? (
        <GridLayout
          //   style={{ marginRight: 30 }}
          gap={{ rows: 10, cols: 10 }}
          cols={[{ width: "100%" }]}
        >
          <GridLayoutItem className="d-flex justify-content-between align-items-center">
            <Typography.h4 className="m-0">
              Roasting List{" "}
              {(RoastingReportDetails?.from_date ||
                RoastingReportDetails?.to_date) && (
                <span style={{ fontSize: 13 }}>
                  {"("}
                  {RoastingReportDetails?.from_date
                    ? moment(
                        RoastingReportDetails?.from_date,
                        "YYYY-MM-DD"
                      ).format("DD/MM/YYYY")
                    : ""}
                  {RoastingReportDetails?.from_date &&
                    RoastingReportDetails?.to_date &&
                    " - "}
                  {RoastingReportDetails?.to_date
                    ? moment(
                        RoastingReportDetails?.to_date,
                        "YYYY-MM-DD"
                      ).format("DD/MM/YYYY")
                    : ""}
                  {")"}
                </span>
              )}
            </Typography.h4>
            <div>
              {isExcelAccess && (
                <Button
                  style={{ marginRight: 5 }}
                  type="button"
                  fillMode={"solid"}
                  themeColor={"primary"}
                  onClick={handleExportExcel}
                >
                  Excel Export
                </Button>
              )}
              {isPrintAccess && (
                <Button
                  type="button"
                  fillMode={"solid"}
                  themeColor={"primary"}
                  onClick={() => printTable(RoastingReportList)}
                >
                  Print
                </Button>
              )}
            </div>
          </GridLayoutItem>
          <GridLayoutItem>
            <Grid
              className="table-bordered responsive-table"
              style={{ maxHeight: "calc(100vh - 152px)" }}
              filterable={true}
              filter={filter}
              data={
                filter
                  ? filterBy(RoastingReportList, filter).slice(
                      page.skip,
                      page.take + page.skip
                    )
                  : RoastingReportList.slice(page.skip, page.take + page.skip)
              }
              onFilterChange={handleFilterChange}
              skip={page.skip}
              take={page.take}
              total={RoastingReportList.length}
              pageable={{
                buttonCount: 5,
                pageSizes: [10, 25, 50, 100, 500, "All"],
                pageSizeValue: pageSizeValue,
                type: "input",
              }}
              onPageChange={pageChange}
              cells={{
                headerCell: HeaderCustomCell,
                data: MyDataCustomCell,
              }}
            >
              <GridColumn
                field="UniqueNo"
                title="Sr. No."
                width={80}
                filterable={false}
              />
              <GridColumn
                field="roasting_date"
                title="Date"
                width={150}
                cell={(props: any) => (
                  <td>
                    {props.dataItem?.roasting_date
                      ? moment(
                          props.dataItem?.roasting_date,
                          "DD/MM/YYYY"
                        ).format("DD MMM YYYY")
                      : ""}
                  </td>
                )}
              />
              <GridColumn
                field="product_name"
                title="Product Name"
                width={250}
              />
              <GridColumn field="grn_no" title="GRN No" width={150} />
              <GridColumn
                field="no_of_workers"
                title="No Of Workers"
                width={150}
                cell={(props: any) => (
                  <td style={{ textAlign: "end" }}>
                    {props.dataItem?.no_of_workers}
                  </td>
                )}
              />
              <GridColumn
                field="start_time"
                title="Starting Time"
                width={150}
              />
              <GridColumn field="end_time" title="Closing Time" width={150} />
              <GridColumn
                field="total_qty"
                title="Total Qty"
                width={150}
                cell={(props: any) => (
                  <td style={{ textAlign: "end" }}>
                    {formatIndianNumberForQty(+props.dataItem.total_qty)}
                  </td>
                )}
              />
              <GridColumn
                field="received_qty"
                title="Received Qty"
                width={150}
                cell={(props: any) => (
                  <td style={{ textAlign: "end" }}>
                    {formatIndianNumberForQty(+props.dataItem.received_qty)}
                  </td>
                )}
              />
              <GridColumn
                field="process_loss_qty"
                title="Roasting Loss Qty"
                width={160}
                cell={(props: any) => (
                  <td style={{ textAlign: "end" }}>
                    {formatIndianNumberForQty(+props.dataItem.process_loss_qty)}
                  </td>
                )}
              />
              <GridColumn
                field="loss_percentage"
                title="Roasting Loss Qty (%)"
                width={190}
                cell={(props) => (
                  <td>{props.dataItem?.loss_percentage || 0} %</td>
                )}
              />
              <GridColumn field="machine_name" title="Machine No" width={250} />
              <GridColumn
                field="process_name"
                title="Material Issued to Dept."
                width={200}
              />
              <GridColumn
                field="ready_product_name"
                title="Ready Product Name"
                width={300}
              />
              <GridColumn
                field="checker_name"
                title="Supervisor By"
                width={200}
              />
            </Grid>
          </GridLayoutItem>
        </GridLayout>
      ) : (
        <AlertBox />
      )}
    </>
  );
};
export default RoastingReportForm;
