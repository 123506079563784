import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  convertToWords,
  formatIndianNumber,
  formatIndianNumberForQty,
} from "../../_helper/helper";
import { getSalesPrint } from "./services/sales.services";

const SalesPrintcopy = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const params = useParams();
  const queryParam = new URLSearchParams(location.search);
  const isPrint = queryParam.get("print");
  const sale_guid = params?.sale_guid;
  const SalesPrintDetail = useAppSelector(
    (state) => state.sales.SalesPrintDetail
  );

  // const [tableHeight, setTableHeight] = React.useState<any>(null);

  useEffect(() => {
    if (sale_guid) {
      const handleOrderPrint = async () => {
        const res = await dispatch(getSalesPrint(sale_guid));
        if (res.meta.requestStatus === "fulfilled") {
          if (isPrint === "1") {
            window.print();
            // const table1: any = document.getElementById("main-table");
            // const table2: any = document.getElementById("secondary-table");

            // if (table1 && table1.offsetHeight > 421) {
            //   table2.classList.add("start-on-new-page");
            // }
          }
        }
      };
      handleOrderPrint();
    }
  }, [sale_guid]);

  // const table1: any = document.getElementById("main-table");
  // const table2: any = document.getElementById("secondary-table");

  // useEffect(() => {
  //   if (table1?.offsetHeight) {
  //     setTableHeight(table1?.offsetHeight);
  //   }
  //   if (table1 && table1.offsetHeight > 421) {
  //     table2.classList.add("start-on-new-page");
  //   }
  // }, [table1 && table1?.offsetHeight]);

  let totalAmount = 0;
  let totalTaxAmount = 0;

  SalesPrintDetail?.sales_items &&
    SalesPrintDetail?.sales_items.length > 0 &&
    SalesPrintDetail?.sales_items?.map(
      (item: any) => (totalAmount += item?.amount)
    );

  SalesPrintDetail?.gst_details_hsn &&
    SalesPrintDetail?.gst_details_hsn?.length > 0 &&
    SalesPrintDetail?.gst_details_hsn?.map((gstamount: any) => {
      const totaltax =
        gstamount?.total_taxable_amount &&
        gstamount?.gst &&
        (gstamount?.total_taxable_amount * gstamount?.gst) / 100;
      totalTaxAmount += totaltax || 0;
    });

  return (
    <div className="p-4 sales-print-page">
      <p className="fw-600 text-center mb-1">
        <i>(ORIGINAL FOR RECEPIENT)</i>
      </p>
      <h6 className="text-center fw-600">
        {SalesPrintDetail?.voucher_type_id === 2
          ? "Bill Of Supply"
          : "Tax Invoice"}
      </h6>
      {/* <p className="text-center">
        <b>Harihar Foods Pvt. Ltd.</b>
        <br />
        519/1, Nr Ramol Police Chowky
        <br />
        Phase IV, G.I.D.C. Vatva, Ahmedabad 382445
        <br />
        Tel (Off) 079-25841773/25840855 <br />
        FSSAI No: 10012021000359 <br />
        GSTIN/UIN: 24AAACH5100R1ZN <br />
        State Name: Gujarat, Code: 24 <br />
        E-Mail: marketing@shreeganeshmasala.com
      </p> */}
      <table
        className="table table-bordered w-100 sales-printcopy-table m-0"
        style={{ width: "100%" }}
      >
        <thead style={{ width: "100%" }}>
          <tr style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}>
            <td
              rowSpan={3}
              colSpan={3}
              className="p-0"
              style={{ width: "40%" }}
            >
              <p className="m-0 p-1">
                <b style={{ fontSize: 13 }}>Harihar Foods Pvt. Ltd.</b>
                <br />
                519/1, Nr Ramol Police Chowky
                <br />
                Phase IV, G.I.D.C. Vatva, Ahmedabad 382445
                <br />
                Tel (Off) 079-25841773/25840855 <br />
                FSSAI No: 10012021000359 <br />
                GSTIN/UIN: 24AAACH5100R1ZN <br />
                State Name: Gujarat, Code: 24 <br />
                E-Mail: marketing@shreeganeshmasala.com
              </p>
            </td>
            <td colSpan={3} style={{ height: 40, width: "20%" }}>
              Invoice No.
              <br />
              <span className="fw-600 p-0">
                {SalesPrintDetail?.invoice_no_string}
              </span>
            </td>
            <td colSpan={3} style={{ height: 40, width: "20%" }}>
              Dated
              <br />
              <span className="fw-600 p-0">
                {SalesPrintDetail?.invoice_date}
              </span>
            </td>
            <td colSpan={3} style={{ height: 40, width: "20%" }}>
              Sales Order No.
              <br />
              <span className="fw-600 p-0">
                {SalesPrintDetail?.sales_order_no}
              </span>
            </td>
          </tr>
          <tr style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}>
            <td colSpan={3} style={{ height: 40, width: "20%" }}>
              Destination
              <br />
              <span className="fw-600 p-0">{SalesPrintDetail?.city_name}</span>
            </td>
            <td colSpan={3} style={{ height: 40, width: "20%" }}>
              Challan No.
              <br />
              <span className="fw-600 p-0">
                {SalesPrintDetail?.challan_no_string}
              </span>
            </td>
            <td colSpan={3} style={{ height: 40, width: "20%" }}>
              Challan Date
              <br />
              <span className="fw-600 p-0">
                {" "}
                {SalesPrintDetail?.sales_challan_date}
              </span>
            </td>
          </tr>
          <tr style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}>
            <td colSpan={3} style={{ height: 40, width: "20%" }}>
              Dispatched through
              <br />
              <span className="fw-600 p-0">
                {SalesPrintDetail?.transport_name}
              </span>
            </td>
            <td colSpan={3} style={{ height: 40, width: "20%" }}>
              Vehicle Number
              <br />
              <span className="fw-600 p-0">{SalesPrintDetail?.vehicle_no}</span>
            </td>
            <td colSpan={3} style={{ height: 40, width: "20%" }}>
              Scheme
              <br />
              <span className="fw-600 p-0">
                {SalesPrintDetail?.display_name}
              </span>
            </td>
          </tr>
          <tr>
            <td colSpan={12} className="p-0" style={{ width: "100%" }}>
              <div style={{ display: "flex", padding: 0 }}>
                <div
                  className="p-1"
                  style={{
                    flex: "1",
                    borderRight: "1px solid rgba(0, 0, 0, 0.5)",
                  }}
                >
                  <p className="fw-600 mb-1">Billing Address:-</p>
                  <p className="m-0 fw-bold" style={{ fontSize: 13 }}>
                    {SalesPrintDetail?.account_name}
                  </p>
                  <div className="pt-0" style={{ minHeight: 80 }}>
                    {SalesPrintDetail?.address1}{" "}
                    {SalesPrintDetail?.address1 && <br />}
                    Ph.: {SalesPrintDetail?.mobile_number}
                    {SalesPrintDetail?.mobile_number &&
                      SalesPrintDetail?.alternet_mobile_no &&
                      ", "}
                    {SalesPrintDetail?.alternet_mobile_no} <br />
                    GSTIN/UIN: {SalesPrintDetail?.gst_no}
                    {", "}&nbsp;&nbsp; PAN No.: {SalesPrintDetail?.pan_no}{" "}
                    <br />
                    State: {SalesPrintDetail?.state_name} Code:{" "}
                    {SalesPrintDetail?.gst_state_code}
                  </div>
                </div>
                <div className="p-1" style={{ minHeight: 80, flex: 1 }}>
                  <p className="fw-600 mb-1">Shipping Address:-</p>
                  {SalesPrintDetail?.address2}{" "}
                  {SalesPrintDetail?.address2 && <br />}
                  {SalesPrintDetail?.city_name2}{" "}
                  {SalesPrintDetail?.city_name2 &&
                    SalesPrintDetail?.pincode2 &&
                    " - "}
                  {SalesPrintDetail?.pincode2}{" "}
                  {SalesPrintDetail?.pincode2 && <br />}
                  State: {SalesPrintDetail?.state_name2} Code:{" "}
                  {SalesPrintDetail?.gst_state_code}
                </div>
              </div>
            </td>
          </tr>
          <tr className="text-center fw-600" style={{ fontSize: 12 }}>
            <th rowSpan={2} style={{ width: "2% " }}>
              Sr No.
            </th>
            <th rowSpan={2} style={{ width: "40%" }}>
              Description Of Goods
            </th>
            <th rowSpan={2} style={{ width: "5%" }}>
              Batch No.
            </th>
            <th rowSpan={2} style={{ width: "8%" }}>
              HSN/SAC
            </th>
            <th rowSpan={2} style={{ width: "4%" }}>
              GST Rate
            </th>
            <th rowSpan={2} style={{ width: "6%" }}>
              MRP
            </th>
            <th colSpan={2} style={{ width: "10%" }}>
              Quantity
            </th>
            <th rowSpan={2} style={{ width: "5%" }}>
              Per
            </th>
            <th rowSpan={2} style={{ width: "7%" }}>
              Rate
            </th>
            <th rowSpan={2} style={{ width: "5%" }}>
              Vatav (%)
            </th>
            <th rowSpan={2} style={{ width: "8%" }}>
              Amount
            </th>
          </tr>
          <tr className="text-center fw-600" style={{ fontSize: 12 }}>
            <th>Free</th>
            <th>Billed</th>
          </tr>
        </thead>
        <tbody>
          {SalesPrintDetail?.sales_items &&
            SalesPrintDetail?.sales_items.length > 0 &&
            SalesPrintDetail?.sales_items?.map((item: any, index: number) => (
              <>
                <tr>
                  <td className="text-center">{index + 1}</td>
                  <td>
                    {item?.product_name}
                    {item?.item_description && <br />}{" "}
                    <span
                      style={{
                        fontSize: 8,
                        display: "inline-table",
                        marginLeft: 10,
                      }}
                    >
                      {item?.item_description}
                    </span>
                  </td>
                  <td className="text-center">{item?.lot_no}</td>
                  <td className="text-center">{item?.hsn_sac_code}</td>
                  <td className="text-end">
                    {item?.billed_quantity && item?.gst ? `${item?.gst} %` : ""}
                  </td>
                  <td className="text-end">
                    {item?.billed_quantity && item?.mrp ? item?.mrp || 0 : ""}
                  </td>
                  <td className="text-end">
                    {formatIndianNumberForQty(item?.free_quantity || 0)}
                  </td>
                  <td className="text-end">
                    {formatIndianNumberForQty(item?.billed_quantity || 0)}
                  </td>
                  <td>{item?.unit_name}</td>
                  <td className=" text-end">
                    {item?.billed_quantity
                      ? formatIndianNumber(item?.rate)
                      : ""}
                  </td>
                  <td className=" text-end">
                    {item?.billed_quantity && item?.vatav_per
                      ? `${item?.vatav_per} %`
                      : ""}
                  </td>
                  <td className=" text-end">
                    {formatIndianNumber(item?.amount)}
                  </td>
                </tr>
              </>
            ))}
          <tr>
            <td colSpan={11}></td>
            <td className="text-end fw-600" style={{ fontSize: 12 }}>
              {formatIndianNumber(totalAmount)}
            </td>
          </tr>

          {SalesPrintDetail?.sales_duty_and_taxes &&
            SalesPrintDetail?.sales_duty_and_taxes?.length > 0 &&
            SalesPrintDetail?.sales_duty_and_taxes?.map(
              (gstdetails: any) => (
                // SalesPrintDetail?.state_name === "GUJARAT" ? (
                //   <>
                //     {gstdetails?.gst_per > 0 && (
                //       <tr>
                //         <td className=" text-end" colSpan={9}>
                //           CGST {gstdetails?.gst_per / 2}%
                //         </td>
                //         <td className=" text-end">
                //           {(gstdetails?.gst_per / 2)?.toFixed(2)}
                //         </td>
                //         <td>%</td>
                //         <td className=" text-end">
                //           {formatIndianNumber(gstdetails?.gst_amount)}
                //           {/* {gstdetails?.gst_amount &&
                //             gstdetails?.gst_per &&
                //             formatIndianNumber(
                //               (gstdetails?.total_taxable_amount * gstdetails?.gst_per) /
                //                 100 /
                //                 2
                //             )} */}
                //         </td>
                //       </tr>
                //     )}
                //     {gstdetails?.gst_per > 0 && (
                //       <tr>
                //         <td className=" text-end" colSpan={9}>
                //           SGST {gstdetails?.gst_per / 2}%
                //         </td>
                //         <td className=" text-end">
                //           {(gstdetails?.gst_per / 2)?.toFixed(2)}
                //         </td>
                //         <td>%</td>
                //         <td className=" text-end">
                //           {formatIndianNumber(gstdetails?.gst_amount)}
                //         </td>
                //       </tr>
                //     )}
                //   </>
                // ) : (

                <tr>
                  <td className=" text-end" colSpan={9}>
                    {gstdetails?.ledger_name}
                  </td>
                  <td className=" text-end">
                    {gstdetails?.gst_per?.toFixed(2)}
                  </td>
                  <td>%</td>
                  <td className=" text-end">
                    {formatIndianNumber(gstdetails?.gst_amount)}
                  </td>
                </tr>
              )
              // )
            )}
          <tr style={{ fontSize: 12 }}>
            <td colSpan={11} className=" text-end fw-600">
              {" "}
              Round Off:
            </td>
            <td className="text-end fw-600">
              {formatIndianNumber(SalesPrintDetail?.round_off)}
            </td>
          </tr>
          <tr style={{ fontSize: 12 }}>
            <td colSpan={11} className=" text-end fw-600">
              {" "}
              Total Invoice Amount:
            </td>
            <td className="text-end fw-600">
              {formatIndianNumber(Math.round(+SalesPrintDetail?.total_amount))}
            </td>
          </tr>
          <tr className="b-none">
            <td colSpan={11} className="b-none">
              <span className="d-block p-0" style={{ fontSize: 8 }}>
                Amount Chargeable (in words)
              </span>
              <span
                className="d-block fw-600 p-0"
                style={{ fontSize: 12, textTransform: "uppercase" }}
              >
                {convertToWords(Math.round(+SalesPrintDetail?.total_amount))}
              </span>
            </td>
            <td className="text-end b-none">E & O.E</td>
          </tr>
          {SalesPrintDetail?.state_name === "GUJARAT" ? (
            <>
              <tr className="text-center fw-600" style={{ fontSize: 13 }}>
                <th colSpan={4} rowSpan={2}>
                  HSN/SAC Code
                </th>
                <th colSpan={2} rowSpan={2}>
                  Taxable Value
                </th>
                <th colSpan={2}>Central Tax</th>
                <th colSpan={2}>State Tax</th>
                <th colSpan={2} rowSpan={2}>
                  Total Tax Amount
                </th>
              </tr>
              <tr className="text-center" style={{ fontSize: 13 }}>
                <th>Rate</th>
                <th>Amount</th>
                <th>Rate</th>
                <th>Amount</th>
              </tr>
            </>
          ) : (
            <>
              <tr className="text-center" style={{ fontSize: 13 }}>
                <th colSpan={2} rowSpan={2}>
                  HSN/SAC Code
                </th>
                <th colSpan={3} rowSpan={2}>
                  Taxable Value
                </th>
                <th colSpan={4}>Integrated Tax</th>
                <th colSpan={3} rowSpan={2}>
                  Total Tax Amount
                </th>
              </tr>
              <tr className="text-center" style={{ fontSize: 13 }}>
                <th colSpan={2}>Rate</th>
                <th colSpan={2}>Amount</th>
              </tr>
            </>
          )}
          {SalesPrintDetail?.gst_details_hsn &&
            SalesPrintDetail?.gst_details_hsn?.length > 0 &&
            SalesPrintDetail?.gst_details_hsn?.map((gstdetails: any) =>
              SalesPrintDetail?.state_name === "GUJARAT" ? (
                <tr className="text-end">
                  <td className="text-start" colSpan={4}>
                    {gstdetails?.hsn_sac_code || ""}
                  </td>
                  <td colSpan={2}>
                    {formatIndianNumber(gstdetails?.total_taxable_amount)}
                  </td>
                  <td>{formatIndianNumber(gstdetails?.gst / 2)} %</td>
                  <td className=" ">
                    {gstdetails?.total_taxable_amount &&
                      gstdetails?.gst &&
                      formatIndianNumber(
                        (gstdetails?.total_taxable_amount * gstdetails?.gst) /
                          100 /
                          2
                      )}
                  </td>
                  <td>{formatIndianNumber(gstdetails?.gst / 2)} %</td>
                  <td>
                    {gstdetails?.total_taxable_amount &&
                      gstdetails?.gst &&
                      formatIndianNumber(
                        (gstdetails?.total_taxable_amount * gstdetails?.gst) /
                          100 /
                          2
                      )}
                  </td>
                  <td colSpan={2}>
                    {gstdetails?.total_taxable_amount &&
                      gstdetails?.gst &&
                      formatIndianNumber(
                        (gstdetails?.total_taxable_amount * gstdetails?.gst) /
                          100
                      )}
                  </td>
                </tr>
              ) : (
                <tr className="text-end">
                  <td className="text-start" colSpan={2}>
                    {gstdetails?.hsn_sac_code || ""}
                  </td>
                  <td colSpan={3}>
                    {formatIndianNumber(gstdetails?.total_taxable_amount)}
                  </td>
                  <td colSpan={2}>{formatIndianNumber(gstdetails?.gst)} %</td>
                  <td colSpan={2}>
                    {gstdetails?.total_taxable_amount &&
                      gstdetails?.gst &&
                      formatIndianNumber(
                        (gstdetails?.total_taxable_amount * gstdetails?.gst) /
                          100
                      )}
                  </td>
                  <td colSpan={3}>
                    {gstdetails?.total_taxable_amount &&
                      gstdetails?.gst &&
                      formatIndianNumber(
                        (gstdetails?.total_taxable_amount * gstdetails?.gst) /
                          100
                      )}
                  </td>
                </tr>
              )
            )}
          <tr style={{ borderBottom: "none" }}>
            <td colSpan={12} style={{ textAlign: "end", border: "none" }}>
              <span className="d-block p-0" style={{ fontSize: 8 }}>
                Tax Amount (in words)
              </span>
              <span
                className="d-block fw-600 p-0"
                style={{ fontSize: 12, textTransform: "uppercase" }}
              >
                {convertToWords(+totalTaxAmount?.toFixed(2))}
              </span>
            </td>
          </tr>
          <tr>
            <td
              className="border-bottom-0 p-1"
              colSpan={6}
              rowSpan={3}
              style={{ width: "60%" }}
            >
              Company's PAN : <span className="fw-600 p-0">AAACH5100R</span>{" "}
              <br />
              CIN NO : <span className="fw-600 p-0">
                U15499GJ1994PTC021520
              </span>{" "}
              <br />
              <br />
              <br />
              <u className="p-1 d-block pb-0 fw-600">Remarks:</u>
              {SalesPrintDetail.remarks && (
                <p className="p-0 ps-1 m-0">{SalesPrintDetail.remarks}</p>
              )}
              <u className="p-1 d-block pb-0 fw-600">Declaration:</u>
              <div
                className="sales-description"
                dangerouslySetInnerHTML={{
                  __html: SalesPrintDetail.description,
                }}
              />
            </td>
            <td colSpan={2} className=" border-top-0 border-start-0">
              <span style={{ boxSizing: "border-box" }} className="fw-600 p-0">
                Bags :
              </span>{" "}
              {SalesPrintDetail?.bag || 0}
            </td>
            <td colSpan={2} className="border-top-0">
              <span style={{ boxSizing: "border-box" }} className="fw-600 p-0">
                Cartoon :
              </span>{" "}
              {SalesPrintDetail?.cartoon || 0}
            </td>
            <td colSpan={2} className="border-top-0 border-end-0">
              <span style={{ boxSizing: "border-box" }} className="fw-600 p-0">
                Total :
              </span>{" "}
              {+SalesPrintDetail?.bag + +SalesPrintDetail?.cartoon}
            </td>
            {/* <td
              colSpan={2}
              className="p-0 border-bottom-0"
              style={{
                width: "34%",
                verticalAlign: "baseline",
                borderLeft: "1px solid rgba(0, 0, 0, 0.5)",
              }}
            >
              <div className="p-0">
                <table className="table w-100 sales-printcopy-table border-0  m-0">
                  <tr className="border-0"></tr>
                  <tr className="border-end-0 border-bottom-0">
                </tr>
                <tr
                  className="border-end-0 border-bottom-0"
                  style={{ background: "whitesmoke" }}
                >
                </tr>
                </table>
              </div>
            </td> */}
          </tr>
          <tr className="border-top-0">
            <td
              colSpan={6}
              className=" p-0"
              style={{
                width: "34%",
                borderTop: 0,
                borderLeft: "1px solid rgba(0, 0, 0, 0.5)",
                borderBottom: "1px solid rgba(0, 0, 0, 0.5)",
              }}
            >
              <div className="p-1">
                <b>Company's Bank Details</b> <br />
                <table className="table bank-details-table mb-0">
                  <tr>
                    <td>Bank Name</td>
                    <td className="fw-600">: Kotak Mahindra Bank</td>
                  </tr>
                  <tr>
                    <td>A/c No.</td>
                    <td className="fw-600">: 2411684374</td>
                  </tr>
                  <tr>
                    <td>Branch</td>
                    <td className="fw-600">: SATELITE BRANCH</td>
                  </tr>
                  <tr>
                    <td>IFS Code</td>
                    <td className="fw-600">: KKBK0000810</td>
                  </tr>
                </table>
              </div>
            </td>
          </tr>
          <tr>
            <td
              colSpan={6}
              style={{
                height: 80,
                border: "none",
                borderLeft: "1px solid rgba(0, 0, 0, 0.5) ",
                textAlign: "end",
              }}
            >
              <p className="p-0 mb-5 fw-600">for Harihar Foods Pvt. Ltd.</p>
              <p className="m-0 p-0">Authorised Signatory</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p className="text-center m-0">
        This is Computer Generated Invoice. Signature Is Not Required.
      </p>
    </div>
  );
};

export default SalesPrintcopy;
