import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useCallback, useEffect, useRef } from "react";
import ShadowCard from "../../components/common/ShadowCard";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import FormTextField from "../../components/formFields/FormTextField";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Button } from "@progress/kendo-react-buttons";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { closed } from "../../components/drawer/drawerSlice";
import { LoadingPanel } from "../../components/layout/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import FormDatePicker from "../../components/formFields/FormDateField";
import moment from "moment";
import FormTimePicker from "../../components/formFields/FormTimeField";
import {
  createProductMiliingNew,
  generateLotNoForMilling,
  getProductMiliingByIDNew,
  updateProductMiliingNew,
} from "./services/productmilling.services";
import { clearProductMillingDetails } from "./productmillingSlice";
import { getAllItemIncremental } from "../Item/services/item.services";
import { findAllActiveProcess } from "../process/services/process.services";
import { findAllActiveMachine } from "../machine/services/machine.services";
import { getAllActiveUsers } from "../user/services/user.services";
import { IUser } from "../user/userModel";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import CreateMachine from "../machine/CreateMachine";
import AppDrawer from "../../components/muidrawer/Drawer";
import { clearItemList } from "../Item/itemSlice";
import {
  formatIndianNumberForQty,
  getLocalStorageItem,
} from "../../_helper/helper";
import { FINANCIAL_YEAR } from "../../_contstants/common";
import FormTextArea from "../../components/formFields/FormTextArea";
import PMItemDetailsArray from "./PMItemDetailsArray";
import { getCurrentStock } from "../stocktransfer/services/stockTransfer.services";
import { getGRNByItemID } from "../inwardreturn/services/inwardReturn.services";
import { ErrorToast } from "../../components/toast/Toasts";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const QtychangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const total_qty = formRenderProps.valueGetter("total_qty");
  const received_qty = formRenderProps.valueGetter("received_qty");
  const production_loss_qty = formRenderProps.valueGetter(
    "production_loss_qty"
  );

  useEffect(() => {
    formRenderProps.onChange("production_loss_qty", {
      value: +total_qty - +received_qty,
    });
  }, [total_qty, received_qty]);

  if (production_loss_qty < 0) {
    formRenderProps.onChange("production_loss_qty", {
      value: 0,
    });
  }

  useEffect(() => {
    const lossPercentage = (production_loss_qty / total_qty) * 100;
    formRenderProps.onChange("milling_loss_percentage", {
      value: `${lossPercentage ? lossPercentage?.toFixed(2) : 0} %`,
    });
  }, [total_qty, production_loss_qty]);

  return null;
};

const LotNoChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const product_milling_guid = location.state?.product_milling_guid;
  const isLotNoRef = useRef(false);

  useEffect(() => {
    const fetchlotNo = async () => {
      const response = await dispatch(
        generateLotNoForMilling({ financial_year: FINANCIAL_YEAR })
      );
      if (response.meta.requestStatus === "fulfilled") {
        formRenderProps.onChange("lot_no", {
          value: response.payload?.lot_no || "",
        });
      }
    };
    if (!isLotNoRef.current) {
      if (!product_milling_guid) {
        fetchlotNo();
      }
      isLotNoRef.current = true;
    } else {
      isLotNoRef.current = true;
    }
  }, []);

  return null;
};

const TakenQtyWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const product_milling_items = formRenderProps.valueGetter(
    "product_milling_items"
  );
  let totalQty = 0;
  let totalLossQty = 0;

  useEffect(() => {
    if (product_milling_items && product_milling_items.length > 0) {
      product_milling_items?.map((item: any, index: number) => {
        totalQty += +item?.taken_qty || 0;
        totalLossQty += +item?.wastage_qty || 0;

        const TakenQTY = item.taken_qty;
        const WastageQTY = item.wastage_qty;
        if (WastageQTY > TakenQTY) {
          formRenderProps.onChange(
            `product_milling_items.${index}.wastage_qty`,
            {
              value: TakenQTY,
            }
          );
        }
        const lossPercentage = (WastageQTY / TakenQTY) * 100;
        formRenderProps.onChange(
          `product_milling_items.${index}.loss_percentage`,
          {
            value: `${lossPercentage ? lossPercentage?.toFixed(2) : 0} %`,
          }
        );
      });
    }

    formRenderProps.onChange("total_qty", {
      value: totalQty,
    });
    formRenderProps.onChange("total_loss_qty", {
      value: totalLossQty,
    });
  }, [
    product_milling_items?.map((item: any) => item?.taken_qty).join("-"),
    product_milling_items?.map((item: any) => item?.wastage_qty).join("-"),
  ]);

  return null;
};

const InnerItemChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const product_milling_items = formRenderProps.valueGetter(
    "product_milling_items"
  );
  const isFetchGrnOption = React.useRef(true);
  const product_milling_guid = location.state?.product_milling_guid;

  const fetchGRNOptions = useCallback(
    async (index: number, item: any) => {
      if (item?.item_id && item?.process_id) {
        const grnPayload = {
          item_id: item.item_id,
          process_id: item?.process_id,
          warehouse_id: 1,
        };
        const response = await dispatch(getGRNByItemID(grnPayload));
        const result =
          response.payload
            ?.filter((grn: any) => grn.item_id === item.item_id)
            .map((grnno: any) => ({
              value: `${grnno?.grn_no}`,
              label: `${grnno?.grn_no} - ${formatIndianNumberForQty(
                grnno?.current_stock || 0
              )}`,
            })) || [];
        formRenderProps.onChange(
          `product_milling_items.${index}.grnNoOptions`,
          {
            value: result,
          }
        );
      } else {
        formRenderProps.onChange(
          `product_milling_items.${index}.grnNoOptions`,
          {
            value: [],
          }
        );
        formRenderProps.onChange(`product_milling_items.${index}.grn_no`, {
          value: null,
        });
      }
    },
    [dispatch, formRenderProps]
  );

  const fetchCurrentStock = useCallback(
    async (index: number, item: any) => {
      if (item?.item_id && item?.grn_no && item?.process_id) {
        const payload = {
          item_id: +item.item_id,
          grn_no: item.grn_no,
          process_id: +item?.process_id,
          warehouse_id: 1,
        };
        const response = await dispatch(getCurrentStock(payload));
        formRenderProps.onChange(`product_milling_items.${index}.current_qty`, {
          value: response.payload?.actual_stock || 0,
        });
        formRenderProps.onChange(`product_milling_items.${index}.dagina`, {
          value:
            `${response.payload?.dagina} - ${response.payload?.inward_date}` ||
            "",
        });
      }
    },
    [dispatch, formRenderProps]
  );

  useEffect(() => {
    if (isFetchGrnOption.current && product_milling_guid) {
      product_milling_items.forEach((item: any, index: number) => {
        fetchGRNOptions(index, item);
        fetchCurrentStock(index, item);
      });
      isFetchGrnOption.current = false;
    }
  }, [product_milling_guid]);

  return null;
};

const UserChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const location = useLocation();
  const UserID = getLocalStorageItem("UserID");
  const product_milling_guid = location.state?.product_milling_guid;

  useEffect(() => {
    if (!product_milling_guid) {
      formRenderProps.onChange("checker_by", {
        value: UserID ? +UserID : null,
      });
    }
  }, [UserID]);

  return null;
};

const CreateProductMilling: React.FC = () => {
  const gridRef = useRef<any>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const ProductMillingDetail = useAppSelector(
    (state) => state.productmilling.ProductMillingDetail
  );
  const loading = useAppSelector((state) => state.productmilling.loading);
  const MachineList = useAppSelector((state) => state.machine.MachineList);
  const UserList = useAppSelector((state) => state.user.UserList);
  const product_milling_guid = location.state?.product_milling_guid;
  const isDrawerOpen = useAppSelector((state) => state.muidrawer.open);
  const drawerName = useAppSelector((state) => state.muidrawer.name);

  const [formKey, setFormKey] = React.useState(1);

  useEffect(() => {
    if (product_milling_guid) {
      const payload = {
        product_milling_guid: product_milling_guid,
      };
      dispatch(getProductMiliingByIDNew(payload));
    }
  }, [product_milling_guid]);

  useEffect(() => {
    dispatch(getAllActiveUsers());
    dispatch(findAllActiveProcess());
    dispatch(findAllActiveMachine());
    return () => {
      dispatch(clearProductMillingDetails());
      dispatch(clearItemList());
    };
  }, []);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [ProductMillingDetail]);

  const handleItemSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllItemIncremental({ search }));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = async (values: any) => {
    if (
      values?.production_loss_qty?.toFixed(3) !=
      values?.total_loss_qty?.toFixed(3)
    ) {
      ErrorToast("Milling loss quantity must be same.");
    } else {
      if (product_milling_guid) {
        try {
          const editPayload = {
            product_milling_guid: ProductMillingDetail?.product_milling_guid,
            milling_date: values.milling_date
              ? moment(values.milling_date).format("YYYY-MM-DD")
              : "",
            milling_no: values.milling_no ? +values.milling_no : null,
            ready_product_id: values.ready_product_id
              ? +values.ready_product_id
              : null,
            no_of_workers: values.no_of_workers ? +values.no_of_workers : null,
            machine_id: values.machine_id ? +values.machine_id : null,
            start_time: values.start_time
              ? moment(values.start_time).format("HH:mm:ss")
              : "",
            end_time: values.end_time
              ? moment(values.end_time).format("HH:mm:ss")
              : "",
            total_qty: values.total_qty ? +values.total_qty : 0,
            received_qty: values.received_qty ? +values.received_qty : 0,
            production_loss_qty: values.production_loss_qty
              ? +values.production_loss_qty
              : 0,
            milling_loss_percentage: values?.milling_loss_percentage
              ? +values?.milling_loss_percentage?.replace("%", "")
              : null,
            katta: values.katta ? +values.katta : null,
            lot_no: values.lot_no ? values.lot_no : "",
            checker_by: values.checker_by ? +values.checker_by : null,
            financial_year: FINANCIAL_YEAR,
            remarks: values?.remarks ? values?.remarks : "",
            product_milling_items: values?.product_milling_items
              ? values?.product_milling_items?.map((pmitem: any) => {
                  return {
                    id: pmitem?.id ? +pmitem?.id : 0,
                    process_id: pmitem?.process_id ? +pmitem?.process_id : null,
                    item_id: pmitem?.item_id ? +pmitem?.item_id : null,
                    grn_no: pmitem?.grn_no ? pmitem?.grn_no : "",
                    taken_qty: pmitem?.taken_qty ? +pmitem?.taken_qty : null,
                    wastage_qty: pmitem?.wastage_qty
                      ? +pmitem?.wastage_qty
                      : null,
                    loss_percentage: pmitem?.loss_percentage
                      ? +pmitem?.loss_percentage?.replace("%", "")
                      : null,
                  };
                })
              : [],
          };
          const response = await dispatch(updateProductMiliingNew(editPayload));
          if (response?.meta?.requestStatus === "fulfilled") {
            navigate("/productmilling");
            dispatch(closed());
            dispatch(clearProductMillingDetails());
          }
        } catch (error) {
          console.error("Error in handleSubmit:", error);
        }
      } else {
        try {
          const insertPayload = {
            milling_date: values.milling_date
              ? moment(values.milling_date).format("YYYY-MM-DD")
              : "",
            ready_product_id: values.ready_product_id
              ? +values.ready_product_id
              : null,
            no_of_workers: values.no_of_workers ? +values.no_of_workers : null,
            machine_id: values.machine_id ? +values.machine_id : null,
            start_time: values.start_time
              ? moment(values.start_time).format("HH:mm:ss")
              : "",
            end_time: values.end_time
              ? moment(values.end_time).format("HH:mm:ss")
              : "",
            total_qty: values.total_qty ? +values.total_qty : 0,
            received_qty: values.received_qty ? +values.received_qty : 0,
            production_loss_qty: values.production_loss_qty
              ? +values.production_loss_qty
              : 0,
            milling_loss_percentage: values?.milling_loss_percentage
              ? +values?.milling_loss_percentage?.replace("%", "")
              : null,
            katta: values.katta ? +values.katta : null,
            lot_no: values.lot_no ? values.lot_no : "",
            checker_by: values.checker_by,
            financial_year: FINANCIAL_YEAR,
            remarks: values?.remarks ? values?.remarks : "",
            product_milling_items: values?.product_milling_items
              ? values?.product_milling_items?.map((pmitem: any) => {
                  return {
                    process_id: pmitem?.process_id ? +pmitem?.process_id : null,
                    item_id: pmitem?.item_id ? +pmitem?.item_id : null,
                    grn_no: pmitem?.grn_no ? pmitem?.grn_no : "",
                    taken_qty: pmitem?.taken_qty ? +pmitem?.taken_qty : null,
                    wastage_qty: pmitem?.wastage_qty
                      ? +pmitem?.wastage_qty
                      : null,
                    loss_percentage: pmitem?.loss_percentage
                      ? +pmitem?.loss_percentage?.replace("%", "")
                      : null,
                  };
                })
              : [],
          };
          const response = await dispatch(
            createProductMiliingNew(insertPayload)
          );
          if (response?.meta?.requestStatus === "fulfilled") {
            navigate("/productmilling");
            dispatch(closed());
            dispatch(clearProductMillingDetails());
          }
        } catch (error) {
          console.error("Error in handleSubmit:", error);
          throw error;
        }
      }
    }
  };

  const handleProductMillingNoChange = (
    ev: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (ev.key === "Enter" || ev.key === "Tab") {
      ev.preventDefault();
      if ((ev.target as HTMLInputElement).value) {
        const payload = {
          milling_no: (ev.target as HTMLInputElement).value,
          financial_year: FINANCIAL_YEAR,
        };
        dispatch(getProductMiliingByIDNew(payload));
      }
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
    navigate("/productmilling");
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {isDrawerOpen && drawerName === "machineForm" && (
        <AppDrawer>
          <CreateMachine />
        </AppDrawer>
      )}
      <Form
        onSubmit={handleSubmit}
        initialValues={ProductMillingDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 40 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>
                    {product_milling_guid
                      ? "Update Product Milling"
                      : "Add Product Milling"}
                  </Typography.h4>
                </GridLayoutItem>
                {product_milling_guid && (
                  <GridLayoutItem>
                    <Field
                      name="milling_no"
                      onKeyDown={handleProductMillingNoChange}
                      label="Product Milling No"
                      placeholder="Product Milling No"
                      component={FormTextField}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                )}
                {product_milling_guid && (
                  <GridLayoutItem colSpan={3}></GridLayoutItem>
                )}
                <GridLayoutItem>
                  <Field
                    name="milling_date"
                    label="Milling Date"
                    format={"dd/MM/yyyy"}
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="ready_product_id"
                    label="Ready Product"
                    placeholder="Product Name"
                    component={FormIncrementalSearch}
                    validator={requiredValidator}
                    astrike={true}
                    fetchIncrementalData={(search: any) =>
                      handleItemSearchChange(
                        search,
                        `item_options`,
                        formRenderProps
                      )
                    }
                    options={
                      formRenderProps
                        .valueGetter("item_options")
                        ?.map((item: any) => {
                          return {
                            value: item?.id,
                            label: item?.product_name,
                          };
                        }) || []
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="w-100"
                    name="lot_no"
                    label="Lot No."
                    placeholder="0"
                    component={FormTextField}
                    validator={requiredValidator}
                    disabled={true}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="machine_id"
                    label="Machine No"
                    placeholder="Machine No"
                    isAddNew={true}
                    drawerName="machineForm"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    options={MachineList?.map((machine: any) => {
                      return {
                        value: machine.id,
                        label: machine.machine_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="w-100 right-alighned-field"
                    name="no_of_workers"
                    label="No Of Workers"
                    placeholder="0"
                    type="number"
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperStyle={{ width: "100%" }}
                    name="start_time"
                    label="Start Time"
                    placeholder="Start Time"
                    component={FormTimePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperStyle={{ width: "100%" }}
                    name="end_time"
                    label="End Time"
                    placeholder="End Time"
                    component={FormTimePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    name="katta"
                    label="Katta"
                    placeholder="0"
                    component={FormNumericTextField}
                    min={0}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="checker_by"
                    label="Checked By"
                    placeholder="Checked By"
                    component={FormSelectionField}
                    options={UserList?.map((user: IUser) => {
                      return {
                        value: user.id,
                        label: `${user?.first_name} ${user?.last_name}`,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={3}>
                  <Field
                    name="remarks"
                    label="Remarks"
                    placeholder="Remarks"
                    rows={2}
                    component={FormTextArea}
                  />
                </GridLayoutItem>
                <QtychangeWatcher formRenderProps={formRenderProps} />
                <LotNoChangeWatcher formRenderProps={formRenderProps} />
                <TakenQtyWatcher formRenderProps={formRenderProps} />
                <InnerItemChangeWatcher formRenderProps={formRenderProps} />
                <UserChangeWatcher formRenderProps={formRenderProps} />
              </GridLayout>
            </ShadowCard>

            <ShadowCard
              style={{ padding: 12, marginTop: 10, marginBottom: 10 }}
            >
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 10, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>Item Details</Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem colSpan={4}>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    component={PMItemDetailsArray}
                    handleItemSearchChange={handleItemSearchChange}
                    name="product_milling_items"
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={4}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    marginTop: 10,
                  }}
                >
                  <ButtonWithLoading
                    label={!product_milling_guid ? "Save" : "Update"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                  />
                  <Button
                    type="button"
                    fillMode={"outline"}
                    themeColor={"primary"}
                    style={{ marginLeft: 4 }}
                    onClick={handleFormClose}
                  >
                    Cancel
                  </Button>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateProductMilling;
