import React, { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import AlertBox from "../../components/common/AlertBox";
import { LoadingPanel } from "../../components/layout/Loading";
import IconButton from "../../components/common/IconButton";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { MdDelete } from "react-icons/md";

import { closed } from "../../components/muidrawer/muiDrawerSlice";
import ButtonCreate from "../../components/muidrawer/ButtonCreate";
import AppDrawer from "../../components/muidrawer/Drawer";
import ButtonEdit from "../../components/muidrawer/ButtonEdit";
import { checkAcessRights, formatIndianNumber } from "../../_helper/helper";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  INSERT_ACCESS,
} from "../../_contstants/common";
import {
  deleteExpenseAllocation,
  getAllExpenseAllocations,
} from "./services/expenseAllocation.services";
import {
  clearExpenseAllocationID,
  setExpenseAllocationID,
} from "./expenseAllocationSlice";
import CreateExpenseAllocation from "./CreateExpenseAllocation";
import { setCurrentPage, setPageLimit } from "../pagination/paginationSlice";

const ExpenseAllocation: React.FC = () => {
  const dispatch = useAppDispatch();
  const isDrawerOpen = useAppSelector((state) => state.muidrawer.open);
  const drawerName = useAppSelector((state) => state.muidrawer.name);
  const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);

  useEffect(() => {
    return () => {
      dispatch(closeDialog());
      dispatch(closed());
      dispatch(setCurrentPage(0));
    };
  }, []);

  return (
    <>
      <DeleteExpenseAllocationDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Expense Allocation List
          </Typography.h4>
          {isAddAccess && (
            <ButtonCreate label="Add New" drawerName="expenseAllocationForm" />
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <ExpenseAllocationGridComponent />
        </GridLayoutItem>
        {isDrawerOpen && drawerName === "expenseAllocationForm" && (
          <AppDrawer>
            <CreateExpenseAllocation />
          </AppDrawer>
        )}
      </GridLayout>
    </>
  );
};

const ExpenseAllocationGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);

  const ExpenseAllocationList = useAppSelector(
    (state) => state.expenseAllocation.ExpenseAllocationList
  );
  const loading = useAppSelector((state) => state.expenseAllocation.loading);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);

  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };

  useEffect(() => {
    const fetchItemList = async () => {
      const payload = {
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      await dispatch(getAllExpenseAllocations(payload));
    };
    fetchItemList();
  }, [currentPage, pageLimit]);

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleOpenDeleteDialog = (expense_allocation_guid: number) => {
      dispatch(openDialog("deleteExpenseAllocation"));
      dispatch(setExpenseAllocationID(expense_allocation_guid));
    };

    return (
      <td
        className="action-td"
        style={{
          ...props.style,
        }}
      >
        {isEditAccess && (
          <ButtonEdit
            ID={props.dataItem.expense_allocation_guid}
            name="expenseAllocationForm"
            tooltipTitle="Edit"
          />
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() =>
              handleOpenDeleteDialog(props.dataItem.expense_allocation_guid)
            }
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  // const MyActiveInActtiveCommandCell = (props: GridCellProps) => {
  //   const [IsActive, setIsActive] = useState<boolean>(props.dataItem.isactive);

  //   const handleActiveInActive = () => {
  //     setIsActive(!IsActive);
  //     const data = {
  //       expense_allocation_guid: props.dataItem.expense_allocation_guid,
  //       isactive: !IsActive,
  //     };
  //     dispatch(ActiveInactiveExpenseAllocation(data)).then((response: any) => {
  //       const payload = {
  //         limit: +pageLimit,
  //         skip: +currentPage * +pageLimit,
  //       };
  //       if (response.payload?.data?.Status === 200) {
  //         dispatch(getAllExpenseAllocations(payload));
  //       } else {
  //         dispatch(getAllExpenseAllocations(payload));
  //       }
  //     });
  //   };

  //   return (
  //     <td style={{ textAlign: "center" }}>
  //       <Switch
  //         disabled={isEditAccess ? false : true}
  //         name="isactive"
  //         checked={IsActive}
  //         onChange={handleActiveInActive}
  //         size="small"
  //       />
  //     </td>
  //   );
  // };

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      {ExpenseAllocationList && ExpenseAllocationList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          className="responsive-table"
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(ExpenseAllocationList, filter)
              : ExpenseAllocationList
          }
          onFilterChange={handleFilterChange}
          total={
            ExpenseAllocationList[0]?.itemcount || ExpenseAllocationList?.length
          }
          skip={currentPage * pageLimit}
          take={pageLimit}
          pageable={{
            buttonCount: 5,
            pageSizes: [10, 50, 100, 300, 500],
            pageSizeValue: pageLimit,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            width={300}
            field="sales_reprensentatine_name"
            title="Sales Representative Name"
            cell={(props: any) => (
              <td className={isEditAccess ? "hoverableName" : "normalName"}>
                {isEditAccess ? (
                  <ButtonEdit
                    ID={props.dataItem?.expense_allocation_guid}
                    name="expenseAllocationForm"
                    label={props.dataItem?.sales_reprensentatine_name}
                  />
                ) : (
                  props.dataItem?.sales_reprensentatine_name
                )}
              </td>
            )}
          />
          <Column width={250} field="expense_type_name" title="Expense Type" />
          <Column
            field="expense_amount"
            title="Expense Amount"
            cell={(props: any) => (
              <td className="text-end">
                ₹ {formatIndianNumber(props.dataItem?.expense_amount || 0)}
              </td>
            )}
            width={180}
          />
          {/* <Column
            field="id"
            title="Status"
            cell={MyActiveInActtiveCommandCell}
            width={"90px"}
            filterable={false}
            locked={true}
          /> */}
          {(isEditAccess || isDeleteAccess) && (
            <Column
              field="id"
              title="Actions"
              cell={MyEditCommandCell}
              width={"110px"}
              filterable={false}
            />
          )}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteExpenseAllocationDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const ExpenseAllocationID = useAppSelector(
    (state) => state.expenseAllocation.ExpenseAllocationID
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);

  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteExpenseAllocation(ID));
      const payload = {
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      if (response.payload.Data.Status === 200) {
        dispatch(getAllExpenseAllocations(payload));
        dispatch(closeDialog());
        dispatch(clearExpenseAllocationID());
      } else {
        dispatch(getAllExpenseAllocations(payload));
        dispatch(closeDialog());
        dispatch(clearExpenseAllocationID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteExpenseAllocation" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Expense Allocation?"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Expense Allocation?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(ExpenseAllocationID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default ExpenseAllocation;
