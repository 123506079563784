import React, { useCallback, useEffect, useState } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import FormDatePicker from "../../../components/formFields/FormDateField";
import moment from "moment";
import { Typography } from "@progress/kendo-react-common";
import ShadowCard from "../../../components/common/ShadowCard";
import { getAllItemIncremental } from "../../Item/services/item.services";
import {
  clearPurchaseRegisterDetails,
  clearPurchaseRegisterList,
  setPurchaseRegisterReport,
} from "../reportsSlice";
import AlertBox from "../../../components/common/AlertBox";
import { LoadingPanel } from "../../../components/layout/Loading";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import FormIncrementalSearch from "../../../components/formFields/FormIncrementalSearch";
import { clearItemList } from "../../Item/itemSlice";
import { Button } from "@progress/kendo-react-buttons";
import Logo from "../../../assets/Images/logo.png";
import {
  checkAcessRights,
  formatIndianNumber,
  formatIndianNumberForQty,
} from "../../../_helper/helper";
import {
  EDIT_ACCESS,
  EXCEL_EXPORT_ACCESS,
  PRINT_ACCESS,
} from "../../../_contstants/common";
import { getAllAccountIncremental } from "../../account/services/account.services";
import { RxCross2 } from "react-icons/rx";
import FormCheckboxGroup from "../../../components/formFields/FormCheckboxGroup";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { BsThreeDotsVertical } from "react-icons/bs";
import { getPurchaseRegisterReport } from "../services/reports.services";
import { FiEdit } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import IconButton from "../../../components/common/IconButton";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { FaSort } from "react-icons/fa";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const DummyChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const createdBy = localStorage.getItem("UserID");
  useEffect(() => {
    formRenderProps.onChange("UserID", {
      value: createdBy,
    });
  }, []);

  return null;
};

const PurchaseRegisterReportForm: React.FC = () => {
  // const gridRef = React.useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.reports.loading);
  const PurchaseRegisterList = useAppSelector(
    (state) => state.reports.PurchaseRegisterList
  );
  const PurchaseRegisterDetails = useAppSelector(
    (state) => state.reports.PurchaseRegisterDetails
  );
  const [initialValue, setInitialValue] = useState<any>({});
  const [formKey, setFormKey] = useState<any>(1);

  // useEffect(() => {
  // }, [PurchaseRegisterDetails]);

  useEffect(() => {
    setInitialValue({
      ...PurchaseRegisterDetails,
      from_date: PurchaseRegisterDetails?.from_date
        ? moment(PurchaseRegisterDetails?.from_date, "YYYY-MM-DD").toDate()
        : null,
      to_Date: PurchaseRegisterDetails?.to_Date
        ? moment(PurchaseRegisterDetails?.to_Date, "YYYY-MM-DD").toDate()
        : null,
    });
    setFormKey(formKey + 1);
  }, [PurchaseRegisterDetails]);

  const handleVendorSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllAccountIncremental(search));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleItemSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllItemIncremental({ search }));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = (values: any) => {
    const payload = {
      item_id: values?.item_id ? values?.item_id : null,
      vendor_id: values?.vendor_id ? values?.vendor_id : null,
      from_date: values?.from_date
        ? moment(values?.from_date).format("YYYY-MM-DD")
        : null,
      to_Date: values?.to_Date
        ? moment(values?.to_Date).format("YYYY-MM-DD")
        : null,
    };
    const setPayload = {
      ...payload,
      vendor_options: values?.vendor_options || [],
      item_options: values?.item_options || [],
      isPurchaseRef: true,
    };
    dispatch(setPurchaseRegisterReport(setPayload));
    dispatch(getPurchaseRegisterReport(payload));
  };

  return (
    <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
      <GridLayoutItem>
        <ShadowCard>
          <Form
            key={formKey}
            initialValues={initialValue}
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 30 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                  ]}
                >
                  <GridLayoutItem colSpan={4}>
                    <Typography.h4
                      style={{ marginBottom: 0, marginLeft: "3px" }}
                    >
                      Purchase Register
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="from_date"
                      label="From Date"
                      format="dd/MM/yyyy"
                      component={FormDatePicker}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="to_Date"
                      label="To Date"
                      format="dd/MM/yyyy"
                      minDate={moment(
                        formRenderProps.valueGetter("from_date")
                      ).toDate() || new Date(1900, 0, 1)}
                      component={FormDatePicker}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="vendor_id"
                      label="Vendor"
                      placeholder="Type here to search Vendor..."
                      component={FormIncrementalSearch}
                      fetchIncrementalData={(search: any) =>
                        handleVendorSearchChange(
                          search,
                          `vendor_options`,
                          formRenderProps
                        )
                      }
                      options={
                        formRenderProps
                          .valueGetter("vendor_options")
                          ?.map((item: any) => {
                            return {
                              value: item.id,
                              label: item.account_name,
                            };
                          }) || []
                      }
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="w-100"
                      name="item_id"
                      label="Product Name"
                      placeholder="Type here to search item..."
                      component={FormIncrementalSearch}
                      fetchIncrementalData={(search: any) =>
                        handleItemSearchChange(
                          search,
                          `item_options`,
                          formRenderProps
                        )
                      }
                      options={
                        formRenderProps
                          .valueGetter("item_options")
                          ?.map((item: any) => {
                            return {
                              value: item?.id,
                              label: item?.product_name,
                            };
                          }) || []
                      }
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    colSpan={4}
                    style={{
                      textAlign: "end",
                      marginTop: 10,
                    }}
                  >
                    <ButtonWithLoading
                      label={"View"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                  </GridLayoutItem>
                </GridLayout>
                <DummyChangeWatcher formRenderProps={formRenderProps} />
              </FormElement>
            )}
          />
        </ShadowCard>
        {/* {loading ? (
          <LoadingPanel gridRef={gridRef} />
        ) : ( */}
        {PurchaseRegisterList && PurchaseRegisterList.length > 0 && (
          <ShadowCard style={{ marginTop: 10 }}>
            <PurchaseRegisterGridCommponent />
          </ShadowCard>
        )}
        {/* )} */}
      </GridLayoutItem>
    </GridLayout>
  );
};

const columns = [
  { label: "Invoice No.", value: "invoice_no" },
  { label: "Invoice Date", value: "invoice_date" },
  { label: "Inward No.", value: "inward_no" },
  { label: "Inward Date", value: "inward_date" },
  { label: "PO No.", value: "po_no" },
  { label: "PO Date", value: "po_date" },
  { label: "Vendor Name", value: "account_name" },
  { label: "PAN No.", value: "pan_no" },
  { label: "GST No.", value: "gst_no" },
  { label: "Item Name", value: "product_name" },
  { label: "Qty", value: "quantity" },
  { label: "Free Qty", value: "free_quantity" },
  { label: "Rate", value: "rate" },
  { label: "Unit", value: "unit_name" },
  { label: "Discount", value: "discount" },
  { label: "Taxable Amount ", value: "taxable_amount" },
  { label: "CGST(%)", value: "cgst" },
  { label: "CGST Amount", value: "cgst_amount" },
  { label: "SGST(%)", value: "sgst" },
  { label: "SGST Amount", value: "sgst_amount" },
  { label: "IGST(%)", value: "igst" },
  { label: "IGST Amount", value: "igst_amount" },
  { label: "Total Amount", value: "total_gst_with_amount" },
  { label: "Total Taxable Amount", value: "total_taxable_amount" },
  { label: "Total CGST Amount", value: "total_cgst" },
  { label: "Total SGST Amount", value: "total_sgst" },
  { label: "Total IGST Amount", value: "total_igst" },
  { label: "packing", value: "packing" },
  { label: "Dalali", value: "dalali" },
  { label: "Labour", value: "labour" },
  { label: "Grand Amount", value: "total_amount" },
  { label: "Terms Day", value: "terms_days" },
  { label: "Due Date", value: "due_date" },
];
const PurchaseRegisterGridCommponent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const gridRef = React.useRef<any>(null);
  const isEditBtnRef = React.useRef<any>(false);
  const isEditAccess = checkAcessRights("/purchase", EDIT_ACCESS);
  const isPrintAccess = checkAcessRights(location.pathname, PRINT_ACCESS);
  const isExcelAccess = checkAcessRights(
    location.pathname,
    EXCEL_EXPORT_ACCESS
  );

  const loading = useAppSelector((state) => state.reports.loading);
  const PurchaseRegisterList = useAppSelector(
    (state) => state.reports.PurchaseRegisterList
  );
  const PurchaseRegisterDetails = useAppSelector(
    (state) => state.reports.PurchaseRegisterDetails
  );
  const [showfilterColumns, setShowFilterColumns] = React.useState(false);
  const [selectedGroup, setSelectedGroup] = React.useState<string | null>(null);
  const [filterGroupedArray, setFilterGroupedArray] = React.useState<any>(null);
  const [filterData, setFilterData] = React.useState(false);

  useEffect(() => {
    return () => {
      if (!isEditBtnRef.current) {
        dispatch(clearPurchaseRegisterList());
        dispatch(clearPurchaseRegisterDetails());
        dispatch(clearItemList());
      }
    };
  }, []);

  const handleExportExcel = () => {
    const datetime = moment().format("DD-MM-YYYY HH-mm");
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.table_to_sheet(
      document.querySelector(".currentstock-print-table")
    );
    XLSX.utils.book_append_sheet(wb, ws, "PurchaseRegisterReport");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(data, `Purchase Register Report ${datetime}`);
  };

  const handleRowClick = (purchase_guid: string) => {
    const groupKey = `${purchase_guid}`;
    setSelectedGroup(selectedGroup === groupKey ? null : groupKey);
  };

  const initialValues = {
    filter_columns: [
      "invoice_no",
      "invoice_date",
      // "inward_no",
      // "inward_date",
      "po_no",
      // "po_date",
      "account_name",
      // "pan_no",
      "gst_no",
      // "product_name",
      // "quantity",
      // "free_quantity",
      // "rate",
      // "unit_name",
      // "discount",
      "taxable_amount",
      "cgst",
      "cgst_amount",
      "sgst",
      "sgst_amount",
      "igst",
      "igst_amount",
      // "total_gst_with_amount",
      // "total_taxable_amount",
      "total_cgst",
      "total_sgst",
      "total_igst",
      // "packing",
      // "dalali",
      // "labour",
      "total_amount",
      // "terms_days",
      // "due_date",
    ],
  };

  const GrandTotalArray: any = [
    "quantity",
    "free_quantity",
    "taxable_amount",
    "cgst_amount",
    "sgst_amount",
    "igst_amount",
    "total_gst_with_amount",
    "total_taxable_amount",
    "total_cgst",
    "total_sgst",
    "total_igst",
    "packing",
    "dalali",
    "labour",
    "total_amount",
  ];

  const groupedData = PurchaseRegisterList.reduce((acc: any, item: any) => {
    const { purchase_guid } = item;
    acc[purchase_guid] = acc[purchase_guid] || [];
    acc[purchase_guid].push(item);
    return acc;
  }, {});
  const groupedArray = Object.values(groupedData);

  useEffect(() => {
    setFilterGroupedArray(groupedArray);
    setFilterData(false);
  }, [PurchaseRegisterList]);

  const handleFilter = (filterKey: string) => {
    const sortedArray = [...PurchaseRegisterList].sort((a: any, b: any) => {
      let valueA = a[filterKey];
      let valueB = b[filterKey];
      if (filterKey === "invoice_date") {
        valueA = new Date(valueA.split("/").reverse().join("-")).getTime();
        valueB = new Date(valueB.split("/").reverse().join("-")).getTime();
      }
      if (typeof valueA === "string" && typeof valueB === "string") {
        valueA = valueA.toLowerCase();
        valueB = valueB.toLowerCase();
      }
      if (!filterData) {
        return valueA > valueB ? 1 : valueA < valueB ? -1 : 0; // Ascending order
      } else {
        return valueA < valueB ? 1 : valueA > valueB ? -1 : 0; // Descending order
      }
    });

    const groupedData = sortedArray.reduce((acc: any, item: any) => {
      const { purchase_guid } = item;
      acc[purchase_guid] = acc[purchase_guid] || [];
      acc[purchase_guid].push(item);
      return acc;
    }, {});
    setFilterGroupedArray(Object.values(groupedData));
    setFilterData((prev) => !prev);
  };

  let totalQty = 0;
  let totalFreeQty = 0;
  let totalTaxableAmount = 0;
  let totalCGSTAmount = 0;
  let totalSGSTAmount = 0;
  let totalIGSTAmount = 0;
  let totalGSTWithAmount = 0;
  let totalGrandTaxableAmount = 0;
  let GrandtotalCGSTAmount = 0;
  let GrandtotalSGSTAmount = 0;
  let GrandtotalIGSTAmount = 0;
  let totalPackingAmount = 0;
  let totalDalaliAmount = 0;
  let totalLabourAmount = 0;
  let GrandTotalAmount = 0;

  groupedArray &&
    groupedArray.length > 0 &&
    groupedArray?.map((group: any) => {
      group?.map((item: any, index: number) => {
        totalQty += item?.quantity || 0;
        totalFreeQty += item?.free_quantity || 0;
        totalTaxableAmount += item?.taxable_amount || 0;
        totalCGSTAmount += item?.cgst_amount || 0;
        totalSGSTAmount += item?.sgst_amount || 0;
        totalIGSTAmount += item?.igst_amount || 0;
        totalGSTWithAmount += item?.total_gst_with_amount || 0;

        if (index === 0) {
          totalGrandTaxableAmount += item.total_taxable_amount || 0;
          GrandtotalCGSTAmount += item.total_cgst || 0;
          GrandtotalSGSTAmount += item.total_sgst || 0;
          GrandtotalIGSTAmount += item.total_igst || 0;
          totalPackingAmount += item.packing || 0;
          totalDalaliAmount += item.dalali || 0;
          totalLabourAmount += item.labour || 0;
          GrandTotalAmount += item.total_amount || 0;
        }
      });
    });

  const printTable = (data: any[], formRenderProps: any) => {
    let printbuttons = document.querySelector(
      ".printbuttons"
    ) as HTMLElement | null;
    let report_container = document.querySelector(
      ".report-container"
    ) as HTMLElement | null;

    if (printbuttons) printbuttons.style.display = "none";
    if (report_container) report_container.style.padding = "20px 0";

    let tableRows = data.length;
    let maxRows = tableRows;
    let pageCount = Math.ceil(tableRows / maxRows);
    let content = "";

    for (let i = 0; i < pageCount; i++) {
      content += '<div class="print-page">';
      content +=
        '<table class="table table-bordered" style="font-size:10px;margin-bottom:30px;">';
      content += "<tr>";
      content += `<td rowSpan="3" style="width:15%" ><img src="${Logo}" width="100%" /></td>`;
      content +=
        '<td colSpan="2" style="text-align: center;font-size: 14px;font-weight: 600;color: gray;">Harihar Foods Pvt Ltd.</td>';
      content += "</tr>";
      content += "<tr>";
      content +=
        '<td colSpan="2"><h3 style="margin:0;">Purchase Register Report</h3></td>';
      content += "</tr>";
      content += "<tr>";
      content += `<td style="text-align:end;">Page : ${i + 1}</td>`;
      content += "</tr>";

      content += "</table>";

      content += '<table class="table table-bordered" style="font-size:10px;">';
      content += '<thead style="background:lightgray;">';
      content += "<tr>";
      content += '<th style="width:30px;">Purchase No.</th>';
      {
        formRenderProps.valueGetter("filter_columns")?.includes("invoice_no") &&
          (content += '<th style="width:30px;">Invoice No.</th>');
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("invoice_date") && (content += "<th>Invoice Date</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("inward_no") &&
          (content += "<th>Inward No.</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("inward_date") && (content += "<th>Inward Date</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("po_no") &&
          (content += "<th>PO No.</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("po_date") &&
          (content += "<th>PO Date</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("account_name") && (content += "<th>Vendor Name</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("pan_no") &&
          (content += "<th>PAN No.</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("gst_no") &&
          (content += "<th>GST No.</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("product_name") && (content += "<th>Item Name</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("quantity") &&
          (content += "<th>Qty</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("free_quantity") && (content += "<th>Free Qty</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("rate") &&
          (content += "<th>Rate</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("unit_name") &&
          (content += "<th>Unit</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("discount") &&
          (content += "<th>Discount</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("taxable_amount") &&
          (content += "<th>Taxable Amount</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("cgst") &&
          (content += "<th>CGST(%)</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("cgst_amount") && (content += "<th>CGST Amount</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("sgst") &&
          (content += "<th>SGST(%)</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("sgst_amount") && (content += "<th>SGST Amount</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("igst") &&
          (content += "<th>IGST(%)</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("igst_amount") && (content += "<th>IGST Amount</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("total_gst_with_amount") &&
          (content += "<th>Total Amount</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("total_taxable_amount") &&
          (content += "<th>Total Taxable Amount</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("total_cgst") &&
          (content += "<th>Total CGST Amount</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("total_sgst") &&
          (content += "<th>Total SGST Amount</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("total_igst") &&
          (content += "<th>Total IGST Amount</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("packing") &&
          (content += "<th>Packing</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("dalali") &&
          (content += "<th>Dalali</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("labour") &&
          (content += "<th>Labour</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("total_amount") && (content += "<th>Grand Amount</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("terms_days") &&
          (content += "<th>Terms Day</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("due_date") &&
          (content += "<th>Due Date</th>");
      }
      content += "</tr>";
      content += "</thead>";
      content += "<tbody>";

      // let startRow = i * maxRows;
      // let endRow = Math.min((i + 1) * maxRows, tableRows);

      data.map((group: any) =>
        group.map((item: any, index: any) => {
          content += "<tr>";
          index === 0 &&
            (content += `<td rowspan="${group.length}" >${
              item.purchase_no_string ? item.purchase_no_string : ""
            }</td>`);
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("invoice_no") &&
              index === 0 &&
              (content += `<td rowspan="${group.length}" class="text-start">${item.invoice_no}</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("invoice_date") &&
              index === 0 &&
              (content += `<td rowspan="${
                group.length
              }" style="text-align:center;">${
                item?.invoice_date
                  ? moment(item?.invoice_date, "DD/MM/YYYY").format(
                      "DD MMM YYYY"
                    )
                  : ""
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("inward_no") &&
              index === 0 &&
              (content += `<td rowspan="${
                group.length
              }" style="text-align:center;">${
                item.inward_no ? item.inward_no : ""
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("inward_date") &&
              index === 0 &&
              (content += `<td rowspan="${
                group.length
              }" style="text-align:center;">${
                item?.inward_date
                  ? moment(item?.inward_date, "DD/MM/YYYY").format(
                      "DD MMM YYYY"
                    )
                  : ""
              }</td>`);
          }
          {
            formRenderProps.valueGetter("filter_columns")?.includes("po_no") &&
              index === 0 &&
              (content += `<td rowspan="${group.length}" style="text-align:center;">${item.po_no}</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("po_date") &&
              index === 0 &&
              (content += `<td rowspan="${
                group.length
              }" style="text-align:center;">${
                item?.po_date
                  ? moment(item?.po_date, "DD/MM/YYYY").format("DD MMM YYYY")
                  : ""
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("account_name") &&
              index === 0 &&
              (content += `<td rowspan="${group.length}">${item.account_name}</td>`);
          }
          {
            formRenderProps.valueGetter("filter_columns")?.includes("pan_no") &&
              index === 0 &&
              (content += `<td rowspan="${group.length}">${item.pan_no}</td>`);
          }
          {
            formRenderProps.valueGetter("filter_columns")?.includes("gst_no") &&
              index === 0 &&
              (content += `<td rowspan="${group.length}">${item.gst_no}</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("product_name") &&
              (content += `<td>${item.product_name}</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("quantity") &&
              (content += `<td style="text-align:end;">${
                item.quantity ? formatIndianNumberForQty(item.quantity) : 0
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("free_quantity") &&
              (content += `<td style="text-align:end;">${
                item.free_quantity
                  ? formatIndianNumberForQty(item.free_quantity)
                  : 0
              }</td>`);
          }
          {
            formRenderProps.valueGetter("filter_columns")?.includes("rate") &&
              (content += `<td style="text-align:end;">${
                item.rate ? formatIndianNumber(item.rate) : 0
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("unit_name") &&
              (content += `<td>${item.unit_code}</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("discount") &&
              (content += `<td style="text-align:end;">${
                item.discount ? item.discount : ""
              } ${
                item.discount && item.discount_type
                  ? item.discount_type === 1
                    ? "₹"
                    : item.discount_type === 2
                    ? "%"
                    : ""
                  : ""
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("taxable_amount") &&
              (content += `<td style="text-align:end;">${
                item.taxable_amount
                  ? formatIndianNumber(item.taxable_amount)
                  : 0
              }</td>`);
          }
          {
            formRenderProps.valueGetter("filter_columns")?.includes("cgst") &&
              (content += `<td style="text-align:end;">${
                item.cgst ? `${item.cgst} %` : ""
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("cgst_amount") &&
              (content += `<td style="text-align:end;">${
                item.cgst_amount ? formatIndianNumber(item.cgst_amount) : ""
              }</td>`);
          }
          {
            formRenderProps.valueGetter("filter_columns")?.includes("sgst") &&
              (content += `<td style="text-align:end;">${
                item.sgst ? `${item.sgst} %` : ""
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("sgst_amount") &&
              (content += `<td style="text-align:end;">${
                item.sgst_amount ? formatIndianNumber(item.sgst_amount) : ""
              }</td>`);
          }
          {
            formRenderProps.valueGetter("filter_columns")?.includes("igst") &&
              (content += `<td style="text-align:end;">${
                item.igst ? `${item.igst} %` : ""
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("igst_amount") &&
              (content += `<td style="text-align:end;">${
                item.igst_amount ? formatIndianNumber(item.igst_amount) : ""
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("total_gst_with_amount") &&
              (content += `<td style="text-align:end;">${
                item.total_gst_with_amount
                  ? formatIndianNumber(item.total_gst_with_amount)
                  : ""
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("total_taxable_amount") &&
              index === 0 &&
              (content += `<td rowspan="${
                group.length
              }" style="text-align:end;">${
                item.total_taxable_amount
                  ? formatIndianNumber(item.total_taxable_amount)
                  : ""
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("total_cgst") &&
              index === 0 &&
              (content += `<td rowspan="${
                group.length
              }" style="text-align:end;">${
                item.total_cgst ? formatIndianNumber(item.total_cgst) : ""
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("total_sgst") &&
              index === 0 &&
              (content += `<td rowspan="${
                group.length
              }" style="text-align:end;">${
                item.total_sgst ? formatIndianNumber(item.total_sgst) : ""
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("total_igst") &&
              index === 0 &&
              (content += `<td rowspan="${
                group.length
              }" style="text-align:end;">${
                item.total_igst ? formatIndianNumber(item.total_igst) : ""
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("packing") &&
              index === 0 &&
              (content += `<td rowspan="${
                group.length
              }" style="text-align:end;">${
                item.packing ? formatIndianNumber(item.packing) : ""
              }</td>`);
          }
          {
            formRenderProps.valueGetter("filter_columns")?.includes("dalali") &&
              index === 0 &&
              (content += `<td rowspan="${
                group.length
              }" style="text-align:end;">${
                item.dalali ? formatIndianNumber(item.dalali) : ""
              }</td>`);
          }
          {
            formRenderProps.valueGetter("filter_columns")?.includes("labour") &&
              index === 0 &&
              (content += `<td rowspan="${
                group.length
              }" style="text-align:end;">${
                item.labour ? formatIndianNumber(item.labour) : ""
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("total_amount") &&
              index === 0 &&
              (content += `<td rowspan="${
                group.length
              }" style="text-align:end;">${
                item.total_amount ? formatIndianNumber(item.total_amount) : ""
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("terms_days") &&
              index === 0 &&
              (content += `<td rowspan="${
                group.length
              }" style="text-align:center;">${
                item.terms_days ? item.terms_days : ""
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("due_date") &&
              index === 0 &&
              (content += `<td rowspan="${
                group.length
              }" style="text-align:center;">${
                item?.due_date
                  ? moment(item?.due_date, "DD/MM/YYYY").format("DD MMM YYYY")
                  : ""
              }</td>`);
          }
          content += "</tr>";
        })
      );
      if (data && data?.length > 0) {
        content += '<tr style="background:whitesmoke; font-weight:600;">';

        content += `<td  >Total</td>`;
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("invoice_no") &&
            (content += `<td  class="text-start"></td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("invoice_date") &&
            (content += `<td style="text-align:center;"></td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("inward_no") &&
            (content += `<td style="text-align:center;"></td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("inward_date") &&
            (content += `<td style="text-align:center;"></td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("po_no") &&
            (content += `<td  style="text-align:center;"></td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("po_date") &&
            (content += `<td style="text-align:center;"></td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("account_name") && (content += `<td ></td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("pan_no") &&
            (content += `<td ></td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("gst_no") &&
            (content += `<td ></td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("product_name") && (content += `<td></td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("quantity") &&
            (content += `<td style="text-align:end;">${formatIndianNumberForQty(
              totalQty
            )}</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("free_quantity") &&
            (content += `<td style="text-align:end;">${formatIndianNumberForQty(
              totalFreeQty
            )}</td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("rate") &&
            (content += `<td style="text-align:end;"></td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("unit_name") && (content += `<td></td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("discount") &&
            (content += `<td style="text-align:end;"></td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("taxable_amount") &&
            (content += `<td style="text-align:end;">${formatIndianNumber(
              totalTaxableAmount
            )}</td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("cgst") &&
            (content += `<td style="text-align:end;"></td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("cgst_amount") &&
            (content += `<td style="text-align:end;">${formatIndianNumber(
              totalCGSTAmount
            )}</td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("sgst") &&
            (content += `<td style="text-align:end;"></td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("sgst_amount") &&
            (content += `<td style="text-align:end;">${formatIndianNumber(
              totalSGSTAmount
            )}</td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("igst") &&
            (content += `<td style="text-align:end;"></td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("igst_amount") &&
            (content += `<td style="text-align:end;">${formatIndianNumber(
              totalIGSTAmount
            )}</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("total_gst_with_amount") &&
            (content += `<td style="text-align:end;">${formatIndianNumber(
              totalGSTWithAmount
            )}</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("total_taxable_amount") &&
            (content += `<td style="text-align:end;">${formatIndianNumber(
              totalGrandTaxableAmount
            )}</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("total_cgst") &&
            (content += `<td style="text-align:end;">${formatIndianNumber(
              GrandtotalCGSTAmount
            )}</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("total_sgst") &&
            (content += `<td style="text-align:end;">${formatIndianNumber(
              GrandtotalSGSTAmount
            )}</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("total_igst") &&
            (content += `<td style="text-align:end;">${formatIndianNumber(
              GrandtotalIGSTAmount
            )}</td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("packing") &&
            (content += `<td style="text-align:end;">${formatIndianNumber(
              totalPackingAmount
            )}</td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("dalali") &&
            (content += `<td style="text-align:end;">${formatIndianNumber(
              totalDalaliAmount
            )}</td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("labour") &&
            (content += `<td style="text-align:end;">${formatIndianNumber(
              totalLabourAmount
            )}</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("total_amount") &&
            (content += `<td style="text-align:end;">${formatIndianNumber(
              GrandTotalAmount
            )}</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("terms_days") &&
            (content += `<td style="text-align:center;"></td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("due_date") &&
            (content += `<td style="text-align:center;"></td>`);
        }
        content += "</tr>";
      }

      content += "</tbody>";
      content += "</table>";

      content += "</div>";
    }

    let printWindow = window.open("", "_blank");
    if (!printWindow) {
      console.error("Failed to open print preview window.");
      return;
    }
    printWindow.document.write(`
        <html>
        <head>
          <title>Print Preview</title>
          <style>
            @page { margin: 1cm; }
            .print-page { page-break-after: always; }
            .table-bordered { width: 100%; border-collapse: collapse; }
            .table-bordered th, .table-bordered td { border: 1px solid black; padding: 8px; }
            body { font-family: Arial, sans-serif; }
          </style>
        </head>
        <body>
          ${content}
          <script>
            window.onload = function() {
              window.print();
            };
          </script>
        </body>
        </html>
      `);
    printWindow.document.close();

    if (printbuttons) printbuttons.style.display = "block";
    if (report_container) report_container.style.padding = "20px 10px";
  };

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      {PurchaseRegisterList && PurchaseRegisterList.length ? (
        <Form
          initialValues={initialValues}
          render={(formRenderProps: FormRenderProps) => {
            const isMatchFound = GrandTotalArray?.some((subItem: any) =>
              formRenderProps.valueGetter("filter_columns")?.includes(subItem)
            );

            return (
              <FormElement
                style={{ width: "100%", minHeight: "calc(100vh - 290px)" }}
              >
                <GridLayout
                  gap={{ rows: 10, cols: 10 }}
                  cols={[{ width: "100%" }]}
                >
                  <GridLayoutItem className="d-flex justify-content-between align-items-center">
                    <Typography.h4 className="m-0">
                      Purchase Register List{" "}
                      {(PurchaseRegisterDetails?.from_date ||
                        PurchaseRegisterDetails?.to_Date) && (
                        <span style={{ fontSize: 13 }}>
                          {"("}
                          {PurchaseRegisterDetails?.from_date
                            ? moment(
                                PurchaseRegisterDetails?.from_date,
                                "YYYY-MM-DD"
                              ).format("DD/MM/YYYY")
                            : ""}
                          {PurchaseRegisterDetails?.from_date &&
                            PurchaseRegisterDetails?.to_Date &&
                            " - "}
                          {PurchaseRegisterDetails?.to_Date
                            ? moment(
                                PurchaseRegisterDetails?.to_Date,
                                "YYYY-MM-DD"
                              ).format("DD/MM/YYYY")
                            : ""}
                          {")"}
                        </span>
                      )}
                    </Typography.h4>
                    <div className="position-relative d-flex">
                      {isPrintAccess && (
                        <Button
                          style={{ marginRight: 5 }}
                          type="button"
                          fillMode={"solid"}
                          themeColor={"primary"}
                          onClick={() =>
                            printTable(filterGroupedArray, formRenderProps)
                          }
                        >
                          Print
                        </Button>
                      )}
                      {isExcelAccess && (
                        <Button
                          type="button"
                          fillMode={"solid"}
                          themeColor={"primary"}
                          onClick={handleExportExcel}
                          style={{ marginRight: 5 }}
                        >
                          Excel Export
                        </Button>
                      )}
                      <Tooltip
                        anchorElement="target"
                        position="left"
                        parentTitle={true}
                      >
                        <Button
                          title="Filter Columns"
                          type="button"
                          themeColor={"primary"}
                          fillMode={"solid"}
                          onClick={() => setShowFilterColumns(true)}
                        >
                          Filter
                          <BsThreeDotsVertical />
                        </Button>
                      </Tooltip>
                      {showfilterColumns && (
                        <div
                          style={{
                            position: "absolute",
                            top: "32px",
                            right: "0",
                            background: "white",
                            padding: "12px",
                            border: "1px solid whitesmoke",
                            width: "250px",
                            zIndex: 2,
                          }}
                        >
                          <div className="d-flex justify-content-between align-items-baseline">
                            <p className="m-0 fw-600">Filter</p>
                            <Button
                              themeColor={"primary"}
                              fillMode={"flat"}
                              onClick={() => setShowFilterColumns(false)}
                            >
                              <RxCross2 />
                            </Button>
                          </div>
                          <hr className="m-1" style={{ color: "lightgray" }} />
                          <Field
                            wrapperClassName="d-flex flex-column checkBoxGroup-vertical"
                            inputClassName="checkBoxGroup-vertical-input"
                            id={"filter_columns"}
                            name={"filter_columns"}
                            marginRight={0}
                            marginBeetween={8}
                            component={FormCheckboxGroup}
                            options={columns}
                          />
                        </div>
                      )}
                    </div>
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{
                      maxHeight: "calc(100vh - 334px)",
                      overflow: "scroll",
                      scrollbarWidth: "thin",
                    }}
                  >
                    <table
                      cellSpacing={1}
                      cellPadding={2}
                      className="table table-bordered w-100  currentstock-print-table"
                      style={{
                        marginTop: 1,
                      }}
                    >
                      <tr
                        className="text-center fw-600"
                        style={{
                          fontSize: 12,
                          position: "sticky",
                          top: 0,
                          outline: "1px solid #3b3b3b",
                          zIndex: 1,
                        }}
                      >
                        <th style={{ minWidth: 180 }}>Purchase No.</th>
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("invoice_no") && (
                          <th style={{ minWidth: 120 }}>Invoice No.</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("invoice_date") && (
                          <th style={{ minWidth: 120 }}>Invoice Date</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("inward_no") && (
                          <th style={{ minWidth: 80 }}>Inward No.</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("inward_date") && (
                          <th style={{ minWidth: 120 }}>Inward Date</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("po_no") && (
                          <th style={{ minWidth: 80 }}>PO No.</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("po_date") && (
                          <th style={{ minWidth: 120 }}>PO Date</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("account_name") && (
                          <th
                            style={{
                              minWidth: 250,
                              position: "relative",
                              cursor: "pointer",
                            }}
                            onClick={() => handleFilter("account_name")}
                          >
                            Vendor Name{" "}
                            <FaSort
                              onClick={() => handleFilter("account_name")}
                              style={{
                                fontSize: "20px",
                                zIndex: 0,
                                color: "red",
                                padding: "0",
                                background: "none",
                                cursor: "pointer",
                                position: "absolute",
                                right: "5px",
                              }}
                            />
                          </th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("pan_no") && (
                          <th style={{ minWidth: 150 }}>PAN No.</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("gst_no") && (
                          <th style={{ minWidth: 170 }}>GST No.</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("product_name") && (
                          <th style={{ minWidth: 250 }}>Item Name</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("quantity") && (
                          <th style={{ minWidth: 150 }}>Qty</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("free_quantity") && (
                          <th style={{ minWidth: 150 }}>Free Qty</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("rate") && (
                          <th style={{ minWidth: 150 }}>Rate</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("unit_name") && (
                          <th style={{ minWidth: 120 }}>Unit</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("discount") && (
                          <th style={{ minWidth: 80 }}>Discount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("taxable_amount") && (
                          <th style={{ minWidth: 150 }}>Taxable Amount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("cgst") && (
                          <th style={{ minWidth: 100 }}>CGST(%)</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("cgst_amount") && (
                          <th style={{ minWidth: 150 }}>CGST Amount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("sgst") && (
                          <th style={{ minWidth: 100 }}>SGST(%)</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("sgst_amount") && (
                          <th style={{ minWidth: 150 }}>SGST Amount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("igst") && (
                          <th style={{ minWidth: 100 }}>IGST(%)</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("igst_amount") && (
                          <th style={{ minWidth: 150 }}>IGST Amount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("total_gst_with_amount") && (
                          <th style={{ minWidth: 150 }}>Total Amount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("total_taxable_amount") && (
                          <th style={{ minWidth: 150 }}>
                            Total Taxable Amount
                          </th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("total_cgst") && (
                          <th style={{ minWidth: 150 }}>Total CGST Amount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("total_sgst") && (
                          <th style={{ minWidth: 150 }}>Total SGST Amount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("total_igst") && (
                          <th style={{ minWidth: 150 }}>Total IGST Amount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("packing") && (
                          <th style={{ minWidth: 150 }}>Packing</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("dalali") && (
                          <th style={{ minWidth: 150 }}>Dalali</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("labour") && (
                          <th style={{ minWidth: 150 }}>Labour</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("total_amount") && (
                          <th style={{ minWidth: 150 }}>Grand Amount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("terms_days") && (
                          <th style={{ minWidth: 120 }}>Terms Day</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("due_date") && (
                          <th style={{ minWidth: 120 }}>Due Date</th>
                        )}
                      </tr>
                      {filterGroupedArray &&
                        filterGroupedArray?.length > 0 &&
                        filterGroupedArray?.map((group: any) =>
                          group.map((item: any, index: number) => {
                            const groupKey = `${item?.purchase_guid}`;
                            const isSelected = selectedGroup === groupKey;
                            return (
                              <tr
                                className="text-center"
                                style={{
                                  background: isSelected
                                    ? "rgb(255 198 198 / 53%)"
                                    : "transparent",
                                }}
                                onClick={() =>
                                  handleRowClick(item.purchase_guid)
                                }
                              >
                                {index === 0 && (
                                  <td
                                    rowSpan={group.length}
                                    className="text-start register-edit-column"
                                  >
                                    {item.purchase_no_string}
                                    {isEditAccess && (
                                      <IconButton
                                        type="button"
                                        fillMode="flat"
                                        themeColor={"primary"}
                                        size="small"
                                        ref={isEditBtnRef}
                                        style={{
                                          padding: "15px",
                                          marginLeft: "5px",
                                        }}
                                        onClick={() => {
                                          isEditBtnRef.current = true;
                                          navigate("/purchase/edit", {
                                            state: {
                                              purchase_guid:
                                                item?.purchase_guid,
                                            },
                                          });
                                        }}
                                      >
                                        <FiEdit
                                          className="absolute-position"
                                          style={{
                                            fontSize: "20px",
                                            zIndex: 0,
                                            color: "red",
                                          }}
                                        />
                                      </IconButton>
                                    )}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("invoice_no") &&
                                  index === 0 && (
                                    <td
                                      rowSpan={group.length}
                                      className="text-start"
                                    >
                                      {item.invoice_no}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("invoice_date") &&
                                  index === 0 && (
                                    <td rowSpan={group.length}>
                                      {item.invoice_date
                                        ? moment(
                                            item.invoice_date,
                                            "DD/MM/YYYY"
                                          )?.format("DD MMM YYYY")
                                        : ""}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("inward_no") &&
                                  index === 0 && (
                                    <td rowSpan={group.length}>
                                      {item.inward_no}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("inward_date") &&
                                  index === 0 && (
                                    <td rowSpan={group.length}>
                                      {item.inward_date
                                        ? moment(
                                            item.inward_date,
                                            "DD/MM/YYYY"
                                          )?.format("DD MMM YYYY")
                                        : ""}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("po_no") &&
                                  index === 0 && (
                                    <td rowSpan={group.length}>{item.po_no}</td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("po_date") &&
                                  index === 0 && (
                                    <td rowSpan={group.length}>
                                      {item.po_date}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("account_name") &&
                                  index === 0 && (
                                    <td
                                      rowSpan={group.length}
                                      className="text-start"
                                    >
                                      {item.account_name}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("pan_no") &&
                                  index === 0 && (
                                    <td
                                      rowSpan={group.length}
                                      className="text-start"
                                    >
                                      {item.pan_no}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("gst_no") &&
                                  index === 0 && (
                                    <td
                                      rowSpan={group.length}
                                      className="text-start"
                                    >
                                      {item.gst_no}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("product_name") && (
                                  <td className="text-start">
                                    {item?.product_name}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("quantity") && (
                                  <td className="text-end">
                                    {formatIndianNumberForQty(item?.quantity) ||
                                      0}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("free_quantity") && (
                                  <td className="text-end">
                                    {formatIndianNumberForQty(
                                      item?.free_quantity
                                    ) || 0}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("rate") && (
                                  <td className="text-end">
                                    {formatIndianNumber(item?.rate || 0)}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("unit_name") && (
                                  <td>{item?.unit_code}</td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("discount") && (
                                  <td className="text-end">
                                    {item?.discount ? item?.discount : ""}
                                    {item.discount &&
                                      item.discount_type &&
                                      (item?.discount_type === 1
                                        ? " ₹"
                                        : item?.discount_type === 2
                                        ? " %"
                                        : "")}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("taxable_amount") && (
                                  <td className="text-end">
                                    {formatIndianNumber(
                                      item?.taxable_amount || 0
                                    )}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("cgst") && (
                                  <td className="text-end">
                                    {item?.cgst ? `${item?.cgst} %` : ""}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("cgst_amount") && (
                                  <td className="text-end">
                                    {formatIndianNumber(item?.cgst_amount)}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("sgst") && (
                                  <td className="text-end">
                                    {item?.sgst ? `${item?.sgst} %` : ""}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("sgst_amount") && (
                                  <td className="text-end">
                                    {formatIndianNumber(item?.sgst_amount)}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("igst") && (
                                  <td className="text-end">
                                    {item?.igst ? `${item?.igst} %` : ""}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("igst_amount") && (
                                  <td className="text-end">
                                    {formatIndianNumber(item?.igst_amount)}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("total_gst_with_amount") && (
                                  <td className="text-end">
                                    {formatIndianNumber(
                                      item?.total_gst_with_amount || 0
                                    )}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("total_taxable_amount") &&
                                  index === 0 && (
                                    <td
                                      rowSpan={group.length}
                                      className="text-end"
                                    >
                                      {formatIndianNumber(
                                        item?.total_taxable_amount || 0
                                      )}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("total_cgst") &&
                                  index === 0 && (
                                    <td
                                      rowSpan={group.length}
                                      className="text-end"
                                    >
                                      {formatIndianNumber(item?.total_cgst)}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("total_sgst") &&
                                  index === 0 && (
                                    <td
                                      rowSpan={group.length}
                                      className="text-end"
                                    >
                                      {formatIndianNumber(item?.total_sgst)}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("total_igst") &&
                                  index === 0 && (
                                    <td
                                      rowSpan={group.length}
                                      className="text-end"
                                    >
                                      {formatIndianNumber(item?.total_igst)}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("packing") &&
                                  index === 0 && (
                                    <td
                                      rowSpan={group.length}
                                      className="text-end"
                                    >
                                      {formatIndianNumber(item?.packing)}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("dalali") &&
                                  index === 0 && (
                                    <td
                                      rowSpan={group.length}
                                      className="text-end"
                                    >
                                      {formatIndianNumber(item?.dalali)}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("labour") &&
                                  index === 0 && (
                                    <td
                                      rowSpan={group.length}
                                      className="text-end"
                                    >
                                      {formatIndianNumber(item?.labour)}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("total_amount") &&
                                  index === 0 && (
                                    <td
                                      rowSpan={group.length}
                                      className="text-end"
                                    >
                                      {formatIndianNumber(item?.total_amount)}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("terms_days") &&
                                  index === 0 && (
                                    <td rowSpan={group.length}>
                                      {item?.terms_days}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("due_date") &&
                                  index === 0 && (
                                    <td rowSpan={group.length}>
                                      {item?.due_date}
                                    </td>
                                  )}
                              </tr>
                            );
                          })
                        )}
                      {groupedArray &&
                        groupedArray?.length > 0 &&
                        isMatchFound && (
                          <tr
                            className="text-center"
                            style={{
                              background: "whitesmoke",
                              fontWeight: 600,
                            }}
                          >
                            <td className="text-start">Total</td>
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("invoice_no") && (
                              <td className="text-start"></td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("invoice_date") && <td></td>}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("inward_no") && <td></td>}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("inward_date") && <td></td>}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("po_no") && <td></td>}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("po_date") && <td></td>}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("account_name") && (
                              <td className="text-start"></td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("pan_no") && (
                              <td className="text-start"></td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("gst_no") && (
                              <td className="text-start"></td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("product_name") && (
                              <td className="text-start"></td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("quantity") && (
                              <td className="text-end">
                                {formatIndianNumberForQty(totalQty) || 0}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("free_quantity") && (
                              <td className="text-end">
                                {formatIndianNumberForQty(totalFreeQty) || 0}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("rate") && (
                              <td className="text-end"></td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("unit_name") && <td></td>}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("discount") && (
                              <td className="text-end"></td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("taxable_amount") && (
                              <td className="text-end">
                                {formatIndianNumber(totalTaxableAmount)}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("cgst") && (
                              <td className="text-end"></td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("cgst_amount") && (
                              <td className="text-end">
                                {formatIndianNumber(totalCGSTAmount)}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("sgst") && (
                              <td className="text-end"></td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("sgst_amount") && (
                              <td className="text-end">
                                {formatIndianNumber(totalSGSTAmount)}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("igst") && (
                              <td className="text-end"></td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("igst_amount") && (
                              <td className="text-end">
                                {formatIndianNumber(totalIGSTAmount)}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("total_gst_with_amount") && (
                              <td className="text-end">
                                {formatIndianNumber(totalGSTWithAmount)}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("total_taxable_amount") && (
                              <td className="text-end">
                                {formatIndianNumber(totalGrandTaxableAmount)}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("total_cgst") && (
                              <td className="text-end">
                                {formatIndianNumber(GrandtotalCGSTAmount)}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("total_sgst") && (
                              <td className="text-end">
                                {formatIndianNumber(GrandtotalSGSTAmount)}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("total_igst") && (
                              <td className="text-end">
                                {formatIndianNumber(GrandtotalIGSTAmount)}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("packing") && (
                              <td className="text-end">
                                {formatIndianNumber(totalPackingAmount)}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("dalali") && (
                              <td className="text-end">
                                {formatIndianNumber(totalDalaliAmount)}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("labour") && (
                              <td className="text-end">
                                {formatIndianNumber(totalLabourAmount)}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("total_amount") && (
                              <td className="text-end">
                                {formatIndianNumber(GrandTotalAmount)}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("terms_days") && <td></td>}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("due_date") && <td></td>}
                          </tr>
                        )}
                    </table>
                  </GridLayoutItem>
                </GridLayout>
              </FormElement>
            );
          }}
        />
      ) : (
        <AlertBox />
      )}
    </>
  );
};
export default PurchaseRegisterReportForm;
