import React, { useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import FormTextField from "../../components/formFields/FormTextField";
import FormDatePicker from "../../components/formFields/FormDateField";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ShadowCard from "../../components/common/ShadowCard";
import { Button } from "@progress/kendo-react-buttons";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { LoadingPanel } from "../../components/layout/Loading";
import { clearProductCleaningDetails } from "./productCleaningSlice";
import {
  createProductCleaningNew,
  getProductCleaningByIDNew,
  updateProductCleaningNew,
} from "./services/productCleaning.services";
import { IMachine } from "../machine/machineModel";
import FormTimePicker from "../../components/formFields/FormTimeField";
import { findAllActiveMachine } from "../machine/services/machine.services";
import { IProcess } from "../process/processModel";
import { findAllActiveProcess } from "../process/services/process.services";
import moment from "moment";
import { IUser } from "../user/userModel";
import { getAllActiveUsers } from "../user/services/user.services";
import { clearItemList } from "../Item/itemSlice";
import { getAllItemIncremental } from "../Item/services/item.services";
import {
  formatIndianNumberForQty,
  getLocalStorageItem,
} from "../../_helper/helper";
import { FINANCIAL_YEAR } from "../../_contstants/common";
import PCItemDetailsArray from "./PCItemDetailsArray";
import { getCurrentStock } from "../stocktransfer/services/stockTransfer.services";
import { getGRNByItemID } from "../inwardreturn/services/inwardReturn.services";
import FormTextArea from "../../components/formFields/FormTextArea";
import { ErrorToast } from "../../components/toast/Toasts";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const QtyPercentageWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const total_qty = formRenderProps.valueGetter("total_qty");
  const rejection_qty = formRenderProps.valueGetter("rejection_qty");

  useEffect(() => {
    const lossPercentage = (rejection_qty / total_qty) * 100;
    formRenderProps.onChange("cleaning_loss_percentage", {
      value: `${lossPercentage ? lossPercentage?.toFixed(2) : 0} %`,
    });
  }, [total_qty, rejection_qty]);

  return null;
};

const QtychangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const total_qty = formRenderProps.valueGetter("total_qty");
  const received_qty = formRenderProps.valueGetter("received_qty");
  const rejection_qty = formRenderProps.valueGetter("rejection_qty");

  useEffect(() => {
    formRenderProps.onChange("rejection_qty", {
      value: (total_qty - received_qty)?.toFixed(3),
    });
  }, [total_qty, received_qty]);

  if (rejection_qty < 0) {
    formRenderProps.onChange("rejection_qty", {
      value: 0,
    });
  }

  return null;
};

const TakenQtyWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const product_cleaning_items = formRenderProps.valueGetter(
    "product_cleaning_items"
  );
  let totalQty = 0;
  let totalLossQty = 0;

  useEffect(() => {
    if (product_cleaning_items && product_cleaning_items.length > 0) {
      product_cleaning_items?.map((item: any, index: number) => {
        totalQty += +item?.taken_qty || 0;
        totalLossQty += +item?.wastage_qty || 0;

        const TakenQTY = item.taken_qty;
        const WastageQTY = item.wastage_qty;
        if (WastageQTY > TakenQTY) {
          formRenderProps.onChange(
            `product_cleaning_items.${index}.wastage_qty`,
            {
              value: TakenQTY,
            }
          );
        }

        const lossPercentage = (WastageQTY / TakenQTY) * 100;
        formRenderProps.onChange(
          `product_cleaning_items.${index}.loss_percentage`,
          {
            value: `${lossPercentage ? lossPercentage?.toFixed(2) : 0} %`,
          }
        );
      });
    }

    formRenderProps.onChange("total_qty", {
      value: totalQty,
    });
    formRenderProps.onChange("total_loss_qty", {
      value: totalLossQty,
    });
  }, [
    product_cleaning_items?.map((item: any) => item?.taken_qty).join("-"),
    product_cleaning_items?.map((item: any) => item?.wastage_qty).join("-"),
  ]);

  return null;
};

const InnerItemChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const product_cleaning_items = formRenderProps.valueGetter(
    "product_cleaning_items"
  );
  const isFetchGrnOption = React.useRef(true);
  const product_cleaning_guid = location.state?.product_cleaning_guid;

  const fetchGRNOptions = useCallback(
    async (index: number, item: any) => {
      if (item?.item_id) {
        const grnPayload = {
          item_id: item.item_id,
          process_id: 1,
          warehouse_id: 1,
        };
        const response = await dispatch(getGRNByItemID(grnPayload));
        const result =
          response.payload
            ?.filter((grn: any) => grn.item_id === item.item_id)
            .map((grnno: any) => ({
              value: `${grnno?.grn_no}`,
              label: `${grnno?.grn_no} - ${formatIndianNumberForQty(
                grnno?.current_stock || 0
              )}`,
            })) || [];
        formRenderProps.onChange(
          `product_cleaning_items.${index}.grnNoOptions`,
          {
            value: result,
          }
        );
      } else {
        formRenderProps.onChange(
          `product_cleaning_items.${index}.grnNoOptions`,
          {
            value: [],
          }
        );
        formRenderProps.onChange(`product_cleaning_items.${index}.grn_no`, {
          value: null,
        });
      }
    },
    [dispatch, formRenderProps]
  );

  const fetchCurrentStock = useCallback(
    async (index: number, item: any) => {
      if (item?.item_id && item?.grn_no) {
        const payload = {
          item_id: +item.item_id,
          grn_no: item.grn_no,
          process_id: 1,
          warehouse_id: 1,
        };
        const response = await dispatch(getCurrentStock(payload));
        formRenderProps.onChange(
          `product_cleaning_items.${index}.current_qty`,
          {
            value: response.payload?.actual_stock || 0,
          }
        );
        formRenderProps.onChange(`product_cleaning_items.${index}.dagina`, {
          value:
            `${response.payload?.dagina} - ${response.payload?.inward_date}` ||
            "",
        });
      }
    },
    [dispatch, formRenderProps]
  );

  useEffect(() => {
    if (isFetchGrnOption.current && product_cleaning_guid) {
      product_cleaning_items.forEach((item: any, index: number) => {
        fetchGRNOptions(index, item);
        fetchCurrentStock(index, item);
      });
      isFetchGrnOption.current = false;
    }
  }, [product_cleaning_guid]);

  return null;
};

const UserChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const location = useLocation();
  const UserID = getLocalStorageItem("UserID");
  const product_cleaning_guid = location.state?.product_cleaning_guid;

  useEffect(() => {
    if (!product_cleaning_guid) {
      formRenderProps.onChange("cleaning_checker_by", {
        value: UserID ? +UserID : null,
      });
    }
  }, [UserID]);

  return null;
};

const CreateProductCleaning: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const product_cleaning_guid = location.state?.product_cleaning_guid;
  const loading = useAppSelector((state) => state.productCleaning.loading);
  const ProductCleaningDetail = useAppSelector(
    (state) => state.productCleaning.ProductCleaningDetail
  );
  const MachineList = useAppSelector((state) => state.machine.MachineList);
  const ProcessList = useAppSelector((state) => state.process.ProcessList);
  const UserList = useAppSelector((state) => state.user.UserList);
  const [formKey, setFormKey] = React.useState(1);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [ProductCleaningDetail]);

  useEffect(() => {
    dispatch(findAllActiveMachine());
    dispatch(findAllActiveProcess());
    dispatch(getAllActiveUsers());

    return () => {
      dispatch(clearProductCleaningDetails());
      dispatch(clearItemList());
    };
  }, []);

  useEffect(() => {
    if (product_cleaning_guid) {
      const payload = {
        product_cleaning_guid: product_cleaning_guid,
      };
      dispatch(getProductCleaningByIDNew(payload));
    }
  }, [product_cleaning_guid]);

  const handleItemSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllItemIncremental({ search }));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = async (values: any) => {
    if (values?.rejection_qty !== values?.total_loss_qty?.toFixed(3)) {
      ErrorToast("Cleaning loss quantity must be same.");
    } else {
      if (product_cleaning_guid) {
        try {
          const updatePayload = {
            product_cleaning_guid: ProductCleaningDetail?.product_cleaning_guid,
            cleaning_date: values?.cleaning_date
              ? moment(values?.cleaning_date).format("YYYY-MM-DD")
              : "",
            cleaning_no: values?.cleaning_no ? +values?.cleaning_no : null,
            // item_id: values?.item_id ? +values?.item_id : null,
            // grn_no: values?.grn_no ? values?.grn_no : "",
            no_of_workers: values?.no_of_workers
              ? +values?.no_of_workers
              : null,
            machine_id: values?.machine_id ? +values?.machine_id : null,
            start_time: values?.start_time
              ? moment(values?.start_time).format("HH:mm:ss")
              : "",
            end_time: values?.end_time
              ? moment(values?.end_time).format("HH:mm:ss")
              : "",
            total_qty: values?.total_qty ? +values?.total_qty : 0,
            received_qty: values?.received_qty ? +values?.received_qty : 0,
            rejection_qty: values?.rejection_qty ? +values?.rejection_qty : 0,
            cleaning_loss_percentage: values.cleaning_loss_percentage
              ? +values.cleaning_loss_percentage?.replace("%", "")
              : null,
            cleaning_checker_by: values?.cleaning_checker_by
              ? +values?.cleaning_checker_by
              : null,
            financial_year: FINANCIAL_YEAR,
            // department_id: values?.department_id ? +values?.department_id : null,
            process_id: values?.process_id ? +values?.process_id : null,
            remarks: values?.remarks ? values?.remarks : "",
            product_cleaning_items: values?.product_cleaning_items
              ? values?.product_cleaning_items?.map((pcitem: any) => {
                  return {
                    id: pcitem?.id ? +pcitem?.id : 0,
                    item_id: pcitem?.item_id ? +pcitem?.item_id : null,
                    grn_no: pcitem?.grn_no ? pcitem?.grn_no : "",
                    taken_qty: pcitem?.taken_qty ? +pcitem?.taken_qty : null,
                    wastage_qty: pcitem?.wastage_qty
                      ? +pcitem?.wastage_qty
                      : null,
                    loss_percentage: pcitem?.loss_percentage
                      ? +pcitem?.loss_percentage?.replace("%", "")
                      : null,
                  };
                })
              : [],
          };
          const response = await dispatch(
            updateProductCleaningNew(updatePayload)
          );
          if (response?.meta?.requestStatus === "fulfilled") {
            navigate("/productcleaning");
          }
        } catch (error) {
          console.error("Error in handleSubmit:", error);
          throw error;
        }
      } else {
        try {
          const insertPayload = {
            cleaning_date: values?.cleaning_date
              ? moment(values?.cleaning_date).format("YYYY-MM-DD")
              : "",
            cleaning_no: values?.cleaning_no ? +values?.cleaning_no : null,
            // item_id: values?.item_id ? +values?.item_id : null,
            // grn_no: values?.grn_no ? values?.grn_no : "",
            no_of_workers: values?.no_of_workers
              ? +values?.no_of_workers
              : null,
            machine_id: values?.machine_id ? +values?.machine_id : null,
            start_time: values?.start_time
              ? moment(values?.start_time).format("HH:mm:ss")
              : "",
            end_time: values?.end_time
              ? moment(values?.end_time).format("HH:mm:ss")
              : "",
            total_qty: values?.total_qty ? +values?.total_qty : 0,
            received_qty: values?.received_qty ? +values?.received_qty : 0,
            rejection_qty: values?.rejection_qty ? +values?.rejection_qty : 0,
            cleaning_loss_percentage: values.cleaning_loss_percentage
              ? +values.cleaning_loss_percentage?.replace("%", "")
              : null,
            cleaning_checker_by: values?.cleaning_checker_by
              ? +values?.cleaning_checker_by
              : null,
            financial_year: FINANCIAL_YEAR,
            // department_id: values?.department_id ? +values?.department_id : null,
            process_id: values?.process_id ? +values?.process_id : null,
            remarks: values?.remarks ? values?.remarks : "",
            product_cleaning_items: values?.product_cleaning_items
              ? values?.product_cleaning_items?.map((pcitem: any) => {
                  return {
                    item_id: pcitem?.item_id ? +pcitem?.item_id : null,
                    grn_no: pcitem?.grn_no ? pcitem?.grn_no : "",
                    taken_qty: pcitem?.taken_qty ? +pcitem?.taken_qty : null,
                    wastage_qty: pcitem?.wastage_qty
                      ? +pcitem?.wastage_qty
                      : null,
                    loss_percentage: pcitem?.loss_percentage
                      ? +pcitem?.loss_percentage?.replace("%", "")
                      : null,
                  };
                })
              : [],
          };
          const response = await dispatch(
            createProductCleaningNew(insertPayload)
          );
          if (response?.meta?.requestStatus === "fulfilled") {
            navigate("/productcleaning");
          }
        } catch (error) {
          console.error("Error in handleSubmit:", error);
          throw error;
        }
      }
    }
  };

  const handleProductCleaningNoChange = (
    ev: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (ev.key === "Enter" || ev.key === "Tab") {
      ev.preventDefault();
      if ((ev.target as HTMLInputElement).value) {
        const payload = {
          cleaning_no: (ev.target as HTMLInputElement).value,
          financial_year: FINANCIAL_YEAR,
        };
        dispatch(getProductCleaningByIDNew(payload));
      }
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={ProductCleaningDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>
                    {product_cleaning_guid
                      ? "Update Product Cleaning"
                      : "Add Product Cleaning"}
                  </Typography.h4>
                </GridLayoutItem>
                {product_cleaning_guid && (
                  <GridLayoutItem>
                    <Field
                      name="cleaning_no"
                      onKeyDown={handleProductCleaningNoChange}
                      label="Product Cleaning No"
                      placeholder="Product Cleaning No"
                      component={FormTextField}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                )}
                {product_cleaning_guid && (
                  <GridLayoutItem colSpan={3}></GridLayoutItem>
                )}
                <GridLayoutItem>
                  <Field
                    name="cleaning_date"
                    label="Cleaning Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                {/* <GridLayoutItem>
                  <Field
                    name="item_id"
                    label="Product Name"
                    placeholder="Product Name"
                    component={FormIncrementalSearch}
                    validator={requiredValidator}
                    astrike={true}
                    loading={itemLoading}
                    fetchIncrementalData={(search: any) =>
                      handleItemSearchChange(
                        search,
                        `item_options`,
                        formRenderProps
                      )
                    }
                    options={
                      formRenderProps
                        .valueGetter("item_options")
                        ?.map((item: any) => {
                          return {
                            value: item.id,
                            label: item.product_name,
                          };
                        }) || []
                    }
                  />
                </GridLayoutItem> */}
                {/* <GridLayoutItem
                // style={{ display: "flex", gap: 10, alignItems: "start" }}
                >
                  <Field
                    // wrapperStyle={{ width: "50%" }}
                    name="grn_no"
                    label="GRN No"
                    placeholder="GRN No"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    // options={grnOptions || []}
                    options={formRenderProps.valueGetter("grnNoOptions") || []}
                  />
                </GridLayoutItem> */}
                {/* <GridLayoutItem>
                  <Field
                    wrapperClassName="stock-label center-alighned-field d-flex flex-wrap justify-content-center"
                    wrapperStyle={{ color: "red" }}
                    name="current_stock"
                    label="Current Stock"
                    disabled={true}
                    placeholder="0"
                    component={FormNumericTextField}
                  />
                </GridLayoutItem> */}
                <GridLayoutItem>
                  <Field
                    name="machine_id"
                    label="Machine No"
                    placeholder="Machine No"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    options={MachineList?.map((machine: IMachine) => {
                      return {
                        value: machine?.id,
                        label: machine?.machine_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperStyle={{ width: "100%" }}
                    name="start_time"
                    label="Start Time"
                    component={FormTimePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperStyle={{ width: "100%" }}
                    name="end_time"
                    label="End Time"
                    component={FormTimePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    name="no_of_workers"
                    label="No Of Workers"
                    type="number"
                    placeholder="0"
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                {/* <GridLayoutItem>
                  <Field
                    wrapperClassName="w-100 right-alighned-field"
                    name="total_qty"
                    label="Total Qty (KG)"
                    placeholder="0"
                    format="n3"
                    min={0}
                    component={FormNumericTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="w-100 right-alighned-field"
                    name="received_qty"
                    label="Received Qty"
                    placeholder="0"
                    format="n3"
                    min={0}
                    max={formRenderProps.valueGetter("total_qty")}
                    component={FormNumericTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="w-100 center-alighned-field red-highlight-field d-flex flex-column align-items-center"
                    name="rejection_qty"
                    label="Cleaning Loss Qty"
                    format="n3"
                    placeholder="0"
                    disabled={true}
                    component={FormNumericTextField}
                  />
                </GridLayoutItem> */}
                <GridLayoutItem>
                  <Field
                    name="process_id"
                    label="Material Issued to Department"
                    placeholder="Material Issued to Department"
                    component={FormSelectionField}
                    options={ProcessList?.map((process: IProcess) => {
                      return {
                        value: process?.id,
                        label: process?.process_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="cleaning_checker_by"
                    label="Cleaning Checker By"
                    placeholder="Checker Name"
                    component={FormSelectionField}
                    options={UserList?.map((user: IUser) => {
                      return {
                        value: user?.id,
                        label: `${user?.first_name} ${user?.last_name}`,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}>
                  <Field
                    name="remarks"
                    label="Remarks"
                    placeholder="Remarks"
                    component={FormTextArea}
                    rows={2}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 10, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>Item Details</Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem colSpan={4}>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    component={PCItemDetailsArray}
                    handleItemSearchChange={handleItemSearchChange}
                    name="product_cleaning_items"
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={4}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                  }}
                >
                  <div>
                    <ButtonWithLoading
                      label={product_cleaning_guid ? "Update" : "Save"}
                      type="submit"
                      disabled={
                        !formRenderProps.allowSubmit || loading
                        // ||
                        // formRenderProps.valueGetter("rejection_qty") !==
                        //   formRenderProps
                        //     .valueGetter("total_loss_qty")
                        //     ?.toFixed(3)
                      }
                      loading={loading}
                    />
                    <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginLeft: 4 }}
                      onClick={() => navigate("/productcleaning")}
                    >
                      Cancel
                    </Button>
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <QtyPercentageWatcher formRenderProps={formRenderProps} />
            <QtychangeWatcher formRenderProps={formRenderProps} />
            <TakenQtyWatcher formRenderProps={formRenderProps} />
            <InnerItemChangeWatcher formRenderProps={formRenderProps} />
            <UserChangeWatcher formRenderProps={formRenderProps} />
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateProductCleaning;
