import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { FiEdit } from "react-icons/fi";
import { LoadingPanel } from "../../components/layout/Loading";
import IconButton from "../../components/common/IconButton";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { MdDelete } from "react-icons/md";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import {
  deleteProduction,
  getAllProductions,
  getProductionByID,
} from "./services/production.services";
import {
  clearFilterProduction,
  clearProductionDetails,
  clearProductionID,
  setFilterProduction,
  setProductionID,
} from "./productionSlice";
import { setCurrentPage, setPageLimit } from "../pagination/paginationSlice";
import {
  checkAcessRights,
  formatIndianNumberForQty,
} from "../../_helper/helper";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  INSERT_ACCESS,
} from "../../_contstants/common";
import moment from "moment";
import { clearItemList } from "../Item/itemSlice";
import _debounce from "lodash/debounce";
import { filterClearIcon } from "@progress/kendo-svg-icons";
import { TextBox } from "@progress/kendo-react-inputs";
import AlertBox from "../../components/common/AlertBox";
import { FaEye } from "react-icons/fa";

const Production: React.FC = () => {
  const navigate = useNavigate();
  const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);

  const handleCreate = () => {
    navigate("/production/create");
  };

  return (
    <>
      <DeleteProductionDialog />
      <ProductionChildItemDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Production List
          </Typography.h4>
          {isAddAccess && (
            <Button
              onClick={handleCreate}
              fillMode={"solid"}
              themeColor={"primary"}
            >
              {"Add New"}
            </Button>
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <ProductionGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

const ProductionGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const isEditBtnRef = useRef<any>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);

  const ProductionList = useAppSelector(
    (state) => state.production.ProductionList
  );
  const loading = useAppSelector((state) => state.production.loading);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterProduction = useAppSelector(
    (state) => state.production.FilterProduction
  );

  useEffect(() => {
    return () => {
      dispatch(clearItemList());
      dispatch(setCurrentPage(0));
      if (!isEditBtnRef.current) {
        dispatch(clearFilterProduction());
      }
    };
  }, []);

  useEffect(() => {
    const payload = {
      ...FilterProduction,
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };
    dispatch(getAllProductions(payload));
  }, [currentPage, pageLimit]);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);

  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEdit = (Production_guid: any) => {
      navigate("/production/edit", {
        state: { Production_guid: Production_guid },
      });
    };
    const handleOpenDeleteDialog = (Production_guid: any) => {
      dispatch(openDialog("deleteProduction"));
      dispatch(setProductionID(Production_guid));
    };
    const handleItemView = async (Production_guid: any) => {
      const res = await dispatch(getProductionByID(Production_guid));
      if (res.meta.requestStatus === "fulfilled") {
        dispatch(openDialog("ProductionItemView"));
      }
    };

    return (
      <td
        className={`${props?.className}`}
        style={{
          ...props.style,
          display: "flex",
          justifyContent: "center",
        }}
      >
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            ref={isEditBtnRef}
            onClick={() => {
              isEditBtnRef.current = true;
              handleEdit(props.dataItem?.Production_guid);
            }}
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() =>
              handleOpenDeleteDialog(props.dataItem?.Production_guid)
            }
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="View"
          onClick={() => handleItemView(props.dataItem?.Production_guid)}
        >
          <FaEye className="absolute-position" style={{ fontSize: "20px" }} />
        </IconButton>
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 7 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      <KendoGrid
        style={{ maxHeight: "calc(100vh - 152px)" }}
        className="responsive-table"
        filterable={true}
        filter={filter}
        data={filter ? filterBy(ProductionList, filter) : ProductionList}
        onFilterChange={handleFilterChange}
        total={ProductionList[0]?.itemcount || ProductionList.length}
        skip={currentPage * pageLimit}
        take={pageLimit}
        pageable={{
          buttonCount: 5,
          pageSizes: [5, 10, 50, 100, 300, 500],
          pageSizeValue: pageLimit,
          type: "input",
        }}
        onPageChange={pageChange}
        cells={{
          headerCell: HeaderCustomCell,
          data: MyDataCustomCell,
        }}
      >
        <Column
          field="production_no"
          title="Production No"
          width={150}
          cell={(props: any) => (
            <td>
              <div
                className={isEditAccess ? "hoverableName" : "normalName"}
                onClick={() =>
                  isEditAccess &&
                  navigate("/production/edit", {
                    state: {
                      Production_guid: props.dataItem?.Production_guid,
                    },
                  })
                }
              >
                {props.dataItem?.production_no}
              </div>
            </td>
          )}
        />
        <Column
          field="start_date"
          title="Production Date"
          filterCell={CommonFilterCell}
          width={150}
          cell={(props) => (
            <td>
              {props.dataItem?.start_date
                ? moment(props.dataItem?.start_date, "YYYY-MM-DD").format(
                    "DD/MM/YYYY"
                  )
                : ""}
            </td>
          )}
        />
        <Column
          field="product_name"
          title="Product Name"
          width={250}
          filterCell={CommonFilterCell}
        />
        <Column
          field="lot_no"
          title="Lot No"
          width={150}
          cell={(props: any) => (
            <td className="text-end">{props.dataItem?.lot_no}</td>
          )}
        />
        <Column
          field="no_of_workers"
          title="No Of Workers"
          width={150}
          cell={(props: any) => (
            <td className="text-end">{props.dataItem?.no_of_workers}</td>
          )}
        />
        <Column
          field="start_time"
          title="Start Time"
          width={200}
          // filterCell={CommonFilterCell}
          cell={(props: any) => (
            <td>
              {props.dataItem?.start_time}
              {props.dataItem?.start_time && props.dataItem?.end_time && " - "}
              {props.dataItem?.end_time}
            </td>
          )}
        />
        <Column field="supervisor_name" title="Supervisor Name" width={200} />
        {(isEditAccess || isDeleteAccess) && (
          <Column
            field="ID"
            title="Actions"
            cell={MyEditCommandCell}
            width={"140px"}
            locked={true}
            filterable={false}
          />
        )}
      </KendoGrid>
    </>
  );
};

const DeleteProductionDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const ProductionID = useAppSelector((state) => state.production.ProductionID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterProduction = useAppSelector(
    (state) => state.production.FilterProduction
  );

  const handleDeleteAction = async (ID: any) => {
    if (ID) {
      const response = await dispatch(deleteProduction(ID));
      dispatch(setCurrentPage(0));
      const payload = {
        ...FilterProduction,
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      if (response.payload.Data.Status === 200) {
        dispatch(getAllProductions(payload));
        dispatch(closeDialog());
        dispatch(clearProductionID());
      } else {
        dispatch(getAllProductions(payload));
        dispatch(closeDialog());
        dispatch(clearProductionID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteProduction" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Production"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Production?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(ProductionID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

const CommonFilterCell = (props: any) => {
  const dispatch = useAppDispatch();
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterProduction: any = useAppSelector(
    (state) => state.production.FilterProduction
  );
  const isFilterRef = useRef(true);
  const [inputValue, setInputValue] = useState(props.value || "");
  const [inputField, setInputField] = useState(props.field || "");

  const onChange = (event: any) => {
    setInputValue(event.target.value);
    setInputField(event.target.name);
  };

  const delayedApiCall = React.useCallback(
    _debounce((updatedFilters: any) => {
      dispatch(setCurrentPage(0));
      const payload = {
        ...updatedFilters,
        limit: +pageLimit,
        skip: 0 * +pageLimit,
      };

      dispatch(getAllProductions(payload));
    }, 300),
    [dispatch]
  );

  useEffect(() => {
    if (!isFilterRef.current) {
      const updatedFilters = {
        ...FilterProduction,
        [inputField]: inputValue,
      };
      dispatch(setFilterProduction(updatedFilters));
      delayedApiCall(updatedFilters);
    } else {
      isFilterRef.current = false;
    }
    return () => {
      delayedApiCall.cancel();
    };
  }, [inputValue, delayedApiCall]);

  const onClearButtonClick = (event: any) => {
    event.preventDefault();
    props.onChange({
      value: null,
      operator: "",
      syntheticEvent: event,
    });

    const updatedFilters = { ...FilterProduction, [props.field]: "" };
    dispatch(setFilterProduction(updatedFilters));

    const payload = {
      ...updatedFilters,
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };

    dispatch(getAllProductions(payload));
  };

  return (
    <div className="d-flex">
      <TextBox
        value={FilterProduction[props.field] || ""}
        name={props.field || ""}
        onChange={onChange}
      />
      <Button
        svgIcon={filterClearIcon}
        title="Clear"
        disabled={!FilterProduction[props.field]}
        onClick={onClearButtonClick}
        type="button"
        style={{
          marginLeft: "8px",
        }}
      ></Button>
    </div>
  );
};

const ProductionChildItemDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const ProductionDetail = useAppSelector(
    (state) => state.production.ProductionDetail
  );
  const loading = useAppSelector((state) => state.production.loading);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" || event.key === "Esc") {
        dispatch(clearProductionDetails());
      }
    };
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearProductionDetails());
    };
  }, []);

  let totalUsedQty = 0;
  let totalBOMQty = 0;

  ProductionDetail &&
    ProductionDetail?.ProductionItemDetails &&
    ProductionDetail?.ProductionItemDetails.length > 0 &&
    ProductionDetail?.ProductionItemDetails?.forEach((item: any) => {
      totalUsedQty += item?.used_qty || 0;
      totalBOMQty += item?.bom_qty || 0;
    });

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {dialogName === "ProductionItemView" && (
        <AppDialog className="inward-items-dialog">
          <>
            <Typography.h5>{"Production Items"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                {ProductionDetail &&
                ProductionDetail?.ProductionItemDetails &&
                ProductionDetail?.ProductionItemDetails.length > 0 ? (
                  <div
                    className="table-responsive m-0"
                    style={{
                      maxHeight: "calc(100vh - 227px)",
                      scrollbarWidth: "thin",
                    }}
                  >
                    <table className="table table-bordered m-0">
                      <thead>
                        <tr>
                          <th style={{ background: "black", color: "white" }}>
                            No
                          </th>
                          <th
                            style={{
                              background: "black",
                              color: "white",
                              minWidth: 250,
                            }}
                          >
                            Item
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Process Name
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            GRN No
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            BOM Qty
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Used Qty
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {ProductionDetail?.ProductionItemDetails?.map(
                          (item: any, index: number) => (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{item?.product_name}</td>
                              <td>{item?.Process_name}</td>
                              <td>{item?.grn_no}</td>
                              <td className="text-end">
                                {formatIndianNumberForQty(item?.bom_qty || 0)}
                              </td>
                              <td className="text-end">
                                {formatIndianNumberForQty(item?.used_qty || 0)}
                              </td>
                            </tr>
                          )
                        )}
                        <tr className="fw-bold">
                          <td
                            colSpan={4}
                            className="text-end pe-5"
                            style={{
                              background: "lightgray",
                              color: "black",
                            }}
                          >
                            Total
                          </td>
                          <td
                            style={{
                              textAlign: "end",
                              background: "lightgray",
                              color: "black",
                            }}
                          >
                            {formatIndianNumberForQty(totalBOMQty)}
                          </td>
                          <td
                            style={{
                              textAlign: "end",
                              background: "lightgray",
                              color: "black",
                            }}
                          >
                            {formatIndianNumberForQty(totalUsedQty)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <AlertBox style={{ marginBottom: 10 }} />
                )}
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: 20,
                }}
              >
                <Button
                  onClick={() => {
                    dispatch(closeDialog());
                    dispatch(clearProductionDetails());
                  }}
                  themeColor="error"
                  fillMode={"outline"}
                >
                  Close
                </Button>
                {/* <DialogCloseButton themeColor="error" /> */}
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default Production;
