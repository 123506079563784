import React, { useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import ShadowCard from "../../components/common/ShadowCard";
import FormDatePicker from "../../components/formFields/FormDateField";
import {
  getAccountByID,
  getAllPaymentAndReceipt,
  getAllSPDCIncremental,
} from "../account/services/account.services";
import { getAllItemIncremental } from "../Item/services/item.services";
import { findAllActiveUnit } from "../unit/services/unit.services";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import {
  createDebitNote,
  generateDebitNoteNoForDebitNote,
  getDebitNoteByID,
  updateDebitNote,
} from "./services/debitNote.services";
import {
  clearDebitNoteDetails,
  setDebitNoteStateForGST,
  setGeneratedDebitNoteNo,
} from "./debitNoteSlice";
import { getAllActiveGST } from "../gst/services/gst.services";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import { FINANCIAL_YEAR, GUJARATIDFORGST } from "../../_contstants/common";
import moment from "moment";
import { calculateAmount, calculateGST } from "../../_helper/helper";
import DebitNoteItemDetailsArray from "./DebitNoteItemDetailsArray";
import FormTextArea from "../../components/formFields/FormTextArea";
import { getAllDutyAndTaxLedgeAccount } from "../purchase/services/purchase.services";
import debitNoteDutyAndTaxArray from "./debitNoteDutyAndTaxArray";
import { findAllVoucherTypeByType } from "../vouchertype/services/voucherType.services";
import { ErrorToast } from "../../components/toast/Toasts";
import { sumBy } from "lodash";
import { IVoucherType } from "../vouchertype/voucherTypeModel";
import FormSelectionField from "../../components/formFields/FormSelectionField";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const VendorChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const debit_note_guid = location.state?.debit_note_guid;
  const vendor_id = formRenderProps.valueGetter("vendor_id");
  const isVendorRef = useRef(true);

  useEffect(() => {
    if (vendor_id) {
      const handleVendorChange = async () => {
        const payload = {
          id: vendor_id,
        };
        const response = await dispatch(getAccountByID(payload));
        if (response.meta.requestStatus === "fulfilled") {
          dispatch(setDebitNoteStateForGST(response.payload?.state_id));
          formRenderProps.onChange("state_id", {
            value: response.payload?.state_id,
          });
        } else {
          formRenderProps.onChange("state_id", {
            value: null,
          });
        }
      };
      if (!isVendorRef.current) {
        handleVendorChange();
      } else {
        if (!debit_note_guid) {
          handleVendorChange();
        }
        isVendorRef.current = false;
      }
    } else {
      dispatch(setDebitNoteStateForGST(null));
      formRenderProps.onChange("state_id", {
        value: "",
      });
    }
  }, [vendor_id]);

  return null;
};
const QtyChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const debit_note_items = formRenderProps.valueGetter("debit_note_items") || 0;

  const debit_note_duty_and_taxes = formRenderProps.valueGetter(
    "debit_note_duty_and_taxes"
  );
  const taxable_amount = formRenderProps.valueGetter("taxable_amount") || 0;
  // const gst = formRenderProps.valueGetter("gst") || 0;
  // const packing = formRenderProps.valueGetter("packing") || 0;
  // const labour = formRenderProps.valueGetter("labour") || 0;
  // const dalali = formRenderProps.valueGetter("dalali") || 0;
  const sub_total = formRenderProps.valueGetter("sub_total") || 0;
  const round_off = formRenderProps.valueGetter("round_off") || 0;
  const DebitNoteStateForGST = useAppSelector(
    (state) => state.debitnote.DebitNoteStateForGST
  );
  const AccountPaymentAndReceiptList = useAppSelector(
    (state) => state.account.AccountPaymentAndReceiptList
  );

  const UnitList = useAppSelector((state) => state.unit.UnitList);

  useEffect(() => {
    let totalGSTamount = 0;
    let totalGST5 = 0;
    let totalGST12 = 0;
    let totalGST18 = 0;
    let totalGST28 = 0;

    debit_note_items?.map((item: any, index: number) => {
      const gst_id = item?.gst_id;
      const amount = item?.amount;
      // const gstid = gst_id ? parseInt(gst_id?.split("--")[1]) : 0;
      const gstid = item?.tax_percentage || 0;

      if (gstid === 5) {
        totalGST5 += (+amount * +gstid) / 100;
      } else if (gstid === 12) {
        totalGST12 += (+amount * +gstid) / 100;
      } else if (gstid === 18) {
        totalGST18 += (+amount * +gstid) / 100;
      } else if (gstid === 28) {
        totalGST28 += (+amount * +gstid) / 100;
      }
      totalGSTamount += (+amount * +gstid) / 100;
      if (!gst_id) {
        formRenderProps.onChange(`debit_note_items.${index}.gst_id`, {
          value: null,
        });
      }
    });
    formRenderProps.onChange("gst", {
      value: totalGSTamount || 0,
    });
    if (DebitNoteStateForGST === GUJARATIDFORGST) {
      formRenderProps.onChange("cgst", {
        value: totalGSTamount / 2 || 0,
      });
      formRenderProps.onChange("sgst", {
        value: totalGSTamount / 2 || 0,
      });
    } else {
      formRenderProps.onChange("igst", {
        value: totalGSTamount || 0,
      });
    }
    formRenderProps.onChange("gst5", {
      value: totalGST5 || 0,
    });
    formRenderProps.onChange("gst12", {
      value: totalGST12 || 0,
    });
    formRenderProps.onChange("gst18", {
      value: totalGST18 || 0,
    });
    formRenderProps.onChange("gst28", {
      value: totalGST28 || 0,
    });

    // Account Implementation Starts
    const gstConfig = {
      2.5: (isGujarat: boolean) => (isGujarat ? totalGST5 / 2 : 0),
      5: (isGujarat: boolean) => (!isGujarat ? totalGST5 : 0),
      6: (isGujarat: boolean) => (isGujarat ? totalGST12 / 2 : 0),
      12: (isGujarat: boolean) => (!isGujarat ? totalGST12 : 0),
      9: (isGujarat: boolean) => (isGujarat ? totalGST18 / 2 : 0),
      18: (isGujarat: boolean) => (!isGujarat ? totalGST18 : 0),
      14: (isGujarat: boolean) => (isGujarat ? totalGST28 / 2 : 0),
      28: (isGujarat: boolean) => (!isGujarat ? totalGST28 : 0),
    };

    debit_note_duty_and_taxes?.map((item: any, index: number) => {
      const legder_id = item?.ledger_id;
      const gst_per = item?.gst_per;
      const ledger_option_data =
        debit_note_duty_and_taxes[index].ledger_options?.filter(
          (tax: any) => tax.id === legder_id
        )?.[0] || [];
      const tax_type = ledger_option_data?.tax_type;
      const type_of_supply = ledger_option_data?.type_of_supply;
      const isGujarat = DebitNoteStateForGST === GUJARATIDFORGST;
      if (legder_id) {
        if (
          ["igst", "cgst", "sgst"]?.includes(tax_type?.toLowerCase()) &&
          gst_per
        ) {
          const gstAmount = gstConfig[gst_per as keyof typeof gstConfig]
            ? gstConfig[gst_per as keyof typeof gstConfig](isGujarat)
            : 0;
          formRenderProps.onChange(
            `debit_note_duty_and_taxes.${index}.gst_amount`,
            {
              value: gstAmount,
            }
          );
        } else if (tax_type?.toLowerCase() === "cess" && gst_per) {
          const applycess = AccountPaymentAndReceiptList?.filter(
            (item: any) => item?.isapplycess
          )?.map((item: any) => item.id);
          const cess_apply_amount = sumBy(
            debit_note_items?.filter((item: any) =>
              applycess.includes(item?.purchase_ledger_id)
            ),
            "amount"
          );
          formRenderProps.onChange(
            `debit_note_duty_and_taxes.${index}.gst_amount`,
            {
              value: (cess_apply_amount * gst_per) / 100,
            }
          );
        } else if (type_of_supply === "Service" && gst_per) {
          formRenderProps.onChange(
            `debit_note_duty_and_taxes.${index}.gst_amount`,
            {
              value: (taxable_amount * gst_per) / 100,
            }
          );
        } else {
          if (gst_per) {
            formRenderProps.onChange(
              `debit_note_duty_and_taxes.${index}.gst_amount`,
              {
                value: (taxable_amount * gst_per) / 100,
              }
            );
          }
        }
      }
    });

    const grandTotals = debit_note_duty_and_taxes?.reduce(
      (acc: Record<string, number>, item: any, index: number) => {
        // const firstWord = item?.ledger_name?.split(" ")[0];
        const firstWord =
          debit_note_duty_and_taxes[index].ledger_options?.filter(
            (tax: any) => tax.id === item?.ledger_id
          )[0]?.tax_type ||
          debit_note_duty_and_taxes[index].ledger_options?.filter(
            (tax: any) => tax.id === item?.ledger_id
          )[0]?.account_name ||
          "";

        acc[firstWord] = (acc[firstWord] || 0) + item?.gst_amount;
        acc["duty_tax_total"] = (acc["duty_tax_total"] || 0) + item?.gst_amount;
        acc["sub_total"] =
          (acc["duty_tax_total"] || 0) + (+taxable_amount || 0);
        return acc;
      },
      {} as Record<string, number>
    );

    formRenderProps.onChange("sub_total", {
      value: grandTotals?.sub_total || 0,
    });

    formRenderProps.onChange("dutytaxtotal", {
      value:
        (
          (grandTotals?.CGST || 0) +
          (grandTotals?.SGST || 0) +
          (grandTotals?.IGST || 0)
        )?.toFixed(2) || 0,
    });

    if (DebitNoteStateForGST === GUJARATIDFORGST) {
      formRenderProps.onChange("cgst", {
        value: grandTotals?.CGST || 0,
      });
      formRenderProps.onChange("sgst", {
        value: grandTotals?.SGST || 0,
      });
      formRenderProps.onChange("gst", {
        value: +(+grandTotals?.CGST + +grandTotals?.SGST)?.toFixed(2) || 0,
      });
    } else {
      formRenderProps.onChange("igst", {
        value: grandTotals?.IGST || 0,
      });
      formRenderProps.onChange("gst", {
        value: +grandTotals?.IGST?.toFixed(2) || 0,
      });
    }
  }, [
    DebitNoteStateForGST,
    taxable_amount,
    // debit_note_items?.map((item: any) => item?.gst_id).join("-"),
    debit_note_items?.map((item: any) => item?.tax_percentage).join("-"),
    debit_note_items?.map((item: any) => item?.amount).join("-"),
    // debit_note_duty_and_taxes?.map((item: any) => item?.ledger_id).join("-"),
    debit_note_duty_and_taxes?.map((item: any) => item?.gst_per).join("-"),
    debit_note_duty_and_taxes?.map((item: any) => item?.gst_amount).join("-"),
  ]);

  useEffect(() => {
    if (debit_note_items && debit_note_items.length > 0) {
      debit_note_items.forEach((item: any, index: number) => {
        const quantity = parseFloat(
          item?.quantity?.toFixed(item?.unit_decimal || 0) || "0"
        );
        const rate = item?.rate || 0;
        const discount = item?.discount || 0;
        const discountType = item?.discount_type || "₹";

        // const gstIdStr = item?.gst_id;
        // const gstid = gstIdStr ? parseInt(gstIdStr.split("--")[1], 10) : 0;
        const gstid = item?.tax_percentage || 0;

        const amount = calculateAmount(quantity, rate, discount, discountType);
        const gstDetails = calculateGST(
          amount,
          gstid,
          DebitNoteStateForGST === GUJARATIDFORGST
        );

        // Update the form fields
        const updates: Record<string, any> = {
          [`debit_note_items.${index}.amount`]: { value: amount },
          [`debit_note_items.${index}.sgst_amount`]: {
            value: gstDetails.sgstAmount,
          },
          [`debit_note_items.${index}.cgst_amount`]: {
            value: gstDetails.cgstAmount,
          },
          [`debit_note_items.${index}.igst_amount`]: {
            value: gstDetails.igstAmount,
          },
          [`debit_note_items.${index}.total_gst_amount`]: {
            value:
              gstDetails.sgstAmount +
              gstDetails.cgstAmount +
              gstDetails.igstAmount,
          },
          [`debit_note_items.${index}.sgst_per`]: { value: gstDetails.sgstPer },
          [`debit_note_items.${index}.cgst_per`]: { value: gstDetails.cgstPer },
          [`debit_note_items.${index}.igst_per`]: { value: gstDetails.igstPer },
        };

        // Apply all updates
        Object.entries(updates).forEach(([key, update]) => {
          formRenderProps.onChange(key, update);
        });
      });
    }
  }, [
    DebitNoteStateForGST,
    // debit_note_items?.map((item: any) => item?.gst_id).join("-"),
    debit_note_items?.map((item: any) => item?.tax_percentage).join("-"),
    debit_note_items.map((item: any) => item?.quantity).join("-"),
    debit_note_items.map((item: any) => item?.rate).join("-"),
    debit_note_items.map((item: any) => item?.discount).join("-"),
    debit_note_items.map((item: any) => item?.discount_type).join("-"),
    debit_note_items.map((item: any) => item?.unit_decimal).join("-"),
  ]);

  let totalamount = 0;
  let totalQty = 0;

  useEffect(() => {
    if (debit_note_items && debit_note_items.length > 0) {
      debit_note_items?.map((item: any) => {
        totalQty += +item?.quantity || 0;
        totalamount += +item?.amount || 0;
      });
    }
    formRenderProps.onChange("totalQty", {
      value: totalQty,
    });
    formRenderProps.onChange("taxable_amount", {
      value: totalamount,
    });
  }, [
    debit_note_items.map((item: any) => item?.quantity).join("-"),
    debit_note_items.map((item: any) => item?.amount).join("-"),
  ]);

  useEffect(() => {
    if (debit_note_items && debit_note_items.length > 0) {
      debit_note_items?.map((item: any, index: number) => {
        const unit_id = item?.unit_id;

        const unit = UnitList.find((unit: any) => unit?.id === unit_id);
        if (unit) {
          formRenderProps.onChange(`debit_note_items.${index}.unit_decimal`, {
            value: unit?.unit_decimal || 0,
          });
        } else {
          formRenderProps.onChange(`debit_note_items.${index}.unit_decimal`, {
            value: 0,
          });
        }
      });
    }
  }, [debit_note_items.map((item: any) => item?.unit_id).join("-")]);

  useEffect(() => {
    formRenderProps.onChange("round_off", {
      value: Math.round(+sub_total) - +sub_total,
    });
    formRenderProps.onChange("grand_total", {
      value: +sub_total + +round_off || 0,
    });
  }, [sub_total, round_off]);

  return null;
};

const DebitNoteNoChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const debit_note_guid = location.state?.debit_note_guid;
  const DebitNoteDetail = useAppSelector(
    (state) => state.debitnote.DebitNoteDetail
  );
  const GeneratedDebitNoteNo = useAppSelector(
    (state) => state.debitnote.GeneratedDebitNoteNo
  );
  const debit_note_no_string = formRenderProps.valueGetter(
    "debit_note_no_string"
  );
  const voucher_type_id = formRenderProps.valueGetter("voucher_type_id");

  useEffect(() => {
    const fetchDebitNoteNo = async (voucher_type_id: number) => {
      const payload = {
        financial_year: FINANCIAL_YEAR,
        voucher_type_id: +voucher_type_id,
      };
      const response = await dispatch(generateDebitNoteNoForDebitNote(payload));
      if (response.meta.requestStatus === "fulfilled") {
        dispatch(
          setGeneratedDebitNoteNo(response.payload?.debit_note_no_string)
        );
        formRenderProps.onChange("debit_note_no_string", {
          value: response.payload?.debit_note_no_string || "",
        });
      }
    };
    if (!debit_note_guid && voucher_type_id) {
      fetchDebitNoteNo(voucher_type_id);
    }
  }, [voucher_type_id]);

  useEffect(() => {
    const updateInvoiveNoDate = async () => {
      const DebitNoteNoString = debit_note_guid
        ? DebitNoteDetail?.debit_note_no_string
        : GeneratedDebitNoteNo;
      const oldkey = DebitNoteNoString?.split("/")?.slice(0, -1).join("/");
      if (
        DebitNoteNoString?.split("/").length ===
          debit_note_no_string?.split("/").length &&
        voucher_type_id
      ) {
        const modifiyed = debit_note_no_string?.split("/")?.pop();
        formRenderProps.onChange("debit_note_no_string", {
          value: `${oldkey}/${modifiyed}`,
        });
        formRenderProps.onChange("debit_note_no", {
          value: modifiyed,
        });
      } else {
        if (voucher_type_id) {
          formRenderProps.onChange("debit_note_no_string", {
            value: `${oldkey}/`,
          });
        } else {
          formRenderProps.onChange("debit_note_no_string", {
            value: "",
          });
        }
        formRenderProps.onChange("debit_note_no", {
          value: null,
        });
      }
    };

    updateInvoiveNoDate();
  }, [debit_note_no_string, voucher_type_id]);

  return null;
};

const CreateDebitNote = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const debit_note_guid = location.state?.debit_note_guid;
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.debitnote.loading);
  const DebitNoteDetail = useAppSelector(
    (state) => state.debitnote.DebitNoteDetail
  );
  const DebitNoteStateForGST = useAppSelector(
    (state) => state.debitnote.DebitNoteStateForGST
  );
  const VoucherTypeList = useAppSelector(
    (state) => state.voucherType.VoucherTypeList
  );

  const [formKey, setFormKey] = React.useState(1);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [DebitNoteDetail]);

  useEffect(() => {
    if (debit_note_guid) {
      const payload = {
        debit_note_guid: debit_note_guid,
      };
      dispatch(getDebitNoteByID(payload));
    }
  }, [debit_note_guid]);

  useEffect(() => {
    const PurchaseAccountPayload = {
      account_name: "",
      under_group_id: "14",
    };
    dispatch(findAllActiveUnit());
    dispatch(getAllActiveGST());
    dispatch(findAllVoucherTypeByType(8));
    dispatch(getAllPaymentAndReceipt(PurchaseAccountPayload));

    return () => {
      dispatch(clearDebitNoteDetails());
    };
  }, []);

  useEffect(() => {
    const fetchAccountDetails = async () => {
      const payload = {
        id: DebitNoteDetail?.vendor_id,
      };
      const response = await dispatch(getAccountByID(payload));
      if (response.meta.requestStatus === "fulfilled") {
        dispatch(setDebitNoteStateForGST(response.payload?.state_id));
      } else {
        dispatch(setDebitNoteStateForGST(null));
      }
    };
    if (DebitNoteDetail?.vendor_id) {
      fetchAccountDetails();
    }
  }, [DebitNoteDetail]);

  const handleItemSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllItemIncremental({ search }));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleLedgerSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllDutyAndTaxLedgeAccount(search));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleVendorSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllSPDCIncremental(search));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = async (values: any) => {
    let totalGST = 0;
    values?.debit_note_items &&
      values?.debit_note_items?.map(
        (purchasereturn: any) =>
          (totalGST +=
            purchasereturn?.cgst_amount +
            purchasereturn?.sgst_amount +
            purchasereturn?.igst_amount)
      );
    if (values?.debit_note_no_string?.endsWith("/")) {
      ErrorToast("Please Enter Purchase Return Number.");
      return;
    }

    if (Math.ceil(+totalGST?.toFixed(2)) !== Math.ceil(+values?.dutytaxtotal)) {
      ErrorToast("Mismatch in Tax!");
    } else {
      const formdata: any = {
        debit_note_date: values?.debit_note_date
          ? moment(values?.debit_note_date).format("YYYY-MM-DD")
          : "",
        voucher_type_id: values?.voucher_type_id
          ? +values?.voucher_type_id
          : null,
        bill_no: values?.bill_no,
        bill_date: values?.bill_date
          ? moment(values?.bill_date).format("YYYY-MM-DD")
          : "",
        debit_note_no_string: values?.debit_note_no_string
          ? values?.debit_note_no_string
          : "",
        debit_note_no: values?.debit_note_no ? +values?.debit_note_no : null,
        vendor_id: values?.vendor_id ? +values?.vendor_id : null,
        total_amount: values?.grand_total ? +values?.grand_total : null,
        gst: values?.gst ? +values?.gst?.toFixed(2) : 0,
        remarks: values?.remarks ? values?.remarks : "",
        financial_year: values?.financial_year
          ? +values?.financial_year
          : FINANCIAL_YEAR,
        purchase_ledger_id:
          //  values?.purchase_ledger_id
          //   ? +values?.purchase_ledger_id
          //   :
          null,
        sub_total: values?.sub_total ? +values?.sub_total?.toFixed(2) : null,
        round_off: values?.round_off ? +values?.round_off?.toFixed(2) : null,
        taxable_amount: values?.taxable_amount
          ? +values?.taxable_amount?.toFixed(2)
          : null,
        debit_note_items: values?.debit_note_items
          ? values?.debit_note_items?.map((debitNoteitem: any) => {
              return {
                id: debitNoteitem?.id ? +debitNoteitem?.id : 0,
                item_id: debitNoteitem?.item_id
                  ? +debitNoteitem?.item_id
                  : null,
                quantity: debitNoteitem?.quantity
                  ? +debitNoteitem?.quantity?.toFixed(
                      debitNoteitem.unit_decimal
                        ? debitNoteitem.unit_decimal
                        : 0
                    )
                  : 0,
                unit_id: debitNoteitem?.unit_id
                  ? +debitNoteitem?.unit_id
                  : null,
                rate: debitNoteitem?.rate ? +debitNoteitem?.rate : null,

                free_quantity: debitNoteitem?.free_quantity
                  ? +debitNoteitem?.free_quantity?.toFixed(
                      debitNoteitem.unit_decimal
                        ? debitNoteitem.unit_decimal
                        : 0
                    )
                  : 0,
                discount_type: debitNoteitem?.discount_type === "%" ? 2 : 1,
                discount: debitNoteitem?.discount
                  ? +debitNoteitem?.discount
                  : null,
                // gst_id: debitNoteitem?.gst_id
                //   ? parseInt(debitNoteitem?.gst_id.split("--")[0])
                //   : null,

                sgst_per: debitNoteitem?.sgst_per
                  ? +debitNoteitem?.sgst_per?.toFixed(2)
                  : 0,
                sgst_amount: debitNoteitem?.sgst_amount
                  ? +debitNoteitem?.sgst_amount?.toFixed(2)
                  : 0,
                cgst_per: debitNoteitem?.cgst_per
                  ? +debitNoteitem?.cgst_per?.toFixed(2)
                  : 0,
                cgst_amount: debitNoteitem?.cgst_amount
                  ? +debitNoteitem?.cgst_amount?.toFixed(2)
                  : 0,
                igst_per: debitNoteitem?.igst_per
                  ? +debitNoteitem?.igst_per?.toFixed(2)
                  : 0,
                igst_amount: debitNoteitem?.igst_amount
                  ? +debitNoteitem?.igst_amount?.toFixed(2)
                  : 0,
                total_gst_amount: debitNoteitem?.total_gst_amount
                  ? +debitNoteitem?.total_gst_amount?.toFixed(2)
                  : 0,
                amount: debitNoteitem?.amount ? +debitNoteitem?.amount : null,
                purchase_ledger_id: debitNoteitem?.purchase_ledger_id
                  ? +debitNoteitem?.purchase_ledger_id
                  : null,
              };
            })
          : [],
        debit_note_duty_and_taxes: values?.debit_note_duty_and_taxes
          ? values.debit_note_duty_and_taxes
              .filter(
                (debitnoteduty: any) =>
                  debitnoteduty?.ledger_id && debitnoteduty?.gst_amount
              )
              .map((debitnoteduty: any) => ({
                ledger_id: +debitnoteduty.ledger_id,
                gst_per: debitnoteduty?.gst_per ? +debitnoteduty.gst_per : null,
                gst_amount: debitnoteduty?.gst_amount
                  ? +debitnoteduty.gst_amount
                  : null,
              }))
          : [],
      };
      if (DebitNoteStateForGST === GUJARATIDFORGST) {
        formdata.cgst = values?.cgst ? +values?.cgst?.toFixed(2) : 0;
        formdata.sgst = values?.sgst ? +values?.sgst?.toFixed(2) : 0;
        formdata.igst = 0;
      } else {
        formdata.igst = values?.igst ? +values?.igst?.toFixed(2) : 0;
        formdata.cgst = 0;
        formdata.sgst = 0;
      }

      if (debit_note_guid) {
        try {
          const updatePayload: any = {
            debit_note_guid: debit_note_guid,
            id: values?.id ? +values?.id : null,
            ...formdata,
          };

          const response = await dispatch(updateDebitNote(updatePayload));
          if (response?.meta?.requestStatus === "fulfilled") {
            navigate(-1);
          }
        } catch (error) {
          console.error("Error in handleSubmit:", error);
          throw error;
        }
      } else {
        try {
          const response = await dispatch(createDebitNote(formdata));
          if (response?.meta?.requestStatus === "fulfilled") {
            navigate("/debitnote");
          }
        } catch (error) {
          console.error("Error in handleSubmit:", error);
          throw error;
        }
      }
    }
  };

  // const handlePurchaseLedgerSearchChange = useCallback(
  //   async (search: string, field: string, formRenderProps: FormRenderProps) => {
  //     const result = await dispatch(getAllPurchaseLedgeAccount(search));
  //     formRenderProps.onChange(field, {
  //       value: result.payload,
  //     });
  //   },
  //   [dispatch]
  // );

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={DebitNoteDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>
                    {debit_note_guid
                      ? "Update Purchase Return"
                      : "Add Purchase Return"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="voucher_type_id"
                    label="Voucher Type"
                    placeholder="Voucher Type"
                    astrike={true}
                    disabled={debit_note_guid && true}
                    validator={requiredValidator}
                    component={FormSelectionField}
                    options={VoucherTypeList?.map((scheme: IVoucherType) => {
                      return {
                        value: scheme?.id,
                        label: scheme?.name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="debit_note_no_string"
                    label="Purchase Return No"
                    placeholder="Purchase Return No"
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="debit_note_date"
                    label="Purchase Return Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem></GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="bill_no"
                    label="Bill No"
                    placeholder="Bill No"
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="bill_date"
                    label="Bill Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="vendor_id"
                    label="Vendor Name"
                    placeholder="Search Vendor Name"
                    isAddNew={true}
                    addNewLink="account/create"
                    component={FormIncrementalSearch}
                    validator={requiredValidator}
                    astrike={true}
                    fetchIncrementalData={(search: any) =>
                      handleVendorSearchChange(
                        search,
                        `vendor_options`,
                        formRenderProps
                      )
                    }
                    options={
                      formRenderProps
                        .valueGetter("vendor_options")
                        ?.map((item: any) => {
                          return {
                            value: item.id,
                            label: item.account_name,
                          };
                        }) || []
                    }
                  />
                </GridLayoutItem>
                {/* <GridLayoutItem>
                  <Field
                    name="purchase_ledger_id"
                    label="Purchase Ledger"
                    placeholder="Search Purchase Ledger"
                    // isAddNew={true}
                    // addNewLink="account/create"
                    component={FormIncrementalSearch}
                    validator={requiredValidator}
                    astrike={true}
                    fetchIncrementalData={(search: any) =>
                      handlePurchaseLedgerSearchChange(
                        search,
                        `debit_note_ledger`,
                        formRenderProps
                      )
                    }
                    options={
                      formRenderProps
                        .valueGetter("debit_note_ledger")
                        ?.map((item: any) => {
                          return {
                            value: item.id,
                            label: item.account_name,
                          };
                        }) || []
                    }
                  />
                </GridLayoutItem> */}
                <GridLayoutItem></GridLayoutItem>
                <GridLayoutItem colSpan={2}>
                  <Field
                    name="remarks"
                    label="Remarks"
                    placeholder="Remarks.."
                    component={FormTextArea}
                    rows={2}
                  />
                </GridLayoutItem>
                <QtyChangeWatcher formRenderProps={formRenderProps} />
                <VendorChangeWatcher formRenderProps={formRenderProps} />
                <DebitNoteNoChangeWatcher formRenderProps={formRenderProps} />
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>Item Details</Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={4}
                  // style={{ borderBottom: "1px solid lightgray" }}
                >
                  <FieldArray
                    formRenderProps={formRenderProps}
                    handleItemSearchChange={handleItemSearchChange}
                    component={DebitNoteItemDetailsArray}
                    name="debit_note_items"
                  />
                </GridLayoutItem>

                <GridLayoutItem colSpan={2}></GridLayoutItem>
                <GridLayoutItem
                  colSpan={2}
                  style={{ display: "flex", alignItems: "end" }}
                >
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Total
                        </td>
                        <td style={{ width: "5%", textAlign: "center" }}>:</td>
                        <td style={{ width: "45%" }}>
                          <Field
                            wrapperClassName="calculation-label"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="taxable_amount"
                            format="n2"
                            component={FormNumericTextField}
                            disabled="true"
                          />
                        </td>
                      </tr>
                    </table>
                  </div>
                </GridLayoutItem>
                <GridLayoutItem colSpan={4}>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    handleLedgerSearchChange={handleLedgerSearchChange}
                    component={debitNoteDutyAndTaxArray}
                    name="debit_note_duty_and_taxes"
                  />
                  {/* <DebitNoteInvoiceTaxSummary
                    formRenderProps={formRenderProps}
                  /> */}
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}></GridLayoutItem>
                <GridLayoutItem
                  colSpan={2}
                  style={{ display: "flex", alignItems: "end" }}
                >
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      {/* <tr>
                        <td
                          style={{
                            width: "50%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Total
                        </td>
                        <td style={{ width: "5%", textAlign: "center" }}>:</td>
                        <td style={{ width: "45%" }}>
                          <Field
                            wrapperClassName="calculation-label"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="taxable_amount"
                            format="n2"
                            component={FormNumericTextField}
                            disabled="true"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Packing
                        </td>
                        <td style={{ width: "5%", textAlign: "center" }}>:</td>
                        <td style={{ width: "45%" }}>
                          <Field
                            wrapperClassName="right-alighned-field"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="packing"
                            format="n2"
                            placeholder="0.00"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Labour
                        </td>
                        <td style={{ width: "5%", textAlign: "center" }}>:</td>
                        <td style={{ width: "45%" }}>
                          <Field
                            wrapperClassName="right-alighned-field"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="labour"
                            format="n2"
                            placeholder="0.00"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Dalali
                        </td>
                        <td style={{ width: "5%", textAlign: "center" }}>:</td>
                        <td style={{ width: "45%" }}>
                          <Field
                            wrapperClassName="right-alighned-field"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="dalali"
                            format="n2"
                            placeholder="0.00"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr>
                      {DebitNoteStateForGST === GUJARATIDFORGST && (
                        <tr>
                          <td
                            style={{
                              width: "50%",
                              textAlign: "end",
                              fontWeight: 600,
                            }}
                          >
                            CGST
                          </td>
                          <td style={{ width: "5%", textAlign: "center" }}>
                            :
                          </td>
                          <td style={{ width: "45%" }}>
                            <Field
                              wrapperClassName="calculation-label"
                              wrapperStyle={{ margin: 0, width: "100%" }}
                              name="cgst"
                              placeholder="0"
                              disabled={true}
                              format="n2"
                              component={FormNumericTextField}
                            />
                          </td>
                        </tr>
                      )}
                      {DebitNoteStateForGST === GUJARATIDFORGST && (
                        <tr>
                          <td
                            style={{
                              width: "50%",
                              textAlign: "end",
                              fontWeight: 600,
                            }}
                          >
                            SGST
                          </td>
                          <td style={{ width: "5%", textAlign: "center" }}>
                            :
                          </td>
                          <td style={{ width: "45%" }}>
                            <Field
                              wrapperClassName="calculation-label"
                              wrapperStyle={{ margin: 0, width: "100%" }}
                              name="sgst"
                              placeholder="0"
                              disabled={true}
                              format="n2"
                              component={FormNumericTextField}
                            />
                          </td>
                        </tr>
                      )}
                      {DebitNoteStateForGST !== GUJARATIDFORGST && (
                        <tr>
                          <td
                            style={{
                              width: "50%",
                              textAlign: "end",
                              fontWeight: 600,
                            }}
                          >
                            IGST
                          </td>
                          <td style={{ width: "5%", textAlign: "center" }}>
                            :
                          </td>
                          <td style={{ width: "45%" }}>
                            <Field
                              wrapperClassName="calculation-label"
                              wrapperStyle={{ margin: 0, width: "100%" }}
                              name="igst"
                              placeholder="0"
                              disabled={true}
                              format="n2"
                              component={FormNumericTextField}
                            />
                          </td>
                        </tr>
                      )} */}
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Sub Total
                        </td>
                        <td style={{ width: "5%", textAlign: "center" }}>:</td>
                        <td style={{ width: "45%" }}>
                          <Field
                            wrapperClassName="calculation-label"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="sub_total"
                            placeholder="0"
                            disabled={true}
                            format="n2"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Round Off
                        </td>
                        <td style={{ width: "5%", textAlign: "center" }}>:</td>
                        <td style={{ width: "45%" }}>
                          <Field
                            wrapperClassName="calculation-label"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="round_off"
                            placeholder="0"
                            disabled={true}
                            format="n2"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Grand Total
                        </td>
                        <td style={{ width: "5%", textAlign: "center" }}>:</td>
                        <td style={{ width: "45%" }}>
                          <Field
                            wrapperClassName="calculation-label"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="grand_total"
                            placeholder="0"
                            disabled={true}
                            format="n2"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr>
                    </table>
                  </div>
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={4}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    marginTop: 15,
                  }}
                >
                  <div>
                    <ButtonWithLoading
                      label={debit_note_guid ? "Update" : "Save"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                    <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginLeft: 4 }}
                      onClick={() =>
                        // navigate("/debitNote")
                        navigate(-1)
                      }
                    >
                      Cancel
                    </Button>
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

// const DebitNoteInvoiceTaxSummary: React.FC<FormChangeWatcherProps> = ({
//   formRenderProps,
// }) => {
//   const location = useLocation();
//   const DebitNoteStateForGST = useAppSelector(
//     (state) => state.debitnote.DebitNoteStateForGST
//   );
//   const debit_note_guid = location.state?.debit_note_guid;
//   const DebitNoteDetail = useAppSelector(
//     (state) => state.debitnote.DebitNoteDetail
//   );
//   const isVendorRef = useRef(true);
//   const [isIGST, setIsIgst] = React.useState<boolean>(false);

//   const totalGST = formRenderProps.valueGetter("gst");
//   const totalGST5 = formRenderProps.valueGetter("gst5");
//   const totalGST12 = formRenderProps.valueGetter("gst12");
//   const totalGST18 = formRenderProps.valueGetter("gst18");
//   const totalGST28 = formRenderProps.valueGetter("gst28");

//   useEffect(() => {
//     if (debit_note_guid) {
//       if (!isVendorRef.current) {
//         setIsIgst(DebitNoteStateForGST !== GUJARATIDFORGST);
//       } else {
//         if (DebitNoteDetail?.igst && DebitNoteDetail?.igst !== null) {
//           setIsIgst(true);
//         } else {
//           setIsIgst(false);
//         }
//         isVendorRef.current = false;
//       }
//     } else {
//       setIsIgst(DebitNoteStateForGST !== GUJARATIDFORGST);
//     }
//   }, [DebitNoteStateForGST]);

//   return (
//     <>
//       <div>
//         <table style={{ width: "100%" }} className="sctaxsummary-table">
//           {(totalGST5 > 0 ||
//             totalGST12 > 0 ||
//             totalGST18 > 0 ||
//             totalGST28 > 0) && (
//             <tr style={{ background: "lightgray" }}>
//               <td colSpan={4}>
//                 <h5 className="m-0">Tax Summery</h5>
//               </td>
//             </tr>
//           )}
//           {isIGST && totalGST5 > 0 && (
//             <tr>
//               <td
//                 style={{
//                   width: "30%",
//                   fontWeight: 600,
//                 }}
//               >
//                 IGST 5%
//               </td>
//               <td style={{ width: "10%", textAlign: "center" }}>:</td>
//               <td style={{ width: "10%", textAlign: "center" }}>5 %</td>
//               <td
//                 style={{
//                   width: "50%",
//                   textAlign: "right",
//                   paddingRight: 45,
//                 }}
//               >
//                 {formatIndianNumber(totalGST5)}
//               </td>
//             </tr>
//           )}
//           {isIGST && totalGST12 > 0 && (
//             <tr>
//               <td
//                 style={{
//                   width: "30%",
//                   fontWeight: 600,
//                 }}
//               >
//                 IGST 12%
//               </td>
//               <td style={{ width: "10%", textAlign: "center" }}>:</td>
//               <td style={{ width: "10%", textAlign: "center" }}>12 %</td>
//               <td
//                 style={{
//                   width: "50%",
//                   textAlign: "right",
//                   paddingRight: 45,
//                 }}
//               >
//                 {formatIndianNumber(totalGST12)}
//               </td>
//             </tr>
//           )}
//           {isIGST && totalGST18 > 0 && (
//             <tr>
//               <td
//                 style={{
//                   width: "30%",
//                   fontWeight: 600,
//                 }}
//               >
//                 IGST 18%
//               </td>
//               <td style={{ width: "10%", textAlign: "center" }}>:</td>
//               <td style={{ width: "10%", textAlign: "center" }}>18 %</td>
//               <td
//                 style={{
//                   width: "50%",
//                   textAlign: "right",
//                   paddingRight: 45,
//                 }}
//               >
//                 {formatIndianNumber(totalGST18)}
//               </td>
//             </tr>
//           )}
//           {isIGST && totalGST28 > 0 && (
//             <tr>
//               <td
//                 style={{
//                   width: "30%",
//                   fontWeight: 600,
//                 }}
//               >
//                 IGST 28%
//               </td>
//               <td style={{ width: "10%", textAlign: "center" }}>:</td>
//               <td style={{ width: "10%", textAlign: "center" }}>28 %</td>
//               <td
//                 style={{
//                   width: "50%",
//                   textAlign: "right",
//                   paddingRight: 45,
//                 }}
//               >
//                 {formatIndianNumber(totalGST28)}
//               </td>
//             </tr>
//           )}
//           {!isIGST && totalGST5 > 0 && (
//             <>
//               <tr>
//                 <td
//                   style={{
//                     width: "30%",
//                     fontWeight: 600,
//                   }}
//                 >
//                   CGST 2.5%
//                 </td>
//                 <td style={{ width: "10%", textAlign: "center" }}>:</td>
//                 <td style={{ width: "10%", textAlign: "center" }}>2.5 %</td>
//                 <td
//                   style={{
//                     width: "50%",
//                     textAlign: "right",
//                     paddingRight: 45,
//                   }}
//                 >
//                   {formatIndianNumber(totalGST5 / 2)}
//                 </td>
//               </tr>
//               <tr>
//                 <td
//                   style={{
//                     width: "30%",
//                     fontWeight: 600,
//                   }}
//                 >
//                   SGST 2.5%
//                 </td>
//                 <td style={{ width: "10%", textAlign: "center" }}>:</td>
//                 <td style={{ width: "10%", textAlign: "center" }}>2.5 %</td>
//                 <td
//                   style={{
//                     width: "50%",
//                     textAlign: "right",
//                     paddingRight: 45,
//                   }}
//                 >
//                   {formatIndianNumber(totalGST5 / 2)}
//                 </td>
//               </tr>
//             </>
//           )}
//           {!isIGST && totalGST12 > 0 && (
//             <>
//               <tr>
//                 <td
//                   style={{
//                     width: "30%",
//                     fontWeight: 600,
//                   }}
//                 >
//                   CGST 6%
//                 </td>
//                 <td style={{ width: "10%", textAlign: "center" }}>:</td>
//                 <td style={{ width: "10%", textAlign: "center" }}>6 %</td>
//                 <td
//                   style={{
//                     width: "50%",
//                     textAlign: "right",
//                     paddingRight: 45,
//                   }}
//                 >
//                   {formatIndianNumber(totalGST12 / 2)}
//                 </td>
//               </tr>
//               <tr>
//                 <td
//                   style={{
//                     width: "30%",
//                     fontWeight: 600,
//                   }}
//                 >
//                   SGST 6%
//                 </td>
//                 <td style={{ width: "10%", textAlign: "center" }}>:</td>
//                 <td style={{ width: "10%", textAlign: "center" }}>6 %</td>
//                 <td
//                   style={{
//                     width: "50%",
//                     textAlign: "right",
//                     paddingRight: 45,
//                   }}
//                 >
//                   {formatIndianNumber(totalGST12 / 2)}
//                 </td>
//               </tr>
//             </>
//           )}
//           {!isIGST && totalGST18 > 0 && (
//             <>
//               <tr>
//                 <td
//                   style={{
//                     width: "30%",
//                     fontWeight: 600,
//                   }}
//                 >
//                   CGST 9%
//                 </td>
//                 <td style={{ width: "10%", textAlign: "center" }}>:</td>
//                 <td style={{ width: "10%", textAlign: "center" }}>9 %</td>
//                 <td
//                   style={{
//                     width: "50%",
//                     textAlign: "right",
//                     paddingRight: 45,
//                   }}
//                 >
//                   {formatIndianNumber(totalGST18 / 2)}
//                 </td>
//               </tr>
//               <tr>
//                 <td
//                   style={{
//                     width: "30%",
//                     fontWeight: 600,
//                   }}
//                 >
//                   SGST 9%
//                 </td>
//                 <td style={{ width: "10%", textAlign: "center" }}>:</td>
//                 <td style={{ width: "10%", textAlign: "center" }}>9 %</td>
//                 <td
//                   style={{
//                     width: "50%",
//                     textAlign: "right",
//                     paddingRight: 45,
//                   }}
//                 >
//                   {formatIndianNumber(totalGST18 / 2)}
//                 </td>
//               </tr>
//             </>
//           )}
//           {!isIGST && totalGST28 > 0 && (
//             <>
//               <tr>
//                 <td
//                   style={{
//                     width: "30%",
//                     fontWeight: 600,
//                   }}
//                 >
//                   CGST 14%
//                 </td>
//                 <td style={{ width: "10%", textAlign: "center" }}>:</td>
//                 <td style={{ width: "10%", textAlign: "center" }}>14 %</td>
//                 <td
//                   style={{
//                     width: "50%",
//                     textAlign: "right",
//                     paddingRight: 45,
//                   }}
//                 >
//                   {formatIndianNumber(totalGST28 / 2)}
//                 </td>
//               </tr>
//               <tr>
//                 <td
//                   style={{
//                     width: "30%",
//                     fontWeight: 600,
//                   }}
//                 >
//                   SGST 14%
//                 </td>
//                 <td style={{ width: "10%", textAlign: "center" }}>:</td>
//                 <td style={{ width: "10%", textAlign: "center" }}>14 %</td>
//                 <td
//                   style={{
//                     width: "50%",
//                     textAlign: "right",
//                     paddingRight: 45,
//                   }}
//                 >
//                   {formatIndianNumber(totalGST28 / 2)}
//                 </td>
//               </tr>
//             </>
//           )}

//           {!isIGST && totalGST > 0 && (
//             <>
//               <tr style={{ background: "whitesmoke" }}>
//                 <td
//                   style={{
//                     width: "30%",
//                     fontWeight: 600,
//                   }}
//                 >
//                   Total CGST
//                 </td>
//                 <td style={{ width: "10%", textAlign: "center" }}>:</td>
//                 <td style={{ width: "10%", textAlign: "center" }}></td>
//                 <td
//                   style={{
//                     width: "50%",
//                     textAlign: "right",
//                     paddingRight: 45,
//                   }}
//                 >
//                   {formatIndianNumber(totalGST / 2)}
//                 </td>
//               </tr>
//               <tr style={{ background: "whitesmoke" }}>
//                 <td
//                   style={{
//                     width: "30%",
//                     fontWeight: 600,
//                   }}
//                 >
//                   Total SGST
//                 </td>
//                 <td style={{ width: "10%", textAlign: "center" }}>:</td>
//                 <td style={{ width: "10%", textAlign: "center" }}></td>
//                 <td
//                   style={{
//                     width: "50%",
//                     textAlign: "right",
//                     paddingRight: 45,
//                   }}
//                 >
//                   {formatIndianNumber(totalGST / 2)}
//                 </td>
//               </tr>
//             </>
//           )}
//           {isIGST && totalGST > 0 && (
//             <tr style={{ background: "whitesmoke" }}>
//               <td
//                 style={{
//                   width: "30%",
//                   fontWeight: 600,
//                 }}
//               >
//                 Total IGST
//               </td>
//               <td style={{ width: "10%", textAlign: "center" }}>:</td>
//               <td style={{ width: "10%", textAlign: "center" }}></td>
//               <td
//                 style={{
//                   width: "50%",
//                   textAlign: "right",
//                   paddingRight: 45,
//                 }}
//               >
//                 {formatIndianNumber(totalGST)}
//               </td>
//             </tr>
//           )}
//         </table>
//       </div>
//     </>
//   );
// };

export default CreateDebitNote;
